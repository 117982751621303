import { useSelector } from "react-redux";
import { DiscoverablePersonalityAnalysis } from "@dimensional-engineering/dimensional-models";

import styles from "./PersonalityAnalysis.module.css";

import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { RootState } from "@/redux/store";
import InvitePill from "@/components/appHome/InvitePill/InvitePill";
import { analysisIconMap } from "./AnalysisData";
import { useModalContext } from "@/context/ModalContext";
import AnalysisModal from "./AnalysisModal/AnalysisModal";
import useShowPremiumPill from "@/helpers/useShowPremiumPill";
import DiscoveryGate from "@/components/shared/DiscoveryGate/DiscoveryGate";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";

type Props = {
  onClick: () => void;
  analysis: DiscoverablePersonalityAnalysis | undefined;
  updatedOn?: number;
  showInvitePill?: boolean;
};

export default function PersonalitAnalysisBlockSmall(props: Props) {
  const inviteLevel = useSelector((state: RootState) => state.user.inviteLevel);

  if (!props.analysis) {
    return <></>;
  }

  if (props.showInvitePill) {
    return (
      <div
        onClick={() => {
          props.onClick();
        }}
        className={styles.mainSmall}
        key={props.analysis?.analysisSlug}
      >
        {props.analysis?.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogoSmall}>
            <InvitePill
              invitesRequired={
                props.analysis.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        )}
        <div className={styles.analysisIconDiv}>
          {
            analysisIconMap[
              props.analysis.analysisSlug as keyof typeof analysisIconMap
            ]
          }
        </div>
        <h1 className={styles.titleSmall}>{props.analysis?.name}</h1>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        props.onClick();
      }}
      className={styles.mainSmall}
      key={props.analysis?.analysisSlug}
    >
      {props.analysis?.isPremium &&
        !props.analysis.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogoSmall}>
            <PremiumPillIcon />
          </div>
        )}
      {props.analysis?.discoveryGate.numSuccessfulInvitesRequired &&
        inviteLevel <
          props.analysis?.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogoSmall}>
            <InvitePill
              invitesRequired={
                props.analysis.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        )}
      <div className={styles.analysisIconDiv}>
        {
          analysisIconMap[
            props.analysis.analysisSlug as keyof typeof analysisIconMap
          ]
        }
      </div>
      <h1 className={styles.titleSmall}>{props.analysis?.name}</h1>
    </div>
  );
}

export function PersonalityAnalysisBlockResultsTab(props: { slug: string }) {
  const userState = useSelector((state: RootState) => state.user);
  const { user } = useAuth();

  const personalityState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );
  const discoveredAnalysis = personalityState.discoveredPersonalAnalysis?.find(
    (p) => p.analysisSlug === props.slug
  );
  const discoverableAnalysis =
    personalityState.discoverablePersonalAnalysis?.find(
      (p) => p.analysisSlug === props.slug
    );

  const showPremium = useShowPremiumPill(
    discoverableAnalysis?.isPremium,
    discoverableAnalysis?.analysisSlug
  );

  const { setModalComponent, setModalOpen } = useModalContext();

  if (!discoverableAnalysis) {
    return <></>;
  }

  function handleClick() {
    if (discoveredAnalysis) {
      return setModalComponent(
        <AnalysisModal slug={props.slug} onClose={() => setModalOpen(false)} />
      );
    } else {
      if (discoverableAnalysis?.discoveryGate.numSuccessfulInvitesRequired) {
        return setModalComponent(
          <DiscoveryGate
            onClose={() => setModalOpen(false)}
            requiredDimensionSlugs={
              discoverableAnalysis?.discoveryGate.requiredDimensionSlugs
            }
            numSuccessfulInvitesRequired={
              discoverableAnalysis?.discoveryGate.numSuccessfulInvitesRequired
            }
            onOpenShareLink={() => {
              summonProfileLink(
                user?.uid,
                "rewards modal",
                "Invite to Dimensional"
              );
            }}
          />
        );
      } else if (discoverableAnalysis?.isPremium && !userState.isPremium) {
        return setModalComponent(<ConversionModal initialSlide={2} />);
      }
      return setModalComponent(
        <DiscoveryGate
          onClose={() => setModalOpen(false)}
          requiredDimensionSlugs={
            discoverableAnalysis?.discoveryGate.requiredDimensionSlugs
          }
          numSuccessfulInvitesRequired={
            discoverableAnalysis?.discoveryGate.numSuccessfulInvitesRequired
          }
          onOpenShareLink={() => {
            summonProfileLink(
              user?.uid,
              "rewards modal",
              "Invite to Dimensional"
            );
          }}
        />
      );
    }
  }

  return (
    <div
      onClick={() => {
        handleClick();
      }}
      className={styles.mainSmall}
      key={props.slug}
    >
      {showPremium &&
        !discoverableAnalysis.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogoSmall}>
            <PremiumPillIcon />
          </div>
        )}
      {!userState.isPremium &&
        discoverableAnalysis.discoveryGate.numSuccessfulInvitesRequired &&
        userState.inviteLevel <
          discoverableAnalysis?.discoveryGate.numSuccessfulInvitesRequired && (
          <div className={styles.premiumLogoSmall}>
            <InvitePill
              invitesRequired={
                discoverableAnalysis.discoveryGate.numSuccessfulInvitesRequired
              }
            />
          </div>
        )}
      <div
        style={!discoveredAnalysis ? { opacity: 0.3 } : {}}
        className={styles.analysisIconDiv}
      >
        {analysisIconMap[props.slug as keyof typeof analysisIconMap]}
      </div>
      <h1
        style={!discoveredAnalysis ? { opacity: 0.3 } : {}}
        className={styles.titleSmall}
      >
        {discoverableAnalysis.name}
      </h1>
    </div>
  );
}
