import { useEffect, useState } from "react";

import archetypesJsonData from "../../../../assets/traitInfo/archetypes.json";
import { ExtendedArchetype } from "@/models/sharedModels";

export default function useGetArcehtypeModalData(props: { slug: string }) {
  const [archetype, setArchetype] = useState<ExtendedArchetype | null>(null);
  const [otherArchetypes, setOtherArchetypes] = useState<ExtendedArchetype[]>(
    []
  );

  useEffect(() => {
    let otherSlugs: string[] = [];
    let otherTempArchetypes: ExtendedArchetype[] = [];
    archetypesJsonData.forEach((arch) => {
      if (arch.slug === props.slug) {
        setArchetype(arch as unknown as ExtendedArchetype);
        arch.traitContent.otherDetails.otherTraits.forEach((otherArch) => {
          otherSlugs.push(otherArch.slug);
        });
      }
    });

    if (otherSlugs.length > 0) {
      archetypesJsonData.forEach((arch) => {
        if (otherSlugs.includes(arch.slug)) {
          otherTempArchetypes.push(arch as unknown as ExtendedArchetype);
        }
      });
    }

    setOtherArchetypes(otherTempArchetypes);
  }, [props.slug]);

  return { archetype, otherArchetypes };
}
