import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DiscoveredSnippet,
  ElementDiscovery,
  PublicProfile,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";

import {
  ExtendedArchetype,
  ExtendedPattern,
  ExtendedScale,
} from "@/models/sharedModels";
import { RootState } from "@/redux/store";
import {
  getElementFromTraitIdentifier,
  getFriendScoreOnElement,
} from "../components/authenticatedProfile/tabs/AuthCompareTab/utils";
import {
  getSnippetFeedbackScore,
  getUserScoreFromTraitIdentifier,
} from "../components/results/Snippets/utils";
import { useAuth } from "@/context/AuthContext";
import { Mixpanel } from "./mixpanel";
import { getUserScaleScore } from "@/components/traits/utils";

export default function useGetSnippetModalDataHook(
  discoveredSnippetSlug: string
) {
  const [snippet, setSnippet] = useState<DiscoveredSnippet | null>(null);
  const userState = useSelector((state: RootState) => state.user);
  const snippetState = useSelector((state: RootState) => state.snippet);
  const [trait, setTrait] = useState<
    ExtendedArchetype | ExtendedPattern | ExtendedScale | null
  >(null);
  const [score, setScore] = useState<number | null | undefined>();
  const { user } = useAuth();
  const [feedbackScore, setFeedbackScore] = useState<number | null>(null);

  useEffect(() => {
    Mixpanel?.track("Story Viewed", { story_slug: discoveredSnippetSlug });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    snippetState.snippets.forEach((s) => {
      if (s?.snippetSlug === discoveredSnippetSlug) {
        if (s?.snippetDetail.traitComparisonDetail?.traitIdentifier) {
          setScore(
            getUserScoreFromTraitIdentifier(
              s?.snippetDetail.traitComparisonDetail?.traitIdentifier,
              userState.elementsDiscovered,
              userState.patternsDiscovered,
              userState.archetypesDiscovered
            )
          );
          setTrait(
            getElementFromTraitIdentifier(
              s.snippetDetail.traitComparisonDetail?.traitIdentifier
            )
          );
        }
        setSnippet(s);
      }
    });
    if (user?.uid) {
      getSnippetFeedbackScore(discoveredSnippetSlug, user?.uid)
        .then((res) => {
          setFeedbackScore(res);
        })
        .catch(() => {
          setFeedbackScore(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discoveredSnippetSlug, snippetState.snippets]);

  return { snippet, trait, score, userState, feedbackScore };
}

export function useGetConnectedAndUnConnectedSnippetModalDataHook(
  publicProfile: PublicProfile | null,
  disocoveredSnippet: DiscoveredSnippet
) {
  const [loading, setLoading] = useState<boolean>(true);
  const [score, setScore] = useState<number | null | undefined>(null);
  const [trait, setTrait] = useState<
    ExtendedArchetype | ExtendedPattern | ExtendedScale | null
  >(null);
  useEffect(() => {
    if (
      disocoveredSnippet?.snippetDetail.traitComparisonDetail
        ?.traitIdentifier &&
      publicProfile
    ) {
      getFriendScoreOnElement(
        publicProfile.ownerUUID,
        disocoveredSnippet?.snippetDetail.traitComparisonDetail?.traitIdentifier
          .slug
      )
        .then((res) => {
          const data = res.data as ElementDiscovery;
          const userScore = getUserScaleScore(
            data.scaleScore?.percentScore,
            data.scaleSlug
          );
          setScore(userScore);
          setLoading(false);
          setTrait(
            getElementFromTraitIdentifier(
              disocoveredSnippet?.snippetDetail.traitComparisonDetail
                ?.traitIdentifier as TraitIdentifier
            )
          );
        })
        .catch((err) => {
          console.log(err);
          setScore(null);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setScore(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disocoveredSnippet?.snippetDetail.traitComparisonDetail?.traitIdentifier,
    publicProfile?.ownerUUID,
  ]);

  return { loading, score, trait };
}
