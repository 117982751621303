import { useEffect, useState } from "react";

import styles from "./Pattern.module.css";

import pattetsJsonData from "../../../../assets/traitInfo/patterns.json";
import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import { natureData } from "@/assets/traitInfo/natureData";

import { ExtendedPattern } from "@/models/sharedModels";
import TraitScore from "../../shared/TraitScore/TraitScore";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import useShowPremiumPill from "@/helpers/useShowPremiumPill";

type PatternProps = {
  patternSlug: string;
  score?: number | null;
  showPremiumPill?: boolean;
};

export default function Pattern(props: PatternProps) {
  const { pattern, divs } = useGetPatternColors(props.patternSlug);
  const showPremium = useShowPremiumPill(pattern?.isPremium, pattern?.slug);

  if (props.showPremiumPill) {
    return (
      <div className={styles.patternParent}>
        <div className={styles.patternMidContainer}>
          <div className="patternMaksContainer">
            {divs.map((d, i) => {
              return d;
            })}
          </div>
        </div>
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="patternImageClipPath">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 2.64685C0 1.18504 1.18577 0 2.64848 0H9.31722C10.7799 0 11.9657 1.18504 11.9657 2.64685V38.3531C11.9657 39.815 10.7799 41 9.31722 41H2.64848C1.18577 41 0 39.815 0 38.3531V2.64685ZM14.4684 2.64685C14.4684 1.18504 15.6542 0 17.1169 0H23.7856C25.2484 0 26.4341 1.18504 26.4341 2.64685V38.3531C26.4341 39.815 25.2484 41 23.7856 41H17.1169C15.6542 41 14.4684 39.815 14.4684 38.3531V2.64685ZM31.6828 0C30.2201 0 29.0343 1.18504 29.0343 2.64685V38.3531C29.0343 39.815 30.2201 41 31.6828 41H38.3515C39.8143 41 41 39.815 41 38.3531V2.64685C41 1.18504 39.8143 0 38.3515 0H31.6828Z"
              fill="#FFFFF2"
            />
          </clipPath>
        </svg>
        {pattern?.isPremium && (
          <div className={styles.premiumIcon}>
            <PremiumPillIcon />
          </div>
        )}
        <div className={styles.elementNameContainer}>
          <p className={styles.bottomText}>{pattern?.name}</p>
        </div>
        {props.score ? <TraitScore score={props.score * 100} /> : null}
      </div>
    );
  }

  return (
    <div className={styles.patternParent}>
      <div className={styles.patternMidContainer}>
        <div className="patternMaksContainer">
          {divs.map((d, i) => {
            return d;
          })}
        </div>
      </div>
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="patternImageClipPath">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2.64685C0 1.18504 1.18577 0 2.64848 0H9.31722C10.7799 0 11.9657 1.18504 11.9657 2.64685V38.3531C11.9657 39.815 10.7799 41 9.31722 41H2.64848C1.18577 41 0 39.815 0 38.3531V2.64685ZM14.4684 2.64685C14.4684 1.18504 15.6542 0 17.1169 0H23.7856C25.2484 0 26.4341 1.18504 26.4341 2.64685V38.3531C26.4341 39.815 25.2484 41 23.7856 41H17.1169C15.6542 41 14.4684 39.815 14.4684 38.3531V2.64685ZM31.6828 0C30.2201 0 29.0343 1.18504 29.0343 2.64685V38.3531C29.0343 39.815 30.2201 41 31.6828 41H38.3515C39.8143 41 41 39.815 41 38.3531V2.64685C41 1.18504 39.8143 0 38.3515 0H31.6828Z"
            fill="#FFFFF2"
          />
        </clipPath>
      </svg>
      {showPremium && (
        <div className={styles.premiumIcon}>
          <PremiumPillIcon />
        </div>
      )}
      <div className={styles.elementNameContainer}>
        <p className={styles.bottomText}>{pattern?.name}</p>
      </div>
      {props.score ? <TraitScore score={props.score * 100} /> : null}
    </div>
  );
}

export function useGetPatternColors(slug: string) {
  const [pattern, setPattern] = useState<ExtendedPattern | null>(null);
  const [divs, setDivs] = useState<[] | JSX.Element[]>([]);

  useEffect(() => {
    setDivs([]);
    pattetsJsonData.forEach((patt) => {
      if (patt.slug === slug) {
        setPattern(patt as unknown as ExtendedPattern);
        let mappedColorHit = {
          opennessAndIntellect: {
            primary: false,
            secondary: true,
          },
          warmthAndAgreeableness: {
            primary: false,
            secondary: true,
          },
          chaos: {
            primary: false,
            secondary: true,
          },
          energyAndExcitement: {
            primary: false,
            secondary: true,
          },
          tranquility: {
            primary: false,
            secondary: true,
          },
          selfEnhancement: {
            primary: false,
            secondary: true,
          },
          orderAndStructure: {
            primary: false,
            secondary: true,
          },
        };
        patt.blendedRules.forEach((blend, i) => {
          let scale = elementsJsonData?.find(
            (extendedEl) => extendedEl.slug === blend.traitSlug
          );
          let nature = scale?.nature;
          if (scale?.isDiscoverable && nature) {
            const foundNature = natureData?.find((n) => n.slug === nature);
            let color = "gray";
            if (foundNature) {
              const natureSlug = foundNature.slug;
              if (
                mappedColorHit[natureSlug as keyof typeof mappedColorHit]
                  .primary === false
              ) {
                color = foundNature.color;
                mappedColorHit[
                  natureSlug as keyof typeof mappedColorHit
                ].primary = true;
                mappedColorHit[
                  natureSlug as keyof typeof mappedColorHit
                ].secondary = false;
              } else {
                color = foundNature.secondaryColor;
                mappedColorHit[
                  natureSlug as keyof typeof mappedColorHit
                ].primary = false;
                mappedColorHit[
                  natureSlug as keyof typeof mappedColorHit
                ].secondary = true;
              }
            }
            const div = (
              <div
                className={styles.innerDiv}
                style={{ backgroundColor: color }}
                key={i}
              ></div>
            );
            setDivs((current) => [...current, div]);
          }
        });
      }
    });
  }, [slug]);

  return { pattern, divs };
}
