import { useSelector } from "react-redux";

import styles from "./ContextSwitcherModal.module.css";

import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import { useAuth } from "@/context/AuthContext";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import { RootState } from "@/redux/store";
import ElementModal from "../TraitsModals/Element/ElementModal";
import PatternModal from "../TraitsModals/Pattern/PatternModal";
import ArchetypeModal from "../TraitsModals/Archetype/ArchetypeModal";
import { useModalContext } from "@/context/ModalContext";

export default function ContextSwitcherModal(props: {
  type: "element" | "pattern" | "archetype";
  slug: string;
  elementName: "ContextSwitcherModal";
}) {
  const { setModalOpen, emptyModal, setModalComponent, removeModalByName } =
    useModalContext();
  const { user } = useAuth();
  const connectionListWithFriendDetails = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );
  const userState = useSelector((state: RootState) => state.user);

  function handleClick(uid: string | undefined) {
    if (props.type === "element") {
      setModalComponent(<ElementModal uid={uid} slug={props.slug} />);
      removeModalByName("ContextSwitcherModal");
    } else if (props.type === "pattern") {
      setModalComponent(<PatternModal uid={uid} slug={props.slug} />);
      removeModalByName("ContextSwitcherModal");
    } else if (props.type === "archetype") {
      setModalComponent(<ArchetypeModal uid={uid} slug={props.slug} />);
      removeModalByName("ContextSwitcherModal");
    }
  }

  const mapped = connectionListWithFriendDetails?.map((person) => {
    return (
      <div
        onClick={() => handleClick(person.ownerUUID)}
        className={styles.person}
        key={person.ownerUUID}
      >
        <SmallProfileImage imageUrl={person.imageUrl} publicProfile={person} />
        <div className={styles.nameDiv}>
          <p className={styles.name}>{person.name}</p>
          <p className={styles.username}>{person.userName}</p>
        </div>
        <div className={styles.circle}></div>
      </div>
    );
  });

  return (
    <dialog
      onClick={() => {
        emptyModal();
      }}
      className={styles.main}
    >
      <main
        id="dialog"
        className={styles.wrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => setModalOpen(false)} className={styles.closeBtn}>
          <CloseButtonSummary />
        </div>
        <h1 className={styles.title}>See scores for</h1>
        <div className={styles.personWrap}>
          <div onClick={() => handleClick(user?.uid)} className={styles.person}>
            <SmallProfileImage
              imageUrl={userState.imageURL ?? ""}
              publicProfile={userState.publicProfileData}
            />
            <div className={styles.nameDiv}>
              <p className={styles.name}>{userState.publicProfileData?.name}</p>
              <p className={styles.username}>
                {userState.publicProfileData?.userName}
              </p>
            </div>
            <div className={styles.circle}></div>
          </div>
          {mapped}
        </div>
      </main>
    </dialog>
  );
}
