// import BackArrow from "@/components/shared/buttons/BackArrow";
// import ForwardArrow from "@/components/shared/buttons/ForwardArrow";
import { useEffect, useState } from "react";
import { Roboto_Mono } from "next/font/google";
import Link from "next/link";

import styles from "./TraitsTab.module.css";
import BlueDot from "@/components/shared/SwipeTabs/BlueDot";

const roboto = Roboto_Mono({
  weight: "400",
  subsets: ["latin"],
});

type TraitsTabProps = {
  tabs: string[];
  changeTab: (tab: string) => void;
  parentTab: string;
  urls?: string;
  grow?: boolean;
  onProfile?: boolean;
  sticky?: boolean;
  tabsWithBlueDot?: string[];
};

export default function TraitsTab(props: TraitsTabProps) {
  const [currentTab, setCurrentTab] = useState<string>(props.tabs[0]);
  const [showBlueDot, setShowBlueDot] = useState<boolean>(true);

  useEffect(() => {
    setCurrentTab(props.parentTab);
    const el = document.getElementById(props.parentTab);
    el?.scrollIntoView({
      block: "nearest",
      behavior: "smooth",
      inline: "center",
    });
  }, [props.parentTab]);

  return (
    <div
      style={
        props.sticky
          ? {
              position: "sticky",
              top: "-1px",
              zIndex: "2",
              backgroundColor: "#181716",
            }
          : {}
      }
      className={styles.tabParent}
    >
      <div
        className={
          props.onProfile ? styles.tabContainerProfile : styles.tabContainer
        }
      >
        {props.tabs.map((t, i) => {
          return (
            <div
              id={t}
              key={t}
              onClick={(e) => {
                setCurrentTab(t);
                props.changeTab(t);
                setShowBlueDot(false);
              }}
              className={
                currentTab === t
                  ? `${styles.tabChild} ${styles.tabActive}`
                  : styles.tabChild
              }
              style={props.grow === true ? { flexGrow: "1" } : {}}
            >
              {props.tabsWithBlueDot?.includes(t) && showBlueDot && (
                <div className={styles.blueDot}>
                  <BlueDot />
                </div>
              )}
              {props.urls ? (
                <Link href={`${props.urls}/#${t}`}>
                  <h3>{t.toUpperCase()}</h3>
                </Link>
              ) : (
                <h3>{t.toUpperCase()}</h3>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
