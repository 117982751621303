import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  SummaryTable as SummaryTableType,
  Scale,
  Archetype,
  DimensionEvent,
  ElementDiscovery,
  PatternDiscovery,
  ArchetypeDiscovery,
  DimensionSummary,
  PublicProfile,
  DiscoverableStory,
  DiscoveredStory,
  FriendRequest,
  LlmRequest,
  AggregatePeerScore,
} from "@dimensional-engineering/dimensional-models";
import {
  HighestScoringTraitsModel,
  PrivateMemberProfile,
  UseGetProfileDataResult,
  UserAccessMap,
} from "../../models/sharedModels";
import { Mixpanel } from "@/helpers/mixpanel";

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export interface ProfileData {
  imageURL: string | null;
  summaryTableData: SummaryTableType | null;
  adjectivesData: null | string[];
  nutshell: undefined | string;
  scales: Scale[] | [];
  archetypeData: ArchetypeDataModel[] | null;
  isSignatureArray: any[];
  scalesLoading: boolean;
  archetypeLoading: boolean;
  summaryLoading: boolean;
  adjesctivesLoading: boolean;
  dimensionEvents: DimensionEvent[] | null;
  primaryFinished: boolean;
  cognitiveFinished: boolean;
  valueFinished: boolean;
  currentRecommended: string | null;
  elementsDiscovered: ElementDiscovery[] | null;
  patternsDiscovered: PatternDiscovery[] | null;
  archetypesDiscovered: ArchetypeDiscovery[] | null;
  dimensionSummaries: DimensionSummary[] | null;
  publicProfileData: PublicProfile | null;
  discoverableStories: DiscoverableStory[] | null;
  discoveredStories: DiscoveredStory[] | null;
  uploadImageSource: string;
  phoneNumber: string | null | undefined;
  memberProfile: PrivateMemberProfile | null;
  isPremium: boolean;
  friendRequestsRecieved: FriendRequest[];
  friendRequestRecievedUidsList: string[];
  friendRequestsSentUidList: string[];
  isCloseRequestSentUidList: string[];
  isDeepRequestSentUidList: string[];
  accessMap: UserAccessMap | null;
  friendUidList: string[];
  closeFriendUidList: string[];
  deepFriendUidList: string[];
  highestScroingTraits: HighestScoringTraitsModel | null;
  nutshellLLMRequests: LlmRequest[];
  inviteLevel: number;
  peerFeedbackLevel: number;
  notificationPermission: boolean;
  aggregatePeerScores: AggregatePeerScore[];
  isUserInitialized: boolean;
}

const initialState: ProfileData = {
  imageURL: null,
  summaryTableData: null,
  adjectivesData: null,
  nutshell: undefined,
  scales: [],
  archetypeData: null,
  isSignatureArray: [],
  scalesLoading: true,
  archetypeLoading: true,
  summaryLoading: true,
  adjesctivesLoading: true,
  dimensionEvents: null,
  primaryFinished: false,
  cognitiveFinished: false,
  valueFinished: false,
  currentRecommended: null,
  elementsDiscovered: null,
  patternsDiscovered: null,
  archetypesDiscovered: null,
  dimensionSummaries: null,
  publicProfileData: null,
  discoverableStories: null,
  discoveredStories: null,
  uploadImageSource: "",
  phoneNumber: null,
  memberProfile: null,
  isPremium: false,
  friendRequestsRecieved: [],
  friendRequestRecievedUidsList: [],
  friendRequestsSentUidList: [],
  isCloseRequestSentUidList: [],
  isDeepRequestSentUidList: [],
  accessMap: null,
  friendUidList: [],
  closeFriendUidList: [],
  deepFriendUidList: [],
  highestScroingTraits: null,
  nutshellLLMRequests: [],
  inviteLevel: 0,
  peerFeedbackLevel: 0,
  notificationPermission: false,
  aggregatePeerScores: [],
  isUserInitialized: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UseGetProfileDataResult>) => {
      return {
        ...state,
        summaryTableData: action.payload.summaryTableData,
        adjectivesData: action.payload.adjectivesData,
        archetypeData: action.payload.archetypeData,
        nutshell: action.payload.nutshell,
      };
    },
    resetState: (state) => {
      return { ...initialState };
    },
    setDimensionData: (state, action: PayloadAction<DimensionEvent[]>) => {
      return { ...state, dimensionEvents: action.payload };
    },
    setElementDiscoveries: (
      state,
      action: PayloadAction<ElementDiscovery[]>
    ) => {
      return { ...state, elementsDiscovered: action.payload };
    },
    setPatternDiscoveries: (
      state,
      action: PayloadAction<PatternDiscovery[]>
    ) => {
      return { ...state, patternsDiscovered: action.payload };
    },
    setArchetypeDiscoveries: (
      state,
      action: PayloadAction<ArchetypeDiscovery[]>
    ) => {
      return { ...state, archetypesDiscovered: action.payload };
    },
    setPrimaryFinished: (state, action: PayloadAction<boolean>) => {
      return { ...state, primaryFinished: action.payload };
    },
    setCognitiveFinished: (state, action: PayloadAction<boolean>) => {
      return { ...state, cognitiveFinished: action.payload };
    },
    setValuesFinished: (state, action: PayloadAction<boolean>) => {
      return { ...state, valueFinished: action.payload };
    },
    setCurrentRecommended: (state, action: PayloadAction<string>) => {
      return { ...state, currentRecommended: action.payload };
    },
    setDimensionSummaries: (
      state,
      action: PayloadAction<DimensionSummary[]>
    ) => {
      return { ...state, dimensionSummaries: action.payload };
    },
    setImageURL: (state, action: PayloadAction<string | null>) => {
      return { ...state, imageURL: action.payload };
    },
    setPublicProfileData: (state, action: PayloadAction<PublicProfile>) => {
      Mixpanel?.people.update({ FriendsCount: action.payload.numFriends });
      return { ...state, publicProfileData: action.payload };
    },
    setDiscoverableStories: (
      state,
      action: PayloadAction<DiscoverableStory[]>
    ) => {
      ///duplicates bug
      let slugs: string[] = [];
      let list: DiscoverableStory[] = [];
      action.payload.forEach((s) => {
        if (!slugs.includes(s.storySlug)) {
          list.push(s);
          slugs.push(s.storySlug);
        }
      });
      return { ...state, discoverableStories: list };
    },
    setDiscoveredStories: (state, action: PayloadAction<DiscoveredStory[]>) => {
      return { ...state, discoveredStories: action.payload };
    },
    setUploadImageSource: (state, action: PayloadAction<string>) => {
      return { ...state, uploadImageSource: action.payload };
    },
    setPhoneNumber: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, phoneNumber: action.payload };
    },
    setMemberProfile: (state, action: PayloadAction<PrivateMemberProfile>) => {
      return { ...state, memberProfile: action.payload };
    },
    setIsPremium: (state, action: PayloadAction<boolean>) => {
      Mixpanel?.people.update({ is_premium: action.payload });
      return { ...state, isPremium: action.payload };
    },
    setFriendRequestsRecieved: (
      state,
      action: PayloadAction<FriendRequest[]>
    ) => {
      return { ...state, friendRequestsRecieved: action.payload };
    },
    setFriendRequestRecievedUidsList: (
      state,
      action: PayloadAction<string[]>
    ) => {
      return { ...state, friendRequestRecievedUidsList: action.payload };
    },
    setAccessMap: (state, action: PayloadAction<UserAccessMap>) => {
      return { ...state, accessMap: action.payload };
    },
    setFriendUidList: (state, action: PayloadAction<string[]>) => {
      return { ...state, friendUidList: action.payload };
    },
    setCloseFriendUidList: (state, action: PayloadAction<string[]>) => {
      return { ...state, closeFriendUidList: action.payload };
    },
    setDeepFriendUidList: (state, action: PayloadAction<string[]>) => {
      return { ...state, deepFriendUidList: action.payload };
    },
    setFriendRequestsSentUidList: (state, action: PayloadAction<string[]>) => {
      return { ...state, friendRequestsSentUidList: action.payload };
    },
    setIsCloseFriendRequestsSentUidList: (
      state,
      action: PayloadAction<string[]>
    ) => {
      return { ...state, isCloseRequestSentUidList: action.payload };
    },
    setIsDeepFriendRequestsSentUidList: (
      state,
      action: PayloadAction<string[]>
    ) => {
      return { ...state, isDeepRequestSentUidList: action.payload };
    },
    setHighestScoringTraits: (
      state,
      action: PayloadAction<HighestScoringTraitsModel>
    ) => {
      return { ...state, highestScroingTraits: action.payload };
    },
    setNutshellLlmRequests: (state, action: PayloadAction<LlmRequest[]>) => {
      return { ...state, nutshellLLMRequests: action.payload };
    },
    setInviteLevel: (state, action: PayloadAction<number>) => {
      return { ...state, inviteLevel: action.payload };
    },
    setPeerFeedbackLevel: (state, action: PayloadAction<number>) => {
      return { ...state, peerFeedbackLevel: action.payload };
    },
    setNotificationPemrission: (
      state,
      action: PayloadAction<{ value: boolean; uid: string | undefined }>
    ) => {
      Mixpanel?.people.update(
        { isNotificationsEnabled: action.payload.value },
        action.payload.uid
      );
      return { ...state, notificationPermission: action.payload.value };
    },
    setAggregatePeerScore: (
      state,
      action: PayloadAction<AggregatePeerScore[]>
    ) => {
      return { ...state, aggregatePeerScores: action.payload };
    },
    setUserIsInitialized: (state, action: PayloadAction<boolean>) => {
      return { ...state, isUserInitialized: action.payload };
    },
  },
});

export const {
  setUser,
  resetState,
  setDimensionData,
  setElementDiscoveries,
  setPatternDiscoveries,
  setArchetypeDiscoveries,
  setPrimaryFinished,
  setCognitiveFinished,
  setValuesFinished,
  setCurrentRecommended,
  setDimensionSummaries,
  setImageURL,
  setPublicProfileData,
  setDiscoverableStories,
  setDiscoveredStories,
  setUploadImageSource,
  setPhoneNumber,
  setMemberProfile,
  setIsPremium,
  setFriendRequestsRecieved,
  setFriendRequestRecievedUidsList,
  setAccessMap,
  setFriendUidList,
  setCloseFriendUidList,
  setDeepFriendUidList,
  setFriendRequestsSentUidList,
  setIsCloseFriendRequestsSentUidList,
  setIsDeepFriendRequestsSentUidList,
  setHighestScoringTraits,
  setNutshellLlmRequests,
  setInviteLevel,
  setPeerFeedbackLevel,
  setNotificationPemrission,
  setAggregatePeerScore,
  setUserIsInitialized,
} = userSlice.actions;

export default userSlice.reducer;
