import { useState } from "react";
import {
  ContentType,
  DimensionEvent,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import styles from "./DiscoveryGate.module.css";

import { RootState } from "@/redux/store";
import DiscoveryCheckMark from "./DiscoveryCheckMark";
import ComingSoonIcon from "../icons/ComingSoonIcon";
import UserSuccessInviteGate from "./UserSuccessInviteGate";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import NudgeModal from "../NudgeModal/NudgeModal";
import { useModalContext } from "@/context/ModalContext";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import useDiscoveryGateHook from "@/helpers/useDiscoveryGateHook";
import UserConnectionGate from "./UserConnectionGate";
import Loader from "../Loader";
import UserPeerRatingGate from "./UserPeerRatingGate";
import { dimensionIcons } from "@/assets/traitInfo/dimensionData";
import { Mixpanel } from "@/helpers/mixpanel";

type Props = {
  numFriendsRequired?: number | null | undefined;
  requiredDimensionSlugs?: string[] | null | undefined;
  numEndorsementsRequired?: number | null | undefined;
  numPeerRatingsRequired?: number | null | undefined;
  numSuccessfulInvitesRequired?: number | null | undefined;
  onClose: () => void;
  onOpenShareLink: () => void;
  fromStory?: boolean;
  showNudge?: boolean;
  publicProfile?: PublicProfile | null;
  fromArchetypes?: boolean;
  similaritySlug?: string;
};

export default function DiscoveryGate(props: Props) {
  const router = useRouter();
  const dimensionEvents: DimensionEvent[] | null = useSelector(
    (state: RootState) => state.user?.dimensionEvents
  );
  const userSuccessfulInvites = useSelector(
    (state: RootState) => state.user.memberProfile?.recommendeeUids
  );
  const userConnectionCount = useSelector(
    (state: RootState) => state.user.friendUidList.length
  );
  const {
    dimensionData,
    errorState,
    neededDimensionsCompleted,
    connectionComplete,
    invitesComplete,
    peerRatingCount,
    peerRatingsComplete,
  } = useDiscoveryGateHook({
    userConnectionCount: userConnectionCount,
    userSuccessfulInvites: userSuccessfulInvites,
    userDimensionEvents: dimensionEvents,
    requiredDimensionSlugs: props.requiredDimensionSlugs,
    numFriendsRequired: props.numFriendsRequired,
    numSuccessfulInvitesRequired: props.numSuccessfulInvitesRequired,
    numPeerRatingsRequired: props.numPeerRatingsRequired,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { setModalOpen } = useModalContext();

  const mappedDimensions = dimensionData
    .filter((a) => a.isNeeded === true)
    .map((dim, index) => {
      return (
        <div className={styles.row} key={dim.slug}>
          <div className={styles.iconDiv}>
            {dim.event === "Retake"
              ? dimensionIcons[dim.slug].iconInactive
              : dimensionIcons[dim.slug].icon}
          </div>
          <div>
            <h2 className={dim.event === "Retake" ? styles.inactive : ""}>
              {dim.title}
            </h2>
            <p className={dim.event === "Retake" ? styles.inactive : ""}>
              {dim.numOfElements} Elements
            </p>
          </div>

          {dim.event !== "Retake" && (
            <button
              onClick={() => {
                router.push(dim.url);
                props.onClose();
              }}
              className={styles.buttonDivActive}
            >
              Go
            </button>
          )}
          {dim.event === "Retake" && (
            <div className={styles.checkParentDiv}>
              <DiscoveryCheckMark
                backgroundColor="#464542"
                checkColor="#fffff2"
              />
            </div>
          )}
        </div>
      );
    });

  function handleRetryClick() {
    setLoading(true);
    const callRetry = callFirebaseFunctions("recomputeDiscoveredStories");
    callRetry()
      .then((res) => {
        setLoading(false);
        setModalOpen(false);
      })
      .catch(() => {
        setLoading(false);
        setModalOpen(false);
      });
  }

  function handleRetryArchetypes() {
    setLoading(true);
    const comp = callFirebaseFunctions("recomputeMemberScores");
    comp()
      .then((res) => {
        setLoading(false);
        setModalOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setModalOpen(false);
        console.log(err);
      });
  }

  if (loading) {
    return (
      <ModalWrapper title="" noHeader large backgroundColor="#2d2927">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  if (errorState && props.showNudge && props.publicProfile) {
    return (
      <NudgeModal
        publicProfile={props.publicProfile}
        content={{
          contentType: ContentType.similarity,
          contentSlug: props.similaritySlug ?? "",
        }}
      />
    );
  }

  if (errorState === true) {
    Mixpanel?.track("Error Modal Summoned", {});
    return (
      <ModalWrapper title="" noHeader large backgroundColor="#2d2927">
        <div
          onClick={() => {
            props.onClose();
          }}
          className={styles.closeButton}
          style={{ top: "10px" }}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.15"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#FFFFF2"
            />
            <g opacity="0.7">
              <path
                d="M23.8192 11.9618C23.9309 11.8559 23.986 11.7253 23.986 11.5715C23.986 11.4177 23.9309 11.2842 23.8192 11.1725C23.7133 11.0666 23.5827 11.0143 23.4289 11.0143C23.2751 11.0143 23.1416 11.0666 23.0299 11.1725L17.8574 16.3535L12.6763 11.1725C12.5704 11.0666 12.4398 11.0143 12.286 11.0143C12.1322 11.0143 11.9987 11.0666 11.887 11.1725C11.7811 11.2842 11.7289 11.4177 11.7289 11.5715C11.7289 11.7253 11.7811 11.8558 11.887 11.9618L17.0681 17.1428L11.887 22.3153C11.7811 22.4271 11.7289 22.5605 11.7289 22.7143C11.7289 22.8681 11.7811 22.9987 11.887 23.1046C11.9987 23.2164 12.1322 23.2715 12.286 23.2715C12.4398 23.2715 12.5704 23.2164 12.6763 23.1046L17.8574 17.9321L23.0299 23.1046C23.1416 23.2164 23.2751 23.2715 23.4289 23.2715C23.5827 23.2715 23.7132 23.2164 23.8192 23.1046C23.9309 22.9987 23.986 22.8681 23.986 22.7143C23.986 22.5605 23.9309 22.4271 23.8192 22.3153L18.6467 17.1428L23.8192 11.9618Z"
                fill="#FFFFF2"
              />
            </g>
          </svg>
        </div>

        {!props.fromStory && !props.fromArchetypes && (
          <div className={styles.gatedParent}>
            <div className={styles.soonIcon}>
              <ComingSoonIcon />
            </div>
            <h1 className={styles.soonText}>
              Content is recomputed once a week. Come back soon!
            </h1>
          </div>
        )}
        {props.fromStory && (
          <div className={styles.gatedParent}>
            <div className={styles.soonIcon}>
              <ComingSoonIcon />
            </div>
            <h1 className={styles.soonText}>An Error Occured!</h1>
            <button
              className={styles.retrtyButton}
              onClick={() => {
                handleRetryClick();
              }}
            >
              Retry
            </button>
          </div>
        )}
        {props.fromArchetypes && (
          <div className={styles.gatedParent}>
            <div className={styles.soonIcon}>
              <ComingSoonIcon />
            </div>
            <h1 className={styles.soonText}>An Error Occured!</h1>
            <button
              className={styles.retrtyButton}
              onClick={() => {
                handleRetryArchetypes();
              }}
            >
              Retry
            </button>
          </div>
        )}
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper title="" noHeader large backgroundColor="#2d2927">
      <div className={styles.parent}>
        <div
          onClick={() => {
            props.onClose();
          }}
          className={styles.closeButton}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.15"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#FFFFF2"
            />
            <g opacity="0.7">
              <path
                d="M23.8192 11.9618C23.9309 11.8559 23.986 11.7253 23.986 11.5715C23.986 11.4177 23.9309 11.2842 23.8192 11.1725C23.7133 11.0666 23.5827 11.0143 23.4289 11.0143C23.2751 11.0143 23.1416 11.0666 23.0299 11.1725L17.8574 16.3535L12.6763 11.1725C12.5704 11.0666 12.4398 11.0143 12.286 11.0143C12.1322 11.0143 11.9987 11.0666 11.887 11.1725C11.7811 11.2842 11.7289 11.4177 11.7289 11.5715C11.7289 11.7253 11.7811 11.8558 11.887 11.9618L17.0681 17.1428L11.887 22.3153C11.7811 22.4271 11.7289 22.5605 11.7289 22.7143C11.7289 22.8681 11.7811 22.9987 11.887 23.1046C11.9987 23.2164 12.1322 23.2715 12.286 23.2715C12.4398 23.2715 12.5704 23.2164 12.6763 23.1046L17.8574 17.9321L23.0299 23.1046C23.1416 23.2164 23.2751 23.2715 23.4289 23.2715C23.5827 23.2715 23.7132 23.2164 23.8192 23.1046C23.9309 22.9987 23.986 22.8681 23.986 22.7143C23.986 22.5605 23.9309 22.4271 23.8192 22.3153L18.6467 17.1428L23.8192 11.9618Z"
                fill="#FFFFF2"
              />
            </g>
          </svg>
        </div>

        <div className={styles.parent}>
          <h1 className={styles.requiredTitle}>REQUIRED TO UNLOCK THIS</h1>
          {props.requiredDimensionSlugs &&
          neededDimensionsCompleted === false ? (
            <>
              <div className={styles.tableHeader}>Dimensions</div>
              {mappedDimensions}
            </>
          ) : (
            <div className={styles.tableHeader}>
              Dimensions
              <div className={styles.checkmarkCompleteDiv}>
                <DiscoveryCheckMark
                  backgroundColor="#e6e1df"
                  checkColor="#2D2927"
                />
              </div>
            </div>
          )}

          {props.numFriendsRequired && (
            <UserConnectionGate
              numberOfConnectionsRequired={props.numFriendsRequired}
              onOpenShareLink={() => {
                props.onOpenShareLink();
              }}
            />
          )}

          {props.numPeerRatingsRequired && (
            <UserPeerRatingGate
              peerRatingsCount={peerRatingCount}
              numPeerRatingsRequired={props.numPeerRatingsRequired}
              isPeerRatingComplete={peerRatingsComplete}
            />
          )}

          {props.numSuccessfulInvitesRequired && (
            <UserSuccessInviteGate
              numberOfInvitesRequired={props.numSuccessfulInvitesRequired}
              invites={userSuccessfulInvites}
              onOpenShareLink={() => {
                props.onOpenShareLink();
              }}
            />
          )}
        </div>
      </div>
    </ModalWrapper>
  );
}
