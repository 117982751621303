export default function NutshellSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M33.8643 9.74238L26.6625 2.54475C26.3294 2.20163 25.878 2.01099 25.4044 2.00098H9.19744C7.21897 2.00098 5.5957 3.61823 5.5957 5.60272V34.3989C5.5957 36.3874 7.20895 38.0006 9.19744 38.0006H30.798C32.7905 38.0006 34.3998 36.3934 34.3998 34.3989V11.0045C34.3938 10.527 34.1994 10.0754 33.8643 9.74238ZM25.4045 6.34128L30.0637 11.0005H25.4045V6.34128ZM30.7981 34.3988H9.19755V5.60107H21.7985V11.0008C21.7985 12.9893 23.4118 14.6026 25.4043 14.6026H30.7979L30.7981 34.3988Z"
        fill="#FFFFF2"
      />
      <path
        d="M25.4059 24.2539H14.5986C13.6074 24.2539 12.8008 25.0605 12.8008 26.0518C12.8008 27.049 13.6074 27.8556 14.5986 27.8556H25.4059C26.3971 27.8556 27.2037 27.049 27.2037 26.0518C27.1997 25.0545 26.3971 24.2539 25.4059 24.2539Z"
        fill="#FFFFF2"
      />
      <path
        d="M14.5977 17.0527C13.6064 17.0527 12.7998 17.8594 12.7998 18.8506C12.7998 19.8418 13.6064 20.6485 14.5977 20.6485H25.4049C26.3961 20.6485 27.2028 19.8479 27.2028 18.8506C27.2028 17.8533 26.3961 17.0527 25.4049 17.0527H14.5977Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function NustshellSnippetIconPreview() {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="15.5"
        r="14.7493"
        stroke="#FFFFF2"
        strokeWidth="1.5015"
      />
      <path
        d="M21.3784 11.5247L18.5877 8.73562C18.4586 8.60266 18.2837 8.52878 18.1002 8.5249H11.82C11.0533 8.5249 10.4243 9.15159 10.4243 9.92058V21.0791C10.4243 21.8496 11.0495 22.4748 11.82 22.4748H20.1902C20.9623 22.4748 21.5859 21.852 21.5859 21.0791V12.0138C21.5836 11.8287 21.5082 11.6538 21.3784 11.5247ZM18.1002 10.2068L19.9057 12.0122H18.1002V10.2068ZM20.1903 21.0791H11.82V9.91994H16.7029V12.0123C16.7029 12.7829 17.328 13.408 18.1001 13.408H20.1902L20.1903 21.0791Z"
        fill="#FFFFF2"
      />
      <path
        d="M18.0979 17.1479H13.9101C13.5259 17.1479 13.2134 17.4605 13.2134 17.8446C13.2134 18.2311 13.5259 18.5436 13.9101 18.5436H18.0979C18.482 18.5436 18.7945 18.2311 18.7945 17.8446C18.793 17.4582 18.482 17.1479 18.0979 17.1479Z"
        fill="#FFFFF2"
      />
      <path
        d="M13.9101 14.3574C13.5259 14.3574 13.2134 14.67 13.2134 15.0541C13.2134 15.4382 13.5259 15.7508 13.9101 15.7508H18.0979C18.482 15.7508 18.7945 15.4405 18.7945 15.0541C18.7945 14.6677 18.482 14.3574 18.0979 14.3574H13.9101Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
