import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import {
  SummaryTable as SummaryTableType,
  Archetype,
  LlmRequest,
} from "@dimensional-engineering/dimensional-models";

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

export async function dispatchUserProfileData(uid: string) {
  let summaryTableData: SummaryTableType | null = null;
  let adjectivesData: string[] | [] = [];
  let archetypeData: ArchetypeDataModel[] | null = null;

  const getSummaryTable = callFirebaseFunctions("fetchSummaryTable");
  await getSummaryTable({ uid: uid })
    .then((result) => {
      summaryTableData = result.data as SummaryTableType;
    })
    .catch((err) => {
      summaryTableData = null;
    });

  const getAdjectivesData = callFirebaseFunctions("fetchProfileAdjectives");
  await getAdjectivesData({ uid: uid }).then((result) => {
    const resultingData = result.data as any;
    adjectivesData = resultingData.adjectives.filter((adj: string) => {
      return adj !== "";
    }) as string[];
  });

  const getArchetypeData = callFirebaseFunctions("fetchProfileArchetypes");

  await getArchetypeData({ uid: uid })
    .then((result) => {
      if (result.data) {
        archetypeData = result.data as ArchetypeDataModel[];
      }
    })
    .catch(() => {
      archetypeData = null;
    });

  return {
    summaryTableData: summaryTableData,
    adjectivesData: adjectivesData,
    archetypeData: archetypeData,
  };
}

export function regenerateNutshell() {
  const nutshell = callFirebaseFunctions("updateProfileNutshell");
  return nutshell();
}

export function getLatestLLMRequest(objects: LlmRequest[]): LlmRequest | null {
  if (objects.length === 0) {
    return null;
  }

  const sortedObjects = [...objects].sort((a, b) => {
    return b.createdOn - a.createdOn;
  });

  return sortedObjects[0];
}

export function isTimestampWithin7Days(
  timestamp: number | undefined
): boolean | null {
  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const currentTimestamp = Date.now(); // Current timestamp in milliseconds

  // Check if the difference between the current timestamp and the provided timestamp
  // is less than or equal to seven days (in milliseconds)
  if (timestamp) {
    return currentTimestamp - timestamp <= sevenDaysInMilliseconds;
  } else {
    return null;
  }
}

export function isTimestampWithin1Day(
  timestamp: number | undefined
): boolean | null {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
  const currentTimestamp = Date.now(); // Current timestamp in milliseconds

  // Check if the difference between the current timestamp and the provided timestamp
  // is less than or equal to 1 day (in milliseconds)
  if (timestamp) {
    return currentTimestamp - timestamp <= oneDayInMilliseconds;
  } else {
    return null;
  }
}

export function isTimestampWithin1Hour(
  timestamp: number | undefined
): boolean | null {
  const currentTimestamp = Date.now();
  const oneHour = 60 * 60 * 1000;
  if (timestamp) {
    return currentTimestamp - timestamp <= oneHour;
  } else {
    return null;
  }
}

export async function fetchProfileResultSnippets(uid: string) {
  const call = callFirebaseFunctions("fetchProfileResultSnippets");
  return await call({ peerUid: uid });
}
