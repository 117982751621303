import { useRouter } from "next/router";
import { useState } from "react";
import { DiscoverableCompatibilitySnippet } from "@dimensional-engineering/dimensional-models";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import styles from "./CompatibilitySnippets.module.css";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";
import { useModalContext } from "@/context/ModalContext";
import Alert from "@/components/shared/Alerts/Alert";
import { computeCompatibilitySnippets } from "./utils";

import Loader from "@/components/shared/Loader";
import { Mixpanel } from "@/helpers/mixpanel";
import { useAlertContext } from "@/context/AlertContext";

export default function ErrorStateCompatibilitySnippet(props: {
  disabled: boolean;
  snippet: DiscoverableCompatibilitySnippet;
  connectionUid: string | null | undefined;
}) {
  const { setModalOpen } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const [loading, setLoading] = useState<boolean>(false);

  function handleClick() {
    if (props.disabled) {
      setAlertComponent(
        <Alert
          type="fail"
          message="Results computing. You can retry once every hour."
          elementName="Alert"
        />,
        7000
      );
    } else {
      Mixpanel?.track("Retry CTA Clicked", {
        content_type: "compatibility_snippet",
        slug: props.snippet?.snippetSlug,
      });
      setLoading(true);
      computeCompatibilitySnippets(
        props.snippet?.snippetSlug,
        props.connectionUid as string
      )
        .then(() => {
          setLoading(false);
          setModalOpen(false);
        })
        .catch((err) => {
          setLoading(false);
          setModalOpen(false);
        });
    }
  }

  if (loading) {
    return (
      <ModalWrapper backgroundColor="#2D2927" title="" noHeader noLine>
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper backgroundColor="#2D2927" title="" noLine>
      <section className={styles.generateModalMain}>
        <div className={styles.warningIcon}>
          <WarningIcon active />
        </div>
        <h3 style={{ marginTop: "30px" }} className={styles.generateContent}>
          Something went wrong.
        </h3>
        <button
          className={
            props.disabled ? styles.generateBtnDisabled : styles.generateBtn
          }
          onClick={() => handleClick()}
        >
          Retry
        </button>

        <p className={styles.computeBottomText}>Still not working?</p>
        <p className={styles.computeBottomTextTwo}>Contact the Support team</p>
      </section>
    </ModalWrapper>
  );
}
