import { Nature } from "@dimensional-engineering/dimensional-models";

export const ProfileColorMap: Record<Nature, string> = {
  warmthAndAgreeableness: "#E0CC65",
  tranquility: "#6AB4EA",
  orderAndStructure: "#A069CE",
  chaos: "#EF453C",
  energyAndExcitement: "#F37A1C",
  selfEnhancement: "#FFA0B9",
  opennessAndIntellect: "#5DAF4D",
};

export const ProfileSecondaryColorMap: Record<Nature, string> = {
  warmthAndAgreeableness: "#FDF65B",
  tranquility: "#4275EE",
  orderAndStructure: "#9026FF",
  chaos: "#F62626",
  energyAndExcitement: "#FF994C",
  selfEnhancement: "#ED85C2",
  opennessAndIntellect: "#1ACC6C",
};
