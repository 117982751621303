export const traitsMapper: any = {
  element: "Elements",
  pattern: "Patterns",
  archetype: "Archetypes",
  "personality-II": "Cognition",
  personality: "Primary Traits",
  values: "Values",
  "communication-style": "Interaction Styles",
  interests: "Interests",
  "attachment-style": "Attachment Styles",
  "love-style": "Love Styles",
  "love-attitudes": "Love Attitudes",
  "conflict-style": "Conflict Styles",
  strengths: "Strengths",
  chaos: "Passion & Sensitivity",
  opennessAndIntellect: "Openness & Curiosity",
  warmthAndAgreeableness: "Warmth & Agreeableness",
  orderAndStructure: "Order & Responsibility",
  tranquility: "Tranquility",
  energyAndExcitement: "Energy & Excitement",
  selfEnhancement: "Ambition & Self-Enhancement",
  "sex-attitudes": "Sex Attitudes",
  "political-ideology": "Political Ideology",
  "secondary-traits": "Secondary Traits",
  lifestyle: "Lifestyle",
  identity: "Identity",
  love: "Love",
  work: "Work",
  career: "Career",
  spirit: "Spirit",
};
