import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ArchetypeDiscovery,
  ElementDiscovery,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./CareerArchetypeBreakdown.module.css";

import { RootState } from "@/redux/store";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import Loader from "@/components/shared/Loader";
import ArchetypeBreakdownHeading from "../../Heading/ArchetypeBreakdownHeading";
import { ExtendedArchetype } from "@/models/sharedModels";

type Props = {
  own: boolean;
  publicProfile: PublicProfile | null;
  archetypeDiscovery: ArchetypeDiscovery | undefined;
};

type Score = number | null | undefined;

export default function CareerArchetypeBreakdown(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const [connectionScores, setConnectionScores] = useState<Score[]>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [loading, setLoading] = useState<boolean>(true);
  const scaleSlugs = useGetScaleSlugs({
    archetypeDiscovery: props.archetypeDiscovery,
  });

  async function getScaleScore(slug: string) {
    const fetchData = callFirebaseFunctions("fetchFriendElementDiscovery");
    return await fetchData({
      memberUUID: props.publicProfile?.ownerUUID,
      version: "2",
      traitSlug: slug,
    })
      .then((res) => {
        const data = res.data as ElementDiscovery;
        return data.scaleScore?.percentScore;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }

  useEffect(() => {
    async function getAllScores() {
      const promises = [
        getScaleScore("holland-artistic"),
        getScaleScore("holland-conventional"),
        getScaleScore("holland-enterprising"),
        getScaleScore("holland-investigative"),
        getScaleScore("holland-realistic"),
        getScaleScore("holland-social"),
      ];
      const results = await Promise.all(promises);
      return results;
    }
    if (!props.own) {
      getAllScores().then((res) => {
        setLoading(false);
        setConnectionScores(res as number[]);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.own]);

  const scaleData = [
    {
      slug: "holland-artistic",
      name: "Artistic",
      score: props.own
        ? userState.elementsDiscovered?.find(
            (el) => el.scaleSlug === "holland-artistic"
          )?.scaleScore?.percentScore
        : null,
    },
    {
      slug: "holland-conventional",
      name: "Conventional",
      score: props.own
        ? userState.elementsDiscovered?.find(
            (el) => el.scaleSlug === "holland-conventional"
          )?.scaleScore?.percentScore
        : null,
    },
    {
      slug: "holland-enterprising",
      name: "Enterprising",
      score: props.own
        ? userState.elementsDiscovered?.find(
            (el) => el.scaleSlug === "holland-enterprising"
          )?.scaleScore?.percentScore
        : null,
    },
    {
      slug: "holland-investigative",
      name: "Investigative",
      score: props.own
        ? userState.elementsDiscovered?.find(
            (el) => el.scaleSlug === "holland-investigative"
          )?.scaleScore?.percentScore
        : null,
    },
    {
      slug: "holland-realistic",
      name: "Realistic",
      score: props.own
        ? userState.elementsDiscovered?.find(
            (el) => el.scaleSlug === "holland-realistic"
          )?.scaleScore?.percentScore
        : null,
    },
    {
      slug: "holland-social",
      name: "Social",
      score: props.own
        ? userState.elementsDiscovered?.find(
            (el) => el.scaleSlug === "holland-social"
          )?.scaleScore?.percentScore
        : null,
    },
  ];

  const mappedScores = scaleData.map((row, index) => {
    let rowScore = props.own ? row.score : connectionScores[index];
    return (
      <div
        style={index === 0 ? { marginTop: "50px" } : {}}
        className={styles.barWrap}
        key={row.name}
      >
        <p className={styles.label} key={row.name}>
          {row.name}
        </p>
        <div
          style={rowScore ? { width: `${rowScore * 100 * 0.7}%` } : {}}
          className={
            scaleSlugs.includes(row.slug) ? styles.barActive : styles.bar
          }
        ></div>
      </div>
    );
  });

  if (loading) {
    return (
      <div className={styles.main}>
        <ArchetypeBreakdownHeading publicProfile={props.publicProfile} />
        <Loader mobileHeight="700px" height="700px" />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <ArchetypeBreakdownHeading publicProfile={props.publicProfile} />
      <div className={styles.wrapper}>
        <div className={styles.whiteLine}></div>
        {mappedScores}
        <p className={styles.warning}>
          Career Archetypes are determined by the two highest scoring Elements
          (highlighted) in the Interests Dimension.
        </p>
      </div>
    </div>
  );
}

export function useGetScaleSlugs(props: {
  archetypeDiscovery: ArchetypeDiscovery | undefined;
}) {
  const [scaleSlugs, setScaleSlugs] = useState<string[]>([]);

  useEffect(() => {
    setScaleSlugs([]);
    props.archetypeDiscovery?.scaleScores?.forEach((s) => {
      setScaleSlugs((current) => [...current, s.scaleSlug]);
    });
  }, [props.archetypeDiscovery]);

  return scaleSlugs;
}

type DetialProps = {
  archetype: ExtendedArchetype;
};

export function CareerArchetypeBreakdownDetailsView(props: DetialProps) {
  const scaleData = [
    {
      slug: "holland-artistic",
      name: "Artistic",
    },
    {
      slug: "holland-conventional",
      name: "Conventional",
    },
    {
      slug: "holland-enterprising",
      name: "Enterprising",
    },
    {
      slug: "holland-investigative",
      name: "Investigative",
    },
    {
      slug: "holland-realistic",
      name: "Realistic",
    },
    {
      slug: "holland-social",
      name: "Social",
    },
  ];

  const mappedScores = scaleData.map((row, index) => {
    return (
      <div
        style={index === 0 ? { marginTop: "50px" } : {}}
        className={styles.barWrap}
        key={row.name}
      >
        <p
          style={
            props.archetype.scaleSlugs.includes(row.slug)
              ? {}
              : { color: `#928E8C` }
          }
          className={styles.label}
          key={row.name}
        >
          {row.name}
        </p>
        <div
          style={
            props.archetype.scaleSlugs.includes(row.slug)
              ? { width: `70%` }
              : { width: "30%" }
          }
          className={
            props.archetype.scaleSlugs.includes(row.slug)
              ? styles.barActive
              : styles.bar
          }
        ></div>
      </div>
    );
  });

  return (
    <div className={styles.main}>
      <ArchetypeBreakdownHeading />
      <div className={styles.wrapper}>
        <div className={styles.whiteLine}></div>
        {mappedScores}
        <p className={styles.warning}>
          Career Archetypes are determined by the two highest scoring Elements
          (highlighted) in the Interests Dimension.
        </p>
      </div>
    </div>
  );
}
