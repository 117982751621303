import {
  CommentThread,
  DeliveredDailyInsight,
  InsightDelivery,
} from "@dimensional-engineering/dimensional-models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type DailyInsightSlice = {
  deliveredDailyInsight: DeliveredDailyInsight | null;
  insightDelivery: InsightDelivery | null;
  commentThread: CommentThread | null;
};

const initialState: DailyInsightSlice = {
  deliveredDailyInsight: null,
  insightDelivery: null,
  commentThread: null,
};

export const dailyInsight = createSlice({
  name: "dailyInsight",
  initialState: initialState,
  reducers: {
    setDeliveredDailyInsight: (
      state,
      action: PayloadAction<DeliveredDailyInsight | null>
    ) => {
      return { ...state, deliveredDailyInsight: action.payload };
    },
    setInsightDelivery: (state, action: PayloadAction<InsightDelivery>) => {
      return { ...state, insightDelivery: action.payload };
    },
    setCommentThread: (state, action: PayloadAction<CommentThread>) => {
      return { ...state, commentThread: action.payload };
    },
  },
});

export const {
  setDeliveredDailyInsight,
  setInsightDelivery,
  setCommentThread,
} = dailyInsight.actions;

export default dailyInsight.reducer;
