export default function DimensionStatusIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34162_27609)">
        <path
          d="M21.75 21.75C23.675 19.825 23.675 16.5 22.1 13C23.675 9.5 23.675 6.175 21.75 4.25C19.825 2.325 16.5 2.325 13 3.9C9.5 2.325 6.175 2.325 4.25 4.25C2.325 6.175 2.325 9.5 3.9 13C2.2375 16.4125 2.2375 19.7375 4.25 21.75C6.2625 23.7625 9.5 23.675 13 22.1C16.5 23.675 19.825 23.675 21.75 21.75ZM20 6C20.7875 6.7875 20.9625 8.3625 20.4375 10.2875C19.7375 9.4125 19.0375 8.5375 18.25 7.75C17.4625 6.9625 16.5875 6.2625 15.7125 5.5625C17.6375 5.0375 19.2125 5.2125 20 6ZM16.5 16.5C15.3625 17.6375 14.1375 18.5125 13 19.3C11.8625 18.5125 10.6375 17.6375 9.5 16.5C8.3625 15.3625 7.4875 14.1375 6.7 13C7.4875 11.8625 8.3625 10.6375 9.5 9.5C10.6375 8.3625 11.8625 7.4875 13 6.7C14.1375 7.4875 15.3625 8.3625 16.5 9.5C17.6375 10.6375 18.5125 11.8625 19.3 13C18.5125 14.1375 17.6375 15.3625 16.5 16.5ZM6 6C6.7875 5.2125 8.3625 5.0375 10.2875 5.5625C9.4125 6.2625 8.5375 6.9625 7.75 7.75C6.9625 8.5375 6.2625 9.4125 5.5625 10.2875C5.0375 8.3625 5.2125 6.7875 6 6ZM6 20C5.2125 19.2125 5.0375 17.6375 5.5625 15.7125C6.2625 16.5875 6.9625 17.4625 7.75 18.25C8.5375 19.0375 9.4125 19.7375 10.2875 20.4375C8.3625 20.9625 6.7875 20.7875 6 20ZM15.7125 20.4375C16.5875 19.7375 17.4625 19.0375 18.25 18.25C19.0375 17.4625 19.7375 16.5875 20.4375 15.7125C20.9625 17.6375 20.7875 19.2125 20 20C19.2125 20.7875 17.6375 20.9625 15.7125 20.4375Z"
          fill="#FFFFF2"
        />
        <path
          d="M14.75 14.7498C13.7834 15.7163 12.2166 15.7163 11.25 14.7498C10.2834 13.7832 10.2834 12.2164 11.25 11.2498C12.2166 10.2832 13.7834 10.2832 14.75 11.2498C15.7166 12.2164 15.7166 13.7832 14.75 14.7498Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_34162_27609">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
