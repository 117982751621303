export default function PredictionOfFutureTogetherIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8694 20.914C21.9738 21.1043 22.1734 21.2203 22.3892 21.2203C22.605 21.2203 22.8046 21.1043 22.909 20.914L24.0972 18.793L26.2182 17.6048C26.4062 17.5004 26.5245 17.3031 26.5245 17.085C26.5245 16.8692 26.4061 16.6719 26.2182 16.5652L24.0972 15.377L22.909 13.256V13.2583C22.8046 13.0681 22.605 12.9497 22.3892 12.9497C22.1734 12.9497 21.9738 13.0681 21.8694 13.2583L20.6812 15.3794L18.5602 16.5675V16.5652C18.3699 16.6719 18.2539 16.8692 18.2539 17.085C18.2539 17.3031 18.3699 17.5004 18.5602 17.6048L20.6812 18.793L21.8694 20.914Z"
        fill="#E0CC65"
      />
      <path
        d="M9.27524 4.63762C9.27524 4.41948 9.15689 4.21991 8.96428 4.11548L6.84326 2.92734L5.65511 0.806317C5.55069 0.616029 5.35344 0.5 5.1353 0.5C4.91948 0.5 4.72223 0.616029 4.61548 0.806317L3.42734 2.92734L1.30632 4.11548H1.30864C1.11835 4.21991 1 4.41948 1 4.6353C1 4.85111 1.11835 5.05068 1.30864 5.15511L3.42966 6.34326L4.61781 8.46428H4.61548C4.72223 8.65225 4.91948 8.77059 5.1353 8.77059C5.35344 8.77059 5.55069 8.65224 5.65511 8.46428L6.84326 6.34326L8.96428 5.15511C9.15457 5.05068 9.27292 4.85343 9.27524 4.63762Z"
        fill="#ED85C2"
      />
      <path
        d="M33.4668 7.60786C30.7006 5.28028 27.1963 4.01556 23.5808 4.04342C22.9844 4.04342 22.3903 4.07823 21.7986 4.14553C17.7236 4.62357 14.0061 6.70515 11.467 9.92848C8.92797 13.1518 7.77499 17.2522 8.26463 21.3276C8.75426 25.4029 10.8452 29.1129 14.0754 31.6448V34.9355C14.0754 36.9033 15.6719 38.4999 17.6398 38.4999H29.5213C31.4892 38.4999 33.0857 36.9033 33.0857 34.9355V31.6448C36.7639 28.7696 38.9452 24.3858 39.0195 19.717C39.0938 15.048 37.0521 10.5966 33.4668 7.60786ZM17.4618 28.259L17.0047 27.9017C14.6493 26.0637 13.1687 23.3231 12.9227 20.3456C12.6791 17.366 13.6909 14.4234 15.7167 12.2235C17.7403 10.0259 20.5923 8.77506 23.5811 8.77506C26.5698 8.77506 29.422 10.0259 31.4454 12.2235C33.4713 14.4234 34.4831 17.3659 34.2394 20.3456C33.9934 23.323 32.5129 26.0636 30.1575 27.9017L29.7003 28.259C28.8371 28.9343 28.3311 29.9716 28.3335 31.067V33.7473H18.8283V31.067C18.8306 29.9716 18.3251 28.9344 17.4618 28.259Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
