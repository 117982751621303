import { useEffect, useState } from "react";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./TraitAccessDeniedBar.module.css";

import PrivateIcon from "@/components/shared/icons/PrivateIcon";
import CloseConnectionIcon from "@/components/shared/icons/CloseConnectionIcon";
import DeepConnectionIcon from "@/components/shared/icons/DeepConnectionIcon";
import { useModalContext } from "@/context/ModalContext";
import CloseConnectionModal from "@/components/connections/ConnectionRequestModal/CloseConnectionModal";
import DeepConnectionModal from "@/components/connections/ConnectionRequestModal/DeepConnectionModal";
import { RootState } from "@/redux/store";

type Props = {
  connectionTypeNeeded: "basic" | "close" | "deep" | "private";
  publicProfile: PublicProfile | null | undefined;
  noPadding?: boolean;
};

export default function TraitAccessDeniedBar(props: Props) {
  const { setModalComponent } = useModalContext();
  const [sent, setsent] = useState<boolean>(false);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (props.connectionTypeNeeded === "close") {
      if (
        props.publicProfile?.ownerUUID &&
        userState.isCloseRequestSentUidList.includes(
          props.publicProfile?.ownerUUID
        )
      ) {
        setsent(true);
      } else {
        setsent(false);
      }
    } else if (props.connectionTypeNeeded === "deep") {
      if (
        props.publicProfile?.ownerUUID &&
        userState.isDeepRequestSentUidList.includes(
          props.publicProfile?.ownerUUID
        )
      ) {
        setsent(true);
      } else {
        setsent(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.publicProfile?.ownerUUID, props.connectionTypeNeeded]);

  if (props.connectionTypeNeeded === "private") {
    return (
      <div
        style={props.noPadding ? { padding: "0" } : {}}
        className={styles.main}
      >
        <div className={styles.wrapper}>
          <div className={styles.iconDiv}>
            <PrivateIcon />
          </div>
          <p className={styles.text}>
            Connection scores are not available on private traits.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={props.noPadding ? { padding: "0" } : {}}
      className={styles.main}
    >
      <div className={styles.wrapper}>
        {props.connectionTypeNeeded === "close" ? (
          <div className={styles.connectionIconDiv}>
            <CloseConnectionIcon active />
          </div>
        ) : (
          <div className={styles.connectionIconDiv}>
            <DeepConnectionIcon active />
          </div>
        )}
        <p className={styles.connectionText}>
          {props.connectionTypeNeeded === "close"
            ? "Score only available to close connections."
            : "Score only available to deep connections."}
        </p>
        <button
          onClick={() => {
            if (props.publicProfile && sent == false) {
              if (props.connectionTypeNeeded === "close") {
                setModalComponent(
                  <CloseConnectionModal
                    fromContentLock
                    publicProfile={props.publicProfile}
                  />
                );
              } else {
                setModalComponent(
                  <DeepConnectionModal
                    fromContentLock
                    publicProfile={props.publicProfile}
                  />
                );
              }
            }
          }}
          className={sent === true ? styles.buttonPending : styles.button}
        >
          {sent === true ? "Pending" : "Request"}
        </button>
      </div>
    </div>
  );
}
