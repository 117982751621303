export default function HigherThanAllConnectionsIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25.7303"
        cy="34.0809"
        r="7.16912"
        transform="rotate(-180 25.7303 34.0809)"
        fill="#ED85C2"
      />
      <circle
        cx="42.9364"
        cy="25.4783"
        r="7.16912"
        transform="rotate(-180 42.9364 25.4783)"
        fill="#E0CC65"
      />
      <circle
        cx="25.7303"
        cy="15.9188"
        r="7.16912"
        transform="rotate(-180 25.7303 15.9188)"
        fill="#5FA9DF"
      />
      <path
        d="M0.977552 36.397C1.74049 37.1587 2.97583 37.1587 3.73755 36.397L13.1772 26.9573H13.1785C13.9402 26.1944 13.9402 24.959 13.1785 24.1961L3.73877 14.7564H3.73755C2.96852 14.0398 1.76977 14.0606 1.02515 14.804C0.281739 15.5486 0.260989 16.7474 0.977539 17.5164L9.0341 25.5767L0.977539 33.637C0.21582 34.3988 0.215833 35.6341 0.977552 36.397Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
