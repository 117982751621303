import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/redux/store";

export default function useConnectionStatus(uid: string | null | undefined) {
  const [friendStatus, setFriendStatus] = useState<
    "connected" | "close" | "deep" | "sent" | "unconnected" | "received" | null
  >(null);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (uid) {
      if (userState.deepFriendUidList.includes(uid)) {
        setFriendStatus("deep");
      } else if (userState.closeFriendUidList.includes(uid)) {
        setFriendStatus("close");
      } else if (userState.friendUidList.includes(uid)) {
        setFriendStatus("connected");
      } else if (userState.friendRequestsSentUidList.includes(uid)) {
        setFriendStatus("sent");
      } else if (userState.friendRequestRecievedUidsList.includes(uid)) {
        setFriendStatus("received");
      } else {
        setFriendStatus("unconnected");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uid,
    userState.friendUidList.length,
    userState.friendRequestRecievedUidsList.length,
    userState.friendRequestsSentUidList.length,
  ]);

  return friendStatus;
}
