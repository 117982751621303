/* eslint-disable @next/next/no-img-element */
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  DiscoverableSnippet,
  PeerAssessmentLink,
} from "@dimensional-engineering/dimensional-models";
import Image from "next/image";

import styles from "./PeerFeedbackModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import RewardsScoreBar from "../../rewards/RewardsScoreBar";
import { PeerFeedbackIconSmall } from "@/components/shared/icons/PeerFeedbackIcon";
import RewardsMappedImages from "../../rewards/RewardsMappedImages";
import RewardsCheckmark from "../../rewards/RewardsCheckmark";
import { PeerFeedbackPillSmall } from "../PeerFeedbackPill/PeerFeedbackPillElement";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import RequestFeedbackModal from "../RequestFeedbackModal/RequestFeedbackModal";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { generatePeerAssessmentDynamicLinkAndroid } from "@/_firebase/generateDymaicLink";
import { Mixpanel } from "@/helpers/mixpanel";
import { SmallLoader } from "@/components/shared/Loader";
import SnippetBlock from "@/components/results/Snippets/SnippetBlock";
import { useGetDiscoveredSnippetSlugs } from "@/components/results/Snippets/Snippets";
import SnippetsModal from "@/components/results/Snippets/SnippetsModal";
import { snippetsMap } from "@/components/results/Snippets/snippetsMap";
import SnippetDiscoveryGate from "@/components/shared/DiscoveryGate/SnippetDiscoveryGate";
import { summonProfileLink } from "@/components/shared/utils";
import { useAuth } from "@/context/AuthContext";

export default function PeerFeedbackModal() {
  const { setModalComponent, setModalOpen } = useModalContext();
  const { setAlertComponent } = useAlertContext();
  const userState = useSelector((state: RootState) => state.user);
  const discoveredSlugs = useGetDiscoveredSnippetSlugs();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  const discoverableSnippetNature = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-peer-rated-nature"
  ) as DiscoverableSnippet;
  const discoverableSnippetPrimaryTraits = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-peer-rated-primary-traits"
  ) as DiscoverableSnippet;
  const discoverableSnippetCognition = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-peer-rated-cognition"
  ) as DiscoverableSnippet;
  const discoverableSnippetStrenghts = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-peer-rated-strengths"
  ) as DiscoverableSnippet;
  const discoverableSnippetInterractionStyle = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-peer-rated-interaction-style"
  ) as DiscoverableSnippet;
  const discoverableSnippetOverEstimatedTraits = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-overestimated-traits"
  ) as DiscoverableSnippet;
  const discoverableSnippetUnderEstimatedTraits = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-underestimated-traits"
  ) as DiscoverableSnippet;
  const discoverableSnippetBlindspots = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-your-blindspots"
  ) as DiscoverableSnippet;
  const discoverableSnippetOthersSeeYou = snippetsMap?.find(
    (s) => s?.snippetSlug === "snippet-how-others-see-you"
  ) as DiscoverableSnippet;

  const levelMapper: Record<number, JSX.Element[]> = {
    1: [
      <Image
        key={1}
        src="/peerFeedbackPlaceHolderImages/levelOneImage.png"
        alt=""
        width={345}
        height={155}
        quality={100}
      />,
    ],
    2: [
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-peer-rated-nature")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-peer-rated-nature"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetNature?.snippetSlug}
                snippetType={discoverableSnippetNature.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetNature.discoveryGate.requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetNature.discoveryGate.numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetNature.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetNature.discoveryGate.numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetNature.discoveryGate.requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetNature}
        key={2}
        greyedOut={
          !discoveredSlugs.includes("snippet-peer-rated-primary-traits")
        }
      />,
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-peer-rated-primary-traits")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-peer-rated-primary-traits"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetPrimaryTraits?.snippetSlug}
                snippetType={discoverableSnippetPrimaryTraits.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetPrimaryTraits.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetPrimaryTraits.discoveryGate
                    .numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetPrimaryTraits.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetPrimaryTraits.discoveryGate
                    .numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetPrimaryTraits.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetPrimaryTraits}
        key={3}
        greyedOut={
          !discoveredSlugs.includes("snippet-peer-rated-primary-traits")
        }
      />,
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-peer-rated-cognition")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-peer-rated-cognition"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetCognition?.snippetSlug}
                snippetType={discoverableSnippetCognition.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetCognition.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetCognition.discoveryGate.numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetCognition.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetCognition.discoveryGate.numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetCognition.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetCognition}
        key={4}
        greyedOut={!discoveredSlugs.includes("snippet-peer-rated-cognition")}
      />,
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-peer-rated-strengths")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-peer-rated-strengths"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetStrenghts?.snippetSlug}
                snippetType={discoverableSnippetStrenghts.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetStrenghts.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetStrenghts.discoveryGate.numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetStrenghts.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetStrenghts.discoveryGate.numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetStrenghts.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetStrenghts}
        key={5}
        greyedOut={!discoveredSlugs.includes("snippet-peer-rated-strengths")}
      />,
      <SnippetBlock
        onClick={() => {
          if (
            discoveredSlugs.includes("snippet-peer-rated-interaction-style")
          ) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-peer-rated-interaction-style"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetInterractionStyle?.snippetSlug}
                snippetType={discoverableSnippetInterractionStyle.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetInterractionStyle.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetInterractionStyle.discoveryGate
                    .numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetInterractionStyle.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetInterractionStyle.discoveryGate
                    .numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetInterractionStyle.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetInterractionStyle}
        key={6}
        greyedOut={
          !discoveredSlugs.includes("snippet-peer-rated-interaction-style")
        }
      />,
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-overestimated-traits")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-overestimated-traits"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={
                  discoverableSnippetOverEstimatedTraits?.snippetSlug
                }
                snippetType={discoverableSnippetOverEstimatedTraits.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetOverEstimatedTraits.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetOverEstimatedTraits.discoveryGate
                    .numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetOverEstimatedTraits.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetOverEstimatedTraits.discoveryGate
                    .numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetOverEstimatedTraits.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetOverEstimatedTraits}
        key={7}
        greyedOut={!discoveredSlugs.includes("snippet-overestimated-traits")}
      />,
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-underestimated-traits")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-underestimated-traits"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={
                  discoverableSnippetUnderEstimatedTraits?.snippetSlug
                }
                snippetType={
                  discoverableSnippetUnderEstimatedTraits.snippetType
                }
                requiredDimensionSlugs={
                  discoverableSnippetUnderEstimatedTraits.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetUnderEstimatedTraits.discoveryGate
                    .numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetUnderEstimatedTraits.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetUnderEstimatedTraits.discoveryGate
                    .numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetUnderEstimatedTraits.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetUnderEstimatedTraits}
        key={8}
        greyedOut={!discoveredSlugs.includes("snippet-underestimated-traits")}
      />,
    ],
    3: [
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-your-blindspots")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-your-blindspots"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetBlindspots?.snippetSlug}
                snippetType={discoverableSnippetBlindspots.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetBlindspots.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetBlindspots.discoveryGate.numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetBlindspots.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetBlindspots.discoveryGate.numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetBlindspots.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetBlindspots}
        key={9}
        greyedOut={!discoveredSlugs.includes("snippet-your-blindspots")}
      />,
      <SnippetBlock
        onClick={() => {
          if (discoveredSlugs.includes("snippet-how-others-see-you")) {
            setModalComponent(
              <SnippetsModal
                discoveredSnippetSlug={"snippet-how-others-see-you"}
              />
            );
          } else {
            return setModalComponent(
              <SnippetDiscoveryGate
                snippetSlug={discoverableSnippetOthersSeeYou?.snippetSlug}
                snippetType={discoverableSnippetOthersSeeYou.snippetType}
                requiredDimensionSlugs={
                  discoverableSnippetOthersSeeYou.discoveryGate
                    .requiredDimensionSlugs
                }
                numFriendsRequired={
                  discoverableSnippetOthersSeeYou.discoveryGate
                    .numFriendsRequired
                }
                numPeerAssessorsRequired={
                  discoverableSnippetOthersSeeYou.discoveryGate
                    .numPeerAssessorsRequired
                }
                onClose={() => {
                  setModalOpen(false);
                }}
                onOpenShareLink={() => {
                  summonProfileLink(
                    user?.uid,
                    "gating modal",
                    "Invite to Dimensional"
                  );
                }}
                snippetConnection={
                  discoverableSnippetOthersSeeYou.discoveryGate
                    .numFriendsRequired
                    ? true
                    : false
                }
                snippetDimension={
                  discoverableSnippetOthersSeeYou.discoveryGate
                    .requiredDimensionSlugs
                    ? true
                    : false
                }
              />
            );
          }
        }}
        snippet={discoverableSnippetOthersSeeYou}
        key={10}
        greyedOut={!discoveredSlugs.includes("snippet-how-others-see-you")}
      />,
    ],
    4: [
      <Image
        width={345}
        height={173}
        quality={100}
        key={7}
        alt=""
        src="/peerFeedbackPlaceHolderImages/levelFourImage.png"
      />,
    ],
  };

  function handleLinkGeneration() {
    Mixpanel?.track("Peer Assessment Request Sent", { type: "share link" });
    setLoading(true);
    const linkCall = callFirebaseFunctions("createPeerAssessmentLink");
    linkCall()
      .then((res) => {
        const peerAssessmentLink: { peerAssessmentLink: PeerAssessmentLink } =
          res.data as any;
        generatePeerAssessmentDynamicLinkAndroid(
          peerAssessmentLink.peerAssessmentLink.id
        )
          .then(async (link) => {
            if (window && window.Android) {
              window.Android.onSystemShare(
                link,
                false,
                "other",
                "Invite to Dimensional"
              );
              setLoading(false);
              Mixpanel?.track("System Share Modal Opened", {
                type: "Peer assessment request",
                source: "peer feedback modal",
              });
            } else {
              navigator.clipboard.writeText(link);
              setLoading(false);
              setAlertComponent(
                <Alert
                  elementName="Alert"
                  type="success"
                  message="Copied to clipboard"
                />,
                3000
              );
            }
          })
          .catch((fallback) => {
            navigator.clipboard.writeText(fallback);
            setLoading(false);
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="success"
                message="Copied to clipboard"
              />,
              3000
            );
          });
      })
      .catch((err) => {
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="fail"
            message="Failed to generate link"
          />,
          3000
        );
      });
  }

  return (
    <ModalWrapper title="Peer assessment rewards">
      <div className={styles.modalMain}>
        <div style={{ cursor: "auto" }} className={styles.feedbackBarMain}>
          <p className={styles.barTitle}>
            Peer assessment reward level {userState.peerFeedbackLevel}
          </p>
          <div className={styles.rewardsLevelParent}>
            <RewardsScoreBar level={userState.peerFeedbackLevel} />
          </div>
          <div className={styles.barMidDiv}>
            <PeerFeedbackIconSmall />
            <p className={styles.barMidDivText}>
              PEERS WHO HAVE ASSESSED YOU:{" "}
              {userState.memberProfile?.peerAssesseeUids?.length ?? 0}
            </p>
          </div>
          <div className={styles.imageWrapper}>
            {
              <RewardsMappedImages
                uidList={userState.memberProfile?.peerAssesseeUids}
              />
            }
          </div>
          <button
            onClick={() => {
              setModalComponent(<RequestFeedbackModal />);
            }}
            className={styles.askConnectionBUtton}
          >
            Ask a connection to assess you
          </button>
          <p className={styles.askSomeoneText}>
            Ask someone not on Dimensional to assess you
          </p>
          <div className={styles.linkDiv}>
            <input placeholder="Their name" className={styles.linkInput} />
            <button
              onClick={() => {
                if (loading) {
                  return;
                }
                handleLinkGeneration();
              }}
              className={styles.linkButton}
              disabled={loading}
              style={loading ? { padding: "0" } : { padding: "5px 7px" }}
            >
              {loading ? <SmallLoader /> : "Generate invite link"}
            </button>
          </div>
          <p className={styles.linkWarning}>
            You will automatically be connected when they sign up.
          </p>
        </div>

        <div className={styles.mid}>
          <h2 className={styles.modalTitle}>Reward levels</h2>

          <div
            style={{ marginTop: "26.5px" }}
            className={styles.levelHeaderWrapper}
          >
            <h3 className={styles.levelHeading}>Level 1</h3>
            <RewardsCheckmark checked={userState.peerFeedbackLevel >= 1} />
          </div>
          <div className={styles.levelSubDiv}>
            <PeerFeedbackPillSmall numOfPeerAssessmentsReceived={3} />
            <p className={styles.levelSubTitle}>
              Requires assessments from 3 peers.
            </p>
          </div>
          <div className={styles.levelWrapper}>
            {levelMapper[1].map((c) => {
              return c;
            })}
          </div>

          <div className={styles.levelHeaderWrapper}>
            <h3 className={styles.levelHeading}>Level 2</h3>
            <RewardsCheckmark checked={userState.peerFeedbackLevel >= 2} />
          </div>
          <div className={styles.levelSubDiv}>
            <PeerFeedbackPillSmall numOfPeerAssessmentsReceived={4} />
            <p className={styles.levelSubTitle}>
              Requires assessments from 4 peers.
            </p>
          </div>
          <div className={styles.levelWrapper}>
            {levelMapper[2].map((c) => {
              return c;
            })}
          </div>

          <div className={styles.levelHeaderWrapper}>
            <h3 className={styles.levelHeading}>Level 3</h3>
            <RewardsCheckmark checked={userState.peerFeedbackLevel >= 3} />
          </div>
          <div className={styles.levelSubDiv}>
            <PeerFeedbackPillSmall numOfPeerAssessmentsReceived={6} />
            <p className={styles.levelSubTitle}>
              Requires assessments from 6 peers.
            </p>
          </div>
          <div className={styles.levelWrapper}>
            {levelMapper[3].map((c) => {
              return c;
            })}
          </div>

          <div className={styles.levelHeaderWrapper}>
            <h3 className={styles.levelHeading}>Level 4</h3>
            <RewardsCheckmark checked={userState.peerFeedbackLevel >= 4} />
          </div>
          <div className={styles.levelSubDiv}>
            <PeerFeedbackPillSmall numOfPeerAssessmentsReceived={7} />
            <p className={styles.levelSubTitle}>
              Requires assessments from 7 peers.
            </p>
          </div>
          <div className={styles.levelWrapper}>
            {levelMapper[4].map((c) => {
              return c;
            })}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
