export default function BringRelationshipIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.182 31.0075L32.5023 36.1265V36.1285C31.7133 36.9918 30.6 37.482 29.4301 37.482H22.8991C22.3249 37.482 21.8581 37.0152 21.8581 36.441C21.8581 35.8648 22.3248 35.4 22.8991 35.4H28.5571C29.309 35.4156 30.0297 35.0992 30.5278 34.5367C31.0258 33.9722 31.2504 33.2164 31.1411 32.4722L31.1274 32.4077C30.7426 30.5112 29.0746 29.1499 27.1391 29.1499H18.3791C17.2756 29.1499 16.217 29.5874 15.4357 30.3687L13.7111 32.0952H13.7131C12.9318 32.8765 11.8732 33.3159 10.7697 33.3159H8.33223C7.77948 33.3159 7.25018 33.5367 6.85958 33.9273C6.46898 34.3179 6.25018 34.8472 6.25018 35.3999V43.7339V43.732C6.25018 44.8824 7.18183 45.814 8.33223 45.816H31.2227C33.8673 45.816 36.3537 44.5582 37.9202 42.4312L43.1292 35.3647H43.1312C44.2249 33.7202 43.8323 31.5073 42.2386 30.3412C40.6448 29.1752 38.4163 29.4701 37.1821 31.0092L37.182 31.0075Z"
        fill="#FFFFF2"
      />
      <path
        d="M33.8708 12.0413C33.2746 10.9576 29.5371 7.32881 25.2045 12.5826C20.6545 7.32881 17.1333 10.9576 16.5382 12.0413C15.4545 14.0451 16.1045 17.0788 17.6219 18.5413L25.2055 26.125L32.7892 18.5413C34.3045 17.0788 34.9545 14.0462 33.8708 12.0413Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
