import { useEffect } from "react";
import { db } from "@/_firebase/firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "@/context/AuthContext";
import { useDispatch } from "react-redux";
import { setNotificationPemrission } from "@/redux/slices/userSlice";
import { Mixpanel } from "./mixpanel";

export function useGetFCMTokenHook() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const LSFcmToken = localStorage.getItem("fcmToken");
  const LSNotificationGranted = localStorage.getItem("isNotificationGranted");

  useEffect(() => {
    console.log("Log from useGetFCMTokenHook. Token is: ", LSFcmToken);
    if (
      LSFcmToken &&
      user?.uid &&
      LSFcmToken !== "null" &&
      LSFcmToken !== "hello"
    ) {
      const ref = doc(db, `/members/${user?.uid}`);
      updateDoc(ref, { fcmToken: LSFcmToken })
        .then((res) => {
          console.log("Updated DB for FCM Token");
        })
        .catch((err: any) => {
          console.log("Error updating db for FCM token", err);
        });
    } else {
      console.log("Not able to update db", LSFcmToken, user?.uid);
    }
  }, [LSFcmToken, user?.uid]);

  useEffect(() => {
    if (LSNotificationGranted) {
      console.log("Notification status:", LSNotificationGranted);
      dispatch(
        setNotificationPemrission({
          value: JSON.parse(LSNotificationGranted),
          uid: user?.uid,
        })
      );
    }
  }, [user?.uid, LSNotificationGranted]);
}
