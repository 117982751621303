import { useEffect, useState } from "react";
import { ConnectionAvailability } from "@dimensional-engineering/dimensional-models";

import styles from "./InteractionBar.module.css";

import { useAuth } from "@/context/AuthContext";
import CompareIcon from "@/components/shared/icons/CompareIcon";
import TraitsRateIcon from "@/components/shared/icons/TraitsRateIcon";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import { useModalContext } from "@/context/ModalContext";
import ContextSwitcherModal from "../../ContextSwitcherModal/ContextSwitcherModal";
import ComparisonModal from "../ComparisonModal/ComparisonModal";
import elementJsonData from "../../../../assets/traitInfo/elements.json";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import { getUserScaleScore, traitPermissionStatus } from "../../utils";

type Props = {
  type: "element" | "pattern" | "archetype";
  ownProfile: boolean;
  friendScore?: number | null | undefined;
  histogramData?: number[] | null;
  connectionAvailabilities?: ConnectionAvailability[] | null | undefined;
  isPremium?: boolean | null | undefined;
  slug: string;
  uid: string;
};

export default function InteractionBar(props: Props) {
  const { user } = useAuth();
  const imageUrl = useGetProfilePhoto(props.uid);
  const publicProfile = useGetPublicProfile(props.uid);
  const friendStatus = useConnectionStatus(props.uid);
  const { setModalComponent } = useModalContext();
  const [isRateAble, setIsRateAble] = useState<boolean>(false);
  const [status, setStatus] = useState<
    "close" | "deep" | "private" | "basic" | null
  >(null);

  useEffect(() => {
    if (!props.ownProfile && props.connectionAvailabilities) {
      setStatus(
        traitPermissionStatus(friendStatus, props.connectionAvailabilities)
      );
    } else {
      setStatus(null);
    }
  }, [friendStatus, props.connectionAvailabilities, props.ownProfile]);

  useEffect(() => {
    if (props.type === "element") {
      elementJsonData.forEach((el) => {
        if (el.slug === props.slug) {
          if (el.isRateable) {
            setIsRateAble(true);
          } else {
            setIsRateAble(false);
          }
        }
      });
    }
  }, [props.slug, props.type]);

  return (
    <section className={styles.main}>
      <div className={styles.wrapper}>
        {/* <div className={styles.column}>
          <MessageIcon />
          <p className={styles.columnText}>Message</p>
        </div> */}
        {props.uid !== user?.uid &&
          props.type !== "archetype" &&
          status === null && (
            <div
              onClick={() => {
                setModalComponent(
                  <ComparisonModal
                    friendUid={props.uid as string}
                    slug={props.slug as string}
                    type={props.type}
                    friendScore={getUserScaleScore(
                      props.friendScore,
                      props.slug as string
                    )}
                    histogramData={props.histogramData}
                    isPremium={props.isPremium}
                  />
                );
              }}
              className={styles.column}
            >
              <CompareIcon />
              <p className={styles.columnText}>Compare</p>
            </div>
          )}
        {/* {!props.ownProfile && props.type === "element" && isRateAble && (
          <div
            onClick={() => {
              return;
            }}
            className={styles.column}
          >
            <TraitsRateIcon />
            <p className={styles.columnText}>Rate</p>
          </div>
        )} */}
        <div
          onClick={() => {
            setModalComponent(
              <ContextSwitcherModal
                elementName="ContextSwitcherModal"
                type={props.type}
                slug={props.slug}
              />
            );
          }}
          className={styles.lastColumn}
        >
          <SmallProfileImage
            imageUrl={imageUrl}
            publicProfile={publicProfile}
            noLink
          />
          <p>
            {publicProfile?.userName
              ? publicProfile.userName.length > 10
                ? `@${publicProfile.userName.slice(0, 7)}...`
                : `@${publicProfile.userName}`
              : null}
          </p>
        </div>
      </div>
    </section>
  );
}
