import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type AlertComponent = {
  component: JSX.Element;
  timeout: number;
};

type AlertContextModel = {
  alertComponent: AlertComponent[];
  setAlertComponent: (value: JSX.Element, timeout: number) => void;
  emptyAlerts: () => void;
  removeAlert: () => void;
  isAlertOpen: boolean;
};

const ModalDefaultValue: AlertContextModel = {
  alertComponent: [],
  setAlertComponent: () => {},
  emptyAlerts: () => {},
  removeAlert: () => {},
  isAlertOpen: false,
};

type Props = {
  children: ReactNode;
};

const AlertContext = createContext<AlertContextModel>(ModalDefaultValue);

export function useAlertContext() {
  return useContext(AlertContext);
}

export function AlertContextProvider({ children }: Props) {
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [alertComponent, setAlertJSXComponent] = useState<AlertComponent[]>([]);

  useEffect(() => {
    if (alertComponent.length === 0) {
      setIsAlertOpen(false);
    } else {
      setIsAlertOpen(true);
      const current = alertComponent[0];
      setTimeout(() => {
        removeAlert();
      }, current.timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertComponent.length]);

  function setAlertComponent(value: JSX.Element, timeout: number) {
    for (let i = 0; i < alertComponent.length; i++) {
      if (alertComponent[i].component.props.message === value.props.message) {
        ///removing duplicates
        return;
      }
    }

    //for duration, look at the following: https://www.notion.so/Toast-types-and-durations-dc3da74e9f244c5e9aa621bd7c19fdac?pvs=4
    setAlertJSXComponent((current) => [
      ...current,
      { component: value, timeout: timeout },
    ]);
    if (window.Android) {
      window.Android.onHaptic();
    }
  }

  function emptyAlerts() {
    setAlertJSXComponent([]);
  }

  function removeAlert() {
    const alertDiv = document.getElementById("alertMain");
    if (alertDiv) {
      alertDiv.style.top = "-100px";
    }
    setTimeout(() => {
      if (alertDiv) {
        alertDiv.style.top = "20px";
      }
      setAlertJSXComponent((current) => {
        const newArray = current.slice(1);
        return newArray;
      });
    }, 200);
  }

  const value = {
    alertComponent: alertComponent,
    setAlertComponent: setAlertComponent,
    emptyAlerts: emptyAlerts,
    removeAlert: removeAlert,
    isAlertOpen: isAlertOpen,
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
}
