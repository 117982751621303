import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { FriendsPublicProfile } from "@/models/sharedModels";
import {
  CommentThread,
  DeliveredDailyInsight,
  DeliveredDailyStoryNew,
  DiscoverableStory,
  PeerAssessmentEvent,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

export function getProfileHashValue(uuid: string): number {
  const prime = 31;
  let hashValue = 0;

  for (const char of uuid) {
    const charValue = char.charCodeAt(0);
    hashValue = (hashValue * prime + charValue) & 0x7fffffff;
  }

  return Math.abs(hashValue);
}

export async function getDefaultProfilePhoto(uuid: string) {
  const totalMale = 19;
  const totalFemale = 40;
  const totalThem = 23;
  const publicProfileRef = doc(db, "publicProfiles", uuid);
  const document = await getDoc(publicProfileRef);
  const hashValue = Math.abs(getProfileHashValue(uuid));
  let data: PublicProfile | null = null;
  if (document.exists()) {
    data = document.data() as PublicProfile;
  }
  let num: number = 0;
  if (
    data !== null &&
    data.memberGender !== null &&
    data.memberGender !== undefined
  ) {
    if (data.memberGender === "male") {
      num = hashValue % (totalMale + totalThem);
      if (num < totalThem) {
        return `/default-images/them-${num + 1}.jpg`;
      } else {
        return `/default-images/him-${num - totalThem + 1}.jpg`;
      }
    } else if (data.memberGender === "female") {
      num = hashValue % (totalFemale + totalThem);
      if (num < totalThem) {
        return `/default-images/them-${num + 1}.jpg`;
      } else {
        return `/default-images/her-${num - totalThem + 1}.jpg`;
      }
    } else {
      num = hashValue % totalThem;
      return `/default-images/them-${num + 1}.jpg`;
    }
  } else {
    num = hashValue % totalThem;
    return `/default-images/them-${num + 1}.jpg`;
  }
}

export async function getAllStories() {
  let stories: DiscoverableStory[] = [];
  const storiesQuery = query(collection(db, "discoverableListicleStories"));
  try {
    const storiesSnapshot = await getDocs(storiesQuery);
    storiesSnapshot.forEach((doc) => {
      stories.push(doc.data() as DiscoverableStory);
    });
  } catch (error) {
    console.log(error);
  }

  return stories;
}

export async function handleConnections(userUid: string) {
  const reference = doc(db, `members/${userUid}/accessMaps/friends`);
  const document = await getDoc(reference);
  if (document.exists()) {
    let list: string[] = Object.keys(document.data().accessMap);
    let connectionList: FriendsPublicProfile[] = [];
    return await Promise.all(
      list.map(async (id) => {
        let profileRef = doc(db, "publicProfiles", id);
        await getDoc(profileRef).then(async (res) => {
          if (res.exists()) {
            let docData = res.data() as FriendsPublicProfile;
            try {
              const url = await getDownloadURL(
                ref(
                  firebaseStorage,
                  `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${id}/cropped.jpg`
                )
              );
              docData.imageUrl = url;
            } catch (err) {
              const value = await getDefaultProfilePhoto(id);
              docData.imageUrl = value;
            }
            connectionList.push(docData);
          }
        });
      })
    ).then((res) => {
      return connectionList;
    });
  }
}

export function isExpired(expirationDate: string): boolean {
  const expirationTime = Date.parse(expirationDate);
  const currentTime = Date.now();

  return expirationTime <= currentTime;
}

export const whitelistedNotifications: string[] = [
  "newFeatureAvailable",
  "contactListDiscovery",
  "peerPackageRequest",
  "connectionRequestReceived",
  "connectionRequestAccepted",
  "connectionCompletedPeerAssessment",
  "connectionNatureDiscovered",
  "commentLiked",
  "commentReplied",
  "rewardLevelObtained",
  "successfulInviteSignUp",
  "typeformSurveyReceived",
  "premiumPurchaseConfirmed",
  "scoresUpdated",
  "highRatingReceived",
  "peerRatingRequestReceived",
  "dailyReflectionReceived",
  "nudgeReceived",
  "surveyLinkReceived",
  "connectionCompletedPeerAssessment",
  "peerAssessmentRequestReceived",
  "viewPeriodicQuiz",
  "weeklyPublicProfileViews",
];

export function handlePeerAssessmentRecommendationSort(
  friendUidList: string[] | undefined,
  closeFriendUidList: string[],
  deepFriendUidList: string[],
  peerAssessmentEvents: PeerAssessmentEvent[]
): string[] | [] {
  if (!friendUidList || friendUidList.length === 0) {
    return [];
  }
  const uidListOfPeerEventsStartedOrCompleted = peerAssessmentEvents.map(
    (event) => event.assesseeUid
  );
  const filteredFriendList = friendUidList.filter(
    (uid) => !uidListOfPeerEventsStartedOrCompleted.includes(uid)
  );

  const sortedUidList = filteredFriendList.sort((a, b) => {
    //Deep connections first, then close, then basic
    let order = 0;

    const isADeep = deepFriendUidList.includes(a);
    const isBDeep = deepFriendUidList.includes(b);

    const isAClose = closeFriendUidList.includes(a);
    const isBClose = closeFriendUidList.includes(b);

    if (isADeep && !isBDeep) {
      order = -1;
    } else if (isBDeep && !isADeep) {
      order = 1;
    }

    if (order === 0) {
      if (isAClose && !isBClose) {
        order = -1;
      } else if (isBClose && !isAClose) {
        order = 1;
      }
    }

    return order;
  });

  return sortedUidList;
}

export function mod10(uid: string | undefined): boolean {
  if (!uid) {
    return false;
  }
  //gives us a mod 10 uid
  let hash = 0;
  for (let i = 0; i < uid.length; i++) {
    hash = uid.charCodeAt(i) + ((hash << 5) - hash);
  }

  const positiveHash = Math.abs(hash);

  return positiveHash % 10 === 0;
}

export async function getDeliveredDailyInsight(
  uid: string | undefined,
  insightId: string
): Promise<DeliveredDailyInsight | null> {
  const ref = doc(db, `/members/${uid}/deliveredDailyInsights/${insightId}`);
  return await getDoc(ref)
    .then((res) => {
      if (res.exists()) {
        return res.data() as DeliveredDailyInsight;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
}

export async function gerDeliveredDailyStory(
  uid: string | undefined,
  deliveryId: string
) {
  const ref = doc(db, `/members/${uid}/deliveredDailyStoriesNew/${deliveryId}`);
  return await getDoc(ref)
    .then((res) => {
      if (res.exists()) {
        return res.data() as DeliveredDailyStoryNew;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
}

export async function getCommentThread(threadId: string) {
  const ref = doc(db, `/commentThreads/${threadId}`);
  return await getDoc(ref)
    .then((res) => {
      if (res.exists()) {
        return res.data() as CommentThread;
      } else {
        return null;
      }
    })
    .catch((err) => {
      return null;
    });
}
