import { useState } from "react";
import {
  PublicProfile,
  ContentIdentifier,
} from "@dimensional-engineering/dimensional-models";

import styles from "./NudgeModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "../Alerts/Alert";
import { Mixpanel } from "@/helpers/mixpanel";

export default function NudgeModal(props: {
  publicProfile: PublicProfile | null;
  content?: ContentIdentifier;
  archetypeGroupSlug?: string;
}) {
  const { setModalOpen } = useModalContext();
  const { setAlertComponent } = useAlertContext();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const handleClick = async () => {
    setButtonDisabled(true);
    if (!props.content && !props.archetypeGroupSlug) {
      return;
    }
    const nudgeCall = callFirebaseFunctions("nudgeConnection");
    return nudgeCall(
      JSON.stringify({
        connectionUid: props.publicProfile?.ownerUUID,
        contentIdentifier: props.content,
        archetypeGroupSlug: props.archetypeGroupSlug,
      })
    )
      .then(() => {
        Mixpanel?.track("Nudge Sent", {
          content_slug: props.content?.contentSlug,
          nudgee_UID: props.publicProfile?.ownerUUID,
        });
        setModalOpen(false);
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="success"
            message={`Your nudge has been sent to @${props.publicProfile?.userName}.`}
          />,
          5000
        );
      })
      .catch((err) => {
        setButtonDisabled(false);
        console.log(err);
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="fail"
            message="Something went wrong. Please try again"
          />,
          7000
        );
      });
  };

  return (
    <ModalWrapper title="" noHeader backgroundColor="#2d2927">
      <div className={styles.content}>
        <div onClick={() => setModalOpen(false)} className={styles.closeBtn}>
          <CloseButtonSummary />
        </div>
        <div className={styles.mid}>
          <p
            className={styles.text}
          >{`@${props.publicProfile?.userName} has not completed the necessary Dimensions.`}</p>
          <button
            disabled={buttonDisabled}
            onClick={handleClick}
            className={buttonDisabled ? styles.btnDisabled : styles.btn}
          >
            Nudge
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
