import { DiscoveredCompatibilitySnippet } from "@dimensional-engineering/dimensional-models";
import styles from "../CompatibilitySnippets.module.css";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
};

export default function CompatibilityLlmListDetailView(props: Props) {
  const mapped = props.snippet?.snippetDetail.llmListDetail?.map((d, i) => {
    return (
      <div
        style={i === 0 ? { marginTop: "15px" } : {}}
        className={styles.llmListDiv}
        key={i}
      >
        <p className={styles.tileNumber}>~ {i + 1} ~</p>
        <h4 className={styles.llmListTitle}>{d.title}</h4>
        <p className={styles.llmListParagraph}>{d.paragraph}</p>
      </div>
    );
  });

  return (
    <div style={{ marginTop: "35px" }} className={styles.spectrumsParent}>
      {mapped}
    </div>
  );
}
