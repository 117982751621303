import mixpanel from "mixpanel-browser";
let token = process.env.NEXT_PUBLIC_MIX_PANEL_TOKEN;
let actions;
if (token) {
  mixpanel.init(token, {
    opt_out_tracking_by_default: false,
    opt_out_persistence_by_default: false,
  });
  actions = {
    track: (name: string, props: any) => {
      if (window.Android) {
        window.Android.trackEvent(name, JSON.stringify(props));
      } else {
        mixpanel.track(name, props, (callback) => {
          console.log(callback);
        });
      }
    },
    identify: (id: string) => {
      mixpanel.identify(id);
    },
    people: {
      set: (props: any, id: string) => {
        if (window.Android) {
          window.Android.setPeople(JSON.stringify(props), id);
        }
      },
      update: (props: any, id?: string) => {
        if (window.Android) {
          window.Android.setPeople(JSON.stringify(props), id);
        }
      },
      delete: () => {
        if (window.Android) {
          window.Android.deletePeople();
        } else {
          mixpanel.people.delete_user();
        }
      },
    },
  };
}

export const Mixpanel = actions;
