import { TabIconModel } from "@/models/sharedModels";

export default function TabResultsIcon(props: TabIconModel) {
  return (
    <svg
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0.681818C0.5 0.30526 0.80526 0 1.18182 0H14.8182C15.1947 0 15.5 0.30526 15.5 0.681818V20.7331C15.5 21.2762 14.8959 21.6013 14.4426 21.3021L8.37557 17.2979C8.14776 17.1475 7.85224 17.1475 7.62443 17.2979L1.55739 21.3021C1.10409 21.6013 0.5 21.2762 0.5 20.7331V0.681818Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}
