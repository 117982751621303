import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import elementsJson from "../../../../assets/traitInfo/elements.json";
import patternsJson from "../../../../assets/traitInfo/patterns.json";

import styles from "./ComparisonModal.module.css";

import { useModalContext } from "@/context/ModalContext";
import Element from "@/components/traits/element/Element/Element";
import Pattern from "@/components/traits/pattern/Pattern/Pattern";
import { RootState } from "@/redux/store";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import { getUserPatternScore, getUserScaleScore } from "../../utils";
import CompareHistogram from "../../detailsTab/histogram/CompareHistogram";
import ScoreRowNonReport from "@/components/premium/reports/you/MotivationalTheme/ScoreRowNonReport";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import ComparedSmallProfileImage from "@/components/shared/SmallProfileImage/ComparedSmallProfileImage";
import useGetIsPremium from "@/helpers/useGetIsPremium";
import ScoreRowPremium from "@/components/premium/reports/you/MotivationalTheme/ScoreRowPremium";
import ElementModal from "../../TraitsModals/Element/ElementModal";
import PatternModal from "../../TraitsModals/Pattern/PatternModal";

type Props = {
  slug: string;
  friendUid: string;
  type: "element" | "pattern" | "archetype";
  histogramData: number[] | null | undefined;
  friendScore: number | null | undefined;
  isPremium?: boolean | null | undefined;
};

export default function ComparisonModal(props: Props) {
  const { setModalComponent } = useModalContext();
  const userState = useSelector((state: RootState) => state.user);
  const friendPublicProfile = useGetPublicProfile(props.friendUid as string);
  const friendIMageUrl = useGetProfilePhoto(props.friendUid as string);
  const [score, setScore] = useState<number | null | undefined>();
  const [description, setDiscription] = useState<string>("");
  const isFriendPremium = useGetIsPremium(props.friendUid);

  useEffect(() => {
    if (props.type === "element") {
      userState.elementsDiscovered?.forEach((el) => {
        if (el.scaleSlug === props.slug) {
          setScore(
            getUserScaleScore(el.scaleScore?.percentScore, el.scaleSlug)
          );
        }
      });
      setDiscription(
        elementsJson?.find((el) => el.slug === props.slug)?.shortDescription ??
          ""
      );
    } else {
      userState.patternsDiscovered?.forEach((patt) => {
        if (patt.patternSlug === props.slug) {
          setScore(getUserPatternScore(patt.percentScore, patt.patternSlug));
        }
      });
      setDiscription(
        patternsJson?.find((el) => el.slug === props.slug)?.shortDescription ??
          ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper
      title="Comparison"
      subTitle={`@${userState.publicProfileData?.userName} and @${friendPublicProfile?.userName}`}
      backgroundColor="#2d2927"
    >
      {props.type === "element" ? (
        <div
          className={styles.element}
          onClick={() => setModalComponent(<ElementModal slug={props.slug} />)}
          style={{ cursor: "pointer" }}
        >
          <Element slug={props.slug} />
        </div>
      ) : (
        <div
          className={styles.element}
          onClick={() => setModalComponent(<PatternModal slug={props.slug} />)}
          style={{ cursor: "pointer" }}
        >
          <Pattern patternSlug={props.slug} />
        </div>
      )}
      <p className={styles.description}>{description}</p>
      <div className={styles.histogramDiv}>
        <CompareHistogram
          data={props.histogramData}
          users={[
            {
              score: props.friendScore,
              imageUrl: friendIMageUrl,
              publicProfile: friendPublicProfile,
            },
            {
              score: score,
              imageUrl: userState.imageURL,
              publicProfile: userState.publicProfileData,
            },
          ]}
        />
      </div>
      <div className={styles.scoreDiv}>
        <div style={{ borderBottom: "0" }} className={styles.personDiv}>
          <SmallProfileImage
            imageUrl={userState.imageURL ?? ""}
            publicProfile={userState.publicProfileData}
          />
          <div className={styles.scoreNameDiv}>
            <p className={styles.name}>
              {userState.publicProfileData?.name ??
                userState.publicProfileData?.userName}
            </p>
            {props.isPremium && !userState.isPremium ? (
              <ScoreRowPremium />
            ) : (
              <ScoreRowNonReport score={score ? score * 100 : score} />
            )}
          </div>
        </div>
        <div className={styles.personDiv}>
          <ComparedSmallProfileImage
            imageUrl={friendIMageUrl}
            publicProfile={friendPublicProfile}
          />
          <div className={styles.scoreNameDiv}>
            <p className={styles.name}>
              {friendPublicProfile?.name ?? friendPublicProfile?.userName}
            </p>
            {props.isPremium && !isFriendPremium ? (
              <ScoreRowPremium />
            ) : (
              <ScoreRowNonReport
                score={
                  props.friendScore
                    ? props.friendScore * 100
                    : props.friendScore
                }
              />
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
