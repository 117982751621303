import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { DiscoveredCompatibilitySnippet } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "../CompatibilitySnippets.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";
import { RootState } from "@/redux/store";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import ThickForwardArrow from "@/components/shared/buttons/ThickForwardArrow";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilityCarouselCardDetailView(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [connectionSwiperRef, setConnectionSwiperRef] = useState<any>(null);
  const [myQuestionIndex, setMyQuestionIndex] = useState<number>(0);
  const [connectionQuestionIndex, setConnectionQuestionIndex] =
    useState<number>(0);
  const fillArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  function onCircleClick(myQuestions: boolean, index: number) {
    if (myQuestions === true && mySwiperRef) {
      setMyQuestionIndex(index);
      mySwiperRef.slideTo(index);
    } else {
      if (connectionSwiperRef) {
        setConnectionQuestionIndex(index);
        connectionSwiperRef.slideTo(index);
      }
    }
  }

  const mappedMyQuestions =
    props.snippet?.snippetDetail.llmCarouselCardsDetail?.myQuestions.map(
      (q, i) => {
        return (
          <SwiperSlide key={i}>
            <div className={styles.headersListCard}>
              <p className={styles.cardQuestionNumber}>Question {i + 1}</p>
              <p className={styles.caredQuestion}>{q}</p>
            </div>
          </SwiperSlide>
        );
      }
    );

  const mappedConnectionQuestions =
    props.snippet?.snippetDetail.llmCarouselCardsDetail?.connectionQuestions.map(
      (q, i) => {
        return (
          <SwiperSlide key={i}>
            <div className={styles.headersListCard}>
              <p className={styles.cardQuestionNumber}>Question {i + 1}</p>
              <p className={styles.caredQuestion}>{q}</p>
            </div>
          </SwiperSlide>
        );
      }
    );

  const mappedMyQuestionIndex = fillArray.map((n, i) => {
    return (
      <div
        onClick={() => onCircleClick(true, i)}
        key={i}
        className={myQuestionIndex === i ? styles.circleActive : styles.circle}
      ></div>
    );
  });

  const mappedConnectionQuestionIndex = fillArray.map((n, i) => {
    return (
      <div
        onClick={() => onCircleClick(false, i)}
        key={i}
        className={
          connectionQuestionIndex === i ? styles.circleActive : styles.circle
        }
      ></div>
    );
  });

  return (
    <div style={{ marginTop: "35px" }} className={styles.spectrumsParent}>
      <div style={{ marginTop: "15px" }} className={styles.cardsSection}>
        <div className={styles.cardsViewTop}>
          <SmallProfileImage
            imageUrl={userState.imageURL ?? "/default-image.png"}
            publicProfile={userState.publicProfileData}
            medium
            noLink
          />
          <div className={styles.arrowDiv}>
            <ThickForwardArrow />
          </div>
          <ComparedMediumProfileImage
            imageUrl={props.connection?.imageUrl ?? "/default-image.png"}
            publicProfile={props.connection}
            noLink
          />
        </div>
        <p className={styles.headerdListAsk}>
          @{userState.publicProfileData?.userName?.toUpperCase()} ASKS @
          {props.connection?.userName?.toUpperCase()}
        </p>
        <div>
          <Swiper
            min-height={200}
            autoHeight={false}
            slidesPerView={1}
            touchReleaseOnEdges={true}
            onSlideChange={(e) => {
              setMyQuestionIndex(e.activeIndex);
            }}
            scrollbar={{ draggable: true }}
            threshold={1}
            onSwiper={setMySwiperRef}
          >
            {mappedMyQuestions}
          </Swiper>
        </div>
        <div className={styles.circleDiv}>{mappedMyQuestionIndex}</div>
      </div>

      <div className={styles.cardsSection}>
        <div className={styles.cardsViewTop}>
          <ComparedMediumProfileImage
            imageUrl={props.connection?.imageUrl ?? "/default-image.png"}
            publicProfile={props.connection}
            noLink
          />
          <div className={styles.arrowDiv}>
            <ThickForwardArrow />
          </div>
          <SmallProfileImage
            imageUrl={userState.imageURL ?? "/default-image.png"}
            publicProfile={userState.publicProfileData}
            medium
            noLink
          />
        </div>
        <p className={styles.headerdListAsk}>
          @{props.connection?.userName?.toUpperCase()} ASKS @
          {userState.publicProfileData?.userName?.toUpperCase()}
        </p>
        <div>
          <Swiper
            autoHeight={false}
            min-height={200}
            slidesPerView={1}
            touchReleaseOnEdges={true}
            onSlideChange={(e) => {
              setConnectionQuestionIndex(e.activeIndex);
            }}
            scrollbar={{ draggable: true }}
            threshold={1}
            onSwiper={setConnectionSwiperRef}
          >
            {mappedConnectionQuestions}
          </Swiper>
        </div>
        <div className={styles.circleDiv}>{mappedConnectionQuestionIndex}</div>
      </div>
    </div>
  );
}
