import {
  ExtendedArchetype,
  ExtendedPattern,
  ExtendedScale,
} from "@/models/sharedModels";
import {
  ArchetypeDiscovery,
  ElementDiscovery,
  PatternDiscovery,
} from "@dimensional-engineering/dimensional-models";

import {
  getUserPatternScore as getPatternScore,
  getUserScaleScore,
} from "@/components/traits/utils";

export function getUserArchetypeScore(
  slug: string,
  archetypesDiscovered: ArchetypeDiscovery[] | null
): number | undefined {
  let score;
  archetypesDiscovered?.forEach((arch) => {
    if (arch.archetype.slug === slug) {
      score = arch.archetypeScore;
    }
  });
  return score;
}

export function getUserPatternScore(
  slug: string,
  patternsDiscovered: PatternDiscovery[] | null
): number | undefined {
  let score;
  patternsDiscovered?.forEach((el) => {
    if (el.patternSlug === slug) {
      score = getPatternScore(el.percentScore, el.patternSlug);
    }
  });
  return score;
}

export function getUserElementScore(
  slug: string,
  elementsDiscovered: ElementDiscovery[] | null
): number | undefined {
  let score;
  elementsDiscovered?.forEach((el) => {
    if (el.scaleSlug === slug) {
      score = getUserScaleScore(el.scaleScore?.percentScore, el.scaleSlug);
    }
  });
  return score;
}

export function getUserElementSignature(
  slug: string,
  elementDiscovered: ElementDiscovery[] | null
): boolean | undefined {
  let isSignature;
  elementDiscovered?.forEach((el) => {
    if (el.scaleSlug === slug) {
      isSignature = el.scaleScore?.isSignature;
    }
  });
  if (isSignature !== undefined) {
    return isSignature;
  } else {
    return false;
  }
}

export function sortArchetypes(
  sortType: string,
  a: ExtendedArchetype,
  b: ExtendedArchetype,
  archetypesDiscovered: ArchetypeDiscovery[] | null
) {
  if (sortType === "a-z") {
    const nameA =
      a.archetypeGroupSlug !== "work"
        ? a.acronym ?? a.name
        : `${a.name} (${a.acronym})`;

    const nameB =
      b.archetypeGroupSlug !== "work"
        ? b.acronym ?? b.name
        : `${b.name} (${b.acronym})`;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  } else {
    let scoreSlugs: string[] = [];
    let scoreMap: any = {};
    archetypesDiscovered?.forEach((arch) => {
      scoreSlugs.push(arch.archetypeSlug);
      scoreMap[arch.archetypeSlug] = arch.archetypeScore;
    });
    if (scoreSlugs.includes(a.slug) && scoreSlugs.includes(b.slug)) {
      ///both have scores
      if (scoreMap[a.slug] > scoreMap[b.slug]) {
        return -1;
      } else if (scoreMap[a.slug] < scoreMap[b.slug]) {
        return 1;
      } else {
        return 0;
      }
    } else if (scoreSlugs.includes(a.slug) && !scoreSlugs.includes(b.slug)) {
      return -1;
    } else if (!scoreSlugs.includes(a.slug) && scoreSlugs.includes(b.slug)) {
      return 1;
    } else {
      return 0;
    }
  }
}

export function sortPatterns(
  sortType: string,
  a: ExtendedPattern,
  b: ExtendedPattern,
  patternsDiscovered: PatternDiscovery[] | null
) {
  if (sortType === "a-z") {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  } else {
    let scoreSlugs: string[] = [];
    let scoreMap: Record<string, number> = {};
    patternsDiscovered?.forEach((patt) => {
      if (patt.patternScore !== null && patt.patternScore !== undefined) {
        scoreSlugs.push(patt.patternSlug);
        scoreMap[patt.patternSlug] = patt.patternScore;
      }
    });
    if (scoreSlugs.includes(a.slug) && scoreSlugs.includes(b.slug)) {
      ///both have scores
      if (scoreMap[a.slug] > scoreMap[b.slug]) {
        return -1;
      } else if (scoreMap[a.slug] < scoreMap[b.slug]) {
        return 1;
      } else {
        return 0;
      }
    } else if (scoreSlugs.includes(a.slug) && !scoreSlugs.includes(b.slug)) {
      return -1;
    } else if (!scoreSlugs.includes(a.slug) && scoreSlugs.includes(b.slug)) {
      return 1;
    } else {
      return 0;
    }
  }
}

export function sortElements(
  sortType: string,
  a: ExtendedScale,
  b: ExtendedScale,
  elementsDiscovered: ElementDiscovery[] | null
) {
  if (sortType === "a-z") {
    const aSortableName = a.alias || a.name;
    const bSortableName = b.alias || b.name;

    if (aSortableName < bSortableName) {
      return -1;
    } else if (aSortableName > bSortableName) {
      return 1;
    } else {
      return 0;
    }
  } else if (sortType === "score") {
    let scoreSlugs: string[] = [];
    let scoreMap: any = {};
    elementsDiscovered?.forEach((el) => {
      scoreSlugs.push(el.scaleSlug);
      scoreMap[el.scaleSlug] = getUserScaleScore(
        el.scaleScore?.percentScore,
        el.scaleSlug
      );
    });
    if (scoreSlugs.includes(a.slug) && scoreSlugs.includes(b.slug)) {
      ///both have scores
      if (scoreMap[a.slug] > scoreMap[b.slug]) {
        return -1;
      } else if (scoreMap[a.slug] < scoreMap[b.slug]) {
        return 1;
      } else {
        return 0;
      }
    } else if (scoreSlugs.includes(a.slug) && !scoreSlugs.includes(b.slug)) {
      return -1;
    } else if (!scoreSlugs.includes(a.slug) && scoreSlugs.includes(b.slug)) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (a.nature && b.nature && a.nature < b.nature) {
      return -1;
    }
    if (a.nature && b.nature && a.nature > b.nature) {
      return 1;
    }
    return 0;
  }
}

export function filterElements(
  a: ExtendedScale,
  filterType: string,
  elementsDiscovered: ElementDiscovery[] | null
) {
  if (filterType === "DISCOVERED") {
    let scoreSlugs: string[] = [];
    elementsDiscovered?.forEach((el) => {
      scoreSlugs.push(el.scaleSlug);
    });
    if (scoreSlugs.includes(a.slug)) {
      return true;
    } else {
      return false;
    }
  } else if (filterType === "PREMIUM") {
    return a.isPremium === true;
  } else {
    return true;
  }
}

export function showPremiumScore(
  isPremium: boolean,
  userPremiumStatus: boolean
): boolean {
  if (isPremium === true) {
    if (userPremiumStatus === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export const traitEncylopediaSortOptions: Record<string, string[]> = {
  elements: ["score", "nature", "a-z"],
  patterns: ["score", "a-z"],
  archetypes: ["score", "a-z"],
};

export function scrollOnTraitSearch() {
  const el = document.getElementById("rightBarMain");
  if (el) {
    window.scrollTo({ top: el.offsetHeight - 300, behavior: "auto" });
  }
}
