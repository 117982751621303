import { AppInfo } from "@dimensional-engineering/dimensional-models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type AppInfoSlice = {
  appInfo: AppInfo | null;
};

const initialState: AppInfoSlice = {
  appInfo: null,
};

export const appInfoSlice = createSlice({
  name: "appInfo",
  initialState: initialState,
  reducers: {
    setAppInfo: (state, action: PayloadAction<AppInfo>) => {
      return { ...state, appInfo: action.payload };
    },
  },
});

export const { setAppInfo } = appInfoSlice.actions;

export default appInfoSlice.reducer;
