export default function HowOthersSeeYouSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <g clipPath="url(#clip0_56619_30495)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.0419922 19.6458L1.32605 22.0156H11.3589L5.43361 32.2796H33.2407L29.2407 25.3525H39.5603L38.1922 22.9827H27.8727L27.3154 22.0174H37.6349L36.2669 19.6477H25.9473L25.3901 18.6824H35.7096L34.3416 16.3126H24.022L19.338 8.19971L12.7287 19.6477H-0.0419922V19.6458ZM15.4651 19.6458H19.3384C19.9929 19.6458 20.5241 20.1771 20.5241 20.8316C20.5241 21.4862 19.9929 22.0174 19.3384 22.0174H14.097L9.5397 29.9098H29.137L19.3384 12.9374L15.4651 19.6458Z"
          fill="#FFFFF2"
        />
        <path
          d="M36.0001 18.6667H25.3334L23.5557 16H34.2223L36.0001 18.6667Z"
          fill="#E0CC65"
        />
        <path
          d="M38.1339 22.2223H27.4672L25.6895 19.5557H36.3561L38.1339 22.2223Z"
          fill="#ED85C2"
        />
        <path
          d="M40.0001 25.5998H29.3334L27.5557 22.9331H38.2223L40.0001 25.5998Z"
          fill="#5FA9DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_56619_30495">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
