import Image from "next/image";
import { useRouter } from "next/router";

import styles from "./ProfileImage.module.css";

import { useModalContext } from "@/context/ModalContext";
import NatureModal from "@/components/authenticatedProfile/NatureModal/NatureModal";
import NatureModalConnection from "@/components/authenticatedProfile/NatureModal/NatureModalConnection";

type props = {
  color: string;
  url: string | null;
  clickable?: boolean;
  notOwn?: boolean;
  transparentOutline?: boolean;
  largeMobile?: boolean;
  quality?: number;
};

export default function ProfileImage(props: props) {
  const { setModalComponent } = useModalContext();
  const router = useRouter();
  const { slug } = router.query;

  return (
    <div
      className={
        props.largeMobile ? styles.imageContainerLarge : styles.imageContainer
      }
      style={{ borderColor: `${props.color}` }}
    >
      <Image
        onClick={() => {
          if (props.clickable) {
            if (props.notOwn) {
              return setModalComponent(
                <NatureModalConnection
                  uid={slug as string}
                  imageUrl={props.url ? props.url : "/default-image.png"}
                />
              );
            } else {
              return setModalComponent(<NatureModal />);
            }
          } else {
            return;
          }
        }}
        priority
        className={styles.image}
        fill
        src={props.url ? props.url : "/default-image.png"}
        alt="Profile Image"
        style={props.clickable ? { cursor: "pointer" } : {}}
        quality={props.quality ?? 50}
      />
    </div>
  );
}

//NEED TO CHANGE PROPS OF QUALITY FOR AUTH BAR
