/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { Roboto_Mono } from "next/font/google";
import { useSelector } from "react-redux";

import styles from "./AnalysisHistogram.module.css";

import { ProfileColorMap } from "@/components/shared/ColorMap";
import { RootState } from "@/redux/store";

type HistogramProps = {
  data: number[] | null | undefined;
  averageUsrScore?: number | null | undefined;
  score?: number;
  name: string;
  caption: string | null | undefined;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function AnalysisHistogram(props: HistogramProps) {
  const [multiplier, setMultiplier] = useState<number>(0);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let high = 0;
    if (props.data) {
      props.data.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(240 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.data?.map((point, index) => {
    return (
      <div
        className={styles.histogramBar}
        style={{ height: `${point * 100 * multiplier}px` }}
        key={index}
      ></div>
    );
  });
  return (
    <div className={styles.main}>
      {props.score && (
        <div className={styles.scoreDescDiv}>
          <div className={styles.scoreBarDiv}>
            <h3 className={styles.improvementTitle}>{props.name}</h3>
            <h3 className={`${styles.scoreNumber} ${font.className}`}>
              {(props.score * 100).toFixed(0)}
            </h3>
          </div>
          <div className={styles.scoreBarParent}>
            <div className={styles.scoreBarEmpty}></div>
            <div
              style={{ width: `${props.score * 100}%` }}
              className={styles.scoreBar}
            ></div>
          </div>
          {props.caption && <p className={styles.scorePara}>{props.caption}</p>}
        </div>
      )}
      <div className={styles.histogramParent}>
        {props.averageUsrScore && (
          <div
            style={{ right: `${props.averageUsrScore * 100}%` }}
            className={styles.averageScoreDiv}
          >
            <div className={styles.averageScoreLine}></div>
            <div className={styles.averageScoreCircle}></div>
          </div>
        )}
        {props.score && (
          <div
            style={{ right: `${props.score * 100}%` }}
            className={styles.averageScoreDiv}
          >
            <div
              style={
                userState.publicProfileData?.primaryNature
                  ? {
                      backgroundColor: `${
                        ProfileColorMap[
                          userState.publicProfileData?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { backgroundColor: "#55504F" }
              }
              className={styles.userScoreLine}
            ></div>
            <div
              style={
                userState.publicProfileData?.primaryNature
                  ? {
                      border: `3px solid ${
                        ProfileColorMap[
                          userState.publicProfileData?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: "3px solid #55504F" }
              }
              className={styles.userScoreCircle}
            >
              <img src={userState.imageURL as string} alt="" />
            </div>
          </div>
        )}
        {mapped}
      </div>
      <div className={styles.labels}>
        <p>0</p>
        <p>25</p>
        <p>50</p>
        <p>75</p>
        <p>100</p>
      </div>
      <div className={styles.subTitleDiv}>
        <div className={styles.avScoreLabelCircle}></div>
        <h4>Average score of all users</h4>
      </div>
    </div>
  );
}
