import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityPoliticalIdeologyIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6899 9.84396C18.6899 12.1901 17.6141 14.3995 15.929 15.8763C14.6059 17.0357 13.3287 18.425 13.1063 20.17L13.0574 20.5534C12.8104 22.4912 14.0567 24.2663 15.4337 25.6519C15.711 25.931 15.9666 26.2318 16.1977 26.5515C16.7326 27.2917 17.536 27.8464 18.4493 27.8464H19.1882C20.4337 27.8464 21.3129 26.5884 21.3129 25.3428C21.3129 21.1946 24.6757 17.355 28.8238 17.355C32.972 17.355 36.3348 21.1946 36.3348 25.3428C36.3348 29.491 32.972 32.8538 28.8238 32.8538C28.5566 32.8538 28.2927 32.8398 28.0327 32.8126C27.2981 32.7357 26.5652 32.6153 25.8266 32.6153H24.8297C20.5797 32.6153 16.8722 36.7741 12.6845 37.4993C12.2139 37.5808 11.7117 37.6226 11.1789 37.6226C7.03074 37.6226 3.66797 34.2598 3.66797 30.1116C3.66797 27.6573 4.46952 25.7701 5.98329 24.6381C7.679 23.37 9.43931 21.6447 9.1716 19.5443C8.99806 18.1826 8.02108 17.091 6.91923 16.2723C4.95518 14.8131 3.66797 12.4104 3.66797 9.84396C3.66797 5.69577 7.03074 2.33301 11.1789 2.33301C15.3271 2.33301 18.6899 5.69577 18.6899 9.84396Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
