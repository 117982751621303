/* eslint-disable @next/next/no-img-element */
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import styles from "./SmallProfileImage.module.css";
import { ProfileColorMap } from "../ColorMap";
import Link from "next/link";
import { useModalContext } from "@/context/ModalContext";

export default function VerySmallProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
}) {
  const { emptyModal } = useModalContext();
  if (props.noLink) {
    return (
      <div
        className={styles.verySmallImageContatiner}
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `3px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `3px solid #55504F`,
              }
        }
      >
        <img alt="" src={props.imageUrl} className={styles.verySmallImage} />
      </div>
    );
  }

  return (
    <Link
      href={`/app/profile/${props.publicProfile?.ownerUUID}`}
      onClick={() => emptyModal()}
      className={styles.verySmallImageContatiner}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `3px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      <img alt="" src={props.imageUrl} className={styles.verySmallImage} />
    </Link>
  );
}
