import { useSelector } from "react-redux";
import Link from "next/link";
import { Roboto_Mono } from "next/font/google";

import styles from "./ConnectionTab.module.css";

import Histogram from "../detailsTab/histogram/Histogram";
import { RootState } from "@/redux/store";
import useGetConnectionTabData from "@/helpers/useGetConnectionTabData";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import ScoreRowNonReport from "@/components/premium/reports/you/MotivationalTheme/ScoreRowNonReport";
import Loader from "@/components/shared/Loader";
import { ConnectionAvailability } from "@dimensional-engineering/dimensional-models";
import { traitPermissionStatus } from "../utils";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "../TraitsModals/Element/ElementModal";
import PatternModal from "../TraitsModals/Pattern/PatternModal";
import ArchetypeModal from "../TraitsModals/Archetype/ArchetypeModal";
import WorldIcon from "@/components/shared/icons/WorldIcon";
import { TabConnectionIconSmall } from "@/components/shared/GlobalTabBar/icons/TabConnectionsIcon";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";
import { eclipseString } from "@/components/shared/utils";
import PrivateTraitGhostbox from "@/components/shared/GhostBox/PrivateTraitGhostbox";

const font = Roboto_Mono({ subsets: ["latin"] });

type Props = {
  histogramData: number[] | null;
  slug: string;
  type: "element" | "archetype" | "pattern";
  averageUserScore?: number | null | undefined;
  connectionAvailabilities: ConnectionAvailability[] | null | undefined;
  traitSlug: string;
};

export default function ConnectionTab(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const { returnData, loading, scoreTotal, count } = useGetConnectionTabData(
    userState.friendUidList,
    props.slug,
    props.type
  );
  const { setModalComponent } = useModalContext();

  const mapped = returnData
    .filter((a) => {
      if (a.score !== null && a.score !== undefined) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      if (a.score === null || a.score === undefined) {
        return 1;
      }
      if (b.score === null || b.score === undefined) {
        return -1;
      }
      return b.score - a.score;
    })
    .filter((a) => {
      let friendStatus:
        | "close"
        | "deep"
        | "connected"
        | "sent"
        | "unconnected"
        | "received"
        | null = null;
      if (a.publicProfile?.ownerUUID) {
        if (userState.deepFriendUidList.includes(a.publicProfile?.ownerUUID)) {
          friendStatus = "deep";
        } else if (
          userState.closeFriendUidList.includes(a.publicProfile?.ownerUUID)
        ) {
          friendStatus = "close";
        } else if (
          userState.friendUidList.includes(a.publicProfile?.ownerUUID)
        ) {
          friendStatus = "connected";
        }
      }
      const status = traitPermissionStatus(
        friendStatus,
        props.connectionAvailabilities
      );

      if (status === null) {
        return true;
      } else {
        return false;
      }
    })
    .slice(0, 10)
    .map((person, index, array) => {
      return (
        <div
          style={
            index === array.length - 1
              ? { borderBottom: "1px solid #464542" }
              : {}
          }
          className={styles.personDiv}
          key={person.publicProfile?.ownerUUID}
        >
          <div className={styles.numberDiv}>{index + 1}</div>
          <Link
            href={`/app/profile/${person.publicProfile?.ownerUUID}`}
            className={styles.imageDiv}
          >
            <SmallProfileImage
              imageUrl={person.imageUrl}
              publicProfile={person.publicProfile}
            />
          </Link>
          <div
            onClick={() => {
              if (props.type === "element") {
                setModalComponent(
                  <ElementModal
                    uid={person.publicProfile?.ownerUUID}
                    slug={props.traitSlug}
                  />
                );
              } else if (props.type === "pattern") {
                setModalComponent(
                  <PatternModal
                    uid={person.publicProfile?.ownerUUID}
                    slug={props.traitSlug}
                  />
                );
              } else {
                setModalComponent(
                  <ArchetypeModal
                    uid={person.publicProfile?.ownerUUID}
                    slug={props.traitSlug}
                  />
                );
              }
            }}
            style={{ cursor: "pointer", flexGrow: 1 }}
          >
            <div className={styles.scoreNameDiv}>
              <p className={styles.name}>
                {person?.publicProfile?.name ?? person?.publicProfile?.userName}
              </p>
              <ScoreRowNonReport score={person.score} />
            </div>
          </div>
        </div>
      );
    });

  return (
    <div className={styles.main}>
      {props.type !== "archetype" && (
        <>
          <h2 className={styles.title}>Comparison</h2>
          <Histogram
            averageUsrScore={props.averageUserScore}
            averageConnectionScore={
              count !== 0 ? scoreTotal / count : undefined
            }
            auth
            data={props.histogramData}
            hideTitle
            lowMargin
            type={props.type}
            modal
          />
        </>
      )}
      <div className={styles.bottom}>
        <h2 className={styles.title}>Connection Scores</h2>
        {loading ? (
          <Loader height="200px" mobileHeight="200px" />
        ) : props.connectionAvailabilities?.includes(
            ConnectionAvailability.private
          ) ? (
          <div className={styles.privateTraitDiv}>
            <PrivateTraitGhostbox />
          </div>
        ) : (
          <div className={styles.mapDiv}>{mapped}</div>
        )}
      </div>
    </div>
  );
}

export function ArchetypeConnectionsTab(props: {
  slug: string;
  signatureFrequency: number | null | undefined;
}) {
  const userState = useSelector((state: RootState) => state.user);
  const { returnData, loading, scoreTotal, count } = useGetConnectionTabData(
    userState.friendUidList,
    props.slug,
    "archetype"
  );

  const { setModalComponent } = useModalContext();

  const filteredReturnData = returnData.filter((a) => {
    if (a.score !== null && a.score !== undefined) {
      return true;
    } else {
      return false;
    }
  });

  const friendPercentageWithArchetype =
    (filteredReturnData.length / userState.friendUidList.length) * 100;

  const mappedConnectionsWithArchetype = filteredReturnData.map((person) => {
    const displayUserName = eclipseString(person.publicProfile?.userName, 16);
    const diplayName = eclipseString(person.publicProfile?.name, 16);
    return (
      <div
        className={styles.archPersonDiv}
        key={person.publicProfile?.ownerUUID}
        onClick={() => {
          setModalComponent(
            <ArchetypeModal
              uid={person.publicProfile?.ownerUUID}
              slug={props.slug}
            />
          );
        }}
      >
        <ComparedMediumProfileImage
          large
          imageUrl={person.imageUrl}
          publicProfile={person.publicProfile}
          noLink
        />
        <p className={styles.archPersonName}>{diplayName}</p>
        <p className={styles.archPersonUsername}>{displayUserName}</p>
      </div>
    );
  });

  return (
    <div className={styles.main}>
      <div className={styles.statsParent}>
        <h3 className={styles.statsHeader}>Stats</h3>

        <div className={styles.statsRow}>
          <div className={styles.leftStats}>
            <div className={styles.iconDiv}>
              <WorldIcon />
            </div>{" "}
            <p className={`${styles.rowDesc} ${font.className}`}>
              Users with this Archetype
            </p>
          </div>
          <p className={`${styles.statResult} ${font.className}`}>
            {props.signatureFrequency
              ? `${(props.signatureFrequency * 100).toFixed(1)}%`
              : "?"}
          </p>
        </div>

        <div style={{ border: "0" }} className={styles.statsRow}>
          <div className={styles.leftStats}>
            <div className={styles.iconDiv}>
              <TabConnectionIconSmall />
            </div>{" "}
            <p className={`${styles.rowDesc} ${font.className}`}>
              Connections with this Archetype
            </p>
          </div>
          <p className={`${styles.statResult} ${font.className}`}>
            {friendPercentageWithArchetype.toFixed(1)}%
          </p>
        </div>
      </div>

      {!loading && mappedConnectionsWithArchetype.length ? (
        <>
          <h3 className={styles.archWrapperTitle}>
            Connections with this Archetype
          </h3>
          <div className={styles.archWrapper}>
            {mappedConnectionsWithArchetype}
          </div>
        </>
      ) : null}
    </div>
  );
}
