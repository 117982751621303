import { useEffect, useState } from "react";

import { useAuth } from "@/context/AuthContext";
import useGetFriendStatus from "@/helpers/useGetFriendStatus";
import OwnArchetypeModal from "./OwnArchetypeModal";
import FriendArchetypeModal from "./FriendArchetypeModal";
import { Mixpanel } from "@/helpers/mixpanel";

export default function ArchetypeModal(props: { slug: string; uid?: string }) {
  const [ownProfile, setOwnProfile] = useState<boolean>(true);
  const { user } = useAuth();
  const isFriend = useGetFriendStatus(props.uid);

  useEffect(() => {
    Mixpanel?.track("Trait clicked", { type: "archetype", slug: props.slug });
    Mixpanel?.track("Trait Viewed", { type: "archetype", slug: props.slug });
  }, []);

  useEffect(() => {
    if (props.uid) {
      if (props.uid === user?.uid) {
        setOwnProfile(true);
      } else {
        setOwnProfile(false);
      }
    }
  }, [user?.uid, isFriend, props.uid]);

  if (ownProfile) {
    return <OwnArchetypeModal slug={props.slug} />;
  } else {
    return <FriendArchetypeModal slug={props.slug} uid={props.uid} />;
  }
}
