export default function MessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <g clipPath="url(#clip0_39586_2067)">
        <path
          d="M0.685196 12.6552C-0.261104 13.1523 -0.222029 13.8798 0.770156 14.2724L3.71066 15.4365C4.70383 15.831 6.20676 15.6162 7.04941 14.9589L19.8209 4.88667C20.6608 4.22652 20.7506 4.32417 20.0211 5.10445L9.92535 15.9102C9.1939 16.6885 9.40973 17.6446 10.4068 18.0293L10.7515 18.1631C11.7486 18.5489 13.3725 19.1954 14.3628 19.5987L17.6245 20.9268C18.6157 21.3292 19.6743 20.8545 19.9556 19.8233L24.9263 1.55079C25.2066 0.519537 24.6617 0.0820368 23.7154 0.578137L0.685196 12.6552Z"
          fill="#FFFFF2"
          fillOpacity="0.8"
        />
        <path
          d="M8.75655 24.6173C8.69893 24.7912 10.7683 21.6213 10.7683 21.6213C11.3532 20.7278 11.0212 19.6614 10.0329 19.2571L7.7761 18.3333C6.78782 17.929 6.31417 18.4036 6.72337 19.3909C6.72142 19.3919 8.81615 24.4386 8.75657 24.6174L8.75655 24.6173Z"
          fill="#FFFFF2"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_39586_2067">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MessageIconStatusBar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_64488_2424)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.71469 0.385742H17.2116C18.7453 0.385742 20 1.64034 20 3.17416V13.2109C20 14.7439 18.7454 15.9985 17.2116 15.9985H6.61738L1.83025 19.6706C1.60285 19.8988 1.32371 19.9999 1.04926 19.9999C0.476065 19.9999 -0.0736084 19.5537 -0.0736084 18.8825V3.1742C-0.0736084 1.64046 1.18087 0.385742 2.71469 0.385742ZM2.71481 2.61575C2.41214 2.61575 2.15652 2.87137 2.15652 3.17404V16.6092L5.26009 14.2287L5.86073 13.7684H17.2117C17.5144 13.7684 17.77 13.5128 17.77 13.2109V3.17418C17.77 2.87151 17.5144 2.61589 17.2117 2.61589L2.71481 2.61575Z"
          fill="#E6E1DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_64488_2424">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
