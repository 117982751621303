import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PeriodicLikertItemResponse,
  PeriodicQuizResult,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PeriodicQuizModal.module.css";

import { RootState } from "@/redux/store";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import BackButton from "@/components/shared/buttons/BackButton";
import { useModalContext } from "@/context/ModalContext";
import {
  handlePeerItemAnswerScroll,
  handlePeerItemAnswerUndoScroll,
} from "@/components/peerAssessments/utils";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import LoadingModal from "@/components/shared/LoadingModal/LoadingModal";
import PeriodicQuizResultModal from "../PeriodicQuizResultModal/PeriodicQuizResultModal";
import AssessmentBackButton from "@/components/assessments-new/AssessmentBackButton/AssessmentBackButton";
import { Mixpanel } from "@/helpers/mixpanel";

export default function PeriodicQuizModal(props: {
  elementName: "PeriodicQuizModal";
}) {
  const { setModalOpen, removeModalByName, setModalComponent } =
    useModalContext();

  const quizState = useSelector((state: RootState) => state.periodicQuiz);

  const [instructionalStep, setInstructionalStep] = useState<boolean>(
    quizState.availableQuiz?.instructionalText !== null &&
      quizState.availableQuiz?.instructionalText !== undefined
  );
  const [responses, setResponses] = useState<PeriodicLikertItemResponse[]>([]);

  useEffect(() => {
    Mixpanel?.track("Quiz started", { slug: quizState.availableQuiz?.slug });
  }, []);

  if (!quizState.availableQuiz) {
    return <></>;
  }

  const progress =
    responses.length / quizState.availableQuiz.periodicLikertItems.length;

  function handleClick(option: PeriodicLikertItemResponse) {
    handlePeerItemAnswerScroll();
    if (
      quizState.availableQuiz &&
      responses.length ===
        quizState.availableQuiz.periodicLikertItems.length - 1
    ) {
      setResponses((current) => [...current, option]);
      handleFinished()
        .then((res) => {
          if (quizState.availableQuiz) {
            const data = res.data as PeriodicQuizResult;
            setModalComponent(
              <PeriodicQuizResultModal
                periodicQuiz={quizState.availableQuiz}
                periodicQuizResult={data}
                elementName="PeriodicQuizResultModal"
              />
            );

            removeModalByName("LoadingModal");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setResponses((current) => [...current, option]);
    }
  }

  function handleBackButton() {
    handlePeerItemAnswerUndoScroll();
    setResponses(responses.slice(0, -1));
  }

  async function handleFinished() {
    setModalComponent(<LoadingModal elementName="LoadingModal" />);
    removeModalByName("PeriodicQuizModal");
    Mixpanel?.track("Quiz completed", { slug: quizState.availableQuiz?.slug });
    const call = callFirebaseFunctions("computePeriodicQuizResult");
    return await call({
      periodicQuizItemResponses: responses,
      periodicQuizSlug: quizState.availableQuiz?.slug,
    });
  }

  const mappedQuestions = quizState.availableQuiz.periodicLikertItems[
    responses.length
  ]?.options.map((option) => {
    return (
      <div
        onClick={() => {
          if (quizState.availableQuiz) {
            handleClick({
              itemSlug:
                quizState.availableQuiz?.periodicLikertItems[responses.length]
                  .slug,
              responseValue: option.value,
            });
          }
        }}
        className={styles.optionBox}
        key={option.value}
      >
        <p className={styles.optionText}>{option.text}</p>
      </div>
    );
  });

  if (instructionalStep) {
    return (
      <ModalWrapper noHeader noLine title="">
        <main className={styles.instructionalMain}>
          <section className={styles.header}>
            <h2 className={styles.quizTitle}>
              {quizState.availableQuiz?.title}
            </h2>
            <h3 className={styles.quizSubTitle}>Daily Quiz</h3>
            <div className={styles.progressBar}></div>
            <div className={styles.downArrorDiv}>
              <BackButton step={true} onBack={() => setModalOpen(false)} />
            </div>
          </section>

          <section className={styles.instructionTitleSection}>
            <h1 className={styles.instructionTitle}>How to take this quiz</h1>
            <p className={styles.instructionSubTitle}>
              {quizState.availableQuiz.instructionalText}
            </p>
          </section>

          <section className={styles.instructionalBottomSection}>
            <button
              onClick={() => setInstructionalStep(false)}
              className={styles.instructionalBottomButton}
            >
              Continue
            </button>
          </section>
        </main>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noHeader noLine title="">
      <main id="mainAssessment">
        <section className={styles.header}>
          <h2 className={styles.quizTitle}>{quizState.availableQuiz?.title}</h2>
          <h3 className={styles.quizSubTitle}>Daily Quiz</h3>
          <div className={styles.progressBar}>
            <div
              style={{ width: `${progress * 100}%` }}
              className={styles.progress}
            ></div>
          </div>
          <div className={styles.downArrorDiv}>
            <BackButton step={true} onBack={() => setModalOpen(false)} />
          </div>
        </section>
        <section style={{ position: "relative" }} id="carouselContainer">
          <p className={styles.leadInText}>
            {
              quizState.availableQuiz.periodicLikertItems[responses.length]
                ?.leadInText
            }
          </p>
          <h4 className={styles.itemText}>
            {
              quizState.availableQuiz.periodicLikertItems[responses.length]
                ?.itemText
            }
          </h4>
          <div className={styles.optionsWrapper}>{mappedQuestions}</div>
          {responses.length !== 0 && (
            <div className={styles.backButtonDiv}>
              <AssessmentBackButton
                onClick={() => {
                  handleBackButton();
                }}
              />
            </div>
          )}
        </section>
      </main>
    </ModalWrapper>
  );
}
