import { useEffect } from "react";

import { useModalContext } from "@/context/ModalContext";
import { useAlertContext } from "@/context/AlertContext";
import { useGetFCMTokenHook } from "./useGetFCMTokenHook";

export default function useEmptyModalOnRenderHook() {
  const { emptyModal, modalComponent } = useModalContext();
  const { emptyAlerts } = useAlertContext();
  useGetFCMTokenHook();

  useEffect(() => {
    let hasNotificationLoadingModal = false;
    modalComponent.forEach((modal) => {
      if (
        modal.props.elementName === "NotificationLoadingModal" ||
        modal.props.elementName === "DailyStory" ||
        modal.props.elementName === "PeriodicQuizResultModal" ||
        modal.props.elementName === "DailyInsightsModal"
      ) {
        hasNotificationLoadingModal = true;
      }
    });
    if (!hasNotificationLoadingModal) {
      emptyModal();
    }
    emptyAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return;
}
