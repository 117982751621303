import React, { useState, TouchEvent } from "react";

import styles from "./AuthHeader.module.css";

import BackButton from "../buttons/BackButton";

export default function ModalAuthHeader(props: {
  title: string;
  subTitle?: string;
  step?: boolean;
  onBack?: () => void;
  downArrow?: boolean;
  noLine?: boolean;
  noCloser?: boolean;
  onDragDown: (postionY: number) => void;
}) {
  const [startY, setStartY] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const currentY = e.touches[0].clientY;
    const diffY = currentY - startY;
    if (diffY > 0) {
      console.log("Dragging down");
      props.onDragDown(diffY);
      // You can update the div position or perform any other action here
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    props.onDragDown(0);
  };

  return (
    <div
      style={props.noLine ? { borderBottom: "none" } : {}}
      className={styles.mainModal}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {!props.noCloser && (
        <div
          className={props.downArrow ? styles.backDivDown : styles.backDivSmall}
        >
          <BackButton
            step={true}
            onBack={() => props.onBack && props.onBack()}
          />
        </div>
      )}
      <h1 className={styles.modalTitle}>{props.title}</h1>
      <h2 className={styles.subTitle}>{props.subTitle}</h2>
    </div>
  );
}
