/* eslint-disable @next/next/no-img-element */
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import Link from "next/link";

import styles from "./SmallProfileImage.module.css";
import { ProfileColorMap } from "../ColorMap";
import { useModalContext } from "@/context/ModalContext";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { getConnectionProfileColor } from "../utils";

type Props = {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  noLink?: boolean;
  verySmal?: boolean;
};

export default function ComparedSmallProfileImage(props: Props) {
  const { emptyModal } = useModalContext();
  const userState = useSelector((state: RootState) => state.user);
  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.publicProfile?.primaryNature?.nature
  );

  if (props.noLink) {
    return (
      <div
        className={
          props.verySmal
            ? styles.verySmallImageContatiner
            : styles.smallImageContainer
        }
        style={{ borderRadius: "50%", border: `3px solid ${connectionColor}` }}
      >
        <img
          alt=""
          src={props.imageUrl}
          className={props.verySmal ? styles.verySmallImage : styles.smallImage}
        />
      </div>
    );
  }
  return (
    <Link
      href={`/app/profile/${props.publicProfile?.ownerUUID}`}
      onClick={() => emptyModal()}
      className={
        props.verySmal
          ? styles.verySmallImageContatiner
          : styles.smallImageContainer
      }
      style={{ borderRadius: "50%", border: `3px solid ${connectionColor}` }}
    >
      <img
        alt=""
        src={props.imageUrl}
        className={props.verySmal ? styles.verySmallImage : styles.smallImage}
      />
    </Link>
  );
}
