import styles from "./DiscoveryGate.module.css";

export default function DiscoveryCheckMark(props: {
  backgroundColor: string;
  checkColor: string;
}) {
  return (
    <div
      style={{ backgroundColor: props.backgroundColor }}
      className={styles.checkParent}
    >
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.500244 6.58357L2.16659 4.41682L6.50024 8.08357L13.5004 0.250122L15.5002 2.08357L6.66659 11.7497L0.500244 6.58357Z"
          fill={props.checkColor}
        />
      </svg>
    </div>
  );
}
