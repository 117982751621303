import { useRouter } from "next/router";

import styles from "./NotificationBar.module.css";
import { Mixpanel } from "@/helpers/mixpanel";

export default function NotificationBar(props: {
  source: "Notification feed" | "Home warning pill";
}) {
  const router = useRouter();

  return (
    <section className={styles.main}>
      <p className={styles.title}>Your notifications are off</p>
      <p className={styles.subtitle}>
        {"Don't miss new prompts, stories or assessments."}
      </p>
      <div className={styles.buttonsDiv}>
        <button
          onClick={() => router.push("/app/settings/notifications")}
          className={styles.customizeButton}
        >
          Customize
        </button>
        <button
          onClick={async () => {
            Mixpanel?.track("Enable notification cta clicked", {
              source: props.source,
            });
            if (window.Android) {
              window.Android.onGoToNotificationSettings();
            }
          }}
          className={styles.enableButton}
        >
          Enable
        </button>
      </div>
    </section>
  );
}

export function SettingsNotificationBar() {
  return (
    <section className={styles.mainSettings}>
      <p className={styles.settingsInfo}>
        Notifications are off. Would you like to receive Dimensional
        notifications?
      </p>
      <button
        onClick={async () => {
          if (window.Android) {
            window.Android.onGoToNotificationSettings();
            Mixpanel?.track("Enable notification cta clicked", {
              source: "Settings",
            });
          }
        }}
        className={styles.enableButtonSettings}
      >
        Enable Notifications
      </button>
    </section>
  );
}
