import { createContext, ReactNode, useContext, useState } from "react";
import uuid from "react-uuid";

type PublicProfileContextModel = {
  modal: boolean;
  connectSource: string | null;
  sessionId: string | null;
  mobileSearchOpen: boolean;
  setModal: (value: boolean) => void;
  setConnectSource: (value: string) => void;
  setMobileSearchOpen: (value: boolean) => void;
  betaModal: boolean;
  setBetaModal: (value: boolean) => void;
  isDesktopShareModalOpen: boolean;
  setDesktopShareModalOpen: (value: boolean) => void;
};

const PublicProfileDefaultValues: PublicProfileContextModel = {
  sessionId: null,
  modal: false,
  connectSource: null,
  mobileSearchOpen: false,
  setModal: () => {},
  setConnectSource: () => {},
  setMobileSearchOpen: () => {},
  betaModal: false,
  setBetaModal: () => {},
  isDesktopShareModalOpen: false,
  setDesktopShareModalOpen: () => {},
};

type Props = {
  children: ReactNode;
};

const PublicProfileConext = createContext<PublicProfileContextModel>(
  PublicProfileDefaultValues
);

export function usePublicProfileContext() {
  return useContext(PublicProfileConext);
}

export function PublicProfileProvider({ children }: Props) {
  const [modal, setModal] = useState<boolean>(false);
  const [connectSource, setConnectSource] = useState<string | null>(null);
  const [mobileSearchOpen, setMobileSearchOpen] = useState<boolean>(false);
  const [isDesktopShareModalOpen, setIsDesktopShareModalOpen] =
    useState<boolean>(false);
  const [betaModal, betaModalSet] = useState<boolean>(false);

  function updateModal(value: boolean) {
    setModal(value);
  }

  function updateModalSource(value: string) {
    setConnectSource(value);
  }

  function updateMobileSearch(value: boolean) {
    setMobileSearchOpen(value);
  }

  function setBetaModalfunction(value: boolean) {
    betaModalSet(value);
  }

  function setDesktopShareModalOpen(value: boolean) {
    setIsDesktopShareModalOpen(value);
  }

  const value = {
    sessionId: uuid(),
    modal: modal,
    connectSource: connectSource,
    mobileSearchOpen: mobileSearchOpen,
    setModal: updateModal,
    setConnectSource: updateModalSource,
    setMobileSearchOpen: updateMobileSearch,
    betaModal: betaModal,
    setBetaModal: setBetaModalfunction,
    isDesktopShareModalOpen: isDesktopShareModalOpen,
    setDesktopShareModalOpen: setDesktopShareModalOpen,
  };

  return (
    <PublicProfileConext.Provider value={value}>
      {children}
    </PublicProfileConext.Provider>
  );
}
