export default function CompareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="33"
      viewBox="0 0 38 33"
      fill="none"
    >
      <rect opacity="0.8" y="9" width="38" height="5" rx="2.5" fill="#FFFFF2" />
      <rect
        opacity="0.8"
        y="21"
        width="16"
        height="5"
        rx="2.5"
        fill="#FFFFF2"
      />
    </svg>
  );
}
