/* eslint-disable react/no-children-prop */
import { useState, useEffect } from "react";

import styles from "../../../../styles/app/AuthTraits/ElementModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetArcehtypeModalData from "./useGetArcehtypeModalData";
import Loader from "@/components/shared/Loader";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { ArchetypeDiscovery } from "@dimensional-engineering/dimensional-models";
import ArchetypeTraitsPageLeftBarModal from "../../arcehtype/ArchetypeTraitsPage/ArchetypeTraitsPageLeftBarModal";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import OtherArchetypes from "@/components/traits/detailsTab/archetypes/otherArchetypes/OtherArchetypes.";
import OppositeArchetype from "@/components/traits/detailsTab/archetypes/opposite/OppositeArchetype";
import AboutThisTrait from "@/components/traits/detailsTab/aboutThisTrait/AboutThisTrait";
import Dichotomies from "@/components/traits/detailsTab/archetypes/dichotomies/Dichotomies";
import ArchetypeComponentTree from "@/components/traits/detailsTab/archetypes/archetypeComponents/ArchetypeComponentTree";
import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import ReactMarkdown from "react-markdown";
import { CartesianModalBreakdown } from "../../detailsTab/archetypes/cartesian/CartesianModal";
import InteractionBar from "../../shared/InteractionBar/InteractionBar";
import LargeArchetypeModal from "../../arcehtype/Archetypes/LargeArchetype/LargeArchetypeModal";
import { ValuesArchetypeBreakdownDetailsTab } from "../../detailsTab/archetypes/ValuesArchetypeBreakdown/ValuesArchetypeBreakdown";
import { CareerArchetypeBreakdownDetailsView } from "../../detailsTab/archetypes/CareerArchetypeBreakdown/CareerArchetypeBreakdown";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";

export default function FriendArchetypeModal(props: {
  slug: string;
  uid?: string;
}) {
  const { archetype, otherArchetypes } = useGetArcehtypeModalData({
    slug: props.slug,
  });
  const publicProfile = useGetPublicProfile(props.uid as string);

  const [tabs, setTabs] = useState<string[]>([
    "summary",
    "details",
    "identity",
  ]);
  const [markdownContent, setMarkdownContent] = useState<string[]>([]);
  const [slicedMarkdown, setSlicedMarkdown] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [friendScore, setFriendScore] = useState<number | null | undefined>(
    null
  );
  const [archDiscovery, setArchDiscovery] = useState<
    ArchetypeDiscovery | undefined
  >(undefined);

  const imageUrl = useGetProfilePhoto(publicProfile?.ownerUUID);

  useEffect(() => {
    setLoading(true);
    const fetchData = callFirebaseFunctions("fetchFriendArchetypeDiscovery");
    fetchData({
      memberUUID: props.uid,
      version: "2",
      traitSlug: archetype?.slug,
    })
      .then((res) => {
        setLoading(false);
        const data = res.data as ArchetypeDiscovery;
        setArchDiscovery(data);
        if (data) {
          setFriendScore(data.archetypeScore);
        }
      })
      .catch((err) => {});

    setTabs(["summary", "details", "identity"]);
    setMarkdownContent([]);
    setSlicedMarkdown([]);
    archetype?.traitContent.markdownSections?.forEach((section, index) => {
      if (!tabs.includes(section.traitSection as string)) {
        setTabs((current) => [...current, section.traitSection as string]);
      }
      setMarkdownContent((current) => [...current, section.markdownString]);
      if (index !== 0) {
        setSlicedMarkdown((current) => [...current, section.markdownString]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archetype?.slug, props.slug]);

  if (archetype === null || loading === true) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper title={archetype.name}>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.rightBarMain}>
            <SwipeTabs
              sticky
              content={[
                <ArchetypeTraitsPageLeftBarModal
                  nonOwnProfile
                  archetype={archetype}
                  friendScore={friendScore}
                  publicProfile={publicProfile}
                  key={1}
                  archetypeDiscovery={archDiscovery}
                />,
                <div
                  style={
                    archetype.archetypeGroupSlug === "love"
                      ? { minHeight: "1900px" }
                      : {}
                  }
                  key={2}
                >
                  <div className={styles.archetypeDiv}>
                    <LargeArchetypeModal archetype={archetype} border />
                  </div>
                  <AboutThisTrait
                    traitContent={archetype.traitContent}
                    archetypeGroupSlug={archetype.archetypeGroupSlug}
                  />
                  {archetype.archetypeGroupSlug === "values" && (
                    <ValuesArchetypeBreakdownDetailsTab archetype={archetype} />
                  )}
                  {archetype.archetypeGroupSlug === "work" && (
                    <CartesianModalBreakdown archetype={archetype} />
                  )}
                  {archetype.traitContent.polarityArchetypeDetails && (
                    <Dichotomies
                      data={archetype.traitContent}
                      useSpectrum
                      slug={archetype.slug}
                      publicProfile={publicProfile}
                      imageUrl={imageUrl}
                    />
                  )}
                  {archetype.archetypeGroupSlug === "career" && (
                    <CareerArchetypeBreakdownDetailsView
                      archetype={archetype}
                    />
                  )}
                  {/* <FriendHistogram
                    // @ts-ignore
                    data={archetype.traitContent.histogramData}
                    type="archetype"
                    averageUsrScore={archetype.traitContent.averagePercentScore}
                    publicProfile={publicProfile}
                    score={friendScore}
                  /> */}
                  <OtherArchetypes
                    uid={props.uid}
                    archetypes={otherArchetypes}
                    authenticated
                  />
                  {archetype.traitContent.oppositeTrait?.traitSlug && (
                    <OppositeArchetype
                      uid={props.uid}
                      authenticated
                      archetype={archetype}
                    />
                  )}
                  {archetype.traitContent.archetypeDetails?.componentTraits && (
                    <ArchetypeComponentTree
                      archetype={archetype}
                      authenticated
                      uid={props.uid}
                    />
                  )}
                </div>,
                <div
                  key={3}
                  style={{ pointerEvents: "none" }}
                  className={styles.markdownDiv}
                >
                  <ReactMarkdown
                    transformLinkUri={(href: string) => {
                      return "";
                    }}
                    children={markdownContent[0]}
                  />
                </div>,
              ]}
              slicedContent={slicedMarkdown}
              tabs={tabs}
              grow={tabs.length < 4 ? true : false}
            />
          </div>
        </div>
      </div>
      {props.uid && (
        <InteractionBar
          type="archetype"
          ownProfile={false}
          connectionAvailabilities={archetype.connectionAvailabilities}
          slug={archetype.slug}
          uid={props.uid}
        />
      )}
    </ModalWrapper>
  );
}
