import { useEffect, useState } from "react";

import styles from "./Elements.module.css";

type ElementProps = {
  name: string;
  color: string;
  icon?: JSX.Element;
  noMarginTopBottom?: boolean;
  midColor?: string;
  score?: number | null;
  question: boolean;
};

export default function UnknownTutorialElement(props: ElementProps) {
  const [color, setColor] = useState<string>(props.color);

  useEffect(() => {
    if (!props.color.includes("#")) {
      setColor("#" + props.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  return (
    <div
      className={styles.elementContainer}
      style={
        props.noMarginTopBottom ? { marginTop: "0", marginBottom: "0" } : {}
      }
    >
      <div className={styles.elementTop}>
        <div className={styles.iconDiv}>{props.icon && props.icon}</div>
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.59043 6.00018C8.35277 5.99971 8.12496 5.90502 7.95669 5.73721L5.88944 3.66961V10.4909C5.89788 10.7323 5.80835 10.9671 5.64054 11.1415C5.47273 11.3154 5.24117 11.4139 4.99928 11.4139C4.7574 11.4139 4.52632 11.3154 4.35851 11.1415C4.1907 10.9671 4.1007 10.7323 4.10961 10.4909V3.66961L2.04104 5.73721C1.68995 6.08784 1.12135 6.08784 0.770725 5.73674C0.420097 5.38564 0.420097 4.81705 0.771194 4.46642L4.37119 0.87578V0.876249C4.539 0.707973 4.76729 0.613281 5.00494 0.613281C5.24259 0.613281 5.47041 0.707968 5.63821 0.876249L9.23821 4.46689V4.46642C9.49649 4.72516 9.57244 5.1147 9.42994 5.45126C9.28791 5.78782 8.95603 6.00486 8.59041 6.00016L8.59043 6.00018Z"
            fill="#FFFFF2"
          />
        </svg>
      </div>
      <div className={styles.wrapperCircle}>
        <div
          className={styles.mainCircle}
          style={{ border: `${color} 15px solid` }}
        ></div>
      </div>
      <div className={styles.elementNameContainer}>
        <p>{props.question ? "?" : props.name}</p>
      </div>
      <div
        style={props.score ? { width: `${props.score * 100}%` } : {}}
        className={styles.scoreDiv}
      ></div>
    </div>
  );
}
