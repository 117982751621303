export default function CareerSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_46528_9803)">
        <path
          d="M39.5469 31.2384C37.953 29.6371 35.6882 29.1209 33.6539 29.675L18.8124 14.8365C19.368 12.7992 18.8503 10.5331 17.249 8.93337C15.5486 7.23294 13.0871 6.75754 10.9565 7.49835L11.0979 7.8221L14.4536 11.1792L10.8879 14.7434L7.53231 11.3863L7.20856 11.2463C6.46626 13.3754 6.94169 15.8357 8.65086 17.5403C10.2186 19.1109 12.4368 19.6359 14.442 19.1299L29.3608 34.0472C28.8504 36.0568 29.3798 38.2703 30.9504 39.8439C32.6508 41.5458 35.1123 42.0212 37.2429 41.2819L37.1015 40.9567L33.7458 37.601L37.3115 34.0354L40.6671 37.3954L40.9938 37.5368C41.7317 35.4004 41.259 32.9415 39.5469 31.2384Z"
          fill="#FFFFF2"
        />
        <path
          d="M38.9431 14.2897L40.6056 12.6229C41.8043 11.4285 41.8043 9.48746 40.6056 8.29033C39.4112 7.09451 37.4702 7.09451 36.2757 8.29033L34.6088 9.95868L38.9431 14.2897Z"
          fill="#FFFFF2"
        />
        <path
          d="M12.4132 36.506L14.9041 36.7962L15.0455 38.1845L37.3171 15.9129L32.9685 11.5976L10.6704 33.8968L12.1536 34.0455L12.4132 36.506Z"
          fill="#FFFFF2"
        />
        <path
          d="M13.5009 39.7287L13.5432 39.6893L13.4017 38.2995L10.9094 38.0137L10.6513 35.5535L9.16671 35.4033L7.23441 41.6085L13.5009 39.7287Z"
          fill="#FFFFF2"
        />
        <path
          d="M13.5009 39.7287L13.5432 39.6893L13.4017 38.2995L10.9094 38.0137L10.6513 35.5535L9.16671 35.4033L7.23441 41.6085L13.5009 39.7287Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_46528_9803">
          <rect
            width="37.3334"
            height="37.3334"
            fill="white"
            transform="translate(5.66666 5.66664)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
