export default function PeerStrengthsSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4845 13.9562C13.4724 13.7633 13.6296 13.6053 13.8228 13.6053H26.1943C26.3875 13.6053 26.5447 13.7633 26.5326 13.9562C26.3376 17.0834 23.957 19.6162 20.9051 20.0361H26.1943C26.3876 20.0361 26.5447 20.194 26.5326 20.3869C26.3188 23.8144 23.4796 26.528 20.0085 26.528C16.5375 26.528 13.6983 23.8144 13.4845 20.3869C13.4724 20.194 13.6296 20.0361 13.8228 20.0361H19.112C16.0601 19.6162 13.6795 17.0834 13.4845 13.9562Z"
        fill="#FFFFF2"
      />
      <path
        d="M38.1141 14.8226C38.2311 14.6671 38.2504 14.4589 38.163 14.2841C38.0756 14.1105 37.8982 14 37.7028 14H34.8236C34.3545 14.0039 33.9123 14.2185 33.6206 14.5861L30.3403 18.6993C29.8866 19.2635 29.8866 20.0682 30.3403 20.6324L33.6206 24.7456C33.9123 25.1132 34.3545 25.3279 34.8236 25.3317H37.7028C37.8982 25.3317 38.0756 25.2212 38.163 25.0476C38.2504 24.8728 38.2311 24.6646 38.1141 24.5091L34.7414 20.005C34.5923 19.8148 34.5923 19.5474 34.7414 19.3572L38.1141 14.8226Z"
        fill="#FFFFF2"
      />
      <path
        d="M6.59678 14.5861C6.305 14.2185 5.86282 14.0038 5.39369 14H2.51449C2.31911 14 2.14174 14.1105 2.05434 14.2841C1.96694 14.4589 1.98622 14.6671 2.10319 14.8226L5.47597 19.3267C5.62507 19.5169 5.62507 19.7843 5.47597 19.9745L2.10319 24.4888C1.98622 24.6443 1.96694 24.8526 2.05434 25.0274C2.14175 25.2009 2.31913 25.3114 2.51449 25.3114H5.39369C5.86286 25.3076 6.30501 25.0929 6.59678 24.7253L9.87703 20.6122C10.3308 20.0479 10.3308 19.2432 9.87703 18.679L6.59678 14.5861Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.6324 29.3461C20.0708 28.8846 19.261 28.8846 18.6993 29.3461L14.5861 32.6263C14.2185 32.9181 14.0038 33.3603 14 33.8294V36.7086C14 36.904 14.1105 37.0814 14.2841 37.1688C14.4589 37.2562 14.6671 37.2369 14.8226 37.1199L19.3267 33.7471C19.5169 33.598 19.7843 33.598 19.9745 33.7471L24.4786 37.1199C24.566 37.1906 24.6753 37.2266 24.7871 37.2227C24.8616 37.2369 24.9388 37.2369 25.0133 37.2227C25.1881 37.1341 25.2987 36.9554 25.3012 36.76V33.8808C25.2974 33.4117 25.0827 32.9695 24.7151 32.6777L20.6324 29.3461Z"
        fill="#FFFFF2"
      />
      <path
        d="M25.0235 2.07327C24.859 1.97558 24.6533 1.97558 24.4888 2.07327L19.9847 5.49738C19.7945 5.64648 19.5272 5.64648 19.3369 5.49738L14.8226 2.1246C14.6671 2.00763 14.4589 1.98835 14.2841 2.07575C14.1105 2.16316 14 2.34054 14 2.5359V5.41511C14.0039 5.88427 14.2185 6.32642 14.5861 6.61819L18.6993 9.89844C19.2635 10.3522 20.0682 10.3522 20.6324 9.89844L24.7456 6.61819C25.1132 6.32641 25.3279 5.88424 25.3317 5.41511V2.5359C25.3253 2.33538 25.206 2.15553 25.0235 2.07327Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
