export default function OrganizationalIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_45429_22453)">
        <path
          d="M12.4539 27.1109H27.5641C28.956 27.1109 30.0824 25.9845 30.0824 24.5926C30.0824 23.2006 28.956 22.0742 27.5641 22.0742H12.4539C11.0619 22.0742 9.93555 23.2006 9.93555 24.5926C9.93555 25.9845 11.0619 27.1109 12.4539 27.1109Z"
          fill="#FFFFF2"
        />
        <path
          d="M12.4539 18.0738H27.5641C28.956 18.0738 30.0824 16.9474 30.0824 15.5555C30.0824 14.1635 28.956 13.0371 27.5641 13.0371H12.4539C11.0619 13.0371 9.93555 14.1635 9.93555 15.5555C9.93555 16.9474 11.0619 18.0738 12.4539 18.0738Z"
          fill="#FFFFF2"
        />
        <path
          d="M12.4539 9.03672H27.5641C28.956 9.03672 30.0824 7.91032 30.0824 6.51836C30.0824 5.1264 28.956 4 27.5641 4H12.4539C11.0619 4 9.93555 5.1264 9.93555 6.51836C9.93555 7.91032 11.0619 9.03672 12.4539 9.03672Z"
          fill="#FFFFF2"
        />
        <path
          d="M39.4339 29.4166L39.9823 25.0095H39.9798C40.1544 23.6298 39.1757 22.3706 37.7959 22.1959C36.4162 22.0213 35.1546 23.0001 34.9824 24.3799L34.434 28.787C34.2741 30.0535 33.192 31.0028 31.9156 30.9906H8.08438C6.80801 31.0029 5.72587 30.0535 5.56602 28.787L5.01759 24.3799H5.02005C4.84544 23.0002 3.58628 22.0214 2.20408 22.1959C0.824398 22.3706 -0.154427 23.6297 0.0201585 25.0095L0.568588 29.4166C1.04078 33.2064 4.26743 36.0443 8.08463 36.0348H31.9354C35.7449 36.0348 38.9592 33.1992 39.4339 29.4166Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_45429_22453">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5 0.399414)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
