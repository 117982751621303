import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function WorkStyleIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.2562 50.1443C69.6472 47.7764 68.0761 45.3981 67.5971 42.5346C67.0576 39.3013 68.1152 35.9886 69.7088 33.1111C72.0909 28.8082 75.6315 25.6729 76.0678 20.578C76.4366 16.2797 74.945 11.7832 71.8206 8.71434C67.2532 4.22831 59.59 3.17853 53.9495 6.26487C51.3409 7.69489 49.1995 9.87026 46.5613 11.2583C42.3746 13.4605 37.046 13.4045 32.909 11.1148C30.764 9.92741 28.9736 8.21628 26.9804 6.80491C21.5463 2.96857 13.8854 3.69992 8.90538 7.9375C3.98822 12.1214 2.14681 19.0535 4.91901 24.9345C6.06204 27.363 7.875 29.4136 9.42473 31.6123C12.5289 36.012 13.404 41.444 10.8179 46.3383C9.39509 49.0315 7.0948 51.1905 5.6008 53.8453C2.44917 59.4441 3.58508 67.0515 8.22597 71.5305C12.8669 76.0095 20.6285 76.9625 26.2488 73.7467C28.6819 72.3563 30.6846 70.3139 33.1438 68.9644C37.2523 66.719 42.5275 66.6945 46.6609 68.9002C49.1806 70.2556 51.2318 72.3318 53.7313 73.7315C59.5876 77.0068 66.9473 75.7623 71.651 71.1094C75.6078 67.1938 76.5445 61.5623 74.7861 56.4126C74.0071 54.1357 72.6174 52.1458 71.2562 50.1443ZM39.977 54.4682C38.0554 54.4689 36.1524 54.0973 34.3768 53.3744C32.6012 52.6515 30.9878 51.5916 29.6287 50.2551C28.2696 48.9187 27.1915 47.332 26.4559 45.5856C25.7204 43.8392 25.3418 41.9673 25.3418 40.0769C25.3418 38.1866 25.7204 36.3147 26.4559 34.5683C27.1915 32.8219 28.2696 31.2351 29.6287 29.8987C30.9878 28.5623 32.6012 27.5024 34.3768 26.7795C36.1524 26.0566 38.0554 25.6849 39.977 25.6857C43.856 25.6872 47.5755 27.2041 50.3177 29.9028C53.06 32.6016 54.6005 36.2611 54.6005 40.0769C54.6005 43.8927 53.06 47.5523 50.3177 50.251C47.5755 52.9497 43.856 54.4666 39.977 54.4682V54.4682Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
