import { DimensionEvent } from "@dimensional-engineering/dimensional-models";
import { useEffect, useState } from "react";

import {
  dimensionData as initialDimensionData,
  DimensionDataType,
} from "@/assets/traitInfo/dimensionData";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

type Props = {
  numFriendsRequired?: number | null | undefined;
  requiredDimensionSlugs?: string[] | null | undefined;
  numEndorsementsRequired?: number | null | undefined;
  numPeerAssessorsRequired?: number | null | undefined;
  peerPackageCompletion?: {
    packageSlug: string;
    packageName: string;
    numRequired: number;
  };
  numPeerRatingsRequired?: number | null | undefined;
  numSuccessfulInvitesRequired?: number | null | undefined;
  userSuccessfulInvites: string[] | [] | null | undefined;
  userDimensionEvents: DimensionEvent[] | null;
  userConnectionCount: number;
};

export default function useDiscoveryGateHook(props: Props) {
  const [dimensionData, setDimensionData] =
    useState<DimensionDataType[]>(initialDimensionData);
  const [errorState, setErrorState] = useState<boolean>(false);
  const [neededDimensionsCompleted, setNeededDimensionsCompleted] =
    useState<boolean>(true);
  const [connectionComplete, setConnectionComplete] = useState<boolean>(true);
  const [invitesComplete, setInvitesComplete] = useState<boolean>(true);
  const [peerRatingsComplete, setPeerRatingsComplete] = useState<boolean>(true);
  const [peerRatingCount, setPeerRatingCount] = useState<number>(0);
  const [peerAssessorComplete, setPeerAssessorComplete] =
    useState<boolean>(true);

  const userState = useSelector((state: RootState) => state.user);

  async function checkNumberOfPeerRatings() {
    let count = 0;

    const call = callFirebaseFunctions("fetchNumPeerRatings");
    await call().then((res) => {
      setPeerRatingCount(res.data as number);
      count = res.data as number;
    });

    return count;
  }

  useEffect(() => {
    let data = dimensionData.map((item) => ({ ...item }));
    ///copying array

    for (let i = 0; i < data.length; i++) {
      if (props.requiredDimensionSlugs?.includes(data[i].slug)) {
        data[i].isNeeded = true;
      }
    }
    ///what dimensions are needed

    if (props.userDimensionEvents && props.userDimensionEvents?.length > 0) {
      let finishedSlugs: string[] = [];
      let startedSlugs: string[] = [];
      props.userDimensionEvents.forEach((userDimEvent) => {
        if (userDimEvent.event === "complete") {
          finishedSlugs.push(userDimEvent.dimensionSlug);
        } else {
          startedSlugs.push(userDimEvent.dimensionSlug);
        }
      });
      ///what dimensions are finished and started by user

      for (let i = 0; i < data.length; i++) {
        if (finishedSlugs.includes(data[i].slug)) {
          data[i].event = "Retake";
        } else if (startedSlugs.includes(data[i].slug)) {
          data[i].event = "Continue";
        } else {
          data[i].event = "Start";
        }
      }
      ///altering mapping data
    }

    data.forEach((dim) => {
      if (dim.isNeeded === true && dim.event !== "Retake") {
        setNeededDimensionsCompleted(false);
      }
    });
    ///if any dimensions that are needed and not completed

    if (
      props.numSuccessfulInvitesRequired &&
      props.userSuccessfulInvites?.length
    ) {
      if (
        props.userSuccessfulInvites.length < props.numSuccessfulInvitesRequired
      ) {
        setInvitesComplete(false);
      }
    } else if (
      props.numSuccessfulInvitesRequired &&
      !props.userSuccessfulInvites
    ) {
      setInvitesComplete(false);
    }
    ///number of successful invites

    if (props.numFriendsRequired) {
      if (props.userConnectionCount < props.numFriendsRequired) {
        setConnectionComplete(false);
      }
    }
    //number of connections

    if (props.numPeerRatingsRequired) {
      checkNumberOfPeerRatings().then((res) => {
        console.log(res);
        if (
          props?.numPeerRatingsRequired &&
          props?.numPeerRatingsRequired > res
        ) {
          setPeerRatingsComplete(false);
        }
      });
    }

    //number of peer ratings

    if (props.numPeerAssessorsRequired) {
      const numberOfPeerAssesors =
        userState.memberProfile?.peerAssesseeUids?.length;
      if (numberOfPeerAssesors) {
        if (numberOfPeerAssesors < props.numPeerAssessorsRequired) {
          setPeerAssessorComplete(false);
        } else {
          setPeerAssessorComplete(true);
        }
      } else {
        setPeerAssessorComplete(false);
      }
    }

    setDimensionData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.requiredDimensionSlugs,
    props.numFriendsRequired,
    props.numSuccessfulInvitesRequired,
    props.numPeerRatingsRequired,
    props.numPeerAssessorsRequired,
    userState.memberProfile?.peerAssesseeUids?.length,
  ]);

  useEffect(() => {
    let error = true;
    if (props.requiredDimensionSlugs && !neededDimensionsCompleted) {
      error = false;
    }
    if (props.numSuccessfulInvitesRequired && !invitesComplete) {
      error = false;
    }
    if (props.numFriendsRequired && !connectionComplete) {
      error = false;
    }

    if (props.numPeerRatingsRequired && !peerRatingsComplete) {
      error = false;
    }

    if (props.numPeerAssessorsRequired && !peerAssessorComplete) {
      error = false;
    }
    setErrorState(error);
    ///error is true unless there is actually something to display for the discovery gate
  }, [
    dimensionData,
    neededDimensionsCompleted,
    connectionComplete,
    invitesComplete,
    peerRatingsComplete,
    peerAssessorComplete,
    props.requiredDimensionSlugs,
    props.numSuccessfulInvitesRequired,
    props.numFriendsRequired,
    props.numPeerRatingsRequired,
    props.numPeerAssessorsRequired,
  ]);

  return {
    dimensionData,
    errorState,
    neededDimensionsCompleted,
    connectionComplete,
    invitesComplete,
    peerRatingsComplete,
    peerRatingCount,
    peerAssessorComplete,
  };
}
