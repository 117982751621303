export default function MagnifyingGlassImage() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3137 21.5428L19.573 15.8041C18.7176 17.1345 17.5861 18.2661 16.2556 19.1215L21.9943 24.8602C22.9096 25.7775 24.3965 25.7775 25.3118 24.8602C26.229 23.9468 26.229 22.4581 25.3137 21.5428"
        fill="#FFFFF2"
      />
      <path
        d="M19.7463 9.91236C19.7463 4.73174 15.5445 0.529907 10.3653 0.529907C5.18618 0.529907 0.986328 4.73174 0.986328 9.91236C0.986328 15.0915 5.18816 19.2913 10.3653 19.2913C15.5445 19.2913 19.7463 15.0934 19.7463 9.91236ZM10.3638 16.9487C6.48638 16.9487 3.32947 13.7915 3.32947 9.91434C3.32947 6.03303 6.48663 2.87799 10.3638 2.87799C14.2451 2.87799 17.3982 6.03515 17.3982 9.91434C17.4001 13.7918 14.2449 16.9487 10.3638 16.9487Z"
        fill="#FFFFF2"
      />
      <path
        d="M7.17029 11.7876L9.07812 9.91456L7.18961 7.99131L8.50657 6.69946L10.3951 8.62079L12.3029 6.74964L13.56 8.03184L11.6522 9.90492L13.5445 11.832L12.2276 13.1239L10.3371 11.1968L8.42931 13.0698L7.17029 11.7876Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
