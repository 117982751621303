import { ExtendedArchetype } from "@/models/sharedModels";
import styles from "./ArchetypeDescriptions.module.css";

export default function ArchetypeDescriptions(props: {
  archetype: ExtendedArchetype;
}) {
  const data = {
    identity: {
      title: "What is the Cognition Archetype?",
      description: [
        "Your Cognition Archetype represents how you perceive reality, process information, and make decisions.",
        "Cognition Archetypes are based on the Jungian theory of psychological functions.",
        "There are 16 in all, based on four pairs of opposite traits.",
      ],
    },
    love: {
      title: "What is the Love Archetype?",
      description: [
        "Love Archetypes represent how you conceptualize love and act in romantic relationships.",
        "They are based on 4 sets of opposite traits: Interdependent vs. Independent (how intertwined you want your life to be with your partner’s), Dreamer vs. Pragmatic (how idealistic you are about love), Centered vs. Anxious (how much emotional reassurance you require from your partner), and Emotion- and stability-seeking vs. Fun- and stimulation-seeking (how exciting you want your love life to be).",
        "There are 16 in all, based on four pairs of opposite traits.",
      ],
    },
    work: {
      title: "What is the Interaction Archetype?",
      description: [
        "Your Interaction Archetype represents how you relate to and communicate with others.",
        "Based on the DISC model of personality, your Interaction Archetype is particularly relevant in the workplace.",
        "There are 8 possible Interaction Archetypes based on your score on two pairs of opposite traits.",
      ],
    },
    career: {
      title: "What is the Career Archetype?",
      description: [
        "Your Career Archetype represents your underlying interests in the world of work.",
        "Based on the Holland Code model of personality, your Career Archetype is defined by your top two interest types.",
        "There are 15 possible Career Archetypes.",
      ],
    },
    values: {
      title: "What is the Values Archetype?",
      description: [
        "Your Values Archetype represents your strongest motivation or driving force in your life.",
        "Based on the Schwartz Theory of Motivational Values, there are 4 primary motivations and corresponding Archetypes.",
        "Our Values Archetype can change over time as our priorities and motivations change in our lives.",
      ],
    },
  };

  return (
    <div className={styles.main}>
      <h2 className={styles.header}>
        {data[props.archetype.archetypeGroupSlug as keyof typeof data].title}
      </h2>
      {data[
        props.archetype.archetypeGroupSlug as keyof typeof data
      ].description.map((row, index) => {
        return (
          <p className={styles.desc} key={index}>
            {row}
          </p>
        );
      })}
    </div>
  );
}
