import styles from "./InfoModal.module.css";

import { useModalContext } from "@/context/ModalContext";

type Props = {
  title?: string | null;
  body: string;
  onAccept: () => void;
  primaryText: string;
  secondaryText: string;
  redSecondary?: boolean;
  elementName: "InfoModal";
};

export default function InfoModal(props: Props) {
  const { removeModalByName } = useModalContext();

  return (
    <dialog
      onClick={() => removeModalByName("InfoModal")}
      className={styles.parent}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.main}>
        {props.title && <h2 className={styles.title}>{props.title}</h2>}
        <p className={styles.body}>{props.body}</p>
        <div className={styles.btnDiv}>
          <button
            onClick={() => {
              removeModalByName("InfoModal");
            }}
            className={styles.secondaryBtn}
            style={props.redSecondary ? { backgroundColor: "#C53723" } : {}}
          >
            {props.secondaryText}
          </button>
          <button
            onClick={() => {
              props.onAccept();
              removeModalByName("InfoModal");
            }}
            className={styles.primaryBtn}
          >
            {props.primaryText}
          </button>
        </div>
      </div>
    </dialog>
  );
}
