import { TabIconModel } from "@/models/sharedModels";

export default function TabConnectionsIcon(props: TabIconModel) {
  return (
    <svg
      width="36"
      height="28"
      viewBox="0 0 36 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.18148 10.7448C7.73884 10.6503 8.11198 10.117 8.01752 9.5596L7.85415 8.61628C7.75966 8.05892 7.22636 7.68578 6.66901 7.78024C6.11165 7.87474 5.73851 8.40804 5.83298 8.96539L5.99634 9.90872C6.08282 10.4084 6.51683 10.7607 7.00531 10.7607C7.06296 10.7607 7.12062 10.7559 7.18148 10.7447L7.18148 10.7448Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M13.1585 8.67388C13.2449 9.17359 13.6789 9.5259 14.1674 9.5259C14.2251 9.5259 14.2827 9.5211 14.3436 9.50989C14.9009 9.41539 15.2741 8.88209 15.1796 8.32474L15.0163 7.38141C14.9218 6.82406 14.3885 6.45092 13.8311 6.54538C13.2738 6.63984 12.9006 7.17317 12.9951 7.73053L13.1585 8.67388Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M15.951 12.556C15.6355 12.0883 14.9965 11.965 14.5288 12.2805C12.4836 13.6659 10.3263 14.039 7.9368 13.416C7.39228 13.2767 6.83013 13.605 6.68597 14.1495C6.54183 14.6941 6.87496 15.2562 7.4195 15.4004C8.32921 15.6374 9.22285 15.7527 10.0973 15.7527C12.0608 15.7527 13.9314 15.1585 15.677 13.9734C16.1446 13.6579 16.268 13.0189 15.9525 12.5512L15.951 12.556Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M28.395 8.39499C25.8325 7.65347 23.1388 7.95297 20.8018 9.23902C20.765 9.25984 20.7313 9.28386 20.6993 9.30469C20.6753 8.96836 20.6336 8.62884 20.576 8.29248C20.1211 5.6643 18.6733 3.37248 16.4968 1.83006C14.3203 0.292558 11.6711 -0.306411 9.04302 0.148403C6.41483 0.603257 4.12302 2.05105 2.5806 4.22757C1.0383 6.4041 0.444127 9.05327 0.89894 11.6814C1.73497 16.5358 5.96613 19.9679 10.7307 19.9679C11.2929 19.9679 11.863 19.9182 12.4316 19.8205C13.5799 19.6235 14.6658 19.2344 15.6539 18.677C15.7516 20.104 16.1616 21.5054 16.8711 22.7979C18.1588 25.1346 20.2777 26.8274 22.8371 27.5707C23.7596 27.8366 24.6949 27.9647 25.6094 27.9647C29.9385 27.9647 33.9406 25.1187 35.2025 20.7577C36.732 15.4696 33.6778 9.92143 28.3928 8.39213L28.395 8.39499ZM33.2367 20.1907C32.0195 24.3932 27.6119 26.8212 23.4094 25.6023C19.2069 24.3851 16.7789 19.9775 17.9978 15.775C18.5888 13.741 19.9325 12.0562 21.7903 11.0358C22.9787 10.3791 24.2871 10.0476 25.6068 10.0476C26.3484 10.0476 27.0947 10.1549 27.825 10.3631C32.0275 11.5803 34.4555 15.9879 33.2366 20.1904L33.2367 20.1907ZM2.92089 11.331C2.56054 9.24419 3.0362 7.14122 4.25819 5.40692C5.48017 3.67262 7.30436 2.52412 9.39139 2.16373C9.84624 2.08525 10.3011 2.04522 10.7559 2.04522C12.3799 2.04522 13.9622 2.54493 15.3155 3.50105C17.0452 4.72305 18.1983 6.54722 18.5587 8.63425C18.6852 9.38057 18.706 10.1301 18.6244 10.8652C17.4152 12.0504 16.5167 13.5302 16.033 15.2022C15.9514 15.4889 15.8809 15.7755 15.8232 16.0639C14.7614 16.9543 13.4866 17.5565 12.0884 17.7984C7.77846 18.5447 3.66739 15.6379 2.92406 11.3282L2.92089 11.331Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M22.0849 17.1109C22.1794 17.1397 22.2771 17.1525 22.3716 17.1525C22.8136 17.1525 23.2236 16.861 23.3549 16.411L23.6208 15.4933C23.7761 14.9488 23.4654 14.3818 22.9193 14.2216C22.3748 14.0663 21.8078 14.377 21.6476 14.9231L21.3818 15.8408C21.2264 16.3853 21.5371 16.9523 22.0832 17.1124L22.0849 17.1109Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M29.9037 16.246C29.3591 16.0907 28.7922 16.4014 28.632 16.9475L28.3662 17.8652C28.2108 18.4097 28.5215 18.9767 29.0676 19.1369C29.1621 19.1657 29.2598 19.1785 29.3543 19.1785C29.7963 19.1785 30.2063 18.887 30.3376 18.4402L30.6035 17.5225C30.7589 16.9779 30.4482 16.411 29.902 16.2508L29.9037 16.246Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M28.0214 21.7072C25.5775 22.0596 23.4745 21.4494 21.5926 19.8462C21.1618 19.4778 20.5148 19.5307 20.1496 19.9615C19.7813 20.3923 19.8341 21.0393 20.265 21.4045C22.1708 23.0317 24.3409 23.8517 26.7307 23.8517C27.248 23.8517 27.7765 23.8149 28.3178 23.7364C28.88 23.6547 29.2691 23.1374 29.1875 22.5769C29.1058 22.0147 28.5885 21.6303 28.0279 21.7072H28.0214Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}

export function TabConnectionIconSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M6.52592 11.3229C6.87052 11.2645 7.10123 10.9348 7.04283 10.5902L6.94182 10.0069C6.8834 9.6623 6.55366 9.43159 6.20906 9.49C5.86445 9.54842 5.63374 9.87816 5.69214 10.2228L5.79315 10.806C5.84662 11.115 6.11497 11.3328 6.41699 11.3328C6.45263 11.3328 6.48828 11.3298 6.52591 11.3229L6.52592 11.3229Z"
        fill="#FFFFF2"
      />
      <path
        d="M10.2228 10.0424C10.2763 10.3513 10.5447 10.5692 10.8467 10.5692C10.8823 10.5692 10.918 10.5662 10.9556 10.5593C11.3002 10.5008 11.5309 10.1711 11.4725 9.82649L11.3715 9.24324C11.3131 8.89863 10.9834 8.66792 10.6387 8.72632C10.2941 8.78473 10.0634 9.11448 10.1218 9.45909L10.2228 10.0424Z"
        fill="#FFFFF2"
      />
      <path
        d="M11.949 12.4427C11.7539 12.1536 11.3588 12.0773 11.0696 12.2724C9.80512 13.1289 8.47127 13.3597 6.99388 12.9744C6.6572 12.8883 6.30963 13.0913 6.2205 13.428C6.13138 13.7647 6.33735 14.1122 6.67404 14.2014C7.2365 14.3479 7.78903 14.4192 8.32969 14.4192C9.54373 14.4192 10.7003 14.0518 11.7796 13.3191C12.0687 13.124 12.145 12.7289 11.9499 12.4397L11.949 12.4427Z"
        fill="#FFFFF2"
      />
      <path
        d="M19.643 9.87001C18.0586 9.41153 16.3931 9.59671 14.9482 10.3919C14.9254 10.4047 14.9046 10.4196 14.8848 10.4325C14.87 10.2245 14.8442 10.0146 14.8086 9.80662C14.5273 8.18164 13.6322 6.76462 12.2864 5.81095C10.9407 4.86033 9.30274 4.48999 7.6778 4.7712C6.05282 5.05243 4.6358 5.94759 3.68214 7.29332C2.72855 8.63905 2.36117 10.277 2.64238 11.902C3.15929 14.9034 5.77539 17.0254 8.72131 17.0254C9.06889 17.0254 9.4214 16.9947 9.77293 16.9343C10.4829 16.8125 11.1543 16.5719 11.7653 16.2273C11.8257 17.1096 12.0792 17.976 12.5179 18.7752C13.314 20.22 14.6241 21.2667 16.2066 21.7262C16.7769 21.8906 17.3552 21.9698 17.9207 21.9698C20.5974 21.9698 23.0718 20.2102 23.852 17.5138C24.7977 14.2442 22.9093 10.8138 19.6417 9.86824L19.643 9.87001ZM22.6366 17.1632C21.884 19.7616 19.1588 21.2628 16.5604 20.5091C13.9621 19.7566 12.4608 17.0314 13.2145 14.433C13.5799 13.1754 14.4107 12.1337 15.5594 11.5028C16.2941 11.0968 17.1031 10.8918 17.9191 10.8918C18.3776 10.8918 18.839 10.9582 19.2906 11.0869C21.889 11.8395 23.3902 14.5647 22.6365 17.163L22.6366 17.1632ZM3.89254 11.6853C3.66973 10.3951 3.96383 9.0948 4.71938 8.0225C5.47492 6.95019 6.60281 6.24009 7.8932 6.01726C8.17443 5.96874 8.45566 5.94399 8.73687 5.94399C9.74096 5.94399 10.7193 6.25295 11.556 6.84411C12.6255 7.59967 13.3385 8.72754 13.5613 10.0179C13.6395 10.4794 13.6524 10.9428 13.6019 11.3973C12.8543 12.1301 12.2987 13.0451 11.9997 14.0789C11.9492 14.2561 11.9056 14.4333 11.87 14.6116C11.2135 15.1622 10.4252 15.5345 9.56073 15.684C6.89594 16.1455 4.35409 14.3482 3.8945 11.6836L3.89254 11.6853Z"
        fill="#FFFFF2"
      />
      <path
        d="M15.7417 15.2589C15.8001 15.2767 15.8605 15.2846 15.9189 15.2846C16.1922 15.2846 16.4457 15.1044 16.5269 14.8261L16.6913 14.2587C16.7873 13.9221 16.5952 13.5715 16.2576 13.4725C15.9209 13.3764 15.5704 13.5685 15.4713 13.9062L15.3069 14.4736C15.2109 14.8103 15.403 15.1608 15.7407 15.2598L15.7417 15.2589Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.5759 14.7242C20.2393 14.6281 19.8887 14.8202 19.7897 15.1579L19.6253 15.7253C19.5293 16.062 19.7214 16.4125 20.059 16.5116C20.1174 16.5294 20.1778 16.5373 20.2363 16.5373C20.5096 16.5373 20.7631 16.3571 20.8443 16.0808L21.0086 15.5134C21.1047 15.1767 20.9126 14.8262 20.5749 14.7271L20.5759 14.7242Z"
        fill="#FFFFF2"
      />
      <path
        d="M19.4118 18.1009C17.9007 18.3188 16.6005 17.9415 15.437 16.9503C15.1706 16.7225 14.7705 16.7552 14.5448 17.0216C14.317 17.288 14.3497 17.688 14.6161 17.9138C15.7944 18.9199 17.1362 19.4269 18.6138 19.4269C18.9336 19.4269 19.2604 19.4041 19.5951 19.3556C19.9427 19.3051 20.1833 18.9852 20.1328 18.6386C20.0823 18.2911 19.7624 18.0534 19.4159 18.1009H19.4118Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
