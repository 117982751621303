export default function ReadMoreStoryIcon() {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3C5.5 4.38085 4.38085 5.5 3 5.5C1.61915 5.5 0.5 4.38085 0.5 3C0.5 1.61915 1.61915 0.5 3 0.5C4.38085 0.5 5.5 1.61915 5.5 3Z"
        fill="white"
      />
      <path
        d="M5.5 10.5C5.5 11.8808 4.38085 13 3 13C1.61915 13 0.5 11.8808 0.5 10.5C0.5 9.11915 1.61915 8 3 8C4.38085 8 5.5 9.11915 5.5 10.5Z"
        fill="white"
      />
      <path
        d="M5.5 18C5.5 19.3809 4.38085 20.5 3 20.5C1.61915 20.5 0.5 19.3809 0.5 18C0.5 16.6192 1.61915 15.5 3 15.5C4.38085 15.5 5.5 16.6192 5.5 18Z"
        fill="white"
      />
      <path d="M8 16.125H21.75V19.875H8V16.125Z" fill="white" />
      <path d="M8 8.625H21.75V12.375H8V8.625Z" fill="white" />
      <path d="M8 1.125H21.75V4.875H8V1.125Z" fill="white" />
    </svg>
  );
}
