import {
  FeedItemDetail,
  SecondaryFeedIconType,
} from "@dimensional-engineering/dimensional-models";

import styles from "./NotificationViewModal.module.css";

export default function FeedItemSecondaryIcon(props: {
  feedItemDetail?: FeedItemDetail | null | undefined;
}) {
  const iconMapper: Record<SecondaryFeedIconType, JSX.Element> = {
    button: (
      <button className={styles.secondaryButton}>
        {props.feedItemDetail?.secondaryIcon?.secondaryIconDetail?.buttonText}
      </button>
    ),
    dimension: <></>,
    peerRating: (
      <button className={styles.peerRatingButton}>
        <PeerRatingIcon />
      </button>
    ),
    traitIdentifier: <></>,
  };

  return iconMapper[
    props.feedItemDetail?.secondaryIcon?.iconType ?? "dimension"
  ];
}

function PeerRatingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g clipPath="url(#clip0_51923_35852)">
        <rect
          opacity="0.3"
          x="-0.25"
          y="8.24976"
          width="7.5"
          height="3.75"
          rx="0.75"
          fill="#181716"
        />
        <rect
          opacity="0.7"
          x="8.75"
          y="8.24976"
          width="7.5"
          height="3.75"
          rx="0.75"
          fill="#181716"
        />
        <rect
          opacity="0.9"
          x="17.75"
          y="6.74951"
          width="8.25"
          height="6.75"
          rx="0.75"
          fill="#181716"
        />
        <path
          d="M19.7193 11.5731C18.8978 10.986 17.7754 11.2353 17.2214 12.0122L17.2213 12.0122L17.2173 12.0179L14.0426 16.5584C13.9015 15.8467 13.3198 15.269 12.5605 15.1706C11.5819 15.0099 10.6665 15.7019 10.5516 16.6842L10.5465 16.7276V16.7335C10.5448 16.7473 10.5426 16.7654 10.5397 16.7884C10.5368 16.8108 10.5331 16.8401 10.5288 16.8739C10.5221 16.926 10.5141 16.9886 10.506 17.0529C10.4779 17.2761 10.4402 17.5844 10.4025 17.9373C10.3277 18.637 10.25 19.5352 10.25 20.302C10.25 22.289 10.636 23.5013 10.8583 24.0955L10.8586 24.0964C10.9768 24.4111 11.1257 24.7006 11.2855 24.9428C11.4376 25.1733 11.6291 25.4061 11.8502 25.5642C11.9676 25.6482 12.5062 26.0294 13.0123 26.3875L13.6957 26.871L13.9157 27.0266L13.9769 27.0698L13.993 27.0812L13.9949 27.0825C14.564 27.4939 15.3228 27.5924 15.984 27.3389L16.7692 27.0448C16.7698 27.0445 16.7704 27.0443 16.7711 27.0441C18.4463 26.425 19.9143 25.2338 20.9804 23.7414L20.9822 23.7388L22.1037 22.1545L22.1037 22.1546L22.1087 22.1474C22.5895 21.4512 22.5681 20.4469 21.8899 19.8041C21.6577 19.5606 21.3788 19.4074 21.0865 19.3349C21.0701 18.8859 20.8885 18.4397 20.5178 18.0994C20.2743 17.8736 19.9922 17.7331 19.7002 17.6692C19.6801 17.2256 19.4984 16.7858 19.1321 16.4494C18.9979 16.3249 18.8519 16.2264 18.699 16.1523L20.1601 14.068C20.7445 13.2469 20.4951 12.1266 19.7193 11.5731ZM19.7193 11.5731C19.7194 11.5732 19.7196 11.5733 19.7197 11.5734L19.2835 12.1834L19.7189 11.5728C19.719 11.5729 19.7192 11.573 19.7193 11.5731ZM10.5499 16.7026C10.55 16.7024 10.5498 16.7048 10.5491 16.7107C10.5496 16.7058 10.5499 16.7028 10.5499 16.7026Z"
          fill="#181716"
          stroke="#FFFFF2"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_51923_35852">
          <path d="M0 0H33V33H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
