import styles from "./ScoreRow.module.css";

import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";

export default function ScoreRowPremium() {
  return (
    <div className={styles.scoreBarDivParent}>
      <div className={styles.scoreWrapper}>
        <div
          style={{ marginLeft: "4" }}
          className={styles.unselectedNonReport}
        ></div>
        <div className={styles.unselectedNonReport}></div>
        <div className={styles.unselectedNonReport}></div>
        <div className={styles.unselectedNonReport}></div>
        <div className={styles.unselectedNonReport}></div>
        <div className={styles.premiumIcon}>
          <PremiumPillIcon />
        </div>
      </div>
      <div className={styles.scoreTextDivNonReport}>
        <p className={styles.scoreTextNonReport}>Premium</p>
      </div>
    </div>
  );
}
