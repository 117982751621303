import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import { snippetMappedSubtitles } from "./snippetsMap";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetLlmHeaderAndBody(props: Props) {
  const mapped = props.snippet.snippetDetail.llmHeaderAndBody?.paragraphs?.map(
    (p, i) => {
      return (
        <p
          style={i === 0 ? { marginTop: "60px" } : {}}
          className={styles.llmListParagraph}
          key={i}
        >
          {p}
        </p>
      );
    }
  );

  return (
    <>
      {props.snippet.snippetDetail.llmHeaderAndBody && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <h4 className={styles.llmHeaderAndBodyTitle}>
            {props.snippet.snippetDetail.llmHeaderAndBody.title}
          </h4>
          <div>{mapped}</div>
        </>
      )}
    </>
  );
}
