export default function PeerFeedbackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M4.30557 6.22687C4.14596 6.02577 3.90408 5.90836 3.64745 5.90625H2.07245C1.96558 5.90625 1.86855 5.96672 1.82074 6.06164C1.77293 6.15726 1.78348 6.27118 1.84746 6.35625L3.69246 8.82009C3.77402 8.92415 3.77402 9.07039 3.69246 9.17447L1.84746 11.6439C1.78348 11.729 1.77293 11.8429 1.82074 11.9385C1.86855 12.0334 1.96559 12.0939 2.07245 12.0939H3.64745C3.9041 12.0918 4.14596 11.9744 4.30557 11.7733L6.09995 9.52327C6.34816 9.21461 6.34816 8.77443 6.09995 8.46577L4.30557 6.22687Z"
        fill="#FFFFF2"
      />
      <path
        d="M10.1896 12.5606C9.88238 12.3082 9.4394 12.3082 9.13214 12.5606L6.88214 14.355C6.68104 14.5146 6.56363 14.7565 6.56152 15.0131V16.5881C6.56152 16.695 6.62199 16.7921 6.71691 16.8399C6.81254 16.8877 6.92645 16.8771 7.01152 16.8131L9.47536 14.9681C9.57942 14.8866 9.72567 14.8866 9.82974 14.9681L12.2936 16.8131C12.3414 16.8518 12.4012 16.8715 12.4623 16.8694C12.5031 16.8771 12.5453 16.8771 12.5861 16.8694C12.6817 16.8209 12.7422 16.7231 12.7436 16.6163V15.0413C12.7415 14.7846 12.6241 14.5428 12.423 14.3832L10.1896 12.5606Z"
        fill="#FFFFF2"
      />
      <path
        d="M17.4738 6.35625C17.5378 6.27117 17.5483 6.15726 17.5005 6.06164C17.4527 5.96672 17.3557 5.90625 17.2488 5.90625H15.6738C15.4172 5.90836 15.1753 6.02578 15.0157 6.22687L13.2213 8.47687C12.9731 8.78553 12.9731 9.2257 13.2213 9.53437L15.0157 11.7844C15.1753 11.9855 15.4172 12.1029 15.6738 12.105H17.2488C17.3557 12.105 17.4527 12.0445 17.5005 11.9496C17.5483 11.854 17.5378 11.7401 17.4738 11.655L15.6288 9.19115C15.5472 9.08708 15.5472 8.94084 15.6288 8.83676L17.4738 6.35625Z"
        fill="#FFFFF2"
      />
      <path
        d="M12.5965 1.15873C12.5065 1.10529 12.394 1.10529 12.304 1.15873L9.84021 3.03181C9.73615 3.11337 9.5899 3.11337 9.48582 3.03181L7.0164 1.18681C6.93132 1.12283 6.81741 1.11228 6.7218 1.16009C6.62688 1.20791 6.56641 1.30494 6.56641 1.4118V2.9868C6.56852 3.24345 6.68594 3.48531 6.88702 3.64492L9.13702 5.4393C9.44569 5.68751 9.88586 5.68751 10.1945 5.4393L12.4445 3.64492C12.6456 3.48531 12.763 3.24343 12.7651 2.9868V1.4118C12.7616 1.30212 12.6964 1.20373 12.5965 1.15873Z"
        fill="#FFFFF2"
      />
      <circle cx="9.70539" cy="9.04499" r="2.43" fill="#FFFFF2" />
    </svg>
  );
}

export function PeerFeedbackIconSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.24004 5.53499C3.09817 5.35624 2.88316 5.25187 2.65505 5.25H1.25505C1.16005 5.25 1.0738 5.30375 1.03131 5.38812C0.988806 5.47312 0.998181 5.57438 1.05506 5.65L2.69506 7.84008C2.76756 7.93258 2.76756 8.06257 2.69506 8.15509L1.05506 10.3501C0.998181 10.4258 0.988806 10.527 1.03131 10.612C1.07381 10.6964 1.16006 10.7501 1.25505 10.7501H2.65505C2.88318 10.7482 3.09817 10.6439 3.24004 10.4651L4.83505 8.46513C5.05567 8.19076 5.05567 7.7995 4.83505 7.52513L3.24004 5.53499Z"
        fill="#FFFFF2"
      />
      <path
        d="M8.47011 11.1649C8.19699 10.9405 7.80323 10.9405 7.53011 11.1649L5.53011 12.7599C5.35136 12.9017 5.24699 13.1167 5.24512 13.3449V14.7449C5.24512 14.8399 5.29887 14.9261 5.38324 14.9686C5.46824 15.0111 5.5695 15.0017 5.64511 14.9449L7.83519 13.3049C7.92769 13.2324 8.05769 13.2324 8.1502 13.3049L10.3403 14.9449C10.3828 14.9792 10.4359 14.9967 10.4903 14.9949C10.5265 15.0017 10.564 15.0017 10.6003 14.9949C10.6853 14.9517 10.739 14.8649 10.7403 14.7699V13.3699C10.7384 13.1417 10.634 12.9267 10.4553 12.7849L8.47011 11.1649Z"
        fill="#FFFFF2"
      />
      <path
        d="M14.9455 5.65C15.0023 5.57437 15.0117 5.47312 14.9692 5.38813C14.9267 5.30375 14.8405 5.25 14.7455 5.25H13.3455C13.1173 5.25188 12.9023 5.35625 12.7605 5.53499L11.1655 7.53499C10.9448 7.80936 10.9448 8.20062 11.1655 8.47499L12.7605 10.475C12.9024 10.6537 13.1174 10.7581 13.3455 10.76H14.7455C14.8405 10.76 14.9267 10.7062 14.9692 10.6219C15.0117 10.5369 15.0023 10.4356 14.9455 10.36L13.3055 8.16991C13.233 8.07741 13.233 7.94741 13.3055 7.8549L14.9455 5.65Z"
        fill="#FFFFF2"
      />
      <path
        d="M10.6101 1.03001C10.5301 0.98251 10.4301 0.98251 10.3501 1.03001L8.16005 2.69497C8.06755 2.76747 7.93755 2.76747 7.84504 2.69497L5.65 1.05497C5.57437 0.998095 5.47312 0.988721 5.38813 1.03122C5.30375 1.07372 5.25 1.15997 5.25 1.25496V2.65496C5.25188 2.88309 5.35625 3.09808 5.53499 3.23996L7.53499 4.83496C7.80936 5.05559 8.20062 5.05559 8.47499 4.83496L10.475 3.23996C10.6537 3.09808 10.7581 2.88308 10.76 2.65496V1.25496C10.7569 1.15746 10.6989 1.07001 10.6101 1.03001Z"
        fill="#FFFFF2"
      />
      <circle cx="8.03988" cy="8.04013" r="2.16" fill="#FFFFF2" />
    </svg>
  );
}

export function PeerFeedbackIconVerySmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <path
        d="M2.43028 4.65128C2.32387 4.51722 2.16262 4.43894 1.99153 4.43754H0.941531C0.870281 4.43754 0.805595 4.47785 0.773723 4.54113C0.741849 4.60488 0.74888 4.68082 0.791536 4.73754L2.02154 6.3801C2.07591 6.44947 2.07591 6.54697 2.02154 6.61635L0.791536 8.26263C0.74888 8.31935 0.741849 8.39529 0.773723 8.45904C0.805599 8.52232 0.870286 8.56263 0.941531 8.56263H1.99153C2.16263 8.56122 2.32387 8.48294 2.43028 8.34888L3.62653 6.84889C3.792 6.64311 3.792 6.34966 3.62653 6.14389L2.43028 4.65128Z"
        fill="#FFFFF2"
      />
      <path
        d="M6.35283 8.87372C6.14799 8.70544 5.85267 8.70544 5.64783 8.87372L4.14783 10.07C4.01376 10.1764 3.93549 10.3376 3.93408 10.5087V11.5587C3.93408 11.63 3.97439 11.6947 4.03768 11.7265C4.10143 11.7584 4.17737 11.7514 4.23408 11.7087L5.87664 10.4787C5.94601 10.4243 6.04351 10.4243 6.1129 10.4787L7.75546 11.7087C7.78733 11.7345 7.82717 11.7476 7.86796 11.7462C7.89514 11.7514 7.92327 11.7514 7.95046 11.7462C8.01421 11.7139 8.05452 11.6487 8.05546 11.5775V10.5275C8.05405 10.3564 7.97577 10.1951 7.84171 10.0887L6.35283 8.87372Z"
        fill="#FFFFF2"
      />
      <path
        d="M11.209 4.73744C11.2516 4.68072 11.2587 4.60478 11.2268 4.54103C11.1949 4.47775 11.1302 4.43744 11.059 4.43744H10.009C9.83788 4.43885 9.67664 4.51713 9.57024 4.65118L8.37398 6.15118C8.20851 6.35696 8.20851 6.65041 8.37398 6.85618L9.57024 8.35618C9.67664 8.49025 9.8379 8.56852 10.009 8.56993H11.059C11.1302 8.56993 11.1949 8.52961 11.2268 8.46633C11.2587 8.40258 11.2516 8.32664 11.209 8.26993L9.97897 6.62737C9.9246 6.558 9.9246 6.4605 9.97897 6.39111L11.209 4.73744Z"
        fill="#FFFFF2"
      />
      <path
        d="M7.95772 1.2725C7.89772 1.23687 7.82272 1.23687 7.76272 1.2725L6.12016 2.52122C6.05078 2.57559 5.95328 2.57559 5.8839 2.52122L4.23762 1.29122C4.1809 1.24856 4.10496 1.24153 4.04122 1.27341C3.97794 1.30528 3.93762 1.36997 3.93762 1.44122V2.49122C3.93903 2.66231 4.01731 2.82356 4.15137 2.92996L5.65137 4.12622C5.85714 4.29168 6.15059 4.29168 6.35637 4.12622L7.85637 2.92996C7.99043 2.82355 8.06871 2.6623 8.07011 2.49122V1.44122C8.06777 1.36809 8.02428 1.3025 7.95772 1.2725Z"
        fill="#FFFFF2"
      />
      <circle cx="6.03022" cy="6.53" r="1.62" fill="#FFFFF2" />
    </svg>
  );
}
