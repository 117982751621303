export default function HighestScoringTraitsSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M39.4754 6.44864L33.472 0.44426C33.4653 0.437513 33.4633 0.427873 33.4566 0.42209C33.1742 0.139663 32.8001 -0.00106874 32.4271 -0.000106511C32.0541 -0.00107071 31.681 0.138701 31.3976 0.42209C31.3909 0.428837 31.3889 0.437513 31.3822 0.44426L25.3788 6.44765C24.8149 7.01156 24.8149 7.93403 25.3788 8.49795C25.9427 9.06187 26.8652 9.06187 27.4291 8.49795L32.4281 3.50187L37.4252 8.49894C37.9891 9.06285 38.9116 9.06285 39.4755 8.49894C40.0394 7.93502 40.0393 7.01255 39.4754 6.44864Z"
        fill="#FFFFF2"
      />
      <path
        d="M39.4754 12.352L33.472 6.34857C33.4653 6.34182 33.4633 6.33218 33.4566 6.3264C33.1742 6.04397 32.8001 5.90324 32.4271 5.9042C32.0541 5.90324 31.681 6.04301 31.3976 6.3264C31.3909 6.33315 31.3889 6.34182 31.3822 6.34857L25.3788 12.352C24.8149 12.9159 24.8149 13.8383 25.3788 14.4023C25.9427 14.9662 26.8652 14.9662 27.4291 14.4023L32.4281 9.40519L37.4252 14.4023C37.9891 14.9662 38.9116 14.9662 39.4755 14.4023C40.0394 13.8383 40.0393 12.9159 39.4754 12.352Z"
        fill="#FFFFF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 25.7827C28.5 33.6528 22.1201 40.0327 14.25 40.0327C6.37994 40.0327 0 33.6528 0 25.7827C0 17.9127 6.37994 11.5327 14.25 11.5327C22.1201 11.5327 28.5 17.9127 28.5 25.7827ZM18.3211 25.7827C18.3211 28.0313 16.4983 29.8541 14.2497 29.8541C12.0011 29.8541 10.1783 28.0313 10.1783 25.7827C10.1783 23.5341 12.0011 21.7112 14.2497 21.7112C16.4983 21.7112 18.3211 23.5341 18.3211 25.7827Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
