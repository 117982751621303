import styles from "./SwipeTabs.module.css";

export default function BlueDot(props: { nonAbsolute?: boolean }) {
  return (
    <div
      className={props.nonAbsolute ? styles.blueDotNonAbsolute : styles.blueDot}
    ></div>
  );
}

export function LargeBlueDot() {
  return <div className={styles.blueDotLarge}></div>;
}
