import { FriendsPublicProfile } from "@/models/sharedModels";
import {
  ArchetypeDiscovery,
  ElementDiscovery,
  PatternDiscovery,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ConnectionsSlice {
  publicProfiles: FriendsPublicProfile[] | [];
  currentTraitsUid: string | null;
  discoveredElements: ElementDiscovery[];
  discoveredPatterns: PatternDiscovery[];
  discoveredArchetypes: ArchetypeDiscovery[];
  isConnectionPremium: boolean;
  similarityAndCompatibilityScoresLoading: boolean;
  similarityAndCompatibilityScores: { slug: string; score: number | null }[];
}

const initialState: ConnectionsSlice = {
  publicProfiles: [],
  currentTraitsUid: null,
  discoveredElements: [],
  discoveredPatterns: [],
  discoveredArchetypes: [],
  isConnectionPremium: false,
  similarityAndCompatibilityScores: [],
  similarityAndCompatibilityScoresLoading: true,
};

export const connectionSlice = createSlice({
  name: "connections",
  initialState: initialState,
  reducers: {
    setConnectionPublicProfiles: (
      state,
      action: PayloadAction<FriendsPublicProfile[]>
    ) => {
      return { ...state, publicProfiles: action.payload };
    },
    resetConnectionState: (state) => {
      return { ...initialState };
    },
    setCurrentConnectionTraitData: (
      state,
      action: PayloadAction<{
        currentTraitsUid: string;
        discoveredElements: ElementDiscovery[];
        discoveredPatterns: PatternDiscovery[];
        discoveredArchetypes: ArchetypeDiscovery[];
        isConnectionPremium: boolean;
      }>
    ) => {
      return {
        ...state,
        currentTraitsUid: action.payload.currentTraitsUid,
        discoveredElements: action.payload.discoveredElements,
        discoveredPatterns: action.payload.discoveredPatterns,
        discoveredArchetypes: action.payload.discoveredArchetypes,
        isConnectionPremium: action.payload.isConnectionPremium,
      };
    },
    setSimilarityAndCompatibilityScores: (
      state,
      action: PayloadAction<{ slug: string; score: number | null }[]>
    ) => {
      return {
        ...state,
        similarityAndCompatibilityScores: action.payload,
      };
    },
    setSimilarityAndCompatibilityScoresLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        similarityAndCompatibilityScoresLoading: action.payload,
      };
    },
  },
});

export const {
  setConnectionPublicProfiles,
  setCurrentConnectionTraitData,
  resetConnectionState,
  setSimilarityAndCompatibilityScores,
  setSimilarityAndCompatibilityScoresLoading,
} = connectionSlice.actions;

export default connectionSlice.reducer;
