import Image from "next/image";

export default function ChatImage() {
  return (
    <Image
      width={367}
      height={562}
      alt="Earth's Core"
      src={`/compatibility-snippets/chat.png`}
    />
  );
}
