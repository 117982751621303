import styles from "./GhostBox.module.css";
import WarningIcon from "./WarningIcon";

export default function PrivateTraitGhostbox() {
  return (
    <div className={styles.traitMainDiv}>
      <div className={styles.traitIconDiv}>
        <WarningIcon active />
      </div>
      <p className={styles.privateText}>This is a private trait</p>
    </div>
  );
}
