import {
  FeedItemDetail,
  PrimaryFeedIconType,
} from "@dimensional-engineering/dimensional-models";

import styles from "./NotificationViewModal.module.css";

import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { NotificationProfileImage } from "@/components/shared/SmallProfileImage/SmallProfileImage";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";

export default function FeedItemPrimaryIcon(props: {
  feedItemDetail?: FeedItemDetail | null | undefined;
}) {
  const imageUrl = useGetProfilePhoto(
    props.feedItemDetail?.primaryIcon?.primaryIconDetail?.peerUid
  );
  const publicProfile = useGetPublicProfile(
    props.feedItemDetail?.primaryIcon?.primaryIconDetail?.peerUid
  );

  const iconMapper: Record<PrimaryFeedIconType, JSX.Element> = {
    peerPhoto: (
      <NotificationProfileImage
        imageUrl={imageUrl}
        peerId={props.feedItemDetail?.primaryIcon?.primaryIconDetail?.peerUid}
        nature={publicProfile?.primaryNature?.nature}
      />
    ),
    premiumPlus: <PremiumNotificationIcon />,
    rewardLevelObtained: <RewardLevelNotificationIcon />,
    scoresUpdated: <ScoresUpdatedNotificationIcon />,
    surveyLink: <TypeFormSurveyNotificationIcon />,
    generic: <GenericNotificationIcon />,
    dailyReflection: <DailyReflectionNotificationIcon />,
    peerAssessmentRewardLevelObtained: (
      <>
        <PeerLevelRewardsFeedItemIcon />
      </>
    ),
    weeklyPublicProfileViews: <PublicProfileViewsIcon />,
  };

  return (
    <div>
      {
        iconMapper[
          props.feedItemDetail?.primaryIcon?.iconType ?? "dailyReflection"
        ]
      }
    </div>
  );
}

function PremiumNotificationIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <PremiumPillIcon />
    </div>
  );
}

function RewardLevelNotificationIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <div className={styles.rewardLevelDiv}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_51841_542)">
            <path
              d="M15.9029 11.5584L24 3.4416L20.5678 0L12 8.58744L3.43224 0L0 3.4416L8.09712 11.5584C6.33557 12.7968 5.18424 14.8462 5.18424 17.1646C5.18424 20.9371 8.2368 23.9998 11.9998 23.9998C15.7627 23.9998 18.8153 20.9369 18.8153 17.1646C18.8153 14.8461 17.664 12.7968 15.9024 11.5584H15.9029ZM14.6785 20.9326L12.0001 19.5216L9.3217 20.9326L9.83544 17.9419L7.66606 15.8204L10.6613 15.3835L12 12.6619L13.3387 15.3835L16.3339 15.8204L14.1646 17.9419L14.6785 20.9326Z"
              fill="#FFFFF2"
            />
          </g>
          <defs>
            <clipPath id="clip0_51841_542">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
}

function ScoresUpdatedNotificationIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_51841_2023)">
          <circle cx="26" cy="26" r="26" fill="#55504F" />
          <path
            d="M6.34708 33.0943C4.41032 27.459 4.9263 21.182 7.89683 15.9301C10.8674 10.8067 15.7731 7.22023 21.585 6.06669L21.198 3.12012L27.7841 6.70649L22.619 12.2139L22.1029 9.2673C17.3244 10.2927 13.1932 13.2376 10.7401 17.4657C8.28736 21.9497 7.76961 27.2011 9.44838 32.0673L6.34884 33.0928L6.34708 33.0943Z"
            fill="#FFFFF2"
          />
          <path
            d="M41.8575 39.4994C37.855 44.1113 32.0429 46.8002 25.9747 46.8002C20.0355 46.8002 14.4811 44.3672 10.4789 40.0113L8.41316 42.0604L7.37939 34.6316L14.8686 35.529L12.8028 37.7061C16.1603 41.4205 20.9388 43.5975 25.9732 43.5975C31.1383 43.5975 35.9168 41.2924 39.2743 37.4484L41.8561 39.4976L41.8575 39.4994Z"
            fill="#FFFFF2"
          />
          <path
            d="M29.7188 5.94043C35.787 6.96586 40.9525 10.5524 43.923 15.9318C46.8936 21.0553 47.5386 27.203 45.7308 32.7121L48.1836 34.3773L40.9525 36.8104L40.3075 29.3816L42.7602 30.9188C44.181 26.4348 43.5343 21.5691 41.0815 17.469C38.4997 13.1148 34.2374 10.0402 29.0723 9.14305L29.7173 5.94056L29.7188 5.94043Z"
            fill="#FFFFF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5488 18.853C19.0246 17.8901 17.6522 17.8624 17.0894 18.8032L12.6196 26.2754C12.0661 27.2006 12.7083 28.3803 13.7858 28.4177L22.4876 28.7196C23.5833 28.7576 24.3049 27.5899 23.7808 26.6271L19.5488 18.853ZM18.8056 24.2855C18.5625 23.8389 18.0035 23.674 17.5569 23.9171C17.1104 24.1602 16.9454 24.7193 17.1885 25.1658C17.4315 25.6123 17.9906 25.7772 18.4372 25.5341C18.8837 25.291 19.0487 24.732 18.8056 24.2855Z"
            fill="#FFFFF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.7709 30.4317C29.6524 28.3132 26.2177 28.3132 24.0992 30.4317C21.9807 32.5502 21.9807 35.9849 24.0992 38.1034C26.2177 40.2218 29.6524 40.2218 31.7709 38.1034C33.8894 35.9849 33.8894 32.5502 31.7709 30.4317ZM28.5693 33.6587C28.2189 33.3083 27.6507 33.3083 27.3002 33.6587C26.9498 34.0092 26.9498 34.5774 27.3002 34.9278C27.6507 35.2783 28.2189 35.2783 28.5693 34.9278C28.9198 34.5774 28.9198 34.0092 28.5693 33.6587Z"
            fill="#FFFFF2"
          />
          <rect
            x="36.5493"
            y="26.4094"
            width="3.1644"
            height="10.8494"
            rx="1.5822"
            transform="rotate(-180 36.5493 26.4094)"
            fill="#FFFFF2"
          />
          <rect
            x="32.7236"
            y="26.4092"
            width="3.1644"
            height="10.8494"
            rx="1.5822"
            transform="rotate(-180 32.7236 26.4092)"
            fill="#FFFFF2"
          />
          <rect
            x="28.8701"
            y="26.4089"
            width="3.1644"
            height="10.8494"
            rx="1.5822"
            transform="rotate(-180 28.8701 26.4089)"
            fill="#FFFFF2"
          />
        </g>
        <defs>
          <clipPath id="clip0_51841_2023">
            <rect width="52" height="52" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function TypeFormSurveyNotificationIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <path
          d="M5.00098 28.3335H3.44239C2.46388 28.3288 1.6717 27.5366 1.66699 26.5581V6.1077C1.66934 4.93113 2.13808 3.80262 2.97013 2.9694C3.80332 2.13738 4.93186 1.66863 6.10843 1.66626H23.8927C25.0693 1.6686 26.1978 2.13735 27.031 2.9694C27.8631 3.80259 28.3318 4.93113 28.3342 6.1077V20.4912C28.3318 21.6689 27.8631 22.7975 27.031 23.6295C26.1978 24.4627 25.0693 24.9314 23.8927 24.9326H8.69233L7.21693 27.1498C6.72358 27.8905 5.89159 28.3347 5.00098 28.3335ZM6.1084 3.4416C4.63654 3.4416 3.44239 4.63575 3.44239 6.10761V26.558H5.00098C5.29981 26.5592 5.57872 26.408 5.74279 26.1584L7.21819 23.9506L7.21702 23.9494C7.53577 23.4397 8.09125 23.1268 8.69242 23.1162H23.8928C24.5995 23.1162 25.278 22.835 25.7784 22.3357C26.2776 21.8353 26.5589 21.1568 26.5589 20.4502V6.10749C26.5589 5.40084 26.2776 4.72233 25.7784 4.22193C25.278 3.7227 24.5995 3.44145 23.8928 3.44145L6.1084 3.4416Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4681 6.99951L11.1513 7.84209L12.1591 8.22528L11.5673 9.13347L11.5181 10.2163L10.4681 9.9327L9.42628 10.2163L9.36769 9.13347L8.77588 8.22528L9.79306 7.84209L10.4681 6.99951Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9096 6.99951L15.5928 7.84209L16.6006 8.22528L16.0088 9.13347L15.9596 10.2163L14.9096 9.9327L13.8678 10.2163L13.8174 9.13347L13.2256 8.22528L14.2346 7.84209L14.9096 6.99951Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3586 6.99951L20.0336 7.84209L21.0507 8.22528L20.4589 9.13347L20.4003 10.2163L19.3585 9.9327L18.3167 10.2163L18.2581 9.13347L17.6675 8.22528L18.6753 7.84209L19.3586 6.99951Z"
          fill="#FFFFF2"
        />
        <path d="M7.8916 13.2246H22.9993V15H7.8916V13.2246Z" fill="#FFFFF2" />
        <path
          d="M7.8916 17.6672H15.0001V19.4426H7.8916V17.6672Z"
          fill="#FFFFF2"
        />
      </svg>
    </div>
  );
}

function GenericNotificationIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="13"
        viewBox="0 0 42 13"
        fill="none"
      >
        <rect
          x="14.4619"
          width="3.57292"
          height="12.25"
          rx="1.78646"
          fill="#FFFFF2"
        />
        <rect
          x="18.7822"
          width="3.57291"
          height="12.25"
          rx="1.78645"
          fill="#FFFFF2"
        />
        <rect
          x="23.1318"
          width="3.57292"
          height="12.25"
          rx="1.78646"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.12501 12.25C9.50775 12.25 12.25 9.50774 12.25 6.125C12.25 2.74226 9.50775 0 6.12501 0C2.74226 0 0 2.74226 0 6.125C0 9.50774 2.74226 12.25 6.12501 12.25ZM6.10425 7.11747C6.66386 7.11747 7.1175 6.66382 7.1175 6.10421C7.1175 5.5446 6.66386 5.09095 6.10425 5.09095C5.54465 5.09095 5.091 5.5446 5.091 6.10421C5.091 6.66382 5.54465 7.11747 6.10425 7.11747Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.2137 11.9614C41.4515 11.9614 42.2199 10.6153 41.5907 9.54926L36.5937 1.08291C35.9749 0.0345367 34.4584 0.0345379 33.8396 1.08291L28.8426 9.54926C28.2134 10.6153 28.9818 11.9614 30.2196 11.9614H40.2137ZM35.2276 8.29168C35.8016 8.29168 36.2669 7.8263 36.2669 7.25221C36.2669 6.67812 35.8016 6.21273 35.2276 6.21273C34.6535 6.21273 34.1882 6.67812 34.1882 7.25221C34.1882 7.8263 34.6535 8.29168 35.2276 8.29168Z"
          fill="#FFFFF2"
        />
      </svg>
    </div>
  );
}

function DailyReflectionNotificationIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
      >
        <circle opacity="0.15" cx="26" cy="26" r="26" fill="#FFFFF2" />
        <circle
          cx="26.0002"
          cy="26"
          r="19.76"
          fill="#FFFFF2"
          fillOpacity="0.2"
        />
        <circle
          cx="25.9999"
          cy="25.9999"
          r="14.56"
          fill="#FFFFF2"
          fillOpacity="0.2"
        />
        <circle cx="26.0001" cy="26.0001" r="10.4" fill="#FFFCB3" />
      </svg>
    </div>
  );
}

function PeerLevelRewardsFeedItemIcon() {
  return (
    <div className={styles.primariIconDiv}>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_57093_33291)">
          <circle cx="26" cy="26" r="26" fill="#55504F" />
          <circle
            cx="26"
            cy="26"
            r="22"
            fill="url(#paint0_linear_57093_33291)"
          />
          <g clipPath="url(#clip1_57093_33291)">
            <path
              d="M29.9029 25.5584L38 17.4416L34.5678 14L26 22.5874L17.4322 14L14 17.4416L22.0971 25.5584C20.3356 26.7968 19.1842 28.8462 19.1842 31.1646C19.1842 34.9371 22.2368 37.9998 25.9998 37.9998C29.7627 37.9998 32.8153 34.9369 32.8153 31.1646C32.8153 28.8461 31.664 26.7968 29.9024 25.5584H29.9029ZM28.6785 34.9326L26.0001 33.5216L23.3217 34.9326L23.8354 31.9419L21.6661 29.8204L24.6613 29.3835L26 26.6619L27.3387 29.3835L30.3339 29.8204L28.1646 31.9419L28.6785 34.9326Z"
              fill="#FFFFF2"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_57093_33291"
            x1="4.32507"
            y1="26"
            x2="48.3251"
            y2="26"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#19896E" />
            <stop offset="1" stop-color="#229D3D" />
          </linearGradient>
          <clipPath id="clip0_57093_33291">
            <rect width="52" height="52" fill="white" />
          </clipPath>
          <clipPath id="clip1_57093_33291">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(14 14)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

function PublicProfileViewsIcon() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 52C40.3594 52 52 40.3594 52 26C52 11.6406 40.3594 0 26 0C11.6406 0 0 11.6406 0 26C0 40.3594 11.6406 52 26 52Z"
        fill="#55504F"
      />
      <path
        d="M37.6472 26.8508C37.6549 27.623 37.1868 28.2858 36.454 28.5396L30.6384 30.5575L28.5843 36.3348C28.3295 37.0544 27.6732 37.5182 26.9076 37.5182H26.8901C26.1168 37.5106 25.4606 37.0337 25.2177 36.3031L20.8187 23.0655C20.6076 22.429 20.7705 21.7388 21.243 21.263C21.7199 20.784 22.4166 20.6166 23.0619 20.8266L36.421 25.1851C37.1582 25.4246 37.6396 26.0786 37.6472 26.8508ZM17.823 16.5401C17.4795 16.2 16.925 16.2021 16.5859 16.5456C16.2458 16.889 16.248 17.4435 16.5914 17.7837L18.2134 19.3904C18.3841 19.56 18.6061 19.6442 18.8292 19.6442C19.0545 19.6442 19.2798 19.5578 19.4505 19.385C19.7906 19.0415 19.7884 18.4881 19.445 18.1479L17.823 16.5401ZM21.9695 18.3612C22.4529 18.3612 22.8445 17.9696 22.8445 17.4862V15.2134C22.8445 14.7299 22.4529 14.3384 21.9695 14.3384C21.486 14.3384 21.0945 14.7299 21.0945 15.2134V17.4862C21.0945 17.9696 21.486 18.3612 21.9695 18.3612ZM25.1741 19.2636C25.3961 19.2636 25.6192 19.1793 25.7899 19.0098L27.4119 17.4031C27.7554 17.0629 27.7575 16.5095 27.4174 16.166C27.0772 15.8215 26.5227 15.8204 26.1803 16.1606L24.5583 17.7673C24.2148 18.1074 24.2127 18.6609 24.5528 19.0043C24.7235 19.1771 24.9488 19.2636 25.1741 19.2636ZM18.3961 21.894C18.3961 21.4106 18.0046 21.019 17.5211 21.019H15.2275C14.7441 21.019 14.3525 21.4106 14.3525 21.894C14.3525 22.3775 14.7441 22.769 15.2275 22.769H17.5211C18.0046 22.769 18.3961 22.3775 18.3961 21.894ZM17.8164 24.4479L16.1944 26.0557C15.851 26.3959 15.8488 26.9504 16.1889 27.2928C16.3607 27.4656 16.5849 27.552 16.8102 27.552C17.0333 27.552 17.2553 27.4678 17.426 27.2982L19.048 25.6904C19.3915 25.3503 19.3937 24.7968 19.0535 24.4534C18.7133 24.1088 18.1588 24.1067 17.8164 24.4479Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
