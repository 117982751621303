/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Nature } from "@dimensional-engineering/dimensional-models";

import styles from "./Histogram.module.css";

import { ProfileColorMap } from "@/components/shared/ColorMap";
import useIsMobile from "@/helpers/useIsMobile";
import { RootState } from "@/redux/store";

type Props = {
  data: number[] | null | undefined;
  score: number | null | undefined;
  averageUserScore?: number | null | undefined;
  connectionAverageScore?: number | null | undefined;
  imageUrl?: string;
  nature?: Nature | null | undefined;
};

export default function SnippetsHistogram(props: Props) {
  const [multiplier, setMultiplier] = useState<number>(0);
  const isMobile = useIsMobile();
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let high = 0;
    if (props.data) {
      props.data.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(210 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.data?.map((point, index) => {
    return (
      <div
        className={styles.histogramBarCompare}
        style={{ height: `${point * 100 * multiplier}px` }}
        key={index}
      ></div>
    );
  });

  if (props.imageUrl) {
    return (
      <>
        <div
          style={{ justifyContent: "space-between" }}
          className={styles.histogramParent}
        >
          {props.score !== null && props.score !== undefined && (
            <div
              style={
                isMobile
                  ? { right: `${props.score * 99}%` }
                  : { right: `${props.score * 95}%` }
              }
              className={styles.averageScoreDiv}
            >
              <div
                style={
                  props
                    ? {
                        backgroundColor: `${
                          ProfileColorMap[
                            props.nature as keyof typeof ProfileColorMap
                          ]
                        }`,
                      }
                    : { backgroundColor: "#55504F" }
                }
                className={styles.userScoreLine}
              ></div>
              <div
                style={
                  props
                    ? {
                        border: `3px solid ${
                          ProfileColorMap[
                            props.nature as keyof typeof ProfileColorMap
                          ]
                        }`,
                      }
                    : { border: "3px solid #55504F" }
                }
                className={styles.userScoreCircle}
              >
                <img src={props.imageUrl} alt="" />
              </div>
            </div>
          )}
          {props.averageUserScore && (
            <div
              style={
                isMobile
                  ? { right: `${props.averageUserScore * 100 - 4}%` }
                  : { right: `${props.averageUserScore * 100 - 9}%` }
              }
              className={styles.averageScoreDiv}
            >
              <div className={styles.averageScoreLine}></div>
              <div className={styles.averageScoreCircle}></div>
            </div>
          )}
          {props.connectionAverageScore && (
            <div
              style={
                isMobile
                  ? { right: `${props.connectionAverageScore * 100 - 4}%` }
                  : { right: `${props.connectionAverageScore * 100 - 9}%` }
              }
              className={styles.averageScoreDiv}
            >
              <div className={styles.connectionAverageScoreLine}></div>
              <div className={styles.connectionAverageScoreCircle}></div>
            </div>
          )}
          {mapped}
        </div>
        <div style={{ paddingRight: "12px" }} className={styles.labels}>
          <p>0</p>
          <p>25</p>
          <p>50</p>
          <p>75</p>
          <p>100</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        style={{ justifyContent: "space-between" }}
        className={styles.histogramParent}
      >
        {props.score !== null && props.score !== undefined && (
          <div
            style={
              isMobile
                ? { right: `${props.score * 99}%` }
                : { right: `${props.score * 95}%` }
            }
            className={styles.averageScoreDiv}
          >
            <div
              style={
                userState.publicProfileData?.primaryNature
                  ? {
                      backgroundColor: `${
                        ProfileColorMap[
                          userState.publicProfileData?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { backgroundColor: "#55504F" }
              }
              className={styles.userScoreLine}
            ></div>
            <div
              style={
                userState.publicProfileData?.primaryNature
                  ? {
                      border: `3px solid ${
                        ProfileColorMap[
                          userState.publicProfileData?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: "3px solid #55504F" }
              }
              className={styles.userScoreCircle}
            >
              <img src={userState?.imageURL as string} alt="" />
            </div>
          </div>
        )}
        {props.averageUserScore && (
          <div
            style={
              isMobile
                ? { right: `${props.averageUserScore * 100 - 4}%` }
                : { right: `${props.averageUserScore * 100 - 9}%` }
            }
            className={styles.averageScoreDiv}
          >
            <div className={styles.averageScoreLine}></div>
            <div className={styles.averageScoreCircle}></div>
          </div>
        )}
        {props.connectionAverageScore && (
          <div
            style={
              isMobile
                ? { right: `${props.connectionAverageScore * 100 - 4}%` }
                : { right: `${props.connectionAverageScore * 100 - 9}%` }
            }
            className={styles.averageScoreDiv}
          >
            <div className={styles.connectionAverageScoreLine}></div>
            <div className={styles.connectionAverageScoreCircle}></div>
          </div>
        )}
        {mapped}
      </div>
      <div style={{ paddingRight: "12px" }} className={styles.labels}>
        <p>0</p>
        <p>25</p>
        <p>50</p>
        <p>75</p>
        <p>100</p>
      </div>
    </>
  );
}
