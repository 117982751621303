import styles from "./Rewards.module.css";

export default function RewardsScoreBar(props: { level: number }) {
  return (
    <div className={styles.scoreBarWrapper}>
      <div
        className={
          props.level >= 1 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
      <div
        className={
          props.level >= 2 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
      <div
        className={
          props.level >= 3 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
      <div
        className={
          props.level >= 4 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
    </div>
  );
}
