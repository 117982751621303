export default function ConnectionsMostLikeYouIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18.8895"
        cy="24.8895"
        r="10.4628"
        stroke="#FFFFF2"
        strokeWidth="2.85348"
      />
      <circle
        cx="30.8895"
        cy="24.8895"
        r="10.4628"
        stroke="#928E8C"
        strokeWidth="2.85348"
      />
    </svg>
  );
}
