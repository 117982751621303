import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db } from "@/_firebase/firebaseConfig";
import { CommentWithReply } from "@/models/sharedModels";
import {
  CommentReply,
  Comment,
  CommentVote,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  startAfter,
} from "firebase/firestore";
import { HttpsCallableResult } from "firebase/functions";
import { getPublicProfileAnImageUrlDataFromUid } from "../utils";
import moment from "moment";
import { ResolvedReply } from "./CommentThread/CommentThread";

export async function getCommentsThread(commentThreadId: string): Promise<{
  comments: CommentWithReply[];
  lastComment: QueryDocumentSnapshot<DocumentData> | null;
}> {
  const ref = collection(db, `/commentThreads/${commentThreadId}/comments`);
  const q = query(ref, limit(20), orderBy("searchIndex", "desc"));
  const comments: CommentWithReply[] = [];
  let lastComment: QueryDocumentSnapshot<DocumentData> | null = null;

  await getDocs(q)
    .then((res) => {
      if (!res.empty) {
        res.forEach((d) => {
          const data = d.data() as Comment;
          comments.push(data);
        });
        lastComment = res.docs[res.docs.length - 1];
      }
    })
    .catch((err) => {
      console.log(err);
    });

  let promises: Promise<{
    replies: CommentReply[];
    lastReply: QueryDocumentSnapshot<DocumentData> | null;
  }>[] = [];

  comments.forEach((c) => {
    const replyRef = collection(
      db,
      `/commentThreads/${commentThreadId}/comments/${c.id}/commentReplies`
    );
    const replyQuery = query(replyRef, limit(5), orderBy("createdOn", "asc"));

    promises.push(
      getDocs(replyQuery).then((res) => {
        let replies: CommentReply[] = [];
        let lastReply: QueryDocumentSnapshot<DocumentData> | null = null;

        if (!res.empty) {
          res.forEach((d) => {
            const data = d.data() as CommentReply;
            replies.push(data);
          });
          lastReply = res.docs[res.docs.length - 1]; // Set the last reply document snapshot
        }

        return { replies, lastReply };
      })
    );
  });

  const allReplies = await Promise.all(promises);

  for (let i = 0; i < comments.length; i++) {
    const replyData = allReplies[i];
    comments[i].replies = replyData.replies;
    comments[i].lastReply = replyData.lastReply; // Set the last reply for the comment
  }

  return { comments, lastComment };
}

export async function createComment(
  threadId: string,
  body: string
): Promise<HttpsCallableResult<unknown>> {
  const call = callFirebaseFunctions("createComment");
  return call({ threadId: threadId, body: body });
}

export async function replyToComment(
  threadId: string,
  parentCommentId: string,
  taggedMemberId: string,
  body: string
) {
  const call = callFirebaseFunctions("createCommentReply");
  return call({
    threadId: threadId,
    parentCommentId: parentCommentId,
    body: body,
    taggedMemberId: taggedMemberId,
  });
}

export async function fetchMoreThreads(
  lastCommentSnapShot: QueryDocumentSnapshot<DocumentData> | null,
  commentThreadId: string
) {
  const ref = collection(db, `/commentThreads/${commentThreadId}/comments`);
  const q = query(
    ref,
    limit(20),
    orderBy("searchIndex", "desc"),
    startAfter(lastCommentSnapShot)
  );
  const comments: CommentWithReply[] = [];
  let lastComment: QueryDocumentSnapshot<DocumentData> | null = null;

  await getDocs(q)
    .then((res) => {
      if (!res.empty) {
        res.forEach((d) => {
          const data = d.data() as Comment;
          comments.push(data);
        });
        lastComment = res.docs[res.docs.length - 1];
      }
    })
    .catch((err) => {
      console.log(err);
    });

  let promises: Promise<{
    replies: CommentReply[];
    lastReply: QueryDocumentSnapshot<DocumentData> | null;
  }>[] = [];

  comments.forEach((c) => {
    const replyRef = collection(
      db,
      `/commentThreads/${commentThreadId}/comments/${c.id}/commentReplies`
    );
    const replyQuery = query(replyRef, limit(5), orderBy("createdOn", "asc"));

    promises.push(
      getDocs(replyQuery).then((res) => {
        let replies: CommentReply[] = [];
        let lastReply: QueryDocumentSnapshot<DocumentData> | null = null;
        if (!res.empty) {
          res.forEach((d) => {
            const data = d.data() as CommentReply;
            replies.push(data);
          });
          lastReply = res.docs[res.docs.length - 1]; // Capture the last reply document snapshot
        }
        return { replies, lastReply };
      })
    );
  });

  const allReplies = await Promise.all(promises);

  for (let i = 0; i < comments.length; i++) {
    comments[i].replies = allReplies[i].replies; // Assign replies to the corresponding comment
    comments[i].lastReply = allReplies[i].lastReply; // Assign lastReply to the corresponding comment
  }

  return { comments: comments, lastComment: lastComment };
}

export async function fetchMoreReplies(
  lastCommentSnapShot: QueryDocumentSnapshot<DocumentData> | null | undefined,
  commentThreadId: string,
  parentCommentId: string
) {
  const replyRef = collection(
    db,
    `/commentThreads/${commentThreadId}/comments/${parentCommentId}/commentReplies`
  );
  const replyQuery = query(
    replyRef,
    orderBy("createdOn", "asc"),
    startAfter(lastCommentSnapShot),
    limit(10)
  );

  let replies: CommentReply[] = [];
  let lastReply: QueryDocumentSnapshot<DocumentData> | null = null;

  await getDocs(replyQuery).then((res) => {
    if (!res.empty) {
      res.forEach((d) => {
        const data = d.data() as CommentReply;
        replies.push(data);
      });
      lastReply = res.docs[res.docs.length - 1];
    }
  });

  const resolvedReplies: ResolvedReply[] = await Promise.all(
    replies.map(async (reply) => {
      const replyData = await getPublicProfileAnImageUrlDataFromUid(
        reply.author
      );
      const replyFormatted = moment(reply.createdOn).format("M.DD.YY");

      return {
        replyData,
        replyFormatted,
        reply,
      };
    })
  );

  return { resolvedReplies, lastReply };
}

export async function likeOrUnlikeComment(
  uid: string | undefined,
  commentId: string,
  commentThreadId: string,
  voteScore: number,
  replyId?: string | null | undefined
) {
  if (voteScore > 1 || voteScore < -1) {
    return;
  }

  if (window.Android) {
    window.Android.onHaptic();
  }

  const mutatedCommentId = replyId
    ? `${commentThreadId}-${commentId}-${replyId}`
    : `${commentThreadId}-${commentId}`;

  const payload: CommentVote = {
    commentId: commentId,
    commentThreadId: commentThreadId,
    voteScore: voteScore,
    replyId: replyId ?? null,
  };

  if (uid) {
    const ref = doc(db, `/members/${uid}/commentVotes/${mutatedCommentId}`);
    return await setDoc(ref, payload);
  } else {
    return;
  }
}

export async function deleteComment(
  threadId: string,
  commentId: string,
  replyId?: string | null | undefined
) {
  const call = callFirebaseFunctions("deleteComment");
  return call({ threadId: threadId, commentId: commentId, replyId: replyId });
}
