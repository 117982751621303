import { DimensionIconProps } from "@/models/sharedModels";

export default function VibeAsFriendsIcon(props: DimensionIconProps) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "100%"}
      height={props.maxWidth ? "100%" : "100%"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7 4C13.2088 4 12 5.20883 12 6.7V14.6328C12 16.124 13.2088 17.3328 14.7 17.3328H19.9672C21.4584 17.3328 22.6672 18.5416 22.6672 20.0328V25.3C22.6672 26.7912 23.876 28 25.3672 28H33.3C34.7912 28 36 26.7912 36 25.3V6.7C36 5.20883 34.7912 4 33.3 4H14.7Z"
        fill="#FFFFF2"
      />
      <path
        d="M4 22.7C4 21.2088 5.20883 20 6.7 20H17.3C18.7912 20 20 21.2088 20 22.7V33.3C20 34.7912 18.7912 36 17.3 36H6.7C5.20883 36 4 34.7912 4 33.3V22.7Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
