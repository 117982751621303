import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilitySexAttitudesIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43248_20927)">
        <path
          d="M36.0007 4.3335H20.1673C15.9677 4.3335 11.9403 6.00217 8.97157 8.97108C6.00282 11.94 4.33398 15.9675 4.33398 20.1668H20.1673C15.9677 20.1668 11.9403 21.8355 8.97157 24.8044C6.00282 27.7733 4.33398 31.8009 4.33398 36.0002H20.1673C24.367 36.0002 28.3943 34.3315 31.3631 31.3626C34.3318 28.3937 36.0007 24.3661 36.0007 20.1668H20.1737C24.372 20.1656 28.3984 18.4957 31.3659 15.527C34.3334 12.5583 36.001 8.53186 36.001 4.3335L36.0007 4.3335Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_43248_20927">
          <rect
            width="31.6667"
            height="31.6667"
            fill="white"
            transform="translate(4.33398 4.3335)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
