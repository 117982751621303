export default function AudienceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_64488_2428)">
        <path
          d="M10.0006 6.25849e-05C8.6102 6.25849e-05 7.35655 0.837469 6.82433 2.12166C6.29211 3.40706 6.58629 4.88534 7.57017 5.86791C8.55283 6.85178 10.0311 7.14597 11.3164 6.61375C12.6006 6.08153 13.438 4.82787 13.438 3.4375C13.438 1.53931 11.8988 6.25849e-05 10.0006 6.25849e-05ZM10.0006 5.00006C9.36826 5.00006 8.79939 4.61919 8.55648 4.03572C8.31478 3.45222 8.44906 2.77962 8.89583 2.33284C9.34261 1.88606 10.0152 1.75178 10.5987 1.9935C11.1822 2.23642 11.563 2.80528 11.563 3.43759C11.563 3.85141 11.3983 4.24937 11.1053 4.54234C10.8123 4.83531 10.4144 5.00006 10.0006 5.00006Z"
          fill="#E6E1DF"
        />
        <path
          d="M19.6877 18.0629C19.6804 16.4564 18.8906 14.955 17.5723 14.0382L17.2439 13.8075L17.5564 13.545V13.5438C18.605 12.6417 19.0115 11.1976 18.5855 9.88038C18.1606 8.56447 16.9863 7.63063 15.6082 7.51344C14.2288 7.39503 12.9141 8.11525 12.2707 9.34084C11.6273 10.5664 11.7824 12.0569 12.6625 13.1249L12.9189 13.4374L12.5526 13.6095H12.5539C11.5846 14.0636 10.7863 14.8144 10.2724 15.7531L10.0002 16.2499L9.7255 15.7409C9.21159 14.8022 8.41203 14.0514 7.444 13.5973L7.08143 13.4374L7.33778 13.1249C8.22034 12.058 8.37781 10.5651 7.7345 9.33833C7.09241 8.11152 5.7765 7.39008 4.39575 7.50849C3.01634 7.6269 1.84203 8.56196 1.41725 9.8803C0.992469 11.1986 1.40138 12.6427 2.45362 13.5437L2.76612 13.8062L2.43775 14.0369V14.0381C1.11572 14.9524 0.322281 16.4551 0.3125 18.0628V19.0626C0.3125 19.5801 0.732437 20.0001 1.25 20.0001C1.76756 20.0001 2.1875 19.5801 2.1875 19.0626V18.0628C2.20581 16.3551 3.60475 14.9842 5.3125 15.0001C6.68212 14.994 7.8955 15.8814 8.30566 17.1876C8.54125 17.9249 9.22606 18.4241 10 18.4241C10.7739 18.4241 11.4587 17.9248 11.6943 17.1876C12.1045 15.8814 13.3179 14.994 14.6875 15.0001C16.3952 14.9842 17.7942 16.355 17.8125 18.0628V19.0626C17.8125 19.5801 18.2324 20.0001 18.75 20.0001C19.2676 20.0001 19.6875 19.5801 19.6875 19.0626L19.6877 18.0629ZM4.68774 12.5001C4.05543 12.5001 3.48655 12.1192 3.24365 11.5357C3.00195 10.9522 3.13623 10.2796 3.58299 9.83285C4.02977 9.38607 4.70237 9.25179 5.28587 9.49351C5.86937 9.73643 6.25021 10.3053 6.25021 10.9376C6.25021 11.3514 6.08542 11.7494 5.79246 12.0424C5.4995 12.3353 5.10158 12.5001 4.68774 12.5001ZM15.3127 12.5001C14.6804 12.5001 14.1116 12.1192 13.8686 11.5357C13.6269 10.9522 13.7612 10.2796 14.208 9.83285C14.6548 9.38607 15.3274 9.25179 15.9109 9.49351C16.4944 9.73643 16.8752 10.3053 16.8752 10.9376C16.8752 11.3514 16.7104 11.7494 16.4175 12.0424C16.1245 12.3353 15.7266 12.5001 15.3127 12.5001Z"
          fill="#E6E1DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_64488_2428">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
