/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./DiscoveryGate.module.css";

import { RootState } from "@/redux/store";
import { ProfileColorMap } from "../ColorMap";
import DiscoveryCheckMark from "./DiscoveryCheckMark";
import ConnectionsLeastLikeYouIcon from "@/components/results/Snippets/snippetsIcons/ConnectionsLeastLikeYouIcon";

export default function UserConnectionGate(props: {
  numberOfConnectionsRequired: number;
  onOpenShareLink: () => void;
}) {
  const [emptyImages, setEmptyImages] = useState<number>(0);
  const [invitesFull, setInvitesFull] = useState<boolean>(false);
  const connectionListWithFriendDetails = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );

  useEffect(() => {
    if (connectionListWithFriendDetails?.length) {
      if (
        props.numberOfConnectionsRequired >
        connectionListWithFriendDetails.length
      ) {
        setEmptyImages(
          props.numberOfConnectionsRequired -
            connectionListWithFriendDetails.length
        );
      } else if (
        connectionListWithFriendDetails.length >=
        props.numberOfConnectionsRequired
      ) {
        setInvitesFull(true);
      } else {
        setInvitesFull(false);
      }
    } else {
      setEmptyImages(props.numberOfConnectionsRequired);
    }
  }, [
    connectionListWithFriendDetails?.length,
    props.numberOfConnectionsRequired,
  ]);

  const mapped = [
    connectionListWithFriendDetails,
    new Array(emptyImages).fill(" "),
  ]
    .flat()
    .map((value, i) => {
      if (value === undefined) {
        return;
      }
      if (value === " ") {
        return (
          <div className={styles.emptyImageDiv} key={i}>
            <p>{i + 1}</p>
          </div>
        );
      } else {
        return (
          <div className={styles.imageDiv} key={i}>
            <img
              style={
                value?.primaryNature
                  ? {
                      border: `solid 3px ${
                        ProfileColorMap[
                          value.primaryNature
                            ?.nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: `solid 3px grey` }
              }
              className={styles.image}
              src={value?.imageUrl ?? "/default-image.png"}
              alt=""
            />
          </div>
        );
      }
    });

  if (invitesFull) {
    return (
      <div className={styles.tableHeader}>
        Connections{" "}
        <div className={styles.checkmarkCompleteDiv}>
          <DiscoveryCheckMark backgroundColor="#e6e1df" checkColor="#2D2927" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.tableHeader}>Connections</div>
      <div className={styles.friendSignupWrapper}>
        <p className={styles.friendSignupText}>
          {props.numberOfConnectionsRequired} connections required.
        </p>
        <div className={styles.imageParent}>{mapped}</div>
        <div className={styles.inviteButtonDiv}>
          <button
            onClick={() => props.onOpenShareLink()}
            className={styles.inviteButton}
          >
            Invite a friend
          </button>
        </div>
      </div>
    </>
  );
}
