import { useModalContext } from "@/context/ModalContext";
import styles from "./GetPlusButton.module.css";
import ConversionModal from "../ConversionModal/ConversionModal";
import { Mixpanel } from "@/helpers/mixpanel";
import { useRouter } from "next/router";

export default function GetPlusButton(props: {
  source: string;
  trait?: boolean;
}) {
  const { setModalComponent } = useModalContext();
  const router = useRouter();
  return (
    <button
      onClick={() => {
        setModalComponent(
          <ConversionModal initialSlide={props.source === "Trait" ? 1 : 0} />
        );
        Mixpanel?.track("Premium Conversion Modal Summoned", {
          source: props.source,
          trait_slug:
            props.source === "Trait" ? router.query.traitSlug : undefined,
        });
      }}
      className={styles.button}
    >
      Get Plus
    </button>
  );
}
