import { useEffect, useState } from "react";
import {
  AggregatePeerScore,
  ScoreConfidence,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import { useAuth } from "@/context/AuthContext";
import { RootState } from "@/redux/store";
import { getAggregatePeerPercentileScore } from "@/components/peerAssessments/utils";

export default function useGetPeerRatedData(slug: string) {
  const { user } = useAuth();
  const [AggregatePeerScore, setAggregatePeerScore] =
    useState<AggregatePeerScore | null>(null);
  const [peerScore, setPeerScore] = useState<number | null | undefined>();
  const [accuracy, setAccuracy] = useState<ScoreConfidence | null>(null);
  const elementDiscoveries = useSelector(
    (state: RootState) => state.user.elementsDiscovered
  );
  const aggregatePeerScores = useSelector(
    (state: RootState) => state.user.aggregatePeerScores
  );

  useEffect(() => {
    const peerElement = aggregatePeerScores?.find((a) => a.traitSlug === slug);
    if (peerElement) {
      setAggregatePeerScore(peerElement);
      setPeerScore(getAggregatePeerPercentileScore(peerElement) * 100);
      if (peerElement.raterUids) {
        if (peerElement.raterUids.length < 3) {
          setAccuracy(null);
        } else if (peerElement.raterUids.length <= 4) {
          setAccuracy(ScoreConfidence.low);
        } else if (peerElement.raterUids.length <= 6) {
          setAccuracy(ScoreConfidence.medium);
        } else {
          setAccuracy(ScoreConfidence.high);
        }
      } else {
        setAccuracy(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return { AggregatePeerScore, peerScore, accuracy };
}
