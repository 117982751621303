import { useState } from "react";
import styles from "./ShowAllOrLessButton.module.css";

type Props = {
  onClick: (value: boolean) => void;
};

export default function ShowAllOrLessButton(props: Props) {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <button
      className={styles.button}
      onClick={() => {
        setExpanded(!expanded);
        props.onClick(!expanded);
      }}
    >
      {expanded ? "Show less" : "Show all"}
      <div className={expanded ? styles.iconDivReverse : styles.iconDiv}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M7.92 12.6363C7.66563 12.6369 7.42125 12.5357 7.24125 12.3551L1.24493 6.35875C0.870558 5.98375 0.871182 5.37688 1.24555 5.00187C1.61992 4.62751 2.22742 4.62687 2.60243 5.00125L7.91987 10.3187L13.2373 5.00125C13.6123 4.62688 14.2198 4.6275 14.5942 5.00187C14.9686 5.37688 14.9692 5.98375 14.5948 6.35875L8.5985 12.3551C8.4185 12.5357 8.17413 12.6369 7.91974 12.6363H7.92Z"
            fill="#FFFFF2"
          />
        </svg>
      </div>
    </button>
  );
}
