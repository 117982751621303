import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityInteractionStylesIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.8713 25.3459C35.0575 24.14 34.263 22.9288 34.0207 21.4704C33.7478 19.8238 34.2828 18.1367 35.0887 16.6712C36.2935 14.4798 38.0842 12.8831 38.3048 10.2883C38.4913 8.09933 37.7369 5.80933 36.1568 4.24643C33.8468 1.96178 29.971 1.42715 27.1183 2.99897C25.799 3.72725 24.716 4.83512 23.3817 5.54202C21.2642 6.66355 18.5692 6.63504 16.4769 5.46895C15.3921 4.86423 14.4865 3.99278 13.4785 3.274C10.7301 1.32023 6.85554 1.69269 4.33686 3.85081C1.84997 5.9816 0.918656 9.51194 2.32072 12.5071C2.89882 13.7438 3.81574 14.7881 4.59952 15.9079C6.1695 18.1486 6.61207 20.915 5.30416 23.4076C4.58453 24.7792 3.42114 25.8787 2.66554 27.2307C1.07158 30.0821 1.64607 33.9564 3.99324 36.2375C6.34041 38.5186 10.2659 39.0039 13.1085 37.3661C14.339 36.658 15.3519 35.6179 16.5956 34.9306C18.6735 33.7871 21.3415 33.7746 23.432 34.8979C24.7064 35.5882 25.7438 36.6456 27.008 37.3584C29.9698 39.0265 33.6921 38.3926 36.071 36.023C38.0722 34.0289 38.5459 31.1609 37.6566 28.5382C37.2626 27.3787 36.5598 26.3652 35.8713 25.3459ZM20.0516 27.548C19.0797 27.5483 18.1173 27.3591 17.2193 26.9909C16.3212 26.6228 15.5052 26.083 14.8179 25.4023C14.1305 24.7217 13.5852 23.9136 13.2132 23.0242C12.8412 22.1348 12.6497 21.1815 12.6497 20.2188C12.6497 19.2561 12.8412 18.3028 13.2132 17.4133C13.5852 16.5239 14.1305 15.7158 14.8179 15.0352C15.5052 14.3546 16.3212 13.8148 17.2193 13.4467C18.1173 13.0785 19.0797 12.8892 20.0516 12.8896C22.0134 12.8904 23.8946 13.6629 25.2815 15.0373C26.6684 16.4117 27.4475 18.2755 27.4475 20.2188C27.4475 22.1621 26.6684 24.0258 25.2815 25.4002C23.8946 26.7746 22.0134 27.5472 20.0516 27.548Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
