export default function BlindspotsInLoveSNippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7655 10.9197C26.0711 9.33572 20.9323 9.95841 16.728 12.6222C11.6995 15.8202 6.19191 19.321 6.19191 19.321C5.76063 19.5975 5.49818 20.081 5.50001 20.6021C5.50184 21.1231 5.76795 21.6066 6.20107 21.8775L14.8212 27.2635L10.7489 31.4378H10.7507C10.4754 31.7181 10.3194 32.1 10.3194 32.4988C10.3176 32.8976 10.4718 33.2795 10.747 33.5617C11.0223 33.8438 11.3949 34.0019 11.7839 34C12.173 34 12.5455 33.8401 12.819 33.5579L38.1814 7.56215C38.4567 7.28186 38.6109 6.89998 38.6127 6.50118C38.6127 6.10237 38.4585 5.72049 38.1833 5.43833C37.908 5.15615 37.5354 4.99814 37.1464 5.00002C36.7573 5.00002 36.3848 5.15803 36.1113 5.44209L30.7655 10.9197ZM36.1975 13.8374L30.7284 19.4435C31.1432 21.2927 30.6 23.2284 29.2915 24.5699C27.9829 25.9112 26.0945 26.468 24.2903 26.0429L20.2657 30.1683C24.9473 31.7805 30.0838 31.1898 34.3014 28.558C39.295 25.4296 44.7989 21.9796 44.7989 21.9796C45.2302 21.7106 45.4963 21.2309 45.5 20.7117C45.5036 20.1944 45.2467 19.7109 44.8191 19.4325L36.1975 13.8374ZM26.5133 15.2803C24.7643 14.936 22.9658 15.5041 21.7071 16.7927C20.4499 18.0832 19.8957 19.9286 20.2297 21.7193L26.5133 15.2803Z"
        fill="#FFFFF2"
      />
      <path
        d="M31.8801 41.359C32.3011 42.1561 34.9404 44.8252 38 40.9608C41.2131 44.8252 43.6997 42.1561 44.12 41.359C44.8852 39.8851 44.4262 37.6537 43.3547 36.578L37.9993 31L32.6439 36.578C31.5739 37.6537 31.1149 39.8843 31.8801 41.359Z"
        fill="#ED85C2"
      />
    </svg>
  );
}
