import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appInfoReducer from "./slices/appInfoSlice";
import userReducer from "./slices/userSlice";
import connectionReducer from "./slices/connectionSlice";
import assessmentReducer from "./slices/assessmentSlice";
import snippetReducer from "./slices/snippetsSlice";
import personalityAnalyisReducer from "./slices/personalityAnalysisSlice";
import reportsReducer from "./slices/reportSlice";
import feedReducer from "./slices/feedSlice";
import latestResultsReducer from "./slices/latestResultsSlice";
import peerAssessmentReducer from "./slices/peerAssessmentSlice";
import dailyStoryReducer from "./slices/dailyStorySlice";
import compatibilitySnippetsReducer from "./slices/compatibilitySnippetsSlice";
import messagingReducer from "./slices/messagingSlice";
import periodicQuizReducer from "./slices/periodicQuizSlice";
import dailyInsightReducer from "./slices/dailyInsightsSlice";
import commentsReducer from "./slices/commentsSlice";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedAppInfoReducer = persistReducer(persistConfig, appInfoReducer);
const persistedReducer = persistReducer(persistConfig, userReducer);
const persistedSnippet = persistReducer(persistConfig, snippetReducer);
const persistedPersonalityAnalysis = persistReducer(
  persistConfig,
  personalityAnalyisReducer
);
const persistedReportsReducer = persistReducer(persistConfig, reportsReducer);
const persistedFeedReducer = persistReducer(persistConfig, feedReducer);
const persistedPeerAssessmentReducer = persistReducer(
  persistConfig,
  peerAssessmentReducer
);
const persistedDailyStoryReducer = persistReducer(
  persistConfig,
  dailyStoryReducer
);

const persistedPeriodicQuizReducer = persistReducer(
  persistConfig,
  periodicQuizReducer
);

const persistedDailyInsights = persistReducer(
  persistConfig,
  dailyInsightReducer
);

export const store = configureStore({
  reducer: {
    appInfo: persistedAppInfoReducer,
    user: persistedReducer,
    connections: connectionReducer,
    assessment: assessmentReducer,
    snippet: persistedSnippet,
    personalityAnalyis: persistedPersonalityAnalysis,
    report: persistedReportsReducer,
    feed: persistedFeedReducer,
    latestResults: latestResultsReducer,
    peerAssessment: persistedPeerAssessmentReducer,
    dailyStory: persistedDailyStoryReducer,
    compatibilitySnippets: compatibilitySnippetsReducer,
    messaging: messagingReducer,
    periodicQuiz: persistedPeriodicQuizReducer,
    dailyInsight: persistedDailyInsights,
    comments: commentsReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
