import { db } from "@/_firebase/firebaseConfig";
import {
  Nature,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";
import { ProfileColorMap, ProfileSecondaryColorMap } from "./ColorMap";
import { generateWebRecommendationLink } from "@/_firebase/generateDymaicLink";
import { Mixpanel } from "@/helpers/mixpanel";

export function toSentenceCase(input: string): string {
  if (!input) {
    return "";
  }

  const words = input?.split(" ");

  // Capitalize the first word
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  // Lowercase the rest of the words
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].toLowerCase();
  }

  return words.join(" ");
}

export function eclipseString(
  stringToMutate: string | undefined | null,
  maxLength: number
): string | null {
  if (!stringToMutate) {
    return null;
  }
  if (stringToMutate.length > maxLength) {
    return stringToMutate.slice(0, maxLength - 3) + "...";
  } else {
    return stringToMutate;
  }
}

export async function getUserPublicProfile(
  uid: string
): Promise<PublicProfile | null> {
  const publicProfileRef = doc(db, "publicProfiles", uid as string);
  return await getDoc(publicProfileRef)
    .then((res) => {
      if (res.exists()) {
        return res.data() as PublicProfile;
      } else return null;
    })
    .catch(() => {
      return null;
    });
}

export function getConnectionProfileColor(
  userNature: Nature | undefined,
  connectionNature: Nature | undefined
) {
  const connectionColor = connectionNature
    ? connectionNature === userNature
      ? ProfileSecondaryColorMap[
          connectionNature as keyof typeof ProfileSecondaryColorMap
        ]
      : ProfileColorMap[connectionNature as keyof typeof ProfileColorMap]
    : "#55504F";

  return connectionColor;
}

export async function summonProfileLink(
  uid: string | undefined | null,
  source:
    | "connections tab"
    | "rewards modal"
    | "gating modal"
    | "profile"
    | "other"
    | "global search"
    | "dashboard",
  type: "Invite to Dimensional" | "Profile Web URL" | "SP snippet" | "undefined"
) {
  if (uid) {
    Mixpanel?.track("System Share Modal Opened", {
      source: source,
      type: "Invite to Dimensional",
    });
    await generateWebRecommendationLink(uid)
      .then(async (res) => {
        if (window && window.Android) {
          window.Android.onSystemShare(res, true, source, type);
        } else {
          if (navigator.canShare && navigator.canShare()) {
            await navigator.share({
              title: "Dimensional Profile",
              text: "Share your profile",
              url: res,
            });
          }
        }
      })
      .catch(() => {
        generateWebRecommendationLink(uid)
          .then(async (res) => {
            if (window && window.Android) {
              window.Android.onSystemShare(res, true, source, type);
            } else {
              if (navigator.canShare && navigator.canShare()) {
                await navigator.share({
                  title: "Dimensional Profile",
                  text: "Share your profile",
                  url: res,
                });
              }
            }
          })
          .catch(() => {
            generateWebRecommendationLink(uid).then(async (res) => {
              if (window && window.Android) {
                window.Android.onSystemShare(res, true, source, type);
              } else {
                if (navigator.canShare && navigator.canShare()) {
                  await navigator.share({
                    title: "Dimensional Profile",
                    text: "Share your profile",
                    url: res,
                  });
                }
              }
            });
          });
      });
  }
}

export function transformLongUsername(publicProfile: PublicProfile | null) {
  const displayName = publicProfile?.userName as string;

  if (displayName.length <= 13) {
    return displayName;
  }
  return `${displayName.slice(0, 13)}...`;
}

export function openInNewTab(url: string) {
  if (window.Android) {
    try {
      return window.Android.openInNewTab(url);
    } catch (error) {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) return (newWindow.opener = null);
    }
  } else {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) return (newWindow.opener = null);
  }
}
