import { useEffect, useState } from "react";

import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";

export default function useGetIsPremium(uid: string | null | undefined) {
  const [isPremium, setIsPremium] = useState<boolean>(false);

  useEffect(() => {
    if (uid) {
      const call = callFirebaseFunctions("getIsPremium");
      call({ memberUUID: uid })
        .then((res) => {
          setIsPremium(res.data as boolean);
        })
        .catch((err) => {
          console.log(err);
          setIsPremium(false);
        });
    }
  }, [uid]);

  return isPremium;
}
