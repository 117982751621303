import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import styles from "./Snippets.module.css";
import { snippetMappedSubtitles } from "./snippetsMap";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetLlmMatchView(props: Props) {
  const mapped = props.snippet.snippetDetail.llmMatch?.matchGroups.map(
    (detail, index) => {
      return (
        <div
          style={index === 0 ? {} : { border: "0" }}
          className={styles.llmMatchCard}
          key={index}
        >
          <p className={styles.tileNumber}>
            ~{" "}
            {index === 0
              ? "Best match"
              : index === 1
              ? "Runner up"
              : "Third place"}{" "}
            ~
          </p>
          {detail.emojiString && (
            <h4 className={styles.llmListTitle}>{detail.emojiString}</h4>
          )}
          <h4 className={styles.llmListTitle}>{detail.name}</h4>
          <p className={styles.llmListParagraph}>{detail.description}</p>
          <p className={styles.why}>WHY:</p>
          <ul className={styles.ul}>
            {detail.analysis.map((d, i) => {
              return <li key={i}>{d}</li>;
            })}
          </ul>
        </div>
      );
    }
  );

  const mappedRankingList =
    props.snippet.snippetDetail.llmMatch?.rankingList.map((detail, index) => {
      return (
        <div className={styles.rankingListRow} key={index}>
          <p className={styles.rankingListLeft}>{index + 1}</p>
          <p className={styles.rankingListRight}>{detail}</p>
        </div>
      );
    });

  return (
    <>
      {props.snippet.snippetDetail.llmMatch && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          {mapped}
          <div className={styles.rankingListParent}>
            <h3 className={styles.rankingListTitle}>Complete Rankings</h3>
            {mappedRankingList}
          </div>
        </>
      )}
    </>
  );
}

export function SnippetLlmMatchViewOrderedList(props: Props) {
  const mapped = props.snippet.snippetDetail.llmMatch?.matchGroups.map(
    (detail, index) => {
      return (
        <div
          style={index === 0 ? {} : { border: "0" }}
          className={styles.llmMatchCard}
          key={index}
        >
          <p className={styles.tileNumber}>
            ~{" "}
            {index === 0
              ? "Best match"
              : index === 1
              ? "Runner up"
              : "Third place"}{" "}
            ~
          </p>
          {detail.emojiString && (
            <h4 className={styles.llmListTitle}>{detail.emojiString}</h4>
          )}
          <h4 className={styles.llmListTitle}>{detail.name}</h4>
          <p className={styles.llmListParagraph}>{detail.description}</p>
          <p className={styles.why}>WHY:</p>
          <ul className={styles.ul}>
            {detail.analysis.map((d, i) => {
              return <li key={i}>{d}</li>;
            })}
          </ul>
        </div>
      );
    }
  );

  const mappedRankingList =
    props.snippet.snippetDetail.llmMatch?.rankingList.map((detail, index) => {
      return (
        <div className={styles.rankingListRow} key={index}>
          <p className={styles.rankingListLeft}>{index + 1}</p>
          <p className={styles.rankingListRight}>{detail}</p>
        </div>
      );
    });

  return (
    <>
      {props.snippet.snippetDetail.llmMatch && (
        <>
          <h2 className={styles.llmListNamePeer}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          {mapped}
          {mappedRankingList?.length ? (
            <div className={styles.rankingListParent}>
              <h3 className={styles.rankingListTitle}>Complete Rankings</h3>
              {mappedRankingList}
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

export function SnippetLlmMatchViewUnorderedList(props: Props) {
  const mapped = props.snippet.snippetDetail.llmMatch?.matchGroups.map(
    (detail, index) => {
      return (
        <div
          style={{ border: "0", marginTop: "10px" }}
          className={styles.llmMatchCard}
          key={index}
        >
          {detail.emojiString && (
            <h4 className={styles.llmListTitle}>{detail.emojiString}</h4>
          )}
          <h4 className={styles.llmListTitle}>{detail.name}</h4>
          <p className={styles.llmListParagraph}>{detail.description}</p>
        </div>
      );
    }
  );

  const mappedRankingList =
    props.snippet.snippetDetail.llmMatch?.rankingList.map((detail, index) => {
      return (
        <div className={styles.rankingListRow} key={index}>
          <p className={styles.rankingListLeft}>{index + 1}</p>
          <p className={styles.rankingListRight}>{detail}</p>
        </div>
      );
    });

  return (
    <>
      {props.snippet.snippetDetail.llmMatch && (
        <>
          <h2 className={styles.llmListNamePeer}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          {mapped}
          {mappedRankingList?.length ? (
            <div className={styles.rankingListParent}>
              <h3 className={styles.rankingListTitle}>Complete Rankings</h3>
              {mappedRankingList}
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
