/* eslint-disable @next/next/no-img-element */

import styles from "./Rewards.module.css";

import { useGetImagesAndDataInviteGate } from "@/components/shared/DiscoveryGate/UserSuccessInviteGate";
import { ProfileColorMap } from "@/components/shared/ColorMap";

export default function RewardsMappedImages(props: {
  uidList: string[] | [] | null | undefined;
}) {
  const { imagURls, emptyImages, profiles, invitesFull } =
    useGetImagesAndDataInviteGate({
      numberOfInvitesRequired: 5,
      invites: props.uidList,
    });

  const mappedImages = [imagURls, new Array(emptyImages).fill(" ")]
    .flat()
    .map((value, i) => {
      if (value === " ") {
        return (
          <div
            style={i === 0 ? { marginLeft: "0" } : {}}
            className={styles.emptyImageDiv}
            key={i}
          >
            <p>{i + 1}</p>
          </div>
        );
      } else {
        return (
          <div
            style={i === 0 ? { marginLeft: "0" } : {}}
            className={styles.imageDiv}
            key={value}
          >
            <img
              style={
                profiles[i]?.primaryNature
                  ? {
                      border: `solid 3px ${
                        ProfileColorMap[
                          profiles[i].primaryNature
                            ?.nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: `solid 3px grey` }
              }
              className={styles.image}
              src={value}
              alt=""
            />
          </div>
        );
      }
    });

  return <>{mappedImages}</>;
}
