import styles from "./ExpiredErrorModal.module.css";

import WarningIcon from "../GhostBox/WarningIcon";
import { useModalContext } from "@/context/ModalContext";
import CloseButtonSummary from "../CloseButtonSummary";

type Props = {
  body: string;
  ctaBody?: string;
  onClick?: () => void;
};

export default function ExpiredErrorModal(props: Props) {
  const { setModalOpen } = useModalContext();

  return (
    <dialog id="dialog" className={styles.main}>
      <div onClick={() => setModalOpen(false)} className={styles.xmark}>
        <CloseButtonSummary />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.warningIconDiv}>
          <WarningIcon active />
        </div>
        <p className={styles.copy}>{props.body}</p>
        {props.ctaBody && (
          <button
            className={styles.button}
            onClick={() => props.onClick && props.onClick()}
          >
            {props.ctaBody}
          </button>
        )}
      </div>
    </dialog>
  );
}
