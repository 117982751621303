import PrimaryTraitsIcon from "./PrimaryTraitsIcon";
import CognitionIcon from "./CognitionIcon";
import LoveStylesIcon from "./LoveStylesIcon";
import ValuesIcon from "./ValuesIcon";
import WorkStyleIcon from "./WorkStyleIcon";
import LoveAttitudesIcon from "./LoveAttitudesIcon";
import InterestsIcon from "./InterestsIcon";
import ConflictStylesIcon from "./ConflictStylesIcon";
import StrengthsIcon from "./StrengthsIcon";
import AttachmentStyleIcon from "./AttachmentStyleIcon";
import SexAttitudesIcon from "./SexAttitudesIcon";
import PoliticalIdeologyIcon from "./PoliticalIdeologyIcon";
import SecondaryTraitsIcon from "./SecondaryTraitsIcon";
import LifeStyleIcon from "./LifeStyleIcon";
import CopingStyleIcon from "./CopingStyleIcon";

export const iconMapper = {
  personality: <PrimaryTraitsIcon />,
  "personality-II": <CognitionIcon />,
  "love-style": <LoveStylesIcon />,
  values: <ValuesIcon />,
  "communication-style": <WorkStyleIcon />,
  "love-attitudes": <LoveAttitudesIcon />,
  interests: <InterestsIcon />,
  "conflict-style": <ConflictStylesIcon />,
  strengths: <StrengthsIcon />,
  "attachment-style": <AttachmentStyleIcon />,
  "sex-attitudes": <SexAttitudesIcon />,
  "political-ideology": <PoliticalIdeologyIcon />,
  "secondary-traits": <SecondaryTraitsIcon />,
  lifestyle: <LifeStyleIcon />,
  "coping-style": <CopingStyleIcon />,
};

export const iconMapperDark = {
  personality: <PrimaryTraitsIcon dark />,
  "personality-II": <CognitionIcon dark />,
  "love-style": <LoveStylesIcon dark />,
  values: <ValuesIcon dark />,
  "communication-style": <WorkStyleIcon dark />,
  "love-attitudes": <LoveAttitudesIcon dark />,
  interests: <InterestsIcon dark />,
  "conflict-style": <ConflictStylesIcon dark />,
  strengths: <StrengthsIcon dark />,
  "attachment-style": <AttachmentStyleIcon dark />,
  "sex-attitudes": <SexAttitudesIcon dark />,
  "political-ideology": <PoliticalIdeologyIcon dark />,
  "secondary-traits": <SecondaryTraitsIcon dark />,
  lifestyle: <LifeStyleIcon dark />,
  "coping-style": <CopingStyleIcon dark />,
};
