/* eslint-disable @next/next/no-img-element */
import { Archetype as ArchetypeModelNPM } from "@dimensional-engineering/dimensional-models";

import styles from "./LargeArchetype.module.css";
import { archetypeMapper } from "@/assets/traitInfo/dimensionData";

type ArchetypeModel = {
  archetype: ArchetypeModelNPM;
  border?: boolean;
};

export default function LargeArchetypeModal(props: ArchetypeModel) {
  if (props.border) {
    return (
      <div className={styles.architypeBodyModalBorder}>
        <p className={styles.topTextBorder}>
          {archetypeMapper[props.archetype.dimensionSlug].toUpperCase() ?? ""}
        </p>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <clipPath id="ArchetypeImageClipPath">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.9573 47.7643C50.3091 47.7643 51.769 45.2071 50.5736 43.1819L28.1163 5.13656C26.9407 3.14491 24.0593 3.14491 22.8837 5.13656L0.426416 43.1819C-0.769037 45.2071 0.690953 47.7643 3.0427 47.7643H47.9573ZM25.5415 34.6925C27.5865 34.6925 29.2442 33.0347 29.2442 30.9898C29.2442 28.9449 27.5865 27.2872 25.5415 27.2872C23.4966 27.2872 21.8389 28.9449 21.8389 30.9898C21.8389 33.0347 23.4966 34.6925 25.5415 34.6925Z"
              fill="#FFFFF2"
            />
          </clipPath>
        </svg>
        <img
          alt="image"
          className="archetype-image"
          style={{
            clipPath: "url(#ArchetypeImageClipPath)",
            WebkitClipPath: "url(#ArchetypeImageClipPath)",
            top: "38px",
            left: "41px",
          }}
          src={`/archetypes/${props.archetype.slug}.jpg`}
          width="100px"
          height="100px"
        />
        <div className={styles.bottomTextDivBorder}>
          <p className={styles.bottomTextBorder}>
            {props.archetype.archetypeGroupSlug !== "work"
              ? props.archetype.acronym ?? props.archetype.name
              : `${props.archetype.name} (${props.archetype.acronym})`}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.architypeBodyModal}>
      <svg
        width="136"
        height="136"
        viewBox="0 0 136 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="ArchetypeImageClipPathLarge">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.886 127.372C134.157 127.372 138.051 120.553 134.863 115.152L74.9767 13.6978C71.8417 8.38671 64.1583 8.38671 61.0232 13.6978L1.13711 115.152C-2.05077 120.553 1.84254 127.372 8.11386 127.372H127.886ZM68.1108 92.5135C73.5639 92.5135 77.9845 88.0929 77.9845 82.6398C77.9845 77.1867 73.5639 72.7661 68.1108 72.7661C62.6577 72.7661 58.237 77.1867 58.237 82.6398C58.237 88.0929 62.6577 92.5135 68.1108 92.5135Z"
            fill="#FFFFF2"
          />
        </clipPath>
      </svg>
      <img
        alt="image"
        className="archetype-image-large-modal"
        src={`/archetypes/${props.archetype.slug}.jpg`}
        width="145px"
        height="145px"
      />
      <div className={styles.bottomTextDiv}>
        <p className={styles.bottomText}>
          {props.archetype.archetypeGroupSlug !== "work"
            ? props.archetype.acronym ?? props.archetype.name
            : `${props.archetype.name} (${props.archetype.acronym})`}
        </p>
      </div>
    </div>
  );
}
