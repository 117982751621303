import axios from "axios";

export enum View {
  webProfile = "web_profile",
  recommendationLink = "recommendation_link",
  sharedContent = "shared_content",
}

export async function generateDynamicLinkWithNoId(
  view?: View
): Promise<string | undefined> {
  const SHORTLINK_ERROR_FALLBACK = `${
    process.env.NEXT_PUBLIC_SHORTLINK_ERROR_FALLBACK
  }/?view=${view ?? null}`;
  const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`,
      {
        longDynamicLink: `${
          process.env.NEXT_PUBLIC_DYNAMIC_LINK_URL_ONE
        }${null}%26view=${view ?? null}${
          process.env.NEXT_PUBLIC_DYNAMIC_LINK_URL_TWO
        }`,
      }
    )
    .then((res) => {
      if (res?.data?.shortLink) {
        return res.data.shortLink as string;
      } else {
        throw new Error("missing shortLink");
      }
    })
    .catch(() => {
      return SHORTLINK_ERROR_FALLBACK;
    });
}

export async function IOSDynamicTraitLinkGenerator(
  traitSlug: string,
  traitType: "element" | "pattern" | "archetype",
  shareHash: string,
  uid?: string
): Promise<string | undefined> {
  let fallbackURL = "";

  if (traitType === "archetype") {
    fallbackURL = `dimensional.me/traits/archetypes/${traitSlug}`;
  } else if (traitType === "element") {
    fallbackURL = `dimensional.me/traits/elements/${traitSlug}`;
  } else {
    fallbackURL = `dimensional.me/traits/patterns/${traitSlug}`;
  }
  const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

  const subLink = `https://apps.apple.com/app/apple-store/id1520060596?recommenderUid=${uid}%26view=traitPage%26traitType=${traitType}%26traitSlug=${traitSlug}%26shareHash=${shareHash}`;

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`,
      {
        longDynamicLink: `https://profiledimensional.page.link/?link=${subLink}${`&isi=1520060596&ibi=com.dimensional.Dimensional&pt=121710263&ct=webapp&mt=8`}`,
      }
    )
    .then((res) => {
      if (res?.data?.shortLink) {
        return res.data.shortLink as string;
      } else {
        throw new Error("missing shortLink");
      }
    })
    .catch(() => {
      return fallbackURL;
    });
}

//Get rid of when doing Peer Assessments 3
export async function IOSDynamicPeerRatingLinkGenerator(
  sharehash: string,
  uid?: string
): Promise<string | undefined> {
  let fallbackURL = "";
  const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

  const sublink = `https://apps.apple.com/app/apple-store/id1520060596?recommenderUid=${uid}%26view=peerRatingRequest%26shareHash=${sharehash}`;

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`,
      {
        longDynamicLink: `https://ratemedimensional.page.link/?link=${sublink}&isi=1520060596&ibi=com.dimensional.Dimensional`,
      }
    )
    .then((res) => {
      if (res?.data?.shortLink) {
        return res.data.shortLink as string;
      } else {
        throw new Error("missing shortLink");
      }
    })
    .catch(() => {
      return fallbackURL;
    });
}

export async function generateWebRecommendationLink(uid: string, view?: View) {
  let fallbackUrl = `https://www.dimensional.me/?recommenderUid=${uid}&view=recommendation_link`;
  const API_KEY = process.env.NEXT_PUBLIC_API_KEY;
  const sublink = `https://www.dimensional.me/app?recommenderUid=${uid}%26from=web%26view=${
    view ?? `recommendation_link`
  }%26ct=${view ?? "null"}`;

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`,
      {
        longDynamicLink: `https://recommenddimensional.page.link/?link=${sublink}&apn=com.dimensional.dimensional&isi=1520060596&ibi=com.dimensional.Dimensional`,
      }
    )
    .then((res) => {
      if (res?.data?.shortLink) {
        return res.data.shortLink as string;
      } else {
        throw new Error("missing shortLink");
      }
    })
    .catch(() => {
      return fallbackUrl;
    });
}

export async function generatePeerAssessmentDynamicLinkAndroid(linkId: string) {
  const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

  const sublink = `https://apps.apple.com/app/apple-store/id1520060596?peerAssessmentLinkId=${linkId}`;
  const androidSubLink = `apn=com.dimensional.dimensional`;

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`,
      {
        longDynamicLink: `https://dimensionalfeedback.page.link/?link=${sublink}&${androidSubLink}&isi=1520060596&ibi=com.dimensional.Dimensional`,
      }
    )
    .then((res) => {
      if (res?.data?.shortLink) {
        return res.data.shortLink as string;
      } else {
        throw new Error("missing shortLink");
      }
    })
    .catch(() => {
      return "https://www.dimensional.me";
    });
}

export async function generateDownloadDynamicLink() {
  const API_KEY = process.env.NEXT_PUBLIC_API_KEY;

  const sublink = `https://apps.apple.com/app/apple-store/id1520060596`;
  const androidSubLink = `apn=com.dimensional.dimensional`;

  return axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${API_KEY}`,
      {
        longDynamicLink: `https://dimensionalfeedback.page.link/?link=${sublink}&${androidSubLink}&isi=1520060596&ibi=com.dimensional.Dimensional`,
      }
    )
    .then((res) => {
      if (res?.data?.shortLink) {
        return res.data.shortLink as string;
      } else {
        return "https://www.dimensional.me";
      }
    })
    .catch(() => {
      return "https://www.dimensional.me";
    });
}
