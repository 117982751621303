import { Roboto_Mono } from "next/font/google";

import styles from "./CompatibilitySnippets.module.css";

import Loader from "@/components/shared/Loader";
import { getCompatibilityText, getSimilarityText } from "./utils";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function OverallCompatibilityScore(props: {
  score: number;
  title: string;
  scoreLoading?: boolean;
  similarityText?: boolean;
  compatibilityText?: boolean;
}) {
  if (props.scoreLoading) {
    return (
      <div className={styles.overallScoreDiv}>
        <Loader height="33px" mobileHeight="33px" />
      </div>
    );
  }

  const mutatedScore = props.score === 1 ? 2 : props.score;

  return (
    <div className={styles.overallScoreDiv}>
      <div className={styles.overallScoreDivTop}>
        <p className={`${font.className} ${styles.overallScoreTitle}`}>
          {props.title}
        </p>
        <div className={styles.overallScoreDivText}>
          {props.similarityText || props.compatibilityText ? (
            <p
              style={{ color: "#928E8C" }}
              className={`${font.className} ${styles.overallScoreTitle}`}
            >
              {props.similarityText
                ? getSimilarityText(props.score)
                : getCompatibilityText(props.score)}
              :
            </p>
          ) : null}
          <p className={`${font.className} ${styles.overallScoreTitle}`}>
            {props.score.toFixed()}%
          </p>
        </div>
      </div>
      <div className={styles.overallScoreDivBarParent}>
        <div className={styles.overallScoreDivBarOutside}></div>
        <div
          style={{
            width: `${mutatedScore}%`,
          }}
          className={styles.overallScoreDivBarInside}
        ></div>
      </div>
    </div>
  );
}
