export default function PeerPrimaryTraitsSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7171 13.0667C18.6468 13.0667 18.5899 13.1236 18.5899 13.1939V16.8847L15.9926 14.2874C15.9429 14.2377 15.8623 14.2377 15.8126 14.2874L14.1927 15.9074C14.143 15.9571 14.143 16.0376 14.1927 16.0873L16.97 18.8647H13.0606C12.9903 18.8647 12.9333 18.9216 12.9333 18.9919V21.2829C12.9333 21.3532 12.9903 21.4101 13.0606 21.4101H16.7514L14.154 24.0075C14.1043 24.0572 14.1043 24.1378 14.154 24.1874L15.774 25.8074C15.8237 25.8571 15.9043 25.8571 15.9539 25.8074L18.5899 23.1715V26.9394C18.5899 27.0097 18.6468 27.0667 18.7171 27.0667H21.0081C21.0784 27.0667 21.1353 27.0097 21.1353 26.9394V23.03L23.9122 25.8069C23.9619 25.8566 24.0425 25.8566 24.0922 25.8069L25.7121 24.1869C25.7618 24.1372 25.7618 24.0567 25.7121 24.007L23.1153 21.4101H26.8061C26.8764 21.4101 26.9334 21.3532 26.9334 21.2829V18.9919C26.9334 18.9216 26.8764 18.8647 26.8061 18.8647H22.8967L25.6735 16.0879C25.7232 16.0382 25.7232 15.9576 25.6735 15.9079L24.0535 14.2879C24.0038 14.2383 23.9233 14.2383 23.8736 14.2879L21.1353 17.0262V13.1939C21.1353 13.1236 21.0784 13.0667 21.0081 13.0667H18.7171Z"
        fill="#FFFFF2"
      />
      <path
        d="M38.1141 14.8226C38.2311 14.6671 38.2504 14.4589 38.163 14.2841C38.0756 14.1105 37.8982 14 37.7028 14H34.8236C34.3545 14.0039 33.9123 14.2185 33.6206 14.5861L30.3403 18.6993C29.8866 19.2635 29.8866 20.0682 30.3403 20.6324L33.6206 24.7456C33.9123 25.1132 34.3545 25.3279 34.8236 25.3317H37.7028C37.8982 25.3317 38.0756 25.2212 38.163 25.0476C38.2504 24.8728 38.2311 24.6646 38.1141 24.5091L34.7414 20.005C34.5923 19.8148 34.5923 19.5474 34.7414 19.3572L38.1141 14.8226Z"
        fill="#FFFFF2"
      />
      <path
        d="M6.59678 14.5861C6.305 14.2185 5.86282 14.0038 5.39369 14H2.51449C2.31911 14 2.14174 14.1105 2.05434 14.2841C1.96694 14.4589 1.98622 14.6671 2.10319 14.8226L5.47597 19.3267C5.62507 19.5169 5.62507 19.7843 5.47597 19.9745L2.10319 24.4888C1.98622 24.6443 1.96694 24.8526 2.05434 25.0274C2.14175 25.2009 2.31913 25.3114 2.51449 25.3114H5.39369C5.86286 25.3076 6.30501 25.0929 6.59678 24.7253L9.87703 20.6122C10.3308 20.0479 10.3308 19.2432 9.87703 18.679L6.59678 14.5861Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.6324 30.3461C20.0708 29.8846 19.261 29.8846 18.6993 30.3461L14.5861 33.6263C14.2185 33.9181 14.0038 34.3603 14 34.8294V37.7086C14 37.904 14.1105 38.0814 14.2841 38.1688C14.4589 38.2562 14.6671 38.2369 14.8226 38.1199L19.3267 34.7471C19.5169 34.598 19.7843 34.598 19.9745 34.7471L24.4786 38.1199C24.566 38.1906 24.6753 38.2266 24.7871 38.2227C24.8616 38.2369 24.9388 38.2369 25.0133 38.2227C25.1881 38.1341 25.2987 37.9554 25.3012 37.76V34.8808C25.2974 34.4117 25.0827 33.9695 24.7151 33.6777L20.6324 30.3461Z"
        fill="#FFFFF2"
      />
      <path
        d="M25.0235 2.07327C24.859 1.97558 24.6533 1.97558 24.4888 2.07327L19.9847 5.49738C19.7945 5.64648 19.5272 5.64648 19.3369 5.49738L14.8226 2.1246C14.6671 2.00763 14.4589 1.98835 14.2841 2.07575C14.1105 2.16316 14 2.34054 14 2.5359V5.41511C14.0039 5.88427 14.2185 6.32642 14.5861 6.61819L18.6993 9.89844C19.2635 10.3522 20.0682 10.3522 20.6324 9.89844L24.7456 6.61819C25.1132 6.32641 25.3279 5.88424 25.3317 5.41511V2.5359C25.3253 2.33538 25.206 2.15553 25.0235 2.07327Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
