import {
  PeriodicQuiz,
  PeriodicQuizResult,
} from "@dimensional-engineering/dimensional-models";
import { doc, updateDoc } from "firebase/firestore";

import { natureMap } from "@/assets/traitInfo/natureData";
import { db } from "@/_firebase/firebaseConfig";

export function formatPeriodicQuizTime(seconds: number): string {
  const days = Math.floor(seconds / (24 * 3600));
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${days}d:${hours}h:${minutes}m:${remainingSeconds}s`;
}

export function getPeriodicScorePercentileScore(
  periodicQuiz: PeriodicQuiz,
  periodicQuizResult: PeriodicQuizResult | null | undefined
): number {
  if (!periodicQuizResult) {
    return 0;
  }
  if (!periodicQuiz.sampleData?.percentileLookup) {
    return Number((periodicQuizResult.percentScore * 100).toFixed(0));
  }
  const index = Number((periodicQuizResult.percentScore * 100).toFixed(0));
  const percentileScore = periodicQuiz.sampleData.percentileLookup[index];
  return Number((percentileScore * 100).toFixed(0));
}

export function formatPeriodicQuizPercentileDescriptionTemplate(
  percentileDescriptionTemplate: string,
  percentileScore: number
) {
  const mutatedString = percentileDescriptionTemplate.replace(
    "<PERCENTILE_SCORE>",
    percentileScore.toString()
  );

  return mutatedString;
}

export const periodicQuizSampleNameColourMap = {
  opennessAndIntellect: natureMap.opennessAndIntellect.color,
  warmthAndAgreeableness: natureMap.warmthAndAgreeableness.color,
  chaos: natureMap.chaos.color,
  energyAndExcitement: natureMap.energyAndExcitement.color,
  tranquility: natureMap.tranquility.color,
  selfEnhancement: natureMap.selfEnhancement.color,
  orderAndStructure: natureMap.orderAndStructure.color,
  male: natureMap.tranquility.color,
  female: natureMap.selfEnhancement.color,
  other: natureMap.orderAndStructure.color,
  nonBinary: natureMap.orderAndStructure.color,
  under21: natureMap.tranquility.color,
  twentyOneToThirty: natureMap.selfEnhancement.color,
  thirtyOneToThirtyFive: natureMap.orderAndStructure.color,
  thirtySixAndOver: natureMap.warmthAndAgreeableness.color,
  introversion: natureMap.tranquility.color,
  extroversion: natureMap.selfEnhancement.color,
  sensing: natureMap.tranquility.color,
  intuition: natureMap.selfEnhancement.color,
  thinking: natureMap.tranquility.color,
  feeling: natureMap.selfEnhancement.color,
  judging: natureMap.tranquility.color,
  perceiving: natureMap.selfEnhancement.color,
};

export async function sharePeriodicQuizResult(
  periodicQuizResult: PeriodicQuizResult | null | undefined,
  uid: string | undefined
) {
  const ref = doc(
    db,
    `/members/${uid}/periodicQuizResults/${periodicQuizResult?.periodicQuizSlug}`
  );

  return await updateDoc(ref, { isShared: true });
}
