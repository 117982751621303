import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./ParentOf.module.css";

import { ExtendedScale } from "@/models/sharedModels";
import TraitsHeading from "../../Heading/TraitsHeading";
import useIsMobile from "@/helpers/useIsMobile";
import { useAuth } from "@/context/AuthContext";
import Element from "@/components/traits/element/Element/Element";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";

type Props = {
  element: ExtendedScale;
  auth?: boolean;
  uid?: string | undefined;
};

export default function ParentOf(props: Props) {
  const lineWidth = useGetLineWidth({
    element: props.element,
    modal: props.auth,
  });
  const { user } = useAuth();
  const { setModalComponent } = useModalContext();

  if (props.auth) {
    return (
      <div className={styles.main}>
        <TraitsHeading title="Parent" />
        <h3 className={styles.subtitle}>
          {props.element.alias ?? props.element.name} is a parent element
          comprised of{" "}
          {props.element.traitContent.parentDetails?.childTraits.length} child
          elements.
        </h3>
        <div className={styles.parent}>
          <div className={styles.archDiv}>
            <div className="elBody">
              <Element slug={props.element.slug} />
            </div>
            <div className={styles.archLine}></div>
          </div>
          <div
            style={{ width: `${lineWidth}px` }}
            className={styles.long}
          ></div>
          <div className={styles.wrapper}>
            {props.element.traitContent.parentDetails?.childTraits?.map(
              (el) => {
                return (
                  <div
                    onClick={() =>
                      setModalComponent(
                        <ElementModal uid={props.uid} slug={el.slug} />
                      )
                    }
                    className={styles.elDiv}
                    key={el.slug}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={styles.archLine}></div>
                    <div key={el.slug}>
                      <div className="elBodyNoMarginTopBottom">
                        <Element slug={el.slug} />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <TraitsHeading title="Parent" />
      <h3 className={styles.subtitle}>
        {props.element.alias ?? props.element.name} is a parent element
        comprised of{" "}
        {props.element.traitContent.parentDetails?.childTraits.length} child
        elements.
      </h3>
      <div className={styles.parent}>
        <div className={styles.archDiv}>
          <div className="elBody">
            <Element slug={props.element.slug} />
          </div>
          <div className={styles.archLine}></div>
        </div>
        <div style={{ width: `${lineWidth}px` }} className={styles.long}></div>
        <div className={styles.wrapper}>
          {props.element.traitContent.parentDetails?.childTraits?.map(
            (el, index) => {
              return (
                <div className={styles.elDiv} key={el.slug}>
                  <div className={styles.archLine}></div>
                  <Link key={el.slug} href={`/traits/elements/${el.slug}`}>
                    <div className="elBodyNoMarginTopBottom">
                      <Element slug={el.slug} />
                    </div>
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

function useGetLineWidth(props: { element: ExtendedScale; modal?: boolean }) {
  const [lineWidth, setLineWidth] = useState<number>(51.5);
  const isMobile = useIsMobile();
  const length = props.element.traitContent.parentDetails?.childTraits.length;

  useEffect(() => {
    if (length) {
      if (props.modal) {
        if (isMobile) {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(192);
          } else {
            setLineWidth(192);
          }
        } else {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(287);
          } else {
            setLineWidth(287);
          }
        }
      } else {
        if (isMobile) {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(192);
          } else {
            setLineWidth(192);
          }
        } else {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(4 * 76);
          } else if (length === 5) {
            setLineWidth(405);
          } else {
            setLineWidth(477);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  return lineWidth;
}
