import { useState } from "react";
import { Roboto_Mono } from "next/font/google";
import {
  ContentType,
  Nature,
} from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";

import styles from "./NatureModal.module.css";

import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import ProfileImage from "@/components/publicProfiles/ProfileImage/ProfileImage";
import NatureBreakdownRow from "../NatureBreakdownRow";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import useGetPublicProfile from "@/helpers/useGetPublicProfile";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";
import { natureMap } from "@/assets/traitInfo/natureData";
import { RootState } from "@/redux/store";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function NatureModalConnection(props: {
  uid: string | undefined;
  imageUrl: string;
}) {
  const { setModalOpen } = useModalContext();
  const { setAlertComponent } = useAlertContext();
  const publicProfile = useGetPublicProfile(props.uid);
  const friendUidList = useSelector(
    (state: RootState) => state.user
  ).friendUidList;
  const isConnection = props.uid && friendUidList.includes(props.uid);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const handleClick = async () => {
    setButtonDisabled(true);

    const nudgeCall = callFirebaseFunctions("nudgeConnection");
    return nudgeCall(
      JSON.stringify({
        connectionUid: publicProfile?.ownerUUID,
        contentIdentifier: {
          contentType: ContentType.natureBreakdown,
          contentSlug: "",
        },
      })
    )
      .then(() => {
        setModalOpen(false);
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="success"
            message={`Your nudge has been sent to @${publicProfile?.userName}.`}
          />,
          5000
        );
      })
      .catch((err) => {
        setButtonDisabled(false);
        console.log(err);
        setAlertComponent(
          <Alert
            elementName="Alert"
            type="fail"
            message="Something went wrong. Please try again"
          />,
          7000
        );
      });
  };

  if (!isConnection) {
    return (
      <ModalWrapper noHeader backgroundColor="#2d2927" noLine title="">
        <div className={styles.main}>
          <div
            onClick={() => {
              //   router.back();
              setModalOpen(false);
            }}
            className={styles.xmark}
          >
            <CloseButtonSummary />
          </div>
          <h1>@{publicProfile?.userName}</h1>
          <ProfileImage
            largeMobile
            color={
              publicProfile?.primaryNature?.nature
                ? ProfileColorMap[publicProfile.primaryNature.nature]
                : "grey"
            }
            url={props.imageUrl}
            quality={100}
          />

          <div className={styles.ghostBoxParent}>
            <div className={styles.ghostboxDiv}>
              <div className={styles.iconDiv}>
                <WarningIcon active />
              </div>
              <p className={styles.ghostboxDesc}>
                You must be connected to see their Nature breakdown.
              </p>
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  if (publicProfile && !publicProfile.primaryNature) {
    return (
      <ModalWrapper noHeader backgroundColor="#2d2927" noLine title="">
        <div className={styles.main}>
          <div
            onClick={() => {
              //   router.back();
              setModalOpen(false);
            }}
            className={styles.xmark}
          >
            <CloseButtonSummary />
          </div>
          <h1>@{publicProfile?.userName}</h1>
          <ProfileImage
            largeMobile
            color={"grey"}
            url={props.imageUrl}
            quality={100}
          />

          <div className={styles.ghostBoxParent}>
            <div className={styles.ghostboxDiv}>
              <div className={styles.iconDiv}>
                <WarningIcon active />
              </div>
              <p className={styles.ghostboxDesc}>
                {`@${publicProfile.userName} must complete more Dimensions to unlock this.`}
              </p>
              <button
                onClick={() => handleClick()}
                className={styles.ghostboxButton}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noHeader backgroundColor="#2d2927" noLine title="">
      <div className={styles.main}>
        <div
          onClick={() => {
            //   router.back();
            setModalOpen(false);
          }}
          className={styles.xmark}
        >
          <CloseButtonSummary />
        </div>
        <h1>@{publicProfile?.userName}</h1>
        <ProfileImage
          largeMobile
          color={
            publicProfile?.primaryNature?.nature
              ? ProfileColorMap[publicProfile?.primaryNature?.nature]
              : "grey"
          }
          url={props.imageUrl}
          quality={100}
        />
        <div className={`${styles.content} ${font.className}`}>
          <div className={styles.natureDesc}>
            <div className={styles.rowTop}>
              <p className={styles.mainNatureText}>Main Nature</p>
              <p className={styles.mainNatureText}>
                <div
                  style={
                    publicProfile?.primaryNature?.nature
                      ? {
                          backgroundColor:
                            ProfileColorMap[
                              publicProfile?.primaryNature?.nature
                            ],
                        }
                      : {}
                  }
                  className={styles.natureCircle}
                ></div>
                {publicProfile?.primaryNature
                  ? natureMap[
                      publicProfile?.primaryNature
                        ?.nature as keyof typeof natureMap
                    ].name
                  : "?"}
              </p>
            </div>
            <div className={styles.row}>
              <p className={styles.mainNatureDescription}>
                {publicProfile?.primaryNature?.nature
                  ? natureMap[publicProfile?.primaryNature?.nature].description
                  : ""}
              </p>
            </div>
          </div>
        </div>

        <p className={styles.breakdownText}>BREAKDOWN</p>

        <div className={styles.listPArent}>
          <NatureBreakdownRow
            natureSlug={Nature.chaos}
            publicProfile={publicProfile}
            NotOwnProfile
          />
          <NatureBreakdownRow
            natureSlug={Nature.opennessAndIntellect}
            publicProfile={publicProfile}
            NotOwnProfile
          />
          <NatureBreakdownRow
            natureSlug={Nature.warmthAndAgreeableness}
            publicProfile={publicProfile}
            NotOwnProfile
          />
          <NatureBreakdownRow
            natureSlug={Nature.orderAndStructure}
            publicProfile={publicProfile}
            NotOwnProfile
          />
          <NatureBreakdownRow
            natureSlug={Nature.tranquility}
            publicProfile={publicProfile}
            NotOwnProfile
          />
          <NatureBreakdownRow
            natureSlug={Nature.energyAndExcitement}
            publicProfile={publicProfile}
            NotOwnProfile
          />
          <NatureBreakdownRow
            natureSlug={Nature.selfEnhancement}
            publicProfile={publicProfile}
            NotOwnProfile
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
