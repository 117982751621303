export default function YouIdealLifeSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M2.18777 13.2552C2.19845 17.3375 4.36458 21.1077 7.88722 23.1716L16.4063 28.1425V34.9997H20.7813V23.2847L26.9056 19.8838C28.6957 18.8884 30.189 17.4336 31.2271 15.6691C32.2653 13.9025 32.8122 11.8923 32.8122 9.84388V7.65641H28.4372V9.84388C28.4372 12.4244 27.038 14.802 24.7843 16.0557L20.7811 18.2774V0H16.4062V9.98033L15.6136 9.52745C13.3984 8.26069 12.0334 5.90659 12.0312 3.35825V1.09389H7.65628V3.35825C7.65628 5.38122 8.19247 7.37001 9.20714 9.12168C10.224 10.8712 11.6851 12.3239 13.4411 13.3279L16.4062 15.0176V23.0773L10.0893 19.3923C7.90609 18.1192 6.56457 15.7821 6.56244 13.2548V10.9371H2.1875L2.18777 13.2552Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function YouIdealLifeSnippetIconPreview() {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15.5"
        cy="15.5"
        r="14.7493"
        stroke="#FFFFF2"
        strokeWidth="1.5015"
      />
      <g clipPath="url(#clip0_60660_15292)">
        <path
          d="M8.00012 13.0595C8.00501 14.9257 8.99524 16.6492 10.6056 17.5927L14.5 19.8651V22.9998H16.5V17.6444L19.2997 16.0897C20.1181 15.6347 20.8007 14.9696 21.2753 14.163C21.7499 13.3554 21.9999 12.4365 21.9999 11.5001V10.5001H19.9999V11.5001C19.9999 12.6797 19.3602 13.7666 18.33 14.3398L16.4999 15.3554V7H14.5V11.5624L14.1377 11.3554C13.125 10.7763 12.501 9.70016 12.5 8.5352V7.50006H10.5V8.5352C10.5 9.45999 10.7451 10.3691 11.209 11.1699C11.6738 11.9697 12.3418 12.6338 13.1445 13.0927L14.5 13.8652V17.5496L11.6122 15.8651C10.6142 15.2831 10.0009 14.2147 9.99997 13.0594V11.9998H8L8.00012 13.0595Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_60660_15292">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
