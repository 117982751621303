export const isNotDiscoverable = [
  "aas-close",
  "aas-depend",
  "disc-ds",
  "disc-ic",
  "marlowe-crowne-social-desirability",
  "mbti-feeling-thinking",
  "mbti-introversion-extroversion",
  "mbti-judging-perceiving",
  "mbti-sensing-intuition",
];
