export default function NewSearchIcon() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_b_59817_6090)">
        <rect width="44" height="44" rx="22" fill="#FFFFF2" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6083 24.3561L31.0385 29.9996L31.0385 29.9998C31.479 30.4576 31.479 31.1993 31.0385 31.6571C30.8182 31.886 30.5291 32 30.2408 32C29.9516 32 29.6633 31.886 29.443 31.6571L24.0129 26.0128C22.8805 26.8576 21.4933 27.3562 19.9932 27.3562C16.1783 27.3562 13.0859 24.1422 13.0859 20.1777C13.0859 16.213 16.1783 12.9992 19.9938 13C23.8087 13 26.901 16.214 26.901 20.1785C26.901 21.7375 26.4213 23.1792 25.6083 24.3561ZM19.9927 15.343C17.4281 15.343 15.3408 17.5114 15.3408 20.1776C15.3408 22.8437 17.4281 25.0121 19.9927 25.0121C22.5581 25.0121 24.6445 22.8437 24.6445 20.1776C24.6445 17.5123 22.5581 15.343 19.9927 15.343Z"
        fill="#FFFFF2"
      />
      <defs>
        <filter
          id="filter0_b_59817_6090"
          x="-5.43656"
          y="-5.43656"
          width="54.8731"
          height="54.8731"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.71828" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_59817_6090"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_59817_6090"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
