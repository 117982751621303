export default function InSummarySnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <rect x="2.5" y="1" width="36" height="5" rx="1" fill="#5DAF4D" />
      <rect x="2.5" y="9" width="36" height="5" rx="1" fill="#E0CC65" />
      <rect x="2.5" y="17" width="36" height="5" rx="1" fill="#FFA0B9" />
      <rect x="2.5" y="25" width="36" height="5" rx="1" fill="#A069CE" />
      <rect x="2.5" y="33" width="36" height="5" rx="1" fill="#F37A1C" />
    </svg>
  );
}
