type BarHeadingProps = {
  heading: string;
  subHeading?: string;
};

export default function BarHeading(props: BarHeadingProps) {
  return (
    <>
      <h2 className="section-heading">{props.heading}</h2>
      <h3 className="section-sub-heading">{props.subHeading}</h3>
    </>
  );
}
