import {
  ConnectionAvailability,
  ContentContext,
  DiscoverableSnippet,
  PublicProfilePermission,
  ResultCategory,
  SnippetType,
} from "@dimensional-engineering/dimensional-models";

import HigherThanAllUsersIcon from "./snippetsIcons/HigherThanAllUsersIcon";
import HigherThanAllConnectionsIcon from "./snippetsIcons/HigherThanAllConnectionsIcon";
import ConnectionsLeastLikeYouIcon from "./snippetsIcons/ConnectionsLeastLikeYouIcon";
import LowerThanAllUsersIcon from "./snippetsIcons/LowerThanAllUsersIcon";
import LowerThanAllConnectionsIcon from "./snippetsIcons/LowerThanAllConnectionsIcon";
import ConnectionsMostLikeYouIcon from "./snippetsIcons/ConnectionsMostLikeYouIcon";
import VibeWithIcon from "./snippetsIcons/VibeWithIcon";
import DontVibeWithIcon from "./snippetsIcons/DontVibeWithIcon";
import CoreSelfSnippetIcon from "./snippetsIcons/CoreSelfSnippetIcon";
import BringRelationshipIcon from "./snippetsIcons/BringRelationshipIcon";
import CareerSnippetIcon from "./snippetsIcons/CareerSnippetIcon";
import WorkEnvironmentSnippetIcon from "./snippetsIcons/WorkEnvironmentSnippetIcon";
import LeadershipStyleSnippetIcon from "./snippetsIcons/LeadershipStyleSnippetIcon";
import ObstaclesToLoveSnippetIcon from "./snippetsIcons/ObstaclesToLoveSnippetIcon";
import YourPurposeSnippetIcon from "./snippetsIcons/YourPurposeSnippetIcon";
import DeepestThemesSnippetIcon from "./snippetsIcons/DeepestThemesSnippetIcon";
import InternalConflictSnippetIcon from "./snippetsIcons/InternalConflictSnippetIcon";
import BlindspotsInLoveSNippetIcon from "./snippetsIcons/BlindspotsInLoveSnippetIcon";
import AtYourWorstSnippetIcon from "./snippetsIcons/AtYourWorstSnippetIcon";
import AtYourBestSnippetIcon from "./snippetsIcons/AtYourBestSnippetIcon";
import FlagsSnippetIcon from "./snippetsIcons/FlagsSnippetIcon";
import InSummarySnippetIcon from "./snippetsIcons/InSummarySnippetIcon";
import NatureAccordingToPeersIcon from "./snippetsIcons/NatureAccordingToPeersIcon";
import PeerRatedCognitionIcon from "./snippetsIcons/PeerRatedCognitionIcon";
import PeerInteractionStylesSnippetIcon from "./snippetsIcons/PeerInteractionStylesSnippetIcon";
import PeerPrimaryTraitsSnippetIcon from "./snippetsIcons/PeerPrimaryTraitsSnippetIcon";
import PeerStrengthsSnippetIcon from "./snippetsIcons/PeerStrengthsSnippetIcon";
import OverEstimatedSnippetIcon from "./snippetsIcons/OverEstimatedSnippetIcon";
import UnderEstimatedSnippetIcon from "./snippetsIcons/UnderEstimatedSnippetIcon";
import YourBlindspotsSnippetIcon from "./snippetsIcons/YourBlindspotsSnippetIcon";
import HowOthersSeeYouSnippetIcon from "./snippetsIcons/HowOthersSeeYouSnippetIcon";
import HighestScoringTraitsSnippetIcon from "./snippetsIcons/HighestScoringTraitsSNippetIcon";
import NutshellSnippetIcon from "./NutshellSnippet/NutshellSnippetIcon";
import YouIdealLifeSnippetIcon from "./snippetsIcons/YouIdealLifeSnippetIcon";

export const snippetsMap: DiscoverableSnippet[] = [
  {
    resultCategory: ResultCategory.whoAreYou,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "communication-style",
        "love-attitudes",
        "love-style",
      ],
    },
    name: "At your best",
    snippetSlug: "snippet-at-your-best",
    context: ContentContext.identity,
    isPremium: true,
    snippetType: SnippetType.llmHeaderAndBody,
    order: 99,
  },
  {
    isPremium: true,
    resultCategory: ResultCategory.whoAreYou,
    snippetSlug: "snippet-at-your-worst",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "communication-style",
        "love-attitudes",
        "love-style",
      ],
    },
    name: "At your worst",
    snippetType: SnippetType.llmHeaderAndBody,
    order: 99,
    context: ContentContext.identity,
  },
  {
    name: "Blindspots in love",
    snippetSlug: "snippet-blindspots-in-love",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "love-attitudes",
        "love-style",
        "communication-style",
      ],
    },
    isPremium: true,
    resultCategory: ResultCategory.loveAndRelationships,

    order: 22,
    context: ContentContext.love,
    snippetType: SnippetType.llmList,
  },
  {
    resultCategory: ResultCategory.loveAndRelationships,
    context: ContentContext.love,
    order: 10,
    name: "5 things you bring to a relationship",
    snippetSlug: "snippet-bring-relationship",
    snippetType: SnippetType.llmList,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "communication-style",
        "love-style",
        "love-attitudes",
        "attachment-style",
        "sex-attitudes",
        "secondary-traits",
        "lifestyle",
      ],
    },
  },
  {
    snippetType: SnippetType.llmTitlesAndContent,
    snippetSlug: "snippet-core-self",

    order: 1,
    name: "Core self",
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    resultCategory: ResultCategory.whoAreYou,
    context: ContentContext.identity,
  },
  {
    name: "Don't vibe with",
    order: 3,
    snippetSlug: "snippet-dont-vibe-with",
    context: ContentContext.identity,
    snippetType: SnippetType.llmList,

    resultCategory: ResultCategory.loveAndRelationships,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
  },
  {
    order: 99,
    snippetType: SnippetType.llmTitlesAndContent,
    snippetSlug: "snippet-green-beige-red-flags",

    name: "Green, beige, and red flags",
    isPremium: true,
    discoveryGate: {
      numSuccessfulInvitesRequired: 3,
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "communication-style",
        "love-attitudes",
        "love-style",
        "lifestyle",
        "sex-attitudes",
      ],
    },
    context: ContentContext.love,
  },
  {
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    resultCategory: ResultCategory.byTheNumbers,
    order: 4,
    snippetSlug: "snippet-higher-all-users",
    name: "Higher than all users",
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    context: ContentContext.identity,

    snippetType: SnippetType.allUserComparison,
  },
  {
    discoveryGate: {
      numFriendsRequired: 5,
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    snippetSlug: "snippet-higher-connections",
    resultCategory: ResultCategory.byTheNumbers,
    name: "Higher than your connections",
    order: 6,
    snippetType: SnippetType.connectionComparison,

    context: ContentContext.identity,
  },
  {
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II"],
    },
    name: "Highest scoring traits",
    snippetSlug: "snippet-highest-scoring-traits",
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    context: ContentContext.identity,
    isPremium: false,
    resultCategory: ResultCategory.whoAreYou,
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    order: 0,
    snippetType: SnippetType.topScoringTraits,
  },
  {
    context: ContentContext.identity,
    name: "How others see you",
    order: 0,
    snippetType: SnippetType.llmHeaderedList,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
      numPeerAssessorsRequired: 6,
    },
    resultCategory: ResultCategory.howOthersSeeYou,
    isPremium: false,
    snippetSlug: "snippet-how-others-see-you",
  },
  {
    name: "Ideal work environment",
    snippetType: SnippetType.llmList,

    snippetSlug: "snippet-ideal-work-environment",
    context: ContentContext.work,
    resultCategory: ResultCategory.workAndPurpose,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "communication-style",
        "interests",
        "strengths",
      ],
    },
    order: 16,
    isPremium: true,
  },
  {
    name: "In 5 short statements",
    snippetType: SnippetType.llmNatureStatements,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "values", "personality"],
    },
    isPremium: false,
    snippetSlug: "snippet-in-summary",
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    resultCategory: ResultCategory.whoAreYou,
    context: ContentContext.identity,
  },
  {
    name: "Internal conflict",
    context: ContentContext.identity,
    order: 21,
    snippetType: SnippetType.llmList,
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "interests",
        "love-attitudes",
        "love-style",
        "strengths",
        "communication-style",
      ],
    },
    snippetSlug: "snippet-internal-conflict",
    resultCategory: ResultCategory.personalDevelopment,
  },
  {
    name: "Leadership style",
    context: ContentContext.work,
    snippetType: SnippetType.llmMatch,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "interests",
        "strengths",
        "communication-style",
      ],
    },
    resultCategory: ResultCategory.workAndPurpose,
    isPremium: true,
    order: 17,
    snippetSlug: "snippet-leadership-style",
  },
  {
    snippetSlug: "snippet-least-similar-connections",

    order: 9,
    name: "Connections least like you",
    resultCategory: ResultCategory.byTheNumbers,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
      numFriendsRequired: 5,
    },
    snippetType: SnippetType.similarityComparison,

    context: ContentContext.identity,
  },
  {
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    context: ContentContext.identity,

    publicProfilePermission: PublicProfilePermission.endorsedElements,
    name: "Lower than all users",
    snippetType: SnippetType.allUserComparison,
    resultCategory: ResultCategory.byTheNumbers,

    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    order: 5,
    snippetSlug: "snippet-lower-all-users",
  },
  {
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
      numFriendsRequired: 5,
    },
    name: "Lower than your connections",

    resultCategory: ResultCategory.byTheNumbers,
    snippetSlug: "snippet-lower-connections",
    snippetType: SnippetType.connectionComparison,
    order: 7,
    context: ContentContext.identity,
  },
  {
    resultCategory: ResultCategory.byTheNumbers,
    name: "Connections most like you",
    snippetType: SnippetType.similarityComparison,

    order: 8,
    context: ContentContext.identity,

    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
      numFriendsRequired: 5,
    },
    snippetSlug: "snippet-most-similar-connections",
  },
  {
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    snippetType: SnippetType.nutshell,
    context: ContentContext.identity,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    publicProfilePermission: PublicProfilePermission.adjectives,
    isPremium: false,
    name: "In summary",
    snippetSlug: "snippet-nutshell",
    resultCategory: ResultCategory.whoAreYou,
  },
  {
    name: "Obstacles to love",
    context: ContentContext.love,

    snippetType: SnippetType.llmList,
    isPremium: true,
    order: 18,
    snippetSlug: "snippet-obstacles-to-love",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "love-attitudes",
        "love-style",
        "communication-style",
      ],
      numSuccessfulInvitesRequired: 3,
    },
    resultCategory: ResultCategory.loveAndRelationships,
  },
  {
    snippetType: SnippetType.peerRatedTraitsDelta,
    resultCategory: ResultCategory.howOthersSeeYou,
    name: "Overestimated traits",
    context: ContentContext.identity,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
    },
    order: 0,
    snippetSlug: "snippet-overestimated-traits",
    isPremium: false,
  },
  {
    name: "Peer-rated Cognition",
    snippetType: SnippetType.aggregatePeerSpectrum,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II"],
      numPeerAssessorsRequired: 4,
    },
    isPremium: false,
    snippetSlug: "snippet-peer-rated-cognition",
    order: 0,
    resultCategory: ResultCategory.howOthersSeeYou,
    context: ContentContext.identity,
  },
  {
    resultCategory: ResultCategory.howOthersSeeYou,
    snippetType: SnippetType.aggregatePeerTwoByTwo,
    snippetSlug: "snippet-peer-rated-interaction-style",
    name: "Peer-rated Interaction Style",
    context: ContentContext.identity,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
      ],
      numPeerAssessorsRequired: 4,
    },
    order: 0,
    isPremium: false,
  },
  {
    context: ContentContext.identity,
    resultCategory: ResultCategory.howOthersSeeYou,
    snippetSlug: "snippet-peer-rated-nature",
    name: "Peer-rated Nature",
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "values", "personality"],
      numPeerAssessorsRequired: 4,
    },
    isPremium: false,
    snippetType: SnippetType.peerRatedNature,
  },
  {
    snippetSlug: "snippet-peer-rated-primary-traits",
    snippetType: SnippetType.aggregatePeerSpectrum,
    order: 0,
    resultCategory: ResultCategory.howOthersSeeYou,
    isPremium: false,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: ["personality"],
    },
    name: "Peer-rated Primary Traits",
    context: ContentContext.identity,
  },
  {
    snippetType: SnippetType.topPeerRatedTraits,
    order: 0,
    resultCategory: ResultCategory.howOthersSeeYou,
    context: ContentContext.identity,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: ["strengths"],
    },
    name: "Peer-rated Strengths",
    isPremium: false,
    snippetSlug: "snippet-peer-rated-strengths",
  },
  {
    snippetType: SnippetType.llmList,
    name: "Top 10 careers",
    order: 15,
    snippetSlug: "snippet-top-ten-careers",
    resultCategory: ResultCategory.workAndPurpose,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "communication-style",
        "interests",
        "strengths",
      ],
    },
    isPremium: true,
    context: ContentContext.work,
  },
  {
    snippetSlug: "snippet-underestimated-traits",
    order: 0,
    snippetType: SnippetType.peerRatedTraitsDelta,

    context: ContentContext.identity,
    isPremium: false,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
    },
    name: "Underestimated traits",
    resultCategory: ResultCategory.howOthersSeeYou,
  },
  {
    context: ContentContext.identity,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    snippetSlug: "snippet-vibe-with",
    order: 2,
    snippetType: SnippetType.llmList,
    resultCategory: ResultCategory.loveAndRelationships,
    name: "Vibe with",
  },
  {
    context: ContentContext.identity,
    discoveryGate: {
      numPeerAssessorsRequired: 6,
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
    },
    resultCategory: ResultCategory.howOthersSeeYou,
    snippetSlug: "snippet-your-blindspots",
    name: "Your blindspots",
    snippetType: SnippetType.llmList,
    isPremium: false,
    order: 0,
  },
  {
    order: 20,
    name: "Your deepest themes",
    snippetSlug: "snippet-your-deepest-themes",
    context: ContentContext.identity,
    isPremium: true,

    resultCategory: ResultCategory.whoAreYou,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "interests",
        "love-attitudes",
        "love-style",
        "strengths",
        "communication-style",
      ],
    },
    snippetType: SnippetType.llmList,
  },
  {
    isPremium: true,
    snippetSlug: "snippet-your-purpose",
    snippetType: SnippetType.llmHeaderedList,
    order: 19,
    name: "Your purpose",

    resultCategory: ResultCategory.workAndPurpose,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "communication-style",
        "interests",
        "strengths",
      ],
    },
    context: ContentContext.work,
  },
  {
    emojiString: "🪨",
    order: 0,
    name: "Classical element",
    isPremium: false,
    snippetSlug: "sp-snippet-fun-classical-element",
    snippetType: SnippetType.llmOrderedMatch,
    resultCategory: ResultCategory.justForFun,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
  },
  {
    snippetType: SnippetType.llmOrderedMatch,

    name: "Disney character",
    order: 0,
    emojiString: "🏰",
    isPremium: true,
    snippetSlug: "sp-snippet-fun-disney-character",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },
    resultCategory: ResultCategory.justForFun,
  },
  {
    snippetType: SnippetType.llmOrderedMatch,

    name: "Enneagram type",
    resultCategory: ResultCategory.justForFun,
    emojiString: "🧩",
    order: 0,
    discoveryGate: {
      numSuccessfulInvitesRequired: 5,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    snippetSlug: "sp-snippet-fun-enneagram",
    isPremium: true,
  },
  {
    snippetSlug: "sp-snippet-fun-fictional-villain",
    resultCategory: ResultCategory.justForFun,
    emojiString: "😈",
    snippetType: SnippetType.llmOrderedMatch,
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 5,
    },
    name: "Fictional villain",
    order: 0,
  },
  {
    discoveryGate: {
      numSuccessfulInvitesRequired: 1,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },

    isPremium: true,
    snippetSlug: "sp-snippet-fun-friend-type",
    name: "Friend type",
    snippetType: SnippetType.llmOrderedMatch,
    order: 0,
    resultCategory: ResultCategory.justForFun,
    emojiString: "👭",
  },
  {
    isPremium: true,
    name: "Game of Thrones house",
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
      numSuccessfulInvitesRequired: 3,
    },
    snippetSlug: "sp-snippet-fun-game-of-thrones",
    emojiString: "🐺",
    resultCategory: ResultCategory.justForFun,
  },
  {
    name: "Generation",
    isPremium: true,
    resultCategory: ResultCategory.justForFun,
    discoveryGate: {
      numSuccessfulInvitesRequired: 3,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-attitudes",
        "secondary-traits",
      ],
    },
    snippetSlug: "sp-snippet-fun-generation",
    emojiString: "🗓️",
    snippetType: SnippetType.llmOrderedMatch,
    order: 0,
  },
  {
    emojiString: "🔱",
    snippetType: SnippetType.llmOrderedMatch,

    resultCategory: ResultCategory.justForFun,
    isPremium: true,
    order: 0,
    name: "Greek god/goddess",
    snippetSlug: "sp-snippet-fun-greek-god",
    discoveryGate: {
      numSuccessfulInvitesRequired: 4,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-attitudes",
        "secondary-traits",
      ],
    },
  },
  {
    order: 0,
    snippetSlug: "sp-snippet-fun-historical-leader",
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
      numSuccessfulInvitesRequired: 2,
    },
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "📜",
    name: "Famous historical leader",
    resultCategory: ResultCategory.justForFun,
  },
  {
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    snippetSlug: "sp-snippet-fun-hogwarts",
    order: 0,
    emojiString: "🪄",
    isPremium: false,

    name: "Hogwarts house",
    resultCategory: ResultCategory.justForFun,
    snippetType: SnippetType.llmOrderedMatch,
  },
  {
    snippetType: SnippetType.llmOrderedMatch,
    snippetSlug: "sp-snippet-fun-jungian-archetype",
    order: 0,
    discoveryGate: {
      numSuccessfulInvitesRequired: 5,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-attitudes",
        "secondary-traits",
      ],
    },

    resultCategory: ResultCategory.justForFun,
    isPremium: true,
    emojiString: "🌓",
    name: "Jungian archetype",
  },
  {
    isPremium: true,
    name: "Life path number",
    resultCategory: ResultCategory.justForFun,
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "🔢",

    snippetSlug: "sp-snippet-fun-numerology",
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 4,
    },
  },
  {
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    isPremium: false,
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-pixar-character",
    order: 0,
    emojiString: "💡",
    name: "Pixar character",
    snippetType: SnippetType.llmOrderedMatch,
  },
  {
    snippetSlug: "sp-snippet-fun-planet",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 3,
    },
    isPremium: true,

    name: "Planet",
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
    resultCategory: ResultCategory.justForFun,
    emojiString: "🪐",
  },
  {
    snippetSlug: "sp-snippet-fun-recs-bosses-work",
    isPremium: true,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "interests",
        "secondary-traits",
      ],
      numSuccessfulInvitesRequired: 4,
    },
    emojiString: "🫡",
    resultCategory: ResultCategory.recommendations,
    snippetType: SnippetType.llmUnorderedMatch,

    name: "Bosses to work with",
  },
  {
    resultCategory: ResultCategory.recommendations,
    emojiString: "📖",
    snippetSlug: "sp-snippet-fun-recs-fiction-books",
    order: 0,
    isPremium: true,

    discoveryGate: {
      numSuccessfulInvitesRequired: 1,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    name: "Fiction books",
    snippetType: SnippetType.llmUnorderedMatch,
  },
  {
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    resultCategory: ResultCategory.recommendations,
    isPremium: true,
    emojiString: "🎬",
    snippetSlug: "sp-snippet-fun-recs-foreign-films",
    name: "Foreign films",

    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },
  },
  {
    snippetSlug: "sp-snippet-fun-recs-lesser-known-movies",
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    resultCategory: ResultCategory.recommendations,
    emojiString: "🎞️",
    isPremium: true,
    name: "Lesser-known movies",
    discoveryGate: {
      numSuccessfulInvitesRequired: 2,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
  },
  {
    snippetSlug: "sp-snippet-fun-recs-movies",
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    name: "Movies",
    resultCategory: ResultCategory.recommendations,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },

    isPremium: false,
    emojiString: "🍿",
  },
  {
    resultCategory: ResultCategory.recommendations,
    emojiString: "📚",
    isPremium: true,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 3,
    },
    snippetSlug: "sp-snippet-fun-recs-non-fiction-books",

    snippetType: SnippetType.llmUnorderedMatch,
    name: "Non-fiction books",
  },
  {
    emojiString: "💑",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-style",
        "love-attitudes",
        "lifestyle",
        "attachment-style",
        "sex-attitudes",
        "secondary-traits",
      ],
      numSuccessfulInvitesRequired: 5,
    },
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    snippetSlug: "sp-snippet-fun-recs-people-date",
    name: "People to date",
    resultCategory: ResultCategory.recommendations,
    isPremium: true,
  },
  {
    snippetSlug: "sp-snippet-fun-recs-tv-shows",
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 1,
    },
    emojiString: "📺",
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    resultCategory: ResultCategory.recommendations,
    name: "TV shows",
  },
  {
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    snippetType: SnippetType.llmOrderedMatch,
    isPremium: false,
    resultCategory: ResultCategory.justForFun,

    name: "Season of the year",
    emojiString: "🍂",
    snippetSlug: "sp-snippet-fun-season",
  },
  {
    name: "Sitcom character",

    isPremium: true,
    emojiString: "📺",
    discoveryGate: {
      numSuccessfulInvitesRequired: 1,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-sitcom-character",
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
  },
  {
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "✨",
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-star-sign",
    name: "Star sign",

    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-style",
        "love-attitudes",
      ],
      numSuccessfulInvitesRequired: 1,
    },
    isPremium: true,
  },
  {
    name: "Superhero",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },

    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "🦸",
    order: 0,
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-superhero",
    isPremium: true,
  },
  {
    name: "Tarot card",
    snippetSlug: "sp-snippet-fun-tarot-card",
    resultCategory: ResultCategory.justForFun,
    discoveryGate: {
      numSuccessfulInvitesRequired: 4,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    isPremium: true,
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "🃏",
    order: 0,
  },
  {
    snippetSlug: "sp-snippet-fun-video-game-character",
    discoveryGate: {
      numSuccessfulInvitesRequired: 2,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    snippetType: SnippetType.llmOrderedMatch,
    resultCategory: ResultCategory.justForFun,
    name: "Video game character",
    isPremium: true,

    emojiString: "🕹️",
    order: 0,
  },
  {
    isPremium: true,
    emojiString: "🧸",
    snippetSlug: "sp-snippet-fun-winnie-the-pooh",
    name: "Winnie the Pooh character",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },

    order: 0,
    resultCategory: ResultCategory.justForFun,
    snippetType: SnippetType.llmOrderedMatch,
  },
  {
    order: 0,

    snippetSlug: "sp-snippet-fun-x-men-character",
    emojiString: "🧬",
    isPremium: true,
    resultCategory: ResultCategory.justForFun,
    name: "X-Men character",
    snippetType: SnippetType.llmOrderedMatch,
    discoveryGate: {
      numSuccessfulInvitesRequired: 3,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
  },
  {
    resultCategory: ResultCategory.workAndPurpose,

    isPremium: false,
    name: "Your ideal life",
    snippetType: SnippetType.nutshell,
    order: 0,
    snippetSlug: "sp-snippet-your-ideal-life",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "secondary-traits",
        "interests",
        "lifestyle",
      ],
    },
  },
];

export const computableSnippetsObject: Record<string, DiscoverableSnippet> = {
  "snippet-at-your-best": {
    resultCategory: ResultCategory.whoAreYou,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "communication-style",
        "love-attitudes",
        "love-style",
      ],
    },

    name: "At your best",
    snippetSlug: "snippet-at-your-best",
    context: ContentContext.identity,
    isPremium: true,
    snippetType: SnippetType.llmHeaderAndBody,
    order: 99,
  },
  "snippet-at-your-worst": {
    isPremium: true,
    resultCategory: ResultCategory.whoAreYou,
    snippetSlug: "snippet-at-your-worst",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "communication-style",
        "love-attitudes",
        "love-style",
      ],
    },
    name: "At your worst",
    snippetType: SnippetType.llmHeaderAndBody,
    order: 99,
    context: ContentContext.identity,
  },
  "snippet-blindspots-in-love": {
    name: "Blindspots in love",
    snippetSlug: "snippet-blindspots-in-love",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "love-attitudes",
        "love-style",
        "communication-style",
      ],
    },
    isPremium: true,
    resultCategory: ResultCategory.loveAndRelationships,

    order: 22,
    context: ContentContext.love,
    snippetType: SnippetType.llmList,
  },
  "snippet-bring-relationship": {
    resultCategory: ResultCategory.loveAndRelationships,
    context: ContentContext.love,
    order: 10,
    name: "5 things you bring to a relationship",
    snippetSlug: "snippet-bring-relationship",
    snippetType: SnippetType.llmList,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "communication-style",
        "love-style",
        "love-attitudes",
        "attachment-style",
        "sex-attitudes",
        "secondary-traits",
        "lifestyle",
      ],
    },
  },
  "snippet-core-self": {
    snippetType: SnippetType.llmTitlesAndContent,
    snippetSlug: "snippet-core-self",

    order: 1,
    name: "Core self",
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    resultCategory: ResultCategory.whoAreYou,
    context: ContentContext.identity,
  },
  "snippet-dont-vibe-with": {
    name: "Don't vibe with",
    order: 3,
    snippetSlug: "snippet-dont-vibe-with",
    context: ContentContext.identity,
    snippetType: SnippetType.llmList,

    resultCategory: ResultCategory.loveAndRelationships,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
  },
  "snippet-green-beige-red-flags": {
    order: 99,
    snippetType: SnippetType.llmTitlesAndContent,
    snippetSlug: "snippet-green-beige-red-flags",

    name: "Green, beige, and red flags",
    isPremium: true,
    discoveryGate: {
      numSuccessfulInvitesRequired: 3,
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "communication-style",
        "love-attitudes",
        "love-style",
        "lifestyle",
        "sex-attitudes",
      ],
    },
    context: ContentContext.love,
  },
  "snippet-higher-all-users": {
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    resultCategory: ResultCategory.byTheNumbers,
    order: 4,
    snippetSlug: "snippet-higher-all-users",
    name: "Higher than all users",
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    context: ContentContext.identity,

    snippetType: SnippetType.allUserComparison,
  },
  "snippet-higher-connections": {
    discoveryGate: {
      numFriendsRequired: 5,
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    snippetSlug: "snippet-higher-connections",
    resultCategory: ResultCategory.byTheNumbers,
    name: "Higher than your connections",
    order: 6,
    snippetType: SnippetType.connectionComparison,

    context: ContentContext.identity,
  },
  "snippet-highest-scoring-traits": {
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II"],
    },
    name: "Highest scoring traits",
    snippetSlug: "snippet-highest-scoring-traits",
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    context: ContentContext.identity,
    isPremium: false,
    resultCategory: ResultCategory.whoAreYou,
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    order: 0,
    snippetType: SnippetType.topScoringTraits,
  },
  "snippet-how-others-see-you": {
    context: ContentContext.identity,
    name: "How others see you",
    order: 0,
    snippetType: SnippetType.llmHeaderedList,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
      numPeerAssessorsRequired: 6,
    },
    resultCategory: ResultCategory.howOthersSeeYou,
    isPremium: false,
    snippetSlug: "snippet-how-others-see-you",
  },
  "snippet-ideal-work-environment": {
    name: "Ideal work environment",
    snippetType: SnippetType.llmList,

    snippetSlug: "snippet-ideal-work-environment",
    context: ContentContext.work,
    resultCategory: ResultCategory.workAndPurpose,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "communication-style",
        "interests",
        "strengths",
      ],
    },
    order: 16,
    isPremium: true,
  },
  "snippet-in-summary": {
    name: "In 5 short statements",
    snippetType: SnippetType.llmNatureStatements,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "values", "personality"],
    },
    isPremium: false,
    snippetSlug: "snippet-in-summary",
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    resultCategory: ResultCategory.whoAreYou,
    context: ContentContext.identity,
  },
  "snippet-internal-conflict": {
    name: "Internal conflict",
    context: ContentContext.identity,
    order: 21,
    snippetType: SnippetType.llmList,
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "interests",
        "love-attitudes",
        "love-style",
        "strengths",
        "communication-style",
      ],
    },
    snippetSlug: "snippet-internal-conflict",
    resultCategory: ResultCategory.personalDevelopment,
  },
  "snippet-leadership-style": {
    name: "Leadership style",
    context: ContentContext.work,
    snippetType: SnippetType.llmMatch,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "interests",
        "strengths",
        "communication-style",
      ],
    },
    resultCategory: ResultCategory.workAndPurpose,
    isPremium: true,
    order: 17,
    snippetSlug: "snippet-leadership-style",
  },
  "snippet-least-similar-connections": {
    snippetSlug: "snippet-least-similar-connections",

    order: 9,
    name: "Connections least like you",
    resultCategory: ResultCategory.byTheNumbers,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
      numFriendsRequired: 5,
    },
    snippetType: SnippetType.similarityComparison,

    context: ContentContext.identity,
  },
  "snippet-lower-all-users": {
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    context: ContentContext.identity,
    publicProfilePermission: PublicProfilePermission.endorsedElements,
    name: "Lower than all users",
    snippetType: SnippetType.allUserComparison,
    resultCategory: ResultCategory.byTheNumbers,
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    order: 5,
    snippetSlug: "snippet-lower-all-users",
  },
  "snippet-lower-connections": {
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
      numFriendsRequired: 5,
    },
    name: "Lower than your connections",

    resultCategory: ResultCategory.byTheNumbers,
    snippetSlug: "snippet-lower-connections",
    snippetType: SnippetType.connectionComparison,
    order: 7,
    context: ContentContext.identity,
  },
  "snippet-most-similar-connections": {
    resultCategory: ResultCategory.byTheNumbers,
    name: "Connections most like you",
    snippetType: SnippetType.similarityComparison,
    order: 8,
    context: ContentContext.identity,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
      numFriendsRequired: 5,
    },
    snippetSlug: "snippet-most-similar-connections",
  },
  "snippet-nutshell": {
    connectionAvailabilities: [
      ConnectionAvailability.basic,
      ConnectionAvailability.close,
      ConnectionAvailability.deep,
    ],
    snippetType: SnippetType.nutshell,
    context: ContentContext.identity,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    publicProfilePermission: PublicProfilePermission.adjectives,
    isPremium: false,
    name: "In summary",
    snippetSlug: "snippet-nutshell",
    resultCategory: ResultCategory.whoAreYou,
  },
  "snippet-obstacles-to-love": {
    name: "Obstacles to love",
    context: ContentContext.love,

    snippetType: SnippetType.llmList,
    isPremium: true,
    order: 18,
    snippetSlug: "snippet-obstacles-to-love",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "love-attitudes",
        "love-style",
        "communication-style",
      ],
      numSuccessfulInvitesRequired: 3,
    },
    resultCategory: ResultCategory.loveAndRelationships,
  },
  "snippet-overestimated-traits": {
    snippetType: SnippetType.peerRatedTraitsDelta,
    resultCategory: ResultCategory.howOthersSeeYou,
    name: "Overestimated traits",
    context: ContentContext.identity,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
    },
    order: 0,
    snippetSlug: "snippet-overestimated-traits",
    isPremium: false,
  },
  "snippet-peer-rated-cognition": {
    name: "Peer-rated Cognition",
    snippetType: SnippetType.aggregatePeerSpectrum,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II"],
      numPeerAssessorsRequired: 4,
    },
    isPremium: false,
    snippetSlug: "snippet-peer-rated-cognition",
    order: 0,
    resultCategory: ResultCategory.howOthersSeeYou,
    context: ContentContext.identity,
  },
  "snippet-peer-rated-interaction-style": {
    resultCategory: ResultCategory.howOthersSeeYou,
    snippetType: SnippetType.aggregatePeerTwoByTwo,
    snippetSlug: "snippet-peer-rated-interaction-style",
    name: "Peer-rated Interaction Style",
    context: ContentContext.identity,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
      ],
      numPeerAssessorsRequired: 4,
    },
    order: 0,
    isPremium: false,
  },
  "snippet-peer-rated-nature": {
    context: ContentContext.identity,
    resultCategory: ResultCategory.howOthersSeeYou,
    snippetSlug: "snippet-peer-rated-nature",
    name: "Peer-rated Nature",
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "values", "personality"],
      numPeerAssessorsRequired: 4,
    },
    isPremium: false,
    snippetType: SnippetType.peerRatedNature,
  },
  "snippet-peer-rated-primary-traits": {
    snippetSlug: "snippet-peer-rated-primary-traits",
    snippetType: SnippetType.aggregatePeerSpectrum,
    order: 0,
    resultCategory: ResultCategory.howOthersSeeYou,
    isPremium: false,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: ["personality"],
    },
    name: "Peer-rated Primary Traits",
    context: ContentContext.identity,
  },
  "snippet-peer-rated-strengths": {
    snippetType: SnippetType.topPeerRatedTraits,
    order: 0,
    resultCategory: ResultCategory.howOthersSeeYou,
    context: ContentContext.identity,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: ["strengths"],
    },
    name: "Peer-rated Strengths",
    isPremium: false,
    snippetSlug: "snippet-peer-rated-strengths",
  },
  "snippet-top-ten-careers": {
    snippetType: SnippetType.llmList,
    name: "Top 10 careers",
    order: 15,
    snippetSlug: "snippet-top-ten-careers",
    resultCategory: ResultCategory.workAndPurpose,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "communication-style",
        "interests",
        "strengths",
      ],
    },
    isPremium: true,
    context: ContentContext.work,
  },
  "snippet-underestimated-traits": {
    snippetSlug: "snippet-underestimated-traits",
    order: 0,
    snippetType: SnippetType.peerRatedTraitsDelta,

    context: ContentContext.identity,
    isPremium: false,
    discoveryGate: {
      numPeerAssessorsRequired: 4,
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
    },
    name: "Underestimated traits",
    resultCategory: ResultCategory.howOthersSeeYou,
  },
  "snippet-vibe-with": {
    context: ContentContext.identity,
    discoveryGate: {
      requiredDimensionSlugs: ["personality", "personality-II", "values"],
    },
    snippetSlug: "snippet-vibe-with",
    order: 2,
    snippetType: SnippetType.llmList,
    resultCategory: ResultCategory.loveAndRelationships,
    name: "Vibe with",
  },
  "snippet-your-blindspots": {
    context: ContentContext.identity,
    discoveryGate: {
      numPeerAssessorsRequired: 6,
      requiredDimensionSlugs: [
        "personality-II",
        "values",
        "personality",
        "communication-style",
        "strengths",
        "secondary-traits",
      ],
    },
    resultCategory: ResultCategory.howOthersSeeYou,
    snippetSlug: "snippet-your-blindspots",
    name: "Your blindspots",
    snippetType: SnippetType.llmList,
    isPremium: false,
    order: 0,
  },
  "snippet-your-deepest-themes": {
    order: 20,
    name: "Your deepest themes",
    snippetSlug: "snippet-your-deepest-themes",
    context: ContentContext.identity,
    isPremium: true,

    resultCategory: ResultCategory.whoAreYou,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "attachment-style",
        "sex-attitudes",
        "lifestyle",
        "interests",
        "love-attitudes",
        "love-style",
        "strengths",
        "communication-style",
      ],
    },
    snippetType: SnippetType.llmList,
  },
  "snippet-your-purpose": {
    isPremium: true,
    snippetSlug: "snippet-your-purpose",
    snippetType: SnippetType.llmHeaderedList,
    order: 19,
    name: "Your purpose",

    resultCategory: ResultCategory.workAndPurpose,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality",
        "personality-II",
        "values",
        "secondary-traits",
        "communication-style",
        "interests",
        "strengths",
      ],
    },
    context: ContentContext.work,
  },
  "sp-snippet-fun-classical-element": {
    emojiString: "🪨",

    order: 0,
    name: "Classical element",
    isPremium: false,
    snippetSlug: "sp-snippet-fun-classical-element",
    snippetType: SnippetType.llmOrderedMatch,
    resultCategory: ResultCategory.justForFun,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
  },
  "sp-snippet-fun-disney-character": {
    snippetType: SnippetType.llmOrderedMatch,

    name: "Disney character",
    order: 0,
    emojiString: "🏰",
    isPremium: true,
    snippetSlug: "sp-snippet-fun-disney-character",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },
    resultCategory: ResultCategory.justForFun,
  },
  "sp-snippet-fun-enneagram": {
    snippetType: SnippetType.llmOrderedMatch,

    name: "Enneagram type",
    resultCategory: ResultCategory.justForFun,
    emojiString: "🧩",
    order: 0,
    discoveryGate: {
      numSuccessfulInvitesRequired: 5,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    snippetSlug: "sp-snippet-fun-enneagram",
    isPremium: true,
  },
  "sp-snippet-fun-fictional-villain": {
    snippetSlug: "sp-snippet-fun-fictional-villain",
    resultCategory: ResultCategory.justForFun,
    emojiString: "😈",
    snippetType: SnippetType.llmOrderedMatch,
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 5,
    },
    name: "Fictional villain",
    order: 0,
  },
  "sp-snippet-fun-friend-type": {
    discoveryGate: {
      numSuccessfulInvitesRequired: 1,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },

    isPremium: true,
    snippetSlug: "sp-snippet-fun-friend-type",
    name: "Friend type",
    snippetType: SnippetType.llmOrderedMatch,
    order: 0,
    resultCategory: ResultCategory.justForFun,
    emojiString: "👭",
  },
  "sp-snippet-fun-game-of-thrones": {
    isPremium: true,
    name: "Game of Thrones house",
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
      numSuccessfulInvitesRequired: 3,
    },
    snippetSlug: "sp-snippet-fun-game-of-thrones",
    emojiString: "🐺",
    resultCategory: ResultCategory.justForFun,
  },
  "sp-snippet-fun-generation": {
    name: "Generation",
    isPremium: true,
    resultCategory: ResultCategory.justForFun,
    discoveryGate: {
      numSuccessfulInvitesRequired: 3,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-attitudes",
        "secondary-traits",
      ],
    },
    snippetSlug: "sp-snippet-fun-generation",
    emojiString: "🗓️",
    snippetType: SnippetType.llmOrderedMatch,
    order: 0,
  },
  "sp-snippet-fun-greek-god": {
    emojiString: "🔱",
    snippetType: SnippetType.llmOrderedMatch,

    resultCategory: ResultCategory.justForFun,
    isPremium: true,
    order: 0,
    name: "Greek god/goddess",
    snippetSlug: "sp-snippet-fun-greek-god",
    discoveryGate: {
      numSuccessfulInvitesRequired: 4,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-attitudes",
        "secondary-traits",
      ],
    },
  },
  "sp-snippet-fun-historical-leader": {
    order: 0,
    snippetSlug: "sp-snippet-fun-historical-leader",
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
      numSuccessfulInvitesRequired: 2,
    },
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "📜",
    name: "Famous historical leader",
    resultCategory: ResultCategory.justForFun,
  },
  "sp-snippet-fun-hogwarts": {
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    snippetSlug: "sp-snippet-fun-hogwarts",
    order: 0,
    emojiString: "🪄",
    isPremium: false,

    name: "Hogwarts house",
    resultCategory: ResultCategory.justForFun,
    snippetType: SnippetType.llmOrderedMatch,
  },
  "sp-snippet-fun-jungian-archetype": {
    snippetType: SnippetType.llmOrderedMatch,
    snippetSlug: "sp-snippet-fun-jungian-archetype",
    order: 0,
    discoveryGate: {
      numSuccessfulInvitesRequired: 5,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-attitudes",
        "secondary-traits",
      ],
    },

    resultCategory: ResultCategory.justForFun,
    isPremium: true,
    emojiString: "🌓",
    name: "Jungian archetype",
  },
  "sp-snippet-fun-numerology": {
    isPremium: true,
    name: "Life path number",
    resultCategory: ResultCategory.justForFun,
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "🔢",

    snippetSlug: "sp-snippet-fun-numerology",
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 4,
    },
  },
  "sp-snippet-fun-pixar-character": {
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    isPremium: false,
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-pixar-character",
    order: 0,
    emojiString: "💡",
    name: "Pixar character",
    snippetType: SnippetType.llmOrderedMatch,
  },
  "sp-snippet-fun-planet": {
    snippetSlug: "sp-snippet-fun-planet",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 3,
    },
    isPremium: true,

    name: "Planet",
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
    resultCategory: ResultCategory.justForFun,
    emojiString: "🪐",
  },
  "sp-snippet-fun-recs-bosses-work": {
    snippetSlug: "sp-snippet-fun-recs-bosses-work",
    isPremium: true,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "interests",
        "secondary-traits",
      ],
      numSuccessfulInvitesRequired: 4,
    },
    emojiString: "🫡",
    resultCategory: ResultCategory.recommendations,
    snippetType: SnippetType.llmUnorderedMatch,

    name: "Bosses to work with",
  },
  "sp-snippet-fun-recs-fiction-books": {
    resultCategory: ResultCategory.recommendations,
    emojiString: "📖",
    snippetSlug: "sp-snippet-fun-recs-fiction-books",
    order: 0,
    isPremium: true,

    discoveryGate: {
      numSuccessfulInvitesRequired: 1,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    name: "Fiction books",
    snippetType: SnippetType.llmUnorderedMatch,
  },
  "sp-snippet-fun-recs-foreign-films": {
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    resultCategory: ResultCategory.recommendations,
    isPremium: true,
    emojiString: "🎬",
    snippetSlug: "sp-snippet-fun-recs-foreign-films",
    name: "Foreign films",

    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },
  },
  "sp-snippet-fun-recs-lesser-known-movies": {
    snippetSlug: "sp-snippet-fun-recs-lesser-known-movies",
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    resultCategory: ResultCategory.recommendations,
    emojiString: "🎞️",
    isPremium: true,
    name: "Lesser-known movies",
    discoveryGate: {
      numSuccessfulInvitesRequired: 2,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
  },
  "sp-snippet-fun-recs-movies": {
    snippetSlug: "sp-snippet-fun-recs-movies",
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    name: "Movies",
    resultCategory: ResultCategory.recommendations,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },

    isPremium: false,
    emojiString: "🍿",
  },
  "sp-snippet-fun-recs-non-fiction-books": {
    resultCategory: ResultCategory.recommendations,
    emojiString: "📚",
    isPremium: true,
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 3,
    },
    snippetSlug: "sp-snippet-fun-recs-non-fiction-books",

    snippetType: SnippetType.llmUnorderedMatch,
    name: "Non-fiction books",
  },
  "sp-snippet-fun-recs-people-date": {
    emojiString: "💑",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-style",
        "love-attitudes",
        "lifestyle",
        "attachment-style",
        "sex-attitudes",
        "secondary-traits",
      ],
      numSuccessfulInvitesRequired: 5,
    },
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    snippetSlug: "sp-snippet-fun-recs-people-date",
    name: "People to date",
    resultCategory: ResultCategory.recommendations,
    isPremium: true,
  },
  "sp-snippet-fun-recs-tv-shows": {
    snippetSlug: "sp-snippet-fun-recs-tv-shows",
    isPremium: true,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 1,
    },
    emojiString: "📺",
    order: 0,
    snippetType: SnippetType.llmUnorderedMatch,
    resultCategory: ResultCategory.recommendations,
    name: "TV shows",
  },
  "sp-snippet-fun-season": {
    order: 0,
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    snippetType: SnippetType.llmOrderedMatch,
    isPremium: false,
    resultCategory: ResultCategory.justForFun,

    name: "Season of the year",
    emojiString: "🍂",
    snippetSlug: "sp-snippet-fun-season",
  },
  "sp-snippet-fun-sitcom-character": {
    name: "Sitcom character",

    isPremium: true,
    emojiString: "📺",
    discoveryGate: {
      numSuccessfulInvitesRequired: 1,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-sitcom-character",
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
  },
  "sp-snippet-fun-star-sign": {
    order: 0,
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "✨",
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-star-sign",
    name: "Star sign",

    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
        "love-style",
        "love-attitudes",
      ],
      numSuccessfulInvitesRequired: 1,
    },
    isPremium: true,
  },
  "sp-snippet-fun-superhero": {
    name: "Superhero",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },

    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "🦸",
    order: 0,
    resultCategory: ResultCategory.justForFun,
    snippetSlug: "sp-snippet-fun-superhero",
    isPremium: true,
  },
  "sp-snippet-fun-tarot-card": {
    name: "Tarot card",
    snippetSlug: "sp-snippet-fun-tarot-card",
    resultCategory: ResultCategory.justForFun,
    discoveryGate: {
      numSuccessfulInvitesRequired: 4,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
    isPremium: true,
    snippetType: SnippetType.llmOrderedMatch,
    emojiString: "🃏",
    order: 0,
  },
  "sp-snippet-fun-video-game-character": {
    snippetSlug: "sp-snippet-fun-video-game-character",
    discoveryGate: {
      numSuccessfulInvitesRequired: 2,
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "communication-style",
      ],
    },
    snippetType: SnippetType.llmOrderedMatch,
    resultCategory: ResultCategory.justForFun,
    name: "Video game character",
    isPremium: true,

    emojiString: "🕹️",
    order: 0,
  },
  "sp-snippet-fun-winnie-the-pooh": {
    isPremium: true,
    emojiString: "🧸",
    snippetSlug: "sp-snippet-fun-winnie-the-pooh",
    name: "Winnie the Pooh character",
    discoveryGate: {
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
      numSuccessfulInvitesRequired: 2,
    },

    order: 0,
    resultCategory: ResultCategory.justForFun,
    snippetType: SnippetType.llmOrderedMatch,
  },
  "sp-snippet-fun-x-men-character": {
    order: 0,

    snippetSlug: "sp-snippet-fun-x-men-character",
    emojiString: "🧬",
    isPremium: true,
    resultCategory: ResultCategory.justForFun,
    name: "X-Men character",
    snippetType: SnippetType.llmOrderedMatch,
    discoveryGate: {
      numSuccessfulInvitesRequired: 3,
      requiredDimensionSlugs: ["personality-II", "personality", "values"],
    },
  },
  "sp-snippet-your-ideal-life": {
    resultCategory: ResultCategory.workAndPurpose,

    isPremium: false,
    name: "Your ideal life",
    snippetType: SnippetType.nutshell,
    order: 0,
    snippetSlug: "sp-snippet-your-ideal-life",
    discoveryGate: {
      requiredDimensionSlugs: [
        "personality-II",
        "personality",
        "values",
        "secondary-traits",
        "interests",
        "lifestyle",
      ],
    },
  },
};

export const snippetsIconMap: {
  [key: string]: JSX.Element;
} = {
  "snippet-higher-all-users": <HigherThanAllUsersIcon />,
  "snippet-higher-connections": <HigherThanAllConnectionsIcon />,
  "snippet-least-similar-connections": <ConnectionsLeastLikeYouIcon />,
  "snippet-lower-all-users": <LowerThanAllUsersIcon />,
  "snippet-lower-connections": <LowerThanAllConnectionsIcon />,
  "snippet-most-similar-connections": <ConnectionsMostLikeYouIcon />,
  "snippet-vibe-with": <VibeWithIcon />,
  "snippet-dont-vibe-with": <DontVibeWithIcon />,
  "snippet-core-self": <CoreSelfSnippetIcon />,
  "snippet-bring-relationship": <BringRelationshipIcon />,
  "snippet-top-ten-careers": <CareerSnippetIcon />,
  "snippet-ideal-work-environment": <WorkEnvironmentSnippetIcon />,
  "snippet-leadership-style": <LeadershipStyleSnippetIcon />,
  "snippet-obstacles-to-love": <ObstaclesToLoveSnippetIcon />,
  "snippet-your-purpose": <YourPurposeSnippetIcon />,
  "snippet-your-deepest-themes": <DeepestThemesSnippetIcon />,
  "snippet-internal-conflict": <InternalConflictSnippetIcon />,
  "snippet-blindspots-in-love": <BlindspotsInLoveSNippetIcon />,
  "snippet-at-your-worst": <AtYourWorstSnippetIcon />,
  "snippet-at-your-best": <AtYourBestSnippetIcon />,
  "snippet-green-beige-red-flags": <FlagsSnippetIcon />,
  "snippet-in-summary": <InSummarySnippetIcon />,
  "snippet-peer-rated-nature": <NatureAccordingToPeersIcon />,
  "snippet-peer-rated-cognition": <PeerRatedCognitionIcon />,
  "snippet-peer-rated-interaction-style": <PeerInteractionStylesSnippetIcon />,
  "snippet-peer-rated-primary-traits": <PeerPrimaryTraitsSnippetIcon />,
  "snippet-peer-rated-strengths": <PeerStrengthsSnippetIcon />,
  "snippet-overestimated-traits": <OverEstimatedSnippetIcon />,
  "snippet-underestimated-traits": <UnderEstimatedSnippetIcon />,
  "snippet-your-blindspots": <YourBlindspotsSnippetIcon />,
  "snippet-how-others-see-you": <HowOthersSeeYouSnippetIcon />,
  "snippet-highest-scoring-traits": <HighestScoringTraitsSnippetIcon />,
  "snippet-nutshell": <NutshellSnippetIcon />,
  "sp-snippet-fun-greek-god": <></>,
  "sp-snippet-fun-hogwarts": <></>,
  "sp-snippet-fun-generation": <></>,
  "sp-snippet-fun-friend-type": <></>,
  "sp-snippet-fun-superhero": <></>,
  "sp-snippet-fun-x-men-character": <></>,
  "sp-snippet-fun-tarot-card": <></>,
  "sp-snippet-fun-historical-leader": <></>,
  "sp-snippet-fun-season": <></>,
  "sp-snippet-fun-planet": <></>,
  "sp-snippet-fun-numerology": <></>,
  "sp-snippet-fun-game-of-thrones": <></>,
  "sp-snippet-fun-enneagram": <></>,
  "sp-snippet-fun-star-sign": <></>,
  "sp-snippet-fun-classical-element": <></>,
  "sp-snippet-fun-recs-non-fiction-books": <></>,
  "sp-snippet-fun-recs-fiction-books": <></>,
  "sp-snippet-fun-recs-people-date": <></>,
  "sp-snippet-fun-recs-bosses-work": <></>,
  "sp-snippet-fun-recs-movies": <></>,
  "sp-snippet-fun-recs-tv-shows": <></>,
  "sp-snippet-fun-recs-lesser-known-movies": <></>,
  "sp-snippet-fun-recs-foreign-films": <></>,
  "sp-snippet-fun-pixar-character": <></>,
  "sp-snippet-fun-video-game-character": <></>,
  "sp-snippet-fun-winnie-the-pooh": <></>,
  "sp-snippet-fun-sitcom-character": <></>,
  "sp-snippet-fun-disney-character": <></>,
  "sp-snippet-fun-fictional-villain": <></>,
  "sp-snippet-fun-jungian-archetype": <></>,
  "sp-snippet-your-ideal-life": <YouIdealLifeSnippetIcon />,
};

export const snippetMappedSubtitles = {
  "snippet-vibe-with": "The 3 types of people you get along with best.",
  "snippet-dont-vibe-with":
    "The 3 types of people you tend to not get along with.",
  "snippet-core-self":
    "A basic summary of you: your motivations, your fears, and how you relate to others.",
  "snippet-bring-relationship":
    "What you offer to your partners in romantic relationships.",
  "snippet-top-ten-careers": "Job roles and careers that fit your personality.",
  "snippet-ideal-work-environment":
    "5 aspects of what your ideal work environment might look like.",
  "snippet-leadership-style":
    "Your leadership style refers to how you motivate, guide, and manage teams. We tend to exhibit a blend of two or more. Different situations call for different leadership styles.",
  "snippet-obstacles-to-love":
    "5 obstacles you might face when looking for love.",
  "snippet-your-purpose":
    "An answer to 3 important questions about your place in the world.",
  "snippet-your-deepest-themes": "The themes that underly your personality.",
  "snippet-internal-conflict":
    "Five areas where you might feel internal conflict.",
  "snippet-blindspots-in-love":
    "5 ways your traits might conflict to affect romantic relationships.",
  "snippet-at-your-worst": "A look at what you could be like at your worst. ",
  "snippet-at-your-best": "A look at what you could be like at your best. ",
  "snippet-green-beige-red-flags":
    "A list of potential green, beige, and red flags of dating you. They might not all be spot on, but they'll hint at who you are.",
  "snippet-in-summary": "Your personality summarized in 5 statements.",
  "snippet-peer-rated-nature":
    "How your peers perceive your underlying nature.",
  "snippet-peer-rated-cognition":
    "How your peers rate you on the basic traits that influence how you engage with the world.",
  "snippet-peer-rated-interaction-style":
    "How your peers perceive your Interaction Style.",
  "snippet-peer-rated-primary-traits":
    "How your peers rate you on the five most fundamental personality traits.",
  "snippet-peer-rated-strengths": "How others perceive your strengths.",
  "snippet-overestimated-traits":
    "You think you score higher on these traits than your peers think you do.",
  "snippet-underestimated-traits":
    "Your peers think you score higher on these traits than you think you do.",
  "snippet-your-blindspots":
    "How your self-perception compares with how others see you.",
  "snippet-how-others-see-you":
    "A basic summary of you according to your peers: your overarching themes, your strengths, and your weaknesses.",
  "snippet-nutshell": "A text summary of your core personality.",
  "sp-snippet-fun-greek-god":
    "The greek god/goddess that best represents your personality.",
  "sp-snippet-fun-hogwarts":
    "The Hogwarts that best represents your personality.",
  "sp-snippet-fun-generation":
    "The generation/age cohort that best represents your personality.",
  "sp-snippet-fun-friend-type":
    "The type of friend you are according to your personality.",
  "sp-snippet-fun-superhero":
    "The superhero that best represents your personality.",
  "sp-snippet-fun-x-men-character":
    "The X-Men character that best represents your personality.",
  "sp-snippet-fun-tarot-card":
    "The tarot card that best represents your personality.",
  "sp-snippet-fun-historical-leader":
    "The famous historical leader that best represents your personality.",
  "sp-snippet-fun-season":
    "The season of the year that best represents your personality.",
  "sp-snippet-fun-planet": "The planet that best represents your personality.",
  "sp-snippet-fun-numerology":
    "The numerology life path number that best represents your personality.",
  "sp-snippet-fun-game-of-thrones":
    "The Game of Thrones house that best represents your personality.",
  "sp-snippet-fun-enneagram":
    "The attributes associated with these Enneagram types best represent your personality. Note: this may not be your actual Enneagram type.",
  "sp-snippet-fun-star-sign":
    "The attributes associated with these Zodiac signs best represent your personality. Note: this may not be your actual astrological sign.",
  "sp-snippet-fun-classical-element":
    "The classical element that best represents your personality.",
  "sp-snippet-fun-recs-non-fiction-books":
    "A collection of non-fiction books you would probably like based on your personality.",
  "sp-snippet-fun-recs-fiction-books":
    "A collection of fiction books you would probably like based on your personality.",
  "sp-snippet-fun-recs-people-date":
    "The types of people you'd be a good romantic match for based on your personality.",
  "sp-snippet-fun-recs-bosses-work":
    "The types of bosses you'd work well with based on your personality.",
  "sp-snippet-fun-recs-movies":
    "A list of movies you would probably like based on your personality.",
  "sp-snippet-fun-recs-tv-shows":
    "A list of TV shows you would probably like based on your personality.",
  "sp-snippet-fun-recs-lesser-known-movies":
    "A list of movies you may not have heard of that you'd probably like based on your personality.",
  "sp-snippet-fun-recs-foreign-films":
    "A list of foreign films you would probably like based on your personality.",
  "sp-snippet-fun-pixar-character":
    "The Pixar character that best represents your personality.",
  "sp-snippet-fun-video-game-character":
    "The video game character that best represents your personality.",
  "sp-snippet-fun-winnie-the-pooh":
    "The Winnie the Pooh character that best represents your personality.",
  "sp-snippet-fun-sitcom-character":
    "The sitcom character that best represents your personality.",
  "sp-snippet-fun-disney-character":
    "The Disney character that best represents your personality.",
  "sp-snippet-fun-fictional-villain":
    "The fictional villain that best represents your personality.",
  "sp-snippet-fun-jungian-archetype":
    "The Jungian archetype that best represents your personality.",
  "sp-snippet-your-ideal-life": "A text summary of your ideal life.",
};

export const lowerCaseSnippetTitles = [
  "snippet-vibe-with",
  "snippet-dont-vibe-with",
  "snippet-bring-relationship",
  "snippet-ideal-work-environment",
  "snippet-top-ten-careers",
  "snippet-your-purpose",
  "snippet-obstacles-to-love",
  "snippet-blindspots-in-love",
  "snippet-your-deepest-themes",
  "snippet-internal-conflict",
];

export const snippetSlugsWithImages = [
  "snippet-at-your-worst",
  "snippet-at-your-best",
];

export const peerRatedSnippets = [
  "snippet-peer-rated-nature",
  "snippet-peer-rated-cognition",
  "snippet-peer-rated-interaction-style",
  "snippet-peer-rated-primary-traits",
  "snippet-peer-rated-strengths",
  "How others perceive your strengths.",
  "snippet-overestimated-traits",
  "snippet-underestimated-traits",
  "snippet-your-blindspots",
  "snippet-how-others-see-you",
];
