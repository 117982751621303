import {
  ContentType,
  DiscoverableSnippet,
  DiscoveredSnippet,
  SnippetType,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import { peerRatedSnippets, snippetMappedSubtitles } from "./snippetsMap";
import { RootState } from "@/redux/store";
import Spectrums, {
  PeerRatedScorePill,
} from "@/components/shared/Spectrum/Spectrums";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetAggregatePeerSpectrumView(props: Props) {
  return (
    <>
      {props.snippet.snippetType === SnippetType.aggregatePeerSpectrum && (
        <>
          <h2
            className={
              peerRatedSnippets.includes(props.snippet?.snippetSlug)
                ? styles.llmListNamePeer
                : styles.llmListName
            }
          >
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <div className={styles.spectrumsDiv}>
            <Spectrums
              aggregatePeer
              noMargin
              otherPublicProfile={null}
              imageUrl={""}
              contentIdentifier={{
                contentType: ContentType.resultSnippet,
                contentSlug: props.snippet?.snippetSlug,
              }}
            />
          </div>
          <div className={styles.spectrumLegend}>
            <div className={styles.peerRatedImage}>
              <PeerRatedScorePill />
            </div>
            <p className={styles.legendText}>Peer-rated scores</p>
          </div>
        </>
      )}
    </>
  );
}
