import {
  DiscoveredSnippet,
  DiscoverableSnippet,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import { snippetMappedSubtitles } from "./snippetsMap";
import { capitalizeFirstLetter } from "@/helpers/helpers";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetNutshellView(props: Props) {
  const mapped = props.snippet.snippetDetail.nutshellDetail?.llmContent?.map(
    (llmContent, i) => {
      return (
        <div
          style={i === 0 ? { marginTop: "60px" } : {}}
          key={llmContent.title}
          className={styles.nutshellRow}
        >
          <h3 className={styles.nuthsellTitle}>{llmContent.title}</h3>
          {llmContent.paragraphs?.map((p) => {
            return (
              <p className={styles.nutshellPara} key={p}>
                {capitalizeFirstLetter(p)}
              </p>
            );
          })}
        </div>
      );
    }
  );

  return (
    <>
      {props.snippet.snippetDetail.nutshellDetail && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <div className={styles.nutshellWrapper}>{mapped}</div>
        </>
      )}
    </>
  );
}
