import styles from "./DiscoveryGate.module.css";

import { useModalContext } from "@/context/ModalContext";
import PeerRatingRequestModal from "@/components/connections/PeerRatingRequestModal/PeerRatingRequestModal";
import DiscoveryCheckMark from "./DiscoveryCheckMark";

export default function UserPeerRatingGate(props: {
  numPeerRatingsRequired: number;
  peerRatingsCount: number;
  isPeerRatingComplete: boolean;
}) {
  const { setModalComponent } = useModalContext();

  if (props.numPeerRatingsRequired <= props.peerRatingsCount) {
    return (
      <div className={styles.tableHeader}>
        Peer Ratings{" "}
        <div className={styles.checkmarkCompleteDiv}>
          <DiscoveryCheckMark backgroundColor="#e6e1df" checkColor="#2D2927" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.tableHeader}>Peer Ratings</div>
      <div className={styles.peerRatingWrapper}>
        <p className={styles.peerRatingText}>
          {props.numPeerRatingsRequired} Peer Ratings Required
        </p>
        <div className={styles.scoreBar}>
          <div
            style={{
              width: `${
                (props.peerRatingsCount / props.numPeerRatingsRequired) * 100
              }%`,
            }}
            className={styles.scoreBarInner}
          ></div>
        </div>
        <h3 className={styles.peerRatingCount}>
          {props.peerRatingsCount} / {props.numPeerRatingsRequired}
        </h3>
        {/* <button
          onClick={() => {
            setModalComponent(
              <PeerRatingRequestModal title="Get endorsed faster!" />
            );
          }}
          className={styles.peerRatingButton}
        >
          Request Peer Ratings
        </button> */}
      </div>
    </>
  );
}
