import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./ChildElement.module.css";

import { ExtendedScale } from "@/models/sharedModels";
import Element from "@/components/traits/element/Element/Element";
import elementJsonData from "../../../../../assets/traitInfo/elements.json";
import TraitsHeading from "../../Heading/TraitsHeading";
import { useAuth } from "@/context/AuthContext";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import { natureData } from "@/assets/traitInfo/natureData";

type Props = {
  scale: ExtendedScale;
  authenticated?: boolean;
  uid?: string | undefined;
};

export default function ChildElement(props: Props) {
  const parentColor = useGetParentColor({ scale: props.scale });
  const { user } = useAuth();
  const { setModalComponent } = useModalContext();

  if (props.authenticated) {
    return (
      <div className={styles.main}>
        <TraitsHeading title="Child" />
        <p className={styles.subTitle}>
          {props.scale.alias ?? props.scale.name} is a child component of{" "}
          {props.scale.traitContent.childDetails?.parentTrait.name}
        </p>
        <div className={styles.parent}>
          <div className={styles.archDiv}>
            {parentColor &&
              props.scale.traitContent.childDetails?.parentTrait.slug && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (
                      props.scale.traitContent.childDetails?.parentTrait.slug
                    ) {
                      setModalComponent(
                        <ElementModal
                          slug={
                            props.scale.traitContent.childDetails?.parentTrait
                              .slug
                          }
                          uid={props.uid}
                        />
                      );
                    }
                  }}
                  className="elBodyNoMarginTopBottom"
                >
                  <Element
                    slug={
                      props.scale.traitContent.childDetails?.parentTrait.slug
                    }
                  />
                </div>
              )}
            <div className={styles.archLine}></div>
          </div>
          <div className={styles.long}></div>

          {props.scale.traitContent.childDetails?.parentTrait.slug && (
            <div className={styles.wrapper}>
              <div className={styles.elDiv}>
                <div className={styles.archLine}></div>
                <div className="elBodyNoMarginTopBottom">
                  <Element slug={props.scale.slug} />
                </div>
              </div>
              <div style={{ marginLeft: "5px" }} className={styles.elDiv}>
                <div className={styles.archLine}></div>
                <div className={styles.otherParent}>
                  <h1>
                    <strong>+</strong>
                  </h1>
                  <p>
                    {props.scale.traitContent.childDetails?.numOtherTraits +
                      " "}
                    other traits
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <TraitsHeading title="Child" />
      <p className={styles.subTitle}>
        {props.scale.alias ?? props.scale.name} is a child component of{" "}
        {props.scale.traitContent.childDetails?.parentTrait.name}
      </p>
      <div className={styles.parent}>
        <div className={styles.archDiv}>
          {parentColor &&
            props.scale.traitContent.childDetails?.parentTrait.slug && (
              <Link
                href={`/traits/elements/${props.scale.traitContent.childDetails?.parentTrait.slug}`}
                className="elBodyNoMarginTopBottom"
              >
                <Element
                  slug={props.scale.traitContent.childDetails?.parentTrait.slug}
                />
              </Link>
            )}
          <div className={styles.archLine}></div>
        </div>
        <div className={styles.long}></div>

        {props.scale.traitContent.childDetails?.parentTrait.slug && (
          <div className={styles.wrapper}>
            <div className={styles.elDiv}>
              <div className={styles.archLine}></div>
              <div className="elBodyNoMarginTopBottom">
                <Element slug={props.scale.slug} />
              </div>
            </div>
            <div style={{ marginLeft: "5px" }} className={styles.elDiv}>
              <div className={styles.archLine}></div>
              <div className={styles.otherParent}>
                <h1>
                  <strong>+</strong>
                </h1>
                <p>
                  {props.scale.traitContent.childDetails?.numOtherTraits + " "}
                  other traits
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function useGetParentColor(props: { scale: ExtendedScale }) {
  const [parentColor, setParentColor] = useState<string | null>(null);
  const { user } = useAuth();
  useEffect(() => {
    if (props.scale.traitContent.childDetails?.parentTrait.slug) {
      elementJsonData.forEach((el) => {
        if (
          el.slug === props.scale.traitContent.childDetails?.parentTrait.slug
        ) {
          setParentColor(
            natureData?.find((n) => n.slug === el?.nature)?.color ?? "gray"
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return parentColor;
}
