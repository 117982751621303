export default function LowBatteryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64453 6.5282H16.2085V2.375C16.2085 2.03125 16.4882 1.75 16.8335 1.75H24.1679C24.5117 1.75 24.7929 2.02969 24.7929 2.375V6.5298H31.3569C31.7007 6.5298 31.9819 6.81105 31.9819 7.1548V38.6268C31.9819 38.9706 31.7022 39.2518 31.3569 39.2518H9.64453C9.30078 39.2518 9.01953 38.9706 9.01953 38.6268V7.1548C9.01953 6.80949 9.30078 6.5282 9.64453 6.5282ZM13.5742 10.3032C13.143 10.3032 12.793 10.6532 12.793 11.0844V34.6936C12.793 35.1249 13.143 35.4749 13.5742 35.4749H27.4274C27.8587 35.4749 28.2071 35.1249 28.2071 34.6936V11.0844C28.2071 10.6532 27.8587 10.3032 27.4274 10.3032H13.5742ZM26.6463 29.5737H14.3555V33.9145H26.6463V29.5737Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
