import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import styles from "./MessageSelectorModal.module.css";

import { RootState } from "@/redux/store";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";

export default function MessageSelectorModal() {
  const router = useRouter();
  const connectionsState = useSelector((state: RootState) => state.connections);

  const mappedConnections = connectionsState?.publicProfiles?.map((profile) => {
    return (
      <div
        onClick={() => router.push(`/app/messaging/${profile.ownerUUID}`)}
        className={styles.personDiv}
        key={profile.ownerUUID}
      >
        <SmallProfileImage
          medium
          imageUrl={profile.imageUrl}
          publicProfile={profile}
          noLink
        />
        <div className={styles.infoDiv}>
          <p className={styles.name}>{profile.name}</p>
          <p className={styles.username}>@{profile.userName}</p>
        </div>
      </div>
    );
  });

  return (
    <ModalWrapper title="Start a conversation">
      <main className={styles.main}>{mappedConnections}</main>
    </ModalWrapper>
  );
}
