export default function PrivateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM32.5899 19.0694C33.3327 20.109 33.3327 21.4296 32.5899 22.4693L32.5881 22.4675C31.4143 24.1133 30.0369 25.448 28.534 26.4726L25.7282 23.7168C26.2309 22.8466 26.518 21.8401 26.518 20.7684C26.518 17.4608 23.7881 14.7785 20.4194 14.7785C19.3271 14.7785 18.3033 15.0605 17.4175 15.5542L15.3107 13.485C21.2845 11.1868 28.2925 13.0475 32.5899 19.0694ZM30.6008 29.9335L11.0889 10.7693L10.2378 11.6052L13.1906 14.5054C11.3421 15.5843 9.65122 17.1056 8.24942 19.0704C7.50664 20.11 7.50664 21.4306 8.24942 22.4703C12.7932 28.8355 20.3641 30.5515 26.5414 27.6183L29.7497 30.7694L30.6008 29.9335ZM23.4292 24.5606L24.2854 25.4015C23.2331 26.2484 21.8862 26.758 20.419 26.758C17.0514 26.758 14.3205 24.0767 14.3205 20.7681C14.3205 19.3271 14.8385 18.0045 15.7018 16.9709L16.558 17.8118C15.9103 18.6267 15.523 19.6533 15.523 20.7682C15.523 23.4246 17.7157 25.5771 20.4192 25.5771C21.5543 25.5771 22.5985 25.1968 23.4292 24.5606ZM25.3154 20.7694C25.3154 18.113 23.1227 15.9605 20.4193 15.9605C19.6642 15.9605 18.948 16.1291 18.3095 16.4291L24.8383 22.8416C25.1438 22.2144 25.3154 21.5119 25.3154 20.7694Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
