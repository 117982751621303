export default function CheckMark(props: { color: string; padding?: number }) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={props.padding ? { padding: `${props.padding}px` } : {}}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7618 2.1424L5.13571 9.81258L0.873047 5.52528L2.78425 3.62506L5.13571 5.99012L10.8506 0.242188L12.7618 2.1424Z"
        fill={props.color}
      />
    </svg>
  );
}
