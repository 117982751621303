export default function OutlooksWorldViewsIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0016 1.64844H20C9.88124 1.64844 1.64844 9.88124 1.64844 20C1.64844 30.1188 9.88124 38.3516 20 38.3516C30.1188 38.3501 38.3516 30.1188 38.3516 20C38.3516 9.88124 30.1188 1.65004 20.0016 1.64844ZM20.0016 35.4032C20 35.4032 20 35.4032 20.0016 35.4032C18.1219 35.4032 16.1219 33.3439 14.7484 29.894H25.2532C23.8798 33.3456 21.8798 35.4032 20.0016 35.4032ZM13.8188 26.9468C13.4219 25.3031 13.1563 23.464 13.0672 21.4732H26.9328C26.8438 23.4639 26.5782 25.3029 26.1813 26.9468H13.8188ZM4.6688 21.4732H10.1188C10.1985 23.4045 10.4313 25.242 10.7985 26.9468H6.25768C5.40924 25.2734 4.85476 23.4281 4.6688 21.4732ZM20 4.59684C21.8797 4.59684 23.8797 6.6562 25.2516 10.106L14.7484 10.1045C16.1218 6.65448 18.1218 4.59684 20 4.59684ZM26.1812 13.0532C26.5781 14.697 26.8437 16.536 26.9328 18.5268H13.0672C13.1562 16.5362 13.4218 14.6972 13.8187 13.0532H26.1812ZM10.1188 18.5268H4.6688C4.85474 16.5737 5.40944 14.7268 6.25944 13.0532H10.7986C10.4315 14.7579 10.1985 16.5954 10.1188 18.5268ZM29.8812 21.4737H35.3312C35.1453 23.4268 34.5906 25.2737 33.7406 26.9473H29.2014C29.5686 25.2426 29.8015 23.4051 29.8812 21.4737ZM29.8812 18.5268C29.8015 16.5956 29.5687 14.7581 29.2015 13.0532H33.7407C34.5907 14.7267 35.1438 16.5736 35.3314 18.5268H29.8812ZM31.7937 10.1048H28.3921C27.8718 8.57672 27.2328 7.2064 26.4968 6.03764C28.5484 6.997 30.3531 8.39232 31.7937 10.1048ZM13.5029 6.03764C12.767 7.2064 12.1279 8.57516 11.6076 10.1048H8.206C9.64664 8.39236 11.453 6.99704 13.5029 6.03764ZM8.20608 29.8956H11.6076C12.128 31.4238 12.767 32.7941 13.503 33.9628C11.453 33.0035 9.64664 31.6082 8.20608 29.8956ZM26.4981 33.9612C27.234 32.7925 27.8731 31.4237 28.3934 29.894H31.795C30.3528 31.6081 28.548 33.0034 26.4981 33.9612Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
