export function NewMessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M19.7967 2.08398C18.994 2.08398 18.1932 2.38768 17.5868 2.99316L16.3885 4.16698H4.97651C3.39156 4.16698 2.08301 5.47558 2.08301 7.06048V20.0242C2.08301 21.6092 3.39161 22.9177 4.97651 22.9177H17.9413C19.5262 22.9177 20.8348 21.6091 20.8348 20.0242V8.61298L22.0086 7.41473C23.2205 6.20378 23.2205 4.20473 22.0086 2.99373C21.4031 2.38826 20.5994 2.08456 19.7967 2.08456L19.7967 2.08398ZM19.8006 4.14746C20.0643 4.14746 20.3289 4.25195 20.5389 4.46288C20.9598 4.88378 20.9598 5.51953 20.5389 5.94043C20.536 5.94239 20.533 5.94434 20.5311 5.94825L12.0653 14.583H10.4189V12.9375L19.0556 4.47175C19.0576 4.46882 19.0595 4.46784 19.0615 4.46393C19.2714 4.25397 19.5361 4.14851 19.7997 4.14851L19.8006 4.14746ZM4.97636 6.25098H14.2644L8.64336 11.7607C8.44707 11.956 8.33574 12.2217 8.33574 12.499V15.628C8.33769 16.2003 8.80156 16.6632 9.37381 16.6661H12.5046C12.7809 16.6651 13.0466 16.5548 13.2409 16.3594L18.7506 10.7384V20.0254C18.7506 20.4922 18.4079 20.835 17.9411 20.835L4.97631 20.834C4.50951 20.834 4.16674 20.4913 4.16674 20.0245V7.06071C4.16674 6.59391 4.50951 6.25113 4.97631 6.25113L4.97636 6.25098Z"
        fill="#888888"
      />
    </svg>
  );
}
