import {
  PeriodicQuiz,
  PeriodicQuizResult,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { PeriodicQuizConnectionScores } from "@/models/sharedModels";

export interface PeriodicQuizSliceInterface {
  availableQuiz: PeriodicQuiz | null;
  periodicQuizResults: PeriodicQuizResult[];
  periodicQuizConnectionScores: PeriodicQuizConnectionScores | null;
}

const initialState: PeriodicQuizSliceInterface = {
  availableQuiz: null,
  periodicQuizResults: [],
  periodicQuizConnectionScores: null,
};

export const periodicQuizSlice = createSlice({
  name: "periodicQuiz",
  initialState: initialState,
  reducers: {
    setAvailableQuiz: (state, action: PayloadAction<PeriodicQuiz>) => {
      return { ...state, availableQuiz: action.payload };
    },
    setPeriodicQuizResults: (
      state,
      action: PayloadAction<PeriodicQuizResult[]>
    ) => {
      return { ...state, periodicQuizResults: action.payload };
    },
    setPeriodicQuizConnectionScores: (
      state,
      action: PayloadAction<PeriodicQuizConnectionScores>
    ) => {
      return { ...state, periodicQuizConnectionScores: action.payload };
    },
  },
});

export const {
  setAvailableQuiz,
  setPeriodicQuizResults,
  setPeriodicQuizConnectionScores,
} = periodicQuizSlice.actions;

export default periodicQuizSlice.reducer;
