import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function ConflictStylesIcon(
  props: DimensionIconAssessmentStatus
) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1961 15.4993C33.1961 14.696 32.5391 14.0448 31.7287 14.0448H3.11484C2.30443 14.0448 1.64746 14.696 1.64746 15.4993L1.64746 64.9518C1.64746 65.7551 2.30443 66.4063 3.11484 66.4063H31.7287C32.5391 66.4063 33.1961 65.7551 33.1961 64.9518L33.1961 40.6106C33.1961 54.8572 43.5433 66.4063 56.3072 66.4063C69.0712 66.4063 79.4184 54.8572 79.4184 40.6106C79.4184 26.364 69.0712 14.8149 56.3072 14.8149C43.5433 14.8149 33.1961 26.364 33.1961 40.6106L33.1961 15.4993Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
