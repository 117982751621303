import {
  acceptFriendRequest,
  sendFriendRequest,
} from "@/components/connections/utils";
import { useAuth } from "@/context/AuthContext";
import { RootState } from "@/redux/store";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { useSelector } from "react-redux";
import styles from "./AuthSearchBar.module.css";
import { useState } from "react";

type Props = {
  person: PublicProfile;
  onSearch: () => void;
};

export default function FriendButton(props: Props) {
  const { user } = useAuth();
  const userState = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);

  if (loading) {
    return <button className={styles.reqButton}>Loading...</button>;
  }

  if (
    user?.uid &&
    userState.friendRequestRecievedUidsList.includes(props.person.ownerUUID)
  ) {
    return (
      <button
        onClick={() => {
          setLoading(true);
          const input = document.getElementById("searchbarDesktop");
          if (input) {
            setTimeout(() => {
              input.focus();
              props.onSearch();
            }, 300);
          }
          userState.friendRequestsRecieved?.forEach((req) => {
            if (req.sender.ownerUUID === props.person.ownerUUID) {
              acceptFriendRequest(req)
                .then((res) => {
                  setLoading(false);
                  console.log(res);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log(err);
                });
            }
          });
        }}
        className={styles.reqButton}
      >
        Accept
      </button>
    );
  } else if (
    user?.uid &&
    userState.friendRequestsSentUidList.includes(props.person.ownerUUID)
  ) {
    return (
      <button
        onClick={() => {
          const input = document.getElementById("searchbarDesktop");
          if (input) {
            setTimeout(() => {
              input.focus();
              props.onSearch();
            }, 300);
          }
        }}
        className={styles.sentButton}
      >
        Pending
      </button>
    );
  } else if (
    user?.uid &&
    !userState.friendUidList.includes(props.person.ownerUUID) &&
    !userState.friendRequestsSentUidList.includes(props.person.ownerUUID) &&
    !userState.friendRequestRecievedUidsList.includes(props.person.ownerUUID)
  ) {
    return (
      <button
        onClick={() => {
          setLoading(true);
          const input = document.getElementById("searchbarDesktop");
          if (input) {
            setTimeout(() => {
              input.focus();
              props.onSearch();
            }, 300);
          }
          if (userState.publicProfileData) {
            sendFriendRequest(
              userState.publicProfileData,
              props.person,
              false,
              false
            )
              .then((res) => {
                console.log(res);
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          }
        }}
        className={styles.reqButton}
      >
        Request
      </button>
    );
  } else return <button style={{ display: "none" }}></button>;
}
