import Link from "next/link";
import { useRouter } from "next/router";

import Element from "@/components/traits/element/Element/Element";
import { traitsMapper } from "@/components/traits/shared/traitsMap";
import { ExtendedScale } from "@/models/sharedModels";
import TraitsHeading from "../../Heading/TraitsHeading";
import TraitsWrapper from "../../Wrapper/Wrapper";
import styles from "./OtherElements.module.css";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";

type Props = {
  elements: ExtendedScale[];
  authenticated?: boolean;
  uid?: string | undefined;
};

export default function OtherElements(props: Props) {
  const router = useRouter();
  const { slug } = router.query;
  const { setModalComponent } = useModalContext();

  if (props.authenticated) {
    return (
      <TraitsWrapper>
        <TraitsHeading
          title={`Other Traits in ${
            traitsMapper[props.elements[0].dimensionSlug]
          }`}
        />
        <div className={styles.wrapper}>
          {props.elements.map((el) => {
            return (
              <div
                onClick={() =>
                  setModalComponent(
                    <ElementModal uid={props.uid} slug={el.slug} />
                  )
                }
                key={el.slug}
                className="elBodyNoMarginMobile"
                style={{ cursor: "pointer" }}
              >
                <Element slug={el.slug} />
              </div>
            );
          })}
        </div>
      </TraitsWrapper>
    );
  }

  return (
    <TraitsWrapper>
      <TraitsHeading
        title={`Other Traits in ${
          traitsMapper[props.elements[0].dimensionSlug]
        }`}
      />
      <div className={styles.wrapper}>
        {props.elements.map((el) => {
          return (
            <Link
              href={`/traits/elements/${el.slug}`}
              key={el.slug}
              className="elBodyNoMarginMobile"
            >
              <Element slug={el.slug} />
            </Link>
          );
        })}
      </div>
    </TraitsWrapper>
  );
}
