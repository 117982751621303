import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Auth,
  UserCredential,
  User,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signOut,
  updatePhoneNumber,
  PhoneAuthCredential,
  signInWithCustomToken,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  ArchetypeDiscovery,
  DimensionEvent,
  DiscoveredStory,
  ElementDiscovery,
  PatternDiscovery,
  DiscoveredPersonalityAnalysis,
  DiscoverablePersonalityAnalysis,
  FriendRequest,
  LlmRequest,
  DiscoveredSnippet,
  ConnectionLlmRequest,
  PublicProfile,
  FeedItemNew,
  LatestResult,
  AppInfo,
  PeerAssessmentEvent,
  AggregatePeerScore,
  ChatRoom,
  DiscoverableCompatibilitySnippet,
  PeriodicQuiz,
  PeriodicQuizResult,
  InsightDelivery,
  StoryDelivery,
  CommentVote,
} from "@dimensional-engineering/dimensional-models";
import { getDownloadURL, ref } from "firebase/storage";

import { app, auth, db, firebaseStorage } from "@/_firebase/firebaseConfig";
import {
  resetState,
  setArchetypeDiscoveries,
  setCloseFriendUidList,
  setCognitiveFinished,
  setDeepFriendUidList,
  setDimensionData,
  setDiscoveredStories,
  setElementDiscoveries,
  setFriendRequestRecievedUidsList,
  setFriendRequestsRecieved,
  setFriendRequestsSentUidList,
  setFriendUidList,
  setImageURL,
  setIsCloseFriendRequestsSentUidList,
  setIsDeepFriendRequestsSentUidList,
  setIsPremium,
  setMemberProfile,
  setNutshellLlmRequests,
  setPatternDiscoveries,
  setPrimaryFinished,
  setValuesFinished,
  setPublicProfileData,
  setInviteLevel,
  setPeerFeedbackLevel,
  setAggregatePeerScore,
  setDiscoverableStories,
  setDimensionSummaries,
  setUserIsInitialized,
} from "@/redux/slices/userSlice";
import {
  setHasLoveReportLlmData,
  setHasCareerReportLlmData,
  setHasTherapistReportLlmData,
  setTherapistReportLlmRequest,
  setLoveReportLlmRequest,
  setCareerReportLlmRequest,
} from "@/redux/slices/reportSlice";
import {
  gerDeliveredDailyStory,
  getAllStories,
  getCommentThread,
  getDefaultProfilePhoto,
  getDeliveredDailyInsight,
  handleConnections,
  handlePeerAssessmentRecommendationSort,
  isExpired,
  whitelistedNotifications,
} from "./utils";
import { Mixpanel } from "@/helpers/mixpanel";
import {
  FriendsPublicProfile,
  PeriodicQuizConnectionScores,
  PrivateMemberProfile,
  RevenueCatSubscription,
} from "@/models/sharedModels";
import {
  resetConnectionState,
  setConnectionPublicProfiles,
} from "@/redux/slices/connectionSlice";
import Alert from "@/components/shared/Alerts/Alert";
import axios from "axios";
import {
  setSnippets,
  setSnippetsAllLlmRequests,
  setSnippetsLatestManualLlmRequest,
} from "@/redux/slices/snippetsSlice";
import {
  setDiscoverablePersonalAnalysis,
  setDiscoveredPersonalAnalysis,
} from "@/redux/slices/personalityAnalysisSlice";
import {
  getFeedBackLevelFromNumOfPeerAssessments,
  getRewardsLevelFromNumOfInvites,
} from "@/components/appHome/utils";
import { useRouter } from "next/router";
import { useAlertContext } from "./AlertContext";
import {
  paginateFeed,
  setFeed,
  updateLastNotification,
} from "@/redux/slices/feedSlice";
import { RootState } from "@/redux/store";
import {
  paginateLatestResult,
  setLatestResults,
  updateLastResultPulled,
} from "@/redux/slices/latestResultsSlice";
import { setAppInfo } from "@/redux/slices/appInfoSlice";
import {
  setCtaRecommendationList,
  setPeerAssessmentEvents,
} from "@/redux/slices/peerAssessmentSlice";
import { useGetFCMTokenHook } from "@/helpers/useGetFCMTokenHook";
import {
  setDailyStory,
  setStoryDelivery,
} from "@/redux/slices/dailyStorySlice";
import { DeliveredDailyStory } from "@dimensional-engineering/dimensional-models/lib/models/stories/DeliveredDailyStory";
import useScreenshotHandler from "@/helpers/useScreenshotHandler";
import { setChatRooms } from "@/redux/slices/messagingSlice";
import { setDiscoverableCompatibilitySnippets } from "@/redux/slices/compatibilitySnippetsSlice";
import {
  setAvailableQuiz,
  setPeriodicQuizConnectionScores,
  setPeriodicQuizResults,
} from "@/redux/slices/periodicQuizSlice";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import {
  setCommentThread,
  setDeliveredDailyInsight,
  setInsightDelivery,
} from "@/redux/slices/dailyInsightsSlice";

const appleProvider = new OAuthProvider("apple.com");
const googleProvider = new GoogleAuthProvider();

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface AuthContextModel {
  auth: Auth;
  user: User | null | undefined;
  signInWithApple: () => Promise<UserCredential>;
  signInWithGoogle: () => Promise<UserCredential>;
  signOutOfAccount: () => Promise<unknown>;
  signInUsingCustomToken: (token: string) => Promise<UserCredential>;
  getUser: () => User | null | undefined;
  updateUserPhoneNumber: (
    phoneCredential: PhoneAuthCredential
  ) => Promise<void> | undefined;
  paginateFeedItems: () => void;
  paginateLatestResults: () => void;
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
);

///provider

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  useGetFCMTokenHook();
  useScreenshotHandler();

  const userState = useSelector((state: RootState) => state.user);
  const quizState = useSelector((state: RootState) => state.periodicQuiz);
  const dailyStoryState = useSelector((state: RootState) => state.dailyStory);
  const dailyInsightState = useSelector(
    (state: RootState) => state.dailyInsight
  );

  const lastNotification = useSelector(
    (state: RootState) => state.feed.lastNotification
  );
  const lastResultPulled = useSelector(
    (state: RootState) => state.latestResults.lastResultPulled
  );

  const peerAssessmentState = useSelector(
    (state: RootState) => state.peerAssessment
  );

  const [user, setUser] = useState<User | null | undefined>();
  const dispatch = useDispatch();
  const router = useRouter();
  const { setAlertComponent, removeAlert } = useAlertContext();

  function signInWithGoogle(): Promise<UserCredential> {
    return signInWithPopup(auth, googleProvider);
  }

  function signInWithApple(): Promise<UserCredential> {
    return signInWithPopup(auth, appleProvider);
  }

  function signInUsingCustomToken(token: string) {
    return signInWithCustomToken(auth, token);
  }

  function signOutOfAccount() {
    dispatch(resetState());
    dispatch(resetConnectionState());

    return signOut(auth);
  }

  function getUser() {
    return user;
  }

  function updateUserPhoneNumber(phoneCredential: PhoneAuthCredential) {
    if (user) return updatePhoneNumber(user, phoneCredential);
  }

  useEffect(() => {
    ///make last signin update on refrsh token change
    const unsubsrcibe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user != null) {
        if (window && window.Android) {
          localStorage.setItem("uid", user.uid);
        }
      }
    });
    return () => {
      unsubsrcibe;
    };
  }, []);

  useEffect(() => {
    if (
      userState.publicProfileData?.ownerUUID &&
      dailyStoryState.storyDelivery?.id &&
      quizState.availableQuiz?.slug &&
      dailyInsightState.insightDelivery?.id
    ) {
      dispatch(setUserIsInitialized(true));
    }
  }, [
    userState.publicProfileData?.ownerUUID,
    dailyStoryState.storyDelivery,
    quizState.availableQuiz,
    dailyInsightState.insightDelivery,
  ]);

  useEffect(() => {
    if (userState.isUserInitialized && window && window.Android) {
      console.log("Sent onUserReady");
      window.Android.onUserReady();
    }
  }, [userState.isUserInitialized]);

  useEffect(() => {
    const unsubscribeAppInfo = onSnapshot(
      doc(db, "appInfo/dimensionalIOS"),
      (doc) => {
        if (doc.exists()) {
          const appInfo = doc.data() as AppInfo;
          dispatch(setAppInfo(appInfo));
        }
      },
      (err) => {
        console.log(err);
      }
    );

    const unsubscribeMemberProfile = onSnapshot(
      doc(db, "members", `${user?.uid}`),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data() as PrivateMemberProfile;
          dispatch(setMemberProfile(data));

          const userRewardLevel = getRewardsLevelFromNumOfInvites(
            data?.recommendeeUids?.length
          );
          dispatch(setInviteLevel(userRewardLevel));

          const userPeerFeedbackLevel =
            getFeedBackLevelFromNumOfPeerAssessments(data.peerAssesseeUids);
          dispatch(setPeerFeedbackLevel(userPeerFeedbackLevel));

          Mixpanel?.people.update(
            {
              "successful invites": data?.recommendeeUids?.length,
            },
            user?.uid
          );
          Mixpanel?.people.update(
            {
              "peer assessments received": data.peerAssesseeUids?.length,
            },
            user?.uid
          );
        }
      }
    );

    const unsubscribePublicProfile = onSnapshot(
      doc(db, "publicProfiles", `${user?.uid}`),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data() as PublicProfile;
          dispatch(setPublicProfileData(data));
        }
      }
    );

    const dimensionQuery = query(
      collection(db, "members", `${user?.uid}`, "dimensionEvents")
    );

    const unsubscribeDimensionEvents = onSnapshot(
      dimensionQuery,
      (querySnapshot) => {
        const initiated: DimensionEvent[] = [];
        let finished: string[] = [];
        querySnapshot.forEach((doc) => {
          let dimEvent = doc.data() as DimensionEvent;
          if (dimEvent.event === "complete") {
            finished.push(dimEvent.dimensionSlug);
          }
          initiated.push(dimEvent);
        });
        Mixpanel?.people.update(
          { "Dimensions Completed": finished },
          user?.uid
        );
        initiated.forEach((dimensionEvent) => {
          if (dimensionEvent.dimensionSlug === "personality") {
            if (dimensionEvent.event === "complete") {
              dispatch(setPrimaryFinished(true));
            } else {
              dispatch(setPrimaryFinished(false));
            }
          } else if (dimensionEvent.dimensionSlug === "personality-II") {
            if (dimensionEvent.event === "complete") {
              dispatch(setCognitiveFinished(true));
            } else {
              dispatch(setCognitiveFinished(false));
            }
          } else if (dimensionEvent.dimensionSlug === "values") {
            if (dimensionEvent.event === "complete") {
              dispatch(setValuesFinished(true));
            } else {
              dispatch(setValuesFinished(false));
            }
          }
        });
        dispatch(setDimensionData(initiated));
      },
      (err) => {
        console.log("Error on dimension events listener: ", err);
      }
    );

    ///scales
    //members/<uid>/elementDiscoveries
    const scalesQuery = query(
      collection(db, "members", `${user?.uid}`, "elementDiscoveries"),
      where("scaleScore", "!=", null)
    );
    const unsubscribeScales = onSnapshot(
      scalesQuery,
      (querySnapshot) => {
        const elements: ElementDiscovery[] = [];
        querySnapshot.forEach((doc) => {
          elements.push(doc.data() as ElementDiscovery);
        });
        dispatch(setElementDiscoveries(elements));
        // querySnapshot.docChanges().forEach((doc) => {
        //   if (mod10(user?.uid)) {
        //     Mixpanel?.track("Element Discovered", {});
        //   }
        // });
      },
      (err) => {
        console.log("Error on Element Discovery: ", err);
      }
    );

    ///patterns
    //members/<uid>/patternDiscoveries
    const patternsQuery = query(
      collection(db, "members", `${user?.uid}`, "patternDiscoveries"),
      where("discoveryStatus", "==", "discovered")
    );

    const unsubscribePatterns = onSnapshot(
      patternsQuery,
      (querySnapshot) => {
        const patterns: PatternDiscovery[] = [];
        querySnapshot.forEach((doc) => {
          let pattern = doc.data() as PatternDiscovery;
          patterns.push(pattern);
        });
        dispatch(setPatternDiscoveries(patterns));
        // querySnapshot.docChanges().forEach((doc) => {
        //   if (mod10(user?.uid)) {
        //     Mixpanel?.track("Pattern Discovered", {});
        //   }
        // });
      },
      (err) => {
        console.log("Error on Pattern Discovery: ", err);
      }
    );

    ///archetypes
    //members/<uid>/archetypeDiscoveries

    const archetypesQuery = query(
      collection(db, "members", `${user?.uid}`, "archetypeDiscoveries")
    );

    const unsubscribeArchetypes = onSnapshot(
      archetypesQuery,
      (querySnapshot) => {
        let archetypes: ArchetypeDiscovery[] = [];
        querySnapshot.forEach((doc) => {
          archetypes.push(doc.data() as ArchetypeDiscovery);
        });
        dispatch(setArchetypeDiscoveries(archetypes));
        // querySnapshot.docChanges().forEach((doc) => {
        //   Mixpanel?.track("Archetype Discovered", {});
        // });
      },
      (err) => {
        console.log("Error on Archetype Discovery: ", err);
      }
    );

    const discoveredDoc = query(
      collection(db, "members", `${user?.uid}`, "discoveredStories"),
      where("storyType", "==", "listicle")
    );
    const unsubscribeDiscoveredStories = onSnapshot(
      discoveredDoc,
      (querySnapshot) => {
        let stories: DiscoveredStory[] = [];
        querySnapshot.forEach((doc) => {
          stories.push(doc.data() as DiscoveredStory);
        });
        dispatch(setDiscoveredStories(stories));
      },
      (err) => {
        console.log("Error On Discovered Stories Listener: ", err);
      }
    );

    const discoverableAnalysisQuery = query(
      collection(db, "discoverablePersonalityAnalyses")
    );

    const unsubscribeDiscoverableAnalysis = onSnapshot(
      discoverableAnalysisQuery,
      (querySnapshot) => {
        let analysises: DiscoverablePersonalityAnalysis[] = [];
        querySnapshot.forEach((doc) => {
          analysises.push(doc.data() as DiscoverablePersonalityAnalysis);
        });
        dispatch(setDiscoverablePersonalAnalysis(analysises));
      },
      (err) => {
        console.log(
          "Error On Discoverable Personality Analysis Listener: ",
          err
        );
      }
    );

    const discoverableCompatibilitySnippetsDoc = collection(
      db,
      "discoverableCompatibilitySnippets"
    );

    const unsubscribeDiscoverableCompatiblitySNippets = onSnapshot(
      discoverableCompatibilitySnippetsDoc,
      (querySnapshot) => {
        let array: DiscoverableCompatibilitySnippet[] = [];
        querySnapshot.forEach((doc) => {
          array.push(doc.data() as DiscoverableCompatibilitySnippet);
        });
        if (array.length) {
          dispatch(setDiscoverableCompatibilitySnippets(array));
        }
      }
    );

    const DiscoveredPersonalityAnalysisDoc = query(
      collection(db, "members", `${user?.uid}`, "discoveredPersonalityAnalyses")
    );

    const unsubscribeDiscoveredPersonalAnalysis = onSnapshot(
      DiscoveredPersonalityAnalysisDoc,
      (querySnapshot) => {
        let analysises: DiscoveredPersonalityAnalysis[] = [];
        querySnapshot.forEach((doc) => {
          analysises.push(doc.data() as DiscoveredPersonalityAnalysis);
        });
        dispatch(setDiscoveredPersonalAnalysis(analysises));
      },
      (err) => {
        console.log("Error On Discovered Personality Analysis Listener: ", err);
      }
    );

    function getIsPremium() {
      axios
        .get(`https://api.revenuecat.com/v1/subscribers/${user?.uid}`, {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_REVENUE_CAT}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const data = res.data as RevenueCatSubscription;
          if (data.subscriber.entitlements.premium) {
            const expired = isExpired(
              data.subscriber.entitlements.premium.expires_date
            );
            if (!expired) {
              dispatch(setIsPremium(true));
            } else {
              dispatch(setIsPremium(false));
            }
          } else {
            dispatch(setIsPremium(false));
          }
        })
        .catch((err) => {
          console.log("error from revenue cat", err);
        });
    }
    ///have to use conditional for query due to firebase error on dot notation

    let recievedfreindRequestDoc;

    if (user?.uid !== undefined) {
      recievedfreindRequestDoc = query(
        collection(db, "friendRequests"),
        where("receiver.ownerUUID", "==", user?.uid),
        where("status", "==", "sent")
      );
    } else {
      recievedfreindRequestDoc = query(
        collection(db, "friendRequests"),
        where("status", "==", "sent")
      );
    }

    const unsubscribeRecievedFriendRequestDoc = onSnapshot(
      recievedfreindRequestDoc,
      (querySnapshot) => {
        let requests: FriendRequest[] = [];
        let requestUids: string[] = [];
        querySnapshot.forEach((doc) => {
          requests.push(doc.data() as FriendRequest);
          requestUids.push(doc.data().sender.ownerUUID);
        });
        dispatch(setFriendRequestsRecieved(requests));
        dispatch(setFriendRequestRecievedUidsList(requestUids));
      },
      (err) => {
        console.log("Error On Friend Request Listener: ", err);
      }
    );

    let sentFriendRequestDoc;

    if (user?.uid !== undefined) {
      sentFriendRequestDoc = query(
        collection(db, "friendRequests"),
        where("sender.ownerUUID", "==", user?.uid),
        where("status", "==", "sent")
      );
    } else {
      sentFriendRequestDoc = query(
        collection(db, "friendRequests"),
        where("status", "==", "sent")
      );
    }

    const unsubscribeSentFriendRequestDoc = onSnapshot(
      sentFriendRequestDoc,
      (querySnapshot) => {
        let requests: string[] = [];
        let isCloseList: string[] = [];
        let isDeepList: string[] = [];

        querySnapshot.forEach((doc) => {
          const uid = doc.data().receiver.ownerUUID;
          requests.push(uid);
          if (doc.data().isClose === true && !doc.data().isDeep) {
            isCloseList.push(doc.data().receiver.ownerUUID);
          }
          if (doc.data().isDeep === true) {
            isDeepList.push(doc.data().receiver.ownerUUID);
          }
        });
        dispatch(setFriendRequestsSentUidList(requests));
        dispatch(setIsCloseFriendRequestsSentUidList(isCloseList));
        dispatch(setIsDeepFriendRequestsSentUidList(isDeepList));
      },
      (err) => {
        console.log("Error On Sent Frtiend Request Listener: ", err);
      }
    );

    /////

    const accessMapRef = doc(
      db,
      "members",
      `${user?.uid}`,
      "accessMaps",
      "friends"
    );

    const unsubscribeAccessMapp = onSnapshot(
      accessMapRef,
      (querySnapshot) => {
        if (querySnapshot.exists()) {
          let closeList: string[] = [];
          let deepList: string[] = [];
          for (const key in querySnapshot.data().accessMap) {
            if (querySnapshot.data().accessMap.hasOwnProperty(key)) {
              const item = querySnapshot.data().accessMap[key];
              if (item.isClose === true) {
                closeList.push(key);
              }
              if (item.isDeep === true) {
                deepList.push(key);
              }
            }
          }
          dispatch(
            setFriendUidList(
              Object.keys(querySnapshot.data().accessMap as string[])
            )
          );
          dispatch(setCloseFriendUidList(closeList));
          dispatch(setDeepFriendUidList(deepList));
          if (user?.uid) {
            handleConnections(user?.uid).then((res) => {
              const friendsList = res as FriendsPublicProfile[];
              dispatch(setConnectionPublicProfiles(friendsList));
              Mixpanel?.people.update({ friendCount: friendsList.length });
            });
          }
        }
      },
      (err) => {
        console.log("Error On Access Map Listener: ", err);
      }
    );

    const nutshellRef = query(
      collection(db, `llmRequests/${user?.uid}/nutshell`)
    );

    const unsubscribeNutshellRequests = onSnapshot(
      nutshellRef,
      (querySnapshot) => {
        let allRequests: LlmRequest[] = [];
        querySnapshot.forEach((doc) => {
          allRequests.push(doc.data() as LlmRequest);
        });
        dispatch(setNutshellLlmRequests(allRequests));
      },
      (err) => {
        console.log("Error On Nutshell Listener: ", err);
      }
    );

    const snippetsAllLlmRef = query(
      collection(db, `llmRequests/${user?.uid}/snippets`),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    const snippetsManualLlmRef = query(
      collection(db, `llmRequests/${user?.uid}/snippets`),
      orderBy("createdOn", "desc"),
      where("trigger", "==", "manualRetry"),
      limit(1)
    );

    const unsubscribeSnippetsAllRequests = onSnapshot(
      snippetsAllLlmRef,
      (querySnapshot) => {
        let allRequests: LlmRequest[] = [];
        querySnapshot.forEach((doc) => {
          allRequests.push(doc.data() as LlmRequest);
        });
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            let changedDoc = change.doc.data() as LlmRequest;
            if (changedDoc.status === "complete") {
              if (changedDoc.contentIdentifier?.contentSlug) {
                setAlertComponent(
                  <Alert
                    elementName="Alert"
                    type="success"
                    message="Snippets generated. See them on your Results tab."
                    buttonText="View"
                    onButtonClick={() => {
                      if (changedDoc.contentIdentifier?.contentSlug) {
                        localStorage.setItem(
                          "snippetSlug",
                          changedDoc.contentIdentifier?.contentSlug
                        );
                      }
                      removeAlert();
                      router.push("/app/results");
                    }}
                  />,
                  20000
                );
              } else {
                setAlertComponent(
                  <Alert
                    elementName="Alert"
                    type="success"
                    message="Snippets generated. See them on your Results tab."
                  />,
                  5000
                );
              }
            }
          }
        });
        dispatch(setSnippetsAllLlmRequests(allRequests));
      },
      (err) => {
        console.log("Error On Snippets Listener: ", err);
      }
    );

    const unsubscribeLatestSnippetsManualLlmRequest = onSnapshot(
      snippetsManualLlmRef,
      (querySnapshot) => {
        let allRequests: LlmRequest[] = [];
        querySnapshot.forEach((doc) => {
          allRequests.push(doc.data() as LlmRequest);
        });
        dispatch(setSnippetsLatestManualLlmRequest(allRequests[0]));
      },
      (err) => {
        console.log("Error On Manual LLM Requests Listener: ", err);
      }
    );

    const snippetsRef = collection(
      db,
      "members",
      `${user?.uid}`,
      "discoveredSnippets"
    );

    const unsubscribeSnippets = onSnapshot(
      snippetsRef,
      (querySnapshot) => {
        let snippets: DiscoveredSnippet[] = [];
        querySnapshot.forEach((doc) => {
          snippets.push(doc.data() as DiscoveredSnippet);
        });
        snippets = snippets.filter((s) => {
          if (s.discoveryGate.numPeerAssessorsRequired) {
            if (userState.memberProfile?.peerAssesseeUids) {
              if (
                userState.memberProfile?.peerAssesseeUids.length <
                s.discoveryGate.numPeerAssessorsRequired
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return false;
            }
          } else {
            return true;
          }
        });
        dispatch(setSnippets(snippets));
      },
      (err) => {
        console.log("Error On Discovered Snippets Listener: ", err);
      }
    );

    const latestCompatibilitySnippetLlmRequestRef = query(
      collection(db, `connectionLlmRequests/${user?.uid}/compatibilitySnippet`),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    const unsubscribeLatestCompatibilitySnippetLlmRequest = onSnapshot(
      latestCompatibilitySnippetLlmRequestRef,
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "modified") {
            let changedDoc = change.doc.data() as ConnectionLlmRequest;
            if (changedDoc.status === "complete") {
              setAlertComponent(
                <Alert
                  elementName="Alert"
                  type="success"
                  message="Compatibility snippets computed. See them on your connection's Compare tab."
                  buttonText="View"
                  onButtonClick={() => {
                    localStorage.setItem("currentTab", "Compare");
                    localStorage.setItem(
                      "compatibilitySnippetSlug",
                      changedDoc.contentIdentifier.contentSlug
                    );
                    removeAlert();
                    //setting the userid for tabs
                    if (changedDoc.requesteeUid === user?.uid) {
                      localStorage.setItem(
                        "currentTabUid",
                        changedDoc.requesterUid
                      );
                      router.push(`/app/profile/${changedDoc.requesterUid}`);
                    } else {
                      localStorage.setItem(
                        "currentTabUid",
                        changedDoc.requesteeUid
                      );
                      router.push(`/app/profile/${changedDoc.requesteeUid}`);
                    }
                  }}
                />,
                20000
              );
            }
          }
        });
      },
      (err) => {
        console.log(
          "Error On Latest Multiplayer Compat LLM Snippet Request Listener: ",
          err
        );
      }
    );

    const loveReportRef = query(
      collection(db, `members/${user?.uid}/loveReportLlmData`),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    const unsubscribeLoveReportLlmData = onSnapshot(
      loveReportRef,
      (querySnapShot) => {
        const hasLoveReport = !querySnapShot?.empty;
        dispatch(setHasLoveReportLlmData(hasLoveReport));
      },
      (err) => {
        console.log("Error On Love Report Listener: ", err);
      }
    );

    const loveReportLlmRequest = query(
      collection(db, `llmRequests/${user?.uid}/loveReport`),
      where("trigger", "==", "manualRetry"),
      where("status", "==", "processing"),
      limit(1)
    );

    const unsubscribeLoveReportLlmRequest = onSnapshot(
      loveReportLlmRequest,
      (querySnapShot) => {
        let allRequests: LlmRequest[] = [];
        querySnapShot.forEach((doc) => {
          allRequests.push(doc.data() as LlmRequest);
        });
        dispatch(setLoveReportLlmRequest(allRequests[0]));
      },
      (err) => {
        console.log("Error On Lover Report Request Listener: ", err);
      }
    );

    const careerReportRef = query(
      collection(db, `members/${user?.uid}/careerReportLlmData`),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    const unsubscribeCareerReportLlmData = onSnapshot(
      careerReportRef,
      (querySnapShot) => {
        const hasCareerReport = !querySnapShot?.empty;
        dispatch(setHasCareerReportLlmData(hasCareerReport));
      },
      (err) => {
        console.log("Error On Career Report Listener: ", err);
      }
    );

    const careerReportLlmRequest = query(
      collection(db, `llmRequests/${user?.uid}/careerReport`),
      where("trigger", "==", "manualRetry"),
      where("status", "==", "processing"),
      limit(1)
    );

    const unsubscribeCareerReportLlmRequest = onSnapshot(
      careerReportLlmRequest,
      (querySnapShot) => {
        let allRequests: LlmRequest[] = [];
        querySnapShot.forEach((doc) => {
          allRequests.push(doc.data() as LlmRequest);
        });
        dispatch(setCareerReportLlmRequest(allRequests[0]));
      },
      (err) => {
        console.log("Error On Career Report Request Listener: ", err);
      }
    );

    const therapistReportRef = query(
      collection(db, `members/${user?.uid}/therapistReportLlmData`),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    const unsubscribeTherapistReportLlmData = onSnapshot(
      therapistReportRef,
      (querySnapShot) => {
        const hasTherapistReport = !querySnapShot?.empty;
        dispatch(setHasTherapistReportLlmData(hasTherapistReport));
      },
      (err) => {
        console.log("Error On Therapist Report Listener: ", err);
      }
    );

    const therapistReportLlmRequest = query(
      collection(db, `llmRequests/${user?.uid}/therapistReport`),
      where("trigger", "==", "manualRetry"),
      where("status", "==", "processing"),
      limit(1)
    );

    const unsubscribeTherapistReportLlmRequest = onSnapshot(
      therapistReportLlmRequest,
      (querySnapShot) => {
        let allRequests: LlmRequest[] = [];
        querySnapShot.forEach((doc) => {
          allRequests.push(doc.data() as LlmRequest);
        });
        dispatch(setTherapistReportLlmRequest(allRequests[0]));
      },
      (err) => {
        console.log("Error On Therapist Report Request Listener: ", err);
      }
    );

    const feedQueryFirst = query(
      collection(db, `members/${user?.uid}/feed`),
      where("feedEvent", "in", whitelistedNotifications),
      orderBy("createdOn", "desc"),
      limit(10)
    );

    const unsubscribeInitialFeed = onSnapshot(
      feedQueryFirst,
      (querySnapShot) => {
        let allFeedItems: FeedItemNew[] = [];
        querySnapShot.forEach((doc) => {
          allFeedItems.push(doc.data() as FeedItemNew);
        });
        dispatch(
          updateLastNotification(
            querySnapShot.docs[querySnapShot.docs.length - 1]
          )
        );
        dispatch(setFeed(allFeedItems));
      },
      (err) => {
        console.log("Error On Feed Listener: ", err);
      }
    );

    const latestResultsInitialQuery = query(
      collection(db, `members/${user?.uid}/latestResults`),
      orderBy("createdOn", "desc"),
      limit(20)
    );

    const unsubscribeLatestResults = onSnapshot(
      latestResultsInitialQuery,
      (querySnapShot) => {
        let allResults: LatestResult[] = [];
        querySnapShot.forEach((doc) => {
          allResults.push(doc.data() as LatestResult);
        });
        dispatch(
          updateLastResultPulled(
            querySnapShot.docs[querySnapShot.docs.length - 1]
          )
        );
        dispatch(setLatestResults(allResults));
      },
      (err) => {
        console.log("Error On Latest Results Listener: ", err);
      }
    );

    const peerAssessmentEventsRef = collection(
      db,
      "members",
      `${user?.uid}`,
      "peerAssessmentEvents"
    );

    const unsubscribePeerAssessments = onSnapshot(
      peerAssessmentEventsRef,
      (querySnapShot) => {
        let allAssessments: PeerAssessmentEvent[] = [];
        querySnapShot.forEach((doc) => {
          allAssessments.push(doc.data() as PeerAssessmentEvent);
        });
        dispatch(setPeerAssessmentEvents(allAssessments));
      },
      (err) => {
        console.log("Error On Peer Assessments Listener: ", err);
      }
    );

    const dailyStoryRef = query(
      collection(db, "/storyDeliveries"),
      orderBy("deliveryDate", "desc"),
      limit(1)
    );

    const unsubscribeDailyStory = onSnapshot(
      dailyStoryRef,
      (querySnapshot) => {
        querySnapshot.forEach(async (result) => {
          const data = result.data() as StoryDelivery;
          dispatch(setStoryDelivery(data));
          await gerDeliveredDailyStory(user?.uid, data.id).then((res) => {
            dispatch(setDailyStory(res));
          });
        });
      },
      (err) => {
        console.log("Error on daily stories listener: ", err);
      }
    );

    const aggregatePeerScoreRef = collection(
      db,
      "members",
      `${user?.uid}`,
      "aggregatePeerScores"
    );

    const unsubscribeAggregatePeerScore = onSnapshot(
      aggregatePeerScoreRef,
      (querySnapshot) => {
        const allPeerScores: AggregatePeerScore[] = [];
        querySnapshot.forEach((doc) => {
          allPeerScores.push(doc.data() as AggregatePeerScore);
        });
        dispatch(setAggregatePeerScore(allPeerScores));
      },
      (err) => {
        console.log("Peer score listener error: ", err);
      }
    );

    const messagingQuery = query(
      collection(db, "chatRooms"),
      where("participantUids", "array-contains", user?.uid ?? "null")
    );

    const unsubscribeMessaging = onSnapshot(messagingQuery, (querySnapshot) => {
      const chatRooms: ChatRoom[] = [];
      querySnapshot.forEach((doc) => {
        chatRooms.push(doc.data() as ChatRoom);
      });
      querySnapshot.docChanges().forEach((doc) => {
        if (doc.type === "modified") {
          const data = doc.doc.data() as ChatRoom;
          if (user?.uid && !data.latestMessage?.readBy?.includes(user.uid)) {
            if (window.Android) {
              window.Android.onHaptic();
            }
            setAlertComponent(
              <Alert
                message={`New message received`}
                type="chat"
                elementName="Alert"
              />,
              3000
            );
          }
        }
      });
      dispatch(setChatRooms(chatRooms));
    });

    const availableQuizRef = query(
      collection(db, "/periodicQuizzes"),
      orderBy("deliveredOn", "desc"),
      limit(1)
    );

    const unsubscribeAvailableQuiz = onSnapshot(
      availableQuizRef,
      (querySnapshot) => {
        querySnapshot.forEach((quiz) => {
          const data = quiz.data() as PeriodicQuiz;
          dispatch(setAvailableQuiz(data));
        });
      }
    );

    const quizResults = collection(
      db,
      `/members/${user?.uid}/periodicQuizResults`
    );

    const unsubscribePeriodicQuizResults = onSnapshot(
      quizResults,
      (querySnapshot) => {
        let results: PeriodicQuizResult[] = [];
        querySnapshot.forEach((result) => {
          results.push(result.data() as PeriodicQuizResult);
        });
        dispatch(setPeriodicQuizResults(results));
      }
    );

    const insightDeliveryRef = query(
      collection(db, "/insightDeliveries"),
      orderBy("deliveryDate", "desc"),
      limit(1)
    );

    const unsubscribeInsightDelivery = onSnapshot(
      insightDeliveryRef,
      (querySnapshot) => {
        querySnapshot.forEach(async (result) => {
          const data = result.data() as InsightDelivery;
          dispatch(setInsightDelivery(data));
          await getDeliveredDailyInsight(user?.uid, data.id).then(
            async (res) => {
              dispatch(setDeliveredDailyInsight(res));
              if (res?.assignmentRuleset.commentThreadId) {
                await getCommentThread(
                  res.assignmentRuleset.commentThreadId
                ).then((commentResult) => {
                  if (commentResult) {
                    dispatch(setCommentThread(commentResult));
                  }
                });
              }
            }
          );
        });
      },
      (err) => {
        console.log("Error on daily insights listener: ", err);
      }
    );

    function updateSigninDate() {
      if (user?.uid) {
        const ref = doc(db, "members", user.uid);
        updateDoc(ref, { lastSignInDate: Date.now() }).then((res) => {});
      }
    }

    function updateIsAndroid() {
      if (window.Android && user?.uid) {
        const ref = doc(db, "publicProfiles", user.uid);
        updateDoc(ref, { isAndroid: true }).then((res) => {});
      }
    }

    function getPeriodicQuizResultsFromConnections(
      quizSlug: string | undefined
    ) {
      const call = callFirebaseFunctions("fetchConnectionPeriodicQuizScores");
      call({ periodicQuizSlug: quizSlug }).then((res) => {
        const data: PeriodicQuizConnectionScores =
          res.data as PeriodicQuizConnectionScores;
        dispatch(setPeriodicQuizConnectionScores(data));
      });
    }

    function updateDiemnsionSummaries() {
      const fetchSummaries = callFirebaseFunctions("fetchDimensionSummaries");
      fetchSummaries({ uid: user?.uid })
        .then((res) => {
          dispatch(
            setDimensionSummaries(
              //@ts-ignore
              res.data.dimensionSummaries as DimensionSummary[]
            )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (user?.uid) {
      ////do all stale populating here
      getIsPremium();
      updateSigninDate();
      updateIsAndroid();
      updateDiemnsionSummaries();
      handleConnections(user.uid).then((res) => {
        dispatch(setConnectionPublicProfiles(res as FriendsPublicProfile[]));
      });
      getAllStories().then((res) => {
        dispatch(setDiscoverableStories(res));
      });
      getDownloadURL(
        ref(
          firebaseStorage,
          `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${user.uid}/cropped.jpg`
        )
      )
        .then((url) => {
          dispatch(setImageURL(url));
        })
        .catch((err) => {
          console.log(err);
          getDefaultProfilePhoto(user.uid).then((value) => {
            dispatch(setImageURL(value));
          });
        });
      getPeriodicQuizResultsFromConnections(quizState.availableQuiz?.slug);
    }

    return () => {
      if (user && user.uid) {
        unsubscribeAppInfo;
        unsubscribeMemberProfile;
        unsubscribePublicProfile;
        unsubscribeDimensionEvents;
        unsubscribeScales;
        unsubscribePatterns;
        unsubscribeArchetypes;
        unsubscribeDiscoveredStories;
        unsubscribeDiscoverableAnalysis;
        unsubscribeDiscoverableCompatiblitySNippets;
        unsubscribeDiscoveredPersonalAnalysis;
        unsubscribeRecievedFriendRequestDoc;
        unsubscribeSentFriendRequestDoc;
        unsubscribeAccessMapp;
        unsubscribeNutshellRequests;
        unsubscribeSnippets;
        unsubscribeSnippetsAllRequests;
        unsubscribeLatestSnippetsManualLlmRequest;
        unsubscribeLatestCompatibilitySnippetLlmRequest;
        unsubscribeLoveReportLlmData;
        unsubscribeLoveReportLlmRequest;
        unsubscribeCareerReportLlmData;
        unsubscribeCareerReportLlmRequest;
        unsubscribeTherapistReportLlmRequest;
        unsubscribeTherapistReportLlmData;
        unsubscribeInitialFeed;
        unsubscribeLatestResults;
        unsubscribePeerAssessments;
        unsubscribeDailyStory;
        unsubscribeAggregatePeerScore;
        unsubscribeMessaging;
        unsubscribeAvailableQuiz;
        unsubscribePeriodicQuizResults;
        unsubscribeInsightDelivery;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  useEffect(() => {
    const friendUidList = userState.friendUidList;
    const closeFriendUidList = userState.closeFriendUidList;
    const deepFriendUidList = userState.deepFriendUidList;
    const peerAssessmentEvents = peerAssessmentState.events;

    const sortedRecommendationList = handlePeerAssessmentRecommendationSort(
      friendUidList,
      closeFriendUidList,
      deepFriendUidList,
      peerAssessmentEvents
    );

    dispatch(setCtaRecommendationList(sortedRecommendationList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    peerAssessmentState.events,
    userState.closeFriendUidList,
    userState.deepFriendUidList,
    userState.friendUidList,
  ]);

  async function paginateFeedItems() {
    const feedQueryPagination = query(
      collection(db, `members/${user?.uid}/feed`),
      where("feedEvent", "in", whitelistedNotifications),
      orderBy("createdOn", "desc"),
      startAfter(lastNotification ?? null),
      limit(10)
    );

    const querySnapShot = await getDocs(feedQueryPagination);
    let allFeedItems: FeedItemNew[] = [];
    querySnapShot.forEach((doc) => {
      allFeedItems.push(doc.data() as FeedItemNew);
    });
    dispatch(
      updateLastNotification(querySnapShot.docs[querySnapShot.docs.length - 1])
    );
    allFeedItems.forEach((f) => {
      dispatch(paginateFeed(f));
    });
  }

  async function paginateLatestResults() {
    const latestResultPaginateQuery = query(
      collection(db, `members/${user?.uid}/latestResults`),
      orderBy("createdOn", "desc"),
      startAfter(lastResultPulled ?? null),
      limit(20)
    );

    const querySnapShot = await getDocs(latestResultPaginateQuery);

    let allResults: LatestResult[] = [];
    querySnapShot.forEach((doc) => {
      allResults.push(doc.data() as LatestResult);
    });

    dispatch(
      updateLastResultPulled(querySnapShot.docs[querySnapShot.docs.length - 1])
    );
    allResults.forEach((r) => {
      dispatch(paginateLatestResult(r));
    });
  }

  const values = {
    user,
    auth,
    getUser,
    signInWithApple,
    signInWithGoogle,
    signOutOfAccount,
    signInUsingCustomToken,
    updateUserPhoneNumber,
    paginateFeedItems,
    paginateLatestResults,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

//hooks

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}
