import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { CommentVoteSimplified, CommentWithReply } from "@/models/sharedModels";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

type CommentsSlice = {
  commentThreadId: string | null | undefined;
  comments: CommentWithReply[];
  commentVotes: CommentVoteSimplified[];
  lastComment: QueryDocumentSnapshot<DocumentData> | null;
  loading: boolean;
};

const initialState: CommentsSlice = {
  commentThreadId: null,
  comments: [],
  commentVotes: [],
  lastComment: null,
  loading: true,
};

export const comments = createSlice({
  name: "comments",
  initialState: initialState,
  reducers: {
    setCommentThreadId: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      return { ...state, commentThreadId: action.payload };
    },
    setComments: (state, action: PayloadAction<CommentWithReply[]>) => {
      return { ...state, comments: action.payload };
    },
    setCommentVotes: (
      state,
      action: PayloadAction<CommentVoteSimplified[]>
    ) => {
      return { ...state, commentVotes: action.payload };
    },
    setCommentsLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setLastComment: (
      state,
      action: PayloadAction<QueryDocumentSnapshot<DocumentData> | null>
    ) => {
      return { ...state, lastComment: action.payload };
    },
    resetCommentState: (state) => {
      return initialState;
    },
  },
});

export const {
  setCommentThreadId,
  setComments,
  setCommentsLoading,
  resetCommentState,
  setLastComment,
  setCommentVotes,
} = comments.actions;

export default comments.reducer;
