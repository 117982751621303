export default function PeerAssessmentIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82092 9.24592C4.5473 8.90118 4.13265 8.6999 3.69272 8.69629H0.992719C0.809504 8.69629 0.643169 8.79995 0.561212 8.96267C0.479249 9.1266 0.497329 9.32188 0.607017 9.46771L3.76987 13.6914C3.90969 13.8698 3.90969 14.1205 3.76987 14.299L0.607017 18.5322C0.497329 18.6781 0.479249 18.8734 0.561212 19.0373C0.643178 19.2 0.809517 19.3037 0.992719 19.3037H3.69272C4.13268 19.3001 4.54731 19.0988 4.82092 18.754L7.897 14.8969C8.32249 14.3678 8.32249 13.6132 7.897 13.084L4.82092 9.24592Z"
        fill="#FFFFF2"
      />
      <path
        d="M14.9076 20.1033C14.3809 19.6706 13.6215 19.6706 13.0948 20.1033L9.23762 23.1794C8.89288 23.453 8.6916 23.8677 8.68799 24.3076V27.0076C8.68799 27.1908 8.79165 27.3572 8.95437 27.4391C9.1183 27.5211 9.31358 27.503 9.45941 27.3933L13.6831 24.2305C13.8615 24.0907 14.1122 24.0907 14.2907 24.2305L18.5144 27.3933C18.5963 27.4596 18.6988 27.4934 18.8037 27.4898C18.8736 27.503 18.9459 27.503 19.0158 27.4898C19.1797 27.4066 19.2834 27.239 19.2858 27.0558V24.3558C19.2822 23.9159 19.0809 23.5013 18.7362 23.2276L14.9076 20.1033Z"
        fill="#FFFFF2"
      />
      <path
        d="M27.3948 9.46771C27.5044 9.32186 27.5225 9.12659 27.4406 8.96267C27.3586 8.79995 27.1923 8.69629 27.009 8.69629H24.309C23.8691 8.69991 23.4545 8.9012 23.1808 9.24592L20.1048 13.1031C19.6793 13.6322 19.6793 14.3868 20.1048 14.9159L23.1808 18.7731C23.4545 19.1178 23.8691 19.3191 24.309 19.3227H27.009C27.1923 19.3227 27.3586 19.219 27.4406 19.0563C27.5225 18.8924 27.5044 18.6971 27.3948 18.5513L24.2319 14.3275C24.0921 14.1491 24.0921 13.8984 24.2319 13.72L27.3948 9.46771Z"
        fill="#FFFFF2"
      />
      <path
        d="M19.0337 0.557964C18.8794 0.466356 18.6865 0.466356 18.5322 0.557964L14.3085 3.76896C14.1301 3.90878 13.8794 3.90878 13.701 3.76896L9.46771 0.606102C9.32186 0.496414 9.12659 0.478334 8.96267 0.560297C8.79995 0.642263 8.69629 0.808602 8.69629 0.991803V3.6918C8.69991 4.13176 8.9012 4.54639 9.24592 4.82L13.1031 7.89609C13.6322 8.32158 14.3868 8.32158 14.9159 7.89609L18.7731 4.82C19.1178 4.54639 19.3191 4.13173 19.3227 3.6918V0.991803C19.3167 0.803766 19.2048 0.635107 19.0337 0.557964Z"
        fill="#FFFFF2"
      />
      <circle cx="14.0773" cy="14.0768" r="4.16571" fill="#FFFFF2" />
    </svg>
  );
}
