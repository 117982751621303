export default function WorldIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18C4.05 18 0 13.95 0 9C0 4.05 4.05 0 9 0C13.95 0 18 4.05 18 9C18 13.95 13.95 18 9 18ZM9 1.5003C4.8753 1.5003 1.5003 4.8753 1.5003 9C1.5003 13.1247 4.8753 16.4997 9 16.4997C13.1247 16.4997 16.4997 13.1247 16.4997 9C16.4997 4.8753 13.1247 1.5003 9 1.5003Z"
        fill="#FFFFF2"
      />
      <path
        d="M9.00039 18C6.37509 18 4.27539 14.0247 4.27539 9C4.27539 3.9753 6.37509 0 9.00039 0C11.6257 0 13.7254 3.9753 13.7254 9C13.7254 14.0247 11.6257 18 9.00039 18ZM9.00039 1.5003C7.50009 1.5003 5.77569 4.5756 5.77569 9C5.77569 13.4253 7.50099 16.4997 9.00039 16.4997C10.5007 16.4997 12.2251 13.4244 12.2251 9C12.2251 4.5747 10.5007 1.5003 9.00039 1.5003Z"
        fill="#FFFFF2"
      />
      <path
        d="M17.2503 9.7494H0.7497C0.2997 9.7494 0 9.4497 0 8.9997C0 8.5497 0.2997 8.25 0.7497 8.25H17.2494C17.6994 8.25 17.9991 8.5497 17.9991 8.9997C18 9.4497 17.7003 9.7494 17.2503 9.7494H17.2503Z"
        fill="#FFFFF2"
      />
      <path
        d="M8.9997 18C8.5497 18 8.25 17.7003 8.25 17.2503V0.7497C8.25 0.2997 8.5497 0 8.9997 0C9.4497 0 9.7494 0.2997 9.7494 0.7497V17.2494C9.7494 17.7003 9.4497 18 8.9997 18V18Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
