import { Roboto_Mono } from "next/font/google";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { Nature } from "@dimensional-engineering/dimensional-models";

import styles from "./NatureModal.module.css";

import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import NatureBreakdownRow from "../NatureBreakdownRow";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import ProfileImage from "@/components/publicProfiles/ProfileImage/ProfileImage";
import { natureMap } from "@/assets/traitInfo/natureData";
import WarningIcon from "@/components/shared/GhostBox/WarningIcon";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function NatureModal() {
  const userState = useSelector((state: RootState) => state.user);
  const { setModalOpen } = useModalContext();

  const router = useRouter();

  if (!userState.publicProfileData?.primaryNature) {
    return (
      <ModalWrapper noHeader backgroundColor="#2d2927" noLine title="">
        <div className={styles.main}>
          <div
            onClick={() => {
              //   router.back();
              setModalOpen(false);
            }}
            className={styles.xmark}
          >
            <CloseButtonSummary />
          </div>
          <h1>@{userState.publicProfileData?.userName}</h1>
          <ProfileImage
            largeMobile
            color={
              userState.publicProfileData?.primaryNature?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature?.nature
                  ]
                : "grey"
            }
            url={userState.imageURL}
            quality={100}
          />
        </div>
        <div className={styles.ghostBoxParent}>
          <div className={styles.ghostboxDiv}>
            <div className={styles.iconDiv}>
              <WarningIcon active />
            </div>
            <p className={styles.ghostboxDesc}>
              You must complete Core Dimensions to unlock your Primary Nature.
            </p>
            <button
              onClick={() => router.push("/app/assessment")}
              className={styles.ghostboxButton}
            >
              Continue
            </button>
          </div>
        </div>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noHeader backgroundColor="#2d2927" noLine title="">
      <div className={styles.main}>
        <div
          onClick={() => {
            //   router.back();
            setModalOpen(false);
          }}
          className={styles.xmark}
        >
          <CloseButtonSummary />
        </div>
        <h1>@{userState.publicProfileData?.userName}</h1>
        <ProfileImage
          largeMobile
          color={
            userState.publicProfileData?.primaryNature?.nature
              ? ProfileColorMap[
                  userState.publicProfileData?.primaryNature?.nature
                ]
              : "grey"
          }
          url={userState.imageURL}
          quality={100}
        />
        <div className={`${styles.content} ${font.className}`}>
          <div className={styles.natureDesc}>
            <div className={styles.rowTop}>
              <p className={styles.mainNatureText}>Main Nature</p>
              <p className={styles.mainNatureText}>
                <div
                  style={
                    userState.publicProfileData?.primaryNature?.nature
                      ? {
                          backgroundColor:
                            ProfileColorMap[
                              userState.publicProfileData?.primaryNature?.nature
                            ],
                        }
                      : {}
                  }
                  className={styles.natureCircle}
                ></div>
                {userState.publicProfileData?.primaryNature
                  ? natureMap[
                      userState.publicProfileData?.primaryNature
                        ?.nature as keyof typeof natureMap
                    ].name
                  : "?"}
              </p>
            </div>
            <div className={styles.row}>
              <p className={styles.mainNatureDescription}>
                {userState.publicProfileData?.primaryNature?.nature
                  ? natureMap[
                      userState.publicProfileData?.primaryNature?.nature
                    ].description
                  : ""}
              </p>
            </div>
          </div>
        </div>

        <p className={styles.breakdownText}>BREAKDOWN</p>
        <div className={styles.listPArent}>
          <NatureBreakdownRow
            natureSlug={Nature.chaos}
            publicProfile={userState.publicProfileData}
          />
          <NatureBreakdownRow
            natureSlug={Nature.opennessAndIntellect}
            publicProfile={userState.publicProfileData}
          />
          <NatureBreakdownRow
            natureSlug={Nature.warmthAndAgreeableness}
            publicProfile={userState.publicProfileData}
          />
          <NatureBreakdownRow
            natureSlug={Nature.orderAndStructure}
            publicProfile={userState.publicProfileData}
          />
          <NatureBreakdownRow
            natureSlug={Nature.tranquility}
            publicProfile={userState.publicProfileData}
          />
          <NatureBreakdownRow
            natureSlug={Nature.energyAndExcitement}
            publicProfile={userState.publicProfileData}
          />
          <NatureBreakdownRow
            natureSlug={Nature.selfEnhancement}
            publicProfile={userState.publicProfileData}
          />
        </div>
      </div>
    </ModalWrapper>
  );
}
