import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function StrengthsIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66727 9.90255C7.60778 8.95554 8.3844 8.18036 9.33985 8.18036H70.4926C71.4481 8.18036 72.2247 8.95554 72.1652 9.90255C71.2009 25.2536 59.4338 37.6871 44.3479 39.7481H70.4926C71.4481 39.7481 72.2247 40.5233 72.1652 41.4703C71.1083 58.2956 57.074 71.6161 39.9162 71.6161C22.7585 71.6161 8.72414 58.2956 7.66727 41.4703C7.60778 40.5233 8.3844 39.7481 9.33985 39.7481H35.4845C20.3987 37.6871 8.63153 25.2536 7.66727 9.90255Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
