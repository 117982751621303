import { useEffect, useState } from "react";

import styles from "./LargeElement.module.css";

import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";

type Props = {
  name: string;
  color: string;
  icon?: JSX.Element;
  dimensionName?: string;
  isPremium?: boolean | null | undefined;
};

export default function LargeElement(props: Props) {
  const [color, setColor] = useState<string>(props.color);

  useEffect(() => {
    if (!props.color.includes("#")) {
      setColor("#" + props.color);
    } else {
      setColor(props.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  return (
    <div className={styles.elementContainer}>
      <div className={styles.elementTop}>
        <div className={styles.iconDiv}>
          {props.icon && props.icon}
          <p>{props.dimensionName}</p>
        </div>
      </div>
      <div
        className={styles.mainCircle}
        style={{ backgroundColor: `${color}` }}
      >
        <div className={styles.midCircle}></div>
        {props.isPremium && (
          <div className={styles.premiumIcon}>
            <PremiumPillIcon />
          </div>
        )}
      </div>
      <div className={styles.elementNameContainer}>
        <p>{props.name}</p>
      </div>
    </div>
  );
}

export function LargeElementModal(props: Props) {
  const [color, setColor] = useState<string>(props.color);

  useEffect(() => {
    if (!props.color.includes("#")) {
      setColor("#" + props.color);
    } else {
      setColor(props.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.color]);

  return (
    <div className={styles.elementContainerModal}>
      <div className={styles.elementTopModal}>
        <div className={styles.iconDivModal}>{props.icon && props.icon}</div>
        <p>{props.dimensionName}</p>
      </div>
      <div
        className={styles.mainCircleModal}
        style={{ backgroundColor: `${color}` }}
      >
        <div className={styles.midCircleModal}></div>
        {props.isPremium && (
          <div className={styles.premiumIconModal}>
            <PremiumPillIcon />
          </div>
        )}
      </div>
      <div className={styles.elementNameContainer}>
        <p>{props.name}</p>
      </div>
    </div>
  );
}
