import { ReactNode, useEffect, useState } from "react";

import styles from "./ModalWrapper.module.css";

import { useModalContext } from "@/context/ModalContext";
import ModalAuthHeader from "../AuthenticatedHeader/ModalAuthHeader";

type Props = {
  children: ReactNode;
  large?: boolean;
  backgroundColor?: string;
  height?: number;
  title: string;
  subTitle?: string;
  noHeader?: boolean;
  noLine?: boolean;
  onClose?: () => void;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
};

export default function ModalWrapper(props: Props) {
  const { emptyModal, modalComponent, setModalOpen } = useModalContext();
  const [yPosition, setYPosition] = useState<number>(0);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        emptyModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.height) {
    return (
      <dialog
        onMouseDown={() => {
          emptyModal();
        }}
        className={styles.main}
      >
        <div
          onMouseDown={(e) => e.stopPropagation()}
          className={props.large ? styles.parentLarge : styles.parent}
        >
          <main
            style={
              props.backgroundColor
                ? yPosition !== 0
                  ? {
                      backgroundColor: props.backgroundColor,
                      minHeight: `${props.height}px`,
                      position: "absolute",
                      left: 0,
                      top: yPosition,
                    }
                  : yPosition !== 0
                  ? { position: "absolute", left: 0, top: yPosition }
                  : {
                      backgroundColor: props.backgroundColor,
                      minHeight: `${props.height}px`,
                    }
                : {}
            }
            id="dialog"
            className={props.large ? styles.wrapperLarge : styles.wrapper}
            onMouseDown={(e) => e.stopPropagation()}
          >
            {!props.noHeader && (
              <ModalAuthHeader
                onDragDown={(positionY) => {
                  console.log(positionY, window.screen.height);
                  if (positionY > window.screen.height / 2) {
                    setModalOpen(false);
                  }
                  setYPosition(positionY);
                }}
                noLine={props.noLine}
                title={props.title}
                subTitle={props.subTitle}
                downArrow={modalComponent.length < 2 ? true : false}
                onBack={() => {
                  setModalOpen(false);
                }}
              />
            )}
            <div className={styles.childrenWrapper}>{props.children}</div>
          </main>
        </div>
      </dialog>
    );
  }

  return (
    <dialog
      onMouseDown={() => {
        emptyModal();
      }}
      className={styles.main}
    >
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={props.large ? styles.parentLarge : styles.parent}
      >
        <main
          style={
            props.backgroundColor
              ? yPosition !== 0
                ? {
                    backgroundColor: props.backgroundColor,
                    position: "absolute",
                    left: 0,
                    top: yPosition,
                  }
                : { backgroundColor: props.backgroundColor }
              : yPosition !== 0
              ? { position: "absolute", left: 0, top: yPosition }
              : {}
          }
          id="dialog"
          className={props.large ? styles.wrapperLarge : styles.wrapper}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {!props.noHeader && (
            <ModalAuthHeader
              onDragDown={(positionY) => {
                if (positionY > window.screen.height / 2) {
                  setModalOpen(false);
                }
                setYPosition(positionY);
              }}
              noLine={props.noLine}
              title={props.title}
              subTitle={props.subTitle}
              downArrow={modalComponent.length < 2 ? true : false}
              onBack={() => {
                setModalOpen(false);
                props.onClose && props.onClose();
              }}
            />
          )}
          <div
            onScroll={(e) => props.onScroll && props.onScroll(e)}
            className={styles.childrenWrapper}
          >
            {props.children}
          </div>
        </main>
      </div>
    </dialog>
  );
}
