import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import GetPlusButton from "../GetPlusButton/GetPlusButton";
import styles from "./PremiumTraitAccessDeniedBar.module.css";

export default function PremiumTraitAccessDeniedBar(props: {
  notOwn?: boolean;
  traitName?: string;
  publicProfile?: PublicProfile | null;
}) {
  if (props.notOwn && props.traitName) {
    return (
      <div className={styles.main}>
        <p
          className={styles.text}
        >{`This is a premium trait. ${props.traitName} requires Dimensional Plus.`}</p>
      </div>
    );
  }

  if (props.notOwn && props.publicProfile !== undefined) {
    return (
      <div className={styles.main}>
        <p
          className={styles.text}
        >{`@${props.publicProfile?.userName} requires a Dimensional Plus account.`}</p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <p className={styles.text}>
        {"Unlocking this Trait's score requires Dimensional Plus."}
      </p>
      <div className={styles.btnDiv}>
        <GetPlusButton source="Trait" />
      </div>
    </div>
  );
}
