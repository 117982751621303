import Image from "next/image";

import useIsMobile from "@/helpers/useIsMobile";

type LoaderProps = {
  height: string;
  mobileHeight: string;
};

export default function Loader(props: LoaderProps) {
  const isMobile = useIsMobile();

  return (
    <div
      className="loader-parent"
      style={
        isMobile ? { height: props.mobileHeight } : { height: props.height }
      }
    >
      <Image
        width={50}
        height={50}
        alt=""
        className="loader"
        src={"/loader.png"}
      />
    </div>
  );
}

export function SmallLoader() {
  return (
    <div className="loader-parent" style={{ height: "100%", width: "100%" }}>
      <Image
        width={20}
        height={20}
        alt=""
        className="loader"
        src={"/loader.png"}
        style={{ height: "20px", width: "20px" }}
      />
    </div>
  );
}
