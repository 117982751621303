import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./PatternComponentOf.module.css";

import { natureData } from "@/assets/traitInfo/natureData";
import Pattern from "@/components/traits/pattern/Pattern/Pattern";
import { ExtendedPattern } from "@/models/sharedModels";
import elementJsonData from "../../../../../assets/traitInfo/elements.json";
import Element from "@/components/traits/element/Element/Element";
import useIsMobile from "@/helpers/useIsMobile";
import TraitsHeading from "../../Heading/TraitsHeading";
import UnknownTutorialElement from "@/components/traits/element/Element/UnknownTutorialElement";
import PrimaryTraitsIcon from "@/components/shared/dimensions/icons/PrimaryTraitsIcon";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";

type Props = {
  pattern: ExtendedPattern;
  authenticated?: boolean;
  hideTitle?: boolean;
  score?: number;
  inTutorial?: boolean;
  questionElement?: boolean;
  modal?: boolean;
  uid?: string;
};

export default function PatternComponentOf(props: Props) {
  const [lineWidth, setLineWidth] = useState<number>(51.5);
  const isMobile = useIsMobile();
  const length = props.pattern.traitContent.traitDetails?.length;
  const { setModalComponent } = useModalContext();

  useEffect(() => {
    if (length) {
      if (props.modal) {
        if (isMobile) {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(192);
          } else {
            setLineWidth(192);
          }
        } else {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(287);
          } else {
            setLineWidth(287);
          }
        }
      } else {
        if (isMobile) {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(192);
          } else {
            setLineWidth(192);
          }
        } else {
          if (length === 2) {
            setLineWidth(97);
          } else if (length === 3) {
            setLineWidth(192);
          } else if (length === 4) {
            setLineWidth(4 * 76);
          } else if (length === 5) {
            setLineWidth(405);
          } else {
            setLineWidth(477);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length]);

  if (props.modal) {
    return (
      <div className={styles.main}>
        {!props.hideTitle && <TraitsHeading title="Pattern Components" />}
        <div className={styles.parent}>
          <div className={styles.archDiv}>
            <div className="elBody">
              <Pattern score={props.score} patternSlug={props.pattern.slug} />
            </div>

            <div className={styles.archLine}></div>
          </div>
          <div
            style={{ width: `${lineWidth}px` }}
            className={styles.long}
          ></div>
          <div className={styles.wrapper}>
            {props.pattern.traitContent.traitDetails?.map((el, index) => {
              let color: any;
              elementJsonData.forEach((e) => {
                if (e.slug === el.slug) {
                  color =
                    natureData?.find((n) => n.slug === e.nature)?.color ??
                    "gray";
                }
              });
              return (
                <div className={styles.elDiv} key={el.slug}>
                  <div className={styles.archLine}></div>
                  <div
                    onClick={() => {
                      setModalComponent(
                        <ElementModal uid={props.uid} slug={el.slug} />
                      );
                    }}
                    className="elBodyNoMarginTopBottom"
                    style={{ cursor: "pointer" }}
                  >
                    <Element slug={el.slug} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {!props.hideTitle && <TraitsHeading title="Pattern Components" />}
      <div className={styles.parent}>
        <div className={styles.archDiv}>
          <div className="elBody">
            <Pattern score={props.score} patternSlug={props.pattern.slug} />
          </div>

          <div className={styles.archLine}></div>
        </div>
        <div style={{ width: `${lineWidth}px` }} className={styles.long}></div>
        <div className={styles.wrapper}>
          {props.pattern.traitContent.traitDetails?.map((el, index) => {
            let color: any;
            elementJsonData.forEach((e) => {
              if (e.slug === el.slug) {
                color =
                  natureData?.find((n) => n.slug === e?.nature)?.color ??
                  "gray";
              }
            });
            return (
              <div className={styles.elDiv} key={el.slug}>
                <div className={styles.archLine}></div>
                <Link key={el.slug} href={`/traits/elements/${el.slug}`}>
                  {props.inTutorial ? (
                    <UnknownTutorialElement
                      noMarginTopBottom
                      // @ts-ignore
                      name={el.alias ?? el.name}
                      color={color}
                      question={props.questionElement ?? false}
                      icon={<PrimaryTraitsIcon />}
                    />
                  ) : (
                    <div className="elBodyNoMarginTopBottom">
                      <Element slug={el.slug} />
                    </div>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
