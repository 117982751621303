export default function EmotionsAndIntellectIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8336 12.3994C33.8336 16.8182 27.8648 20.3994 20.5008 20.3994C13.1368 20.3994 7.16797 16.8182 7.16797 12.3994C7.16797 7.98065 13.1368 4.39941 20.5008 4.39941C27.8648 4.39941 33.8336 7.98065 33.8336 12.3994Z"
        fill="#FFFFF2"
      />
      <path
        d="M8.5 20.3998C8.5 23.067 4.5 23.067 4.5 20.3998C4.5 17.7326 8.5 17.7326 8.5 20.3998Z"
        fill="#FFFFF2"
      />
      <path
        d="M36.5 20.3998C36.5 23.067 32.5 23.067 32.5 20.3998C32.5 17.7326 36.5 17.7326 36.5 20.3998Z"
        fill="#FFFFF2"
      />
      <path
        d="M33.8336 28.3994C33.8336 32.8182 27.8648 36.3994 20.5008 36.3994C13.1368 36.3994 7.16797 32.8182 7.16797 28.3994C7.16797 23.9807 13.1368 20.3994 20.5008 20.3994C27.8648 20.3994 33.8336 23.9807 33.8336 28.3994Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
