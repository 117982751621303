/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";
import { DM_Sans } from "next/font/google";

import styles from "./DiscoveryGate.module.css";

import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { getDefaultProfilePhoto } from "@/context/utils";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import DiscoveryCheckMark from "./DiscoveryCheckMark";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";

const font = DM_Sans({ weight: "500", subsets: ["latin"] });

export default function UserSuccessInviteGate(props: {
  numberOfInvitesRequired: number;
  invites: string[] | [] | null | undefined;
  onOpenShareLink: () => void;
}) {
  const { imagURls, emptyImages, profiles, invitesFull } =
    useGetImagesAndDataInviteGate({
      numberOfInvitesRequired: props.numberOfInvitesRequired,
      invites: props.invites,
    });

  const userState = useSelector((state: RootState) => state.user);
  const { setModalComponent } = useModalContext();

  const mapped = [imagURls, new Array(emptyImages).fill(" ")]
    .flat()
    .map((value, i) => {
      if (value === " ") {
        return (
          <div
            style={i === 0 ? { marginLeft: "0" } : {}}
            className={styles.emptyImageDiv}
            key={i}
          >
            <p>{i + 1}</p>
          </div>
        );
      } else {
        return (
          <div
            style={i === 0 ? { marginLeft: "0" } : {}}
            className={styles.imageDiv}
            key={value}
          >
            <img
              style={
                profiles[i]?.primaryNature
                  ? {
                      border: `solid 3px ${
                        ProfileColorMap[
                          profiles[i].primaryNature
                            ?.nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: `solid 3px grey` }
              }
              className={styles.image}
              src={value}
              alt=""
            />
          </div>
        );
      }
    });

  if (invitesFull || userState.isPremium === true) {
    return (
      <div className={styles.tableHeader}>
        Successful Invites{" "}
        <div className={styles.checkmarkCompleteDiv}>
          <DiscoveryCheckMark backgroundColor="#e6e1df" checkColor="#2D2927" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.tableHeader}>Successful Invites</div>
      <div className={styles.friendSignupWrapper}>
        <p className={styles.friendSignupText}>
          Get {props.numberOfInvitesRequired} friends to sign up for Dimensional
          to unlock this
        </p>
        <div className={styles.imageParent}>{mapped}</div>
        <div className={styles.inviteButtonDiv}>
          <button
            onClick={() => props.onOpenShareLink()}
            className={styles.inviteButton}
          >
            Send Invites
          </button>
        </div>
        <p className={styles.friendSignupWarning}>
          Don't worry. If friends don't sign up with your link, they can also
          give you credit when they sign up.
        </p>
        <div className={styles.premiumDiv}>
          <p className={styles.premiumTopText}>Or subscribe to</p>
          <p className={`${styles.premiumBottomText} ${font.className}`}>
            Dimensional <span className={styles.plus}>Plus</span>
          </p>
          <button
            onClick={() => setModalComponent(<ConversionModal />)}
            className={styles.premiumButton}
          >
            Learn More
          </button>
        </div>
      </div>
    </>
  );
}

export function useGetImagesAndDataInviteGate(props: {
  numberOfInvitesRequired: number;
  invites: string[] | [] | null | undefined;
}) {
  const [imagURls, setImageURls] = useState<any[] | []>([]);
  const [emptyImages, setEmptyImages] = useState<number>(0);
  const [profiles, setProfiles] = useState<PublicProfile[]>([]);
  const [invitesFull, setInvitesFull] = useState<boolean>(false);

  useEffect(() => {
    async function getImages(invites: string[]) {
      let promises = invites
        .slice(0, props.numberOfInvitesRequired)
        .map(async (uid) => {
          try {
            const query = doc(db, "publicProfiles", `${uid}`);
            const docSnap = await getDoc(query);
            if (docSnap.exists()) {
              setProfiles((current) => [
                ...current,
                docSnap.data() as PublicProfile,
              ]);
            }
            const url = await getDownloadURL(
              ref(
                firebaseStorage,
                `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${uid}/cropped.jpg`
              )
            );
            return url;
          } catch (err) {
            return await getDefaultProfilePhoto(uid).then((value) => {
              return value;
            });
          }
        });
      setImageURls(await Promise.all(promises));
    }
    if (props.invites) {
      getImages(props.invites).then(() => {
        if (props.invites) {
          if (props.numberOfInvitesRequired > props.invites.length) {
            setEmptyImages(
              props.numberOfInvitesRequired - props.invites.length
            );
          } else if (props.invites.length >= props.numberOfInvitesRequired) {
            setInvitesFull(true);
          } else {
            setInvitesFull(false);
          }
        }
      });
    } else {
      setEmptyImages(props.numberOfInvitesRequired);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { imagURls, emptyImages, profiles, invitesFull };
}
