import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import useIsMobile from "@/helpers/useIsMobile";

type ModalContextModel = {
  isModalOpen: boolean;
  modalComponent: JSX.Element[];
  setModalOpen: (value: boolean) => void;
  setModalComponent: (value: JSX.Element) => void;
  emptyModal: () => void;
  removeModalByName: (name: string) => void;
};

const ModalDefaultValue: ModalContextModel = {
  isModalOpen: false,
  modalComponent: [],
  setModalOpen: () => {},
  setModalComponent: () => {},
  emptyModal: () => {},
  removeModalByName: (name: string) => {},
};

type Props = {
  children: ReactNode;
};

const ModalContext = createContext<ModalContextModel>(ModalDefaultValue);

export function useModalContext() {
  return useContext(ModalContext);
}

export function ModalContextProvider({ children }: Props) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalComponent, setModalJSXComponent] = useState<JSX.Element[]>([]);
  const isMobile = useIsMobile();

  function handleUi() {
    const dialogComp = document.querySelectorAll("#dialog");
    if (dialogComp.length) {
      let first = dialogComp[dialogComp.length - 1] as HTMLDivElement;
      first.style.marginTop = "0";
    }
  }

  useEffect(() => {
    if (modalComponent.length === 0) {
      setIsModalOpen(false);
      document.body.style.overflowY = "visible";
    } else {
      setIsModalOpen(true);
      document.body.style.overflowY = "hidden";
    }
  }, [modalComponent.length]);

  function setModalOpen(value: boolean) {
    const dialogComp = document.querySelectorAll("#dialog");
    if (value === false) {
      if (dialogComp.length) {
        let first = dialogComp[dialogComp.length - 1] as HTMLDivElement;
        first.style.transition = "all 0.5s";
        if (modalComponent.length > 1) {
          first.style.marginLeft = "200%";
          setTimeout(() => {
            setModalJSXComponent((current) => {
              const newArray = current.slice(0, -1);
              return newArray;
            });
          }, 200);
        } else {
          isMobile
            ? (first.style.marginTop = "400%")
            : (first.style.marginTop = "200%");
          setTimeout(() => {
            setModalJSXComponent((current) => {
              const newArray = current.slice(0, -1);
              return newArray;
            });
          }, 200);
        }
      } else {
        setModalJSXComponent((current) => {
          const newArray = current.slice(0, -1);
          return newArray;
        });
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function setModalComponent(value: JSX.Element) {
    setModalJSXComponent((current) => [...current, value]);
  }

  function emptyModal() {
    setModalJSXComponent([]);
  }

  function removeModalByName(name: string) {
    setModalJSXComponent((prevComponents) =>
      prevComponents.filter((component) =>
        component.type ? component.props.elementName !== name : true
      )
    );
  }

  useEffect(() => {
    setTimeout(() => {
      handleUi();
    }, 100);
  }, [setModalComponent]);

  const value = {
    isModalOpen: isModalOpen,
    modalComponent: modalComponent,
    setModalOpen: setModalOpen,
    setModalComponent: setModalComponent,
    emptyModal: emptyModal,
    removeModalByName: removeModalByName,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
