import { TabIconModel } from "@/models/sharedModels";

export default function TabAssessmentIcon(props: TabIconModel) {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2573 12.4221H10.9749C10.1968 12.4221 9.56055 11.7224 9.56055 10.8666C9.56055 10.0108 10.1968 9.31104 10.9749 9.31104H11.2573C12.0354 9.31104 12.6717 10.0108 12.6717 10.8666C12.6703 11.7224 12.0341 12.4221 11.2573 12.4221Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M6.11663 15.8666C6.67219 16.4222 7.22775 16.9317 7.7833 17.3944C8.33886 17.1626 8.94041 16.8389 9.54286 16.4682C8.80241 15.8666 8.06108 15.2642 7.36663 14.5697C5.05152 12.2546 3.33886 9.70863 2.55152 7.30152C1.81108 5.17219 1.90394 3.45952 2.7833 2.5333C3.70952 1.65397 5.46819 1.56108 7.59841 2.30152C10.0055 3.13486 12.5524 4.84752 14.8666 7.11663C15.5611 7.81108 16.2095 8.55152 16.7651 9.29286C17.1357 8.6913 17.4135 8.13575 17.6913 7.5333C17.2286 6.97775 16.7191 6.37619 16.1635 5.86663C13.6626 3.36663 10.8389 1.46819 8.15397 0.588856C5.3293 -0.383366 2.96819 -0.151589 1.5333 1.2833C0.0984114 2.71819 -0.133366 5.0793 0.838856 7.94997C1.71819 10.5889 3.61663 13.4126 6.11663 15.8666Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M20.9772 12.7651C20.6065 13.3667 20.1439 13.9691 19.7272 14.5247C20.4217 16.608 20.2828 18.2747 19.4494 19.154C18.5701 20.0334 16.9034 20.1262 14.8201 19.4318C14.2185 19.8945 13.663 20.3111 13.0605 20.6818C13.4312 20.8207 13.755 20.9596 14.1257 21.0985C15.2828 21.5151 16.3479 21.7 17.3201 21.7C18.755 21.7 19.913 21.2834 20.7923 20.404C22.2272 18.9691 22.459 16.608 21.4868 13.7374C21.301 13.4596 21.1621 13.1349 20.9772 12.7651Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M7.78234 4.29297C7.22678 4.75564 6.62522 5.26519 6.11567 5.82075C5.56011 6.3763 5.05056 6.93186 4.58789 7.48741C4.81967 8.04297 5.14345 8.64452 5.51411 9.24697C6.11567 8.50652 6.71811 7.81208 7.41256 7.11764C8.107 6.42319 8.84745 5.77475 9.58878 5.21919C8.93947 4.84852 8.33791 4.57075 7.78236 4.29297H7.78234Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
      <path
        d="M20.7 1.2833C19.2651 -0.151589 16.904 -0.383366 14.0333 0.588856C13.7095 0.727745 13.3389 0.866634 12.9682 1.00552C13.5697 1.37619 14.1722 1.83886 14.7277 2.25552C15.6071 1.97774 16.4404 1.79286 17.1817 1.79286C18.108 1.79286 18.8944 2.02463 19.358 2.5333C20.2373 3.41263 20.3302 5.17219 19.6357 7.30152C18.8024 9.70863 17.0897 12.2555 14.8206 14.5697C12.5055 16.8849 9.95952 18.5975 7.55241 19.3849C5.42221 20.1722 3.70952 20.0793 2.7833 19.2C1.90397 18.3206 1.81108 16.654 2.50552 14.5706C2.04286 13.9691 1.62619 13.4135 1.25552 12.8111C1.11663 13.1817 0.977745 13.5055 0.838856 13.8762C-0.133366 16.7468 0.0984114 19.108 1.5333 20.5428C2.36663 21.3762 3.57063 21.8389 5.00552 21.8389C5.97775 21.8389 7.04286 21.654 8.19997 21.2373C10.8389 20.3111 13.6626 18.4595 16.1626 15.9595C18.6626 13.4595 20.5142 10.6357 21.4404 7.99685C22.3666 5.07929 22.1349 2.71818 20.7 1.28329V1.2833Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}
