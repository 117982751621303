export default function PatternsStatusIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.571411 1.82259C0.571411 1.13158 1.13159 0.571411 1.8226 0.571411H4.97301C5.66401 0.571411 6.22419 1.13159 6.22419 1.8226V18.7012C6.22419 19.3922 5.66401 19.9524 4.97301 19.9524H1.82259C1.13159 19.9524 0.571411 19.3922 0.571411 18.7012V1.82259Z"
        fill="#FFFFF2"
      />
      <path
        d="M7.40649 1.82259C7.40649 1.13158 7.96667 0.571411 8.65768 0.571411H11.8081C12.4991 0.571411 13.0593 1.13159 13.0593 1.8226V18.7012C13.0593 19.3922 12.4991 19.9524 11.8081 19.9524H8.65768C7.96667 19.9524 7.40649 19.3922 7.40649 18.7012V1.82259Z"
        fill="#FFFFF2"
      />
      <path
        d="M14.2877 1.82259C14.2877 1.13158 14.8478 0.571411 15.5388 0.571411H18.6893C19.3803 0.571411 19.9404 1.13159 19.9404 1.8226V18.7012C19.9404 19.3922 19.3803 19.9524 18.6893 19.9524H15.5388C14.8478 19.9524 14.2877 19.3922 14.2877 18.7012V1.82259Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
