import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/redux/store";

export default function useGetFriendStatus(uid: string | undefined) {
  const friendList = useSelector(
    (state: RootState) => state.user.friendUidList
  );
  const [isFriend, setIsFriend] = useState<boolean>(true);

  useEffect(() => {
    if (uid) {
      if (!friendList.includes(uid)) {
        setIsFriend(false);
      } else {
        setIsFriend(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return isFriend;
}
