import { DimensionIconProps } from "@/models/sharedModels";

export default function ComplementEachotherIcon(props: DimensionIconProps) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "100%"}
      height={props.maxWidth ? "100%" : "100%"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.3775 10.803C26.5618 9.76962 26.7836 8.7737 27.1147 7.81379C27.3724 7.03837 27.4098 6.15344 27.1881 5.26851C26.7821 3.71914 25.5295 2.50126 23.9817 2.13229C21.0705 1.43184 18.4906 3.60816 18.4906 6.41305C18.4906 6.893 18.564 7.33546 18.7123 7.77793C19.0434 8.77385 19.2652 9.76976 19.4495 10.8032H9.75532V20.4697C8.76044 20.2853 7.76556 20.0633 6.80665 19.7318C6.03203 19.4738 5.14802 19.4363 4.26401 19.6583C2.71625 20.0648 1.49962 21.3187 1.13104 22.868C0.429882 25.7463 2.64139 28.3291 5.40588 28.3291C5.88533 28.3291 6.32734 28.2556 6.76935 28.1072C7.72826 27.7757 8.72314 27.5537 9.75547 27.3692V37H19.3763C19.192 36.0401 18.9702 35.1177 18.6765 34.1953C18.5297 33.7528 18.4548 33.3103 18.4548 32.8304C18.4548 30.0256 21.0349 27.8493 23.946 28.5496C25.4937 28.9186 26.7478 30.1365 27.1524 31.6858C27.3741 32.6083 27.3367 33.4572 27.0789 34.2311C26.7838 35.1535 26.5635 36.076 26.3792 36.9984H36L35.9986 10.8032L26.3775 10.803Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
