export default function SadFace() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M19.3891 23.4723C23.8832 23.4723 27.6289 27.0254 27.6289 31.2884H31.0005C31.0005 25.2469 25.7581 20.6292 19.7634 20.6292C13.7727 20.6292 8.52637 25.602 8.52637 31.2884H11.8979C11.1496 27.0216 14.8953 23.4723 19.3893 23.4723H19.3891Z"
          fill="#FFFFF2"
        />
        <path
          d="M15.6432 11.3887C15.6432 13.7587 11.8975 13.7587 11.8975 11.3887C11.8975 9.02245 15.6432 9.02245 15.6432 11.3887Z"
          fill="#FFFFF2"
        />
        <path
          d="M27.2545 11.3887C27.2545 13.7587 23.5088 13.7587 23.5088 11.3887C23.5088 9.02245 27.2545 9.02245 27.2545 11.3887Z"
          fill="#FFFFF2"
        />
      </g>
    </svg>
  );
}
