import styles from "./FilterBar.module.css";

type Props = {
  currentFilter: string;
  filters: string[];
  onFilter: (f: string) => void;
};

export default function FilterBar(props: Props) {
  const mapped = props.filters.map((f, i) => {
    return (
      <p
        key={f}
        className={
          props.currentFilter === f ? styles.optionActive : styles.option
        }
        onClick={() => {
          props.onFilter(f);
        }}
      >
        {f.toUpperCase()}
      </p>
    );
  });

  return (
    <div className={styles.main}>
      <p className={styles.show}>Show:</p>
      <div className={styles.optionsDiv}>{mapped}</div>
    </div>
  );
}

type SortProps = {
  currentOption: string;
  options: string[];
  onSort: (s: string) => void;
};

export function SortBar(props: SortProps) {
  const mapped = props.options.map((s, i) => {
    return (
      <p
        key={s}
        className={
          props.currentOption === s ? styles.optionActive : styles.option
        }
        onClick={() => {
          props.onSort(s);
        }}
      >
        {s.toUpperCase()}
      </p>
    );
  });

  return (
    <div className={styles.main}>
      <p className={styles.show}>Sort By:</p>
      <div className={styles.optionsDiv}>{mapped}</div>
    </div>
  );
}
