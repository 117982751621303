export default function WorkEnvironmentSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3629 29.918C17.9717 29.6827 17.5806 29.4092 17.1894 29.0959V29.1357C17.0335 30.465 16.7982 32.1091 16.5247 32.6959C15.6247 34.4561 12.7307 40.4793 12.691 40.5591C12.2601 41.4591 12.6131 42.5546 13.5131 42.984C13.7866 43.1016 14.0219 43.1796 14.2954 43.1796C14.9601 43.1796 15.6247 42.7884 15.938 42.1619C15.9777 42.1222 18.8717 36.0989 19.7717 34.3386C20.1231 33.6342 20.3187 32.696 20.5143 31.6784C19.9275 31.1314 19.3011 30.6226 18.7938 30.2696C18.6746 30.1137 18.5188 29.9959 18.3629 29.918Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.9696 6.00055C23.1072 6.03722 24.8125 7.799 24.7758 9.93801C24.7407 12.0756 22.9774 13.7809 20.8399 13.7458C18.7007 13.7091 16.9971 11.9458 17.0322 9.8083C17.0673 7.6691 18.8306 5.96542 20.9696 6.00055Z"
        fill="#FFFFF2"
      />
      <path
        d="M38.5078 29.7224L36.1991 25.3418C36.0035 24.9903 35.5726 24.8727 35.2212 25.0285L33.4212 25.9667L32.9521 24.9506C32.7962 24.6771 32.5609 24.4815 32.2874 24.4036C32.1316 24.3638 31.936 24.3256 31.7786 24.3638C31.7007 24.208 31.583 24.0903 31.4654 23.9727C30.3316 23.0727 28.5714 21.4698 28.1007 20.7654C27.9831 20.5699 27.8272 20.2184 27.6698 19.8654C26.8875 18.145 25.4008 14.9759 22.1157 14.9759C22.1157 14.9759 20.3554 14.9362 19.8084 14.9362C16.0924 14.8582 13.6675 18.1434 13.0808 19.3168C12.5338 20.4903 11.2808 24.2064 11.0471 25.0286C10.8515 25.7712 11.2824 26.5535 12.025 26.7491C12.1426 26.7888 12.2603 26.7888 12.4161 26.7888C13.0426 26.7888 13.5896 26.3977 13.7852 25.7712C14.0205 24.9888 15.2322 21.4285 15.6631 20.5303C15.7808 20.2568 16.2101 19.6304 16.8366 19.0436C16.2101 21.6641 16.4454 25.2623 16.5631 26.7891C16.641 27.767 18.3631 28.7846 19.341 29.4493C20.9454 30.5449 23.4083 33.0477 23.9172 34.1832C24.3084 35.0831 25.9907 39.855 26.8509 42.0064C27.1245 42.7108 27.8289 43.1799 28.5333 43.1799C28.7686 43.1799 28.9642 43.1402 29.1979 43.0623C30.1361 42.7108 30.6067 41.6535 30.2156 40.7153C30.0979 40.4418 27.7509 33.9098 27.2421 32.7363C26.8112 31.7187 24.973 29.4893 23.7614 28.198C23.7216 28.1583 23.7216 28.1583 23.6834 28.1201C23.6437 27.4936 23.6834 22.9172 24.3879 19.7101C24.6232 20.141 24.8569 20.6101 25.0923 21.0792C25.3276 21.5482 25.4834 21.9394 25.7188 22.2924C26.4613 23.4659 28.4569 25.1864 29.3569 25.9305C29.2392 26.204 29.279 26.557 29.3966 26.8305L29.9054 27.8084L28.1055 28.7466C27.754 28.9422 27.6364 29.3731 27.7922 29.7245L30.0613 34.1451C30.2569 34.4965 30.6878 34.6141 31.0392 34.4583L38.158 30.782C38.5461 30.5054 38.6638 30.076 38.5079 29.7231L38.5078 29.7224ZM30.1361 26.3975C30.292 26.4372 30.4096 26.4754 30.567 26.4754C30.998 26.4754 31.3891 26.2799 31.6626 25.9666C31.8582 25.7313 31.9361 25.4578 31.9759 25.1843H32.0156C32.0935 25.224 32.1332 25.2622 32.1714 25.3401L32.6803 26.3181L30.645 27.4152L30.1361 26.3975Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
