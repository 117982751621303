export default function UnderEstimatedSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M35.2929 21.7071C35.6834 21.3166 35.6834 20.6834 35.2929 20.2929L33.7071 18.7071C33.3166 18.3166 32.6834 18.3166 32.2929 18.7071L20.7071 30.2929C20.3166 30.6834 19.6834 30.6834 19.2929 30.2929L7.70711 18.7071C7.31658 18.3166 6.68342 18.3166 6.29289 18.7071L4.70711 20.2929C4.31658 20.6834 4.31658 21.3166 4.70711 21.7071L19.2929 36.2929C19.6834 36.6834 20.3166 36.6834 20.7071 36.2929L35.2929 21.7071ZM35 12C35.5523 12 36 11.5523 36 11L36 9C36 8.44772 35.5523 8 35 8L5 8C4.44771 8 4 8.44771 4 9L4 11C4 11.5523 4.44771 12 5 12L35 12Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
