import styles from "../GlobalTabBar.module.css";

export default function TabIconWrapper(props: {
  children: React.ReactNode;
  width?: string;
}) {
  return (
    <div
      style={props.width ? { width: props.width } : {}}
      className={styles.iconWrapper}
    >
      {props.children}
    </div>
  );
}
