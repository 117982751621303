import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./ElementComponentOf.module.css";

import Pattern from "@/components/traits/pattern/Pattern/Pattern";
import { ExtendedScale, ExtendedPattern } from "@/models/sharedModels";
import patternJsonData from "../../../../../assets/traitInfo/patterns.json";
import TraitsHeading from "../../Heading/TraitsHeading";
import TraitsWrapper from "../../Wrapper/Wrapper";
import { useModalContext } from "@/context/ModalContext";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";

type Props = {
  scale: ExtendedScale;
  authenticated?: boolean;
  uid?: string;
};

export default function ElementComponentOf(props: Props) {
  const [patterns, setPatterns] = useState<ExtendedPattern[] | []>([]);
  const { setModalComponent } = useModalContext();

  useEffect(() => {
    props.scale.traitContent.componentOfDetails?.componentTraits.forEach(
      (pat) => {
        patternJsonData.forEach((p) => {
          if (pat.slug === p.slug) {
            //@ts-ignore
            setPatterns((current) => [...current, p]);
          }
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.authenticated) {
    return (
      <TraitsWrapper>
        <TraitsHeading title="Component of:" />
        <div className={styles.wrapper}>
          {patterns.map((patt, index) => {
            return (
              <div
                onClick={() => {
                  setModalComponent(
                    <PatternModal slug={patt.slug} uid={props.uid} />
                  );
                }}
                key={patt.slug}
                className="elBodyNoMarginMobile"
                style={{ cursor: "pointer" }}
              >
                <Pattern patternSlug={patt.slug} />
              </div>
            );
          })}
        </div>
      </TraitsWrapper>
    );
  }

  return (
    <TraitsWrapper>
      <TraitsHeading title="Component of:" />
      <div className={styles.wrapper}>
        {patterns.map((patt, index) => {
          return (
            <Link
              href={`/traits/patterns/${patt.slug}`}
              key={patt.slug}
              className="elBodyNoMarginMobile"
            >
              <Pattern patternSlug={patt.slug} />
            </Link>
          );
        })}
      </div>
    </TraitsWrapper>
  );
}
