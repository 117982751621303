import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { DimensionDataType } from "@/assets/traitInfo/dimensionData";
import { getDefaultProfilePhoto } from "@/context/utils";
import {
  DimensionEvent,
  PeerAssessmentEvent,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

export function getRewardsLevelFromNumOfInvites(
  invites: number | undefined
): number {
  //  Level 1 - 1 invite
  //  Level 2 - 2 invites
  //  Level 3 - 3 invites
  //  Level 4 - 5 invites

  if (!invites || invites === 0) {
    return 0;
  } else if (invites <= 3) {
    return invites;
  } else if (invites === 4) {
    return 3;
  } else {
    return 4;
  }
}

export function getFeedBackLevelFromNumOfPeerAssessments(
  peerAssesseeUids: string[] | null | undefined
): number {
  //  Level 1 - 3 assessments
  //  Level 2 - 5 assessmentss
  //  Level 3 - 6 assessmentss
  //  Level 4 - 7 assessmentss
  if (
    peerAssesseeUids === null ||
    peerAssesseeUids == undefined ||
    peerAssesseeUids.length < 3
  ) {
    return 0;
  } else if (peerAssesseeUids.length === 3) {
    return 1;
  } else if (peerAssesseeUids.length === 4 || peerAssesseeUids.length === 5) {
    return 2;
  } else if (peerAssesseeUids.length === 6) {
    return 3;
  } else {
    return 4;
  }
}

export function sortAssessmentBarData(
  a: DimensionDataType,
  b: DimensionDataType,
  dimensionEvents: DimensionEvent[] | null
): number {
  const aStatus = a.event;
  const bStatus = b.event;

  const aUpdatedOn = dimensionEvents?.find(
    (dim) => dim.dimensionSlug === a.slug
  )?.updatedOn;
  const bUpdatedOn = dimensionEvents?.find(
    (dim) => dim.dimensionSlug === b.slug
  )?.updatedOn;

  let order = 0;

  if (aStatus === "Continue" && bStatus !== "Continue") {
    order = -1;
  } else if (bStatus === "Continue" && aStatus !== "Continue") {
    order = 1;
  } else if (aStatus === "Continue" && bStatus === "Continue") {
    if (aUpdatedOn && bUpdatedOn) {
      order = aUpdatedOn - bUpdatedOn;
    } else {
      order = 0;
    }
  }

  if (aStatus === "Start" && bStatus === "Retake") {
    order = -1;
  } else if (bStatus === "Start" && aStatus === "Retake") {
    order = 1;
  } else if (aStatus === "Start" && bStatus === "Start") {
    if (aUpdatedOn && bUpdatedOn) {
      order = aUpdatedOn - bUpdatedOn;
    } else {
      order = 0;
    }
  }

  if (aStatus === "Retake" && bStatus === "Retake") {
    if (aUpdatedOn && bUpdatedOn) {
      order = bUpdatedOn - aUpdatedOn;
    } else {
      order = 0;
    }
  }

  if (order === 0) {
    return a.order - b.order;
  }

  return order;
}

export function onMoreClick(id: string) {
  const el = document.getElementById(id);

  if (el) {
    el.scrollBy({ left: 100, behavior: "smooth" });
  }
}

export function onMoreClickExtended(id: string) {
  const el = document.getElementById(id);

  if (el) {
    el.scrollBy({ left: 380, behavior: "smooth" });
  }
}

export function isDateExpired(expirationTimestamp: number | undefined) {
  if (!expirationTimestamp) {
    return true;
  }
  const currentTimestamp = Date.now();
  return currentTimestamp > expirationTimestamp;
}

export async function getPublicProfileDataFromUid(
  uid: string | undefined | null
) {
  const publicProfileRef = doc(db, `/publicProfiles/${uid}`);
  const publicSnap = await getDoc(publicProfileRef);
  if (publicSnap.exists() && publicSnap.data()) {
    return publicSnap.data() as PublicProfile;
  }
  return null;
}

export async function getPublicProfileAnImageUrlDataFromUid(
  uid: string | undefined | null
) {
  const publicProfileRef = doc(db, `/publicProfiles/${uid}`);
  const getImage = getDownloadURL(
    ref(
      firebaseStorage,
      `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${uid}/cropped.jpg`
    )
  )
    .then((url) => {
      return url;
    })
    .catch(async (err) => {
      return await getDefaultProfilePhoto(uid as string).then((value) => {
        return value;
      });
    });
  const publicSnap = await getDoc(publicProfileRef)
    .then((res) => {
      if (res.exists()) {
        return res.data() as PublicProfile;
      } else {
        return null;
      }
    })
    .catch(() => {
      return null;
    });
  return Promise.all([getImage, publicSnap]);
}

export function getConnectionStatusFromUid(
  uid: string | undefined | null,
  friendRequestRecievedUidsList: string[],
  friendRequestsSentUidList: string[],
  friendUidList: string[],
  closeFriendUidList: string[],
  deepFriendUidList: string[]
) {
  if (uid) {
    if (deepFriendUidList.includes(uid)) {
      return "deep";
    } else if (closeFriendUidList.includes(uid)) {
      return "close";
    } else if (friendUidList.includes(uid)) {
      return "connected";
    } else if (friendRequestsSentUidList.includes(uid)) {
      return "sent";
    } else if (friendRequestRecievedUidsList.includes(uid)) {
      return "received";
    } else {
      return "unconnected";
    }
  }

  return null;
}

export function sortFlattenedAssessmentBar(
  a: DimensionDataType | PeerAssessmentEvent,
  b: DimensionDataType | PeerAssessmentEvent
): number {
  if (a.event === "Retake") {
    return 1;
  }
  if (b.event === "Retake") {
    return -1;
  }
  if ("assesseeUid" in a && "order" in b) {
    //a is PeerAssessmentEvent and b is DimensionDataType
    if (b.event === "Continue") {
      return 1;
    }
    if (b.event === "Start") {
      return -1;
    }
  }
  if ("assesseeUid" in b && "order" in a) {
    //b is PeerAssessmentEvent and a is DimensionDataType
    if (a.event === "Continue") {
      return -1;
    }
    if (a.event === "Start") {
      return 1;
    }
  }
  return 0;
}
