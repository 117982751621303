import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityLoveAttitudesIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9838 19.3507C38.7912 14.6831 36.6033 10.3204 32.9738 7.37218C29.072 4.1799 24.0008 2.78432 19.0125 3.53021C14.3313 4.22211 10.0048 6.42694 6.69686 9.8068C3.38668 13.1867 1.27381 17.5539 0.682063 22.2453C0.602244 22.9208 0.822925 23.5963 1.28306 24.0959C1.90522 24.816 2.89356 25.1021 3.80443 24.8253C4.71531 24.5486 5.37503 23.7605 5.49006 22.8175C5.98306 19.074 7.71327 15.6024 10.4061 12.952C13.0988 10.3039 16.5994 8.62918 20.3532 8.19523C23.8207 7.81994 27.2905 8.8684 29.9692 11.1013C32.4389 13.1396 33.9602 16.1043 34.1762 19.2964C34.3734 22.4418 33.3311 25.5381 31.2745 27.9256C29.2179 30.3133 26.3069 31.8027 23.1658 32.0748C21.0482 32.2578 18.9471 31.5706 17.3483 30.1726C15.8998 28.9224 15.0312 27.1328 14.9443 25.2235C14.8598 23.279 15.5665 21.3815 16.9023 19.9671C18.2404 18.5504 20.0927 17.7342 22.0413 17.7061C23.2245 17.7108 24.2481 18.534 24.5063 19.6881C24.6308 20.3847 24.4406 21.1001 23.9875 21.6442C23.5344 22.1884 22.8653 22.505 22.1563 22.5097H21.945C20.7337 22.5097 19.7524 23.4902 19.7524 24.7004V25.1226C19.7524 26.3329 20.7337 27.3133 21.945 27.3133C25.5205 27.3485 28.6077 24.8201 29.2718 21.3088C29.6263 19.2165 29.0394 17.0751 27.6684 15.4543C26.2949 13.8359 24.2807 12.9 22.156 12.9023C18.9586 12.9023 15.8924 14.176 13.6363 16.4394C11.3825 18.7028 10.1218 21.7708 10.1359 24.9653C10.1453 28.3194 11.6032 31.5073 14.1387 33.7074C16.477 35.7761 19.4913 36.9185 22.614 36.9208C23.1281 36.9208 23.6446 36.8903 24.1564 36.8317C28.3751 36.3133 32.2441 34.2281 34.993 30.9889C37.7398 27.7497 39.1648 23.5937 38.984 19.3502L38.9838 19.3507Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
