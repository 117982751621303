export default function YourPurposeSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8284 12.8257L23.7093 21.1189L32.0024 24.9997L23.7093 28.8805L19.8284 37.1737L15.9476 28.8805L7.65446 24.9997L15.9476 21.1189L19.8284 12.8257Z"
        fill="#FFFFF2"
      />
      <path
        d="M37.2659 34.1629L34.8884 29.0807L32.5109 34.1629L27.4288 36.5404L32.5109 38.9178L34.8884 44L37.2659 38.9178L42.348 36.5404L37.2659 34.1629Z"
        fill="#ED85C2"
      />
      <path
        d="M37.2659 11.0823L34.8884 6.00018L32.5109 11.0823L27.4288 13.4598L32.5109 15.8373L34.8884 20.9195L37.2659 15.8373L42.348 13.4598L37.2659 11.0823Z"
        fill="#E0CC65"
      />
    </svg>
  );
}
