export default function UpArrowIcon(props: { dark?: boolean }) {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.37401 5.8439L4.74645 2.47303L8.11889 5.8439C8.44065 6.14371 8.94221 6.13503 9.25376 5.82398C9.5648 5.51243 9.57348 5.01087 9.27368 4.68911L5.32411 0.739539V0.739028C5.00489 0.420324 4.48802 0.420324 4.16881 0.739028L0.219238 4.6886V4.68911C-0.0805725 5.01087 -0.0718907 5.51243 0.239156 5.82398C0.550708 6.13502 1.05224 6.14371 1.37401 5.8439Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
      />
    </svg>
  );
}
