import { useEffect, useState } from "react";
import { Roboto_Mono } from "next/font/google";

import styles from "./TraitScoreTable.module.css";

import dimensionJson from "../../../../assets/traitInfo/dimensions.json";

type Props = {
  type: "Archetype" | "Pattern" | "Element";
  archetypeType?: string;
  childElementCount?: number;
  dimension?: string;
  nature?: string;
  color?: string;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function TraitDescription(props: Props) {
  const [dimensionName, setDimensionName] = useState<string | null>(null);

  useEffect(() => {
    if (props.dimension) {
      dimensionJson.forEach((dim) => {
        if (dim.slug === props.dimension) {
          setDimensionName(dim.title);
        }
      });
    }
  }, [props.dimension]);

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              Trait Type
            </h3>
          </div>
          <div className={styles.rowChild}>
            <h3 style={{ marginLeft: "0" }} className={font.className}>
              {props.type}
            </h3>
          </div>
        </div>
        {props.dimension && (
          <div className={styles.row}>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Dimension
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                {dimensionName}
              </h3>
            </div>
          </div>
        )}
        {props.nature && (
          <div className={styles.row}>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Nature
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3
                style={
                  props.color
                    ? { color: props.color, marginLeft: "0" }
                    : { marginLeft: "0" }
                }
                className={font.className}
              >
                {props.nature}
              </h3>
            </div>
          </div>
        )}
        {props.childElementCount && (
          <div className={styles.row}>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                No. of child elements
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                {props.childElementCount}
              </h3>
            </div>
          </div>
        )}
        {props.archetypeType && (
          <div className={styles.row}>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                Archetype type
              </h3>
            </div>
            <div className={styles.rowChild}>
              <h3 style={{ marginLeft: "0" }} className={font.className}>
                {props.archetypeType}
              </h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
