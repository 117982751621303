import styles from "./SystemDialog.module.css";

type Props = {
  title?: string;
  body: string;
  cancelText: string;
  approveText: string;
  onCancel: () => void;
  onApprove: () => void;
};

export default function SystemDialog(props: Props) {
  return (
    <main onClick={() => props.onCancel()} className={styles.main}>
      <div onClick={(e) => e.stopPropagation()} className={styles.wrapper}>
        {props.title && <h1 className={styles.title}>{props.title}</h1>}
        <p className={styles.body}>{props.body}</p>
        <div className={styles.btnWrapper}>
          <button onClick={() => props.onCancel()} className={styles.btnCancel}>
            {props.cancelText}
          </button>
          <button
            onClick={() => props.onApprove()}
            className={styles.btnApprove}
          >
            {props.approveText}
          </button>
        </div>
      </div>
    </main>
  );
}
