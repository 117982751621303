import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DiscoverablePersonalityAnalysis } from "@dimensional-engineering/dimensional-models";

import { RootState } from "@/redux/store";
import styles from "./AnalysisMap.module.css";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { useModalContext } from "@/context/ModalContext";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import ShowAllOrLessButton from "@/components/shared/ShowAllOrLessButton/ShowAllOrLessButton";
import FilterBar from "@/components/shared/FilterBar/FilterBar";
import { Mixpanel } from "@/helpers/mixpanel";
import InvitePill from "@/components/appHome/InvitePill/InvitePill";
import { analysisIconMap } from "../AnalysisData";

export default function AnalysisMap(props: {
  setModalSlug: (slug: string) => void;
  onDiscoveryGate: (
    slug: string[],
    numSuccessfulInvitesRequired?: number | null | undefined
  ) => void;
}) {
  const discoveredSlugs = useGetDiscoveredPersonalityAnalysisSlugs();
  const personalityState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );
  const userState = useSelector((state: RootState) => state.user);
  const [filter, setFilter] = useState<"ALL" | "IDENTITY" | "LOVE" | "WORK">(
    "ALL"
  );
  const [expanded, setExpanded] = useState<boolean>(false);
  const { setModalComponent } = useModalContext();

  function filterMappedAnalysis(
    filterType: "ALL" | "IDENTITY" | "LOVE" | "WORK",
    a: DiscoverablePersonalityAnalysis
  ) {
    if (filterType === "ALL") {
      return a;
    } else {
      return a.analysisContext === filterType.toLowerCase();
    }
  }

  const copied = personalityState.discoverablePersonalAnalysis?.slice();
  const mapped = copied
    ?.filter((a) => filterMappedAnalysis(filter, a))
    .filter((a) => {
      const blockedList = [
        "pa-entrepreneurialism",
        "pa-attachment-style",
        "pa-conflict-styles",
      ];
      return !blockedList.includes(a.analysisSlug);
    })
    ?.sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      } else {
        return 0;
      }
    })
    .slice(...(expanded ? [] : [0, 12]))
    .map((analysis) => {
      if (discoveredSlugs.includes(analysis.analysisSlug)) {
        return (
          <div
            onClick={() => {
              props.setModalSlug(analysis.analysisSlug);
            }}
            className={styles.main}
            key={analysis.analysisSlug}
          >
            <div className={styles.analysisIconDiv}>
              {
                analysisIconMap[
                  analysis.analysisSlug as keyof typeof analysisIconMap
                ]
              }
            </div>
            <h1 className={styles.title}>{analysis.name}</h1>
          </div>
        );
      } else {
        return (
          <div style={{ position: "relative" }} key={analysis.analysisSlug}>
            {analysis.isPremium &&
              !analysis.discoveryGate.numSuccessfulInvitesRequired &&
              !userState.isPremium && (
                <div className={styles.premiumLogo}>
                  <PremiumPillIcon />
                </div>
              )}
            {analysis.discoveryGate.numSuccessfulInvitesRequired &&
              userState.inviteLevel <
                analysis.discoveryGate.numSuccessfulInvitesRequired && (
                <div className={styles.premiumLogo}>
                  <InvitePill
                    invitesRequired={
                      analysis.discoveryGate.numSuccessfulInvitesRequired
                    }
                  />
                </div>
              )}
            <div
              onClick={() => {
                if (analysis.discoveryGate.numSuccessfulInvitesRequired) {
                  props.onDiscoveryGate(
                    analysis.discoveryGate.requiredDimensionSlugs as string[],
                    analysis.discoveryGate.numSuccessfulInvitesRequired
                  );
                } else if (analysis.isPremium && !userState.isPremium) {
                  setModalComponent(<ConversionModal initialSlide={3} />);
                  Mixpanel?.track("Premium Conversion Modal Summoned", {
                    personality_analysis_slug: analysis.analysisSlug,
                  });
                } else {
                  props.onDiscoveryGate(
                    analysis.discoveryGate.requiredDimensionSlugs as string[]
                  );
                }
              }}
              className={styles.mainBlacked}
            >
              <div className={styles.analysisIconDiv}>
                {
                  analysisIconMap[
                    analysis.analysisSlug as keyof typeof analysisIconMap
                  ]
                }
              </div>
              <h1 className={styles.title}>{analysis.name}</h1>
            </div>
          </div>
        );
      }
    });

  return (
    <div className={styles.mainDiv}>
      <div className={styles.filterDiv}>
        <FilterBar
          onFilter={(f) => setFilter(f as "ALL" | "IDENTITY" | "LOVE" | "WORK")}
          filters={["ALL", "IDENTITY", "LOVE", "WORK"]}
          currentFilter={filter}
        />
      </div>
      <div className={styles.wrapper}>{mapped}</div>
      {mapped && mapped?.length >= 12 && (
        <div className={styles.bottomButtonDiv}>
          <ShowAllOrLessButton
            onClick={(value) => {
              setExpanded(value);
            }}
          />
        </div>
      )}
    </div>
  );
}

export function useGetDiscoveredPersonalityAnalysisSlugs() {
  const [discoveredSlugs, setDiscoveredSlugs] = useState<string[]>([]);

  const personalityState = useSelector(
    (state: RootState) => state.personalityAnalyis
  );

  useEffect(() => {
    if (personalityState.discoveredPersonalAnalysis?.length) {
      personalityState.discoveredPersonalAnalysis.forEach((s) => {
        if (s.analysisDetail !== null) {
          setDiscoveredSlugs((current) => [...current, s.analysisSlug]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalityState.discoveredPersonalAnalysis?.length]);

  return discoveredSlugs;
}
