import { useSelector } from "react-redux";
import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import { snippetMappedSubtitles } from "./snippetsMap";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetLlmHeaderedListView(props: Props) {
  const userState = useSelector((state: RootState) => state.user);

  const mapped = props.snippet.snippetDetail.llmHeaderedLists?.map(
    (row, index) => {
      return (
        <div className={styles.headerdListDiv} key={index}>
          <p
            className={styles.headeredListPart}
            style={{
              color: userState.publicProfileData?.primaryNature?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                : "#55504F",
            }}
          >
            PART {index + 1}
          </p>
          <h3 className={styles.headeredListHeader}>{row.header}</h3>
          <div
            className={styles.lineHeader}
            style={{
              backgroundColor: userState.publicProfileData?.primaryNature
                ?.nature
                ? ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                : "#55504F",
            }}
          ></div>
          {row.llmContent.map((r, i) => {
            return (
              <div className={styles.headeredLlmContentDiv} key={i}>
                <p style={{ color: "#C0B9B7" }}>~{i + 1}~</p>
                <h4
                  style={{
                    marginTop: "16px",
                    textAlign: "center",
                    fontSize: "26px",
                  }}
                  className={styles.llmListTitleContent}
                >
                  {r.title}
                </h4>
                <p className={styles.llmListParagraphContent}>{r.paragraph}</p>
              </div>
            );
          })}
        </div>
      );
    }
  );

  return (
    <>
      {props.snippet.snippetDetail.llmHeaderedLists && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          {mapped}
        </>
      )}
    </>
  );
}
