import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function ValuesIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51584 8.92718C6.44643 7.93316 7.26239 7.11829 8.26573 7.11829H71.678C72.6813 7.11829 73.4973 7.93316 73.4279 8.92718C72.3018 25.051 58.4428 37.8824 41.2764 38.5038C41.4151 38.6055 41.5421 38.7296 41.6521 38.876L64.6745 69.5105C65.5623 70.6918 64.7134 72.3731 63.229 72.3731H17.1842C15.6998 72.3731 14.8508 70.6918 15.7387 69.5105L38.7611 38.876C38.8663 38.736 38.987 38.6164 39.1186 38.5173C21.7444 38.1067 7.65173 25.1921 6.51584 8.92718Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
