import { db } from "@/_firebase/firebaseConfig";
import { DeliveredDailyInsight } from "@dimensional-engineering/dimensional-models";
import { doc, updateDoc } from "firebase/firestore";
import moment from "moment";

export function formatDailyInsightTime(seconds: number): string {
  const hours = Math.floor((seconds % (24 * 3600)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}h:${minutes}m:${remainingSeconds}s`;
}

export function formatDeliveredOnDate(deliveredOn: number | null | undefined) {
  const created = moment(deliveredOn).format("MMM D, YYYY");
  return created;
}

export async function updateInsightsFeedback(
  deliveredDailyInsight: DeliveredDailyInsight | null,
  uid: string,
  value: number
) {
  if (deliveredDailyInsight && uid) {
    const ref = doc(
      db,
      "members",
      `${uid}`,
      "deliveredDailyInsights",
      deliveredDailyInsight?.deliveryId
    );
    return await updateDoc(ref, {
      feedbackScore: value,
    });
  }
}

export function getListOfTraitRulesForMixpanel(
  deliveredDailyInsight: DeliveredDailyInsight | null
) {
  let list: string[] = [];
  deliveredDailyInsight?.assignmentRuleset.scaleRules.forEach((scale) => {
    const string = `${scale.slug} ${scale.direction}`;
    list.push(string);
  });
  if (deliveredDailyInsight?.assignmentRuleset.identityArchetypeSlug) {
    list.push(deliveredDailyInsight?.assignmentRuleset.identityArchetypeSlug);
  }
  if (deliveredDailyInsight?.assignmentRuleset.interactionArchetypeSlug) {
    list.push(
      deliveredDailyInsight?.assignmentRuleset.interactionArchetypeSlug
    );
  }

  return list;
}

export function getCountOfTraitRulesForMixpanel(
  deliveredDailyInsight: DeliveredDailyInsight | null
) {
  let list: string[] = [];
  deliveredDailyInsight?.assignmentRuleset.scaleRules.forEach((scale) => {
    const string = `${scale.slug} ${scale.direction}`;
    list.push(string);
  });
  if (deliveredDailyInsight?.assignmentRuleset.identityArchetypeSlug) {
    list.push(deliveredDailyInsight?.assignmentRuleset.identityArchetypeSlug);
  }
  if (deliveredDailyInsight?.assignmentRuleset.interactionArchetypeSlug) {
    list.push(
      deliveredDailyInsight?.assignmentRuleset.interactionArchetypeSlug
    );
  }

  return list.length;
}
