import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function InterestsIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8707 21.2049C51.629 19.6413 52.054 17.8882 52.054 16.0365C52.054 9.45004 46.6775 4.11069 40.0452 4.11069C33.4129 4.11069 28.0363 9.45004 28.0363 16.0365C28.0363 18.1679 28.5994 20.1688 29.5858 21.9003C29.1494 21.2466 28.6412 20.6263 28.0611 20.0502C23.3756 15.3971 15.7788 15.3971 11.0933 20.0502C6.40772 24.7034 6.40772 32.2476 11.0933 36.9008C13.1706 38.9638 15.8203 40.1121 18.5347 40.3458C15.8209 40.5799 13.172 41.7282 11.0951 43.7907C6.40952 48.4439 6.40952 55.9881 11.0951 60.6413C15.7806 65.2944 23.3774 65.2944 28.0629 60.6413C28.3669 60.3394 28.6511 60.0253 28.9157 59.7007C28.3504 61.0842 28.0391 62.597 28.0391 64.1819C28.0391 70.7683 33.4156 76.1077 40.0479 76.1077C46.6802 76.1077 52.0567 70.7684 52.0567 64.1819C52.0567 62.7129 51.7893 61.3059 51.3 60.0063C51.4889 60.2228 51.6868 60.4342 51.894 60.6399C56.5795 65.293 64.1763 65.293 68.8618 60.6399C73.5474 55.9867 73.5474 48.4425 68.8618 43.7893C66.7875 41.7293 64.1425 40.5813 61.4321 40.3453C64.1423 40.1092 66.7871 38.9612 68.8614 36.9013C73.5469 32.2481 73.5469 24.7039 68.8614 20.0508C64.1758 15.3976 56.5791 15.3976 51.8935 20.0508C51.5233 20.4184 51.1824 20.8041 50.8707 21.2049ZM59.3232 40.3453C56.6129 40.1093 53.9679 38.9613 51.8935 36.9013C48.1921 33.2255 47.4147 27.7456 49.5612 23.3119C47.3653 26.1402 43.9195 27.9623 40.0452 27.9623C35.8614 27.9623 32.1774 25.8376 30.0274 22.6155C32.6141 27.159 31.9587 33.0301 28.0611 36.9008C25.9842 38.9633 23.3353 40.1116 20.6215 40.3457C23.3359 40.5794 25.9855 41.7277 28.0629 43.7907C31.5755 47.279 32.4548 52.392 30.7009 56.6938C32.9024 53.9871 36.2716 52.2561 40.0479 52.2561C43.6792 52.2561 46.934 53.8567 49.1361 56.3863C47.5451 52.15 48.4644 47.1952 51.894 43.7893C53.9682 41.7294 56.613 40.5814 59.3232 40.3453Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
