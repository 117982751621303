import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function PoliticalIdeologyIcon(
  props: DimensionIconAssessmentStatus
) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 98 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0657 22.5328C45.0657 29.5713 41.8386 36.1992 36.7834 40.6295C32.8143 44.1079 28.9825 48.2757 28.3152 53.511L28.1686 54.6617C27.4277 60.4747 31.1664 65.7999 35.2971 69.9566C36.1293 70.7939 36.896 71.6963 37.5891 72.6554C39.1939 74.876 41.6039 76.5401 44.3437 76.5401H46.56C50.2968 76.5401 52.9343 72.766 52.9343 69.0292C52.9343 56.5846 63.0226 45.0657 75.4672 45.0657C87.9117 45.0657 98 56.5846 98 69.0292C98 81.4737 87.9117 91.562 75.4672 91.562C74.6114 91.562 73.7668 91.5143 72.9358 91.4214C70.8387 91.187 68.745 90.8467 66.6348 90.8467H62.4353C50.2922 90.8467 39.9326 102.663 28.0784 105.296C26.3764 105.674 24.5262 105.869 22.5328 105.869C10.0883 105.869 0 95.7803 0 83.3358C0 75.9727 2.40479 70.3109 6.94632 66.9149C12.0335 63.1108 17.3143 57.9351 16.5112 51.6339C15.9906 47.5489 13.0596 44.274 9.75407 41.8182C3.86177 37.4407 0 30.2324 0 22.5328C0 10.0883 10.0883 0 22.5328 0C34.9774 0 45.0657 10.0883 45.0657 22.5328Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
