import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import styles from "./Snippets.module.css";
import { snippetMappedSubtitles, lowerCaseSnippetTitles } from "./snippetsMap";
import { toSentenceCase } from "@/components/shared/utils";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetLlmListDetailView(props: Props) {
  const mapped = props.snippet.snippetDetail.llmListDetail?.map((d, i) => {
    return (
      <div className={styles.llmListDiv} key={i}>
        <p className={styles.tileNumber}>~ {i + 1} ~</p>
        <h4 className={styles.llmListTitle}>
          {lowerCaseSnippetTitles.includes(props.snippet?.snippetSlug)
            ? toSentenceCase(d.title)
            : d.title}
        </h4>
        <p className={styles.llmListParagraph}>{d.paragraph}</p>
      </div>
    );
  });

  return (
    <>
      {props.snippet.snippetDetail.llmListDetail && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          {mapped}
        </>
      )}
    </>
  );
}
