import styles from "./Wrapper.module.css";

type Props = {
  children: string | JSX.Element | JSX.Element[] | any;
  lowMargin?: boolean;
};

export default function TraitsWrapper({ children, lowMargin }: Props) {
  return (
    <div
      style={lowMargin ? { marginTop: "30px" } : {}}
      className={styles.parent}
    >
      {children}
    </div>
  );
}
