import { useEffect } from "react";

const useHapticFeedback = () => {
  useEffect(() => {
    const handleClick = (event: any) => {
      if (event.target.tagName === "BUTTON") {
        if (window.Android) {
          window.Android.onHaptic();
        }
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
};

export default useHapticFeedback;
