export default function InformationIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C15.5476 0 20 4.4524 20 10C20 15.5476 15.5476 20 10 20C4.4524 20 0 15.5476 0 10C0 4.4524 4.4524 0 10 0ZM8.61328 8.3946C8.24844 8.3946 7.8836 8.02976 7.8836 7.59148C7.8836 7.1532 8.24844 6.8618 8.61328 6.8618H10.0734C10.5117 6.8618 10.8766 7.15398 10.8766 7.59148V14.4525H11.3875C11.8258 14.4525 12.1906 14.8173 12.1906 15.2556C12.1906 15.6204 11.8258 15.9853 11.3875 15.9853H8.7593C8.32102 15.9853 7.95618 15.6204 7.95618 15.2556C7.95618 14.8173 8.32102 14.4525 8.7593 14.4525H9.27024V8.39388H8.6132L8.61328 8.3946ZM9.8539 4.015C10.3648 4.015 10.8031 4.45328 10.8031 5.03688C10.8031 5.54782 10.3648 5.9861 9.8539 5.9861C9.2703 5.9861 8.90468 5.54782 8.90468 5.03688C8.90468 4.45328 9.26952 4.015 9.8539 4.015Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
