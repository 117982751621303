import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { RootState } from "@/redux/store";

export default function useShowPremiumPill(
  isContentPremium: boolean | undefined | null,
  contentSlug: string | undefined | null
) {
  const router = useRouter();
  const isUserPremium = useSelector((state: RootState) => state.user.isPremium);

  const [showPemiun, setShowPremiun] = useState<boolean>(false);

  useEffect(() => {
    if (isContentPremium === true) {
      //el is premium
      if (router.pathname.includes("app")) {
        //authenticated
        if (isUserPremium) {
          //don't show pill if user is premium
          return setShowPremiun(false);
        } else {
          return setShowPremiun(true);
        }
      } else {
        //unauthenticated
        return setShowPremiun(true);
      }
    } else {
      //not premium el
      return setShowPremiun(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSlug]);

  return showPemiun;
}
