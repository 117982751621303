import { TwoByTwoChart } from "@dimensional-engineering/dimensional-models";
import styles from "./AnalysisTwoByTwo.module.css";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import Image from "next/image";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { Roboto_Mono } from "next/font/google";
const font = Roboto_Mono({
  weight: "400",
  subsets: ["latin"],
});
export default function AnalysisTwoByTwo(props: TwoByTwoChart) {
  const userState = useSelector((state: RootState) => state.user);

  return (
    <>
      <div className={styles.main}>
        <p className={styles.quadrantOne}>{props.quadrantData[0]}</p>
        <p className={styles.quadrantTwo}>{props.quadrantData[1]}</p>
        <p className={styles.quadrantThree}>{props.quadrantData[2]}</p>
        <p className={styles.quadrantFour}>{props.quadrantData[3]}</p>
        <p style={{ alignSelf: "center" }} className={styles.label}>
          {props.yAxis.highDescriptor}
        </p>
        <div className={styles.rowTop}>
          <div className={styles.leftTop}>
            <p className={styles.label}>{props.xAxis.lowDescriptor}</p>
          </div>
          <div className={styles.RightTop}>
            <p className={styles.label}>{props.xAxis.highDescriptor}</p>
          </div>
        </div>
        <div className={styles.rowBottom}>
          <div className={styles.leftBottom}></div>
          <div className={styles.rightBottom}></div>
        </div>
        <p style={{ alignSelf: "center" }} className={styles.label}>
          {props.yAxis.lowDescriptor}
        </p>
        <div className={styles.cicle}></div>
        <div
          style={
            userState.publicProfileData?.primaryNature?.nature
              ? {
                  border: `3px solid ${
                    ProfileColorMap[
                      userState?.publicProfileData.primaryNature?.nature
                    ]
                  }`,
                  left: `${props.xAxisScore * 100 - 7.5}%`,
                  bottom: `${props.yAxisScore * 100 - 7.5}%`,
                }
              : {
                  border: `3px solid #55504F`,
                  left: `${props.xAxisScore * 100 - 7.5}%`,
                  bottom: `${props.yAxisScore * 100 - 7.5}%`,
                }
          }
          className={styles.imageDiv}
        >
          <Image
            priority
            src={userState.imageURL ? userState.imageURL : "/default-image.png"}
            alt="Profile Image"
            fill
            style={{ borderRadius: "50%", border: "1px solid black" }}
          />
        </div>
        <div
          style={
            props.xAxisAverageUserScore && props.yAxisAverageUserScore
              ? {
                  left: `${props.xAxisAverageUserScore * 100 - 7.5}%`,
                  bottom: `${props.yAxisAverageUserScore * 100 - 7.5}%`,
                }
              : { display: "none" }
          }
          className={styles.averageScore}
        >
          <div className={styles.averageCircle}></div>
        </div>
      </div>
      <div className={styles.averageLabel}>
        <div
          style={{
            left: `${props.xAxisScore * 100 - 7.5}%`,
            bottom: `${props.yAxisScore * 100 - 7.5}%`,
          }}
          className={styles.averageCircle}
        ></div>
        <p className={font.className}>AVERAGE USER SCORE</p>
      </div>
    </>
  );
}
