import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityCognitionIcon(props: DimensionIconProps) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "41"}
      height={props.maxWidth ? "100%" : "41"}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0093 0.449317C20.0621 -0.149769 20.9378 -0.149774 20.9907 0.449312L22.2794 15.0627C22.3144 15.4594 22.7813 15.6528 23.0865 15.397L34.331 5.97506C34.792 5.58881 35.4112 6.20801 35.0249 6.66899L25.603 17.9135C25.3472 18.2187 25.5406 18.6856 25.9373 18.7206L40.5507 20.0093C41.1498 20.0621 41.1498 20.9378 40.5507 20.9907L25.9373 22.2794C25.5406 22.3144 25.3472 22.7813 25.603 23.0865L35.0249 34.331C35.4112 34.792 34.792 35.4112 34.331 35.0249L23.0865 25.603C22.7813 25.3472 22.3144 25.5406 22.2794 25.9373L20.9907 40.5507C20.9379 41.1498 20.0622 41.1498 20.0093 40.5507L18.7206 25.9373C18.6856 25.5406 18.2187 25.3472 17.9135 25.603L6.669 35.0249C6.20802 35.4112 5.5888 34.792 5.97506 34.331L15.397 23.0865C15.6528 22.7813 15.4594 22.3144 15.0627 22.2794L0.449317 20.9907C-0.149769 20.9379 -0.149774 20.0622 0.449312 20.0093L15.0627 18.7206C15.4594 18.6856 15.6528 18.2187 15.397 17.9135L5.97506 6.66899C5.5888 6.20802 6.20801 5.5888 6.66899 5.97506L17.9135 15.397C18.2187 15.6528 18.6856 15.4594 18.7206 15.0627L20.0093 0.449317Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
