import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LatestResult } from "@dimensional-engineering/dimensional-models";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

export interface LatestResultsSlice {
  latestResults: LatestResult[];
  lastResultPulled: QueryDocumentSnapshot<DocumentData> | null;
}

const initialState: LatestResultsSlice = {
  latestResults: [],
  lastResultPulled: null,
};

export const latestResultSlice = createSlice({
  name: "feed",
  initialState: initialState,
  reducers: {
    setLatestResults: (state, action: PayloadAction<LatestResult[]>) => {
      return { ...state, latestResults: action.payload };
    },
    updateLastResultPulled: (
      state,
      action: PayloadAction<QueryDocumentSnapshot<DocumentData>>
    ) => {
      return { ...state, lastResultPulled: action.payload };
    },
    paginateLatestResult: (state, action: PayloadAction<LatestResult>) => {
      return {
        ...state,
        latestResults: [...state.latestResults, action.payload],
      };
    },
  },
});

export const {
  setLatestResults,
  updateLastResultPulled,
  paginateLatestResult,
} = latestResultSlice.actions;

export default latestResultSlice.reducer;
