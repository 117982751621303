export default function CommentUnLikeIcon(props: { active?: boolean }) {
  if (props.active === true) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0804 15.8751C9.19054 15.8751 8.36632 15.4087 7.90858 14.6454L6.32108 12C6.21249 11.8195 6.01718 11.7086 5.80546 11.7086H4.17734C3.71562 11.7086 3.33046 11.7086 3.01562 11.682C2.6875 11.6539 2.37968 11.5937 2.09062 11.4398C1.6875 11.2258 1.3578 10.8961 1.14296 10.493C0.989838 10.2031 0.929682 9.89607 0.901562 9.56795C0.875 9.25311 0.875 8.86795 0.875 8.40623V6.45701C0.875 5.70779 0.875 5.08357 0.941406 4.58825C1.01172 4.06481 1.16641 3.59215 1.54609 3.21325C1.92577 2.83357 2.39843 2.67887 2.92109 2.60857C3.41719 2.54138 4.04141 2.54138 4.78985 2.54138H11.314C12.2179 2.54138 12.7969 2.54138 13.3125 2.68279C14.314 2.95857 15.1672 3.61561 15.6883 4.51403C15.9562 4.97731 16.1039 5.53747 16.3336 6.41091L16.8602 8.41411C17.2984 10.0789 16.043 11.7087 14.3212 11.7087H12.0946L12.3453 12.2095C13.1875 13.8939 11.9624 15.8751 10.0804 15.8751ZM8.98038 14.0024C9.21162 14.3883 9.6296 14.6251 10.0804 14.6251C11.0335 14.6251 11.6538 13.6219 11.2273 12.7688L10.8218 11.9579C10.4773 11.2688 10.9781 10.4587 11.7491 10.4587H14.3209C15.2233 10.4587 15.8811 9.60475 15.6514 8.73209L15.1498 6.82585C14.8858 5.82195 14.7795 5.43991 14.6061 5.14147C14.2498 4.52663 13.6662 4.07663 12.9811 3.88835C12.649 3.79695 12.2514 3.79148 11.2139 3.79148H6.2913V10.5235C6.74676 10.6469 7.14364 10.9422 7.39286 11.3571L8.98038 14.0024ZM5.04138 10.4586V3.79139H4.83356C4.02966 3.79139 3.4906 3.79295 3.08746 3.84686C2.70308 3.8992 2.53902 3.98827 2.43042 4.09686C2.32183 4.20545 2.23198 4.3703 2.18042 4.75468C2.12652 5.15702 2.12495 5.69686 2.12495 6.5V8.37968C2.12495 8.87422 2.12574 9.20702 2.14683 9.4625C2.16792 9.71016 2.20542 9.82812 2.24683 9.90546C2.34449 10.0891 2.49449 10.2391 2.67729 10.3367C2.75541 10.3781 2.87338 10.4156 3.12105 10.4359C3.37651 10.4578 3.70855 10.4586 4.20387 10.4586L5.04138 10.4586Z"
          fill="#FFFFF2"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0804 15.8751C9.19054 15.8751 8.36632 15.4087 7.90858 14.6454L6.32108 12C6.21249 11.8195 6.01718 11.7086 5.80546 11.7086H4.17734C3.71562 11.7086 3.33046 11.7086 3.01562 11.682C2.6875 11.6539 2.37968 11.5937 2.09062 11.4398C1.6875 11.2258 1.3578 10.8961 1.14296 10.493C0.989838 10.2031 0.929682 9.89607 0.901562 9.56795C0.875 9.25311 0.875 8.86795 0.875 8.40623V6.45701C0.875 5.70779 0.875 5.08357 0.941406 4.58825C1.01172 4.06481 1.16641 3.59215 1.54609 3.21325C1.92577 2.83357 2.39843 2.67887 2.92109 2.60857C3.41719 2.54138 4.04141 2.54138 4.78985 2.54138H11.314C12.2179 2.54138 12.7969 2.54138 13.3125 2.68279C14.314 2.95857 15.1672 3.61561 15.6883 4.51403C15.9562 4.97731 16.1039 5.53747 16.3336 6.41091L16.8602 8.41411C17.2984 10.0789 16.043 11.7087 14.3212 11.7087H12.0946L12.3453 12.2095C13.1875 13.8939 11.9624 15.8751 10.0804 15.8751ZM8.98038 14.0024C9.21162 14.3883 9.6296 14.6251 10.0804 14.6251C11.0335 14.6251 11.6538 13.6219 11.2273 12.7688L10.8218 11.9579C10.4773 11.2688 10.9781 10.4587 11.7491 10.4587H14.3209C15.2233 10.4587 15.8811 9.60475 15.6514 8.73209L15.1498 6.82585C14.8858 5.82195 14.7795 5.43991 14.6061 5.14147C14.2498 4.52663 13.6662 4.07663 12.9811 3.88835C12.649 3.79695 12.2514 3.79148 11.2139 3.79148H6.2913V10.5235C6.74676 10.6469 7.14364 10.9422 7.39286 11.3571L8.98038 14.0024ZM5.04138 10.4586V3.79139H4.83356C4.02966 3.79139 3.4906 3.79295 3.08746 3.84686C2.70308 3.8992 2.53902 3.98827 2.43042 4.09686C2.32183 4.20545 2.23198 4.3703 2.18042 4.75468C2.12652 5.15702 2.12495 5.69686 2.12495 6.5V8.37968C2.12495 8.87422 2.12574 9.20702 2.14683 9.4625C2.16792 9.71016 2.20542 9.82812 2.24683 9.90546C2.34449 10.0891 2.49449 10.2391 2.67729 10.3367C2.75541 10.3781 2.87338 10.4156 3.12105 10.4359C3.37651 10.4578 3.70855 10.4586 4.20387 10.4586L5.04138 10.4586Z"
        fill="#928E8C"
      />
    </svg>
  );
}
