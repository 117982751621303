import { ConnectionAvailability } from "@dimensional-engineering/dimensional-models";
import elementsJsonData from "../../assets/traitInfo/elements.json";
import patternsJsonData from "../../assets/traitInfo/patterns.json";

export function getUserScaleScore(
  percentScore: number | null | undefined,
  slug: string
): number | null | undefined {
  let returnNumber = percentScore;
  if (percentScore) {
    elementsJsonData.forEach((el) => {
      if (el.slug === slug) {
        const index = Math.round(percentScore * 100);
        if (el.percentileLookup) {
          returnNumber = el.percentileLookup[index];
        } else {
          returnNumber = percentScore;
        }
      }
    });
  }

  return returnNumber;
}

export function getUserPatternScore(
  percentScore: number | null | undefined,
  slug: string
): number | null | undefined {
  let returnNumber = percentScore;
  if (percentScore) {
    patternsJsonData.forEach((el) => {
      if (el.slug === slug) {
        const index = Math.round(percentScore * 100);
        if (el.percentileLookup) {
          returnNumber = el.percentileLookup[index];
        } else {
          returnNumber = percentScore;
        }
      }
    });
  }
  return returnNumber;
}

export function getUserScaleScoreText(score: number | null | undefined) {
  let text = "";
  if (score !== null && score !== undefined) {
    if (score <= 0.12) {
      text = "Very low";
    } else if (score > 0.12 && score <= 0.29) {
      text = "Low";
    } else if (score > 0.29 && score <= 0.69) {
      text = "Average";
    } else if (score > 0.69 && score <= 0.88) {
      text = "High";
    } else {
      text = "Very high";
    }
  }

  return text;
}

export function traitPermissionStatus(
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null,
  connectionAvailabilities: ConnectionAvailability[] | null | undefined,
  permission?: boolean,
  isPremium?: boolean | null | undefined
): null | "close" | "deep" | "private" | "basic" {
  //null means they have status
  if (connectionAvailabilities?.includes(ConnectionAvailability.private)) {
    return "private";
  }

  if (friendStatus === "unconnected") {
    if (connectionAvailabilities?.includes(ConnectionAvailability.basic)) {
      if (permission === true) {
        return null;
      } else {
        return "basic";
      }
    } else {
      if (connectionAvailabilities?.includes(ConnectionAvailability.close)) {
        return "close";
      } else if (
        connectionAvailabilities?.includes(ConnectionAvailability.deep)
      ) {
        return "deep";
      }
    }
  } else if (friendStatus === "connected") {
    if (connectionAvailabilities?.includes(ConnectionAvailability.basic)) {
      return null;
    } else {
      if (connectionAvailabilities?.includes(ConnectionAvailability.close)) {
        return "close";
      } else if (
        connectionAvailabilities?.includes(ConnectionAvailability.deep)
      ) {
        return "deep";
      }
    }
  } else if (friendStatus === "close") {
    if (connectionAvailabilities?.includes(ConnectionAvailability.close)) {
      return null;
    } else {
      if (connectionAvailabilities?.includes(ConnectionAvailability.deep)) {
        return "deep";
      } else {
        return "close";
      }
    }
  } else if (friendStatus === "deep") {
    if (connectionAvailabilities?.includes(ConnectionAvailability.deep)) {
      return null;
    } else {
      return "deep";
    }
  }
  return "private";
}

export function normalizeData(x: number, min: number, max: number): number {
  let returnNumber = x;
  if (x > 100) {
    returnNumber = 100;
  }
  if (x < 0) {
    returnNumber = 0;
  }

  let normalized = (returnNumber - min) / (max - min);

  if (normalized >= 1) {
    return 1;
  } else if (normalized <= 0) {
    return 0;
  } else {
    return normalized;
  }
}
