export default function DontVibeWithIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_42398_19805)">
        <path
          d="M9.00045 33.1846C8.95202 33.1846 8.90514 33.1877 8.79889 33.1924C8.77858 33.1908 8.67545 33.1846 8.64889 33.1846H8.64733C4.13653 33.2564 0.420927 35.9111 0.00532703 39.3598C-0.0149854 39.5223 0.036577 39.6863 0.144391 39.8082C0.252203 39.9301 0.408455 40.0004 0.572511 40.0004H17.0849C17.2474 40.0004 17.4037 39.9301 17.5115 39.8082C17.6208 39.6863 17.6708 39.5223 17.6521 39.3598C17.2365 35.9113 13.5209 33.2582 9.00045 33.1846Z"
          fill="#FFFFF2"
        />
        <path
          d="M8.72549 32.0406H8.94268C12.0692 31.9812 14.5647 29.4141 14.5347 26.2874C14.505 23.1593 11.9612 20.6406 8.83308 20.6406C5.70652 20.6406 3.16267 23.1594 3.13307 26.2874C3.10339 29.414 5.59872 31.981 8.72508 32.0406H8.72549Z"
          fill="#FFFFF2"
        />
        <path
          d="M31.3437 33.1846C31.2953 33.1846 31.2484 33.1877 31.1421 33.1924C31.1218 33.1908 31.0187 33.1846 30.9921 33.1846H30.9906C26.4798 33.2564 22.7642 35.9111 22.3486 39.3598C22.3298 39.5223 22.3798 39.6863 22.4892 39.8082C22.597 39.9301 22.7533 40.0004 22.9158 40.0004H39.4282C39.5922 40.0004 39.7485 39.9301 39.8563 39.8082C39.9641 39.6863 40.0157 39.5223 39.9954 39.3598C39.5797 35.9113 35.8657 33.2582 31.3437 33.1846Z"
          fill="#FFFFF2"
        />
        <path
          d="M25.4767 26.3576C25.4985 29.4529 27.9751 31.97 31.0703 32.0404H31.2874H31.2859C33.5765 31.992 35.6155 30.5763 36.4579 28.4451C37.3001 26.3154 36.7829 23.8871 35.1454 22.2843C33.5079 20.6827 31.069 20.2187 28.9578 21.1093C26.8469 21.9984 25.4751 24.067 25.4767 26.3576Z"
          fill="#FFFFF2"
        />
        <path
          d="M30.882 10.441C30.882 7.67087 29.7806 5.01513 27.8225 3.05947C25.8643 1.10129 23.2086 0 20.441 0C17.6734 0 15.0174 1.10134 13.0595 3.05947C11.1013 5.01536 10 7.6711 10 10.441C10 13.2089 11.1013 15.8646 13.0595 17.8225C15.0177 19.7807 17.6734 20.8797 20.441 20.8797C23.2089 20.8775 25.8624 19.7762 27.8208 17.8203C29.7767 15.8621 30.878 13.2086 30.8825 10.4405L30.882 10.441ZM20.4405 18.2715C17.2733 18.2715 14.4181 16.3627 13.2065 13.4378C11.993 10.5106 12.6637 7.14385 14.9045 4.90327C17.143 2.66475 20.5098 1.99407 23.4367 3.20756C26.3617 4.41881 28.2705 7.27414 28.2705 10.4415C28.266 14.7638 24.7624 18.2675 20.4399 18.2721L20.4405 18.2715Z"
          fill="#F3431C"
        />
        <path
          d="M23.9731 6.90797C23.7286 6.66348 23.3967 6.52441 23.0512 6.52441C22.7036 6.52441 22.3716 6.66348 22.1271 6.90797L20.4403 8.59473L18.7536 6.90797C18.2422 6.40553 17.4212 6.41002 16.9165 6.91694C16.4096 7.42163 16.4051 8.24257 16.9076 8.75397L18.5943 10.4407L16.9076 12.1275C16.6608 12.372 16.5195 12.7039 16.5195 13.0516C16.5195 13.3993 16.6564 13.7335 16.9008 13.978C17.1476 14.2247 17.4818 14.3616 17.8295 14.3616C18.1771 14.3593 18.5091 14.2202 18.7536 13.9735L20.4404 12.2868L22.1271 13.9735C22.6385 14.4782 23.4595 14.4737 23.9664 13.9668C24.4755 13.4599 24.4778 12.6389 23.9731 12.1275L22.2864 10.4408L23.9731 8.75401C24.2176 8.50728 24.3544 8.17532 24.3544 7.82987C24.3544 7.48445 24.2176 7.15247 23.9731 6.90797Z"
          fill="#F3431C"
        />
      </g>
      <defs>
        <clipPath id="clip0_42398_19805">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
