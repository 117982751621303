/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactMarkdown from "react-markdown";

import "swiper/css";
import styles from "./SwipeTabs.module.css";

import TraitsTab from "@/components/traits/shared/traitsTab/TraitsTab";
import { useRouter } from "next/router";

type SwipeTabsProps = {
  tabs: string[];
  content: JSX.Element[];
  slicedContent?: string[];
  urls?: string;
  grow?: boolean;
  noAutoHeight?: boolean;
  onProfile?: boolean;
  initialTab?: string;
  connectionTab?: JSX.Element[];
  sticky?: boolean;
  tabsWithBlueDot?: string[];
};

export default function SwipeTabs(props: SwipeTabsProps) {
  const [currentTab, setCurrentTab] = useState<string>(
    props.initialTab ?? props.tabs[0]
  );
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const router = useRouter();
  const { slug } = router.query;

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        if (swiperRef) {
          swiperRef?.updateAutoHeight(0.5);
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, props.tabs]);

  useEffect(() => {
    if (props.initialTab) {
      setCurrentTab(props.initialTab);
      if (swiperRef) swiperRef.slideTo(props.tabs.indexOf(props.initialTab));
    } else {
      if (swiperRef) swiperRef.slideTo(props.tabs.indexOf(props.tabs[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialTab]);

  return (
    <div className={styles.main}>
      <TraitsTab
        tabs={props.tabs}
        changeTab={(t) => {
          setCurrentTab(t);
          if (swiperRef) swiperRef.slideTo(props.tabs.indexOf(t));
        }}
        parentTab={currentTab}
        urls={props.urls}
        grow={props.grow}
        onProfile={props.onProfile}
        sticky={props.sticky}
        tabsWithBlueDot={props.tabsWithBlueDot}
      />
      <Swiper
        autoHeight={props.noAutoHeight ? false : true}
        slidesPerView={1}
        touchReleaseOnEdges={true}
        onSlideChange={(e) => {
          if (props.tabs[e.activeIndex].toLowerCase() === "details") {
            e.el.scrollTo({ top: e.el.offsetHeight, behavior: "auto" });
          }
          setCurrentTab(props.tabs[e.activeIndex]);
          localStorage.setItem("currentTab", props.tabs[e.activeIndex]);
          localStorage.setItem("currentTabUid", slug as string);
        }}
        onSwiper={setSwiperRef}
        scrollbar={{ draggable: true }}
        threshold={20}
        style={{ minHeight: "500px" }}
      >
        {props.content.map((el, index) => {
          return (
            <SwiperSlide key={index}>
              <div style={{ width: "100%", padding: "0 5px" }}>{el}</div>
            </SwiperSlide>
          );
        })}
        {props.slicedContent?.map((el, index) => {
          return (
            <SwiperSlide virtualIndex={index} key={index}>
              <div
                style={{ pointerEvents: "none" }}
                key={1}
                className={styles.markdownDiv}
              >
                <ReactMarkdown
                  transformLinkUri={(href: string) => {
                    return "";
                  }}
                  children={el}
                />
              </div>
            </SwiperSlide>
          );
        })}
        {props.connectionTab &&
          props.connectionTab?.map((el, index) => {
            return (
              <SwiperSlide key={index}>
                <div style={{ width: "100%" }}>{el}</div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}
