import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import styles from "./Snippets.module.css";
import { getPublicProfileFromSimilarConnection } from "./utils";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import CircularScoreBarSmall from "@/components/authenticatedProfile/tabs/AuthCompareTab/CircularScoreBar/CircularScoreBarSmall";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetSimilarityComparisonDetailView(props: Props) {
  const connectionProfiles = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );

  const mappedPeople =
    props.snippet.snippetDetail.similarityComparisonDetail?.similarConnections
      .slice(0, 5)
      .sort((a, b) => {
        if (props.snippet?.snippetSlug === "snippet-most-similar-connections") {
          return b.similarityScore - a.similarityScore;
        }
        return a.similarityScore - b.similarityScore;
      })
      .map((person, index) => {
        let profile = getPublicProfileFromSimilarConnection(
          person,
          connectionProfiles
        );
        return (
          <div className={styles.personDiv} key={index}>
            <SmallProfileImage
              medium
              imageUrl={profile?.imageUrl ?? ""}
              publicProfile={profile}
            />
            <div className={styles.nameDiv}>
              <p className={styles.name}>{profile?.name}</p>
              <p className={styles.username}>@{profile?.userName}</p>
            </div>
            <div className={styles.scoreBar}>
              <CircularScoreBarSmall score={person.similarityScore * 100} />
            </div>
          </div>
        );
      });
  return (
    <>
      {props.snippet.snippetDetail.similarityComparisonDetail && (
        <>
          <h2 className={styles.titleLarge}>
            {props.snippet.snippetDetail.similarityComparisonDetail.body}
          </h2>
          <div className={styles.peopleDiv}>{mappedPeople}</div>
        </>
      )}
    </>
  );
}
