import { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";

import { firebaseStorage } from "@/_firebase/firebaseConfig";
import { getDefaultProfilePhoto } from "@/context/utils";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

export default function useGetProfilePhoto(uid: string | null | undefined) {
  const [imageUrl, setImageUrl] = useState<string>("");
  const connections = useSelector((state: RootState) => state.connections);

  useEffect(() => {
    if (uid) {
      const connectionFound = connections.publicProfiles?.find(
        (p) => p.ownerUUID === uid
      );
      if (connectionFound) {
        return setImageUrl(connectionFound.imageUrl);
      }

      getDownloadURL(
        ref(
          firebaseStorage,
          `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${uid}/cropped.jpg`
        )
      )
        .then((url) => {
          setImageUrl(url);
        })
        .catch(async (err) => {
          return await getDefaultProfilePhoto(uid as string)
            .then((value) => {
              return setImageUrl(value);
            })
            .catch((err) => {});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return imageUrl;
}
