import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import styles from "./Snippets.module.css";
import { snippetMappedSubtitles } from "./snippetsMap";
import { natureData } from "@/assets/traitInfo/natureData";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};
export default function SnippetLlmNaturedStatements(props: Props) {
  const mapped = props.snippet.snippetDetail.llmNatureStatements?.map(
    (statement, index) => {
      const color = natureData?.find((n) => n.slug === statement.nature)?.color;
      return (
        <p
          className={styles.natureStatement}
          style={color ? { color: color } : { color: "#928e8c" }}
          key={index}
        >
          {statement.body}
        </p>
      );
    }
  );

  return (
    <>
      {props.snippet.snippetDetail.llmNatureStatements && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <div className={styles.naturedStatementsDiv}>{mapped}</div>
        </>
      )}
    </>
  );
}
