import { useEffect, useState } from "react";

import { useAuth } from "@/context/AuthContext";
import useGetFriendStatus from "@/helpers/useGetFriendStatus";
import OwnPatternModal from "./OwnPatternModal";
import FriendPatternModal from "./FriendPatternModal";
import { Mixpanel } from "@/helpers/mixpanel";

export default function PatternModal(props: { slug: string; uid?: string }) {
  const [ownProfile, setOwnProfile] = useState<boolean>(true);
  const isFriend = useGetFriendStatus(props.uid);
  const { user } = useAuth();

  useEffect(() => {
    Mixpanel?.track("Trait clicked", { type: "pattern", slug: props.slug });
    Mixpanel?.track("Trait Viewed", {
      type: "pattern",
      slug: props.slug,
    });
  }, []);

  useEffect(() => {
    if (props.uid) {
      if (props.uid === user?.uid) {
        setOwnProfile(true);
      } else {
        setOwnProfile(false);
      }
    }
  }, [user?.uid, isFriend, props.uid]);

  if (ownProfile) {
    return <OwnPatternModal slug={props.slug} />;
  } else {
    return <FriendPatternModal slug={props.slug} uid={props.uid} />;
  }
}
