import { useEffect, useRef } from "react";
import {
  DiscoverableSnippet,
  DiscoveredSnippet,
  SnippetType,
} from "@dimensional-engineering/dimensional-models";
import html2canvas from "html2canvas";

import styles from "./SnippetsModalShareView.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import LogoWithText from "@/components/shared/LogoWithText";
import { snippetMappedSubtitles } from "../snippetsMap";
import { useModalContext } from "@/context/ModalContext";

type Props = {
  discoveredSnippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
  type: "instagram" | "snapchat";
};

export default function SnippetsModalShareView(props: Props) {
  const imageRef = useRef<HTMLDivElement>(null);

  const { setModalOpen } = useModalContext();

  useEffect(() => {
    const handleDownloadImage = async () => {
      if (imageRef.current) {
        const canvas = await html2canvas(imageRef.current);
        const data = canvas.toDataURL("image/jpg");
        if (window.Android) {
          window.Android.onHaptic();
          if (props.type === "instagram") {
            window.Android.shareToInstagramQuiz(data);
          } else {
            window.Android.shareToSnapChat(data);
          }
        }
      }
    };

    setTimeout(() => {
      handleDownloadImage();
      setModalOpen(false);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper noHeader noLine title="">
      <main
        style={{ backgroundColor: "#181716" }}
        ref={imageRef}
        className={styles.main}
      >
        <div className={styles.logoDiv}>
          <LogoWithText />
        </div>
        <div className={styles.titleDiv}>
          <h1 className={styles.title}>{props.discoverableSnippet.name}</h1>
          <p className={styles.description}>
            {
              snippetMappedSubtitles[
                props.discoverableSnippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </p>
        </div>
        {props.discoverableSnippet.snippetType ===
          SnippetType.llmOrderedMatch && <OrderedListShareView {...props} />}

        {props.discoverableSnippet.snippetType ===
          SnippetType.llmUnorderedMatch && (
          <UnOrderedListShareView {...props} />
        )}
      </main>
    </ModalWrapper>
  );
}

function OrderedListShareView(props: Props) {
  if (!props.discoveredSnippet.snippetDetail.llmMatch) {
    return <></>;
  }

  const firstChoiceData =
    props.discoveredSnippet.snippetDetail.llmMatch.matchGroups[0];

  const mappedAnalysis = firstChoiceData.analysis.map((a) => {
    return (
      <li className={styles.firstCardLi} key={a}>
        {a}
      </li>
    );
  });

  const otherMatchesMapped =
    props.discoveredSnippet.snippetDetail.llmMatch.matchGroups.map((d, i) => {
      if (i === 1 || i === 2) {
        return (
          <div className={styles.otherMAtchesMappedTextDiv} key={i}>
            <p className={styles.otherMAtchesMappedText}>
              {i + 1}. {d.emojiString}
            </p>
            <p className={styles.otherMAtchesMappedText}>{d.name}</p>
          </div>
        );
      }
    });

  return (
    <section className={styles.orderedMatchParentDiv}>
      <div className={styles.orderedMatchFirstCard}>
        <h2 className={styles.firstCardTitle}>
          {firstChoiceData.emojiString} {firstChoiceData.name}
        </h2>
        <p className={styles.firstCardDesc}>{firstChoiceData.description}</p>
        <p className={styles.whyText}>WHY:</p>
        <ul className={styles.firstCardUl}>{mappedAnalysis}</ul>
      </div>
      <p className={styles.otherMatchesText}>OTHER MATCHES:</p>
      <div className={styles.otherMAtchesMappedDiv}>{otherMatchesMapped}</div>
    </section>
  );
}

function UnOrderedListShareView(props: Props) {
  if (!props.discoveredSnippet.snippetDetail.llmMatch) {
    return <></>;
  }

  const mapped = props.discoveredSnippet.snippetDetail.llmMatch.matchGroups
    .slice(0, 10)
    .map((d, i) => {
      return (
        <div className={styles.orderedListTextDiv} key={i}>
          <p className={styles.orderedListText}>{d.emojiString}</p>
          <p className={styles.orderedListText}>{d.name}</p>
        </div>
      );
    });

  return <section className={styles.UnOrderedMatchParentDiv}>{mapped}</section>;
}
