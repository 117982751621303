export default function UknownQuestionMarkIcon(props: { dark?: boolean }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill={props.dark ? "#181716" : "#FFFFF2"} />
      <path
        d="M9.40764 11.158H8.17564C8.16631 11.046 8.16164 10.8827 8.16164 10.668C8.16164 9.73467 8.54897 9.00667 9.32364 8.484L9.93964 8.064C10.4343 7.728 10.6816 7.27067 10.6816 6.692C10.6816 6.244 10.537 5.866 10.2476 5.558C9.95831 5.25 9.54764 5.096 9.01564 5.096C8.44631 5.096 8.01231 5.27333 7.71364 5.628C7.41497 5.98267 7.26564 6.40267 7.26564 6.888C7.26564 7.168 7.29831 7.39667 7.36364 7.574L6.00564 7.406C5.94964 7.21 5.92164 6.986 5.92164 6.734C5.92164 6.27667 6.02431 5.838 6.22964 5.418C6.43497 4.998 6.78031 4.634 7.26564 4.326C7.75097 4.018 8.33431 3.864 9.01564 3.864C9.94897 3.864 10.691 4.144 11.2416 4.704C11.8016 5.264 12.0816 5.922 12.0816 6.678C12.0816 7.658 11.6056 8.47 10.6536 9.114L10.0096 9.548C9.59897 9.828 9.39364 10.276 9.39364 10.892C9.39364 10.976 9.39831 11.0647 9.40764 11.158ZM8.13364 13.804C7.95631 13.6267 7.86764 13.412 7.86764 13.16C7.86764 12.908 7.95631 12.6933 8.13364 12.516C8.31097 12.3293 8.52564 12.236 8.77764 12.236C9.02964 12.236 9.24431 12.3293 9.42164 12.516C9.60831 12.6933 9.70164 12.908 9.70164 13.16C9.70164 13.412 9.60831 13.6267 9.42164 13.804C9.24431 13.9813 9.02964 14.07 8.77764 14.07C8.52564 14.07 8.31097 13.9813 8.13364 13.804Z"
        fill={props.dark ? "#FFFFF2" : "#181716"}
      />
    </svg>
  );
}
