import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db } from "@/_firebase/firebaseConfig";
import {
  AggregatePeerScore,
  PeerAssessmentEvent,
  PeerAssessmentEventStatus,
  PeerAssessmentItem,
  PeerAssessmentItemResponse,
  PeerAssessmentLink,
  PeerAssessmentLinkStatus,
  PeerDichotomyItemSet,
  PeerDichotomyItemSetResponse,
  PeerRankOrderItemSet,
  PeerRankOrderItemSetResponse,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { FriendsPublicProfile } from "@/models/sharedModels";
import { Mixpanel } from "@/helpers/mixpanel";

import elementsJson from "../../assets/traitInfo/elements.json";

function getCorePeerAssessmentQuestionsPromises(): Promise<
  PeerAssessmentItem[] | PeerRankOrderItemSet | PeerDichotomyItemSet
>[] {
  let promises: Promise<
    PeerAssessmentItem[] | PeerRankOrderItemSet | PeerDichotomyItemSet
  >[] = [];

  const itemCollection = collection(db, "peerAssessmentItems");
  promises.push(
    getDocs(itemCollection).then((docs) => {
      let results: PeerAssessmentItem[] = [];
      docs.forEach((doc) => {
        results.push(doc.data() as PeerAssessmentItem);
      });
      return results;
    })
  );

  const rankOrderItemSetCollection = collection(db, "peerRankOrderItemSets");
  promises.push(
    getDocs(rankOrderItemSetCollection).then((docs) => {
      let result: PeerRankOrderItemSet[] = [];
      docs.forEach((doc) => {
        result.push(doc.data() as PeerRankOrderItemSet);
      });
      return result[0];
    })
  );

  const dichotomySetCollection = collection(db, "peerDichotomyItemSets");
  promises.push(
    getDocs(dichotomySetCollection).then((docs) => {
      let result: PeerDichotomyItemSet[] = [];
      docs.forEach((doc) => {
        result.push(doc.data() as PeerDichotomyItemSet);
      });
      return result[0];
    })
  );

  return promises;
}

export async function getCorePeerAssessmentQuestions() {
  return await Promise.all(getCorePeerAssessmentQuestionsPromises());
}

export async function initPeerAssessment(
  publicProfile: FriendsPublicProfile,
  uid: string,
  relationshipScore: number
) {
  const data: PeerAssessmentEvent = {
    assesseeUid: publicProfile.ownerUUID,
    peerAssessmentSlug: "peer-core",
    event: PeerAssessmentEventStatus.started,
    createdOn: Date.now(),
    relationshipScore: relationshipScore,
  };
  Mixpanel?.track("Peer Assessment Started", {});
  return await setDoc(
    doc(
      db,
      `members/${uid}/peerAssessmentEvents`,
      `${publicProfile.ownerUUID}-peer-core`
    ),
    data
  );
}

export async function submitCognitionResponses(
  peerDichotomyItemSetResponse: PeerDichotomyItemSetResponse,
  publicProfile: FriendsPublicProfile,
  uid: string
) {
  return await updateEventProgress(8 / 79, uid, publicProfile.ownerUUID)
    .then(async () => {
      return await setDoc(
        doc(
          db,
          `members/${uid}/peerDichotomyItemSetResponses/${publicProfile.ownerUUID}-${peerDichotomyItemSetResponse.slug}`
        ),
        peerDichotomyItemSetResponse
      );
    })
    .catch(async () => {
      return await setDoc(
        doc(
          db,
          `members/${uid}/peerDichotomyItemSetResponses/${publicProfile.ownerUUID}-${peerDichotomyItemSetResponse.slug}`
        ),
        peerDichotomyItemSetResponse
      );
    });
}

export async function submitValuesResponses(
  peerRankOrderItemSetResponse: PeerRankOrderItemSetResponse,
  publicProfile: FriendsPublicProfile,
  uid: string
) {
  await updateEventProgress(13 / 79, uid, publicProfile.ownerUUID)
    .then(async () => {
      return await setDoc(
        doc(
          db,
          `members/${uid}/peerRankOrderItemSetResponses/${publicProfile.ownerUUID}-${peerRankOrderItemSetResponse.slug}`
        ),
        peerRankOrderItemSetResponse
      );
    })
    .catch(async () => {
      return await setDoc(
        doc(
          db,
          `members/${uid}/peerRankOrderItemSetResponses/${publicProfile.ownerUUID}-${peerRankOrderItemSetResponse.slug}`
        ),
        peerRankOrderItemSetResponse
      );
    });
}

export function handlePeerItemAnswerScroll() {
  const el = document.getElementById("carouselContainer");
  const main = document.getElementById("mainAssessment");
  if (el && main) {
    main.style.overflow = "hidden";
    el.style.transition = "opacity 0.2s, top 0.2s"; // Add CSS transitions for opacity and top properties

    el.style.opacity = "0"; // Fade out the element

    setTimeout(() => {
      el.style.top = "-500px"; // Move the element up
    }, 50);

    setTimeout(() => {
      el.style.transition = "top 0.2s"; // Add a transition for the top property only
      el.style.top = "500px"; // Move the element down
    }, 100);

    setTimeout(() => {
      el.style.top = "0"; // Scroll the element back to its original position
      el.style.opacity = "1"; // Fade in the element
    }, 200);
  }
}

export function handlePeerItemAnswerUndoScroll() {
  const el = document.getElementById("carouselContainer");
  const main = document.getElementById("mainAssessment");
  if (el && main) {
    main.style.overflow = "hidden";
    el.style.transition = "opacity 0.2s, top 0.2s"; // Add CSS transitions for opacity and top properties

    el.style.opacity = "0"; // Fade out the element

    setTimeout(() => {
      el.style.top = "500px"; // Move the element up
    }, 50);

    setTimeout(() => {
      el.style.transition = "top 0.2s"; // Add a transition for the top property only
      el.style.top = "-500px"; // Move the element down
    }, 100);

    setTimeout(() => {
      el.style.top = "0"; // Scroll the element back to its original position
      el.style.opacity = "1"; // Fade in the element
      // main.style.overflow = "auto";
    }, 200);
  }
}

export async function handlePeerItemAnswerSubmission(
  publicProfile: FriendsPublicProfile,
  itemResponse: PeerAssessmentItemResponse,
  uid: string,
  itermResponseLength: number
) {
  return await updateEventProgress(
    (13 + itermResponseLength + 1) / 79,
    uid,
    publicProfile.ownerUUID
  )
    .then(async () => {
      return await setDoc(
        doc(
          db,
          `members/${uid}/peerAssessmentItemResponses/${publicProfile.ownerUUID}-${itemResponse.itemId}`
        ),
        itemResponse
      );
    })
    .catch(async () => {
      return await setDoc(
        doc(
          db,
          `members/${uid}/peerAssessmentItemResponses/${publicProfile.ownerUUID}-${itemResponse.itemId}`
        ),
        itemResponse
      );
    });
}

export async function completePeerAssessmentEvent(
  uid: string | undefined,
  assesseeUid: string | undefined,
  peerAssessmentSlug: string | undefined
) {
  const ref = doc(
    db,
    `members/${uid}/peerAssessmentEvents/${assesseeUid}-${peerAssessmentSlug}`
  );

  return await updateDoc(ref, {
    event: PeerAssessmentEventStatus.complete,
    progress: 1,
  });
}

const removeDuplicates = (
  items: PeerAssessmentItemResponse[]
): PeerAssessmentItemResponse[] => {
  const uniqueItems: Record<string, PeerAssessmentItemResponse> = {};

  items.forEach((item) => {
    uniqueItems[item.itemId] = item;
  });

  const uniqueItemsArray = Object.values(uniqueItems);

  return uniqueItemsArray;
};

export async function handlePeerAssessmentEnd(
  peerAssessmentItemResponses: PeerAssessmentItemResponse[],
  peerRankOrderItemSetResponses: (PeerRankOrderItemSetResponse | null)[],
  peerDichotomyItemSetResponses: (PeerDichotomyItemSetResponse | null)[],
  assesseeUid: string | undefined,
  peerAssessmentSlug: string | undefined,
  assesseeUsername: string | undefined,
  assessorUid: string | undefined,
  assessorUserName: string | undefined
) {
  const payload = {
    peerAssessmentItemResponses: removeDuplicates(peerAssessmentItemResponses),
    peerRankOrderItemSetResponses: peerRankOrderItemSetResponses,
    peerDichotomyItemSetResponses: peerDichotomyItemSetResponses,
    assesseeUid: assesseeUid,
    peerAssessmentSlug: peerAssessmentSlug,
    assessorUid: assessorUid,
    assessorUserName: assessorUserName,
  };

  Mixpanel?.track("Peer Assessment Complete", {
    assesseeUid: assesseeUid,
    assesseeUserName: assesseeUsername,
  });

  const call = callFirebaseFunctions("computePeerAssessment");

  return await call(payload);
}

export async function resetPeerAssessment(
  assesseeUid: string,
  peerAssessmentSlug: string
) {
  const call = callFirebaseFunctions("resetPeerAssessment");

  return await call({
    assesseeUid: assesseeUid,
    peerAssessmentSlug: peerAssessmentSlug,
  });
}

export async function fetchPeerAssessmentResult(
  assesseeUid: string,
  peerAssessmentSlug: string
) {
  const call = callFirebaseFunctions("fetchPeerAssessmentResult");
  return await call({
    assesseeUid: assesseeUid,
    peerAssessmentSlug: peerAssessmentSlug,
  });
}

export async function fetchPeerAssessmentDataToContinue(
  uid: string | undefined,
  assesseeUid: string | undefined
) {
  const dichotomyRef = doc(
    db,
    `members/${uid}/peerDichotomyItemSetResponses/${assesseeUid}-peer-dichotomy-core`
  );
  const rankOrderRef = doc(
    db,
    `members/${uid}/peerRankOrderItemSetResponses/${assesseeUid}-core-highest-scoring-traits`
  );

  const itemResponseRef = collection(
    db,
    `members/${uid}/peerAssessmentItemResponses`
  );
  const itemResponseQuery = query(
    itemResponseRef,
    where("assesseeUid", "==", assesseeUid)
  );

  const promises = [
    await getDoc(dichotomyRef)
      .then((res) => {
        if (res.exists()) {
          return res.data() as PeerDichotomyItemSetResponse;
        } else {
          return null;
        }
      })
      .catch(() => {
        return null;
      }),
    await getDoc(rankOrderRef)
      .then((res) => {
        if (res.exists()) {
          return res.data() as PeerRankOrderItemSetResponse;
        } else {
          return null;
        }
      })
      .catch(() => {
        return null;
      }),
    await getDocs(itemResponseQuery)
      .then((res) => {
        if (res.empty) {
          return [];
        } else {
          const items: PeerAssessmentItemResponse[] = [];
          res.docs.forEach((doc) => {
            items.push(doc.data() as PeerAssessmentItemResponse);
          });
          return items;
        }
      })
      .catch(() => {
        return [];
      }),
  ];

  return await Promise.all(promises);
}

export async function updateEventProgress(
  progress: number,
  uid: string | undefined,
  assesseeUid: string | undefined
) {
  const ref = doc(
    db,
    `members/${uid}/peerAssessmentEvents`,
    `${assesseeUid}-peer-core`
  );

  return await updateDoc(ref, { progress: progress });
}

export async function findPublicProfileFromSlug(
  slug: string,
  connections: [] | FriendsPublicProfile[]
): Promise<FriendsPublicProfile | null> {
  return new Promise((res, rej) => {
    const connectionUid = slug as string;
    const connectionPublicProfile = connections?.find(
      (c) => c.ownerUUID === connectionUid
    );

    if (connectionPublicProfile) {
      return res(connectionPublicProfile);
    } else {
      return rej(null);
    }
  });
}

export async function getPeerAssessmentLinkData(
  peerAssessmentLinkId: string
): Promise<null | PeerAssessmentLink> {
  const ref = doc(db, `/peerAssessmentLinks/${peerAssessmentLinkId}`);
  const peerLink = await getDoc(ref);
  if (peerLink.exists()) {
    const data: PeerAssessmentLink = peerLink.data() as PeerAssessmentLink;
    return data;
  } else {
    return null;
  }
}

export async function fetchPeerAssessmentLink(
  peerAssessmentLinkId: string
): Promise<null | PeerAssessmentLink> {
  const ref = doc(db, `/peerAssessmentLinks/${peerAssessmentLinkId}`);
  const peerLink = await getDoc(ref);
  if (peerLink.exists()) {
    const data: PeerAssessmentLink = peerLink.data() as PeerAssessmentLink;
    console.log("Link exists: ", data);
    if (data.linkStatus === PeerAssessmentLinkStatus.unclaimed) {
      try {
        const call = callFirebaseFunctions("claimPeerAssessmentLink");
        const res = await call({ linkId: peerAssessmentLinkId });
        console.log("claimPeerAssessmentLink result: ", res.data);
        return data;
      } catch (err) {
        console.log(err);
        return null;
      }
    } else {
      console.log("Already claimed");
      return data; // Return data if already claimed
    }
  } else {
    console.log("It doesn't exist");
    return null;
  }
}

export function getAggregatePeerPercentileScore(
  aggregatePeerScore: AggregatePeerScore
) {
  let returnNumber = aggregatePeerScore.percentScore;
  elementsJson.forEach((el) => {
    if (el.slug === aggregatePeerScore.traitSlug) {
      const index = Math.round(aggregatePeerScore.percentScore * 100);
      if (el.apsPercentileLookup) {
        returnNumber = el.apsPercentileLookup[index];
      } else {
        returnNumber = aggregatePeerScore.percentScore;
      }
    }
  });

  return returnNumber;
}
