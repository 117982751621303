import { useSelector } from "react-redux";
import {
  DiscoveredCompatibilitySnippet,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";

import styles from "../CompatibilitySnippets.module.css";

import { getElementFromTraitIdentifier } from "../utils";
import { useModalContext } from "@/context/ModalContext";
import { ExtendedScale, FriendsPublicProfile } from "@/models/sharedModels";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import { natureData } from "@/assets/traitInfo/natureData";
import { RootState } from "@/redux/store";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilityRankedListDetailView(props: Props) {
  const { setModalComponent } = useModalContext();

  function onTraitClick(trait: TraitIdentifier, connection: boolean) {
    if (trait.type === "element") {
      if (connection) {
        setModalComponent(
          <ElementModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<ElementModal slug={trait.slug} />);
      }
    } else {
      if (connection) {
        setModalComponent(
          <PatternModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<PatternModal slug={trait.slug} />);
      }
    }
  }

  const mappedMyTraits = [
    props.snippet?.snippetDetail.rankedListDetail?.myTopTraitIdentifiers,
    props.snippet?.snippetDetail.rankedListDetail?.myOtherTraitIdentifiers,
  ]
    .flat()
    .map((t, i) => {
      const element = getElementFromTraitIdentifier(
        t as TraitIdentifier
      ) as ExtendedScale;

      const borderColor = natureData?.find(
        (n) => n.slug === element.nature
      )?.color;

      return (
        <p
          onClick={() => {
            onTraitClick(t as TraitIdentifier, false);
          }}
          className={styles.yourTopTraitName}
          key={t?.slug}
          style={borderColor ? { borderColor: borderColor } : {}}
        >
          {i + 1}. {element.alias ?? element.name}
        </p>
      );
    });

  const mappedConnectionTraits = [
    props.snippet?.snippetDetail.rankedListDetail
      ?.connectionTopTraitIdentifiers,
    props.snippet?.snippetDetail.rankedListDetail
      ?.connectionOtherTraitIdentifiers,
  ]
    .flat()
    .map((t, i) => {
      const element = getElementFromTraitIdentifier(
        t as TraitIdentifier
      ) as ExtendedScale;

      const borderColor = natureData?.find(
        (n) => n.slug === element.nature
      )?.color;

      return (
        <p
          onClick={() => {
            onTraitClick(t as TraitIdentifier, true);
          }}
          className={styles.yourTopTraitName}
          key={t?.slug}
          style={borderColor ? { borderColor: borderColor } : {}}
        >
          {i + 1}. {element.alias ?? element.name}
        </p>
      );
    });

  return (
    <div className={styles.spectrumsParent}>
      <div className={styles.listViewHeader}>
        <h3 className={styles.listViewHeaderText}>Full rankings</h3>
      </div>
      <div className={styles.listViewSection}>
        <div>
          <h4 className={styles.yourTopHeading}>YOURS</h4>
          {mappedMyTraits}
        </div>
        <div>
          <h4 style={{ textAlign: "end" }} className={styles.yourTopHeading}>
            THEIRS
          </h4>
          <div className={styles.theirTraitDiv}>{mappedConnectionTraits}</div>
        </div>
      </div>
    </div>
  );
}

export function CompatibilityRankedListsDetailView(props: Props) {
  const { setModalComponent } = useModalContext();
  const username = useSelector((state: RootState) => state.user)
    .publicProfileData?.userName;

  function onTraitClick(trait: TraitIdentifier, connection: boolean) {
    if (trait.type === "element") {
      if (connection) {
        setModalComponent(
          <ElementModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<ElementModal slug={trait.slug} />);
      }
    } else {
      if (connection) {
        setModalComponent(
          <PatternModal slug={trait.slug} uid={props.connection?.ownerUUID} />
        );
      } else {
        setModalComponent(<PatternModal slug={trait.slug} />);
      }
    }
  }

  const mappedMyTraitsTop =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[0].myTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, false);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px" }
                : { fontSize: "12px" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Desiring", "")}
          </p>
        );
      }
    );

  const mappedTheirTraitsTop =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[0].connectionTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, true);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px" }
                : { fontSize: "12px" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Desiring", "")}
          </p>
        );
      }
    );

  const mappedMyTraitsBottom =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[1].myTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, false);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px" }
                : { fontSize: "12px" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Giving", "")}
          </p>
        );
      }
    );

  const mappedTheirTraitsBottom =
    props.snippet?.snippetDetail?.rankedListsDetails &&
    props.snippet?.snippetDetail?.rankedListsDetails[1].connectionTopTraitIdentifiers.map(
      (t, i) => {
        const element = getElementFromTraitIdentifier(
          t as TraitIdentifier
        ) as ExtendedScale;

        const borderColor = natureData?.find(
          (n) => n.slug === element.nature
        )?.color;

        return (
          <p
            onClick={() => {
              onTraitClick(t as TraitIdentifier, true);
            }}
            className={styles.yourTopTraitName}
            key={t?.slug}
            style={
              borderColor
                ? { borderColor: borderColor, fontSize: "12px" }
                : { fontSize: "12px" }
            }
          >
            {i + 1}.{" "}
            {element.highFunLabel ?? element.name.replace("Giving", "")}
          </p>
        );
      }
    );

  return (
    <div className={styles.spectrumsParent}>
      <div className={styles.listViewHeader}>
        <h3 className={styles.listViewHeaderText}>
          {props.snippet?.snippetDetail.rankedListsDetails &&
            props.snippet?.snippetDetail.rankedListsDetails[0].title}
        </h3>
      </div>
      <div className={styles.listViewSection}>
        <div>
          <h4
            style={{ marginBottom: "10px" }}
            className={styles.yourTopHeading}
          >
            @{username?.toUpperCase()}
          </h4>
          {mappedMyTraitsTop}
        </div>
        <div>
          <h4
            style={{ textAlign: "end", marginBottom: "10px" }}
            className={styles.yourTopHeading}
          >
            @{props.connection?.userName?.toUpperCase()}
          </h4>
          <div className={styles.theirTraitDiv}>{mappedTheirTraitsTop}</div>
        </div>
      </div>

      <div style={{ marginTop: "50px" }} className={styles.listViewHeader}>
        <h3 className={styles.listViewHeaderText}>
          <h3 className={styles.listViewHeaderText}>
            {props.snippet?.snippetDetail.rankedListsDetails &&
              props.snippet?.snippetDetail.rankedListsDetails[1].title}
          </h3>
        </h3>
      </div>
      <div className={styles.listViewSection}>
        <div>
          <h4
            style={{ marginBottom: "10px" }}
            className={styles.yourTopHeading}
          >
            @{username?.toUpperCase()}
          </h4>
          {mappedMyTraitsBottom}
        </div>
        <div>
          <h4
            style={{ textAlign: "end", marginBottom: "10px" }}
            className={styles.yourTopHeading}
          >
            @{props.connection?.userName?.toUpperCase()}
          </h4>
          <div className={styles.theirTraitDiv}>{mappedTheirTraitsBottom}</div>
        </div>
      </div>
    </div>
  );
}
