/* eslint-disable @next/next/no-img-element */
import { Archetype as ArchetypeModelNPM } from "@dimensional-engineering/dimensional-models";

import styles from "./Archetypes.module.css";

import useIsMobile from "@/helpers/useIsMobile";
import { archetypeMapper } from "@/assets/traitInfo/dimensionData";

type ArchetypeModel = {
  archetype: ArchetypeModelNPM;
  score?: number | null;
};

export default function Archetype(props: ArchetypeModel) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={styles.architypeBody}>
        <p className={styles.topText}>
          {archetypeMapper[props.archetype.dimensionSlug]
            ? archetypeMapper[props.archetype.dimensionSlug].toUpperCase()
            : ""}
        </p>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <clipPath id="ArchetypeImageClipPath">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.9573 47.7643C50.3091 47.7643 51.769 45.2071 50.5736 43.1819L28.1163 5.13656C26.9407 3.14491 24.0593 3.14491 22.8837 5.13656L0.426416 43.1819C-0.769037 45.2071 0.690953 47.7643 3.0427 47.7643H47.9573ZM25.5415 34.6925C27.5865 34.6925 29.2442 33.0347 29.2442 30.9898C29.2442 28.9449 27.5865 27.2872 25.5415 27.2872C23.4966 27.2872 21.8389 28.9449 21.8389 30.9898C21.8389 33.0347 23.4966 34.6925 25.5415 34.6925Z"
              fill="#FFFFF2"
            />
          </clipPath>
        </svg>
        <img
          alt="image"
          className="archetype-image"
          style={{
            clipPath: "url(#ArchetypeImageClipPath)",
            WebkitClipPath: "url(#ArchetypeImageClipPath)",
          }}
          src={`/archetypes/${props.archetype.slug}.jpg`}
          width="51px"
          height="51px"
        />
        <p className={styles.bottomText}>
          {props.archetype.archetypeGroupSlug !== "work"
            ? props.archetype.acronym ?? props.archetype.name
            : `${props.archetype.name} (${props.archetype.acronym})`}
        </p>
        {/* {props.score && <TraitScore score={props.score * 100} />} */}
      </div>
    );
  } else {
    return (
      <div className={styles.architypeBody}>
        <p className={styles.topText}>
          {archetypeMapper[props.archetype.dimensionSlug]
            ? archetypeMapper[props.archetype.dimensionSlug].toUpperCase()
            : ""}
        </p>
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <clipPath id="ArchetypeImageClipPath">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.9573 47.7643C50.3091 47.7643 51.769 45.2071 50.5736 43.1819L28.1163 5.13656C26.9407 3.14491 24.0593 3.14491 22.8837 5.13656L0.426416 43.1819C-0.769037 45.2071 0.690953 47.7643 3.0427 47.7643H47.9573ZM25.5415 34.6925C27.5865 34.6925 29.2442 33.0347 29.2442 30.9898C29.2442 28.9449 27.5865 27.2872 25.5415 27.2872C23.4966 27.2872 21.8389 28.9449 21.8389 30.9898C21.8389 33.0347 23.4966 34.6925 25.5415 34.6925Z"
              fill="#FFFFF2"
            />
          </clipPath>
        </svg>
        <img
          alt="image"
          style={{ clipPath: "url(#ArchetypeImageClipPath)" }}
          className="archetype-image"
          src={`/archetypes/${props.archetype.slug}.jpg`}
          width="51px"
          height="51px"
        />
        <p className={styles.bottomText}>
          {props.archetype.archetypeGroupSlug !== "work"
            ? props.archetype.acronym ?? props.archetype.name
            : `${props.archetype.name} (${props.archetype.acronym})`}
        </p>
        {/* {props.score && <TraitScore score={props.score * 100} />} */}
      </div>
    );
  }
}

export function AppArchetype(props: ArchetypeModel) {
  return (
    <div className={styles.architypeBodyApp}>
      <p className={styles.topText}>
        {archetypeMapper[props.archetype.dimensionSlug]
          ? archetypeMapper[props.archetype.dimensionSlug].toUpperCase()
          : ""}
      </p>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="ArchetypeImageClipPath">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.9573 47.7643C50.3091 47.7643 51.769 45.2071 50.5736 43.1819L28.1163 5.13656C26.9407 3.14491 24.0593 3.14491 22.8837 5.13656L0.426416 43.1819C-0.769037 45.2071 0.690953 47.7643 3.0427 47.7643H47.9573ZM25.5415 34.6925C27.5865 34.6925 29.2442 33.0347 29.2442 30.9898C29.2442 28.9449 27.5865 27.2872 25.5415 27.2872C23.4966 27.2872 21.8389 28.9449 21.8389 30.9898C21.8389 33.0347 23.4966 34.6925 25.5415 34.6925Z"
            fill="#FFFFF2"
          />
        </clipPath>
      </svg>
      <img
        alt="image"
        className="archetype-image"
        style={{
          clipPath: "url(#ArchetypeImageClipPath)",
          WebkitClipPath: "url(#ArchetypeImageClipPath)",
          top: "24px",
          left: "19px",
        }}
        src={`/archetypes/${props.archetype.slug}.jpg`}
        width="54px"
        height="47px"
      />
      <p className={styles.bottomTextApp}>
        {props.archetype.archetypeGroupSlug !== "work"
          ? props.archetype.acronym ?? props.archetype.name
          : `${props.archetype.name} (${props.archetype.acronym})`}
      </p>
      {/* {props.score && <TraitScore score={props.score * 100} />} */}
    </div>
  );
}
