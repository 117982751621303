import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./TraitsHeading.module.css";

export default function ArchetypeBreakdownHeading(props: {
  publicProfile?: PublicProfile | null;
}) {
  if (props.publicProfile && props.publicProfile.userName) {
    const displayedUsername =
      props.publicProfile?.userName.length > 8
        ? `${props.publicProfile?.userName.slice(0, 7)}...`
        : props.publicProfile?.userName;

    return (
      <h2 className={styles.headerProfile}>
        Score Breakdown: @{displayedUsername}
      </h2>
    );
  }

  return <h2 className={styles.headerNoProfile}>Archetype Breakdown</h2>;
}
