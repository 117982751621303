export default function DeepConnectionIcon(props: { active?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 13"
      fill="none"
      style={{ padding: "1px" }}
    >
      <g clipPath="url(#clip0_39792_5424)">
        <path
          d="M12.9966 3.44709C12.6851 2.38249 11.8025 1.70782 11.1274 1.47417C10.3743 1.21472 9.49178 1.42208 8.68714 2.07145C7.882 1.44837 6.99946 1.24051 6.24641 1.49997C5.57124 1.73363 4.6887 2.38251 4.37716 3.4729C4.1693 4.17386 4.16931 5.31634 5.2597 6.69198C5.72701 7.28926 6.45377 8.146 7.05105 8.82067C8.32301 10.2484 8.42719 10.2484 8.68664 10.2484H8.73872C8.94658 10.2484 9.15394 10.1447 10.3222 8.82067C10.9195 8.1455 11.6463 7.28877 12.1136 6.69198C13.2045 5.31634 13.2045 4.17435 12.9966 3.44709Z"
          fill={props.active ? "#2D2927" : "#55504F"}
        />
        <path
          d="M4.45491 7.31541C3.07878 5.60194 3.13087 4.12212 3.39032 3.1875C3.20875 3.1875 3.05298 3.23959 2.87092 3.29118C2.19575 3.52484 1.31322 4.17372 1.00168 5.26411C0.793817 5.96507 0.793817 7.10755 1.88421 8.48319C2.35152 9.08047 3.07828 9.93721 3.67556 10.6119C4.84384 11.9359 5.02541 12.0396 5.25906 12.0396H5.31115C5.51901 12.0396 5.70057 12.0396 7.1283 10.4298C6.89464 10.1962 6.63519 9.88462 6.29736 9.52099C5.67525 8.7952 4.94852 7.91269 4.45491 7.31541Z"
          fill={props.active ? "#2D2927" : "#55504F"}
        />
      </g>
      <defs>
        <clipPath id="clip0_39792_5424">
          <rect
            width="12.4616"
            height="12.4616"
            fill="white"
            transform="translate(0.769043 0.461426)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DeepConnectionIconSummaries() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM21.6625 9.31226C21.2125 7.77452 19.9377 6.8 18.9625 6.4625C17.8748 6.08774 16.6 6.38727 15.4377 7.32524C14.2748 6.42524 13 6.125 11.9123 6.49978C10.937 6.83728 9.66226 7.77454 9.21226 9.34954C8.91202 10.362 8.91202 12.0123 10.487 13.9993C11.162 14.862 12.2118 16.0995 13.0745 17.0741C14.9118 19.1363 15.0623 19.1363 15.437 19.1363H15.5123C15.8125 19.1363 16.112 18.9866 17.7995 17.0741C18.6623 16.0988 19.712 14.8613 20.387 13.9993C21.9627 12.0123 21.9627 10.3627 21.6625 9.31226ZM7.78702 8.9375C7.41226 10.2875 7.33702 12.425 9.32476 14.9C9.38945 14.9783 9.45692 15.06 9.52679 15.1445C10.227 15.9922 11.169 17.1326 11.9861 18.0858C12.1921 18.3076 12.378 18.516 12.5494 18.7081C12.7839 18.971 12.9913 19.2036 13.1863 19.3986C11.124 21.7238 10.8618 21.7238 10.5615 21.7238H10.4863C10.1488 21.7238 9.88654 21.574 8.19904 19.6615C7.3363 18.687 6.28654 17.4495 5.61154 16.5868C4.03654 14.5998 4.03654 12.9495 4.33678 11.937C4.78678 10.362 6.06154 9.42477 7.03678 9.08727C7.0682 9.07836 7.09908 9.06944 7.12955 9.06065C7.35406 8.99583 7.5561 8.9375 7.78702 8.9375Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
