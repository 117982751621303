import {
  ArchetypeDiscovery,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./ArchetypeTraitsPageLeftBar.module.css";

import { ExtendedArchetype } from "@/models/sharedModels";
import { RootState } from "@/redux/store";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import { traitPermissionStatus } from "../../utils";
import LargeArchetypeModal from "../Archetypes/LargeArchetype/LargeArchetypeModal";
import CartesianModal from "../../detailsTab/archetypes/cartesian/CartesianModal";
import DichotomiesModal from "../../detailsTab/archetypes/dichotomies/DichotomiesModal";
import CareerArchetypeBreakdown from "../../detailsTab/archetypes/CareerArchetypeBreakdown/CareerArchetypeBreakdown";
import ValuesArchetypeBreakdown from "../../detailsTab/archetypes/ValuesArchetypeBreakdown/ValuesArchetypeBreakdown";
import ArchetypeDescriptions from "../../detailsTab/archetypes/ArchetypeDescriptions/ArchetypeDescriptions";
import { AvailableToComponentAvailibility } from "@/components/shared/AvailableToComponent/AvailableToComponent";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import { archetypeMapper } from "@/assets/traitInfo/dimensionData";

type LeftBarProps = {
  archetype: ExtendedArchetype;
  archetypeDiscovery: ArchetypeDiscovery | undefined;
  nonOwnProfile?: boolean;
  friendScore?: number | null | undefined;
  publicProfile: PublicProfile | null;
};

export default function ArchetypeTraitsPageLeftBarModal(props: LeftBarProps) {
  const [score, setScore] = useState<number | null>();
  const userState = useSelector((state: RootState) => state.user);
  const friendStatus = useConnectionStatus(props.publicProfile?.ownerUUID);
  const imageUrl = useGetProfilePhoto(props.publicProfile?.ownerUUID);

  useEffect(() => {
    if (props.nonOwnProfile) {
      setScore(props.friendScore);
    } else {
      userState.archetypesDiscovered?.forEach((arch) => {
        if (arch.archetypeSlug === props.archetype.slug) {
          setScore(arch.archetypeScore);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.friendScore, props.nonOwnProfile]);

  if (!props.nonOwnProfile) {
    // own profile
    return (
      <div className={styles.leftBarModal}>
        <p className={styles.topText}>
          {archetypeMapper[props.archetype.dimensionSlug]} <br />
          Archetype
        </p>
        <LargeArchetypeModal archetype={props.archetype} />
        <div className={styles.defDivModal}>
          <p>{props.archetype.shortDescription}</p>
        </div>
        {props.archetype.archetypeGroupSlug === "work" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <CartesianModal
              archetype={props.archetype}
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
            />
          )}
        {props.archetype.archetypeGroupSlug === "identity" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <DichotomiesModal
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
              imageUrl={imageUrl}
            />
          )}
        {props.archetype.archetypeGroupSlug === "love" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <DichotomiesModal
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
              imageUrl={imageUrl}
            />
          )}
        {props.archetype.archetypeGroupSlug === "career" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <CareerArchetypeBreakdown
              publicProfile={props.publicProfile}
              own
              archetypeDiscovery={props.archetypeDiscovery}
            />
          )}
        {props.archetype.archetypeGroupSlug === "values" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <ValuesArchetypeBreakdown
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
            />
          )}
        {props.archetype.archetypeGroupSlug !==
          props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
          <div className={styles.ghostBox}>
            <SmallProfileImage
              noLink
              publicProfile={props.publicProfile}
              imageUrl={imageUrl}
            />
            <p className={styles.ghostBoxP}>
              Score breakdowns are only available on highest scoring Archetype.
            </p>
          </div>
        )}
        <ArchetypeDescriptions archetype={props.archetype} />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.archetype.connectionAvailabilities}
          />
        </div>
      </div>
    );
  } else {
    const status = traitPermissionStatus(
      friendStatus,
      props.archetype.connectionAvailabilities
    );
    ///connected profile
    return (
      <div className={styles.leftBarModal}>
        <p className={styles.topText}>
          {archetypeMapper[props.archetype.dimensionSlug]} <br />
          Archetype
        </p>
        <LargeArchetypeModal archetype={props.archetype} />
        <div className={styles.defDivModal}>
          <p>{props.archetype.shortDescription}</p>
        </div>
        {props.archetype.archetypeGroupSlug === "work" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <CartesianModal
              archetype={props.archetype}
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
            />
          )}
        {props.archetype.archetypeGroupSlug === "identity" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <DichotomiesModal
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
              imageUrl={imageUrl}
            />
          )}
        {props.archetype.archetypeGroupSlug === "love" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <DichotomiesModal
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
              imageUrl={imageUrl}
            />
          )}
        {props.archetype.archetypeGroupSlug === "career" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <CareerArchetypeBreakdown
              publicProfile={props.publicProfile}
              own={false}
              archetypeDiscovery={props.archetypeDiscovery}
            />
          )}
        {props.archetype.archetypeGroupSlug === "values" &&
          props.archetype.archetypeGroupSlug ===
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <ValuesArchetypeBreakdown
              publicProfile={props.publicProfile}
              archetypeDiscovery={props.archetypeDiscovery}
            />
          )}
        {status !== null ||
          (props.archetype.archetypeGroupSlug !==
            props.archetypeDiscovery?.archetype.archetypeGroupSlug && (
            <div className={styles.ghostBox}>
              <SmallProfileImage
                noLink
                publicProfile={props.publicProfile}
                imageUrl={imageUrl}
              />
              <p className={styles.ghostBoxP}>
                Score breakdowns are only available on highest scoring
                Archetype.
              </p>
            </div>
          ))}
        <ArchetypeDescriptions archetype={props.archetype} />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.archetype.connectionAvailabilities}
          />
        </div>
      </div>
    );
  }
}
