import { useEffect, useState } from "react";

import styles from "./Snippets.module.css";

import { useModalContext } from "@/context/ModalContext";
import Alert from "@/components/shared/Alerts/Alert";
import { useAlertContext } from "@/context/AlertContext";

type Props = {
  onClick: () => void;
  disabled: boolean;
  message: string;
};

export default function SnippetsRegenerateButton(props: Props) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { emptyModal } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  if (disabled === true) {
    return (
      <button
        style={{ background: "#928E8C" }}
        className={styles.retryButton}
        onClick={() => {
          setAlertComponent(
            <Alert elementName="Alert" type="fail" message={props.message} />,
            7000
          );
          setTimeout(() => {
            emptyModal();
          }, 5000);
        }}
      >
        Retry
      </button>
    );
  }
  return (
    <button
      className={styles.retryButton}
      onClick={() => {
        props.onClick();
        setDisabled(true);
      }}
    >
      Retry
    </button>
  );
}
