import {
  ContentType,
  PublicProfile,
  TraitContent,
} from "@dimensional-engineering/dimensional-models";
import styles from "./Dichotomies.module.css";
import Spectrums from "@/components/shared/Spectrum/Spectrums";

export default function Dichotomies(props: {
  data: TraitContent;
  useSpectrum?: boolean;
  slug?: string;
  publicProfile?: PublicProfile | null;
  imageUrl?: string;
}) {
  const map: any = {
    "pattern-independent-lover": {
      down: "(I)ndependent",
      up: "In(T)erdependent ",
    },
    "pattern-pragmatic-lover": {
      down: "(P)ragmatic",
      up: "(D)reamer",
    },
    "pattern-anxious-lover": {
      down: "(A)nxious",
      up: "(C)entered",
    },
    "pattern-chaotic-lover": {
      down: "(F)un & stimulation",
      up: "(E)motion & stability",
    },
    "mbti-introversion-extroversion": {
      up: "(I)ntroverted",
      down: "(E)xtroverted",
    },
    "mbti-sensing-intuition": {
      up: "(S)ensing",
      down: "I(N)tuitive",
    },
    "mbti-feeling-thinking": {
      up: "(F)eeling",
      down: "(T)hinking",
    },
    "mbti-judging-perceiving": {
      up: "(J)udging",
      down: "(P)erceiving",
    },
  };

  const mapped = props.data.polarityArchetypeDetails?.map((pol, index) => {
    if (pol.scaleSlug) {
      return (
        <div className={styles.row} key={pol.scaleSlug}>
          <div className={styles.rowParent}>
            <p
              className={
                pol.direction === "down" ? styles.selected : styles.unselected
              }
            >
              {map[pol.scaleSlug].up}
            </p>
            <p
              className={
                pol.direction === "up" ? styles.selected : styles.unselected
              }
            >
              {map[pol.scaleSlug].down}
            </p>
          </div>
          <div className={styles.lineParent}>
            <div className={styles.lineLeft}></div>
            <div className={styles.lineMiddle}></div>
            <div className={styles.lineRight}></div>
          </div>
        </div>
      );
    } else if (pol.patternSlug) {
      return (
        <div className={styles.row} key={pol.patternSlug}>
          <div className={styles.rowParent}>
            <p
              className={
                pol.direction === "up" ? styles.selected : styles.unselected
              }
            >
              {map[pol.patternSlug].up}
            </p>
            <p
              className={
                pol.direction === "down" ? styles.selected : styles.unselected
              }
            >
              {map[pol.patternSlug].down}
            </p>
          </div>
          <div className={styles.lineParent}>
            <div className={styles.lineLeft}></div>
            <div className={styles.lineMiddle}></div>
            <div className={styles.lineRight}></div>
          </div>
        </div>
      );
    }
  });

  return (
    <div className={styles.parent}>
      <div className={styles.header}>Archetype Breakdown</div>
      {props.useSpectrum ? (
        <div style={{ padding: "15px" }}>
          <Spectrums
            contentIdentifier={{
              contentType: ContentType.archetype,
              contentSlug: props.slug ?? "",
            }}
            otherPublicProfile={props.publicProfile}
            imageUrl={props.imageUrl}
            noMargin
          />
        </div>
      ) : (
        mapped
      )}
    </div>
  );
}
