export default function CloseButtonSummary() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.15" cx="17.5" cy="17.5" r="17.5" fill="#FFFFF2" />
      <g opacity="0.7">
        <path
          d="M23.8193 11.9618C23.931 11.8559 23.9861 11.7253 23.9861 11.5715C23.9861 11.4177 23.931 11.2842 23.8193 11.1725C23.7134 11.0666 23.5828 11.0143 23.429 11.0143C23.2752 11.0143 23.1417 11.0666 23.03 11.1725L17.8575 16.3535L12.6764 11.1725C12.5705 11.0666 12.4399 11.0143 12.2861 11.0143C12.1323 11.0143 11.9989 11.0666 11.8872 11.1725C11.7812 11.2842 11.729 11.4177 11.729 11.5715C11.729 11.7253 11.7812 11.8558 11.8872 11.9618L17.0682 17.1428L11.8872 22.3153C11.7812 22.4271 11.729 22.5605 11.729 22.7143C11.729 22.8681 11.7812 22.9987 11.8872 23.1046C11.9989 23.2164 12.1323 23.2715 12.2861 23.2715C12.4399 23.2715 12.5705 23.2164 12.6764 23.1046L17.8575 17.9321L23.03 23.1046C23.1417 23.2164 23.2752 23.2715 23.429 23.2715C23.5828 23.2715 23.7134 23.2164 23.8193 23.1046C23.931 22.9987 23.9861 22.8681 23.9861 22.7143C23.9861 22.5605 23.931 22.4271 23.8193 22.3153L18.6468 17.1428L23.8193 11.9618Z"
          fill="#FFFFF2"
        />
      </g>
    </svg>
  );
}

export function CloseButtonWhite() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#FFFFF2" />
      <path
        d="M23.8188 11.9618C23.9305 11.8559 23.9857 11.7253 23.9857 11.5715C23.9857 11.4177 23.9305 11.2842 23.8188 11.1725C23.7129 11.0666 23.5823 11.0143 23.4285 11.0143C23.2747 11.0143 23.1412 11.0666 23.0295 11.1725L17.857 16.3535L12.6759 11.1725C12.57 11.0666 12.4395 11.0143 12.2857 11.0143C12.1318 11.0143 11.9984 11.0666 11.8867 11.1725C11.7807 11.2842 11.7285 11.4177 11.7285 11.5715C11.7285 11.7253 11.7807 11.8558 11.8867 11.9618L17.0677 17.1428L11.8867 22.3153C11.7807 22.4271 11.7285 22.5605 11.7285 22.7143C11.7285 22.8681 11.7807 22.9987 11.8867 23.1046C11.9984 23.2164 12.1319 23.2715 12.2857 23.2715C12.4394 23.2715 12.57 23.2164 12.6759 23.1046L17.857 17.9321L23.0295 23.1046C23.1412 23.2164 23.2747 23.2715 23.4285 23.2715C23.5823 23.2715 23.7129 23.2164 23.8188 23.1046C23.9305 22.9987 23.9857 22.8681 23.9857 22.7143C23.9857 22.5605 23.9305 22.4271 23.8188 22.3153L18.6463 17.1428L23.8188 11.9618Z"
        fill="#2D2927"
      />
    </svg>
  );
}

export function CloseButtonDailyStoryFeedback() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#928E8C" />
      <path
        d="M23.8188 11.9618C23.9305 11.8559 23.9857 11.7253 23.9857 11.5715C23.9857 11.4177 23.9305 11.2842 23.8188 11.1725C23.7129 11.0666 23.5823 11.0143 23.4285 11.0143C23.2747 11.0143 23.1412 11.0666 23.0295 11.1725L17.857 16.3535L12.6759 11.1725C12.57 11.0666 12.4395 11.0143 12.2857 11.0143C12.1318 11.0143 11.9984 11.0666 11.8867 11.1725C11.7807 11.2842 11.7285 11.4177 11.7285 11.5715C11.7285 11.7253 11.7807 11.8558 11.8867 11.9618L17.0677 17.1428L11.8867 22.3153C11.7807 22.4271 11.7285 22.5605 11.7285 22.7143C11.7285 22.8681 11.7807 22.9987 11.8867 23.1046C11.9984 23.2164 12.1319 23.2715 12.2857 23.2715C12.4394 23.2715 12.57 23.2164 12.6759 23.1046L17.857 17.9321L23.0295 23.1046C23.1412 23.2164 23.2747 23.2715 23.4285 23.2715C23.5823 23.2715 23.7129 23.2164 23.8188 23.1046C23.9305 22.9987 23.9857 22.8681 23.9857 22.7143C23.9857 22.5605 23.9305 22.4271 23.8188 22.3153L18.6463 17.1428L23.8188 11.9618Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
