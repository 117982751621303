import { ChatRoom } from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface MessagesSliceInterface {
  chatRooms: ChatRoom[];
}

const initialState: MessagesSliceInterface = {
  chatRooms: [],
};

export const messagingSlice = createSlice({
  name: "messaging",
  initialState: initialState,
  reducers: {
    setChatRooms: (state, action: PayloadAction<ChatRoom[]>) => {
      return { ...state, chatRooms: action.payload };
    },
  },
});

export const { setChatRooms } = messagingSlice.actions;

export default messagingSlice.reducer;
