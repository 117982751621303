import { useEffect, useState } from "react";
import { Roboto_Mono } from "next/font/google";

import styles from "./RecommendedAssessment.module.css";
import { iconMapper } from "@/components/shared/dimensions/icons/iconMapper";
import { DimensionAssessmentModel } from "@/models/sharedModels";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { dimensionData } from "@/assets/traitInfo/dimensionData";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function RecommendedAssessment(props: { slug: string }) {
  const router = useRouter();
  const userState = useSelector((state: RootState) => state.user);
  const icon = iconMapper[props.slug as string as keyof typeof iconMapper];

  const dimension = dimensionData?.find((d) => d.slug === props.slug);
  const userDimension = userState.dimensionEvents?.find(
    (d) => d.dimensionSlug === props.slug
  );
  const status =
    userDimension && userDimension.event === "started"
      ? "Continue"
      : userDimension && userDimension.event === "complete"
      ? "Retake"
      : "Start";

  return (
    <div
      style={{
        backgroundImage: `url(/dimensions/LargeBackgrounds/${props.slug}.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={styles.main}
    >
      <p className={styles.topText}>Continue your journey</p>
      <div className={styles.iconDiv}>{icon}</div>
      <h1>{dimension?.title}</h1>
      <div className={styles.estimates}>
        <p className={font.className}>
          {dimension?.estimatedTime} min. {dimension?.numElements} elements
        </p>
      </div>
      <h3>{dimension?.description}</h3>
      <button
        className={styles.startButton}
        onClick={() => router.push(`/app/assessment/${props.slug}`)}
      >
        {status} Dimension
      </button>
    </div>
  );
}
