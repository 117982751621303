export default function PeerInteractionStylesSnippetIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0076 22.6914C26.6739 22.197 26.3481 21.7004 26.2488 21.1025C26.1369 20.4274 26.3563 19.7357 26.6867 19.1348C27.1807 18.2364 27.9148 17.5817 28.0053 16.5178C28.0818 15.6204 27.7725 14.6815 27.1246 14.0407C26.1775 13.104 24.5884 12.8848 23.4188 13.5292C22.8779 13.8278 22.4339 14.282 21.8868 14.5719C21.0186 15.0317 19.9137 15.02 19.0559 14.5419C18.6111 14.294 18.2398 13.9367 17.8265 13.642C16.6997 12.8409 15.1111 12.9936 14.0784 13.8785C13.0588 14.7521 12.677 16.1995 13.2518 17.4275C13.4888 17.9346 13.8648 18.3628 14.1861 18.8219C14.8298 19.7405 15.0113 20.8748 14.475 21.8967C14.18 22.4591 13.703 22.9099 13.3932 23.4642C12.7397 24.6333 12.9752 26.2217 13.9376 27.157C14.8999 28.0922 16.5094 28.2912 17.6748 27.6197C18.1793 27.3294 18.5946 26.903 19.1045 26.6212C19.9565 26.1523 21.0504 26.1472 21.9075 26.6078C22.4299 26.8908 22.8553 27.3243 23.3736 27.6166C24.588 28.3005 26.1141 28.0406 27.0894 27.0691C27.9099 26.2515 28.1042 25.0756 27.7395 24.0003C27.578 23.5249 27.2898 23.1094 27.0076 22.6914ZM20.5215 23.5943C20.123 23.5945 19.7284 23.5168 19.3602 23.3659C18.992 23.215 18.6575 22.9936 18.3757 22.7146C18.0938 22.4355 17.8703 22.1042 17.7177 21.7396C17.5652 21.3749 17.4867 20.984 17.4867 20.5893C17.4867 20.1946 17.5652 19.8038 17.7177 19.4391C17.8703 19.0744 18.0938 18.7431 18.3757 18.4641C18.6575 18.185 18.992 17.9637 19.3602 17.8128C19.7284 17.6618 20.123 17.5842 20.5215 17.5844C21.3258 17.5847 22.0971 17.9014 22.6657 18.4649C23.2344 19.0284 23.5538 19.7926 23.5538 20.5893C23.5538 21.3861 23.2344 22.1502 22.6657 22.7137C22.0971 23.2772 21.3258 23.594 20.5215 23.5943Z"
        fill="#FFFFF2"
      />
      <path
        d="M39.067 15.1933C39.1869 15.0339 39.2067 14.8204 39.1171 14.6413C39.0275 14.4634 38.8457 14.3501 38.6454 14.3501H35.6942C35.2133 14.3541 34.7601 14.5741 34.4611 14.9509L31.0988 19.1668C30.6337 19.7452 30.6337 20.57 31.0988 21.1484L34.4611 25.3643C34.7601 25.7411 35.2134 25.9611 35.6942 25.9651H38.6454C38.8457 25.9651 39.0275 25.8518 39.1171 25.6739C39.2067 25.4947 39.1869 25.2813 39.067 25.1219L35.6099 20.5052C35.4571 20.3102 35.4571 20.0362 35.6099 19.8412L39.067 15.1933Z"
        fill="#FFFFF2"
      />
      <path
        d="M6.7615 14.9509C6.46243 14.5741 6.0092 14.354 5.52834 14.3501H2.57715C2.37689 14.3501 2.19508 14.4634 2.1055 14.6413C2.01592 14.8204 2.03568 15.0339 2.15557 15.1933L5.61267 19.81C5.7655 20.0049 5.7655 20.279 5.61267 20.474L2.15557 25.1011C2.03568 25.2605 2.01592 25.474 2.1055 25.6531C2.1951 25.831 2.37691 25.9443 2.57715 25.9443H5.52834C6.00923 25.9403 6.46243 25.7203 6.7615 25.3435L10.1238 21.1276C10.5888 20.5492 10.5888 19.7244 10.1238 19.146L6.7615 14.9509Z"
        fill="#FFFFF2"
      />
      <path
        d="M21.1479 31.1047C20.5721 30.6318 19.7421 30.6318 19.1664 31.1047L14.9504 34.467C14.5736 34.7661 14.3536 35.2193 14.3496 35.7001V38.6513C14.3496 38.8516 14.4629 39.0334 14.6408 39.123C14.82 39.2126 15.0334 39.1928 15.1928 39.0729L19.8095 35.6158C20.0045 35.463 20.2785 35.463 20.4735 35.6158L25.0902 39.0729C25.1798 39.1454 25.2917 39.1823 25.4064 39.1783C25.4828 39.1928 25.5618 39.1928 25.6382 39.1783C25.8174 39.0874 25.9307 38.9043 25.9334 38.704V35.7528C25.9294 35.272 25.7094 34.8188 25.3326 34.5197L21.1479 31.1047Z"
        fill="#FFFFF2"
      />
      <path
        d="M25.6487 2.1249C25.4801 2.02477 25.2693 2.02477 25.1006 2.1249L20.484 5.63462C20.289 5.78745 20.0149 5.78745 19.8199 5.63462L15.1928 2.17752C15.0334 2.05763 14.8199 2.03786 14.6408 2.12745C14.4629 2.21704 14.3496 2.39886 14.3496 2.5991V5.55029C14.3536 6.03118 14.5736 6.48438 14.9504 6.78345L19.1664 10.1457C19.7447 10.6108 20.5695 10.6108 21.1479 10.1457L25.3638 6.78345C25.7407 6.48438 25.9607 6.03115 25.9646 5.55029V2.5991C25.958 2.39357 25.8358 2.20922 25.6487 2.1249Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
