export default function MagnifyingGlassIcon(props: { dark?: boolean }) {
  return (
    <svg
      width="28"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6064 11.3561L18.0365 16.9996L18.0365 16.9998C18.477 17.4576 18.477 18.1993 18.0365 18.6571C17.8163 18.886 17.5271 19 17.2388 19C16.9497 19 16.6613 18.886 16.4411 18.6571L11.0109 13.0128C9.87852 13.8576 8.49134 14.3562 6.99122 14.3562C3.17633 14.3562 0.0839844 11.1422 0.0839844 7.17768C0.0839844 3.21295 3.17633 -0.000832019 6.99183 1.61578e-07C10.8067 1.61578e-07 13.8991 3.21402 13.8991 7.17854C13.8991 8.73749 13.4193 10.1792 12.6064 11.3561ZM6.99169 2.34277C4.4271 2.34277 2.33984 4.51117 2.33984 7.17733C2.33984 9.8435 4.4271 12.0119 6.99169 12.0119C9.55709 12.0119 11.6435 9.8435 11.6435 7.17733C11.6435 4.51202 9.55709 2.34277 6.99169 2.34277Z"
        fill={props.dark ? "#fffff2" : "#2D2927"}
      />
    </svg>
  );
}
