import { DiscoveredCompatibilitySnippet } from "@dimensional-engineering/dimensional-models";

import styles from "../CompatibilitySnippets.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilityEmojiView(props: Props) {
  const mappedSecondary =
    props.snippet?.snippetDetail.emojiMatchDetail?.secondaryMatches?.map(
      (value, index) => {
        return (
          <p className={styles.secondaryString} key={index}>
            {value.emojiString} - {value.title}
          </p>
        );
      }
    );

  return (
    <div style={{ marginTop: "35px" }} className={styles.spectrumsParent}>
      <div className={styles.emojiCard}>
        <p className={styles.emojiString}>
          {
            props.snippet?.snippetDetail.emojiMatchDetail?.primaryMatch
              .emojiString
          }
        </p>
        <h3 className={styles.emojiTitle}>
          {props.snippet?.snippetDetail.emojiMatchDetail?.primaryMatch.title}
        </h3>
        <p className={styles.emojiDescription}>
          {
            props.snippet?.snippetDetail.emojiMatchDetail?.primaryMatch
              .shortDescription
          }
        </p>
      </div>
      {mappedSecondary?.length ? (
        <div className={styles.secondaryEmojiDiv}>
          <h5 className={styles.secondaryTitle}>OTHER POTENTIAL VIBES:</h5>
          {mappedSecondary}
        </div>
      ) : null}
    </div>
  );
}
