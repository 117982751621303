import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityConflictStylesIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.622 7.74074C16.622 7.33164 16.2897 7 15.8799 7H1.40816C0.998284 7 0.666017 7.33164 0.666017 7.74074L0.666016 32.9259C0.666016 33.335 0.998282 33.6667 1.40815 33.6667H15.8798C16.2897 33.6667 16.622 33.335 16.622 32.9259L16.622 20.5294C16.622 27.7849 21.8552 33.6667 28.3107 33.6667C34.7661 33.6667 39.9993 27.7849 39.9993 20.5294C39.9993 13.2739 34.7661 7.39216 28.3107 7.39216C21.8552 7.39216 16.622 13.2739 16.622 20.5294L16.622 7.74074Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
