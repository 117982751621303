import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function LoveAttitudesIcon(
  props: DimensionIconAssessmentStatus
) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.4104 38.2958C77.0297 29.1307 72.7036 20.5643 65.5272 14.7753C57.8125 8.50708 47.7857 5.76678 37.9227 7.23137C28.6668 8.58997 20.1123 12.9193 13.5718 19.5558C7.02683 26.1925 2.8492 34.7677 1.67919 43.9795C1.52137 45.3059 1.95771 46.6323 2.8675 47.6132C4.09764 49.0271 6.05182 49.5891 7.85283 49.0455C9.65384 48.5021 10.9582 46.9546 11.1857 45.1031C12.1605 37.7525 15.5815 30.9358 20.9057 25.7316C26.23 20.5318 33.1513 17.2435 40.5735 16.3914C47.4295 15.6545 54.2902 17.7132 59.5865 22.0977C64.4698 26.0999 67.4776 31.9214 67.9047 38.1891C68.2946 44.3652 66.2337 50.4452 62.1674 55.133C58.1011 59.8215 52.3453 62.746 46.1347 63.2803C41.9478 63.6396 37.7933 62.2901 34.6322 59.5452C31.7681 57.0904 30.0506 53.5763 29.8789 49.8273C29.7118 46.0093 31.1091 42.2834 33.7502 39.5062C36.3961 36.7244 40.0585 35.1216 43.9112 35.0664C46.2508 35.0757 48.2746 36.6922 48.7852 38.9582C49.0312 40.3261 48.6552 41.7308 47.7594 42.7992C46.8635 43.8677 45.5405 44.4895 44.1387 44.4987H43.7209C41.3258 44.4987 39.3855 46.4238 39.3855 48.8003V49.6293C39.3855 52.0058 41.3258 53.9309 43.7209 53.9309C50.7904 54 56.8946 49.0352 58.2077 42.1405C58.9086 38.0323 57.7481 33.8274 55.0372 30.645C52.3217 27.4671 48.3391 25.6295 44.1382 25.6341C37.816 25.6341 31.7536 28.1349 27.2926 32.5793C22.8365 37.0237 20.3437 43.0478 20.3717 49.3204C20.3902 55.9063 23.2728 62.1659 28.2861 66.4859C32.9093 70.548 38.8694 72.791 45.0436 72.7956C46.0602 72.7956 47.0814 72.7358 48.0933 72.6206C56.4347 71.6028 64.0845 67.5084 69.5198 61.148C74.9507 54.7877 77.7683 46.6271 77.4109 38.2948L77.4104 38.2958Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
