import { useEffect, useState } from "react";
import { TraitContent } from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "./AboutThisTrait.module.css";

import archetypeJsonData from "../../../../assets/traitInfo/archetypes.json";
import elementJsonData from "../../../../assets/traitInfo/elements.json";
import patternJsonData from "../../../../assets/traitInfo/patterns.json";
import { natureData } from "@/assets/traitInfo/natureData";
import { traitsMapper } from "../../shared/traitsMap";
import { archetypeMapper } from "@/assets/traitInfo/dimensionData";

type Props = {
  traitContent: TraitContent;
  archetypeGroupSlug?: string;
  dimensionSlug?: string;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function AboutThisTrait(props: Props) {
  const rows = useGetRowsData(props);

  const mapped = rows.map((row, index) => {
    return (
      <div className={`${styles.row} ${font.className}`} key={row.name}>
        <p>{row.name}</p>
        <p
          className={
            row.value === "UltraRare"
              ? styles.ultraRare
              : row.value === "Rare"
              ? styles.rare
              : ""
          }
        >
          {row.value}
        </p>
      </div>
    );
  });

  return (
    <div className={styles.parent}>
      <div className={styles.header}>About this trait</div>
      {mapped}
    </div>
  );
}

function useGetRowsData(props: Props) {
  const [rows, setRows] = useState<{ name: string; value: any }[]>([]);

  const mapper = {
    archetype: "Archetype",
    element: "Element",
    pattern: "Pattern",
  };

  useEffect(() => {
    setRows([]);
    if (props.traitContent?.traitType !== undefined) {
      setRows((current) => [
        ...current,
        ///@ts-ignore
        { name: "Type", value: mapper[props.traitContent?.traitType] },
      ]);
    }

    if (props.dimensionSlug) {
      let dimensionSlug = traitsMapper[props.dimensionSlug];
      setRows((current) => [
        ...current,

        {
          name: "Dimension",
          ///@ts-ignore
          value: dimensionSlug,
        },
      ]);
    }

    if (props.archetypeGroupSlug) {
      setRows((current) => [
        ...current,
        {
          name: "Archetype Group",
          ///@ts-ignore
          value: archetypeMapper[props.archetypeGroupSlug],
        },
      ]);
    }

    if (props.traitContent?.oppositeTrait?.traitSlug) {
      let slug = props.traitContent?.oppositeTrait?.traitSlug;
      archetypeJsonData.forEach((arch) => {
        if (arch.slug === slug) {
          setRows((current) => [
            ...current,

            {
              name: "Opposite",
              ///@ts-ignore
              value: arch.name,
            },
          ]);
        }
      });
      elementJsonData.forEach((elem) => {
        if (elem.slug === slug) {
          setRows((current) => [
            ...current,

            {
              name: "Opposite",
              ///@ts-ignore
              value: elem.name,
            },
          ]);
        }
      });
      patternJsonData.forEach((patt) => {
        if (patt.slug === slug) {
          setRows((current) => [
            ...current,

            {
              name: "Opposite",
              ///@ts-ignore
              value: patt.name,
            },
          ]);
        }
      });
    }

    if (props.traitContent?.nature) {
      natureData.forEach((nat) => {
        if (nat.slug === props.traitContent.nature) {
          setRows((current) => [
            ...current,

            {
              name: "Nature",
              ///@ts-ignore
              value: nat.name,
            },
          ]);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.traitContent]);

  return rows;
}
