import { useEffect, useState } from "react";
import { Pattern as PatternModel } from "@dimensional-engineering/dimensional-models";

import styles from "./LargePattern.module.css";

import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { useGetPatternColors } from "../Pattern";

type PatternProps = {
  pattern: PatternModel;
};
export default function LargePatternModal(props: PatternProps) {
  const { pattern, divs } = useGetPatternColors(props.pattern.slug);

  return (
    <div className={styles.patternParentModal}>
      <div className={styles.patternMidContainerModal}>
        <div className="patternMaksContainerLarge">
          {divs.map((d, i) => {
            return d;
          })}
        </div>
      </div>
      <svg
        width="117"
        height="117"
        viewBox="0 0 117 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="patternImageClipPathLarge">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 7.5071C0 3.36104 3.36104 0 7.5071 0H26.4096C30.5556 0 33.9167 3.36105 33.9167 7.5071V108.779C33.9167 112.925 30.5556 116.286 26.4096 116.286H7.5071C3.36104 116.286 0 112.925 0 108.779V7.5071ZM41.0105 7.5071C41.0105 3.36104 44.3715 0 48.5176 0H67.4201C71.5661 0 74.9272 3.36105 74.9272 7.5071V108.779C74.9272 112.925 71.5661 116.286 67.4201 116.286H48.5176C44.3715 116.286 41.0105 112.925 41.0105 108.779V7.5071ZM89.8045 0C85.6584 0 82.2974 3.36104 82.2974 7.5071V108.779C82.2974 112.925 85.6584 116.286 89.8045 116.286H108.707C112.853 116.286 116.214 112.925 116.214 108.779V7.5071C116.214 3.36105 112.853 0 108.707 0H89.8045Z"
            fill="#FFFFF2"
          />
        </clipPath>
      </svg>

      {props.pattern.isPremium && (
        <div className={styles.premiumIconModal}>
          <PremiumPillIcon />
        </div>
      )}

      <div className={styles.elementNameContainer}>
        <p className={styles.bottomText}>{props.pattern.name}</p>
      </div>
    </div>
  );
}
