import {
  PALifePathIcon,
  PAFunctionVsBehaviourIcon,
  PABeliefAgencyIcon,
  PAProcessInformationIcon,
  PAAmbitionGoodBadIcon,
  PAThinkingEmotionIcon,
  PAIdealLoveLifeIcon,
  PAExecutiveCapacityIcon,
  PAAbilityHandleChangeIcon,
  PAAbilityLiveInPresentIcon,
  PAStrangersIcon,
  PAImaginationPlayIcon,
  PAFitWithinOrgsIcon,
  PASelfControlDutyIcon,
  PABoundariesPeoplePleasingIcon,
  PADesireForChangeIcon,
  PAOutlookOnWorldIcon,
  PALifeCourseIcon,
  PAKinkIcon,
  PATurbulenceIcon,
  PAApproachAngerIcon,
  PAAssertivenessIcon,
  PAUniversalismIcon,
} from "./AnalysisIcons";

export const analysisIconMap = {
  "pa-conventional-vs-iconoclastic": <PALifePathIcon />,
  "pa-function-vs-behavior": <PAFunctionVsBehaviourIcon />,
  "pa-personal-agency": <PABeliefAgencyIcon />,
  "pa-how-you-process-information": <PAProcessInformationIcon />,
  "pa-ambition-good-and-bad": <PAAmbitionGoodBadIcon />,
  "pa-thinking-and-emotion": <PAThinkingEmotionIcon />,
  "pa-your-ideal-love-life": <PAIdealLoveLifeIcon />,
  "pa-executive-capacity": <PAExecutiveCapacityIcon />,
  "pa-ability-to-handle-change": <PAAbilityHandleChangeIcon />,
  "pa-ability-to-live-in-the-present": <PAAbilityLiveInPresentIcon />,
  "pa-strangers": <PAStrangersIcon />,
  "pa-imagination-and-play": <PAImaginationPlayIcon />,
  "pa-fit-within-orgs": <PAFitWithinOrgsIcon />,
  "pa-self-control-and-duty": <PASelfControlDutyIcon />,
  "pa-boundaries-and-people-pleasing": <PABoundariesPeoplePleasingIcon />,
  "pa-desire-for-change": <PADesireForChangeIcon />,
  "pa-outlook-on-the-world": <PAOutlookOnWorldIcon />,
  "pa-your-life-course": <PALifeCourseIcon />,
  "pa-kink-and-intimacy": <PAKinkIcon />,
  "pa-turbulence-and-optimizing-in-romance": <PATurbulenceIcon />,
  "pa-approach-to-anger": <PAApproachAngerIcon />,
  "pa-assertiveness-compassion-romance": <PAAssertivenessIcon />,
  "pa-universalism-and-benevolence": <PAUniversalismIcon />,
};
