import { useEffect } from "react";
import { Mixpanel } from "./mixpanel";
import { useSelector } from "react-redux";

import Alert from "@/components/shared/Alerts/Alert";
import { useAlertContext } from "@/context/AlertContext";
import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import {
  getCountOfTraitRulesForMixpanel,
  getListOfTraitRulesForMixpanel,
} from "@/components/appHome/DailyInsights/utils";
import {
  getCountOfTraitRulesDailyStoriesForMixpanel,
  getListOfTraitRulesDailyStoriesForMixpanel,
} from "@/components/appHome/DailyStories/utils";

export default function useScreenshotHandler() {
  const { modalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const dailyInsight = useSelector(
    (state: RootState) => state.dailyInsight
  ).deliveredDailyInsight;
  const insightDelivery = useSelector(
    (state: RootState) => state.dailyInsight
  ).insightDelivery;
  const dailyStory = useSelector((state: RootState) => state.dailyStory);

  useEffect(() => {
    const checkLocalStorage = () => {
      const screenShotLS = localStorage.getItem("screenshotTaken");
      if (screenShotLS) {
        let isDailyStoryOpen = false;
        let isDailyInsightsOpen = false;
        modalComponent.forEach((c) => {
          if (c.props.elementName === "DailyStory") {
            isDailyStoryOpen = true;
          }
          if (c.props.elementName === "DailyInsightsModal") {
            isDailyInsightsOpen = true;
          }
        });
        if (isDailyStoryOpen) {
          setAlertComponent(
            <Alert
              elementName="Alert"
              message=""
              type="dailyStoryScreenshot"
            />,
            8000
          );
          Mixpanel?.track("Screenshot taken", {
            view: "LLM daily story",
            slug: dailyStory.dailyStory?.computeableStoryModelSlug,
            body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(0, 250),
            trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
              dailyStory.dailyStory
            ),
            is_novel_ruleset:
              dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated,
            trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
              dailyStory.dailyStory
            ),
          });
        } else if (isDailyInsightsOpen) {
          Mixpanel?.track("Screenshot taken", {
            view: "LLM daily insight",
            slug: insightDelivery?.computeableInsightModelSlug,
            body: dailyInsight?.assignmentRuleset?.body,
            trait_rules: getListOfTraitRulesForMixpanel(dailyInsight),
            is_novel_ruleset:
              dailyInsight?.assignmentRuleset.isNovelGenerated ?? false,
            trait_count: getCountOfTraitRulesForMixpanel(dailyInsight),
          });
        } else {
          setAlertComponent(
            <Alert elementName="Alert" message="" type="screenshot" />,
            8000
          );
          Mixpanel?.track("Screenshot taken", {});
        }
        localStorage.removeItem("screenshotTaken");
      }
    };

    const intervalId = setInterval(checkLocalStorage, 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalComponent]);
}
