import {
  DiscoverablePersonalityAnalysis,
  DiscoveredPersonalityAnalysis,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PersonalityAnalysisSlice {
  discoverablePersonalAnalysis: DiscoverablePersonalityAnalysis[] | null;
  discoveredPersonalAnalysis: DiscoveredPersonalityAnalysis[] | null;
}

const initialState: PersonalityAnalysisSlice = {
  discoverablePersonalAnalysis: null,
  discoveredPersonalAnalysis: null,
};

export const personalityAnalysisSlice = createSlice({
  name: "personalityAnalysis",
  initialState: initialState,
  reducers: {
    setDiscoveredPersonalAnalysis: (
      state,
      action: PayloadAction<DiscoveredPersonalityAnalysis[] | null>
    ) => {
      return { ...state, discoveredPersonalAnalysis: action.payload };
    },
    setDiscoverablePersonalAnalysis: (
      state,
      action: PayloadAction<DiscoverablePersonalityAnalysis[] | null>
    ) => {
      return { ...state, discoverablePersonalAnalysis: action.payload };
    },
  },
});

export const {
  setDiscoveredPersonalAnalysis,
  setDiscoverablePersonalAnalysis,
} = personalityAnalysisSlice.actions;

export default personalityAnalysisSlice.reducer;
