import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityVibeAsFriendsIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.maxWidth ? "100%" : "41"}
      height={props.maxWidth ? "100%" : "41"}
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M26.6877 35.0936C24.6247 35.0936 22.5636 33.0306 20.5005 33.0306C18.4375 33.0306 16.3764 35.0936 14.3133 35.0936C12.2503 35.0936 10.1892 33.0306 8.1261 33.0306C6.7862 33.0306 5.34186 33.9587 4.00195 34.5774V38.7015C5.34186 38.0828 6.7862 37.1547 8.1261 37.1547C10.1892 37.1547 12.2503 39.2178 14.3133 39.2178C16.3764 39.2178 18.4375 37.1547 20.5005 37.1547C22.5636 37.1547 24.6247 39.2178 26.6877 39.2178C28.7508 39.2178 30.8119 37.1547 32.875 37.1547C34.2149 37.1547 35.6592 38.0828 36.9991 38.7015V34.5774C35.6592 33.9587 34.2149 33.0306 32.875 33.0306C30.8119 33.0306 28.7508 35.0936 26.6877 35.0936Z"
        fill="#FFFFF2"
      />
      <path
        d="M32.875 24.7822C30.8119 24.7822 28.7508 26.8453 26.6877 26.8453C24.6247 26.8453 22.5636 24.7822 20.5005 24.7822C18.4375 24.7822 16.3764 26.8453 14.3133 26.8453C12.2503 26.8453 10.1892 24.7822 8.1261 24.7822C6.7862 24.7822 5.34186 25.7103 4.00195 26.329V30.4532C5.34186 29.8345 6.7862 28.9064 8.1261 28.9064C10.1892 28.9064 12.2503 30.9694 14.3133 30.9694C16.3764 30.9694 18.4375 28.9064 20.5005 28.9064C22.5636 28.9064 24.6247 30.9694 26.6877 30.9694C28.7508 30.9694 30.8119 28.9064 32.875 28.9064C34.2149 28.9064 35.6592 29.8345 36.9991 30.4532V26.329C35.6592 25.7103 34.2149 24.7822 32.875 24.7822Z"
        fill="#FFFFF2"
      />
      <path
        d="M30.4214 5.25151C30.4214 7.43649 28.6508 9.20696 26.4672 9.20696C24.2834 9.20696 22.5117 7.4364 22.5117 5.25151C22.5117 3.06773 24.2835 1.2973 26.4672 1.2973C28.6509 1.2973 30.4214 3.06786 30.4214 5.25151Z"
        fill="#FFFFF2"
      />
      <path
        d="M21.666 19.4567H31.2756V14.6483C31.2756 12.0008 29.123 9.83856 26.4659 9.83856C23.81 9.83856 21.666 11.9995 21.666 14.6483V19.4567Z"
        fill="#FFFFF2"
      />
      <path
        d="M10 19.4567H19.6095V14.6483C19.6095 12.0008 17.457 9.83856 14.7999 9.83856C12.144 9.83856 10 11.9995 10 14.6483V19.4567Z"
        fill="#FFFFF2"
      />
      <path
        d="M18.7554 5.25151C18.7554 7.43649 16.9836 9.20696 14.7999 9.20696C12.6161 9.20696 10.8457 7.4364 10.8457 5.25151C10.8457 3.06773 12.6163 1.2973 14.7999 1.2973C16.9837 1.2973 18.7554 3.06786 18.7554 5.25151Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
