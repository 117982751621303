import styles from "./GhostBox.module.css";

import RegenerateButton from "@/components/publicProfiles/Nutshell/RegenerateButton";

type GhostBoxProps = {
  username?: string | null;
  message: string;
  image: React.ReactNode;
  onlyMessage?: boolean;
  darkText?: boolean;
  actionButton?: boolean;
  buttonText?: string;
  buttonAction?: () => void;
  fullWidth?: boolean;
  premium?: boolean;
};

type RegenGhostBoxProps = {
  message: string;
  image: React.ReactNode;
  disabled: boolean;
  alertMessage: string;
  onClick: () => void;
};

export function RegenerateGhostBox(props: RegenGhostBoxProps) {
  return (
    <div className={styles.parent}>
      <div className={styles.image}>{props.image}</div>
      <p>{props.message}</p>
      <div className={styles.regenButtonDiv}>
        <RegenerateButton
          message={props.alertMessage}
          disabled={props.disabled}
          onClick={() => props.onClick()}
        />
      </div>
    </div>
  );
}

export default function GhostBox(props: GhostBoxProps) {
  if (props.premium) {
    return (
      <div
        style={props.fullWidth ? { width: "100%" } : {}}
        className={styles.parentLight}
      >
        <div className={styles.image}>{props.image}</div>
        <p className={styles.messagePremium}>{props.message}</p>
      </div>
    );
  }
  return (
    <div
      style={props.fullWidth ? { width: "100%" } : {}}
      className={styles.parent}
    >
      <div className={styles.image}>{props.image}</div>
      {!props.onlyMessage ? (
        <p
          style={props.darkText ? { color: "#2d2927" } : {}}
        >{`@${props.username} ${props.message}`}</p>
      ) : (
        <p style={props.darkText ? { color: "#2d2927" } : {}}>
          {props.message}
        </p>
      )}
      {props.actionButton ? (
        <button
          className={styles.button}
          onClick={() => {
            if (props.buttonAction) props.buttonAction();
          }}
        >
          {props.buttonText}
        </button>
      ) : null}
    </div>
  );
}

export function LatestGhostBox(props: GhostBoxProps) {
  return (
    <div style={{ width: "100%" }} className={styles.parent}>
      <div className={styles.imageLatest}>{props.image}</div>
      {!props.onlyMessage ? (
        <p
          className={styles.text}
          style={props.darkText ? { opacity: "0.8" } : {}}
        >{`@${props.username} ${props.message}`}</p>
      ) : (
        <p
          className={styles.text}
          style={props.darkText ? { opacity: "0.8" } : {}}
        >
          {props.message}
        </p>
      )}
      {props.actionButton ? (
        <button
          className={styles.button}
          onClick={() => {
            if (props.buttonAction) props.buttonAction();
          }}
        >
          {props.buttonText}
        </button>
      ) : null}
    </div>
  );
}
