export default function RewardsIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_49065_4130)">
        <path
          d="M5.99734 9.19688C8.15302 9.19688 9.84982 10.2094 11.0161 12.1769C11.1992 12.4856 11.2954 12.8375 11.2954 13.1969C11.2954 14.3013 10.4004 15.1969 9.29544 15.1969H2.69864C2.33988 15.1969 1.98739 15.1 1.67864 14.9169C0.728636 14.3538 0.414892 13.1268 0.978012 12.1769C2.14427 10.2094 3.84118 9.19688 5.99734 9.19688ZM5.99734 10.53C4.32294 10.53 3.05606 11.2863 2.12486 12.8569C1.93736 13.1731 2.04174 13.5825 2.3586 13.77C2.46173 13.8313 2.57923 13.8631 2.6986 13.8631H9.29541C9.66353 13.8631 9.96229 13.565 9.96229 13.1969C9.96229 13.0769 9.93041 12.9594 9.86916 12.8569C8.93791 11.2862 7.67174 10.53 5.99734 10.53ZM6.1079 1.86312C7.94918 1.86312 9.44166 3.35562 9.44166 5.19688C9.44166 7.03752 7.94917 8.53 6.1079 8.53L6.05228 8.52938C6.03415 8.53 6.0154 8.53 5.99728 8.53C4.15599 8.53 2.66351 7.03751 2.66351 5.19688C2.66351 3.3556 4.15601 1.86312 5.99728 1.86312L6.05228 1.86375C6.07103 1.86375 6.08978 1.86312 6.1079 1.86312ZM6.0529 3.19749L6.1079 3.19686C5.00353 3.19686 4.1079 4.09187 4.1079 5.19687C4.1079 6.2825 4.97353 7.1663 6.05222 7.19558L5.99722 7.19683C7.10159 7.19683 7.99722 6.3012 7.99722 5.19684C7.99722 4.11058 7.13159 3.22693 6.0529 3.19749Z"
          fill="#FFFFF2"
        />
        <path
          d="M15.5327 5.00035C15.3314 5.00704 15.1401 5.09957 14.9998 5.25898C13.7902 6.59783 12.8582 7.72042 11.75 8.97339L10.6237 7.92104C10.4678 7.76665 10.2614 7.68805 10.0525 7.70366C9.84354 7.71927 9.64876 7.82796 9.51351 8.00466C9.37777 8.18135 9.31317 8.411 9.33387 8.64175C9.35406 8.87195 9.45851 9.08377 9.62252 9.22757L11.3019 10.7972C11.6097 11.0853 12.0664 11.0642 12.3515 10.7481C13.7453 9.20535 14.7359 7.97506 16.0979 6.46737C16.3255 6.221 16.3931 5.84532 16.268 5.52093C16.1433 5.19709 15.8511 4.9903 15.5327 5.00035Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_49065_4130">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.330078 0.529999)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
