export default function ElementStatusIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9524 9.99999C19.9524 15.4965 15.4965 19.9524 9.99999 19.9524C4.50344 19.9524 0.0476074 15.4965 0.0476074 9.99999C0.0476074 4.50344 4.50344 0.0476074 9.99999 0.0476074C15.4965 0.0476074 19.9524 4.50344 19.9524 9.99999ZM12.844 9.99992C12.844 11.5704 11.5709 12.8435 10.0004 12.8435C8.42999 12.8435 7.15689 11.5704 7.15689 9.99992C7.15689 8.42947 8.42999 7.15638 10.0004 7.15638C11.5709 7.15638 12.844 8.42947 12.844 9.99992Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
