import { useEffect, useState } from "react";

import elementsJsonData from "../../../../assets/traitInfo/elements.json";
import { ExtendedScale } from "@/models/sharedModels";

export default function useGetElementModalData(slug: string) {
  const [element, setElement] = useState<ExtendedScale | null>(null);
  const [otherElements, setOtherElements] = useState<ExtendedScale[]>([]);

  useEffect(() => {
    let otherSlugs: string[] = [];
    let elem: ExtendedScale | null = null;
    let tempOtherElements: ExtendedScale[] = [];
    elementsJsonData.forEach((el) => {
      if (el.slug === slug) {
        elem = el as ExtendedScale;
        // setElement(el as ExtendedScale);
        el.traitContent?.otherDetails?.otherTraits.forEach((otherArch) => {
          otherSlugs.push(otherArch.slug);
        });
      }
    });

    elem = elem as ExtendedScale | null;
    if (elem && !elem?.traitContent?.markdownSections) {
      elem.traitContent.markdownSections = [
        {
          markdownString: `### Short Description\n\n${elem.shortDescription}`,
          //@ts-ignore
          traitSection: "identity",
          title: "Identity",
          //@ts-ignore
          feedbackType: "accuracy",
        },
      ];
    } else if (elem && elem?.traitContent?.markdownSections?.length === 0) {
      elem.traitContent.markdownSections.push({
        markdownString: `### Short Description\n\n${elem.shortDescription}`,
        //@ts-ignore
        traitSection: "identity",
        title: "Identity",
        //@ts-ignore
        feedbackType: "accuracy",
      });
    }

    if (otherSlugs.length > 0) {
      elementsJsonData.forEach((el) => {
        if (otherSlugs.includes(el.slug)) {
          tempOtherElements.push(el as ExtendedScale);
        }
      });
    }

    setElement(elem);
    setOtherElements(tempOtherElements);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return { element, otherElements };
}
