import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function CopingStyleIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 102 102"
      fill="none"
      style={{ padding: "2px" }}
    >
      <path
        d="M68.0403 49.7265C67.2751 59.6488 75.1653 67.9167 84.8288 68.0401C91.6953 68.0401 98.0546 71.856 100.598 78.2157C105.433 90.4285 96.4 102 84.8288 102C75.4184 102 68.0403 93.8615 67.9105 84.4457C67.528 56.8456 45.2685 34.5864 17.6684 34.2036C8.25795 33.9551 0.119223 26.5768 0.119223 17.1619C-0.00995621 5.59008 11.696 -3.31398 23.9035 1.39225C30.2633 3.93616 34.0791 10.2959 34.0791 17.1619C34.0791 26.955 42.476 34.7162 52.3927 33.9505C61.2964 33.3145 67.7859 25.4294 68.039 16.526C68.2924 6.47956 77.3253 -1.2814 87.6245 0.243677C94.7495 1.39141 100.602 7.24438 101.621 14.3649C103.146 24.6698 95.3906 33.6973 85.3389 33.9505C76.4352 34.333 68.6737 40.6928 68.0377 49.7252L68.0403 49.7265Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
      <path
        d="M27.7207 101.877C12.4573 101.877 0.120605 89.5396 0.120605 74.2764C0.120605 59.0132 12.4575 46.6763 27.7207 46.6763C42.9839 46.6763 55.3209 59.0132 55.3209 74.2764C55.1966 89.5398 42.8596 101.877 27.7207 101.877Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
