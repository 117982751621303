export default function AdviceIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8057 26.4055C31.9432 25.0118 26.8809 27.9055 22.8121 30.2305C21.1871 31.1618 19.6559 32.0368 18.9809 32.1743C18.3934 32.2931 17.9121 32.2181 17.5184 31.9431C17.0246 31.6118 16.7559 31.0368 16.6122 30.6306C16.9122 30.5306 17.2684 30.4618 17.7059 30.4493C21.6684 30.2993 24.1495 28.8556 24.1871 26.6743C24.2059 25.6618 23.8059 25.0993 23.4684 24.8056C22.6871 24.1306 21.6246 24.2743 21.5184 24.2868C21.4809 24.2931 18.0621 24.7806 15.9872 24.5118L15.8059 24.4868C13.7622 24.2243 11.8372 23.9743 9.94951 26.6368C8.47451 28.7118 6.03703 28.4056 5.93711 28.393C5.75586 28.368 5.57461 28.418 5.43711 28.5368C5.29961 28.6556 5.22461 28.8306 5.22461 29.0118V37.3182C5.22461 37.637 5.46211 37.9057 5.77461 37.937L16.1498 39.0932C16.4998 39.1307 16.8561 39.1494 17.2061 39.1494C19.0685 39.1494 20.9061 38.6057 22.4813 37.587L34.9748 29.4558C35.5061 29.1058 35.8124 28.4933 35.7749 27.8558C35.7373 27.2182 35.3749 26.6745 34.8061 26.4058L34.8057 26.4055Z"
        fill="#FFFFF2"
      />
      <path
        d="M13.1741 18.467H18.3677L21.2552 21.7795C21.3739 21.917 21.5427 21.992 21.7239 21.992C21.9052 21.992 22.0739 21.917 22.1927 21.7795L25.0802 18.467H30.2738C32.1301 18.467 33.6488 16.9608 33.6488 15.1045V5.01092C33.6488 3.15468 32.1301 1.64844 30.2738 1.64844H13.1738C11.3113 1.64844 9.79883 3.15468 9.79883 5.01092V15.1045C9.79883 16.9608 11.3115 18.467 13.1741 18.467ZM27.8365 12.2358C26.6365 12.2358 25.6552 11.2608 25.6552 10.0608C25.6552 8.8608 26.6365 7.8858 27.8365 7.8858C29.0427 7.8858 30.0177 8.8608 30.0177 10.0608C30.0177 11.2608 29.0427 12.2358 27.8365 12.2358ZM21.7241 12.2358C20.5241 12.2358 19.5428 11.2608 19.5428 10.0608C19.5428 8.8608 20.5241 7.8858 21.7241 7.8858C22.9241 7.8858 23.9053 8.8608 23.9053 10.0608C23.9053 11.2608 22.9241 12.2358 21.7241 12.2358ZM15.6117 7.8858C16.8117 7.8858 17.7929 8.8608 17.7929 10.0608C17.7929 11.2608 16.8117 12.2358 15.6117 12.2358C14.4054 12.2358 13.4304 11.2608 13.4304 10.0608C13.4304 8.8608 14.4054 7.8858 15.6117 7.8858Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
