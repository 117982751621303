import styles from "./ShowMoreRepliesCTA.module.css";

export default function ShowMoreRepliesCTA(props: { onClick: () => void }) {
  return (
    <div onClick={() => props.onClick()} className={styles.main}>
      <div className={styles.line}></div>
      <p className={styles.text}>Show more replies</p>
    </div>
  );
}
