export default function CheckMarkIcon() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "2px solid white",
      }}
    >
      <svg
        width="12"
        height="9"
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.77318 5.373L10.1859 0L12 1.80044L4.74572 9L2.9316 7.20065L2.95916 7.17331L0 4.23647L1.81412 2.43713L4.77318 5.373Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
