import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommentReply } from "@dimensional-engineering/dimensional-models";

import styles from "./CommentModal.module.css";

import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import {
  createComment,
  deleteComment,
  fetchMoreThreads,
  replyToComment,
} from "./utils";
import CommentThread from "./CommentThread/CommentThread";
import { CommentWithReply } from "@/models/sharedModels";
import Loader from "@/components/shared/Loader";
import { RootState } from "@/redux/store";
import { setLastComment } from "@/redux/slices/commentsSlice";
import CommentTextBox from "./CommentTextBox/CommentTextBox";
import useCommentVotesListener from "@/helpers/useCommentVoteHook";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import SystemDialog from "@/components/shared/SystemDialog/SystemDialog";
import { Mixpanel } from "@/helpers/mixpanel";

type Props = {
  onClose: () => void;
  source: "daily insight";
  slug: string;
  audienceGroupSize: number;
  commentId?: string;
};

export default function CommentModal(props: Props) {
  const dispatch = useDispatch();

  const { setAlertComponent } = useAlertContext();

  const commentsData = useSelector((state: RootState) => state.comments);
  useCommentVotesListener(commentsData.commentThreadId);
  ///positioning of the modal
  const [startY, setStartY] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [yPosition, setYPosition] = useState<number>(0);
  const [diffY, setDiffY] = useState(0);

  //replying data
  const [replyingUsername, setReplayingUsername] = useState<string | null>(
    null
  );
  const [parentCommentId, setParentCommentId] = useState<string | null>(null);
  const [authorId, setAuthorId] = useState<string | null>(null);

  //deletion state for system dialog
  const [deletionData, setDeletionData] = useState<{
    commentThreadId: string;
    commentId: string;
    body: string;
    replyId?: string | null | undefined;
  } | null>(null);

  //comments ux updating
  const [comments, setComments] = useState<CommentWithReply[]>([]);
  const [deletionLoading, setDeletionLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [loadingSubmission, setLoadingSubmission] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<HTMLDivElement>(null);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    const currentY = e.touches[0].clientY;
    const newDiffY = currentY - startY;
    if (newDiffY > 0) {
      setDiffY(newDiffY);
      setYPosition(newDiffY);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setYPosition(0);

    if (diffY > window.screen.height / 2) {
      props.onClose();
    }

    setDiffY(0);
  };

  function handleClose() {
    if (ref.current) {
      ref.current.style.marginTop = "100vh";
    }
    setTimeout(() => {
      props.onClose();
    }, 300);

    ///this handles the slide down on close
  }

  const handleScroll = () => {
    if (wrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
      // Check if scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 2) {
        getMoreReplies();
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (ref.current) {
        ref.current.style.marginTop = "0";
      }
    }, 200);

    // Clear timeout when the component unmounts or useEffect re-runs
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setComments(commentsData.comments);
  }, [commentsData.comments.length]);

  useEffect(() => {
    if (!commentsData.loading && props.commentId && commentRef.current) {
      commentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [commentsData.loading, props.commentId, commentRef.current]);

  function postComment(body: string) {
    if (body.length > 2047) {
      return setAlertComponent(
        <Alert
          elementName="Alert"
          type="fail"
          message="Comments cannot exceed 2048 characters."
        />,
        3000
      );
    }
    setLoadingSubmission(true);
    if (commentsData.commentThreadId) {
      Mixpanel?.track("Comment created", {
        type: replyingUsername ? "reply" : "root comment",
        source: props.source,
        slug: props.slug,
        body: body,
        audience_group_size: props.audienceGroupSize,
      });
      if (!replyingUsername) {
        return createComment(commentsData.commentThreadId, body)
          .then((res) => {
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="success"
                message="Comment posted"
              />,
              3000
            );

            setLoadingSubmission(false);

            //@ts-ignore
            setComments((prevComments) => [res.data.comment, ...prevComments]);
            setParentCommentId(null);
            setAuthorId(null);
            setReplayingUsername(null);
            if (wrapperRef.current) {
              wrapperRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          })
          .catch((err) => console.log(err));
      } else {
        if (parentCommentId && authorId) {
          return replyToComment(
            commentsData.commentThreadId,
            parentCommentId,
            authorId,
            body
          )
            .then((res) => {
              setAlertComponent(
                <Alert
                  elementName="Alert"
                  type="success"
                  message="Comment posted"
                />,
                3000
              );
              setLoadingSubmission(false);
              //@ts-ignore
              const reply = res.data.commentReply as CommentReply;

              setComments((prevComments) =>
                prevComments.map((comment) => {
                  if (comment.id === reply.parentCommentId) {
                    return {
                      ...comment,
                      replies: comment.replies
                        ? [reply, ...comment.replies]
                        : [reply],
                    };
                  }
                  return comment;
                })
              );

              setParentCommentId(null);
              setAuthorId(null);
              setReplayingUsername(null);
            })
            .catch((err) => {
              console.log(err);
              setLoadingSubmission(false);
              setAlertComponent(
                <Alert
                  elementName="Alert"
                  type="fail"
                  message="Comment failed to post."
                />,
                3000
              );
            });
        }
      }
    } else {
      setAlertComponent(
        <Alert
          elementName="Alert"
          type="fail"
          message="Comment failed to post."
        />,
        3000
      );
      setLoadingSubmission(false);
    }
  }

  async function getMoreReplies() {
    if (commentsData.commentThreadId) {
      await fetchMoreThreads(
        commentsData.lastComment,
        commentsData.commentThreadId
      )
        .then((res) => {
          res.comments.forEach((comment) => {
            const found = comments.find((c) => c.id === comment.id);
            if (!found) {
              setComments((current) => [...current, comment]);
            }
          });

          if (res.lastComment) {
            dispatch(setLastComment(res.lastComment));
          }
        })
        .catch((err) => {});
    }
  }

  async function handleDeleteComment(
    commentThreadId: string,
    commentId: string,
    body: string,
    replyId?: string | null | undefined
  ) {
    setDeletionLoading(true);
    await deleteComment(commentThreadId, commentId, replyId)
      .then(() => {
        Mixpanel?.track("Comment deleted", {
          type: replyId ? "reply" : "root comment",
          source: props.source,
          slug: props.slug,
          body: body,
          audience_group_size: props.audienceGroupSize,
        });
        setComments((prevComments) => {
          const updatedComments = prevComments
            .map((comment) => {
              // If the comment matches the deleted comment
              if (comment.id === commentId) {
                if (!replyId) {
                  // If no replyId, remove the whole comment
                  return null;
                } else if (comment.replies) {
                  // If replyId exists, remove the specific reply
                  return {
                    ...comment,
                    replies: comment.replies.filter(
                      (reply) => reply.id !== replyId
                    ),
                  };
                }
              }
              return comment;
            })
            .filter((comment): comment is CommentWithReply => comment !== null); // Type assertion

          return updatedComments;
        });
        setDeletionData(null);
        setDeletionLoading(false);
      })
      .catch(() => {
        setDeletionLoading(false);
      });
  }

  const mappedComments = comments.map((comment) => {
    return (
      <div
        ref={props.commentId === comment.id ? commentRef : null}
        key={comment.id}
      >
        <CommentThread
          onReply={(username, parentCommentId, authorId) => {
            setReplayingUsername(username);
            setParentCommentId(parentCommentId);
            setAuthorId(authorId);
          }}
          comment={comment}
          onDelete={(commentThreadId, commentId, body, replyId) => {
            setDeletionData({ commentThreadId, commentId, body, replyId });
            setShowDialog(true);
          }}
          source={props.source}
          slug={props.slug}
          audienceGroupSize={props.audienceGroupSize}
        />
      </div>
    );
  });

  return (
    <>
      {deletionLoading && (
        <div className={styles.loadingDivDeletion}>
          <Loader mobileHeight="500px" height="500px" />
        </div>
      )}
      {showDialog && (
        <SystemDialog
          body="Delete comment?"
          cancelText="Cancel"
          approveText="Delete"
          onApprove={() => {
            if (deletionData) {
              setShowDialog(false);
              handleDeleteComment(
                deletionData.commentThreadId,
                deletionData.commentId,
                deletionData.body,
                deletionData.replyId
              ).then(() => {
                setAlertComponent(
                  <Alert
                    type="success"
                    message="Comment deleted"
                    elementName="Alert"
                  />,
                  3000
                );
              });
            }
          }}
          onCancel={() => setShowDialog(false)}
        />
      )}
      <main
        style={yPosition !== 0 ? { top: yPosition } : {}}
        className={styles.main}
        ref={ref}
      >
        <section
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          className={styles.header}
        >
          <div className={styles.line}></div>
          <p className={styles.headerTitle}>Comments</p>
          <div onClick={() => handleClose()} className={styles.closeButtonDiv}>
            <CloseButtonSummary />
          </div>
        </section>
        {commentsData.loading ? (
          <Loader height="300px" mobileHeight="300px" />
        ) : (
          <>
            {mappedComments.length ? (
              <section
                onScroll={handleScroll}
                ref={wrapperRef}
                className={styles.wrapper}
              >
                {mappedComments}
              </section>
            ) : (
              <CommentModalGhostbox />
            )}
            <section className={styles.bottom}>
              <CommentTextBox
                replyingUsername={replyingUsername}
                onMessage={(m) => {
                  postComment(m);
                }}
                onCancel={() => setReplayingUsername(null)}
                loading={loadingSubmission}
              />
            </section>
          </>
        )}
      </main>
    </>
  );
}

function CommentModalGhostbox() {
  return (
    <div className={styles.ghostboxParent}>
      <p className={styles.ghostboxText}>No comments yet. Be the first!</p>
    </div>
  );
}
