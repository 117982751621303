import { useSelector } from "react-redux";
import { DiscoveredCompatibilitySnippet } from "@dimensional-engineering/dimensional-models";

import { FriendsPublicProfile } from "@/models/sharedModels";
import styles from "../CompatibilitySnippets.module.css";
import { RootState } from "@/redux/store";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import ThickForwardArrow from "@/components/shared/buttons/ThickForwardArrow";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilityLlmHeaderedListView(props: Props) {
  const userState = useSelector((state: RootState) => state.user);

  const mapped = [...(props.snippet?.snippetDetail.llmHeaderedLists || [])]
    .sort((a, b) => {
      if (
        a.userData?.sourceUserUid === userState.publicProfileData?.ownerUUID
      ) {
        return -1;
      } else {
        return 0;
      }
    })
    .map((d, i) => {
      return (
        <div
          style={i === 0 ? { marginTop: "15px" } : {}}
          className={styles.llmListDiv}
          key={i}
        >
          <div className={styles.headeredListTop}>
            {userState.publicProfileData?.ownerUUID ===
            d.userData?.sourceUserUid ? (
              <SmallProfileImage
                imageUrl={userState.imageURL ?? "/default-image.png"}
                publicProfile={userState.publicProfileData}
                medium
                noLink
              />
            ) : (
              <ComparedMediumProfileImage
                imageUrl={props.connection?.imageUrl ?? "/default-image.png"}
                publicProfile={props.connection}
                noLink
              />
            )}
            <div className={styles.arrowDiv}>
              <ThickForwardArrow />
            </div>
            {userState.publicProfileData?.ownerUUID !==
            d.userData?.sourceUserUid ? (
              <SmallProfileImage
                imageUrl={userState.imageURL ?? "/default-image.png"}
                publicProfile={userState.publicProfileData}
                medium
                noLink
              />
            ) : (
              <ComparedMediumProfileImage
                imageUrl={props.connection?.imageUrl ?? "/default-image.png"}
                publicProfile={props.connection}
                noLink
              />
            )}
          </div>
          <h3 className={styles.headeredListTitle}>{d.header}</h3>
          <div className={styles.headerdListLine}></div>
          {d.llmContent.map((c, index) => {
            return (
              <div className={styles.headeredListContentDiv} key={index}>
                <p className={styles.tileNumber}>~ {index + 1} ~</p>
                <h4
                  style={{ fontSize: "22px" }}
                  className={styles.llmListTitle}
                >
                  {c.title}
                </h4>
                <p className={styles.llmListParagraph}>{c.paragraph}</p>
              </div>
            );
          })}
        </div>
      );
    });

  return (
    <div style={{ marginTop: "35px" }} className={styles.spectrumsParent}>
      {mapped}
    </div>
  );
}
