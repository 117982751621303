import {
  PeerAssessmentEvent,
  PeerAssessmentItem,
  PeerAssessmentItemResponse,
  PeerAssessmentResult,
  PeerDichotomyItemSet,
  PeerDichotomyItemSetResponse,
  PeerRankOrderItemSet,
  PeerRankOrderItemSetResponse,
  RankOrderItemResponse,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PeerAssessmentSlice {
  events: PeerAssessmentEvent[];
  ctaRecommendationList: string[];

  peerDichotomyItemSet: PeerDichotomyItemSet | null;
  peerRankOrderItemSet: PeerRankOrderItemSet | null;
  peerAssessmentItems: PeerAssessmentItem[];

  peerDichotomyItemSetResponse: PeerDichotomyItemSetResponse | null;

  peerRankOrderItemResponses: RankOrderItemResponse[];
  peerRankOrderItemSetResponse: PeerRankOrderItemSetResponse | null;

  peerAssessmentItemResponses: PeerAssessmentItemResponse[];

  peerAssessmentResult: PeerAssessmentResult | null;
}

const initialState: PeerAssessmentSlice = {
  events: [],
  ctaRecommendationList: [],

  peerDichotomyItemSet: null,
  peerRankOrderItemSet: null,
  peerAssessmentItems: [],

  peerDichotomyItemSetResponse: null,

  peerRankOrderItemResponses: [],
  peerRankOrderItemSetResponse: null,

  peerAssessmentItemResponses: [],

  peerAssessmentResult: null,
};

export const PeerAssessmentSlice = createSlice({
  name: "peerAssessment",
  initialState: initialState,
  reducers: {
    setPeerAssessmentEvents: (
      state,
      action: PayloadAction<PeerAssessmentEvent[]>
    ) => {
      return { ...state, events: action.payload };
    },
    setCtaRecommendationList: (state, action: PayloadAction<string[]>) => {
      return { ...state, ctaRecommendationList: action.payload };
    },
    setPeerDichotomyItemSet: (
      state,
      action: PayloadAction<PeerDichotomyItemSet>
    ) => {
      return { ...state, peerDichotomyItemSet: action.payload };
    },
    setPeerRankOrderItemSet: (
      state,
      action: PayloadAction<PeerRankOrderItemSet>
    ) => {
      return { ...state, peerRankOrderItemSet: action.payload };
    },
    setPeerAssessmentItems: (
      state,
      action: PayloadAction<PeerAssessmentItem[]>
    ) => {
      return {
        ...state,
        peerAssessmentItems: action.payload.sort((a, b) => {
          if (a.assessmentOrder && b.assessmentOrder) {
            return a.assessmentOrder - b.assessmentOrder;
          } else {
            return 0;
          }
        }),
      };
    },
    setPeerDichotomyItemSetResponse: (
      state,
      action: PayloadAction<PeerDichotomyItemSetResponse | null>
    ) => {
      return { ...state, peerDichotomyItemSetResponse: action.payload };
    },
    addPeerRankOrderResponse: (
      state,
      action: PayloadAction<RankOrderItemResponse>
    ) => {
      return {
        ...state,
        peerRankOrderItemResponses: [
          ...state.peerRankOrderItemResponses,
          action.payload,
        ],
      };
    },
    removePeerRankOrderResponseByIndex: (
      state,
      action: PayloadAction<number>
    ) => {
      const indexToRemove = action.payload;

      if (
        indexToRemove >= 0 &&
        indexToRemove < state.peerRankOrderItemResponses.length
      ) {
        const updatedRankOrderResponses =
          state.peerRankOrderItemResponses.filter(
            (_, index) => index !== indexToRemove
          );

        return {
          ...state,
          peerRankOrderItemResponses: updatedRankOrderResponses,
        };
      }
      return state;
    },
    setRankOrderItemSetResponse: (
      state,
      action: PayloadAction<PeerRankOrderItemSetResponse | null>
    ) => {
      return { ...state, peerRankOrderItemSetResponse: action.payload };
    },
    setPeerAssessmentItemResponses: (
      state,
      action: PayloadAction<PeerAssessmentItemResponse[]>
    ) => {
      return { ...state, peerAssessmentItemResponses: action.payload };
    },
    addPeerAssessmentItemResponse: (
      state,
      action: PayloadAction<PeerAssessmentItemResponse>
    ) => {
      return {
        ...state,
        peerAssessmentItemResponses: [
          ...state.peerAssessmentItemResponses,
          action.payload,
        ],
      };
    },
    removeLastPeerAssessmentItemResponse: (state) => {
      if (state.peerAssessmentItemResponses.length === 0) {
        return state;
      }
      const updatedItemResponses = state.peerAssessmentItemResponses.slice(
        0,
        -1
      );
      return {
        ...state,
        peerAssessmentItemResponses: updatedItemResponses,
      };
    },
    setPeerAssessmentResult: (
      state,
      action: PayloadAction<PeerAssessmentResult>
    ) => {
      return { ...state, peerAssessmentResult: action.payload };
    },
    resetPeerAssessmentResponses: (state) => {
      return {
        ...state,
        peerRankOrderItemSetResponse: null,
        peerRankOrderItemResponses: [],
        peerDichotomyItemSetResponse: null,
        peerAssessmentItemResponses: [],
      };
    },
    resetPeerAssessmentData: () => {
      return initialState;
    },
  },
});

export const {
  setPeerAssessmentEvents,
  setCtaRecommendationList,
  setPeerDichotomyItemSet,
  setPeerRankOrderItemSet,
  setPeerAssessmentItems,
  setPeerDichotomyItemSetResponse,
  addPeerRankOrderResponse,
  removePeerRankOrderResponseByIndex,
  setRankOrderItemSetResponse,
  setPeerAssessmentItemResponses,
  addPeerAssessmentItemResponse,
  removeLastPeerAssessmentItemResponse,
  setPeerAssessmentResult,
  resetPeerAssessmentResponses,
  resetPeerAssessmentData,
} = PeerAssessmentSlice.actions;

export default PeerAssessmentSlice.reducer;
