import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function CognitionIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9271 1.23696C39.029 0.0893132 40.7183 0.0893047 40.8202 1.23695L43.3062 29.2313C43.3737 29.9912 44.2742 30.3616 44.863 29.8717L66.5537 11.8224C67.4429 11.0825 68.6374 12.2687 67.8923 13.1518L49.7174 34.6925C49.224 35.2772 49.597 36.1715 50.3622 36.2385L78.5515 38.7073C79.7071 38.8085 79.7071 40.4861 78.5515 40.5873L50.3622 43.0561C49.597 43.1232 49.224 44.0174 49.7174 44.6022L67.8923 66.1429C68.6374 67.0259 67.4429 68.2122 66.5537 67.4722L44.863 49.423C44.2742 48.933 43.3737 49.3034 43.3062 50.0634L40.8202 78.0577C40.7183 79.2053 39.029 79.2053 38.9271 78.0577L36.4411 50.0634C36.3736 49.3034 35.4731 48.933 34.8843 49.423L13.1936 67.4722C12.3044 68.2121 11.1099 67.026 11.855 66.1429L30.0299 44.6022C30.5233 44.0174 30.1503 43.1232 29.3851 43.0561L1.19583 40.5873C0.0401962 40.4861 0.0401877 38.8085 1.19583 38.7073L29.3851 36.2385C30.1503 36.1715 30.5233 35.2772 30.0299 34.6925L11.855 13.1518C11.1099 12.2687 12.3044 11.0825 13.1936 11.8224L34.8843 29.8717C35.4731 30.3616 36.3736 29.9912 36.4411 29.2313L38.9271 1.23696Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
