import React from "react";
import axios from "axios";
import StackTrace from "stacktrace-js";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    const uid = localStorage.getItem("uid");

    const currentRoute = window.location.href;
    const stackTrace = errorInfo.componentStack;
    const inputDataBackup = `Route: ${currentRoute} \n UID: ${uid}. Error: ${error.toString()}\n\nStack Trace:\n${stackTrace}`;

    StackTrace.fromError(error)
      .then((stackFrames) => {
        const readableStackTrace = stackFrames
          .map(
            (sf) =>
              `${sf.functionName || "anonymous"} at ${sf.fileName}:${
                sf.lineNumber
              }:${sf.columnNumber}`
          )
          .join("\n");

        const inputData = `UID: ${uid}. Error: ${error.toString()}\n\nReadable Stack Trace:\n${readableStackTrace}`;

        console.log("Error:", error);
        console.log("Readable Stack Trace:", readableStackTrace);

        axios({
          method: "POST",
          url: "https://www.dimensional.me/api/error-report",
          data: { error: inputData },
        })
          .then((res) => console.log("Error report sent:", res))
          .catch((err) => console.error("Error reporting failed:", err));

        localStorage.clear();
      })
      .catch((err) => {
        axios({
          method: "POST",
          url: "https://www.dimensional.me/api/error-report",
          data: { error: inputDataBackup },
        })
          .then((res) => console.log("Error report sent:", res))
          .catch((err) => console.error("Error reporting failed:", err));
      });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Oops, there is an error!</h2>
          <button
            type="button"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
