import { LlmRequest } from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ReportsSlice {
  hasLoveReportLlmData: boolean;
  loveReportLlmRequest: LlmRequest | null;
  hasCareerReportLlmData: boolean;
  careerReportLlmRequest: LlmRequest | null;
  hasTherapistReportLlmData: boolean;
  therapistReportLlmRequest: LlmRequest | null;
}

const initialState: ReportsSlice = {
  hasLoveReportLlmData: false,
  loveReportLlmRequest: null,
  hasCareerReportLlmData: false,
  careerReportLlmRequest: null,
  hasTherapistReportLlmData: false,
  therapistReportLlmRequest: null,
};

export const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    setHasLoveReportLlmData: (state, action: PayloadAction<boolean>) => {
      return { ...state, hasLoveReportLlmData: action.payload };
    },
    setLoveReportLlmRequest: (state, action: PayloadAction<LlmRequest>) => {
      return { ...state, loveReportLlmRequest: action.payload };
    },
    setHasCareerReportLlmData: (state, action: PayloadAction<boolean>) => {
      return { ...state, hasCareerReportLlmData: action.payload };
    },
    setCareerReportLlmRequest: (state, action: PayloadAction<LlmRequest>) => {
      return { ...state, careerReportLlmRequest: action.payload };
    },
    setHasTherapistReportLlmData: (state, action: PayloadAction<boolean>) => {
      return { ...state, hasTherapistReportLlmData: action.payload };
    },
    setTherapistReportLlmRequest: (
      state,
      action: PayloadAction<LlmRequest>
    ) => {
      return { ...state, therapistReportLlmRequest: action.payload };
    },
  },
});

export const {
  setHasLoveReportLlmData,
  setLoveReportLlmRequest,
  setHasCareerReportLlmData,
  setCareerReportLlmRequest,
  setHasTherapistReportLlmData,
  setTherapistReportLlmRequest,
} = reportSlice.actions;

export default reportSlice.reducer;
