import { useSelector } from "react-redux";
import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import { peerRatedSnippets, snippetMappedSubtitles } from "./snippetsMap";
import { getElementFromTraitIdentifier } from "@/components/authenticatedProfile/tabs/AuthCompareTab/Compatibility/utils";
import { RootState } from "@/redux/store";
import { getUserElementScore } from "@/components/authenticatedProfile/tabs/AuthTraitsTab/utils";
import { getAggregatePeerPercentileScore } from "@/components/peerAssessments/utils";
import { ExtendedScale } from "@/models/sharedModels";
import { natureData } from "@/assets/traitInfo/natureData";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import ScoreRowSnippet from "@/components/premium/reports/you/MotivationalTheme/ScoreRowSnippet";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};
export default function SnippetPeerRatedTraitsDeltaView(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const { setModalComponent } = useModalContext();

  const mapped = props.snippet.snippetDetail.peerRatedTraitsDeltaDetail?.map(
    (traitIDentifier) => {
      const element = getElementFromTraitIdentifier(
        traitIDentifier
      ) as ExtendedScale;
      const peerElement = userState.aggregatePeerScores?.find(
        (a) => a.traitSlug === traitIDentifier.slug
      );
      if (element && peerElement) {
        const percentileScore = getUserElementScore(
          element.slug,
          userState.elementsDiscovered
        );
        const peerScore = getAggregatePeerPercentileScore(peerElement);
        const backgroundColor = natureData?.find(
          (n) => n.slug == element.nature
        )?.color;

        return (
          <div className={styles.deltaRowDiv} key={traitIDentifier.slug}>
            <button
              style={
                backgroundColor
                  ? { border: `2px solid ${backgroundColor}` }
                  : { border: `2px solid grey` }
              }
              onClick={() =>
                setModalComponent(<ElementModal slug={element.slug} />)
              }
              className={styles.deltatraitButton}
            >
              {element.alias ?? element.name}
            </button>
            <ScoreRowSnippet
              score={percentileScore ? percentileScore * 100 : null}
            />
            <ScoreRowSnippet
              peerRated
              score={peerScore ? peerScore * 100 : null}
            />
          </div>
        );
      }

      return null;
    }
  );

  return (
    <>
      {props.snippet.snippetDetail.peerRatedTraitsDeltaDetail && (
        <>
          <h2
            className={
              peerRatedSnippets.includes(props.snippet?.snippetSlug)
                ? styles.llmListNamePeer
                : styles.llmListName
            }
          >
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <div className={styles.natureLegendsDiv}>
            <div className={styles.natureLegendsWrapper}>
              <div className={styles.selfRatedLegendIcon}></div>
              <p className={styles.natureLegendText}>Self-rated</p>
            </div>
            <div className={styles.natureLegendsWrapper}>
              <div className={styles.peerRatedLegendIcon}></div>
              <p className={styles.natureLegendText}>Peer-rated</p>
            </div>
          </div>
          <div className={styles.deltaTraitWrapper}>{mapped}</div>
        </>
      )}
    </>
  );
}
