/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./Histogram.module.css";

import {
  ProfileColorMap,
  ProfileSecondaryColorMap,
} from "@/components/shared/ColorMap";
import useIsMobile from "@/helpers/useIsMobile";
import { useAuth } from "@/context/AuthContext";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

type Props = {
  data: number[] | null | undefined;
  users: UserData[];
};

type UserData = {
  score: number | null | undefined;
  publicProfile: PublicProfile | null;
  imageUrl: string | null;
};

export default function CompareHistogram(props: Props) {
  const [multiplier, setMultiplier] = useState<number>(0);
  const isMobile = useIsMobile();
  const { user } = useAuth();
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let high = 0;
    if (props.data) {
      props.data.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(210 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.data?.map((point, index) => {
    return (
      <div
        className={styles.histogramBarCompare}
        style={{ height: `${point * 100 * multiplier}px` }}
        key={index}
      ></div>
    );
  });

  const mappedUsers = props.users.map((person) => {
    const isConnection = person.publicProfile?.ownerUUID !== user?.uid;
    const color = isConnection
      ? person.publicProfile?.primaryNature?.nature
        ? person.publicProfile?.primaryNature?.nature ===
          userState.publicProfileData?.primaryNature?.nature
          ? ProfileSecondaryColorMap[
              person.publicProfile?.primaryNature
                ?.nature as keyof typeof ProfileSecondaryColorMap
            ]
          : ProfileColorMap[
              person.publicProfile?.primaryNature
                ?.nature as keyof typeof ProfileColorMap
            ]
        : "#55504F"
      : ProfileColorMap[
          person.publicProfile?.primaryNature
            ?.nature as keyof typeof ProfileColorMap
        ];
    if (person.score) {
      return (
        <div
          style={
            isMobile
              ? { right: `${person.score * 99}%` }
              : { right: `${person.score * 95}%` }
          }
          className={styles.averageScoreDiv}
          key={person.publicProfile?.ownerUUID}
        >
          <div
            style={{ backgroundColor: color }}
            className={styles.userScoreLine}
          ></div>
          <div
            style={{ border: `3px solid ${color}` }}
            className={styles.userScoreCircle}
          >
            <img src={person.imageUrl as string} alt="" />
          </div>
        </div>
      );
    }
  });

  return (
    <>
      <div
        style={{ justifyContent: "space-between" }}
        className={styles.histogramParent}
      >
        {mappedUsers} {mapped}
      </div>
      <div style={{ paddingRight: "12px" }} className={styles.labels}>
        <p>0</p>
        <p>25</p>
        <p>50</p>
        <p>75</p>
        <p>100</p>
      </div>
    </>
  );
}
