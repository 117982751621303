export function replaceTitle(title: string, notOwn?: boolean): string {
  let returnString = (" " + title).slice(1);
  if (title.includes("<Your|Their>")) {
    notOwn
      ? (returnString = title.replace("<Your|Their>", "Their"))
      : (returnString = title.replace("<Your|Their>", "Your"));
  }
  if (title.includes("<You|They>")) {
    notOwn
      ? (returnString = title.replace("<You|They>", "They"))
      : (returnString = title.replace("<You|They>", "You"));
  }
  if (title.includes("<You|Them>")) {
    notOwn
      ? (returnString = title.replace("<You|Them>", "Them"))
      : (returnString = title.replace("<You|Them>", "You"));
  }
  if (title.includes("<your|their>")) {
    notOwn
      ? (returnString = title.replace("<your|their>", "their"))
      : (returnString = title.replace("<your|their>", "your"));
  }
  if (title.includes("<you|they>")) {
    notOwn
      ? (returnString = title.replace("<you|they>", "they"))
      : (returnString = title.replace("<you|they>", "you"));
  }
  if (title.includes("<you|them>")) {
    notOwn
      ? (returnString = title.replace("<you|them>", "them"))
      : (returnString = title.replace("<you|them>", "you"));
  }
  if (title.includes("<You're|They're>")) {
    notOwn
      ? (returnString = title.replace("<You're|They're>", "They're"))
      : (returnString = title.replace("<You're|They're>", "You're"));
  }

  if (returnString.includes("<")) {
    return replaceTitle(returnString, notOwn);
  }

  return returnString;
}

export function replaceDescription(desc: string, notOwn?: boolean) {
  let indexOne = desc.indexOf("<");
  let indexTwo = desc.indexOf(">");
  let indexThree = desc.indexOf("|");
  let owncontent = desc.slice(indexOne + 1, indexThree);
  let theirContent = " " + desc.slice(indexThree + 1, indexTwo);
  let descReplacement = desc.slice(indexOne, indexTwo + 1);
  let string = notOwn
    ? desc.replace(descReplacement, theirContent)
    : desc.replace(descReplacement, owncontent);
  if (string.includes("|")) {
    string = replaceDescription(string, notOwn);
  }
  return string;
}
