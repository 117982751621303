import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityLoveArchetypesIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.904 34.7758C36.4648 34.7758 37.4337 33.0788 36.6403 31.7347L21.7365 6.48595C20.9563 5.16419 19.0441 5.16419 18.2639 6.48595L3.36014 31.7347C2.56678 33.0788 3.5357 34.7758 5.09644 34.7758H34.904ZM20.0278 26.1007C21.3849 26.1007 22.4851 25.0006 22.4851 23.6435C22.4851 22.2864 21.3849 21.1862 20.0278 21.1862C18.6707 21.1862 17.5705 22.2864 17.5705 23.6435C17.5705 25.0006 18.6707 26.1007 20.0278 26.1007Z"
        fill="#FFFFF2"
      />
      <path
        d="M39.6199 1.64102C39.1989 0.843941 36.5595 -1.82517 33.5 2.03918C30.2869 -1.82517 27.8003 0.843943 27.38 1.64102C26.6148 3.11489 27.0738 5.34626 28.1453 6.42197L33.5007 12L38.8561 6.42197C39.9261 5.34626 40.3851 3.11566 39.6199 1.64102Z"
        fill="#FFA0B9"
      />
    </svg>
  );
}
