export default function DownArrowIcon(props: { dark?: boolean }) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.11892 0.21921L4.74647 3.59008L1.37403 0.219209C1.05227 -0.080601 0.550714 -0.0719188 0.239162 0.239128C-0.0718798 0.55068 -0.0805611 1.05224 0.219244 1.374L4.16881 5.32357L4.16881 5.32408C4.48803 5.64279 5.0049 5.64279 5.32411 5.32408L9.27368 1.37451L9.27368 1.374C9.57349 1.05224 9.56481 0.55068 9.25376 0.239129C8.94221 -0.0719128 8.44068 -0.0805951 8.11892 0.21921Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
      />
    </svg>
  );
}
