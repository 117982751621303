import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityAttachmentStyleIcon(
  props: DimensionIconProps
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8175 6.17915C31.5445 4.83044 28.3588 5.14037 26.4144 6.96419C24.2503 8.99576 23.891 12.1513 22.0181 14.3787C21.412 15.1024 20.5079 15.6661 19.5697 15.5707C15.9584 15.1705 15.3795 8.44062 12.7233 6.47716C11.7998 5.8142 10.708 5.42523 9.57344 5.35493C2.09029 4.81 1.30707 14.7874 1.33261 20.1703C1.31899 23.1878 1.62377 26.3178 2.50744 29.2093C3.01824 30.9003 3.79294 32.7429 5.24701 33.843C7.29019 35.3909 10.7108 35.3756 12.7523 33.843C15.4016 31.854 15.9243 25.1275 19.6157 24.7869C20.467 24.7085 21.2911 25.1496 21.8802 25.7626C24.0766 28.048 24.2213 31.6803 26.7702 33.7255C28.7146 35.2853 31.6875 35.3756 33.8481 34.204C38.2904 31.7961 38.3363 21.238 38.3312 20.1635C38.35 15.9385 38.0707 9.57476 34.4866 6.63894C34.2757 6.46872 34.052 6.315 33.8175 6.17915Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
