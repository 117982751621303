export default function InternalConflictSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 12L16.5 20V16H32.5V8H16.5V4L4.5 12Z" fill="#FFFFF2" />
      <path d="M24.5 24H8.5V32H24.5V36L36.5 28L24.5 20V24Z" fill="#FFFFF2" />
    </svg>
  );
}
