import {
  DiscoverableSnippet,
  DiscoveredSnippet,
  Nature,
} from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";
import { useSelector } from "react-redux";

import styles from "./Snippets.module.css";

import { peerRatedSnippets, snippetMappedSubtitles } from "./snippetsMap";
import { RootState } from "@/redux/store";
import { natureData } from "@/assets/traitInfo/natureData";
import { VeryLargeProfileImageWithNature } from "@/components/shared/SmallProfileImage/LargeProfileImage";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function SnippetPeerRatedNature(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const userPeerNatureData = natureData?.find(
    (n) =>
      n.slug ===
      props.snippet.snippetDetail.peerRatedNatureDetail?.peerRatedNature.nature
  );
  const userNatureData = natureData?.find(
    (n) => n.slug === userState.publicProfileData?.primaryNature?.nature
  );

  const mappedNatureData = natureData
    .sort((a, b) => {
      const sortMap = {
        chaos: 0,
        opennessAndIntellect: 1,
        warmthAndAgreeableness: 2,
        orderAndStructure: 3,
        tranquility: 4,
        energyAndExcitement: 5,
        selfEnhancement: 6,
      };

      return (
        sortMap[a.slug as keyof typeof sortMap] -
        sortMap[b.slug as keyof typeof sortMap]
      );
    })
    .map((n) => {
      const selfNatureBreakdown =
        userState.publicProfileData?.primaryNature?.natureBreakdown;
      const peerNatureBreakdown =
        props.snippet.snippetDetail.peerRatedNatureDetail?.peerRatedNature
          .natureBreakdown;

      const selfNatureScore = selfNatureBreakdown
        ? selfNatureBreakdown[n.slug as keyof typeof Nature] * 100
        : 0;

      const peerNatureScore = peerNatureBreakdown
        ? peerNatureBreakdown[n.slug as keyof typeof Nature] * 100
        : 0;

      return (
        <div className={styles.natureRow} key={n.slug}>
          <div className={styles.natureRowTopDiv}>
            <div
              style={{ background: n.color }}
              className={styles.natureCircle}
            ></div>
            <p className={`${font.className} ${styles.natureTopRowName}`}>
              {n.name}
            </p>
          </div>
          <div className={styles.natureScoreParent}>
            <div
              style={{ width: `${peerNatureScore}%` }}
              className={styles.natureScorePeer}
            ></div>
          </div>
          <div className={styles.natureScoreParent}>
            <div
              style={{ width: `${selfNatureScore}%` }}
              className={styles.natureScore}
            ></div>
          </div>
        </div>
      );
    });

  return (
    <>
      {props.snippet.snippetDetail.peerRatedNatureDetail && (
        <>
          <h2
            className={
              peerRatedSnippets.includes(props.snippet?.snippetSlug)
                ? styles.llmListNamePeer
                : styles.llmListName
            }
          >
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <h5 className={`${font.className} ${styles.accordingTextNature}`}>
            ACCORDING TO OTHERS
          </h5>
          <h4
            style={{ background: `${userPeerNatureData?.color}` }}
            className={styles.natureTitle}
          >
            {userPeerNatureData?.name}
          </h4>
          <p className={styles.natureDescription}>
            {userPeerNatureData?.description}
          </p>
          <div className={styles.natureImagesDiv}>
            <VeryLargeProfileImageWithNature
              nature={
                props.snippet.snippetDetail.peerRatedNatureDetail
                  ?.peerRatedNature.nature
              }
              imageUrl={userState.imageURL ?? ""}
            />
            <div className={styles.selfNatureImage}>
              <VeryLargeProfileImageWithNature
                nature={userState.publicProfileData?.primaryNature?.nature}
                imageUrl={userState.imageURL ?? ""}
              />
            </div>
          </div>
          <div className={styles.selfNatureDetailsDiv}>
            <h5
              style={{ marginTop: "0" }}
              className={`${font.className} ${styles.accordingTextNature}`}
            >
              ACCORDING TO YOU
            </h5>
            <h4
              style={{
                background: `${userNatureData?.color}`,
              }}
              className={styles.natureTitle}
            >
              {userNatureData?.name}
            </h4>
            <p
              style={{ textAlign: "end" }}
              className={styles.natureDescription}
            >
              {userPeerNatureData?.description}
            </p>
          </div>
          <div className={styles.natureLegendsDiv}>
            <div className={styles.natureLegendsWrapper}>
              <div className={styles.peerRatedLegendIcon}></div>
              <p className={styles.natureLegendText}>Peer-rated</p>
            </div>
            <div className={styles.natureLegendsWrapper}>
              <div className={styles.selfRatedLegendIcon}></div>
              <p className={styles.natureLegendText}>Self-rated</p>
            </div>
          </div>
          <div className={styles.natureRowWrapper}>{mappedNatureData}</div>
        </>
      )}
    </>
  );
}
