import { useEffect, useState } from "react";
import styles from "../CompatibilitySnippets.module.css";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { ContentType } from "@dimensional-engineering/dimensional-models";
import Loader from "@/components/shared/Loader";

type Props = {
  peerUid: string | undefined;
};

export default function SexualCompatibilityBars(props: Props) {
  const { loading, computeData } = useGetSexualCompatibilityBarsData({
    peerUid: props.peerUid,
  });

  const othersMapped = computeData
    ?.filter((row) => row.title !== "overall")
    .map((row, index) => {
      if (row.score !== null && row.score !== undefined) {
        return (
          <div className={styles.otherScoreRowParent} key={index}>
            <div className={styles.otherScoreRowTopDiv}>
              <p>{row.title.toUpperCase()}</p>
              <p>{(row.score * 100).toFixed(0)}%</p>
            </div>
            <div className={styles.sexualBarTopOverallParent}>
              <div className={styles.overallScoreDivBarOutsideSmall}></div>
              <div
                style={{
                  width: `${row.score * 100}%`,
                }}
                className={styles.sexualScoreBarInsideSmall}
              ></div>
            </div>
          </div>
        );
      }
    });

  const overallScore = computeData?.find(
    (row) => row.title === "overall"
  )?.score;

  if (loading) {
    return <Loader height="200px" mobileHeight="200px" />;
  }

  return (
    <div className={styles.sexualScoresDiv}>
      <div className={styles.sexualBarTop}>
        <div className={styles.sexualBarTopTextDiv}>
          <p>OVERALL COMPATIBILITY</p>
          <p>{overallScore ? (overallScore * 100).toFixed(0) : 0}%</p>
        </div>
        <div className={styles.sexualBarTopOverallParent}>
          <div className={styles.overallScoreDivBarOutside}></div>
          <div
            style={
              overallScore
                ? {
                    width: `${overallScore * 100}%`,
                  }
                : {}
            }
            className={styles.sexualScoreBarInside}
          ></div>
        </div>
      </div>

      <div className={styles.sexualBarBottom}>{othersMapped}</div>
    </div>
  );
}

type CompatibilityBarsData = {
  title: string;
  score: number | null | undefined;
};

function useGetSexualCompatibilityBarsData(props: {
  peerUid: string | undefined;
}) {
  const [computeData, setComputeData] = useState<
    CompatibilityBarsData[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    async function getCompatibilityData() {
      if (props.peerUid) {
        const call = callFirebaseFunctions("computeCompatibility");
        const promises = [
          call({
            slug: "compatibility-compute-love-styles",
            peerUid: props.peerUid,
          }).then((res) => {
            const data = res.data as { score: number | null | undefined };
            return {
              title: "love styles",
              score: data.score,
            };
          }),
          call({
            slug: "compatibility-compute-romantic-lifestyle",
            peerUid: props.peerUid,
          }).then((res) => {
            const data = res.data as { score: number | null | undefined };
            return {
              title: "lifestyle",
              score: data.score,
            };
          }),
          call({
            slug: "compatibility-compute-romantic-overall",
            peerUid: props.peerUid,
          }).then((res) => {
            const data = res.data as { score: number | null | undefined };
            return {
              title: "overall",
              score: data.score,
            };
          }),
          call({
            slug: "compatibility-compute-romantic-personality",
            peerUid: props.peerUid,
          }).then((res) => {
            const data = res.data as { score: number | null | undefined };
            return {
              title: "personality",
              score: data.score,
            };
          }),
          call({
            slug: "compatibility-compute-sex",
            peerUid: props.peerUid,
          }).then((res) => {
            const data = res.data as { score: number | null | undefined };
            return {
              title: "sexuality",
              score: data.score,
            };
          }),
        ];

        return await Promise.all(promises);
      }
    }
    getCompatibilityData()
      .then((res) => {
        setLoading(false);
        setComputeData(res as CompatibilityBarsData[]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.peerUid]);

  return { loading, computeData };
}
