export default function HigherThanAllUsersIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="18" y="33.4805" width="4" height="9.52" rx="2" fill="#FFFFF2" />
      <rect x="25" y="21.2402" width="4" height="21.76" rx="2" fill="#FFFFF2" />
      <rect x="46" y="29.4004" width="4" height="13.6" rx="2" fill="#FFFFF2" />
      <rect x="39" y="17.1602" width="4" height="25.84" rx="2" fill="#FFFFF2" />
      <rect x="32" y="9" width="4" height="34" rx="2" fill="#FFFFF2" />
      <path
        d="M1.22755 36.1646C1.99049 36.9263 3.22583 36.9263 3.98755 36.1646L13.4272 26.7249H13.4285C14.1902 25.962 14.1902 24.7266 13.4285 23.9637L3.98877 14.524H3.98755C3.21852 13.8074 2.01977 13.8282 1.27515 14.5716C0.531739 15.3162 0.510989 16.515 1.22754 17.284L9.2841 25.3443L1.22754 33.4046C0.46582 34.1663 0.465833 35.4016 1.22755 36.1646Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
