export default function QuestionsToAskIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55899 20.6V23.8406C3.55899 24.2 3.71837 24.6 3.99963 24.8406C4.24026 25.0813 4.59963 25.2 4.91995 25.2C5.27933 25.2 5.64027 25.0406 5.87931 24.8L9.99971 20.6796H22.4793C24.4793 20.6796 26.12 19.039 26.12 17.039V5.99977C26.12 3.99977 24.4793 2.35913 22.4793 2.35913H4.27931C2.27931 2.35913 0.638672 3.99977 0.638672 5.99977V17.0406C0.640234 18.7593 1.87931 20.2406 3.55899 20.6ZM9.55899 8.6C10.0387 8.12032 10.7184 7.84064 11.3996 7.84064C12.0793 7.84064 12.759 8.12032 13.2403 8.6L13.3996 8.75937L13.559 8.6C14.0387 8.12032 14.7184 7.84064 15.3997 7.84064C16.0793 7.84064 16.759 8.12032 17.2403 8.6C17.72 9.07968 17.9997 9.75936 17.9997 10.4406C17.9997 11.1203 17.72 11.8 17.2403 12.2813L13.6809 15.8406C13.5997 15.9594 13.4794 16 13.3997 16C13.2794 16 13.1997 15.9594 13.12 15.8797L9.56065 12.3203C9.08097 11.8407 8.80129 11.161 8.80129 10.4797C8.7591 9.75938 9.04026 9.12032 9.55899 8.6Z"
        fill="#FFFFF2"
      />
      <path
        d="M35.7193 14.8H28.7585V17C28.7585 20.4797 25.9585 23.2796 22.4789 23.2796H13.8789V29.4796C13.8789 31.4796 15.5195 33.1202 17.5195 33.1202H29.9991L34.1195 37.2406C34.3602 37.4812 34.7195 37.6406 35.0789 37.6406C35.4383 37.6406 35.7586 37.5203 35.9992 37.2812C36.2789 37.0406 36.4399 36.6406 36.4399 36.2812V33.0406C38.0805 32.6812 39.3602 31.2406 39.3602 29.4812L39.3586 18.4404C39.3586 16.4404 37.7193 14.8 35.7193 14.8Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
