export default function CommentLikeIcon(props: { liked?: boolean }) {
  if (props.liked === true) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M15.5906 4.77777C15.1372 3.91427 12.2949 1.02274 8.99996 5.20911C5.53971 1.02274 2.86188 3.91427 2.40928 4.77777C1.58515 6.37447 2.07947 8.79178 3.23341 9.95714L9.00075 16L14.7681 9.95714C15.9205 8.79178 16.4148 6.3753 15.5907 4.77777H15.5906Z"
          fill="#FF7878"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.53661 2.16556C4.52481 2.16556 3.51089 2.58041 2.75219 3.40306C1.23555 5.04767 1.23695 7.65772 2.75219 9.30364L8.60219 15.6598C8.70485 15.7709 8.84829 15.8335 8.99875 15.8335C9.14992 15.8335 9.29335 15.7709 9.39531 15.6598C11.3465 13.5441 13.2941 11.4249 15.2453 9.30922C16.762 7.66462 16.762 5.05312 15.2453 3.40864C13.7287 1.76416 11.1989 1.76404 9.68223 3.40864L9.00161 4.14553L8.31536 3.40303C7.5567 2.58037 6.54841 2.16553 5.53652 2.16553L5.53661 2.16556ZM5.53661 3.21745C6.24887 3.21745 6.95973 3.52401 7.52219 4.13433L8.60781 5.30995C8.71046 5.42104 8.8539 5.48362 9.00436 5.48362C9.15554 5.48362 9.29897 5.42104 9.40092 5.30995L10.4809 4.13995C11.6059 2.92003 13.3271 2.92003 14.4521 4.13995C15.5771 5.35986 15.5771 7.35817 14.4521 8.57803C12.6344 10.5496 10.819 12.524 9.0015 14.4955L3.55092 8.57245C2.42662 7.35041 2.42592 5.35423 3.55092 4.13437C4.11342 3.52406 4.82428 3.21748 5.5365 3.21748L5.53661 3.21745Z"
        fill="#928E8C"
      />
    </svg>
  );
}
