import {
  ArchetypeDiscovery,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Cartesian.module.css";

import { ExtendedArchetype } from "@/models/sharedModels";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";
import ArchetypeBreakdownHeading from "../../Heading/ArchetypeBreakdownHeading";
import { normalizeData } from "@/components/traits/utils";

export default function CartesianModal(props: {
  archetype: ExtendedArchetype;
  publicProfile: PublicProfile | null;
  archetypeDiscovery: ArchetypeDiscovery | undefined;
}) {
  const imageUrl = useGetProfilePhoto(props.publicProfile?.ownerUUID);

  const discDs = props.archetypeDiscovery?.scaleScores?.find(
    (el) => el.scaleSlug === "disc-ds"
  );
  const discIc = props.archetypeDiscovery?.scaleScores?.find(
    (el) => el.scaleSlug === "disc-ic"
  );

  const xScore = discDs?.percentScore ? 1 - discDs.percentScore : 0;
  const yScore = discIc?.percentScore ? discIc.percentScore : 0;

  const xPlot = normalizeData(xScore, 0.3, 0.7) * 100;
  const yPlot = normalizeData(yScore, 0.3, 0.7) * 100;

  return (
    <div className={styles.wrapperModal}>
      <div className={styles.headerModal}>
        <ArchetypeBreakdownHeading publicProfile={props.publicProfile} />
      </div>
      <div className={styles.mainModal}>
        <p className={styles.label}>Influence</p>
        <div className={styles.parentModal}>
          <div className={styles.rowTopModal}>
            <div
              style={
                props.archetype.acronym === "ID"
                  ? {
                      background: `linear-gradient(to top right,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "DI"
                  ? {
                      background: `linear-gradient(to bottom left,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.leftTop}
            >
              <p className={styles.label}>Dominant</p>
            </div>

            <div
              style={
                props.archetype.acronym === "IS"
                  ? {
                      background: `linear-gradient(to top left,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "SI"
                  ? {
                      background: `linear-gradient(to bottom right,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.RightTop}
            >
              <p className={styles.label}>Supportive</p>
            </div>
          </div>
          <div className={styles.row}>
            <div
              style={
                props.archetype.acronym === "DC"
                  ? {
                      background: `linear-gradient(to top left,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "CD"
                  ? {
                      background: `linear-gradient(to bottom right,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.leftBottom}
            ></div>
            <div
              style={
                props.archetype.acronym === "SC"
                  ? {
                      background: `linear-gradient(to top right,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "CS"
                  ? {
                      background: `linear-gradient(to bottom left,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.rightBottom}
            ></div>
          </div>
          <div
            style={
              xScore && yScore
                ? {
                    left: `calc(${xPlot}% - 20px)`,
                    bottom: `calc(${yPlot}% - 20px)`,
                  }
                : {}
            }
            className={styles.personDiv}
          >
            <SmallProfileImage
              publicProfile={props.publicProfile}
              imageUrl={imageUrl}
            />
          </div>
        </div>
        <div className={styles.lineTopLeftModal}></div>
        <div className={styles.lineTopRightModal}></div>
        <p className={styles.diM}>(DI)</p>
        <p className={styles.idM}>(ID)</p>
        <p className={styles.isM}>(IS)</p>
        <p className={styles.siM}>(SI)</p>
        <p className={styles.scM}>(SC)</p>
        <p className={styles.csM}>(CS)</p>
        <p className={styles.cdM}>(CD)</p>
        <p className={styles.dcM}>(DC)</p>
        <p className={styles.label}>Cautious</p>
      </div>
    </div>
  );
}

export function CartesianModalBreakdown(props: {
  archetype: ExtendedArchetype;
}) {
  return (
    <div className={styles.wrapperModal}>
      <div className={styles.headerModal}>
        <ArchetypeBreakdownHeading />
      </div>
      <div className={styles.mainModal}>
        <p className={styles.label}>Influence</p>
        <div className={styles.parentModal}>
          <div className={styles.rowTopModal}>
            <div
              style={
                props.archetype.acronym === "ID"
                  ? {
                      background: `linear-gradient(to top right,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "DI"
                  ? {
                      background: `linear-gradient(to bottom left,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.leftTop}
            >
              <p className={styles.label}>Dominant</p>
            </div>

            <div
              style={
                props.archetype.acronym === "IS"
                  ? {
                      background: `linear-gradient(to top left,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "SI"
                  ? {
                      background: `linear-gradient(to bottom right,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.RightTop}
            >
              <p className={styles.label}>Supportive</p>
            </div>
          </div>
          <div className={styles.row}>
            <div
              style={
                props.archetype.acronym === "DC"
                  ? {
                      background: `linear-gradient(to top left,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "CD"
                  ? {
                      background: `linear-gradient(to bottom right,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.leftBottom}
            ></div>
            <div
              style={
                props.archetype.acronym === "SC"
                  ? {
                      background: `linear-gradient(to top right,transparent 50%,grey 50%)`,
                    }
                  : props.archetype.acronym === "CS"
                  ? {
                      background: `linear-gradient(to bottom left,transparent 50%,grey 50%)`,
                    }
                  : {}
              }
              className={styles.rightBottom}
            ></div>
          </div>
        </div>
        <div className={styles.lineTopLeftModal}></div>
        <div className={styles.lineTopRightModal}></div>
        <p className={styles.diM}>(DI)</p>
        <p className={styles.idM}>(ID)</p>
        <p className={styles.isM}>(IS)</p>
        <p className={styles.siM}>(SI)</p>
        <p className={styles.scM}>(SC)</p>
        <p className={styles.csM}>(CS)</p>
        <p className={styles.cdM}>(CD)</p>
        <p className={styles.dcM}>(DC)</p>
        <p className={styles.label}>Cautious</p>
      </div>
    </div>
  );
}
