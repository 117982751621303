import styles from "./BetaModal.module.css";
import { useModalContext } from "@/context/ModalContext";

export default function BetaModal() {
  const { setModalOpen } = useModalContext();
  return (
    <dialog className={styles.main}>
      <div className={styles.parent}>
        <h1 className={styles.title}>Beta</h1>
        <p className={styles.paraOne}>
          The Dimensional Android app is a work-in-progress beta. Some features,
          are missing for now.
        </p>
        <p className={styles.paraTwo}>{"We're adding more features soon!"}</p>
        <button className={styles.dismiss} onClick={() => setModalOpen(false)}>
          Dismiss
        </button>
      </div>
    </dialog>
  );
}
