import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import {
  Nature,
  NatureBreakdown,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "../authenticatedProfile/NatureModal/NatureModal.module.css";

import { ProfileColorMap } from "../shared/ColorMap";
import { natureMap } from "@/assets/traitInfo/natureData";

type Props = {
  natureSlug: Nature;
  NotOwnProfile?: boolean;
  publicProfile: PublicProfile | null;
};

const font = Roboto_Mono({ subsets: ["latin"] });

export default function NatureBreakdownRow(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const name = natureMap[props.natureSlug].name;
  const shortDescription = natureMap[props.natureSlug].shortDescription;

  return (
    <div className={styles.rowParent}>
      <div className={styles.listRowTop}>
        <div
          style={{
            backgroundColor: `${
              ProfileColorMap[props.natureSlug as keyof typeof ProfileColorMap]
            }`,
          }}
          className={styles.natureCircle}
        ></div>
        <h3 className={font.className}>{name}</h3>
      </div>
      <p className={styles.listRowDescription}>{shortDescription}</p>

      <div className={styles.listRowBottom}>
        {props.NotOwnProfile ? (
          <div
            style={
              props.publicProfile?.primaryNature?.natureBreakdown[
                props.natureSlug as keyof NatureBreakdown
              ]
                ? {
                    width: `${
                      props.publicProfile?.primaryNature?.natureBreakdown[
                        props.natureSlug as keyof NatureBreakdown
                      ] * 100
                    }%`,
                  }
                : { width: "0" }
            }
            className={styles.rowScore}
          ></div>
        ) : (
          <div
            style={
              userState.publicProfileData?.primaryNature?.natureBreakdown[
                props.natureSlug as keyof NatureBreakdown
              ]
                ? {
                    width: `${
                      userState.publicProfileData?.primaryNature
                        ?.natureBreakdown[
                        props.natureSlug as keyof NatureBreakdown
                      ] * 100
                    }%`,
                  }
                : { width: "0" }
            }
            className={styles.rowScore}
          ></div>
        )}
      </div>
    </div>
  );
}
