/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import styles from "./Histogram.module.css";

import { ProfileColorMap } from "@/components/shared/ColorMap";
import { RootState } from "@/redux/store";
import TraitsHeading from "../Heading/TraitsHeading";
import TraitsWrapper from "../Wrapper/Wrapper";
import useIsMobile from "@/helpers/useIsMobile";

type HistogramProps = {
  data: number[] | null;
  auth?: boolean;
  type?: "element" | "pattern" | "archetype";
  averageUsrScore?: number | null | undefined;
  averageConnectionScore?: number | null | undefined;
  hideTitle?: boolean;
  lowMargin?: boolean;
  modal?: boolean;
};

export default function Histogram(props: HistogramProps) {
  const [multiplier, setMultiplier] = useState<number>(0);
  const router = useRouter();
  const userState = useSelector((state: RootState) => state.user);
  const [rawScore, setRawScore] = useState<number | null | undefined>();
  const isMobile = useIsMobile();

  useEffect(() => {
    let high = 0;
    if (props.data) {
      props.data.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    if (props.auth) {
      setMultiplier(210 / (high * 100));
    } else {
      setMultiplier(240 / (high * 100));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.auth) {
      const slug = router.query.traitSlug;
      if (props.type === "element") {
        userState.elementsDiscovered?.forEach((el) => {
          if (el.scaleSlug === slug) {
            setRawScore(el.scaleScore?.percentScore);
          }
        });
      } else if (props.type === "archetype") {
        userState.archetypesDiscovered?.forEach((el) => {
          if (el.archetypeSlug === slug) {
            setRawScore(el.archetypeScore);
          }
        });
      } else {
        userState.patternsDiscovered?.forEach((el) => {
          if (el.patternSlug === slug) {
            setRawScore(el.percentScore);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth, props.type, router.query.traitSlug]);

  useEffect(() => {
    if (rawScore && rawScore > 0.95) {
      if (!isMobile) {
        setRawScore(0.91);
      } else {
        setRawScore(0.95);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawScore]);

  const mapped = props.data?.map((point, index) => {
    return (
      <div
        className={props.modal ? styles.histogramBarModal : styles.histogramBar}
        style={{ height: `${point * 100 * multiplier}px` }}
        key={index}
      ></div>
    );
  });

  if (props.data === null) {
    return (
      <div className={styles.parent}>
        <TraitsHeading title="Distribution of user ucores Coming Soon" />
      </div>
    );
  }

  if (!props.auth) {
    return (
      <TraitsWrapper>
        <TraitsHeading title="Distribution of user scores" />
        <div className={styles.histogramParent}>{mapped}</div>
        <div className={styles.labels}>
          <p>0</p>
          <p>25</p>
          <p>50</p>
          <p>75</p>
          <p>100</p>
        </div>
      </TraitsWrapper>
    );
  } else {
    return (
      <TraitsWrapper lowMargin={props.lowMargin}>
        {props.hideTitle ? null : (
          <TraitsHeading title="Distribution of user ucores" />
        )}
        <div className={styles.subTitleDiv}>
          <div className={styles.avScoreLabelCircle}></div>
          <h4>Average score of all users</h4>
        </div>
        {props.averageConnectionScore && (
          <div style={{ marginTop: "5px" }} className={styles.subTitleDiv}>
            <div
              style={{ background: "#f493ac" }}
              className={styles.avScoreLabelCircle}
            ></div>
            <h4>Average score of your connections</h4>
          </div>
        )}
        <div
          className={
            props.modal ? styles.histogramParentModal : styles.histogramParent
          }
        >
          {props.averageUsrScore && (
            <div
              style={
                isMobile
                  ? { right: `${props.averageUsrScore * 100 - 4}%` }
                  : { right: `${props.averageUsrScore * 100 - 9}%` }
              }
              className={styles.averageScoreDiv}
            >
              <div className={styles.averageScoreLine}></div>
              <div className={styles.averageScoreCircle}></div>
            </div>
          )}
          {props.averageConnectionScore && (
            <div
              style={
                isMobile
                  ? { right: `${props.averageConnectionScore - 4}%` }
                  : { right: `${props.averageConnectionScore - 9}%` }
              }
              className={styles.averageScoreDiv}
            >
              <div className={styles.connectionAverageScoreLine}></div>
              <div className={styles.connectionAverageScoreCircle}></div>
            </div>
          )}
          {rawScore && (
            <div
              style={{ right: `${rawScore * 100}%` }}
              className={styles.averageScoreDiv}
            >
              <div
                style={
                  userState.publicProfileData?.primaryNature
                    ? {
                        backgroundColor: `${
                          ProfileColorMap[
                            userState.publicProfileData?.primaryNature
                              .nature as keyof typeof ProfileColorMap
                          ]
                        }`,
                      }
                    : { backgroundColor: "#55504F" }
                }
                className={styles.userScoreLine}
              ></div>
              <div
                style={
                  userState.publicProfileData?.primaryNature
                    ? {
                        border: `3px solid ${
                          ProfileColorMap[
                            userState.publicProfileData?.primaryNature
                              .nature as keyof typeof ProfileColorMap
                          ]
                        }`,
                      }
                    : { border: "3px solid #55504F" }
                }
                className={styles.userScoreCircle}
              >
                <img src={userState.imageURL as string} alt="" />
              </div>
            </div>
          )}
          {mapped}
        </div>
        <div
          style={props.modal ? { padding: "0 5px" } : {}}
          className={styles.labels}
        >
          <p>0</p>
          <p>25</p>
          <p>50</p>
          <p>75</p>
          <p>100</p>
        </div>
      </TraitsWrapper>
    );
  }
}
