export default function ExplanationMarkIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4706 27C6.03936 27 0 20.9609 0 13.4706C0 5.98131 6.03909 0 13.4706 0C20.9029 0 26.9411 6.03909 26.9411 13.4706C27.0002 20.9609 20.9612 27 13.4706 27ZM13.4706 2.32254C7.31646 2.32254 2.32254 7.31646 2.32254 13.4706C2.32254 19.6258 7.31646 24.6186 13.4706 24.6186C19.6258 24.6186 24.6186 19.6247 24.6186 13.4706C24.6777 7.31646 19.6838 2.32254 13.4706 2.32254ZM14.6328 6.96735H12.3104V15.5609H14.6328V6.96735ZM14.6328 17.6513H12.3104V20.0317H14.6328V17.6513Z"
        fill="white"
      />
    </svg>
  );
}
