/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PeriodicQuiz,
  PeriodicQuizResult,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PeriodicQuizResultModal.module.css";

import BackButton from "@/components/shared/buttons/BackButton";
import { useModalContext } from "@/context/ModalContext";
import {
  formatPeriodicQuizPercentileDescriptionTemplate,
  getPeriodicScorePercentileScore,
  periodicQuizSampleNameColourMap,
  sharePeriodicQuizResult,
} from "../utils";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import {
  PeriodicQuizInstagramIcon,
  PeriodicQuizSnapchatIcon,
} from "../PeriodicQuizShareIcons";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import { BasicConnectionIconSummaries } from "@/components/shared/icons/BasicConnectionIcon";
import { useAuth } from "@/context/AuthContext";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import PeriodicQuizModal from "../PeriodicQuizModal/PeriodicQuizModal";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "@/components/shared/Alerts/Alert";
import { Mixpanel } from "@/helpers/mixpanel";
import PeriodicQuizResultsShareModal from "./PeriodicQuizResultsShareModal";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { PeriodicQuizConnectionScores } from "@/models/sharedModels";
import { setPeriodicQuizConnectionScores } from "@/redux/slices/periodicQuizSlice";
import Image from "next/image";

type Props = {
  periodicQuizResult: PeriodicQuizResult | null | undefined;
  periodicQuiz: PeriodicQuiz;
  elementName: "PeriodicQuizResultModal";
};

type FilterTypes =
  | "Gender"
  | "Age"
  | "Nature"
  | "I/E"
  | "S/N"
  | "T/F"
  | "J/P"
  | null;

export default function PeriodicQuizResultModal(props: Props) {
  const { setModalOpen, setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  useGetConnectionScores();

  const connectionState = useSelector((state: RootState) => state.connections);
  const connectionScores = useSelector(
    (state: RootState) => state.periodicQuiz
  ).periodicQuizConnectionScores;

  const liveQuizShareStatus = useSelector(
    (state: RootState) => state.periodicQuiz
  ).periodicQuizResults?.find(
    (q) => q.periodicQuizSlug === props.periodicQuiz.slug
  )?.isShared;

  const { user } = useAuth();

  const [filter, setFilter] = useState<FilterTypes>(null);

  const percentileScore = getPeriodicScorePercentileScore(
    props.periodicQuiz,
    props.periodicQuizResult
  );

  const sampleBreakdown = props.periodicQuiz.sampleData?.sampleBreakdowns?.find(
    (s) => s.name === filter
  );

  const mappedLabels = sampleBreakdown?.categoryData?.map((s) => {
    const color =
      periodicQuizSampleNameColourMap[
        s.slug as keyof typeof periodicQuizSampleNameColourMap
      ];
    return (
      <div className={styles.labelDiv} key={s.slug}>
        <div style={{ backgroundColor: color }} className={styles.circle}></div>
        <p className={styles.legendText}>{s.name}</p>
      </div>
    );
  });

  const mappedFilters = [
    "Gender",
    "Age",
    "Nature",
    "I/E",
    "S/N",
    "T/F",
    "J/P",
  ].map((option) => {
    return (
      <p
        className={styles.filterText}
        onClick={() => {
          if (props.periodicQuiz.sampleData?.isFinalized) {
            setFilter(option as FilterTypes);
            Mixpanel?.track("Quiz Breakdown Viewed", {
              type: option.toLowerCase(),
            });
          } else {
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="warning"
                message="Breakdowns will be available once more responses have been collected."
              />,
              7000
            );
          }
        }}
        key={option}
        style={
          filter === option
            ? { color: "#FFFFF2", textDecorationLine: "underline" }
            : {}
        }
      >
        {option.toUpperCase()}
      </p>
    );
  });

  const connectionsArrayCopy = connectionScores?.periodicQuizConnectionScores
    ? [...connectionScores?.periodicQuizConnectionScores]
    : [];
  const mappedConnectionScores = connectionsArrayCopy
    .sort((a, b) => {
      return b.score - a.score;
    })
    .map((score) => {
      const publicProfile = connectionState.publicProfiles?.find(
        (c) => c.ownerUUID === score.memberId
      );
      if (publicProfile) {
        return (
          <div className={styles.connectionScoreBox} key={score.memberId}>
            <SmallProfileImage
              medium
              noLink
              publicProfile={publicProfile}
              imageUrl={publicProfile.imageUrl}
            />
            <p className={styles.connectionUsername}>
              @{publicProfile.userName}
            </p>
            <p className={styles.connectionScore}>
              <span className={styles.connectionScoreSpan}>
                {(score.score * 100).toFixed(0)}
              </span>
              /100
            </p>
          </div>
        );
      }
    });

  return (
    <ModalWrapper noHeader noLine title="">
      <main className={styles.main}>
        <section className={styles.header}>
          <h2 className={styles.quizTitle}>Results</h2>
          <h3 className={styles.quizSubTitle}>Daily Quiz</h3>
          <div className={styles.progressBar}></div>
          <div className={styles.downArrorDiv}>
            <BackButton step={true} onBack={() => setModalOpen(false)} />
          </div>
        </section>
        <section className={styles.titleSection}>
          {props.periodicQuiz.emojiString !== "" && (
            <h1 className={styles.emojiString}>
              {props.periodicQuiz.emojiString}
            </h1>
          )}
          <h1 className={styles.quizTitleTitleSection}>
            {props.periodicQuiz.title}
          </h1>
        </section>

        {props.periodicQuizResult ? (
          <section className={styles.scoreBoxSection}>
            <div className={styles.scoreBox}>
              <div className={styles.scoreTextBig}>
                <h2 className={styles.score}>
                  <span className={styles.scoreBig}>
                    {(props.periodicQuizResult.percentScore * 100).toFixed(0)}
                  </span>
                  /100
                </h2>
                {props.periodicQuiz.scoreName && (
                  <p className={styles.scoreName}>
                    {props.periodicQuiz.scoreName.toUpperCase()}
                  </p>
                )}
              </div>
              <p className={styles.percentileDescriptionTemplate}>
                {formatPeriodicQuizPercentileDescriptionTemplate(
                  props.periodicQuiz.percentileDescriptionTemplate,
                  percentileScore
                )}
              </p>
              <PeriodicQuizHistogram
                periodicQuiz={props.periodicQuiz}
                filter={filter}
                periodicQuizResult={props.periodicQuizResult}
              />
              {!props.periodicQuiz.sampleData?.isFinalized ? (
                <>
                  <CollectingDataGhostBox />
                </>
              ) : filter !== null ? (
                <div className={styles.labelWrapper}>{mappedLabels}</div>
              ) : (
                <AverageUserScoreLabel />
              )}
              <div className={styles.filterParent}>
                <p className={styles.showAveragesByText}>Show averages by:</p>
                {mappedFilters}
              </div>
            </div>
          </section>
        ) : (
          <UnknownPeriodicResultBox />
        )}
        {props.periodicQuizResult && (
          <section className={styles.postResultsSection}>
            <h3>Post results</h3>
            <PeriodicQuizInstagramIcon
              onClick={() => {
                Mixpanel?.track("Shared to Social", {
                  platform: "Instagram",
                  slug: props.periodicQuiz.slug,
                  body: props.periodicQuiz.title,
                  type: "periodicQuiz",
                });
                setModalComponent(
                  <PeriodicQuizResultsShareModal
                    periodicQuiz={props.periodicQuiz}
                    periodicQuizResult={
                      props.periodicQuizResult as PeriodicQuizResult
                    }
                    type="instagram"
                  />
                );
              }}
            />
            <PeriodicQuizSnapchatIcon
              onClick={() => {
                Mixpanel?.track("Shared to Social", {
                  platform: "Snapchat",
                  slug: props.periodicQuiz.slug,
                  body: props.periodicQuiz.title,
                  type: "periodicQuiz",
                });
                setModalComponent(
                  <PeriodicQuizResultsShareModal
                    periodicQuiz={props.periodicQuiz}
                    periodicQuizResult={
                      props.periodicQuizResult as PeriodicQuizResult
                    }
                    type="snapchat"
                  />
                );
              }}
            />
          </section>
        )}
        <section className={styles.connectionScoresSection}>
          <h3 className={styles.connectionResultsTitle}>Connection results</h3>
          <div className={styles.connectionScoresWrapper}>
            {mappedConnectionScores?.length ? (
              mappedConnectionScores
            ) : (
              <ConnectionScoresGhostBox />
            )}
          </div>
        </section>
        {props.periodicQuizResult && (
          <section className={styles.bottomSection}>
            {liveQuizShareStatus ? (
              <button className={styles.shareConnectionButtonInactive}>
                Results shared
              </button>
            ) : (
              <button
                onClick={() => {
                  sharePeriodicQuizResult(
                    props.periodicQuizResult,
                    user?.uid
                  ).then((res) => {
                    Mixpanel?.track("Quiz score shared to connections", {
                      slug: props.periodicQuiz.slug,
                    });
                  });
                }}
                className={styles.shareConnectionButton}
              >
                Share results with my connections
              </button>
            )}

            <div className={styles.permissionsDiv}>
              <BasicConnectionIconSummaries />
              <p className={styles.permissionsDivText}>
                Results will be available to Basic connections
              </p>
            </div>
          </section>
        )}
      </main>
    </ModalWrapper>
  );
}

function PeriodicQuizHistogram(props: {
  filter: FilterTypes;
  periodicQuiz: PeriodicQuiz;
  periodicQuizResult: PeriodicQuizResult;
}) {
  const [multiplier, setMultiplier] = useState<number>(0);

  const userState = useSelector((state: RootState) => state.user);

  const sampleBreakdown = props.periodicQuiz.sampleData?.sampleBreakdowns?.find(
    (s) => s.name === props.filter
  );

  useEffect(() => {
    let high = 0;
    if (props.periodicQuiz.sampleData?.histogramData) {
      props.periodicQuiz.sampleData.histogramData.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(160 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.periodicQuiz.sampleData?.histogramData?.map(
    (point, index) => {
      return (
        <div
          className={styles.histogramBar}
          style={
            point === 0
              ? { height: `6px` }
              : { height: `${point * 100 * multiplier}px` }
          }
          key={index}
        ></div>
      );
    }
  );

  const userScore = (
    <div
      style={{
        left: `calc(${props.periodicQuizResult.percentScore * 100}% - 1px)`,
      }}
      className={styles.averageScoreDiv}
    >
      <div
        style={
          props
            ? {
                border: `3px solid ${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { border: "3px solid #55504F" }
        }
        className={styles.userScoreCircle}
      >
        <img src={userState.imageURL ?? ""} alt="" />
      </div>
      <div
        style={
          props
            ? {
                backgroundColor: `${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { backgroundColor: "#55504F" }
        }
        className={styles.userScoreLine}
      ></div>
    </div>
  );

  const averageScore =
    props.filter === null &&
    props.periodicQuiz.sampleData?.averageUserScore &&
    props.periodicQuiz.sampleData.isFinalized ? (
      <div
        style={{
          left: `calc(${
            props.periodicQuiz.sampleData?.averageUserScore * 100
          }% - 1px)`,
          height: "150px",
          zIndex: "20",
        }}
        className={styles.averageScoreDiv}
      >
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.circle}
        ></div>
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.userScoreLine}
        ></div>
      </div>
    ) : null;

  const mappedSampleBreakdown = sampleBreakdown?.categoryData?.map((data) => {
    const color =
      periodicQuizSampleNameColourMap[
        data.slug as keyof typeof periodicQuizSampleNameColourMap
      ];
    if (data.averageUserScore) {
      return (
        <div
          style={{
            left: `calc(${data.averageUserScore * 100}% - 1px)`,
            height: "150px",
            zIndex: "20",
          }}
          className={styles.averageScoreDiv}
          key={data.slug}
        >
          <div
            style={{ backgroundColor: color }}
            className={styles.circle}
          ></div>
          <div
            style={{ backgroundColor: color }}
            className={styles.userScoreLine}
          ></div>
        </div>
      );
    }
  });

  return (
    <div className={styles.histogramSection}>
      <div className={styles.histogramWrapper}>
        {averageScore}
        {userScore}
        {mappedSampleBreakdown}
        {mapped}
      </div>
      <div className={styles.histogramLabelWrapper}>
        <p className={styles.histogramLabel}>0</p>
        <p className={styles.histogramLabel}>25</p>
        <p className={styles.histogramLabel}>50</p>
        <p className={styles.histogramLabel}>75</p>
        <p className={styles.histogramLabel}>100</p>
      </div>
    </div>
  );
}

function UnknownPeriodicResultBox() {
  const { removeModalByName, setModalComponent } = useModalContext();

  function handleClick() {
    setModalComponent(<PeriodicQuizModal elementName="PeriodicQuizModal" />);
    removeModalByName("PeriodicQuizResultModal");
  }

  return (
    <section className={styles.unknownSection}>
      <div className={styles.unknownBox}>
        <h1 className={styles.unknownBigText}>??</h1>
        <h4 className={styles.unknownScoreSmallText}>YOUR SCORE: UNKNOWN</h4>
        <button
          onClick={() => handleClick()}
          className={styles.unknownButtonTakeTheQuiz}
        >
          Take the quiz
        </button>
      </div>
    </section>
  );
}

function CollectingDataGhostBox() {
  return (
    <div className={styles.collectingGhostBoxSection}>
      <div className={styles.collectingGhostBox}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="31"
          viewBox="0 0 30 31"
          fill="none"
        >
          <path
            d="M1.44227 21.1466C0.120267 17.3 0.472467 13.0155 2.5001 9.43064C4.52773 5.93346 7.87628 3.48544 11.8434 2.69805L11.5793 0.686768L16.0748 3.13476L12.5492 6.89401L12.1969 4.88273C8.93522 5.58267 6.11534 7.59276 4.4409 10.4788C2.76667 13.5395 2.41326 17.124 3.55916 20.4456L1.44347 21.1456L1.44227 21.1466Z"
            fill="#FFFFF2"
          />
          <path
            d="M25.6814 25.5184C22.9493 28.6665 18.9821 30.5019 14.8401 30.5019C10.786 30.5019 6.99474 28.8411 4.26291 25.8679L2.85285 27.2666L2.14722 22.1958L7.2592 22.8084L5.84914 24.2944C8.14094 26.8298 11.4027 28.3157 14.839 28.3157C18.3646 28.3157 21.6263 26.7424 23.9181 24.1185L25.6804 25.5172L25.6814 25.5184Z"
            fill="#FFFFF2"
          />
          <path
            d="M17.3956 2.61182C21.5377 3.31176 25.0636 5.75987 27.0912 9.43176C29.1189 12.9289 29.5591 17.1253 28.3252 20.8857L29.9994 22.0224L25.0636 23.6831L24.6233 18.6123L26.2975 19.6616C27.2673 16.6009 26.8259 13.2796 25.1516 10.481C23.3893 7.50891 20.48 5.41025 16.9543 4.79787L17.3946 2.61191L17.3956 2.61182Z"
            fill="#FFFFF2"
          />
          <rect
            x="8.71216"
            y="14.9988"
            width="3.3062"
            height="5.66466"
            rx="0.752753"
            fill="#FFFFF2"
          />
          <rect
            x="13.2207"
            y="10.5266"
            width="3.3062"
            height="10.1368"
            rx="0.752753"
            fill="#FFFFF2"
          />
          <rect
            x="17.729"
            y="13.21"
            width="3.3062"
            height="7.45349"
            rx="0.752753"
            fill="#FFFFF2"
          />
        </svg>
        <p className={styles.collectingGhostBoxText}>
          Collecting more scores from other users. Come back to see how you
          accurately compare.
        </p>
      </div>
    </div>
  );
}

export function AverageUserScoreLabel() {
  return (
    <div
      style={{ justifyContent: "flex-start" }}
      className={styles.labelWrapper}
    >
      <div className={styles.labelDiv}>
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.circle}
        ></div>
        <p className={styles.legendText}>User average</p>
      </div>
    </div>
  );
}

function ConnectionScoresGhostBox() {
  return (
    <div
      style={{ marginTop: "5px", height: "70px" }}
      className={styles.collectingGhostBox}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
      >
        <path
          d="M11.3886 14.472C15.8827 14.472 19.6285 18.0251 19.6285 22.2882H23C23 16.2467 17.7576 11.6289 11.7629 11.6289C5.77217 11.6289 0.525879 16.6017 0.525879 22.2882H3.89741C3.14907 18.0213 6.89485 14.472 11.3888 14.472H11.3886Z"
          fill="#FFFFF2"
        />
        <path
          d="M7.64269 2.38857C7.64269 4.75862 3.89697 4.75862 3.89697 2.38857C3.89697 0.0223317 7.64269 0.0223317 7.64269 2.38857Z"
          fill="#FFFFF2"
        />
        <path
          d="M19.2538 2.38857C19.2538 4.75862 15.5081 4.75862 15.5081 2.38857C15.5081 0.0223317 19.2538 0.0223317 19.2538 2.38857Z"
          fill="#FFFFF2"
        />
      </svg>
      <p className={styles.collectingGhostBoxText}>
        No connections have shared their results yet.
      </p>
    </div>
  );
}

export function PeriodicQuizHistogramShareMode(props: {
  periodicQuiz: PeriodicQuiz;
  periodicQuizResult: PeriodicQuizResult;
}) {
  const [multiplier, setMultiplier] = useState<number>(0);

  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let high = 0;
    if (props.periodicQuiz.sampleData?.histogramData) {
      props.periodicQuiz.sampleData.histogramData.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(160 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mapped = props.periodicQuiz.sampleData?.histogramData?.map(
    (point, index) => {
      return (
        <div
          className={styles.histogramBar}
          style={
            point === 0
              ? { height: `6px` }
              : { height: `${point * 100 * multiplier}px` }
          }
          key={index}
        ></div>
      );
    }
  );

  const userScore = (
    <div
      style={{
        left: `calc(${props.periodicQuizResult.percentScore * 100}% - 1px)`,
      }}
      className={styles.averageScoreDiv}
    >
      <div
        style={
          props
            ? {
                border: `3px solid ${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { border: "3px solid #55504F" }
        }
        className={styles.userScoreCircleShare}
      >
        <Image priority fill src={userState.imageURL ?? ""} alt="" />
      </div>
      <div
        style={
          props
            ? {
                backgroundColor: `${
                  ProfileColorMap[
                    userState.publicProfileData?.primaryNature
                      ?.nature as keyof typeof ProfileColorMap
                  ]
                }`,
              }
            : { backgroundColor: "#55504F" }
        }
        className={styles.userScoreLine}
      ></div>
    </div>
  );

  const averageScore =
    props.periodicQuiz.sampleData?.averageUserScore &&
    props.periodicQuiz.sampleData.isFinalized ? (
      <div
        style={{
          left: `calc(${
            props.periodicQuiz.sampleData?.averageUserScore * 100
          }% - 1px)`,
          height: "150px",
          zIndex: "20",
        }}
        className={styles.averageScoreDiv}
      >
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.circle}
        ></div>
        <div
          style={{ backgroundColor: "#7886FF" }}
          className={styles.userScoreLine}
        ></div>
      </div>
    ) : null;

  return (
    <div className={styles.histogramSection}>
      <div className={styles.histogramWrapper}>
        {averageScore}
        {userScore}
        {mapped}
      </div>
      <div className={styles.histogramLabelWrapper}>
        <p className={styles.histogramLabel}>0</p>
        <p className={styles.histogramLabel}>25</p>
        <p className={styles.histogramLabel}>50</p>
        <p className={styles.histogramLabel}>75</p>
        <p className={styles.histogramLabel}>100</p>
      </div>
    </div>
  );
}

function useGetConnectionScores() {
  const dispatch = useDispatch();
  const quizState = useSelector((state: RootState) => state.periodicQuiz);

  useEffect(() => {
    function getPeriodicQuizResultsFromConnections(
      quizSlug: string | undefined
    ) {
      const call = callFirebaseFunctions("fetchConnectionPeriodicQuizScores");
      call({ periodicQuizSlug: quizSlug }).then((res) => {
        const data: PeriodicQuizConnectionScores =
          res.data as PeriodicQuizConnectionScores;
        dispatch(setPeriodicQuizConnectionScores(data));
      });
    }
    if (quizState.availableQuiz?.slug) {
      getPeriodicQuizResultsFromConnections(quizState.availableQuiz.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizState.availableQuiz?.slug]);
}
