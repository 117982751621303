import {
  DiscoveredSnippet,
  LlmRequest,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SnippetSlice {
  snippets: DiscoveredSnippet[];
  snippetslAllLLMRequest: LlmRequest[];
  snippetsLatestManualLlmRequest: LlmRequest | null;
}

const initialState: SnippetSlice = {
  snippets: [],
  snippetslAllLLMRequest: [],
  snippetsLatestManualLlmRequest: null,
};

export const snippetSlice = createSlice({
  name: "snippet",
  initialState: initialState,
  reducers: {
    setSnippets: (state, action: PayloadAction<DiscoveredSnippet[]>) => {
      return { ...state, snippets: action.payload };
    },
    setSnippetsAllLlmRequests: (state, action: PayloadAction<LlmRequest[]>) => {
      return { ...state, snippetslAllLLMRequest: action.payload };
    },
    setSnippetsLatestManualLlmRequest: (
      state,
      action: PayloadAction<LlmRequest>
    ) => {
      return { ...state, snippetsLatestManualLlmRequest: action.payload };
    },
  },
});

export const {
  setSnippets,
  setSnippetsAllLlmRequests,
  setSnippetsLatestManualLlmRequest,
} = snippetSlice.actions;

export default snippetSlice.reducer;
