import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";

import styles from "./FeatureCarouselModal.module.css";
import "swiper/css";

import ModalWrapper from "../shared/ModalWrapper/ModalWrapper";
import Image from "next/image";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";

export default function FeatureCarouselModal() {
  const appInfo = useSelector((state: RootState) => state.appInfo);
  const { setModalOpen } = useModalContext();

  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const mapped = appInfo.appInfo?.latestFeatureCarousel?.slides.map(
    (slide, i) => {
      return (
        <SwiperSlide key={i}>
          {slide.imageName && (
            <div className={styles.imageDiv}>
              <Image
                alt=""
                fill
                src={`/feature-carousel/${slide.imageName}.png`}
              />
            </div>
          )}
          <h1 className={styles.title}>{slide.title}</h1>
          <p className={styles.body}>{slide.body}</p>
        </SwiperSlide>
      );
    }
  );

  const slideIndexMap = appInfo.appInfo?.latestFeatureCarousel?.slides?.map(
    (n, i) => {
      return (
        <div
          onClick={() => setIndex(i)}
          key={i}
          className={index === i ? styles.circleActive : styles.circle}
        ></div>
      );
    }
  );

  useEffect(() => {
    if (appInfo.appInfo?.latestFeatureCarousel?.requiredVersion) {
      localStorage.setItem(
        "feature-carousel-version",
        appInfo.appInfo?.latestFeatureCarousel?.requiredVersion
      );
    }
  }, []);

  return (
    <ModalWrapper backgroundColor="#2D2927" title="" noHeader noLine>
      <div className={styles.main}>
        <Swiper
          autoHeight={true}
          slidesPerView={1}
          touchReleaseOnEdges={true}
          onSlideChange={(e) => {
            setIndex(e.activeIndex);
          }}
          scrollbar={{ draggable: true }}
          threshold={1}
          onSwiper={setMySwiperRef}
          style={{ width: "100%", maxWidth: "100%" }}
        >
          {mapped}
        </Swiper>
        <div className={styles.bottom}>
          <div className={styles.circleDiv}>{slideIndexMap}</div>
          <button onClick={() => setModalOpen(false)} className={styles.button}>
            Dismiss
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}
