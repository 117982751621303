export default function OverEstimatedSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M4.70711 20.2929C4.31658 20.6834 4.31658 21.3166 4.70711 21.7071L6.29289 23.2929C6.68342 23.6834 7.31658 23.6834 7.70711 23.2929L19.2929 11.7071C19.6834 11.3166 20.3166 11.3166 20.7071 11.7071L32.2929 23.2929C32.6834 23.6834 33.3166 23.6834 33.7071 23.2929L35.2929 21.7071C35.6834 21.3166 35.6834 20.6834 35.2929 20.2929L20.7071 5.70711C20.3166 5.31658 19.6834 5.31658 19.2929 5.70711L4.70711 20.2929ZM5 30C4.44771 30 4 30.4477 4 31V33C4 33.5523 4.44772 34 5 34H35C35.5523 34 36 33.5523 36 33V31C36 30.4477 35.5523 30 35 30H5Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
