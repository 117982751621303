import { useEffect, useState } from "react";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import {
  Archetype,
  CompatibilitySnippetType,
  ContentType,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./CompatibilitySnippets.module.css";

import Loader from "@/components/shared/Loader";
import LargeProfileImage from "@/components/shared/SmallProfileImage/LargeProfileImage";
import {
  compatibilityIconsMap,
  compatibilityDescriptionMap,
} from "./CompatibilityData";
import { FriendsPublicProfile } from "@/models/sharedModels";
import {
  computeCompatibilitySnippets,
  getCompatibilitySnippetFeedbackScore,
  getCompatibilitySnippetLLMRequest,
  snippetConnectionRequirement,
  updateCompatibilitSnippetsFeedback,
} from "./utils";
import AnalysisFeedback from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import CompatibilitySpectrum from "./views/CompatibilitySpectrum";
import CompatibilityListView from "./views/CompatibilityListView";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import AvailableToComponent from "@/components/shared/AvailableToComponent/AvailableToComponent";
import CompatibilityLlmTitleAndContentView from "./views/CompatibilityLlmTitleAndContentView";
import ComparedMediumProfileImage from "@/components/shared/SmallProfileImage/ComparedMediumProfileImage";
import CompatibilityRankedListDetailView, {
  CompatibilityRankedListsDetailView,
} from "./views/CompatibilityRankedListDetailView";
import { Mixpanel } from "@/helpers/mixpanel";
import CompatibilityLlmListDetailView from "./views/CompatibilityLlmListDetailView";
import CompatibilityLlmHeaderedListView from "./views/CompatibilityLlmHeaderedListView";
import CompatibilityCarouselCardDetailView from "./views/CompatibilityCarouselCardDetailView";
import { useModalContext } from "@/context/ModalContext";
import CompatibilityConversionCTA from "./CompatibilityConversionCTA";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";
import { isTimestampWithin1Day } from "@/components/authenticatedProfile/utils";
import SnippetInterractionBar from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import Alert from "@/components/shared/Alerts/Alert";
import InfoModal from "@/components/shared/InfoModal/InfoModal";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { useAlertContext } from "@/context/AlertContext";
import CompatibilityEmojiView from "./views/CompatibilityEmojiView";
import OverallCompatibilityScore from "./OverallCompatibilityScore";
import { TwoByTwoNewNoData } from "@/components/shared/TwoByTwoNew/TwoByTwoNew";

type ArchetypeDataModel = {
  dimensionSlug: string;
  archetypeScore: number;
  archetypeSlug: string;
  archetype: Archetype;
};

type Props = {
  discoverableSnippet: DiscoverableCompatibilitySnippet;
  discoveredSnippets: DiscoveredCompatibilitySnippet[];
  publicProfile: PublicProfile | null;
  archetypeData: ArchetypeDataModel[] | null;
};

export default function CompatibilityModal(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  const { setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();
  const { user } = useAuth();

  const similarityAndCompatibilityScoresLoading = useSelector(
    (state: RootState) => state.connections
  ).similarityAndCompatibilityScoresLoading;
  const similarityAndCompatibilityScores = useSelector(
    (state: RootState) => state.connections
  ).similarityAndCompatibilityScores;

  const score = similarityAndCompatibilityScores?.find(
    (s) => s.slug === props.discoverableSnippet?.snippetSlug
  );

  const snippet = props.discoveredSnippets?.find(
    (s) => s?.snippetSlug === props.discoverableSnippet?.snippetSlug
  );
  const myArchetype = userState.archetypesDiscovered?.find(
    (arch) => arch.dimensionSlug === snippet?.archetypeGroupSlug
  );
  const connectionArchetype = props.archetypeData?.find(
    (arch) => arch.archetype.archetypeGroupSlug === snippet?.archetypeGroupSlug
  );

  const connection = useConnectionList(props.publicProfile);
  const connectionRequired = useConnectionRequired(snippet);
  const feedbackScore = useGetSnippetFeedbackScore(
    props.discoverableSnippet?.snippetSlug,
    connection?.ownerUUID
  );
  useCompatibilityTracking(
    snippet,
    userState.publicProfileData?.userName,
    connection?.userName
  );
  const { disabled, errorType } = useRegenerateStatus(
    snippet,
    props.publicProfile?.ownerUUID
  );

  const [loading, setLoading] = useState<boolean>(false);

  const maxLength = 13;
  const myDisplayNAme = userState.publicProfileData?.userName || "";
  const thierDisplayNAme = connection?.userName || "";

  function handleRegenerate() {
    if (!disabled) {
      if (snippet && props.publicProfile) {
        setModalComponent(
          <InfoModal
            title="Regenerate content?"
            body="You cannot undo this. Are you sure you want to regenerate this content?"
            secondaryText="Cancel"
            primaryText="Regenerate"
            onAccept={() => {
              setLoading(true);
              computeCompatibilitySnippets(
                snippet?.snippetSlug,
                props.publicProfile?.ownerUUID as unknown as string
              )
                .then(() => {
                  Mixpanel?.track("Regenerate CTA Clicked", {
                    content_type: "MP snippet",
                    slug: snippet?.snippetSlug,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  setAlertComponent(
                    <Alert
                      type="fail"
                      message={
                        "Results can be regenerated once a day. Try again later."
                      }
                      elementName="Alert"
                    />,
                    7000
                  );
                  setLoading(false);
                });
            }}
            elementName="InfoModal"
          />
        );
      }
    } else {
      if (errorType === "premium") {
        return setModalComponent(<ConversionModal initialSlide={3} />);
      }
      setAlertComponent(
        <Alert
          type="fail"
          message={
            errorType === "time"
              ? "Results can be regenerated once a day. Try again later."
              : "Regenerating snippets is only available to Plus accounts"
          }
          elementName="Alert"
        />,
        7000
      );
    }
  }

  if (loading) {
    return (
      <ModalWrapper title="">
        <LoadingScreen message="Computing. We'll notify you when it's ready." />
      </ModalWrapper>
    );
  }

  if (snippet === null) {
    return (
      <ModalWrapper title="" subTitle="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      title={props.discoverableSnippet?.name ?? ""}
      subTitle="Comparison"
    >
      <div className={styles.premiumIcon}>
        {snippet?.isPremium && <PremiumPillIcon />}
      </div>

      <section className={styles.modalTop}>
        <div
          style={{ alignItems: "center" }}
          className={styles.generatePersonDiv}
        >
          <LargeProfileImage
            publicProfile={userState.publicProfileData}
            imageUrl={userState.imageURL ?? "/default-image.png"}
          />
          <p className={styles.generateUsername}>
            {myDisplayNAme.length <= maxLength
              ? `@${myDisplayNAme}`
              : `@${myDisplayNAme.slice(0, 11)}...`}
          </p>
          {myArchetype && (
            <div
              onClick={() => {
                setModalComponent(
                  <ArchetypeModal slug={myArchetype.archetypeSlug} />
                );
              }}
              style={{ cursor: "pointer" }}
              className={styles.archetypeAcronym}
            >
              {myArchetype?.archetype.acronym ?? myArchetype?.archetype.name}
            </div>
          )}
        </div>

        <div className={styles.snippetIconSmallModal}>
          {
            compatibilityIconsMap[
              props.discoverableSnippet
                ?.snippetSlug as keyof typeof compatibilityIconsMap
            ]
          }
        </div>

        <div
          style={{ alignItems: "center" }}
          className={styles.generatePersonDiv}
        >
          <ComparedMediumProfileImage
            large
            publicProfile={props.publicProfile}
            imageUrl={connection?.imageUrl ?? "/default-image.png"}
          />
          <p className={styles.generateUsername}>
            {thierDisplayNAme.length <= maxLength
              ? `@${thierDisplayNAme}`
              : `@${thierDisplayNAme.slice(0, 11)}...`}
          </p>
          {connectionArchetype && (
            <div
              onClick={() => {
                setModalComponent(
                  <ArchetypeModal
                    slug={connectionArchetype.archetypeSlug}
                    uid={props.publicProfile?.ownerUUID}
                  />
                );
              }}
              style={{ cursor: "pointer" }}
              className={styles.archetypeAcronym}
            >
              {connectionArchetype?.archetype.acronym ??
                connectionArchetype?.archetype.name}
            </div>
          )}
        </div>
      </section>

      <section className={styles.modalContent}>
        <h1 className={styles.modalHeader}>
          {props.discoverableSnippet?.name}
        </h1>
        <h2 className={styles.modalsubheading}>
          {
            compatibilityDescriptionMap[
              snippet?.snippetSlug as keyof typeof compatibilityDescriptionMap
            ]
          }
        </h2>

        {props.discoverableSnippet.similarityComputePoolSlug && (
          <section className={styles.overCompatSection}>
            <OverallCompatibilityScore
              title="SIMILARITY"
              score={score?.score ?? 0}
              scoreLoading={similarityAndCompatibilityScoresLoading}
              similarityText
            />
          </section>
        )}
        {props.discoverableSnippet.compatibilityComputePoolSlug && (
          <section className={styles.overCompatSection}>
            <OverallCompatibilityScore
              title="COMPATIBILITY"
              score={score?.score ?? 0}
              scoreLoading={similarityAndCompatibilityScoresLoading}
              compatibilityText
            />
          </section>
        )}

        {snippet?.snippetTypes.includes(CompatibilitySnippetType.spectrums) && (
          <CompatibilitySpectrum connection={connection} snippet={snippet} />
        )}
        {snippet?.snippetDetail.comparisonListDetail && (
          <CompatibilityListView connection={connection} snippet={snippet} />
        )}
        {snippet?.snippetTypes.includes(CompatibilitySnippetType.twoByTwo) && (
          <div className={styles.twoByTwoParent}>
            <TwoByTwoNewNoData
              contentIdentifier={{
                contentSlug: props.discoverableSnippet?.snippetSlug,
                contentType: ContentType.compatibilitySnippet,
              }}
              imageUrl={userState.imageURL ?? ""}
              publicProfile={userState.publicProfileData}
              connectionPublicProfile={connection}
            />
          </div>
        )}
        {snippet?.snippetTypes.includes(
          CompatibilitySnippetType.rankedLists
        ) && (
          <CompatibilityRankedListsDetailView
            connection={connection}
            snippet={snippet}
          />
        )}
        {snippet?.snippetDetail.rankedListDetail && (
          <CompatibilityRankedListDetailView
            connection={connection}
            snippet={snippet}
          />
        )}
        {snippet?.snippetDetail.llmTitlesAndContent && (
          <CompatibilityLlmTitleAndContentView
            connection={connection}
            snippet={snippet}
          />
        )}
        {snippet?.snippetDetail.llmListDetail && (
          <CompatibilityLlmListDetailView snippet={snippet} />
        )}
        {snippet?.snippetDetail.llmHeaderedLists && (
          <CompatibilityLlmHeaderedListView
            connection={connection}
            snippet={snippet}
          />
        )}

        {snippet?.snippetDetail.llmCarouselCardsDetail && (
          <CompatibilityCarouselCardDetailView
            connection={connection}
            snippet={snippet}
          />
        )}
        {snippet?.snippetDetail.emojiMatchDetail && (
          <CompatibilityEmojiView connection={connection} snippet={snippet} />
        )}
      </section>

      <section className={styles.modalbottom}>
        <AvailableToComponent connectionRequired={connectionRequired} />
        <div className={styles.feedbackDiv}>
          <AnalysisFeedback
            onAnswer={(value) => {
              if (user?.uid && connection?.ownerUUID) {
                updateCompatibilitSnippetsFeedback(
                  snippet?.snippetSlug ?? "",
                  user?.uid,
                  value,
                  connection?.ownerUUID
                );
              }
            }}
            title="How did you like this content?"
            subtitle="Feedback helps us improve"
            currentAnswer={feedbackScore}
          />
        </div>
      </section>
      <div className={styles.interactionBarDiv}>
        {snippet?.isPremium && !userState.isPremium && (
          <div className={styles.conversionDiv}>
            <CompatibilityConversionCTA username={connection?.userName} />
          </div>
        )}
        <SnippetInterractionBar
          hideShare
          onRegenerate={() => handleRegenerate()}
          disabledRegenerate={disabled}
          type="report"
          slug={snippet?.snippetSlug ?? ""}
        />
      </div>
    </ModalWrapper>
  );
}

function useConnectionList(profile: PublicProfile | null) {
  const connectionListWithFriendDetails = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );
  const [connection, setConnection] = useState<FriendsPublicProfile | null>(
    null
  );
  useEffect(() => {
    connectionListWithFriendDetails?.forEach((f) => {
      if (f.ownerUUID === profile?.ownerUUID) {
        setConnection(f);
      }
    });
  }, [connectionListWithFriendDetails, profile?.ownerUUID]);

  return connection;
}

function useCompatibilityTracking(
  snippet: DiscoveredCompatibilitySnippet | undefined,
  myUsername: string | undefined,
  connectionUsername: string | undefined
) {
  useEffect(() => {
    if (snippet !== undefined && myUsername && connectionUsername) {
      Mixpanel?.track("Compatibility Snippet Viewed", {
        triggerer: myUsername,
        triggeree: connectionUsername,
        compatibility_snippet_slug: snippet?.snippetSlug,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snippet?.snippetSlug, myUsername, connectionUsername]);
}

function useConnectionRequired(
  snippet: DiscoveredCompatibilitySnippet | undefined
) {
  const [connectionRequired, setConnectionRequired] = useState<
    "close" | "basic" | "deep" | "private" | "unconnected"
  >("basic");

  useEffect(() => {
    if (snippet) {
      setConnectionRequired(
        snippetConnectionRequirement(snippet?.connectionAvailabilities)
      );
    }
  }, [snippet]);

  return connectionRequired;
}

function useGetSnippetFeedbackScore(
  discoveredSnippetSlug: string,
  connectionUid: string | undefined
) {
  const [feedbackScore, setFeedbackScore] = useState<number | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.uid && connectionUid) {
      getCompatibilitySnippetFeedbackScore(
        discoveredSnippetSlug,
        user?.uid,
        connectionUid
      )
        .then((res) => {
          setFeedbackScore(res);
        })
        .catch(() => {
          setFeedbackScore(null);
        });
    }
  }, [connectionUid, discoveredSnippetSlug, user?.uid]);

  return feedbackScore;
}

function useRegenerateStatus(
  snippet: DiscoveredCompatibilitySnippet | undefined,
  connectionUid: string | undefined
) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<"premium" | "time">("premium");

  const { user } = useAuth();
  const isPremium = useSelector((state: RootState) => state.user.isPremium);

  useEffect(() => {
    if (!isPremium) {
      setDisabled(true);
      setErrorType("premium");
    } else {
      if (snippet && connectionUid) {
        getCompatibilitySnippetLLMRequest(user?.uid, snippet, connectionUid)
          .then((res) => {
            const isWithin24Hours = isTimestampWithin1Day(res.createdOn);
            if (isWithin24Hours === true) {
              setDisabled(true);
              setErrorType("time");
            }
          })
          .catch((err) => {
            setDisabled(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPremium, snippet?.snippetSlug, user?.uid]);

  return { disabled, errorType };
}
