import { DimensionIconProps } from "@/models/sharedModels";

export default function WorkCompatibilityIcon(props: DimensionIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.maxWidth ? "100%" : "40"}
      height={props.maxWidth ? "100%" : "41"}
      viewBox="0 0 40 41"
      fill="none"
    >
      <path
        d="M36.6664 20.5V30.5C36.6664 33.3328 34.4992 35.5 31.6664 35.5H8.33203C5.49923 35.5 3.33203 33.3328 3.33203 30.5V20.5C4.83203 21.6672 6.49923 22.1672 8.33203 22.1672H31.6648C33.4992 22.1672 35.1664 21.6672 36.6664 20.5ZM3.33203 14.6672C3.83203 12.3328 5.83203 10.5 8.33203 10.5H13.332C13.332 7.6672 15.4992 5.5 18.332 5.5H21.6648C24.4976 5.5 26.6648 7.6672 26.6648 10.5H31.6648C34.1648 10.5 36.332 12.1672 36.6648 14.6672C36.332 17.1672 34.1664 18.8328 31.6664 18.8328H8.33203C5.83203 18.8328 3.83203 17 3.33203 14.6672ZM16.6664 10.5H23.3336C23.3336 9.5 22.6664 8.8328 21.6664 8.8328H18.3336C17.3321 8.8328 16.6664 9.5 16.6664 10.5Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
