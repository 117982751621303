export default function PeerRatedCognitionIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8085 12.1753C19.8291 11.9416 20.1709 11.9416 20.1915 12.1753L20.6944 17.8781C20.7081 18.0329 20.8902 18.1084 21.0094 18.0086L25.3975 14.3317C25.5774 14.181 25.819 14.4226 25.6683 14.6025L21.9914 18.9906C21.8916 19.1098 21.9671 19.2919 22.1219 19.3056L27.8247 19.8085C28.0584 19.8291 28.0584 20.1709 27.8247 20.1915L22.1219 20.6944C21.9671 20.7081 21.8916 20.8902 21.9914 21.0094L25.6683 25.3975C25.819 25.5774 25.5774 25.819 25.3975 25.6683L21.0094 21.9914C20.8902 21.8916 20.7081 21.9671 20.6944 22.1219L20.1915 27.8247C20.1709 28.0584 19.8291 28.0584 19.8085 27.8247L19.3056 22.1219C19.2919 21.9671 19.1098 21.8916 18.9906 21.9914L14.6025 25.6683C14.4226 25.819 14.181 25.5774 14.3317 25.3975L18.0086 21.0094C18.1084 20.8902 18.0329 20.7081 17.8781 20.6944L12.1753 20.1915C11.9416 20.1709 11.9416 19.8291 12.1753 19.8085L17.8781 19.3056C18.0329 19.2919 18.1084 19.1098 18.0086 18.9906L14.3317 14.6025C14.181 14.4226 14.4226 14.181 14.6025 14.3317L18.9906 18.0086C19.1098 18.1084 19.2919 18.0329 19.3056 17.8781L19.8085 12.1753Z"
        fill="#FFFFF2"
      />
      <path
        d="M38.1141 14.8226C38.2311 14.6671 38.2504 14.4589 38.163 14.2841C38.0756 14.1105 37.8982 14 37.7028 14H34.8236C34.3545 14.0039 33.9123 14.2185 33.6206 14.5861L30.3403 18.6993C29.8866 19.2635 29.8866 20.0682 30.3403 20.6324L33.6206 24.7456C33.9123 25.1132 34.3545 25.3279 34.8236 25.3317H37.7028C37.8982 25.3317 38.0756 25.2212 38.163 25.0476C38.2504 24.8728 38.2311 24.6646 38.1141 24.5091L34.7414 20.005C34.5923 19.8148 34.5923 19.5474 34.7414 19.3572L38.1141 14.8226Z"
        fill="#FFFFF2"
      />
      <path
        d="M6.59678 14.5861C6.305 14.2185 5.86282 14.0038 5.39369 14H2.51449C2.31911 14 2.14174 14.1105 2.05434 14.2841C1.96694 14.4589 1.98622 14.6671 2.10319 14.8226L5.47597 19.3267C5.62507 19.5169 5.62507 19.7843 5.47597 19.9745L2.10319 24.4888C1.98622 24.6443 1.96694 24.8526 2.05434 25.0274C2.14175 25.2009 2.31913 25.3114 2.51449 25.3114H5.39369C5.86286 25.3076 6.30501 25.0929 6.59678 24.7253L9.87703 20.6122C10.3308 20.0479 10.3308 19.2432 9.87703 18.679L6.59678 14.5861Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.6324 30.3461C20.0708 29.8846 19.261 29.8846 18.6993 30.3461L14.5861 33.6263C14.2185 33.9181 14.0038 34.3603 14 34.8294V37.7086C14 37.904 14.1105 38.0814 14.2841 38.1688C14.4589 38.2562 14.6671 38.2369 14.8226 38.1199L19.3267 34.7471C19.5169 34.598 19.7843 34.598 19.9745 34.7471L24.4786 38.1199C24.566 38.1906 24.6753 38.2266 24.7871 38.2227C24.8616 38.2369 24.9388 38.2369 25.0133 38.2227C25.1881 38.1341 25.2987 37.9554 25.3012 37.76V34.8808C25.2974 34.4117 25.0827 33.9695 24.7151 33.6777L20.6324 30.3461Z"
        fill="#FFFFF2"
      />
      <path
        d="M25.0235 2.07327C24.859 1.97558 24.6533 1.97558 24.4888 2.07327L19.9847 5.49738C19.7945 5.64648 19.5272 5.64648 19.3369 5.49738L14.8226 2.1246C14.6671 2.00763 14.4589 1.98835 14.2841 2.07575C14.1105 2.16316 14 2.34054 14 2.5359V5.41511C14.0039 5.88427 14.2185 6.32642 14.5861 6.61819L18.6993 9.89844C19.2635 10.3522 20.0682 10.3522 20.6324 9.89844L24.7456 6.61819C25.1132 6.32641 25.3279 5.88424 25.3317 5.41511V2.5359C25.3253 2.33538 25.206 2.15553 25.0235 2.07327Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
