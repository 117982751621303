/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DiscoveredSnippet,
  PublicProfile,
  SnippetType,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import Loader from "@/components/shared/Loader";
import LargeProfileImage from "@/components/shared/SmallProfileImage/LargeProfileImage";
import {
  snippetSlugsWithImages,
  snippetsIconMap,
  snippetsMap,
} from "./snippetsMap";
import {
  ExtendedArchetype,
  ExtendedPattern,
  ExtendedScale,
} from "@/models/sharedModels";
import {
  getSnippetLLMRequest,
  isSnippetLLMGenerated,
  regenerateSnippet,
  updateSnippetsFeedback,
} from "./utils";
import SnippetTraitComparisonDetailView from "./SnippetTraitComparisonDetailView";
import SnippetSimilarityComparisonDetailView from "./SnippetSimilarityComparisonDetailView";
import SnippetLlmListDetailView from "./SnippetLlmListDetailView";
import SnippetLlmTitleAndContentView from "./SnippetLlmTitleAndContentView";
import { AnalysisFeedbackSnippets } from "../personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import useGetSnippetModalDataHook, {
  useGetConnectedAndUnConnectedSnippetModalDataHook,
} from "@/helpers/useGetSnippetModalDataHook";
import SnippetLlmMatchView, {
  SnippetLlmMatchViewOrderedList,
  SnippetLlmMatchViewUnorderedList,
} from "./SnippetLlmMatchView";
import SnippetLlmHeaderedListView from "./SnippetLlmHeaderedListView";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import SnippetInterractionBar from "./SnippetInterractionBar/SnippetInterractionBar";
import { RootState } from "@/redux/store";
import { isTimestampWithin1Day } from "@/components/authenticatedProfile/utils";
import Alert from "@/components/shared/Alerts/Alert";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import LoadingScreen from "@/components/assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import { Mixpanel } from "@/helpers/mixpanel";
import InfoModal from "@/components/shared/InfoModal/InfoModal";
import SnippetLlmHeaderAndBody from "./SnippetLlmHeaderAndBody";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";
import { useAlertContext } from "@/context/AlertContext";
import SnippetLlmNaturedStatements from "./SnippetLlmNaturedStatements";
import SnippetAggregatePeerSpectrumView from "./SnippetAggregatePeerSpectrumView";
import SnippetAggregatePeerTwoByTwoView from "./SnippetAggregatePeerTwoByTwoView";
import SnippetStrengthsPeerRated from "./SnippetStrengthsPeerRated";
import SnippetPeerRatedNature from "./SnippetPeerRatedNature";
import SnippetPeerRatedTraitsDeltaView from "./SnippetPeerRatedTraitsDeltaView";
import SnippetNutshellView from "./SnippetNutshellView";
import SnippetsModalShareView from "./SnippetsModalShareView/SnippetsModalShareView";

type Props = {
  discoveredSnippetSlug: string;
};
export default function SnippetsModal(props: Props) {
  const { setModalComponent } = useModalContext();
  const { setAlertComponent } = useAlertContext();

  const { snippet, trait, score, userState, feedbackScore } =
    useGetSnippetModalDataHook(props.discoveredSnippetSlug);
  const discoverableSnippet = snippetsMap?.find(
    (s) => s?.snippetSlug === props.discoveredSnippetSlug
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showAnlaysis, setShowAnalysis] = useState<boolean>(false);

  const { user } = useAuth();

  const { disabled, errorType } = useRegenerateStatus(snippet);

  useEffect(() => {
    if (props.discoveredSnippetSlug) {
      Mixpanel?.track("Results Snippet Viewed", {
        slug: props.discoveredSnippetSlug,
        context: "mine",
      });
    }
  }, [props.discoveredSnippetSlug]);

  function handleRegenerate() {
    if (!disabled && snippet) {
      setModalComponent(
        <InfoModal
          title="Regenerate content?"
          body="You cannot undo this. Are you sure you want to regenerate this content?"
          secondaryText="Cancel"
          primaryText="Regenerate"
          elementName="InfoModal"
          onAccept={() => {
            setLoading(true);
            regenerateSnippet(snippet?.snippetSlug)
              .then((res) => {
                Mixpanel?.track("Regenerate CTA Clicked", {
                  content_type: "SP snippet",
                  slug: snippet?.snippetSlug,
                });
              })
              .catch((err) => {
                setAlertComponent(
                  <Alert
                    type="fail"
                    message={
                      "Results can be regenerated once a day. Try again later."
                    }
                    elementName="Alert"
                  />,
                  7000
                );
                setLoading(false);
              });
          }}
        />
      );
    } else {
      if (errorType === "premium") {
        return setModalComponent(<ConversionModal initialSlide={2} />);
      }
      setAlertComponent(
        <Alert
          type="fail"
          message={
            errorType === "time"
              ? "Results can be regenerated once a day. Try again later."
              : "Regenerating snippets is only available to Plus accounts"
          }
          elementName="Alert"
        />,
        7000
      );
      setLoading(false);
    }
  }

  if (snippet === null || discoverableSnippet === undefined) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  if (loading) {
    return (
      <ModalWrapper title="">
        <LoadingScreen message="Computing. We'll notify you when it's ready." />
      </ModalWrapper>
    );
  }

  function checkScroll(e: React.UIEvent<HTMLElement>) {
    const target = e.currentTarget;
    const isScrollable = target.scrollHeight > target.clientHeight;
    const isScrolledToBottom =
      target.scrollTop + target.clientHeight >= target.scrollHeight - 4;

    if (!isScrollable || isScrolledToBottom) {
      if (feedbackScore === null) {
        setShowAnalysis(true);
      }
    }
  }

  return (
    <ModalWrapper
      title={discoverableSnippet?.name ?? snippet.name}
      subTitle="Snippet"
      onScroll={(e) => {
        checkScroll(e);
      }}
    >
      <section className={styles.modalMain}>
        <div className={styles.modalWrapper}>
          {snippetSlugsWithImages.includes(snippet?.snippetSlug) && (
            <img
              alt=""
              src={`/snippetImages/${snippet?.snippetSlug}.png`}
              style={{ width: "100%" }}
            />
          )}
          <div className={styles.premiumPill}>
            {snippet.isPremium && <PremiumPillIcon />}
          </div>

          <div className={styles.top}>
            <LargeProfileImage
              publicProfile={userState.publicProfileData}
              imageUrl={userState.imageURL as string}
            />
            <div className={styles.snippetIcon}>
              {discoverableSnippet.emojiString ? (
                <h4 className={styles.modalEmojiTitle}>
                  {discoverableSnippet.emojiString}
                </h4>
              ) : (
                snippetsIconMap[props.discoveredSnippetSlug]
              )}
            </div>
          </div>
          <SnippetSimilarityComparisonDetailView
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetTraitComparisonDetailView
            trait={trait}
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
            score={score}
          />
          <SnippetLlmHeaderedListView
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetLlmListDetailView
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetLlmTitleAndContentView
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          {discoverableSnippet.snippetType !== SnippetType.llmOrderedMatch &&
            discoverableSnippet.snippetType !==
              SnippetType.llmUnorderedMatch && (
              <SnippetLlmMatchView
                snippet={snippet}
                discoverableSnippet={discoverableSnippet}
              />
            )}

          {discoverableSnippet.snippetType === SnippetType.llmOrderedMatch && (
            <SnippetLlmMatchViewOrderedList
              snippet={snippet}
              discoverableSnippet={discoverableSnippet}
            />
          )}

          {discoverableSnippet.snippetType ===
            SnippetType.llmUnorderedMatch && (
            <SnippetLlmMatchViewUnorderedList
              snippet={snippet}
              discoverableSnippet={discoverableSnippet}
            />
          )}

          <SnippetLlmHeaderAndBody
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetLlmNaturedStatements
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetAggregatePeerSpectrumView
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetAggregatePeerTwoByTwoView
            snippet={snippet}
            discoverableSnippet={discoverableSnippet}
          />
          <SnippetStrengthsPeerRated
            discoverableSnippet={discoverableSnippet}
            snippet={snippet}
          />
          <SnippetPeerRatedNature
            discoverableSnippet={discoverableSnippet}
            snippet={snippet}
          />
          <SnippetPeerRatedTraitsDeltaView
            discoverableSnippet={discoverableSnippet}
            snippet={snippet}
          />
          <SnippetNutshellView
            discoverableSnippet={discoverableSnippet}
            snippet={snippet}
          />

          {showAnlaysis && (
            <div className={styles.feedbackDiv}>
              <AnalysisFeedbackSnippets
                onAnswer={(value) => {
                  if (user?.uid) {
                    updateSnippetsFeedback(
                      snippet?.snippetSlug,
                      user?.uid,
                      value
                    );
                  }
                }}
                title="How did you like this content?"
                subtitle="Feedback helps us improve"
                currentAnswer={feedbackScore}
                onClose={() => setShowAnalysis(false)}
              />
            </div>
          )}
        </div>
        <div className={styles.interactionBarDiv}>
          <SnippetInterractionBar
            hideRegenerate={!isSnippetLLMGenerated(snippet)}
            hideShare
            onRegenerate={() => {
              handleRegenerate();
            }}
            disabledRegenerate={disabled}
            type="SP snippet"
            slug={snippet?.snippetSlug ?? ""}
            onRateClick={() => {
              setShowAnalysis(!showAnlaysis);
            }}
            onShare={
              snippet.snippetType === SnippetType.llmOrderedMatch ||
              snippet.snippetType === SnippetType.llmUnorderedMatch
                ? (value) => {
                    Mixpanel?.track("Shared to Social", {
                      platform:
                        value === "instagram" ? "Instagram" : "Snapchat",
                      slug: discoverableSnippet?.snippetSlug,
                      type: "resultSnippet",
                    });
                    setModalComponent(
                      <SnippetsModalShareView
                        type={value}
                        discoverableSnippet={discoverableSnippet}
                        discoveredSnippet={snippet}
                      />
                    );
                  }
                : undefined
            }
          />
        </div>
      </section>
    </ModalWrapper>
  );
}

export function SnippetsModalNonOwner(props: {
  discoveredSnippet: DiscoveredSnippet;
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  const discoverableSnippet = snippetsMap?.find(
    (s) => s?.snippetSlug === props.discoveredSnippet?.snippetSlug
  ) as DiscoveredSnippet;
  const friendUidList = useSelector(
    (state: RootState) => state.user
  ).friendUidList;
  const isConnection =
    props.publicProfile !== null &&
    friendUidList.includes(props.publicProfile?.ownerUUID);

  const { loading, score, trait } =
    useGetConnectedAndUnConnectedSnippetModalDataHook(
      props.publicProfile,
      props.discoveredSnippet
    );

  useEffect(() => {
    if (props.discoveredSnippet?.snippetSlug && props.publicProfile !== null) {
      Mixpanel?.track("Results Snippet Viewed", {
        slug: props.discoveredSnippet?.snippetSlug,
        context: isConnection ? "connected profile" : "unconnected profile",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.discoveredSnippet?.snippetSlug, isConnection]);

  if (loading) {
    return (
      <ModalWrapper title="">
        <LoadingScreen message="Loading" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      title={discoverableSnippet?.name ?? props.discoveredSnippet.name}
      subTitle="Snippet"
    >
      <section style={{ paddingBottom: "50px" }} className={styles.modalMain}>
        <div className={styles.modalWrapper}>
          <div className={styles.top}>
            <LargeProfileImage
              publicProfile={props.publicProfile}
              imageUrl={props.imageUrl}
            />
            <div className={styles.snippetIcon}>
              {snippetsIconMap[props.discoveredSnippet?.snippetSlug]}
            </div>
          </div>

          <SnippetTraitComparisonDetailView
            trait={trait}
            snippet={props.discoveredSnippet}
            discoverableSnippet={discoverableSnippet}
            score={score}
            imageUrl={props.imageUrl}
            nature={props.publicProfile?.primaryNature?.nature}
            uid={props.publicProfile?.ownerUUID}
          />

          <SnippetLlmNaturedStatements
            snippet={props.discoveredSnippet}
            discoverableSnippet={discoverableSnippet}
          />

          <SnippetNutshellView
            snippet={props.discoveredSnippet}
            discoverableSnippet={discoverableSnippet}
          />
        </div>
      </section>
    </ModalWrapper>
  );
}

export function UnderlinedTraitText(props: {
  text: string;
  traitName: string;
  trait?: ExtendedScale | ExtendedPattern | ExtendedArchetype;
}) {
  const lowerText = props.text.toLowerCase();
  const lowerTraitName = props.traitName.toLowerCase();
  const { user } = useAuth();
  const { setModalComponent } = useModalContext();

  const traitIndex = lowerText.indexOf(lowerTraitName);

  if (traitIndex !== -1) {
    if (props.trait) {
      if (props.trait.traitContent.traitType === "element") {
        return (
          <h2 className={styles.title}>
            {props.text.slice(0, traitIndex)}
            <div
              onClick={() => {
                if (props.trait?.slug) {
                  setModalComponent(<ElementModal slug={props.trait.slug} />);
                }
              }}
              style={{ cursor: "pointer", marginTop: "0" }}
              className={styles.title}
            >
              <u>
                {props.text.slice(
                  traitIndex,
                  traitIndex + props.traitName.length
                )}
              </u>
            </div>
            {props.text.slice(traitIndex + props.traitName.length)}
          </h2>
        );
      } else {
        return (
          <h2 className={styles.title}>
            {props.text.slice(0, traitIndex)}
            <div
              onClick={() => {
                if (props.trait?.slug) {
                  setModalComponent(<PatternModal slug={props.trait.slug} />);
                }
              }}
              style={{ cursor: "pointer", marginTop: "0" }}
              className={styles.title}
            >
              <u>
                {props.text.slice(
                  traitIndex,
                  traitIndex + props.traitName.length
                )}
              </u>
            </div>
            {props.text.slice(traitIndex + props.traitName.length)}
          </h2>
        );
      }
    }
    return (
      <h2 className={styles.title}>
        {props.text.slice(0, traitIndex)}
        <u>
          {props.text.slice(traitIndex, traitIndex + props.traitName.length)}
        </u>
        {props.text.slice(traitIndex + props.traitName.length)}
      </h2>
    );
  }

  return <h2 className={styles.title}>{props.text}</h2>;
}

function useRegenerateStatus(snippet: DiscoveredSnippet | null) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<"premium" | "time">("premium");

  const { user } = useAuth();
  const isPremium = useSelector((state: RootState) => state.user.isPremium);

  useEffect(() => {
    if (!isPremium) {
      setDisabled(true);
      setErrorType("premium");
    } else {
      if (snippet) {
        getSnippetLLMRequest(user?.uid, snippet)
          .then((res) => {
            const isWithin24Hours = isTimestampWithin1Day(res.createdOn);
            if (isWithin24Hours === true) {
              setDisabled(true);
              setErrorType("time");
            }
          })
          .catch((err) => {
            setDisabled(false);
          });
      }
    }
  }, [isPremium, snippet, user?.uid]);

  return { disabled, errorType };
}
