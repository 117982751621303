export default function RegenerateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <g clipPath="url(#clip0_49688_924)">
        <path
          d="M19.6478 9.87961C21.6227 13.3001 20.4456 17.688 17.0258 19.6625C14.9984 20.833 12.6356 20.8943 10.6336 20.0413L11.4178 17.1117L5.83526 20.3347L9.05835 25.9173L9.88511 22.8318C12.6024 23.8617 15.7433 23.71 18.4577 22.1429C23.2531 19.3742 24.8965 13.243 22.1275 8.44693L19.6471 9.87901L19.6478 9.87961Z"
          fill="#E6E1DF"
        />
        <path
          d="M17.0041 4.09075C14.2863 3.0599 11.1443 3.20983 8.42994 4.77698C3.63457 7.54559 1.99217 13.6787 4.76065 18.4738L7.24199 17.0412C5.26716 13.6207 6.44281 9.2324 9.86354 7.25744C11.8892 6.08795 14.2537 6.02566 16.2559 6.87736L15.4713 9.80836L21.0538 6.58527L17.8318 1.00452L17.0041 4.09075Z"
          fill="#E6E1DF"
        />
      </g>
      <defs>
        <clipPath id="clip0_49688_924">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
