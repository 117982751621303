import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function SexAttitudesIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ padding: "1px" }}
    >
      <path
        d="M24 0.25H12.125C8.97528 0.25 5.95475 1.50151 3.72819 3.72819C1.50163 5.95487 0.25 8.97551 0.25 12.125H12.125C8.97528 12.125 5.95475 13.3765 3.72819 15.6032C1.50163 17.8299 0.25 20.8505 0.25 24H12.125C15.2747 24 18.2953 22.7485 20.5218 20.5218C22.7484 18.2951 24 15.2745 24 12.125H12.1298C15.2785 12.1241 18.2983 10.8716 20.524 8.64515C22.7496 6.41859 24.0002 3.39878 24.0002 0.25L24 0.25Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
