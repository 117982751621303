import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ContentIdentifier,
  PublicProfile,
  SpectrumData,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";
import Image from "next/image";

import styles from "./Spectrums.module.css";

import BarHeading from "@/components/shared/BarHeading";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import Loader from "@/components/shared/Loader";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { getConnectionProfileColor } from "../utils";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import { TraitType } from "@dimensional-engineering/dimensional-models/lib/models/traits/details/TraitType";
import PatternModal from "@/components/traits/TraitsModals/Pattern/PatternModal";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";
import Link from "next/link";

type Props = {
  title?: string;
  contentIdentifier: ContentIdentifier;
  otherPublicProfile: PublicProfile | null | undefined;
  imageUrl?: string | undefined;
  dots?: boolean;
  aggregatePeer?: boolean;
  cachedData?: SpectrumData[];
  onCompareTabCache?: (data: SpectrumData[], slug: string) => void;
  noMargin?: boolean;
  onLoad?: () => void;
};

export default function Spectrums(props: Props) {
  const { setModalComponent } = useModalContext();
  const { data, loading } = useGetSpectrumData(props);

  const userState = useSelector((state: RootState) => state.user);

  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.otherPublicProfile?.primaryNature?.nature
  );

  function handleTraitIdentifierClick(traitIdentifier: TraitIdentifier) {
    if (traitIdentifier.type === TraitType.element) {
      return setModalComponent(<ElementModal slug={traitIdentifier.slug} />);
    } else if (traitIdentifier.type === TraitType.pattern) {
      return setModalComponent(<PatternModal slug={traitIdentifier.slug} />);
    } else {
      return setModalComponent(<ArchetypeModal slug={traitIdentifier.slug} />);
    }
  }

  const mappedSpectrumsAggregatePeer = data?.map((row, index) => {
    return (
      <div key={index} className={styles.row}>
        <div className={styles.rowParent}>
          <div>
            <p
              className={
                row.lowTraitIdentifier ? styles.selected : styles.unselected
              }
              onClick={() => {
                if (row.lowTraitIdentifier) {
                  handleTraitIdentifierClick(row.lowTraitIdentifier);
                }
              }}
            >
              {row.lowLabel}
            </p>
            <p className={styles.description}>{row.lowDescription}</p>
          </div>
          <div>
            <p
              style={{ textAlign: "right", marginLeft: "auto" }}
              className={
                row.highTraitIdentifier ? styles.selected : styles.unselected
              }
              onClick={() => {
                if (row.highTraitIdentifier) {
                  handleTraitIdentifierClick(row.highTraitIdentifier);
                }
              }}
            >
              {row.highLabel}
            </p>
            <p className={styles.description} style={{ textAlign: "right" }}>
              {row.highDescription}
            </p>
          </div>
        </div>
        <div className={styles.lineParent}>
          {row.myScore !== null && row.myScore !== undefined ? (
            <div
              style={{ left: `calc(${row.myScore * 100}% - 15px)` }}
              className={styles.imageDiv}
            >
              <Image
                priority
                src={
                  userState.imageURL ? userState.imageURL : "/default-image.png"
                }
                alt="Profile Image"
                fill
                style={
                  userState.publicProfileData?.primaryNature?.nature
                    ? {
                        borderRadius: "50%",
                        border: `3px solid ${
                          ProfileColorMap[
                            userState.publicProfileData?.primaryNature?.nature
                          ]
                        }`,
                        zIndex: 1000,
                      }
                    : {
                        borderRadius: "50%",
                        border: `3px solid #55504F`,
                        zIndex: 1000,
                      }
                }
              />
            </div>
          ) : null}
          {row.myAggregatePeerScore !== null &&
          row.myAggregatePeerScore !== undefined ? (
            <div
              style={{
                left: `calc(${row.myAggregatePeerScore * 100}% - 15px)`,
                bottom: "-8px",
              }}
              className={styles.peerRatedImage}
            >
              <PeerRatedScorePill />
            </div>
          ) : null}
          <div className={styles.lineLeft}></div>
          <div className={styles.midLine}></div>
          <div className={styles.lineMiddle}></div>
          <div className={styles.lineRight}></div>
        </div>
      </div>
    );
  });

  const mappedSpectrumsDots = data?.map((row, index) => {
    return (
      <div key={index} className={styles.row}>
        <div className={styles.rowParent}>
          <div>
            <p
              className={
                row.lowTraitIdentifier ? styles.selected : styles.unselected
              }
              onClick={() => {
                if (row.lowTraitIdentifier) {
                  handleTraitIdentifierClick(row.lowTraitIdentifier);
                }
              }}
            >
              {row.lowLabel}
            </p>
            <p className={styles.description}>{row.lowDescription}</p>
          </div>
          <div>
            <p
              style={{ textAlign: "right", marginLeft: "auto" }}
              className={
                row.highTraitIdentifier ? styles.selected : styles.unselected
              }
              onClick={() => {
                if (row.highTraitIdentifier) {
                  handleTraitIdentifierClick(row.highTraitIdentifier);
                }
              }}
            >
              {row.highLabel}
            </p>
            <p className={styles.description} style={{ textAlign: "right" }}>
              {row.highDescription}
            </p>
          </div>
        </div>
        <div className={styles.lineParent}>
          {row.myScore !== null && row.myScore !== undefined ? (
            <div
              style={{
                left: `calc(${row.myScore * 100}% - 10.5px)`,
                bottom: "-4px",
              }}
              className={styles.circle}
            ></div>
          ) : null}
          {row.connectionScore !== null && row.connectionScore !== undefined ? (
            <div
              style={{
                left: `calc(${row.connectionScore * 100}% - 10.5px)`,
                bottom: "-4px",
              }}
              className={styles.circleGrey}
            ></div>
          ) : null}
          <div className={styles.lineLeft}></div>
          <div className={styles.midLine}></div>
          <div className={styles.lineMiddle}></div>
          <div className={styles.lineRight}></div>
        </div>
      </div>
    );
  });

  const mappedSpectrums = data?.map((row, index) => {
    return (
      <div
        style={props.noMargin && index === 0 ? { marginTop: "10px" } : {}}
        key={index}
        className={styles.row}
      >
        <div className={styles.rowParent}>
          <div>
            <p
              className={
                row.lowTraitIdentifier ? styles.selected : styles.unselected
              }
              onClick={() => {
                if (row.lowTraitIdentifier) {
                  handleTraitIdentifierClick(row.lowTraitIdentifier);
                }
              }}
            >
              {row.lowLabel}
            </p>
            <p className={styles.description}>{row.lowDescription}</p>
          </div>
          <div>
            <p
              style={{ textAlign: "right", marginLeft: "auto" }}
              className={
                row.highTraitIdentifier ? styles.selected : styles.unselected
              }
              onClick={() => {
                if (row.highTraitIdentifier) {
                  handleTraitIdentifierClick(row.highTraitIdentifier);
                }
              }}
            >
              {row.highLabel}
            </p>
            <p className={styles.description} style={{ textAlign: "right" }}>
              {row.highDescription}
            </p>
          </div>
        </div>
        <div className={styles.lineParent}>
          {row.myScore !== null && row.myScore !== undefined ? (
            <div
              style={{ left: `calc(${row.myScore * 100}% - 15px)` }}
              className={styles.imageDiv}
            >
              <Image
                priority
                src={
                  userState.imageURL ? userState.imageURL : "/default-image.png"
                }
                alt="Profile Image"
                fill
                style={
                  userState.publicProfileData?.primaryNature?.nature
                    ? {
                        borderRadius: "50%",
                        border: `3px solid ${
                          ProfileColorMap[
                            userState.publicProfileData?.primaryNature?.nature
                          ]
                        }`,
                        zIndex: 1000,
                      }
                    : {
                        borderRadius: "50%",
                        border: `3px solid #55504F`,
                        zIndex: 1000,
                      }
                }
              />
            </div>
          ) : null}
          {row.connectionScore !== null && row.connectionScore !== undefined ? (
            <div
              style={{ left: `calc(${row.connectionScore * 100}% - 15px)` }}
              className={styles.imageDiv}
            >
              <Image
                priority
                src={props.imageUrl ? props.imageUrl : "/default-image.png"}
                alt="Profile Image"
                fill
                style={{
                  borderRadius: "50%",
                  border: `3px solid ${connectionColor}`,
                }}
              />
            </div>
          ) : null}
          <div className={styles.lineLeft}></div>
          <div className={styles.midLine}></div>
          <div className={styles.lineMiddle}></div>
          <div className={styles.lineRight}></div>
        </div>
      </div>
    );
  });

  if (loading || !data) {
    return (
      <section className={styles.main}>
        {props.title && <BarHeading heading={props.title} />}
        <Loader height="400px" mobileHeight="400px" />
      </section>
    );
  }

  return (
    <section
      style={props.noMargin ? { marginTop: "0" } : {}}
      className={styles.main}
    >
      {props.title && <BarHeading heading={props.title} />}
      {props.dots
        ? mappedSpectrumsDots
        : props.aggregatePeer
        ? mappedSpectrumsAggregatePeer
        : mappedSpectrums}
    </section>
  );
}

export function SpectrumsNoDescriptions(props: Props) {
  const { data, loading } = useGetSpectrumData(props);

  useEffect(() => {
    if (loading === false && props.onLoad) {
      props.onLoad();
    }
  }, [loading]);

  const userState = useSelector((state: RootState) => state.user);

  const connectionColor = getConnectionProfileColor(
    userState.publicProfileData?.primaryNature?.nature,
    props.otherPublicProfile?.primaryNature?.nature
  );

  const mappedSpectrums = data?.map((row, index) => {
    return (
      <div
        style={props.noMargin && index === 0 ? { marginTop: "10px" } : {}}
        key={index}
        className={styles.row}
      >
        <div className={styles.rowParent}>
          <div>
            <p className={styles.unselected}>{row.lowLabel}</p>
          </div>
          <div>
            <p
              style={{ textAlign: "right", marginLeft: "auto" }}
              className={styles.unselected}
            >
              {row.highLabel}
            </p>
          </div>
        </div>
        <div className={styles.lineParent}>
          {row.myScore !== null && row.myScore !== undefined ? (
            <div
              style={{ left: `calc(${row.myScore * 100}% - 15px)` }}
              className={styles.imageDiv}
            >
              <Image
                priority
                src={
                  userState.imageURL ? userState.imageURL : "/default-image.png"
                }
                alt="Profile Image"
                fill
                style={
                  userState.publicProfileData?.primaryNature?.nature
                    ? {
                        borderRadius: "50%",
                        border: `3px solid ${
                          ProfileColorMap[
                            userState.publicProfileData?.primaryNature?.nature
                          ]
                        }`,
                        zIndex: 1000,
                      }
                    : {
                        borderRadius: "50%",
                        border: `3px solid #55504F`,
                        zIndex: 1000,
                      }
                }
              />
            </div>
          ) : null}
          {row.connectionScore !== null && row.connectionScore !== undefined ? (
            <div
              style={{ left: `calc(${row.connectionScore * 100}% - 15px)` }}
              className={styles.imageDiv}
            >
              <Image
                priority
                src={props.imageUrl ? props.imageUrl : "/default-image.png"}
                alt="Profile Image"
                fill
                style={{
                  borderRadius: "50%",
                  border: `3px solid ${connectionColor}`,
                }}
              />
            </div>
          ) : null}
          <div className={styles.lineLeft}></div>
          <div className={styles.midLine}></div>
          <div className={styles.lineMiddle}></div>
          <div className={styles.lineRight}></div>
        </div>
      </div>
    );
  });

  if (loading || !data) {
    return (
      <section className={styles.main}>
        {props.title && <BarHeading heading={props.title} />}
        <Loader height="400px" mobileHeight="400px" />
      </section>
    );
  }

  return (
    <section
      style={props.noMargin ? { marginTop: "0" } : {}}
      className={styles.main}
    >
      {props.title && <BarHeading heading={props.title} />}
      {mappedSpectrums}
    </section>
  );
}

export function SEOSPectrums(props: {
  cachedData?: SpectrumData[];
  otherPublicProfile: PublicProfile | null | undefined;
  imageUrl?: string | undefined;
}) {
  const mappedSpectrums = props.cachedData?.map((row, index) => {
    return (
      <div key={index} className={styles.row}>
        <div className={styles.rowParent}>
          <div className={styles.rowInfo}>
            <Link
              href={`/traits/elements/${row.lowTraitIdentifier?.slug}`}
              target="__blank"
              className={
                row.lowTraitIdentifier ? styles.selected : styles.unselected
              }
            >
              {row.lowLabel}
            </Link>
            <p className={styles.description}>{row.lowDescription}</p>
          </div>
          <div className={styles.rowInfo}>
            <Link
              href={`/traits/elements/${row.highTraitIdentifier?.slug}`}
              target="__blank"
              style={{ textAlign: "right", marginLeft: "auto" }}
              className={
                row.highTraitIdentifier ? styles.selected : styles.unselected
              }
            >
              {row.highLabel}
            </Link>
            <p className={styles.description} style={{ textAlign: "right" }}>
              {row.highDescription}
            </p>
          </div>
        </div>
        <div className={styles.lineParent}>
          {row.myScore !== null && row.myScore !== undefined ? (
            <div
              style={{ left: `calc(${row.myScore * 100}% - 15px)` }}
              className={styles.imageDiv}
            >
              <Image
                priority
                src={props.imageUrl ? props.imageUrl : "/default-image.png"}
                alt="Profile Image"
                fill
                style={
                  props.otherPublicProfile?.primaryNature?.nature
                    ? {
                        borderRadius: "50%",
                        border: `3px solid ${
                          ProfileColorMap[
                            props.otherPublicProfile?.primaryNature?.nature
                          ]
                        }`,
                        zIndex: 1000,
                      }
                    : {
                        borderRadius: "50%",
                        border: `3px solid #55504F`,
                        zIndex: 1000,
                      }
                }
              />
            </div>
          ) : null}
          <div className={styles.lineLeft}></div>
          <div className={styles.midLine}></div>
          <div className={styles.lineMiddle}></div>
          <div className={styles.lineRight}></div>
        </div>
      </div>
    );
  });

  return <section className={styles.main}>{mappedSpectrums}</section>;
}

function useGetSpectrumData(props: Props) {
  const [data, setData] = useState<SpectrumData[] | null>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.cachedData) {
      setLoading(false);
      return setData(props.cachedData);
    }
    setLoading(true);
    const call = callFirebaseFunctions("fetchSpectrumData");
    call({
      contentIdentifier: props.contentIdentifier,
      peerUid: props.otherPublicProfile?.ownerUUID,
    })
      .then((res) => {
        setLoading(false);
        setData(res.data as SpectrumData[]);
        if (props.onCompareTabCache) {
          props.onCompareTabCache(
            res.data as SpectrumData[],
            props.contentIdentifier.contentSlug
          );
        }
      })
      .catch((err: any) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.contentIdentifier.contentSlug,
    props.contentIdentifier.contentType,
    props.otherPublicProfile?.ownerUUID,
  ]);

  return { data, loading };
}

export function PeerRatedScorePill() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.64541 6.22687C3.4858 6.02577 3.24392 5.90836 2.9873 5.90625H1.4123C1.30542 5.90625 1.20839 5.96672 1.16059 6.06164C1.11277 6.15726 1.12332 6.27118 1.1873 6.35625L3.0323 8.82009C3.11387 8.92415 3.11387 9.07039 3.0323 9.17447L1.1873 11.6439C1.12332 11.729 1.11277 11.8429 1.16059 11.9385C1.2084 12.0334 1.30543 12.0939 1.4123 12.0939H2.9873C3.24394 12.0918 3.48581 11.9744 3.64541 11.7733L5.4398 9.52327C5.688 9.21461 5.688 8.77443 5.4398 8.46577L3.64541 6.22687Z"
        fill="#FFFFF2"
      />
      <path
        d="M9.52948 12.5604C9.22222 12.308 8.77924 12.308 8.47198 12.5604L6.22198 14.3548C6.02089 14.5144 5.90347 14.7563 5.90137 15.0129V16.5879C5.90137 16.6948 5.96184 16.7918 6.05676 16.8396C6.15238 16.8874 6.2663 16.8769 6.35136 16.8129L8.8152 14.9679C8.91927 14.8863 9.06551 14.8863 9.16959 14.9679L11.6334 16.8129C11.6812 16.8516 11.741 16.8713 11.8022 16.8691C11.843 16.8769 11.8851 16.8769 11.9259 16.8691C12.0216 16.8206 12.082 16.7229 12.0834 16.616V15.041C12.0813 14.7844 11.9639 14.5425 11.7628 14.3829L9.52948 12.5604Z"
        fill="#FFFFF2"
      />
      <path
        d="M16.8136 6.35625C16.8776 6.27117 16.8882 6.15726 16.8404 6.06164C16.7926 5.96672 16.6955 5.90625 16.5887 5.90625H15.0137C14.757 5.90836 14.5151 6.02578 14.3555 6.22687L12.5612 8.47687C12.3129 8.78553 12.3129 9.2257 12.5612 9.53437L14.3555 11.7844C14.5151 11.9855 14.757 12.1029 15.0137 12.105H16.5887C16.6955 12.105 16.7926 12.0445 16.8404 11.9496C16.8882 11.854 16.8776 11.7401 16.8136 11.655L14.9686 9.19115C14.8871 9.08708 14.8871 8.94084 14.9686 8.83676L16.8136 6.35625Z"
        fill="#FFFFF2"
      />
      <path
        d="M11.9364 1.15873C11.8464 1.10529 11.7339 1.10529 11.6439 1.15873L9.18005 3.03181C9.07599 3.11337 8.92974 3.11337 8.82567 3.03181L6.35625 1.18681C6.27117 1.12283 6.15726 1.11228 6.06164 1.16009C5.96672 1.20791 5.90625 1.30494 5.90625 1.4118V2.9868C5.90836 3.24345 6.02578 3.48531 6.22687 3.64492L8.47687 5.4393C8.78553 5.68751 9.2257 5.68751 9.53437 5.4393L11.7844 3.64492C11.9855 3.48531 12.1029 3.24343 12.105 2.9868V1.4118C12.1015 1.30212 12.0362 1.20373 11.9364 1.15873Z"
        fill="#FFFFF2"
      />
      <circle cx="9.04523" cy="9.04475" r="2.43" fill="#FFFFF2" />
    </svg>
  );
}
