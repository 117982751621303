import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function AttachmentStyleIcon(
  props: DimensionIconAssessmentStatus
) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.197 12.509C62.7027 9.86073 56.4039 10.4693 52.5593 14.0505C48.2805 18.0396 47.5701 24.2356 43.8669 28.6092C42.6685 30.0303 40.8808 31.1371 39.0259 30.9499C31.8855 30.1641 30.7408 16.9495 25.489 13.0942C23.663 11.7924 21.5043 11.0286 19.261 10.8906C4.46507 9.8206 2.91648 29.4117 2.96697 39.9814C2.94004 45.9065 3.54265 52.0523 5.28988 57.7301C6.29984 61.0504 7.83161 64.6684 10.7066 66.8284C14.7465 69.8679 21.5098 69.8378 25.5463 66.8284C30.7846 62.9229 31.8181 49.7151 39.1168 49.0463C40.8 48.8925 42.4294 49.7585 43.5943 50.9623C47.9371 55.4496 48.2232 62.5819 53.2629 66.5977C57.1075 69.6606 62.9855 69.8378 67.2576 67.5373C76.0409 62.8092 76.1318 42.0779 76.1217 39.968C76.1587 31.6721 75.6066 19.1765 68.52 13.4118C68.103 13.0776 67.6607 12.7757 67.197 12.509V12.509Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
