import { DiscoveredStory } from "@dimensional-engineering/dimensional-models";

import styles from "../../../styles/app/AppStories.module.css";

import { storyColorMap } from "@/components/results/stories/storyColorMap";
import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import {
  replaceDescription,
  replaceTitle,
} from "@/components/results/stories/storiesTextFunctions";
import { useModalContext } from "@/context/ModalContext";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";

type Props = {
  story: DiscoveredStory;
  own: boolean;
};

export default function StoriesModal(props: Props) {
  const { setModalOpen } = useModalContext();
  const title = props.story?.name.includes("|")
    ? props.own
      ? replaceTitle(props.story?.name, false)
      : replaceTitle(props.story?.name, true)
    : props.story?.name;

  const mapped = props.story?.storyDetail?.listicle?.descriptors.map(
    (desc, index) => {
      return (
        <div className={styles.descDiv} key={index}>
          <div
            onClick={() => {
              setModalOpen(false);
            }}
            className={styles.xmark}
          >
            <CloseButtonSummary />
          </div>
          <div
            style={
              props.story.storySlug
                ? {
                    backgroundColor:
                      storyColorMap[
                        props.story.storySlug as keyof typeof storyColorMap
                      ],
                  }
                : {}
            }
            className={styles.circle}
          >
            <p
              style={{
                backgroundImage: `url(/stories/${props.story.storySlug}.png)`,
              }}
            >
              {index + 1}
            </p>
          </div>
          <h4
            style={
              props.story.storySlug
                ? {
                    color:
                      storyColorMap[
                        props.story.storySlug as keyof typeof storyColorMap
                      ],
                  }
                : {}
            }
          >
            {desc.includes("|") ? replaceDescription(desc, !props.own) : desc}
          </h4>
        </div>
      );
    }
  );

  return (
    <ModalWrapper title="" noHeader>
      <div
        style={{
          backgroundImage: `url(/stories/${props.story.storySlug}.png)`,
        }}
        className={styles.slugMain}
      >
        <h1
          style={
            props.story.storySlug
              ? {
                  color:
                    storyColorMap[
                      props.story.storySlug as keyof typeof storyColorMap
                    ],
                }
              : {}
          }
        >
          {title}
        </h1>
        <div className={styles.slugMappedDiv}>{mapped}</div>
      </div>
    </ModalWrapper>
  );
}
