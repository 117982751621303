import PrivateIcon from "@/components/shared/icons/PrivateIcon";
import styles from "./TraitAccessDeniedBar.module.css";

export default function PrivateTraitAccessDeniedBar() {
  return (
    <div className={styles.privateMain}>
      <div className={styles.privateWrapper}>
        <div className={styles.iconDiv}>
          <PrivateIcon />
        </div>
        <p className={styles.privateText}>
          Private trait: score not available to connections
        </p>
      </div>
    </div>
  );
}
