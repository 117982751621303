import { useEffect, useState } from "react";
import Link from "next/link";

import styles from "./ArchetypeComponentTree.module.css";

import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import Element from "@/components/traits/element/Element/Element";
import { ExtendedArchetype } from "@/models/sharedModels";
import elementJsonData from "../../../../../assets/traitInfo/elements.json";
import useIsMobile from "@/helpers/useIsMobile";
import TraitsHeading from "../../Heading/TraitsHeading";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";
import { natureData } from "@/assets/traitInfo/natureData";

type Props = {
  archetype: ExtendedArchetype;
  authenticated?: boolean;
  uid?: string;
};

export default function ArchetypeComponentTree(props: Props) {
  const lineWidth = useGetLineWidth({ archetype: props.archetype });
  const { setModalComponent } = useModalContext();

  if (props.authenticated) {
    return (
      <div className={styles.main}>
        <TraitsHeading title="Archetype Components" />
        <div className={styles.parent}>
          <div className={styles.archDiv}>
            <Archetype archetype={props.archetype} />
            <div className={styles.archLine}></div>
          </div>
          <div
            style={{ width: `${lineWidth}px` }}
            className={styles.long}
          ></div>
          <div className={styles.wrapper}>
            {props.archetype.traitContent.archetypeDetails?.componentTraits.map(
              (el, index) => {
                let color: any;
                let dimensionSlug;
                elementJsonData.forEach((e) => {
                  if (e.slug === el.slug) {
                    color =
                      natureData?.find((n) => n.slug === e?.nature)?.color ??
                      "gray";
                    dimensionSlug = e.dimensionSlug;
                  }
                });
                return (
                  <div className={styles.elDiv} key={el.slug}>
                    <div className={styles.archLine}></div>
                    <div
                      onClick={() =>
                        setModalComponent(
                          <ElementModal slug={el.slug} uid={props.uid} />
                        )
                      }
                      style={{ cursor: "pointer" }}
                      key={el.slug}
                      className="elBodyNoMarginTopBottom"
                    >
                      <Element slug={el.slug} />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <TraitsHeading title="Archetype Components" />
      <div className={styles.parent}>
        <div className={styles.archDiv}>
          <Archetype archetype={props.archetype} />
          <div className={styles.archLine}></div>
        </div>
        <div style={{ width: `${lineWidth}px` }} className={styles.long}></div>
        <div className={styles.wrapper}>
          {props.archetype.traitContent.archetypeDetails?.componentTraits.map(
            (el, index) => {
              let color: any;
              let dimensionSlug;
              elementJsonData.forEach((e) => {
                if (e.slug === el.slug) {
                  color =
                    natureData?.find((n) => n.slug === e?.nature)?.color ??
                    "gray";
                  dimensionSlug = e.dimensionSlug;
                }
              });
              return (
                <div className={styles.elDiv} key={el.slug}>
                  <div className={styles.archLine}></div>
                  <Link
                    href={`/traits/elements/${el.slug}`}
                    key={el.slug}
                    className="elBodyNoMarginTopBottom"
                  >
                    <Element slug={el.slug} />
                  </Link>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

function useGetLineWidth(props: { archetype: ExtendedArchetype }) {
  const [lineWidth, setLineWidth] = useState<number>(51.5);
  const isMobile = useIsMobile();

  useEffect(() => {
    const length =
      props.archetype.traitContent.archetypeDetails?.componentTraits.length;

    if (length) {
      if (isMobile) {
        if (length === 2) {
          setLineWidth(103);
        } else if (length === 3) {
          setLineWidth(192);
        } else if (length === 4) {
          setLineWidth(192);
        } else {
          setLineWidth(192);
        }
      } else {
        if (length === 2) {
          setLineWidth(103);
        } else if (length === 3) {
          setLineWidth(192);
        } else if (length === 4) {
          setLineWidth(4 * 76);
        } else if (length === 5) {
          setLineWidth(405);
        } else {
          setLineWidth(477);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.archetype.traitContent.traitDetails?.length]);

  return lineWidth;
}
