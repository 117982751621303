import { useEffect, useState } from "react";
import { Roboto_Mono } from "next/font/google";

import styles from "./SelfRatedScoreTable.module.css";

import DownArrowIcon from "@/components/shared/icons/DownArrowIcon";
import UpArrowIcon from "@/components/shared/icons/UpArrowIcon";
import UknownQuestionMarkIcon from "@/components/shared/icons/UknownQuestionMarkIcon";
import useGetPeerRatedData from "@/helpers/useGetPeerRatedData";
import AccuracyComponent from "./AccuracyComponent";
import PeerAssessmentIcon from "@/components/shared/icons/PeerAssessmentIcon";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function PeerRatedScoreTable(props: {
  isPremium: boolean | null | undefined;
  slug: string;
}) {
  const [scoreText, setScoreText] = useState<
    "Very low" | "Low" | "Average" | "High" | "Very high"
  >();

  const { AggregatePeerScore, peerScore, accuracy } = useGetPeerRatedData(
    props.slug
  );

  useEffect(() => {
    if (peerScore !== null && peerScore !== undefined) {
      if (peerScore <= 12) {
        setScoreText("Very low");
      } else if (peerScore > 12 && peerScore <= 29) {
        setScoreText("Low");
      } else if (peerScore > 29 && peerScore <= 69) {
        setScoreText("Average");
      } else if (peerScore > 69 && peerScore <= 88) {
        setScoreText("High");
      } else {
        setScoreText("Very high");
      }
    }
  }, [peerScore]);

  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>Peer-rated score</h1>
          <PeerAssessmentIcon />
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Score</h3>
          {peerScore !== null && peerScore !== undefined && (
            <>
              <div className={styles.scoreWrapper}>
                <div
                  style={{ marginLeft: "4" }}
                  className={styles.selected}
                ></div>
                <div
                  className={
                    peerScore > 12 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    peerScore > 30 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    peerScore > 70 ? styles.selected : styles.unselected
                  }
                ></div>
                <div
                  className={
                    peerScore > 88 ? styles.selected : styles.unselected
                  }
                ></div>
              </div>
              <div className={styles.scoreDescDiv}>
                <h4>{scoreText}</h4>
                <div className={styles.iconDiv}>
                  {scoreText === "Very low" && (
                    <>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <DownArrowIcon />
                      </div>
                    </>
                  )}
                  {scoreText === "Low" && (
                    <div className={styles.arrow}>
                      <DownArrowIcon />
                    </div>
                  )}
                  {scoreText === "Average" && (
                    <div className={styles.neutral}></div>
                  )}
                  {scoreText === "High" && (
                    <div className={styles.arrow}>
                      <UpArrowIcon />
                    </div>
                  )}
                  {scoreText === "Very high" && (
                    <>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                      <div className={styles.arrow}>
                        <UpArrowIcon />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {!peerScore && (
            <>
              <div className={styles.scoreWrapper}>
                <div
                  style={{ marginLeft: "4" }}
                  className={styles.unselected}
                ></div>
                <div className={styles.unselected}></div>
                <div className={styles.unselected}></div>
                <div className={styles.unselected}></div>
                <div className={styles.unselected}></div>
                <div className={styles.restrictIcon}>
                  <UknownQuestionMarkIcon />
                </div>
              </div>
              <div className={styles.scoreDescDiv}>
                <h4>Unknown</h4>
              </div>
            </>
          )}
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Accuracy</h3>
          <AccuracyComponent confidence={accuracy} />
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Peer-rated raw score</h3>
          <h3 className={styles.rowText}>
            {AggregatePeerScore?.percentScore
              ? (AggregatePeerScore?.percentScore * 100).toFixed(0)
              : "?"}
          </h3>
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Peer-rated percentile score</h3>
          <h3 className={styles.rowText}>
            {peerScore ? peerScore.toFixed(0) : "?"}
          </h3>
        </div>
        <div className={`${styles.row} ${font.className}`}>
          <h3 className={styles.rowText}>Rank in group</h3>
          <h3 className={styles.rowText}>
            {AggregatePeerScore?.intraRawRank
              ? AggregatePeerScore?.intraRawRank
              : "?"}
          </h3>
        </div>
      </div>
    </div>
  );
}

export function NonRateablePeerRatedScoreTable() {
  return (
    <div className={styles.main}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h1>Peer-rated score</h1>
          <PeerAssessmentIcon />
        </div>
        <div className={styles.peerGhostBox}>
          <p className={styles.peerGhostBoxPara}>
            This trait cannot be assessed by your connections.
          </p>
        </div>
      </div>
    </div>
  );
}
