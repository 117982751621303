import CheckMark from "@/components/shared/CheckMark";
import styles from "./Rewards.module.css";

export default function RewardsCheckmark(props: { checked: boolean }) {
  if (props.checked) {
    return (
      <div className={styles.checkmarDiv}>
        <CheckMark color="#fffff2" />
      </div>
    );
  }

  return <div className={styles.checkmarDiv}></div>;
}
