import { useEffect, useState } from "react";

import styles from "./ScoreRow.module.css";

import UknownQuestionMarkIcon from "@/components/shared/icons/UknownQuestionMarkIcon";
import DownArrowIcon from "@/components/shared/icons/DownArrowIcon";
import UpArrowIcon from "@/components/shared/icons/UpArrowIcon";

export default function ScoreRowSnippet(props: {
  score: number | null | undefined;
  peerRated?: boolean;
}) {
  const [scoreText, setScoreText] = useState<string>("");

  useEffect(() => {
    if (props.score !== null && props.score !== undefined) {
      let score = props.score;
      if (score <= 12) {
        setScoreText("Very low");
      } else if (score > 12 && score <= 29) {
        setScoreText("Low");
      } else if (score > 29 && score <= 69) {
        setScoreText("Average");
      } else if (score > 69 && score <= 88) {
        setScoreText("High");
      } else {
        setScoreText("Very high");
      }
    }
  }, [props.score]);

  return (
    <div className={styles.scoreBarDivParentSnippet}>
      {props.score !== null && props.score !== undefined ? (
        <div className={styles.scoreWrapperSnippet}>
          <div
            style={{ marginLeft: "4" }}
            className={
              props.peerRated
                ? styles.selectedSnippetPeer
                : styles.selectedSnippet
            }
          ></div>
          <div
            className={
              props.score && props.score > 12
                ? props.peerRated
                  ? styles.selectedSnippetPeer
                  : styles.selectedSnippet
                : styles.unselectedSnippet
            }
          ></div>
          <div
            className={
              props.score && props.score > 30
                ? props.peerRated
                  ? styles.selectedSnippetPeer
                  : styles.selectedSnippet
                : styles.unselectedSnippet
            }
          ></div>
          <div
            className={
              props.score && props.score > 70
                ? props.peerRated
                  ? styles.selectedSnippetPeer
                  : styles.selectedSnippet
                : styles.unselectedSnippet
            }
          ></div>
          <div
            className={
              props.score && props.score > 88
                ? props.peerRated
                  ? styles.selectedSnippetPeer
                  : styles.selectedSnippet
                : styles.unselectedSnippet
            }
          ></div>
        </div>
      ) : (
        <div className={styles.scoreWrapperSnippet}>
          <div
            style={{ marginLeft: "4" }}
            className={styles.unselectedSnippet}
          ></div>
          <div className={styles.unselectedSnippet}></div>
          <div className={styles.unselectedSnippet}></div>
          <div className={styles.unselectedSnippet}></div>
          <div className={styles.unselectedSnippet}></div>
          <div className={styles.restrictIcon}>
            <UknownQuestionMarkIcon />
          </div>
        </div>
      )}
      <div className={styles.scoreTextDivSnippet}>
        <p className={styles.scoreTextNonReport}>{scoreText}</p>
        <div
          style={scoreText === "Average" ? { top: "0" } : {}}
          className={styles.iconDiv}
        >
          {scoreText === "Very low" && (
            <>
              <div className={styles.arrow}>
                <DownArrowIcon />
              </div>
              <div className={styles.arrow}>
                <DownArrowIcon />
              </div>
            </>
          )}
          {scoreText === "Low" && (
            <div className={styles.arrow}>
              <DownArrowIcon />
            </div>
          )}
          {scoreText === "Average" && (
            <div className={styles.neutralNonReport}></div>
          )}
          {scoreText === "High" && (
            <div className={styles.arrow}>
              <UpArrowIcon />
            </div>
          )}
          {scoreText === "Very high" && (
            <>
              <div className={styles.arrow}>
                <UpArrowIcon />
              </div>
              <div className={styles.arrow}>
                <UpArrowIcon />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
