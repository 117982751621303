export default function DeepestThemesSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9295 3.82032C24.5975 3.8379 24.2869 3.98829 24.0662 4.23438C23.8435 4.48243 23.7303 4.80858 23.7498 5.14063L24.574 20.1796C24.574 20.414 24.7654 20.6054 24.9998 20.6054C25.2342 20.6054 25.4256 20.414 25.4256 20.1796L26.2498 5.14063C26.2517 5.09571 26.2517 5.05079 26.2498 5.00587C26.2087 4.31447 25.6209 3.78517 24.9295 3.82032Z"
        fill="#FFFFF2"
      />
      <path
        d="M16.1545 7.176L16.0842 7.05686L16.0861 7.05491C15.6877 6.52951 14.9514 6.40061 14.4006 6.76194C13.8498 7.12522 13.674 7.85179 14.0002 8.42599L22.2247 21.0395C22.3419 21.2211 22.5782 21.2836 22.7696 21.186C22.9805 21.0805 23.0645 20.8246 22.961 20.6157L16.1545 7.176Z"
        fill="#FFFFF2"
      />
      <path
        d="M7.176 16.1545L20.6155 22.961C20.8167 23.0646 21.0647 22.9923 21.178 22.795C21.2913 22.5997 21.2307 22.3478 21.0393 22.2247L8.42583 14.0002C8.38677 13.9729 8.34771 13.9494 8.30474 13.9299C7.68754 13.6174 6.93364 13.8655 6.62309 14.4827C6.31059 15.0999 6.55863 15.8538 7.17584 16.1643L7.176 16.1545Z"
        fill="#FFFFF2"
      />
      <path
        d="M20.586 25.0255C20.5938 24.7911 20.4141 24.5919 20.1798 24.5744L5.14075 23.7502H5.00012C4.31067 23.7873 3.77942 24.3771 3.81847 25.0685C3.85558 25.758 4.44542 26.2873 5.13487 26.2502L20.1739 25.426H20.1758C20.3926 25.4142 20.5684 25.2423 20.586 25.0255Z"
        fill="#9D40FF"
      />
      <path
        d="M21.1855 27.2305C21.08 27.0196 20.8242 26.9356 20.6152 27.0391L7.1757 33.8456L7.05656 33.9159L7.05461 33.9139C6.52921 34.3124 6.40031 35.0487 6.76164 35.5995C7.12492 36.1503 7.85149 36.326 8.42569 35.9999L21.0392 27.7754C21.2208 27.6582 21.2832 27.4219 21.1855 27.2305Z"
        fill="#ED85C2"
      />
      <path
        d="M22.8105 28.8361C22.6132 28.7091 22.3535 28.7658 22.2245 28.9611L14 41.5746C13.9727 41.6136 13.9493 41.6527 13.9297 41.6957C13.6172 42.3129 13.8653 43.0668 14.4825 43.3773C15.0997 43.6898 15.8536 43.4418 16.1641 42.8246L22.9611 29.3851C23.0568 29.1897 22.9921 28.9533 22.8105 28.8361Z"
        fill="#F3431C"
      />
      <path
        d="M25.0255 29.414C24.7911 29.4062 24.5919 29.5859 24.5743 29.8203L23.7501 44.8593C23.7482 44.9042 23.7482 44.9491 23.7501 44.994C23.7872 45.6854 24.3771 46.2147 25.0685 46.1776C25.7579 46.1405 26.2872 45.5507 26.2501 44.8593L25.4259 29.8203C25.4103 29.6035 25.2403 29.4316 25.0255 29.414Z"
        fill="#E0CC65"
      />
      <path
        d="M27.7755 28.961C27.6583 28.7794 27.422 28.7169 27.2306 28.8145C27.0197 28.92 26.9357 29.1758 27.0392 29.3848L33.8457 42.8243L33.916 42.9435L33.914 42.9454C34.3125 43.4708 35.0488 43.5997 35.5996 43.2384C36.1504 42.8751 36.3262 42.1485 36 41.5743L27.7755 28.961Z"
        fill="#699F5B"
      />
      <path
        d="M42.824 33.8455L29.3845 27.039C29.1833 26.9355 28.9353 27.0077 28.822 27.205C28.7087 27.4003 28.7693 27.6523 28.9607 27.7753L41.5742 35.9998C41.6132 36.0272 41.6523 36.0506 41.6953 36.0701C42.3125 36.3826 43.0664 36.1346 43.3769 35.5174C43.6894 34.9002 43.4414 34.1463 42.8242 33.8357L42.824 33.8455Z"
        fill="#68CEC2"
      />
      <path
        d="M44.8595 23.75L29.8205 24.5742C29.5861 24.5742 29.3947 24.7657 29.3947 25C29.3947 25.2344 29.5861 25.4258 29.8205 25.4258L44.8595 26.25H45.0001C45.6896 26.2129 46.2208 25.6231 46.1818 24.9317C46.1447 24.2422 45.5548 23.7129 44.8654 23.75L44.8595 23.75Z"
        fill="#5FA9DF"
      />
      <path
        d="M28.8145 22.7695C28.92 22.9805 29.1758 23.0644 29.3848 22.9609L42.8243 16.1544L42.9434 16.0841L42.9454 16.0861C43.4708 15.6876 43.5997 14.9513 43.2384 14.4005C42.8751 13.8497 42.1485 13.674 41.5743 14.0001L28.9608 22.2246C28.7792 22.3418 28.7168 22.5781 28.8145 22.7695Z"
        fill="#FFFFF2"
      />
      <path
        d="M27.1895 21.164C27.3868 21.291 27.6465 21.2343 27.7754 21.039L36 8.42554C36.0273 8.38647 36.0507 8.34741 36.0703 8.30444C36.3828 7.68724 36.1347 6.93334 35.5175 6.62279C34.9003 6.31029 34.1464 6.55834 33.8359 7.17554L27.0389 20.615C26.9432 20.8104 27.0079 21.0468 27.1895 21.164Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
