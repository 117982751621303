import { useEffect } from "react";
import { useSelector } from "react-redux";

import Loader from "../Loader";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import { useModalContext } from "@/context/ModalContext";
import PeriodicQuizResultModal from "@/components/appHome/PeriodicQuizzes/PeriodicQuizResultModal/PeriodicQuizResultModal";
import PeriodicQuizModal from "@/components/appHome/PeriodicQuizzes/PeriodicQuizModal/PeriodicQuizModal";
import DailyStoriesModal from "@/components/appHome/DailyStories/DailyStoriesModal";
import DailyInsightsModal from "@/components/appHome/DailyInsights/DailyInsightsModal/DailyInsightsModal";

export default function LoadingModal(props: { elementName: "LoadingModal" }) {
  return (
    <ModalWrapper noLine title="">
      <Loader mobileHeight="500px" height="500px" />
    </ModalWrapper>
  );
}

type Props = {
  elementName: "NotificationLoadingModal";
  notificationType:
    | "viewPeriodicQuiz"
    | "periodicQuizResultShared"
    | "viewDeliveredDailyInsight"
    | "viewDeliveredDailyStoryNew";
};

export function NotificationLoadingModal(props: Props) {
  const { setModalComponent, removeModalByName } = useModalContext();

  const userState = useSelector((state: RootState) => state.user);
  const connectionState = useSelector((state: RootState) => state.connections);
  const quizState = useSelector((state: RootState) => state.periodicQuiz);
  const dailyInsightState = useSelector(
    (state: RootState) => state.dailyInsight
  );
  const dailyStoryState = useSelector((state: RootState) => state.dailyStory);

  useEffect(() => {
    if (
      props.notificationType === "periodicQuizResultShared" ||
      props.notificationType === "viewPeriodicQuiz"
    ) {
      const foundQuizResult = quizState.periodicQuizResults?.find(
        (q) => q.periodicQuizSlug === quizState.availableQuiz?.slug
      );
      if (quizState.availableQuiz) {
        if (foundQuizResult) {
          setModalComponent(
            <PeriodicQuizResultModal
              periodicQuiz={quizState.availableQuiz}
              periodicQuizResult={foundQuizResult}
              elementName="PeriodicQuizResultModal"
            />
          );
          removeModalByName("NotificationLoadingModal");
        } else {
          setModalComponent(
            <PeriodicQuizModal elementName="PeriodicQuizModal" />
          );
          removeModalByName("NotificationLoadingModal");
        }
      }
    }
  }, [props.notificationType, quizState.availableQuiz]);

  useEffect(() => {
    if (
      props.notificationType === "viewDeliveredDailyStoryNew" &&
      dailyStoryState.dailyStory &&
      dailyStoryState.dailyStory?.assignmentRuleset?.body &&
      dailyStoryState.dailyStory?.name
    ) {
      setModalComponent(<DailyStoriesModal elementName="DailyStory" />);
      removeModalByName("NotificationLoadingModal");
    }
  }, [
    props.notificationType,
    dailyStoryState.dailyStory,
    dailyStoryState.dailyStory?.assignmentRuleset?.body,
    dailyStoryState.dailyStory?.name,
  ]);

  useEffect(() => {
    if (
      props.notificationType === "viewDeliveredDailyInsight" &&
      dailyInsightState.deliveredDailyInsight
    ) {
      setModalComponent(
        <DailyInsightsModal elementName="DailyInsightsModal" />
      );
      removeModalByName("NotificationLoadingModal");
    }
  }, [
    props.notificationType,
    dailyInsightState.deliveredDailyInsight,
    dailyInsightState.insightDelivery,
  ]);

  return (
    <ModalWrapper noLine title="">
      <Loader mobileHeight="500px" height="500px" />
    </ModalWrapper>
  );
}
