export default function ObstaclesToLoveSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.8874 19.0413C48.2912 17.9576 44.5537 14.3288 40.2211 19.5826C35.6711 14.3288 32.1499 17.9576 31.5547 19.0413C30.4711 21.0451 31.1211 24.0788 32.6384 25.5413L40.2221 33.125L47.8058 25.5413C49.3211 24.0788 49.971 21.0462 48.8874 19.0413Z"
        fill="#ED85C2"
      />
      <rect x="23.2216" y="7" width="4" height="35" rx="2" fill="#FFFFF2" />
      <path
        d="M17.9982 18.4884C17.0666 17.5569 15.8274 17.0438 14.5101 17.0438C13.1927 17.0438 11.9535 17.5569 11.022 18.4884L10.2211 19.2894L9.42101 18.4893C8.48944 17.5577 7.25022 17.0447 5.93291 17.0447C4.61548 17.0447 3.37629 17.5577 2.44547 18.4893C1.5139 19.4209 1 20.6601 1 21.9774C1 23.2947 1.51304 24.534 2.44547 25.4655L9.73561 32.7548C9.86967 32.8888 10.0459 32.9558 10.222 32.9558C10.3982 32.9558 10.5735 32.8888 10.7084 32.7548L17.9994 25.4646C19.9219 23.5405 19.9219 20.4114 17.9986 18.4882L17.9982 18.4884ZM17.0263 24.492L10.2217 31.2966L3.41705 24.492C2.74502 23.82 2.37463 22.9262 2.37463 21.9757C2.37463 21.0252 2.74502 20.1314 3.41705 19.4593C4.08909 18.7873 4.98284 18.4177 5.93253 18.4177C6.883 18.4177 7.77675 18.7881 8.44889 19.4593L9.73539 20.7458C10.0035 21.0139 10.4392 21.0139 10.7082 20.7458L11.9947 19.4593C12.6667 18.7873 13.5605 18.4169 14.5111 18.4169C15.4615 18.4169 16.3553 18.7873 17.0274 19.4593C18.4136 20.8472 18.4136 23.1047 17.0266 24.4918L17.0263 24.492Z"
        fill="#928E8C"
      />
    </svg>
  );
}
