import {
  DiscoveredCompatibilitySnippet,
  LlmContent,
} from "@dimensional-engineering/dimensional-models";

import styles from "../CompatibilitySnippets.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";
import { natureData } from "@/assets/traitInfo/natureData";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};
export default function CompatibilityLlmTitleAndContentView(props: Props) {
  return (
    <div style={{ marginTop: "35px" }} className={styles.spectrumsParent}>
      <LlmTitleAndContentMapper
        data={props.snippet?.snippetDetail.llmTitlesAndContent}
      />
    </div>
  );
}

export function LlmTitleAndContentMapper(props: {
  data: LlmContent[] | null | undefined;
}) {
  const mapped = props.data?.map((c, index) => {
    return (
      <div style={index !== 0 ? { marginTop: "15px" } : {}} key={index}>
        <h3
          style={index === 0 ? { marginTop: "20px" } : {}}
          className={styles.llmTitle}
        >
          {c.title}
        </h3>
        {c.paragraph && <p className={styles.llmParagraph}>{c.paragraph}</p>}
        {c.paragraphs &&
          c.paragraphs.map((p, i) => {
            return (
              <p className={styles.llmParagraph} key={i}>
                {p}
              </p>
            );
          })}
        {c.orderedList && (
          <ol className={styles.llmOl}>
            {c.orderedList.map((li, i) => {
              return (
                <div className={styles.liDiv} key={i}>
                  <span className={styles.olNumber}>{i + 1}</span>
                  <li>{li}</li>
                </div>
              );
            })}
          </ol>
        )}
        {c.unorderedNatureStatements && (
          <ul className={styles.natureStatementUl}>
            {c.unorderedNatureStatements.map((statement, index) => {
              const color = natureData?.find(
                (n) => n.slug === statement.nature
              )?.color;
              return (
                <li
                  className={styles.natureStatement}
                  style={color ? { color: color } : { color: "#928e8c" }}
                  key={index}
                >
                  {statement.body}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  });

  return <div>{mapped}</div>;
}
