import { ReactNode, useEffect, useState, createContext } from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import NotificationViewModal from "@/components/appHome/NotificationViewModal/NotificationViewModal";
import RewardsModal from "@/components/appHome/rewards/RewardsModal";
import ConversionModal from "@/components/premium/ConversionModal/ConversionModal";
import GeneralModal from "@/components/shared/GeneralModal/GeneralModal";
import { useModalContext } from "@/context/ModalContext";
import { RootState } from "@/redux/store";
import DailyStoriesModal from "@/components/appHome/DailyStories/DailyStoriesModal";
import { Mixpanel } from "@/helpers/mixpanel";
import PeerFeedbackModal from "@/components/appHome/PeerFeedBackModule/PeerFeedbackModal/PeerFeedbackModal";
import {
  ExtendedClientNotificationData,
  ExtendedClientNotificationType,
} from "@/models/sharedModels";
import PeriodicQuizResultModal from "@/components/appHome/PeriodicQuizzes/PeriodicQuizResultModal/PeriodicQuizResultModal";
import FeatureCarouselModal from "@/components/FeatureCarouselModal/FeatureCarouselModal";
import PeriodicQuizModal from "@/components/appHome/PeriodicQuizzes/PeriodicQuizModal/PeriodicQuizModal";
import DailyInsightsModal from "@/components/appHome/DailyInsights/DailyInsightsModal/DailyInsightsModal";
import { getListOfTraitRulesForMixpanel } from "@/components/appHome/DailyInsights/utils";
import { NotificationLoadingModal } from "@/components/shared/LoadingModal/LoadingModal";
import ExpirationModal from "@/components/shared/GeneralModal/ExpirationModal";

interface Props {
  children?: ReactNode;
}

export const NotificationContext = createContext<{}>({});

export const NotificationClickHandlersProvider = ({
  children,
}: Props): JSX.Element => {
  const router = useRouter();
  const [notificationData, setNotificationData] = useState<any | null>(null);

  const { removeModalByName, setModalComponent, modalComponent } =
    useModalContext();

  const userState = useSelector((state: RootState) => state.user);
  const connectionState = useSelector((state: RootState) => state.connections);
  const quizState = useSelector((state: RootState) => state.periodicQuiz);
  const dailyInsightState = useSelector(
    (state: RootState) => state.dailyInsight
  );

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function clearNotificationData() {
    localStorage.removeItem("notificationType");
    setNotificationData(null);
  }

  useEffect(() => {
    const checkLocalStorage = () => {
      const LSNotificationType = localStorage.getItem("notificationType");
      if (LSNotificationType) {
        const data = JSON.parse(LSNotificationType);
        if (notificationData === null) {
          setNotificationData(data);
        }
        localStorage.removeItem("notificationType");
      }
    };

    const intervalId = setInterval(checkLocalStorage, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (notificationData) {
      const {
        notificationType,
        snippetSlug,
        peerUid,
        genericModalTitle,
        genericModalBody,
        storySlug,
        linkUrl,
        compatibilitySnippetSlug,
        reportSlug,
        participantUids,
        commentId,
        contentSlug,
      } = notificationData as ExtendedClientNotificationData;

      switch (notificationType as ExtendedClientNotificationType) {
        case "viewSnippet":
          if (snippetSlug) {
            localStorage.setItem("snippetSlug", snippetSlug);
          }
          removeModalByName("LoadingModal");
          clearNotificationData();
          router.push("/app/results");
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewProfile":
          if (peerUid && peerUid !== "null") {
            router.push(`/app/profile/${peerUid}`);
          }
          clearNotificationData();
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewGenericModal":
          if (genericModalTitle && genericModalBody) {
            setModalComponent(
              <GeneralModal title={genericModalTitle} body={genericModalBody} />
            );
            clearNotificationData();
          }
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewPremiumConversionModal":
          setModalComponent(<ConversionModal />);
          clearNotificationData();
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewCompatibilitySnippet":
          if (peerUid && compatibilitySnippetSlug) {
            console.log("Have peeruid and snippetslug");
            const profile = connectionState.publicProfiles?.find(
              (p) => p.ownerUUID === peerUid
            );
            if (profile) {
              localStorage.setItem(
                "copatibilitySnippetSlug",
                compatibilitySnippetSlug
              );
              router.push(`/app/profile/${peerUid}`);
              clearNotificationData();
            }
          }
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewRewards":
          router.push("/app/home");
          setModalComponent(<RewardsModal />);
          clearNotificationData();
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewReport":
          if (reportSlug) {
            router.push(`/app/results`);

            if (userState.memberProfile?.shareHash) {
              openInNewTab(
                `https://www.dimensional.me/reports/${reportSlug}/${userState.memberProfile?.shareHash}`
              );
            }

            clearNotificationData();
          }
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewFeed":
          router.push(`/app/home`);
          setModalComponent(<NotificationViewModal />);
          clearNotificationData();
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "surveyLinkReceived":
          if (linkUrl) {
            openInNewTab(linkUrl);
          }
          clearNotificationData();
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewPeerAssessment":
          if (peerUid) {
            router.push(`/app/peer-assessment/peer-core/${peerUid}`);
            return clearNotificationData();
          }
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          break;

        case "viewPeerFeedbackLevelsModal":
          router.push("/app/home");
          setModalComponent(<PeerFeedbackModal />);
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          return clearNotificationData();

        case "chatMessage":
          if (participantUids) {
            const participantUidsParsed = JSON.parse(
              participantUids
            ) as string[];

            const connectionUid = participantUidsParsed?.find(
              (uid) => uid !== userState.publicProfileData?.ownerUUID
            );

            console.log("participantUidsParsed: ", participantUidsParsed);

            if (connectionUid) {
              router.push(`/app/messaging/${connectionUid}`);
            } else {
              router.push("/app/messaging");
            }
          } else {
            router.push("/app/messaging");
          }
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          return clearNotificationData();

        case "weeklyPublicProfileViews":
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          setModalComponent(<NotificationViewModal />);
          return clearNotificationData();

        case "viewPeriodicQuiz":
          router.push("/app/home");
          setModalComponent(
            <NotificationLoadingModal
              elementName="NotificationLoadingModal"
              notificationType="viewPeriodicQuiz"
            />
          );
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          return clearNotificationData();

        case "periodicQuizResultShared":
          router.push("/app/home");
          setModalComponent(
            <NotificationLoadingModal
              elementName="NotificationLoadingModal"
              notificationType="viewPeriodicQuiz"
            />
          );
          Mixpanel?.track("Notification Opened", {
            type: "connection quiz results shared",
          });
          return clearNotificationData();

        case "viewFeatureCarousel":
          router.push("/app/home");
          setModalComponent(<FeatureCarouselModal />);
          Mixpanel?.track("Notification Opened", {
            type: notificationType,
          });
          return clearNotificationData();

        case "viewDeliveredDailyInsight":
          router.push("/app/home");
          setModalComponent(
            <NotificationLoadingModal
              elementName="NotificationLoadingModal"
              notificationType="viewDeliveredDailyInsight"
            />
          );

          Mixpanel?.track("Notification Opened", {
            type: "viewDeliveredDailyInsight",
            body: dailyInsightState.deliveredDailyInsight?.assignmentRuleset?.body.slice(
              0,
              250
            ),
            trait_rules: getListOfTraitRulesForMixpanel(
              dailyInsightState.deliveredDailyInsight
            ),
            is_novel_ruleset:
              dailyInsightState.deliveredDailyInsight?.assignmentRuleset ??
              false,
          });
          return clearNotificationData();

        case "viewDeliveredDailyStoryNew":
          router.push("/app/home");
          setModalComponent(
            <NotificationLoadingModal
              elementName="NotificationLoadingModal"
              notificationType="viewDeliveredDailyStoryNew"
            />
          );
          Mixpanel?.track("Notification Opened", {
            type: "viewDeliveredDailyStoryNew",
          });
          return clearNotificationData();

        case "commentLiked":
          if (contentSlug) {
            if (
              dailyInsightState.insightDelivery?.id === contentSlug &&
              commentId
            ) {
              Mixpanel?.track("Comment modal summoned", {
                source: "daily insight",
                audience_group_size:
                  dailyInsightState.commentThread?.audienceUids?.length ?? 0,
                type: "from notification or feed",
              });
              setModalComponent(
                <DailyInsightsModal
                  elementName="DailyInsightsModal"
                  showComment
                  commentId={commentId}
                />
              );
              Mixpanel?.track("Notification Opened", {
                type: "commentLiked",
                comment_type: "root comment",
                content_type: "daily insight",
              });
              return clearNotificationData();
            }
            if (dailyInsightState.insightDelivery?.id !== contentSlug) {
              setModalComponent(
                <ExpirationModal copy="You're too late! This content and associated comments have expired." />
              );
              return clearNotificationData();
            }
          }

        case "commentReplied":
          if (contentSlug) {
            if (
              dailyInsightState.insightDelivery?.id === contentSlug &&
              commentId
            ) {
              setModalComponent(
                <DailyInsightsModal
                  elementName="DailyInsightsModal"
                  showComment
                  commentId={commentId}
                />
              );
              Mixpanel?.track("Comment modal summoned", {
                source: "daily insight",
                audience_group_size:
                  dailyInsightState.commentThread?.audienceUids?.length ?? 0,
                type: "from notification or feed",
              });

              Mixpanel?.track("Notification Opened", {
                type: "commentReplied",
                comment_type: "root comment",
                content_type: "daily insight",
              });
              return clearNotificationData();
            }
            if (dailyInsightState.insightDelivery?.id !== contentSlug) {
              setModalComponent(
                <ExpirationModal copy="You're too late! This content and associated comments have expired." />
              );
              return clearNotificationData();
            }
          }

        case "firstComment":
          if (contentSlug) {
            if (
              dailyInsightState.insightDelivery?.id === contentSlug &&
              commentId
            ) {
              Mixpanel?.track("Comment modal summoned", {
                source: "daily insight",
                audience_group_size:
                  dailyInsightState.commentThread?.audienceUids?.length ?? 0,
                type: "from notification or feed",
              });
              setModalComponent(
                <DailyInsightsModal
                  elementName="DailyInsightsModal"
                  showComment
                  commentId={commentId}
                />
              );
              Mixpanel?.track("Notification Opened", {
                type: "firstComment",
                comment_type: "root comment",
                content_type: "daily insight",
              });
              return clearNotificationData();
            }
            if (dailyInsightState.insightDelivery?.id !== contentSlug) {
              setModalComponent(
                <ExpirationModal copy="You're too late! This content and associated comments have expired." />
              );
              return clearNotificationData();
            }
          }

        default:
          console.warn(`Unhandled notification type: ${notificationType}`);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);

  return (
    <NotificationContext.Provider value={{}}>
      {children}
    </NotificationContext.Provider>
  );
};
