/* eslint-disable @next/next/no-img-element */
import Link from "next/link";

import styles from "./otherArcehtypes.module.css";

import Archetype from "@/components/traits/arcehtype/Archetypes/Archetype";
import { ExtendedArchetype } from "@/models/sharedModels";
import TraitsHeading from "../../Heading/TraitsHeading";
import TraitsWrapper from "../../Wrapper/Wrapper";
import { useModalContext } from "@/context/ModalContext";
import ArchetypeModal from "@/components/traits/TraitsModals/Archetype/ArchetypeModal";
import { archetypeMapper } from "@/assets/traitInfo/dimensionData";

type Props = {
  archetypes: ExtendedArchetype[];
  authenticated?: boolean;
  uid?: string;
};

export default function OtherArchetypes(props: Props) {
  const { setModalComponent } = useModalContext();

  if (props.authenticated) {
    return (
      <TraitsWrapper>
        <TraitsHeading
          title={`Other Archetypes in ${
            archetypeMapper[props.archetypes[0].dimensionSlug]
          }`}
        />
        <div className={styles.main}>
          <div className={styles.wrapper}>
            {props.archetypes.map((arch) => {
              return (
                <div
                  onClick={() =>
                    setModalComponent(
                      <ArchetypeModal slug={arch.slug} uid={props.uid} />
                    )
                  }
                  style={{ cursor: "pointer" }}
                  key={arch.slug}
                  className="elBodyNoMarginMobile"
                >
                  <Archetype archetype={arch} />
                </div>
              );
            })}
          </div>
        </div>
      </TraitsWrapper>
    );
  }

  return (
    <TraitsWrapper>
      <TraitsHeading
        title={`Other Archetypes in ${
          archetypeMapper[props.archetypes[0].dimensionSlug]
        }`}
      />
      <div className={styles.main}>
        <div className={styles.wrapper}>
          {props.archetypes.map((arch) => {
            return (
              <Link
                href={`/traits/archetypes/${arch.slug}`}
                key={arch.slug}
                className="elBodyNoMarginMobile"
              >
                <Archetype archetype={arch} />
              </Link>
            );
          })}
        </div>
      </div>
    </TraitsWrapper>
  );
}
