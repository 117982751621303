import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useSelector } from "react-redux";

import { db } from "@/_firebase/firebaseConfig";
import { RootState } from "@/redux/store";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

export default function useGetPublicProfile(uid: string | null | undefined) {
  const [publicProfile, setPublicProfile] = useState<PublicProfile | null>(
    null
  );
  const friendUidList = useSelector(
    (state: RootState) => state.user.friendUidList
  );
  const connections = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );

  useEffect(() => {
    if (uid && friendUidList.includes(uid)) {
      connections?.forEach((prof) => {
        if (prof.ownerUUID === uid) {
          setPublicProfile(prof);
        }
      });
    } else {
      if (uid) {
        const publicProfileRef = doc(db, "publicProfiles", uid as string);
        getDoc(publicProfileRef).then((res) => {
          if (res.exists()) {
            setPublicProfile(res.data() as PublicProfile);
          }
        });
      }
    }
  }, [connections, friendUidList, uid]);

  return publicProfile;
}
