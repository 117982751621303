import { ReactNode, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import { useAuth } from "@/context/AuthContext";
import { db } from "@/_firebase/firebaseConfig";
import { RootState } from "@/redux/store";
import {
  fetchPeerAssessmentLink,
  findPublicProfileFromSlug,
  getPeerAssessmentLinkData,
} from "../peerAssessments/utils";
import useIsMobile from "@/helpers/useIsMobile";
import LoadingScreen from "../assessments-new/views/AsessmentOutroScreen/loadingScreen/LoadingScreen";
import { useAlertContext } from "@/context/AlertContext";
import Alert from "./Alerts/Alert";
import { useModalContext } from "@/context/ModalContext";

type ProtectedRouteProps = {
  children: ReactNode;
  noCreateProfile?: boolean;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { user } = useAuth();
  const router = useRouter();
  const { setAlertComponent } = useAlertContext();

  useHandleUserBackPress();

  //if no user, push to home/login page
  //if no username/profile, push to onboarding
  //no create profile props stops infite loops

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        const data = JSON.parse(event.data);

        console.log("Message from native layer:", event.data);

        if (data.peerAssessmentLinkId && data.peerAssessmentLinkId !== "null") {
          if (window.localStorage) {
            localStorage.setItem(
              "peerAssessmentLinkId",
              data.peerAssessmentLinkId
            );
            if (window.Android?.clearPeerAssessmentLink) {
              window.Android?.clearPeerAssessmentLink();
            }
            getPeerAssessmentLinkData(data.peerAssessmentLinkId).then((res) => {
              if (res) {
                if (res.assesseeUid === user?.uid) {
                  setAlertComponent(
                    <Alert
                      elementName="Alert"
                      message="You cannot complete your own peer assessment"
                      type="fail"
                    />,
                    7000
                  );
                  localStorage.removeItem("peerAssessmentLinkId");
                  if (window.Android?.clearPeerAssessmentLink) {
                    window.Android?.clearPeerAssessmentLink();
                  }
                  return router.push("/app/home");
                }
                if (res.linkStatus === "claimed") {
                  setAlertComponent(
                    <Alert
                      elementName="Alert"
                      message="Peer assessment link has expired."
                      type="fail"
                    />,
                    7000
                  );
                  localStorage.removeItem("peerAssessmentLinkId");
                  if (window.Android?.clearPeerAssessmentLink) {
                    window.Android?.clearPeerAssessmentLink();
                  }
                  return router.push("/app/home");
                }
                router.push(
                  `/app/peer-assessment/peer-core/${res.assesseeUid}`
                );
              }
            });
          }
        }
      } catch (error: any) {
        // console.log("Error handling message:", error);
        if (
          error.message.includes(
            "Failed to execute 'postMessage' on 'DOMWindow'"
          )
        ) {
          console.log("Post message failed: Ensure target origin matches.");
          console.log("Failed message data:", event.data);
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user === null) {
      router.push("/app");
    } else {
      const checkForProfileCompletion = async (id: string) => {
        ///check for public profile
        const publicProfileRef = doc(db, `/publicProfiles/${id}`);
        const publicSnap = await getDoc(publicProfileRef);
        if (publicSnap.exists() && publicSnap.data()) {
        } else {
          !props.noCreateProfile ? router.push("/app/onboarding") : null;
        }
      };

      if (user?.uid) checkForProfileCompletion(user.uid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid, user]);

  return <>{props.children}</>;
}

export function OnboardingProtectedRoute(props: ProtectedRouteProps) {
  const { user } = useAuth();
  const router = useRouter();
  const isMobile = useIsMobile();

  //if no user, push to home/login page
  //if username/profile, push to assessment root

  useHandleUserBackPress();

  useEffect(() => {
    if (user === null) {
      router.push("/app");
    } else {
      const checkForProfileCompletion = async (id: string) => {
        const publicProfileRef = doc(db, `/publicProfiles/${id}`);
        const publicSnap = await getDoc(publicProfileRef);
        if (publicSnap.exists() && publicSnap.data()) {
          router.push("/app/home");
        }
      };

      if (user?.uid) {
        checkForProfileCompletion(user.uid);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  return <>{props.children}</>;
}

type PeerAssessmentProps = {
  children: ReactNode;
};

export function InAppPeerAssessmentProtectedRoute(props: PeerAssessmentProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const router = useRouter();
  const { slug } = router.query;
  const connections = useSelector(
    (state: RootState) => state.connections.publicProfiles
  );
  const connectionUid = slug as string;

  const { setAlertComponent } = useAlertContext();

  const peerAssessmentLS = localStorage.getItem("peerAssessmentLinkId");

  useHandleUserBackPress();

  useEffect(() => {
    if (user === null) {
      router.push("/app/home");
    } else {
      if (connectionUid) {
        findPublicProfileFromSlug(connectionUid, connections)
          .then((res) => {})
          .catch(() => {
            if (peerAssessmentLS) {
              setLoading(true);
              fetchPeerAssessmentLink(peerAssessmentLS)
                .then(async (res) => {
                  if (res) {
                    setLoading(false);
                    localStorage.removeItem("peerAssessmentLinkId");
                    if (res.assesseeUid === user?.uid) {
                      setAlertComponent(
                        <Alert
                          elementName="Alert"
                          message="You cannot complete your own peer assessment"
                          type="fail"
                        />,
                        7000
                      );
                      localStorage.removeItem("peerAssessmentLinkId");
                      if (window.Android?.clearPeerAssessmentLink) {
                        window.Android?.clearPeerAssessmentLink();
                      }
                      return router.push("/app/home");
                    }
                    if (res.linkStatus === "claimed") {
                      setAlertComponent(
                        <Alert
                          elementName="Alert"
                          message="Peer assessment link has expired."
                          type="fail"
                        />,
                        7000
                      );
                      localStorage.removeItem("peerAssessmentLinkId");
                      if (window.Android?.clearPeerAssessmentLink) {
                        window.Android?.clearPeerAssessmentLink();
                      }
                      return router.push("/app/home");
                    }
                    if (res.assesseeUid) {
                      return router.push(
                        `/app/peer-assessment/peer-core/${res.assesseeUid}`
                      );
                    }
                  } else {
                    setLoading(false);
                    router.push("/app/home");
                  }
                })
                .catch((err) => {
                  setAlertComponent(
                    <Alert
                      elementName="Alert"
                      message="Peer assessment link has expired."
                      type="fail"
                    />,
                    7000
                  );
                  localStorage.removeItem("peerAssessmentLinkId");
                  setLoading(false);
                  router.push("/app/home");
                });
            } else {
              setLoading(false);
              router.push("/app/home");
            }
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections]);

  if (loading) {
    return <LoadingScreen message="" />;
  }

  return <>{props.children}</>;
}

export function InAppPeerAssessmentOnBoardingProtectedRoute(
  props: PeerAssessmentProps
) {
  const { user } = useAuth();
  const router = useRouter();
  const { setAlertComponent } = useAlertContext();
  const peerAssessmentLS = localStorage.getItem("peerAssessmentLinkId");

  useHandleUserBackPress();

  useEffect(() => {
    if (user === null) {
      router.push("/app/home");
    } else {
      if (peerAssessmentLS) {
        fetchPeerAssessmentLink(peerAssessmentLS)
          .then(async (res) => {
            if (res) {
              localStorage.removeItem("peerAssessmentLinkId");
              if (res.assesseeUid === user?.uid) {
                setAlertComponent(
                  <Alert
                    elementName="Alert"
                    message="You cannot complete your own peer assessment"
                    type="fail"
                  />,
                  7000
                );
                if (window.Android?.clearPeerAssessmentLink) {
                  window.Android?.clearPeerAssessmentLink();
                }
                return router.push("/app/onboarding");
              }
              if (res.linkStatus === "claimed") {
                setAlertComponent(
                  <Alert
                    elementName="Alert"
                    message="Peer assessment link has expired."
                    type="warning"
                  />,
                  7000
                );
                if (window.Android?.clearPeerAssessmentLink) {
                  window.Android?.clearPeerAssessmentLink();
                }
                return router.push("/app/onboarding");
              }
              router.push(
                `/app/onboarding/peer-assessment/peer-core/${res.assesseeUid}`
              );
            } else {
              localStorage.removeItem("peerAssessmentLinkId");
              router.push("/app/onboarding");
            }
          })
          .catch((err) => {
            setAlertComponent(
              <Alert
                elementName="Alert"
                message="Peer assessment link has expired."
                type="fail"
              />,
              7000
            );
            localStorage.removeItem("peerAssessmentLinkId");
            router.push("/app/onboarding");
          });
      } else {
        router.push("/app/onboarding");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
}

function useHandleUserBackPress() {
  const { modalComponent, setModalOpen } = useModalContext();
  const router = useRouter();

  useEffect(() => {
    let isModalClosed = false; // Flag to track if modal was just closed

    const handleBackNavigation = () => {
      if (modalComponent.length && window.Android) {
        setModalOpen(false); // Close the modal
        isModalClosed = true; // Set flag to avoid further popstate handling
        return false; // Prevent history navigation
      }
      return true; // Allow going back if no modal is open
    };

    const handleRouteChangeStart = () => {
      // Prevent route change if modal is open
      if (modalComponent.length && window.Android) {
        router.events.emit("routeChangeError"); // Prevent route change
        throw "Abort route change";
      }
    };

    const popStateListener = () => {
      if (isModalClosed) {
        isModalClosed = false; // Reset the flag for future navigation
        return;
      }
      handleBackNavigation();
    };

    // Add popstate listener for browser back button
    window.addEventListener("popstate", popStateListener);

    // Add route change listener for Next.js Link component navigations
    router.events.on("routeChangeStart", handleRouteChangeStart);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("popstate", popStateListener);
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalComponent.length]);

  return null;
}
