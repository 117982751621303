import { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import {
  PeriodicQuizResult,
  PeriodicQuiz,
} from "@dimensional-engineering/dimensional-models";

import styles from "./PeriodicQuizResultModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import LogoWithText from "@/components/shared/LogoWithText";
import { useModalContext } from "@/context/ModalContext";
import {
  formatPeriodicQuizPercentileDescriptionTemplate,
  getPeriodicScorePercentileScore,
} from "../utils";
import {
  AverageUserScoreLabel,
  PeriodicQuizHistogramShareMode,
} from "./PeriodicQuizResultModal";

type Props = {
  periodicQuizResult: PeriodicQuizResult;
  periodicQuiz: PeriodicQuiz;
  type: "instagram" | "snapchat";
};

export default function PeriodicQuizResultsShareModal(props: Props) {
  const { setModalOpen } = useModalContext();
  const imageRef = useRef<HTMLDivElement>(null);

  const percentileScore = getPeriodicScorePercentileScore(
    props.periodicQuiz,
    props.periodicQuizResult
  );

  useEffect(() => {
    const handleDownloadImage = async () => {
      try {
        if (imageRef.current) {
          const canvas = await html2canvas(imageRef.current);
          const data = canvas.toDataURL("image/jpg");

          // var a = document.createElement("a");
          // a.href = canvas
          //   .toDataURL("image/jpeg")
          //   .replace("image/jpeg", "image/octet-stream");
          // a.download = "somefilename.jpg";
          // a.click();

          if (window.Android) {
            window.Android.onHaptic();

            if (props.type === "instagram") {
              window.Android.shareToInstagramQuiz(data);
            } else {
              window.Android.shareToSnapChat(data);
            }
          }
        }
      } catch (error) {
        console.error("Error generating image:", error);
      }
    };

    setTimeout(() => {
      handleDownloadImage();
      setModalOpen(false);
    }, 110);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalWrapper noHeader noLine title="">
      <main
        style={{ backgroundColor: "#181716", overflow: "hidden" }}
        ref={imageRef}
        className={styles.main}
      >
        <div className={styles.logoDiv}>
          <LogoWithText />
        </div>
        <section className={styles.scoreBoxSection}>
          <div className={styles.scoreBox}>
            <div className={styles.scoreTextBig}>
              <h2 className={styles.score}>
                <span className={styles.scoreBig}>
                  {(props.periodicQuizResult.percentScore * 100).toFixed(0)}
                </span>
                /100
              </h2>
              {props.periodicQuiz.scoreName && (
                <p className={styles.scoreName}>
                  {props.periodicQuiz.scoreName.toUpperCase()}
                </p>
              )}
            </div>
            <p className={styles.percentileDescriptionTemplate}>
              {formatPeriodicQuizPercentileDescriptionTemplate(
                props.periodicQuiz.percentileDescriptionTemplate,
                percentileScore
              )}
            </p>
            <PeriodicQuizHistogramShareMode
              periodicQuiz={props.periodicQuiz}
              periodicQuizResult={props.periodicQuizResult}
            />
            <AverageUserScoreLabel />
          </div>
        </section>
      </main>
    </ModalWrapper>
  );
}
