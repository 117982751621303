export default function LowerThanAllConnectionsIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="25.7303"
        cy="34.0809"
        r="7.16912"
        transform="rotate(-180 25.7303 34.0809)"
        fill="#ED85C2"
      />
      <circle
        cx="42.9364"
        cy="25.4783"
        r="7.16912"
        transform="rotate(-180 42.9364 25.4783)"
        fill="#E0CC65"
      />
      <circle
        cx="25.7303"
        cy="15.9188"
        r="7.16912"
        transform="rotate(-180 25.7303 15.9188)"
        fill="#5FA9DF"
      />
      <path
        d="M13.1787 14.8042C12.4158 14.0424 11.1804 14.0424 10.4187 14.8042L0.979011 24.2439L0.97779 24.2439C0.216071 25.0068 0.216071 26.2421 0.97779 27.0051L10.4175 36.4448L10.4187 36.4448C11.1877 37.1613 12.3865 37.1406 13.1311 36.3972C13.8745 35.6525 13.8953 34.4538 13.1787 33.6847L5.12215 25.6244L13.1787 17.5641C13.9404 16.8024 13.9404 15.5671 13.1787 14.8042Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
