import styles from "./InvitePill.module.css";

import RewardsIcon from "@/components/shared/icons/RewardsIcon";

type Props = {
  invitesRequired: number;
};

export default function InvitePill(props: Props) {
  return (
    <div className={styles.main}>
      <div className={styles.iconDiv}>
        <RewardsIcon />
      </div>
      <p className={styles.text}>{props.invitesRequired}</p>
    </div>
  );
}
