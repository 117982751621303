export default function FlagsSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M42.719 18.342C42.719 18.342 41.428 21.799 33.0415 20.3244C30.4614 19.8634 28.3403 19.9552 26.729 20.2326H26.6821L28.7563 10.4631L29.4009 7.42209V7.33029C29.4009 7.33029 26.2681 3.45919 17.8794 4.88694C9.49286 6.31469 8.20186 2.90454 8.20186 2.90454L5.71356 14.887C5.71356 14.887 7.00456 18.3441 15.3911 16.8694C21.1061 15.9026 24.3776 17.3772 25.8521 18.3909H25.8989L25.4849 20.4651H25.438C22.6724 21.2483 21.4751 22.7229 21.4751 22.7229L23.1802 31.0179L20.0005 46.5939L21.1978 46.59L23.8697 33.918L26.4498 46.59H27.5552L24.9751 33.8245C26.4497 32.764 29.7212 31.2444 35.5276 32.2581C43.9141 33.6859 45.2051 30.2757 45.2051 30.2757L42.719 18.342Z"
        fill="#FFFFF2"
      />
      <path
        d="M22.0275 28.5725C22.0275 28.5725 19.7697 25.7619 13.6855 26.8205C7.60348 27.8811 6.68148 25.3459 6.68148 25.3459L4.88458 34.0549C4.88458 34.0549 5.80648 36.5433 11.8886 35.4827C15.5761 34.8381 17.8341 35.6214 19.0781 36.3577L17.0507 46.6372H18.3417L22.0275 28.5725Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
