import { useRouter } from "next/router";

type Props = {
  onBack?: () => void;
  step?: boolean;
};

export default function BackButton(props: Props) {
  const router = useRouter();

  function handleBack() {
    if (props.step && props.onBack) {
      props.onBack();
    } else {
      router.back();
    }
  }

  return (
    <svg
      onClick={() => {
        handleBack();
      }}
      width="100%"
      height="100%"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79532 17.4945C8.46367 17.4945 8.14609 17.3632 7.91173 17.1277L0.411731 9.62769C0.177356 9.39331 0.0449219 9.07575 0.0449219 8.7441C0.0449219 8.41245 0.177344 8.09487 0.411731 7.86051L7.91173 0.360512C8.4004 -0.122308 9.1879 -0.119969 9.67306 0.366371C10.1594 0.85153 10.1617 1.63903 9.67892 2.1277L3.06242 8.7442L9.67892 15.3607C10.0363 15.7181 10.143 16.256 9.94962 16.7224C9.75626 17.19 9.30039 17.4947 8.79531 17.4947L8.79532 17.4945Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}

export function SmallBackButton(props: { onClick: () => void }) {
  return (
    <svg
      onClick={() => props.onClick()}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M18.7953 23.4945C18.4637 23.4945 18.1461 23.3632 17.9117 23.1277L10.4117 15.6277C10.1774 15.3933 10.0449 15.0757 10.0449 14.7441C10.0449 14.4124 10.1773 14.0949 10.4117 13.8605L17.9117 6.36051C18.4004 5.87769 19.1879 5.88003 19.6731 6.36637C20.1594 6.85153 20.1617 7.63903 19.6789 8.1277L13.0624 14.7442L19.6789 21.3607C20.0363 21.7181 20.143 22.256 19.9496 22.7224C19.7563 23.19 19.3004 23.4947 18.7953 23.4947L18.7953 23.4945Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
