import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { CommentVote } from "@dimensional-engineering/dimensional-models";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "@/_firebase/firebaseConfig";

import { useAuth } from "@/context/AuthContext";
import { CommentVoteSimplified } from "@/models/sharedModels";
import { setCommentVotes } from "@/redux/slices/commentsSlice";

export default function useCommentVotesListener(
  commentThreadId: string | null | undefined
) {
  const { user } = useAuth();
  const dispatch = useDispatch();

  // Memoize the Firebase query to prevent unnecessary re-subscriptions
  const unsubscribeFn = useMemo(() => {
    if (!commentThreadId || !user) {
      return null;
    }

    const commentVoteRef = collection(db, `/members/${user.uid}/commentVotes`);
    const q = query(
      commentVoteRef,
      where("commentThreadId", "==", commentThreadId)
    );

    return () =>
      onSnapshot(q, (querySnapshot) => {
        const commentVotes: CommentVoteSimplified[] = [];
        querySnapshot.forEach((res) => {
          const data = res.data() as CommentVote;
          commentVotes.push({
            commentId: data.commentId,
            voteScore: data.voteScore,
            replyId: data.replyId,
          });
        });
        dispatch(setCommentVotes(commentVotes));
      });
  }, [commentThreadId, user, dispatch]);

  useEffect(() => {
    if (!unsubscribeFn) return;

    // Call the subscription function and return the unsubscribe callback
    const unsubscribe = unsubscribeFn();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [unsubscribeFn]);
}
