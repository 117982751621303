export default function LeadershipStyleSnippetIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0893 8.80076V25.5063C29.5848 25.2042 29.0134 25.048 28.442 25.048H28.2634C27.7143 25.0703 27.1875 25.2384 26.7277 25.5078V8.8011C26.7277 7.87104 27.4792 7.12102 28.4093 7.12102C29.3393 7.12102 30.0893 7.8707 30.0893 8.80076Z"
        fill="#FFFFF2"
      />
      <path
        d="M41.2942 8.80061V17.7648C41.2942 18.6949 40.5441 19.4449 39.6141 19.4449H28.4087C27.4787 19.4449 26.7287 18.6949 26.7287 17.7648V8.80061C26.7272 7.87055 27.4787 7.12054 28.4087 7.12054H39.6126C40.5426 7.12054 41.2942 7.87055 41.2942 8.80061Z"
        fill="#FFFFF2"
      />
      <path
        d="M42.851 41.9436C42.5832 42.5715 41.9775 42.9748 41.2945 42.9748L8.80192 42.9629C8.11887 42.9629 7.51323 42.5477 7.2439 41.9212C6.98646 41.2933 7.13229 40.5656 7.61294 40.0953L16.5771 31.1311C17.1604 30.5374 18.1009 30.4704 18.7736 30.9749L21.9774 33.3841L27.1321 27.3231C27.4342 26.9645 27.8717 26.7517 28.3419 26.7294C28.7899 26.6952 29.2601 26.8856 29.5964 27.222L42.4817 40.1073C42.9639 40.5879 43.1085 41.3171 42.851 41.9436Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
