import styles from "./GeneralModal.module.css";

import ModalWrapper from "../ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import CloseButtonSummary from "../CloseButtonSummary";
import WarningIcon from "../GhostBox/WarningIcon";

export default function ExpirationModal(props: { copy: string }) {
  const { setModalOpen } = useModalContext();

  return (
    <ModalWrapper noHeader noLine title="">
      <div className={styles.mainExpiration}>
        <section
          onClick={() => setModalOpen(false)}
          className={styles.closeButtonExpiration}
        >
          <CloseButtonSummary />
        </section>
        <section className={styles.midExpiration}>
          <div className={styles.warningIconDiv}>
            <WarningIcon active />
          </div>
          <p className={styles.copyExpiration}>{props.copy}</p>
          <button
            className={styles.buttonExpiration}
            onClick={() => setModalOpen(false)}
          >
            Dismiss
          </button>
        </section>
      </div>
    </ModalWrapper>
  );
}
