import styles from "./TraitScore.module.css";

type Props = {
  score: number;
};

export default function TraitScore(props: Props) {
  return (
    <div className={styles.main}>
      <div style={{ marginLeft: "1.35px" }} className={styles.selected}></div>
      <div
        className={props.score > 12 ? styles.selected : styles.unselected}
      ></div>
      <div
        className={props.score > 30 ? styles.selected : styles.unselected}
      ></div>
      <div
        className={props.score > 70 ? styles.selected : styles.unselected}
      ></div>
      <div
        className={props.score > 88 ? styles.selected : styles.unselected}
      ></div>
    </div>
  );
}
