import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityLifeStyleIcon(props: DimensionIconProps) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "41"}
      height={props.maxWidth ? "100%" : "41"}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43548_51569)">
        <path
          d="M35.5 5.5C31.5214 5.5 27.706 7.08085 24.8935 9.8935C22.081 12.7061 20.5 16.5217 20.5 20.5C20.5 16.5214 18.9192 12.706 16.1065 9.8935C13.2939 7.081 9.4783 5.5 5.5 5.5V20.5C5.5 25.8589 8.35936 30.8113 13 33.4903C17.6407 36.1704 23.3593 36.1704 28 33.4903C32.6407 30.8114 35.5 25.8589 35.5 20.5V5.5Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_43548_51569">
          <rect
            width="31"
            height="31"
            fill="white"
            transform="translate(5.5 5.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
