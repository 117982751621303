import { useEffect, useState } from "react";

import styles from "./Nutshell.module.css";

import { useModalContext } from "@/context/ModalContext";
import Alert from "@/components/shared/Alerts/Alert";
import { useAlertContext } from "@/context/AlertContext";

type Props = {
  onClick: () => void;
  disabled: boolean;
  message: string;
};

export default function RegenerateButton(props: Props) {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { emptyModal } = useModalContext();
  const { setAlertComponent, emptyAlerts } = useAlertContext();

  useEffect(() => {
    setDisabled(props.disabled);
    //don't change this. The state changes form below
  }, [props.disabled]);

  if (disabled === true) {
    return (
      <button
        style={{ background: "#928E8C" }}
        className={styles.button}
        onClick={() => {
          setAlertComponent(
            <Alert type="fail" message={props.message} elementName="Alert" />,
            7000
          );
          setTimeout(() => {
            emptyAlerts();
            emptyModal();
          }, 5000);
        }}
      >
        Regenerate
      </button>
    );
  }
  return (
    <button
      className={styles.button}
      onClick={() => {
        props.onClick();
        setDisabled(true);
      }}
    >
      Regenerate
    </button>
  );
}
