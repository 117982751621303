export default function VibeWithIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_42412_19964)">
        <path
          d="M9.00045 33.1846C8.95202 33.1846 8.90514 33.1877 8.79889 33.1924C8.77858 33.1908 8.67545 33.1846 8.64889 33.1846H8.64733C4.13653 33.2564 0.420927 35.9111 0.00532703 39.3598C-0.0149854 39.5223 0.036577 39.6863 0.144391 39.8082C0.252203 39.9301 0.408455 40.0004 0.572511 40.0004H17.0849C17.2474 40.0004 17.4037 39.9301 17.5115 39.8082C17.6208 39.6863 17.6708 39.5223 17.6521 39.3598C17.2365 35.9113 13.5209 33.2582 9.00045 33.1846Z"
          fill="#FFFFF2"
        />
        <path
          d="M8.72549 32.0406H8.94268C12.0692 31.9812 14.5647 29.4141 14.5347 26.2874C14.505 23.1593 11.9612 20.6406 8.83308 20.6406C5.70652 20.6406 3.16267 23.1594 3.13307 26.2874C3.10339 29.414 5.59872 31.981 8.72508 32.0406H8.72549Z"
          fill="#FFFFF2"
        />
        <path
          d="M31.3437 33.1846C31.2953 33.1846 31.2484 33.1877 31.1421 33.1924C31.1218 33.1908 31.0187 33.1846 30.9921 33.1846H30.9906C26.4798 33.2564 22.7642 35.9111 22.3486 39.3598C22.3298 39.5223 22.3798 39.6863 22.4892 39.8082C22.597 39.9301 22.7533 40.0004 22.9158 40.0004H39.4282C39.5922 40.0004 39.7485 39.9301 39.8563 39.8082C39.9641 39.6863 40.0157 39.5223 39.9954 39.3598C39.5797 35.9113 35.8657 33.2582 31.3437 33.1846Z"
          fill="#FFFFF2"
        />
        <path
          d="M25.4767 26.3576C25.4985 29.4529 27.9751 31.97 31.0703 32.0404H31.2874H31.2859C33.5765 31.992 35.6155 30.5763 36.4579 28.4451C37.3001 26.3154 36.7829 23.8871 35.1454 22.2843C33.5079 20.6827 31.069 20.2187 28.9578 21.1093C26.8469 21.9984 25.4751 24.067 25.4767 26.3576Z"
          fill="#FFFFF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7835 21.2164V18.7785C20.7835 18.3465 20.4329 17.9949 19.9999 17.9949C19.5669 17.9949 19.2163 18.3465 19.2163 18.7785V21.2164C19.2163 21.6494 19.5669 22 19.9999 22C20.4329 22 20.7835 21.6494 20.7835 21.2164ZM24.9466 17.0546L26.6705 18.7775C26.9761 19.084 27.4727 19.084 27.7793 18.7775C28.0849 18.4718 28.0849 17.9753 27.7793 17.6697L26.0554 15.9458C25.7498 15.6402 25.2522 15.6402 24.9466 15.9458C24.6409 16.2524 24.6409 16.748 24.9466 17.0546ZM13.3295 18.7775L15.0535 17.0546C15.3591 16.748 15.3591 16.2524 15.0535 15.9458C14.7479 15.6402 14.2513 15.6402 13.9457 15.9458L12.2217 17.6697C11.9161 17.9753 11.9161 18.4718 12.2217 18.7775C12.5273 19.084 13.0239 19.084 13.3295 18.7775ZM19.9796 5.5449C16.9284 5.5449 14.4502 8.02296 14.4502 11.0751C14.4502 14.1261 16.9283 16.6032 19.9796 16.6032C23.0318 16.6032 25.51 14.1262 25.51 11.0751C25.51 8.02301 23.0318 5.5449 19.9796 5.5449ZM27.7793 11.7842H30.2164C30.6493 11.7842 31 11.4326 31 11.0006C31 10.5677 30.6493 10.217 30.2164 10.217H27.7793C27.3464 10.217 26.9957 10.5677 26.9957 11.0006C26.9957 11.4326 27.3464 11.7842 27.7793 11.7842ZM9.78361 11.7842H12.2207C12.6536 11.7842 13.0043 11.4326 13.0043 11.0006C13.0043 10.5677 12.6536 10.217 12.2207 10.217H9.78361C9.35066 10.217 9 10.5677 9 11.0006C9 11.4326 9.35066 11.7842 9.78361 11.7842ZM12.2216 4.33029L13.9456 6.05416C14.2512 6.35977 14.7478 6.35977 15.0534 6.05416C15.359 5.74757 15.359 5.25198 15.0534 4.94539L13.3295 3.2225C13.0239 2.91591 12.5273 2.91591 12.2216 3.2225C11.916 3.52811 11.916 4.02468 12.2216 4.33029ZM26.0553 6.05416L27.7793 4.33029C28.0849 4.02468 28.0849 3.52811 27.7793 3.2225C27.4727 2.91591 26.9761 2.91591 26.6705 3.2225L24.9465 4.94539C24.6409 5.25198 24.6409 5.74757 24.9465 6.05416C25.2521 6.35977 25.7497 6.35977 26.0553 6.05416ZM20.7837 3.22149V0.783578C20.7837 0.351619 20.433 0 20.0001 0C19.5671 0 19.2165 0.351619 19.2165 0.783578V3.22149C19.2165 3.65443 19.5671 4.00507 20.0001 4.00507C20.433 4.00507 20.7837 3.65443 20.7837 3.22149Z"
          fill="#E0CC65"
        />
      </g>
      <defs>
        <clipPath id="clip0_42412_19964">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
