import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FeedItemNew } from "@dimensional-engineering/dimensional-models";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";

export interface FeedSlice {
  feedItems: FeedItemNew[] | [];
  lastItemDateViewed: number;
  lastNotification: QueryDocumentSnapshot<DocumentData> | null;
}

const initialState: FeedSlice = {
  feedItems: [],
  lastItemDateViewed: 0,
  lastNotification: null,
};

export const feedSlice = createSlice({
  name: "feed",
  initialState: initialState,
  reducers: {
    setFeed: (state, action: PayloadAction<FeedItemNew[]>) => {
      return { ...state, feedItems: action.payload };
    },
    setLastFeedItemIdViewed: (state, action: PayloadAction<number>) => {
      return { ...state, lastItemDateViewed: action.payload };
    },
    updateLastNotification: (
      state,
      action: PayloadAction<QueryDocumentSnapshot<DocumentData>>
    ) => {
      return { ...state, lastNotification: action.payload };
    },
    paginateFeed: (state, action: PayloadAction<FeedItemNew>) => {
      return {
        ...state,
        feedItems: [...state.feedItems, action.payload],
      };
    },
  },
});

export const {
  setFeed,
  setLastFeedItemIdViewed,
  updateLastNotification,
  paginateFeed,
} = feedSlice.actions;

export default feedSlice.reducer;
