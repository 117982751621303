import { useEffect, useState } from "react";
import Link from "next/link";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import {
  ArchetypeDiscovery,
  DimensionEvent,
  ElementDiscovery,
  PatternDiscovery,
} from "@dimensional-engineering/dimensional-models";

import styles from "../../../styles/app/AppAssessment.module.css";

import GlobalTabBar from "@/components/shared/GlobalTabBar/GlobalTabBar";
import AuthenticatedParent from "@/components/shared/AuthenticatedParent/AuthenticatedParent";
import ProtectedRoute from "@/components/shared/ProtectedRoute";
import { usePublicProfileContext } from "@/context/PublicProfileContext";
import { RootState } from "@/redux/store";
import DimensionStatusIcon from "@/components/assessments-new/root/icons/DimensionStatusIcon";
import ElementStatusIcon from "@/components/assessments-new/root/icons/ElementStatusIcon";
import PatternsStatusIcon from "@/components/assessments-new/root/icons/PatternsStatusIcon";
import ArchetypesStatusIcon from "@/components/assessments-new/root/icons/ArchetypesStatusIcon";
import elementsJsonData from "../../../assets/traitInfo/elements.json";
import patternsJsonData from "../../../assets/traitInfo/patterns.json";
import {
  DimensionDataType,
  dimensionData as dimensionInitialData,
  dimensionIcons,
} from "@/assets/traitInfo/dimensionData";
import useRecommendedDimensionSlug from "@/components/assessments-new/useRecommendedDimensionSlug";
import RecommendedAssessment from "@/components/assessments-new/root/RecommendedAssessment/RecommendedAssessment";
import BetaModal from "@/components/shared/BetaModal/BetaModal";
import ModalHandler from "@/helpers/ModalHandler";
import { useModalContext } from "@/context/ModalContext";
import useEmptyModalOnRenderHook from "@/helpers/useEmptyModalOnRenderHook";
import { useAlertContext } from "@/context/AlertContext";
import AlertHandler from "@/helpers/AlertHandler";
import ResultsHeader from "@/components/shared/AuthenticatedHeader/ResultsHeader";
import PremiumPillIcon from "@/components/premium/PremiumPillIcon/PremiumPillIcon";

const font = Roboto_Mono({ subsets: ["latin"], weight: "500" });

export default function AppAssessment() {
  useEmptyModalOnRenderHook();
  const recommendedSlug = useRecommendedDimensionSlug();
  const { betaModal } = usePublicProfileContext();
  const { isModalOpen } = useModalContext();
  const { isAlertOpen } = useAlertContext();

  const elementsDiscovered: ElementDiscovery[] | null = useSelector(
    (state: RootState) => state.user.elementsDiscovered
  );
  const patternsDiscovered: PatternDiscovery[] | null = useSelector(
    (state: RootState) => state.user.patternsDiscovered
  );
  const archetypesDiscovered: ArchetypeDiscovery[] | null = useSelector(
    (state: RootState) => state.user.archetypesDiscovered
  );

  const isUserPremium = useSelector((state: RootState) => state.user.isPremium);

  const { dimensionData, elementsCount, patternsCount, dimensionsComplete } =
    useGetDimensionStatusAndTraitCount();

  const coreMapped = dimensionData?.slice(0, 3).map((row, i, a) => {
    return (
      <Link href={row.url} key={i}>
        <div
          style={i === a.length - 1 ? { borderBottom: "0" } : {}}
          className={styles.row}
        >
          <div className={styles.iconDiv}>
            {row.event === "View"
              ? dimensionIcons[row.slug].iconInactive
              : dimensionIcons[row.slug].icon}
          </div>
          <div>
            <h2 className={row.event === "View" ? styles.inactive : ""}>
              {row.title}
            </h2>
            <p className={row.event === "View" ? styles.inactive : ""}>
              {row.numOfElements} Elements
            </p>
          </div>

          <button
            className={
              row.event === "View" ? styles.buttonDiv : styles.buttonDivActive
            }
          >
            {row.event}
          </button>
        </div>
      </Link>
    );
  });

  const otherMapped = dimensionData
    .slice(3)
    .filter((row) => row.isPremium !== true)
    .map((row, i, a) => {
      return (
        <Link href={row.url} key={i}>
          <div
            style={i === a.length - 1 ? { borderBottom: "0" } : {}}
            className={styles.row}
          >
            <div className={styles.iconDiv}>
              {row.event === "View"
                ? dimensionIcons[row.slug].iconInactive
                : dimensionIcons[row.slug].icon}
            </div>
            <div>
              <h2 className={row.event === "View" ? styles.inactive : ""}>
                {row.title}
              </h2>{" "}
              <p className={row.event === "View" ? styles.inactive : ""}>
                {row.numOfElements} Elements
              </p>
            </div>

            <button
              className={
                row.event === "View" ? styles.buttonDiv : styles.buttonDivActive
              }
            >
              {row.event}
            </button>
          </div>
        </Link>
      );
    });

  const premiumMapped = dimensionData
    .filter((row) => row.isPremium === true)
    .map((row, i, a) => {
      return (
        <Link href={row.url} key={i}>
          <div
            style={i === a.length - 1 ? { borderBottom: "0" } : {}}
            className={styles.row}
          >
            <div className={styles.iconDiv}>
              {row.event === "View"
                ? dimensionIcons[row.slug].iconInactive
                : dimensionIcons[row.slug].icon}
            </div>
            <div>
              <h2 className={row.event === "View" ? styles.inactive : ""}>
                {row.title}
              </h2>{" "}
              <p className={row.event === "View" ? styles.inactive : ""}>
                {row.numOfElements} Elements
              </p>
            </div>
            {!isUserPremium && (
              <div className={styles.premiumPillDiv}>
                <PremiumPillIcon />
              </div>
            )}

            <button
              className={
                row.event === "View" ? styles.buttonDiv : styles.buttonDivActive
              }
            >
              {row.event}
            </button>
          </div>
        </Link>
      );
    });

  return (
    <>
      {isModalOpen && <ModalHandler />}
      {isAlertOpen && <AlertHandler />}
      {betaModal === true && <BetaModal />}
      <ProtectedRoute>
        <AuthenticatedParent>
          <GlobalTabBar />
          <div className={styles.main}>
            <ResultsHeader underline title="Assessments" />

            <div className={styles.parent}>
              {recommendedSlug && (
                <RecommendedAssessment slug={recommendedSlug} />
              )}

              <div className={styles.coreParent}>
                <h2 className={styles.heading}>Core Dimensions</h2>
                <h4 className={styles.subHeading}>
                  Unlocks your Cognition/Values Archetypes and Core Nature
                  (color around your avatar).
                </h4>
                <div className={styles.table}>{coreMapped}</div>
              </div>
              <div className={styles.otherParent}>
                <h2 className={styles.heading}>Advanced Dimensions</h2>
                <h4 className={styles.subHeading}>
                  Unlocks more Traits and results.
                </h4>
                <div className={styles.table}>{otherMapped}</div>
              </div>
              <div className={styles.otherParent}>
                <h2 className={styles.heading}>Premium Dimensions</h2>
                <h4 className={styles.subHeading}>
                  Powers more accurate and diverse insights. For true
                  self-awareness enthusiasts.
                </h4>
                <div className={styles.table}>{premiumMapped}</div>
              </div>

              <div className={styles.status}>
                <div className={styles.table}>
                  <div className={styles.statusHeader}>
                    <h1>Status</h1>
                  </div>
                  <div
                    style={{ height: "38px" }}
                    className={`${styles.row} ${font.className}`}
                  >
                    <div className={styles.statusIconDiv}>
                      <DimensionStatusIcon />
                    </div>

                    <h3>Dimensions Complete</h3>
                    <p>{dimensionsComplete} of 15</p>
                  </div>
                  <div
                    style={{ height: "38px" }}
                    className={`${styles.row} ${font.className}`}
                  >
                    <div className={styles.statusIconDiv}>
                      <ElementStatusIcon />
                    </div>
                    <h3>Elements discovered</h3>
                    {elementsDiscovered && (
                      <p>
                        {elementsDiscovered.length > elementsCount
                          ? `${elementsCount} of ${elementsCount}`
                          : `${elementsDiscovered.length} of ${elementsCount}`}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ height: "38px" }}
                    className={`${styles.row} ${font.className}`}
                  >
                    <div className={styles.statusIconDiv}>
                      <PatternsStatusIcon />
                    </div>
                    <h3>Patterns discovered</h3>
                    <p>
                      {patternsDiscovered?.length} of {patternsCount}
                    </p>
                  </div>
                  <div
                    style={{ height: "38px", borderBottom: "0" }}
                    className={`${styles.row} ${font.className}`}
                  >
                    <div className={styles.statusIconDiv}>
                      <ArchetypesStatusIcon />
                    </div>
                    <h3>Archetypes discovered</h3>
                    <p>
                      {
                        archetypesDiscovered?.filter(
                          (a) => a.dimensionSlug !== "spirit"
                        ).length
                      }{" "}
                      of 5
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AuthenticatedParent>
      </ProtectedRoute>
    </>
  );
}

export function useGetDimensionStatusAndTraitCount() {
  const dimensionEvents: DimensionEvent[] | null = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );

  const [dimensionData, setDimensionData] = useState<DimensionDataType[]>([]);

  const [elementsCount, setElementsCount] = useState<number>(0);
  const [patternsCount, setPatternsCount] = useState<number>(0);
  const [dimensionsComplete, setDimensionsComplete] = useState<number>(0);

  useEffect(() => {
    setElementsCount(0);
    setPatternsCount(0);
    setDimensionsComplete(0);

    let data: DimensionDataType[] = JSON.parse(
      JSON.stringify(dimensionInitialData)
    );
    if (dimensionEvents && dimensionEvents?.length > 0) {
      dimensionEvents.forEach((dimEvent) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].slug === dimEvent.dimensionSlug) {
            if (dimEvent.event === "complete") {
              setDimensionsComplete((current) => (current += 1));
              data[i].event = "View";
            } else {
              data[i].event = "Continue";
            }
          }
        }
      });
    }
    setDimensionData(data);

    elementsJsonData.forEach((el) => {
      if (el.isDiscoverable === true) {
        setElementsCount((prev) => (prev += 1));
      }
    });
    patternsJsonData.forEach((el) => {
      if (el.isDiscoverable === true) {
        setPatternsCount((prev) => (prev += 1));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionEvents]);

  return { dimensionData, elementsCount, patternsCount, dimensionsComplete };
}
