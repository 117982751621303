/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./Histogram.module.css";

import { ProfileColorMap } from "@/components/shared/ColorMap";
import TraitsHeading from "../Heading/TraitsHeading";
import TraitsWrapper from "../Wrapper/Wrapper";
import useIsMobile from "@/helpers/useIsMobile";
import useGetProfilePhoto from "@/helpers/useGetProfilePhoto";

type HistogramProps = {
  data: number[] | null;
  type?: "element" | "pattern" | "archetype";
  averageUsrScore?: number | null | undefined;
  score: number | null | undefined;
  publicProfile: PublicProfile | null;
};

export default function FriendHistogram(props: HistogramProps) {
  const [multiplier, setMultiplier] = useState<number>(0);
  const router = useRouter();
  const [rawScore, setRawScore] = useState<number | null | undefined>();
  const isMobile = useIsMobile();
  const friendImageUrl = useGetProfilePhoto(props.publicProfile?.ownerUUID);

  useEffect(() => {
    let high = 0;
    if (props.data) {
      props.data.forEach((point) => {
        if (point > high) {
          high = point;
        }
      });
    }
    setMultiplier(210 / (high * 100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.score) {
      setRawScore(props.score);
    } else {
      setRawScore(null);
    }
  }, [props.score, router.query.traitSlug]);

  useEffect(() => {
    if (rawScore && rawScore > 0.95) {
      if (!isMobile) {
        setRawScore(0.91);
      } else {
        setRawScore(0.95);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawScore]);

  const mapped = props.data?.map((point, index) => {
    return (
      <div
        className={styles.histogramBarModal}
        style={{ height: `${point * 100 * multiplier}px` }}
        key={index}
      ></div>
    );
  });

  if (props.data === null) {
    return (
      <div className={styles.parent}>
        <TraitsHeading title="Distribution of user scores Coming Soon" />
      </div>
    );
  }

  return (
    <TraitsWrapper>
      <TraitsHeading title="Distribution of user scores" />
      <div className={styles.subTitleDiv}>
        <div className={styles.avScoreLabelCircle}></div>
        <h4>Average score of all users</h4>
      </div>
      <div className={styles.histogramParentModal}>
        {props.averageUsrScore && (
          <div
            style={
              isMobile
                ? { right: `${props.averageUsrScore * 100 - 4}%` }
                : { right: `${props.averageUsrScore * 100 - 9}%` }
            }
            className={styles.averageScoreDiv}
          >
            <div className={styles.averageScoreLine}></div>
            <div className={styles.averageScoreCircle}></div>
          </div>
        )}
        {rawScore && (
          <div
            style={{ right: `${rawScore * 100}%` }}
            className={styles.averageScoreDiv}
          >
            <div
              style={
                props.publicProfile?.primaryNature
                  ? {
                      backgroundColor: `${
                        ProfileColorMap[
                          props.publicProfile?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { backgroundColor: "#55504F" }
              }
              className={styles.userScoreLine}
            ></div>
            <div
              style={
                props.publicProfile?.primaryNature
                  ? {
                      border: `3px solid ${
                        ProfileColorMap[
                          props.publicProfile?.primaryNature
                            .nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: "3px solid #55504F" }
              }
              className={styles.userScoreCircle}
            >
              <img src={friendImageUrl as string} alt="" />
            </div>
          </div>
        )}
        {mapped}
      </div>
      <div style={{ padding: "0 5px" }} className={styles.labels}>
        <p>0</p>
        <p>25</p>
        <p>50</p>
        <p>75</p>
        <p>100</p>
      </div>
    </TraitsWrapper>
  );
}
