export default function BasicConnectionIcon(props: { active?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.9758 4.21802C11.9063 4.21802 12.6587 4.97036 12.6587 5.90088C12.6587 6.83139 11.9063 7.58374 10.9758 7.58374C10.0477 7.58374 9.29297 6.83139 9.29297 5.90088C9.29297 4.97036 10.0477 4.21802 10.9758 4.21802Z"
        fill={props.active ? "#2D2927" : "#55504F"}
      />
      <path
        d="M13.9245 10.0887C13.9245 10.4825 13.669 10.8317 13.2917 10.9489C12.5135 11.1903 11.7026 11.3098 10.8893 11.3005C10.9502 11.1013 10.9807 10.8927 10.9807 10.6841C10.9807 9.86141 10.7111 9.06226 10.2119 8.40586C10.9783 8.64023 11.8081 8.51133 12.4666 8.05664C12.8439 8.15273 13.1814 8.36836 13.4252 8.67305C13.7486 9.07384 13.9245 9.5737 13.9245 10.0887Z"
        fill={props.active ? "#2D2927" : "#55504F"}
      />
      <path
        d="M8.60891 5.45021C8.60891 6.69299 7.60169 7.70022 6.3589 7.70022C5.11611 7.70022 4.10889 6.69299 4.10889 5.45021C4.10889 4.20802 5.11611 3.2002 6.3589 3.2002C7.60169 3.2002 8.60891 4.20802 8.60891 5.45021Z"
        fill={props.active ? "#2D2927" : "#55504F"}
      />
      <path
        d="M9.41964 8.91641C9.82277 9.41798 10.0431 10.0414 10.0431 10.686C10.0431 11.1781 9.72433 11.6141 9.25323 11.7618C7.36892 12.3454 5.35081 12.3454 3.4665 11.7618C2.99541 11.6147 2.67432 11.1782 2.67432 10.686C2.67432 10.0414 2.89464 9.418 3.29775 8.91641C3.63057 8.5039 4.09463 8.22031 4.61494 8.11719C5.67432 8.81094 7.04315 8.81094 8.10246 8.11719C8.62278 8.2209 9.08918 8.50449 9.41964 8.91641Z"
        fill={props.active ? "#2D2927" : "#55504F"}
      />
    </svg>
  );
}

export function BasicConnectionIconSummaries() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26ZM17.2994 7.53674C18.6434 7.53674 19.7302 8.62346 19.7302 9.96753C19.7302 11.3116 18.6435 12.3983 17.2994 12.3983C15.9588 12.3983 14.8686 11.3116 14.8686 9.96753C14.8686 8.62346 15.9587 7.53674 17.2994 7.53674ZM20.644 17.2589C21.1891 17.0896 21.5581 16.5852 21.5581 16.0164C21.5581 15.2725 21.304 14.5505 20.8368 13.9716C20.4848 13.5314 19.9973 13.22 19.4522 13.0812C18.501 13.7379 17.3025 13.9241 16.1955 13.5856C16.9166 14.5337 17.3059 15.6881 17.3059 16.8763C17.3059 17.1776 17.2619 17.479 17.1739 17.7667C18.3486 17.7802 19.5201 17.6076 20.644 17.2589ZM13.88 9.31665C13.88 11.1118 12.4251 12.5667 10.63 12.5667C8.83485 12.5667 7.37998 11.1118 7.37998 9.31665C7.37998 7.52239 8.83485 6.06665 10.63 6.06665C12.4251 6.06665 13.88 7.52239 13.88 9.31665ZM15.952 16.8796C15.952 15.9486 15.6338 15.0481 15.0515 14.3236C14.5742 13.7286 13.9005 13.319 13.1489 13.1692C11.6188 14.1713 9.64163 14.1713 8.11142 13.1692C7.35987 13.3181 6.68957 13.7278 6.20883 14.3236C5.62656 15.0481 5.30832 15.9486 5.30832 16.8796C5.30832 17.5906 5.77211 18.2211 6.45258 18.4335C9.17434 19.2765 12.0894 19.2765 14.8111 18.4335C15.4916 18.2202 15.952 17.5905 15.952 16.8796Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
