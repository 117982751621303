export default function YourBlindspotsSnippetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M32.9342 28.7859C32.4731 28.4396 31.8189 28.5336 31.4727 28.9947C31.1803 29.3845 30.8532 29.7603 30.5192 30.1292C26.7225 29.9256 23.1467 28.2831 20.5196 25.534C17.8922 22.7866 16.4114 19.1398 16.3801 15.3376C16.3488 11.5357 17.7686 7.86446 20.3508 5.07335C20.8449 5.07335 21.3461 5.14295 21.8263 5.21951H21.9864C22.5623 5.26301 23.0669 4.83149 23.1104 4.25554C23.1539 3.67959 22.7224 3.175 22.1464 3.13152C21.4139 3.01669 20.6744 2.94882 19.9331 2.92969H19.5016C13.7947 2.93665 8.4717 5.79892 5.31534 10.5542C2.16076 15.3079 1.59349 21.3267 3.80504 26.5864C6.01481 31.8479 10.7111 35.6533 16.3156 36.729C21.9183 37.8026 27.6898 36.0017 31.6885 31.9316C32.207 31.4027 32.6907 30.8389 33.1362 30.2473C33.4842 29.788 33.3936 29.1339 32.9342 28.7859Z"
        fill="#FFFFF2"
      />
      <path
        d="M34.0475 16.4736C34.1815 17.0356 34.7452 17.3801 35.3072 17.2461C35.8692 17.1121 36.2138 16.5484 36.0798 15.9864C35.6865 14.356 35.0532 12.7952 34.2006 11.3512C33.8926 10.8936 33.2801 10.7544 32.8051 11.0345C32.3301 11.3146 32.1544 11.9167 32.4049 12.4091C33.1496 13.6758 33.7047 15.045 34.0475 16.4736Z"
        fill="#FFFFF2"
      />
      <path
        d="M35.5096 19.8562C35.233 19.8405 34.9615 19.9362 34.7545 20.1224C34.5491 20.3068 34.4239 20.5678 34.4099 20.8445C34.3299 22.3113 34.0341 23.759 33.533 25.1387C33.4164 25.405 33.4164 25.706 33.5312 25.9722C33.6461 26.2384 33.867 26.4455 34.1402 26.5429C34.4134 26.6386 34.7144 26.6177 34.9719 26.4837C35.2295 26.3498 35.4191 26.1149 35.4957 25.8347C36.0664 24.2688 36.404 22.6279 36.4979 20.9625C36.5153 20.6842 36.4196 20.411 36.2352 20.2039C36.049 19.9951 35.788 19.8701 35.5096 19.8562Z"
        fill="#FFFFF2"
      />
      <path
        d="M26.0238 6.52775C27.3479 7.16806 28.5694 8.00152 29.6499 8.99854C29.8465 9.22126 30.1284 9.35002 30.426 9.3535C30.7235 9.35524 31.0089 9.22996 31.2072 9.00898C31.4073 8.78974 31.503 8.49397 31.4717 8.19815C31.4404 7.90236 31.2838 7.63441 31.0419 7.46042C29.81 6.33467 28.4198 5.3968 26.9147 4.67644C26.3961 4.42414 25.7715 4.64164 25.5192 5.16014C25.2669 5.67867 25.4844 6.30331 26.0029 6.5556L26.0238 6.52775Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
