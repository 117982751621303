import { useEffect, useState } from "react";
import {
  ArchetypeDiscovery,
  ElementDiscovery,
  PatternDiscovery,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";

import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { db, firebaseStorage } from "@/_firebase/firebaseConfig";
import { getDefaultProfilePhoto } from "@/context/utils";
import {
  getUserPatternScore,
  getUserScaleScore,
} from "../components/traits/utils";

export default function useGetConnectionTabData(
  uidList: string[],
  slug: string,
  type: "element" | "archetype" | "pattern"
) {
  const [returnData, setData] = useState<
    {
      publicProfile: PublicProfile | null;
      score: number | null | undefined;
      imageUrl: string;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [scoreTotal, setScoreTotal] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  type ElementResult = {
    discoveryData: {
      publicProfile: PublicProfile;
      elementDiscovery: ElementDiscovery;
    }[];
  };

  type PatternResult = {
    discoveryData: {
      publicProfile: PublicProfile;
      patternDiscovery: PatternDiscovery;
    }[];
  };

  useEffect(() => {
    if (type === "archetype") {
      const promises = uidList.map(async (uid) => {
        let publicProfile: PublicProfile | null = null;
        let image: string = "";
        let score: number | null | undefined = null;

        const publicProfileRef = doc(db, "publicProfiles", uid as string);

        getDoc(publicProfileRef).then((res) => {
          if (res.exists()) {
            publicProfile = res.data() as PublicProfile;
          }
        });

        await getDownloadURL(
          ref(
            firebaseStorage,
            `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${uid}/cropped.jpg`
          )
        )
          .then((url) => {
            image = url;
          })
          .catch(async (err) => {
            return await getDefaultProfilePhoto(uid as string).then((value) => {
              return (image = value);
            });
          });

        if (type === "archetype") {
          // Fetch archetype data
          const fetchData = callFirebaseFunctions(
            "fetchFriendArchetypeDiscovery"
          );
          try {
            const res = await fetchData({
              memberUUID: uid,
              version: "2",
              traitSlug: slug,
            });
            const data = res.data as ArchetypeDiscovery;
            if (data && data.archetypeScore) {
              score = data.archetypeScore * 100;
            }
          } catch (err) {
            score = null;
          }
        }

        return {
          score: score,
          imageUrl: image,
          publicProfile: publicProfile,
        };
      });

      Promise.all(promises)
        .then((data) => {
          setData(data);
          data.forEach((connection) => {
            if (connection.score !== null && connection.score !== undefined) {
              //@ts-ignore
              setScoreTotal((current) => current + connection.score);
              setCount((current) => current + 1);
            }
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    } else if (type === "element") {
      //element call
      const elementCall = callFirebaseFunctions(
        "fetchAccessMapElementDiscoveries"
      );
      elementCall({ traitSlug: slug })
        .then((res) => {
          const data = res.data as ElementResult;
          data.discoveryData.forEach(async (connection) => {
            let publicProfile = connection.publicProfile;
            let image: string = "";
            let score = getUserScaleScore(
              connection.elementDiscovery.scaleScore?.percentScore,
              slug
            );
            if (score) {
              score = score * 100;
            }
            if (score !== null && score !== undefined) {
              //@ts-ignore
              setScoreTotal((current) => current + score);
              setCount((current) => current + 1);
            }
            await getDownloadURL(
              ref(
                firebaseStorage,
                `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${connection.publicProfile.ownerUUID}/cropped.jpg`
              )
            )
              .then((url) => {
                image = url;
              })
              .catch(async (err) => {
                return await getDefaultProfilePhoto(
                  connection.publicProfile.ownerUUID as string
                ).then((value) => {
                  return (image = value);
                });
              });
            setLoading(false);
            setData((current) => [
              ...current,
              { publicProfile: publicProfile, imageUrl: image, score: score },
            ]);
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      //pattern call
      const patternCall = callFirebaseFunctions(
        "fetchAccessMapPatternDiscoveries"
      );
      patternCall({ traitSlug: slug })
        .then((res) => {
          const data = res.data as PatternResult;
          data.discoveryData.forEach(async (connection) => {
            let publicProfile = connection.publicProfile;
            let image: string = "";
            let score = getUserPatternScore(
              connection.patternDiscovery.patternScore,
              slug
            );
            if (score) {
              score = score * 100;
            }
            if (score !== null && score !== undefined) {
              //@ts-ignore
              setScoreTotal((current) => current + score);
              setCount((current) => current + 1);
            }
            await getDownloadURL(
              ref(
                firebaseStorage,
                `gs://${process.env.NEXT_PUBLIC_STORAGEBUCKET}/profileImages/${connection.publicProfile.ownerUUID}/cropped.jpg`
              )
            )
              .then((url) => {
                image = url;
              })
              .catch(async (err) => {
                return await getDefaultProfilePhoto(
                  connection.publicProfile.ownerUUID as string
                ).then((value) => {
                  return (image = value);
                });
              });
            setLoading(false);
            setData((current) => [
              ...current,
              { publicProfile: publicProfile, imageUrl: image, score: score },
            ]);
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, type]);

  return { returnData, loading, scoreTotal, count };
}
