import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import styles from "./Snippets.module.css";
import { snippetMappedSubtitles } from "./snippetsMap";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetLlmTitleAndContentView(props: Props) {
  const nature = useSelector(
    (state: RootState) => state.user.publicProfileData?.primaryNature?.nature
  );
  const mapped = props.snippet.snippetDetail.llmTitlesAndContent?.map(
    (d, i) => {
      if (d.paragraph) {
        return (
          <div
            style={i === 0 ? { marginTop: "60px" } : {}}
            className={styles.llmListDivContent}
            key={i}
          >
            <h4 className={styles.llmListTitleContent}>{d.title}</h4>
            <div
              style={
                nature
                  ? { backgroundColor: `${ProfileColorMap[nature]}` }
                  : { backgroundColor: `#55504F` }
              }
              className={styles.line}
            ></div>
            <p className={styles.llmListParagraphContent}>{d.paragraph}</p>
          </div>
        );
      } else {
        return (
          <div
            style={i === 0 ? { marginTop: "60px" } : {}}
            className={styles.llmListDivContent}
            key={i}
          >
            <h4 className={styles.llmListTitleContent}>{d.title}</h4>
            <div
              style={
                nature
                  ? { backgroundColor: `${ProfileColorMap[nature]}` }
                  : { backgroundColor: `#55504F` }
              }
              className={styles.line}
            ></div>
            <ul className={styles.ul}>
              {d.unorderedList?.map((li, index) => {
                return <li key={index}>{li}</li>;
              })}
            </ul>
          </div>
        );
      }
    }
  );

  return (
    <>
      {props.snippet.snippetDetail.llmTitlesAndContent && (
        <>
          <h2 className={styles.llmListName}>
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          {mapped}
        </>
      )}
    </>
  );
}
