import AttachmentStyleIcon from "@/components/shared/dimensions/icons/AttachmentStyleIcon";
import CognitionIcon from "@/components/shared/dimensions/icons/CognitionIcon";
import ConflictStylesIcon from "@/components/shared/dimensions/icons/ConflictStylesIcon";
import CopingStyleIcon from "@/components/shared/dimensions/icons/CopingStyleIcon";
import InterestsIcon from "@/components/shared/dimensions/icons/InterestsIcon";
import LifeStyleIcon from "@/components/shared/dimensions/icons/LifeStyleIcon";
import LoveAttitudesIcon from "@/components/shared/dimensions/icons/LoveAttitudesIcon";
import LoveStylesIcon from "@/components/shared/dimensions/icons/LoveStylesIcon";
import PoliticalIdeologyIcon from "@/components/shared/dimensions/icons/PoliticalIdeologyIcon";
import PrimaryTraitsIcon from "@/components/shared/dimensions/icons/PrimaryTraitsIcon";
import SecondaryTraitsIcon from "@/components/shared/dimensions/icons/SecondaryTraitsIcon";
import SexAttitudesIcon from "@/components/shared/dimensions/icons/SexAttitudesIcon";
import StrengthsIcon from "@/components/shared/dimensions/icons/StrengthsIcon";
import ValuesIcon from "@/components/shared/dimensions/icons/ValuesIcon";
import WorkStyleIcon from "@/components/shared/dimensions/icons/WorkStyleIcon";
import { SummaryView } from "@dimensional-engineering/dimensional-models";

export type DimensionDataType = {
  title: string;
  url: string;
  icon: React.ReactNode;
  iconInactive: React.ReactNode;
  event: string;
  slug: string;
  numOfElements: number;
  headline?: string;
  description?: string;
  longDescription?: string;
  order: number;
  hasArchetype?: boolean;
  estimatedTime: number;
  isPrivate: boolean;
  numElements: number;
  archetypeGroupSlugs?: string[];
  useFuzzyClustering?: boolean;
  isNeeded: boolean;
  summaryView: SummaryView[] | [] | null;
  permission: "connected" | "isClose" | "isDeep" | "private";
  isPremium?: boolean;
  summaryDescription: string[];
};

export const dimensionData: DimensionDataType[] = [
  {
    title: "Cognition",
    url: "/app/assessment/personality-II",
    icon: <CognitionIcon />,
    iconInactive: <CognitionIcon inactive />,
    event: "Start",
    slug: "personality-II",
    numOfElements: 8,
    headline: "YOUR BASIC INSTINCTS",
    description: `How you process reality, make decisions, and relate to others. Essentially how your brain "works."`,
    longDescription:
      "Cognition measures how you think: how interpret reality, process information, and make decisions. The Cognition Dimension is based on Jung's model of Cognitive Functions. For simplicity's sake, function stacks have been replaced with a set of four opposite traits: Introversion vs. Extroversion, Sensing vs. Intuition, Thinking vs. Feeling, and Judging vs. Perceiving.",
    order: 1,
    hasArchetype: true,
    estimatedTime: 2,
    isPrivate: false,
    numElements: 8,
    archetypeGroupSlugs: ["spirit", "identity"],
    isNeeded: false,
    summaryView: null,
    permission: "connected",
    summaryDescription: [
      `The Cognition Dimension is based on Carl Jung's theory of cognitive functions, a model describing how your brain essentially "works." It looks at four main aspects of how you think: how you take in information, make decisions, interact with others, and recharge your energy.`,
      `Jung's model suggests that everyone has unique mental "preferences," kind of like default settings, across four pairs: Introversion vs. Extraversion, Intuition vs. Sensing, Thinking vs. Feeling, and Judging vs. Perceiving. These preferences combine to shape how you see and process the world. For example, you might be someone who's naturally drawn to big ideas and possibilities, while others might zero in on concrete details and immediate realities.`,
      `The Cognition Dimension is all about understanding these mental habits, so you can play to your strengths, relate better to others, and approach life in a way that feels true to you.`,
    ],
  },
  {
    title: "Values",
    url: "/app/assessment/values",
    icon: <ValuesIcon />,
    iconInactive: <ValuesIcon inactive />,
    event: "Start",
    slug: "values",
    numOfElements: 9,
    headline: "YOUR COMPASS",
    description: `Your inner compass. What motivates you and how you make choices.`,
    longDescription:
      "Your values are your underlying drivers — your internal compass. This Dimension explores  ten basic values, which are organized into four higher-order groups: self-transcendence, self-enhancement, openness to change, and conservation. Some Values oppose each other. For example, self-enhancement values such as power and achievement are opposite to self-transcendence values such as benevolence and universalism.",
    order: 2,
    hasArchetype: true,
    estimatedTime: 4,
    isPrivate: false,
    numElements: 9,
    archetypeGroupSlugs: ["spirit", "values"],
    isNeeded: false,
    summaryView: null,
    permission: "connected",
    summaryDescription: [
      `The Motivational Values Dimension is based on the Schwartz Theory of Basic Human Values, helping you understand the core principles that guide your choices and priorities. Think of this as the "why" behind what you do—the values that motivate you and shape your idea of a meaningful life.`,
      `In this model, people are driven by nine key values, including Self-Direction, Achievement, Universalism, Security, and Hedonism. Each of these values reflects a different kind of motivation: some people are driven to explore and create, while others prioritize stability, power, or pleasure.`,
      `This Dimension helps you see which values resonate most with you and how they influence your behavior. Recognizing these motivations can lead to more purposeful decisions and help you align with goals that feel authentic. It's all about understanding what truly matters to you and how it impacts the way you live and interact with others.`,
    ],
  },
  {
    title: "Primary Traits",
    url: "/app/assessment/personality",
    icon: <PrimaryTraitsIcon />,
    iconInactive: <PrimaryTraitsIcon inactive />,
    event: "Start",
    slug: "personality",
    numOfElements: 38,
    headline: "YOUR BASIC INSTINCTS",
    description: `A foundational look at how you tend to behave, think, and feel.`,
    longDescription:
      "Primary Traits is the first and most important Dimension. Based on the Big 5 personality inventory, Primary Traits measure the five most fundamental behavioral traits: Openness, Conscientiousness, Extraversion, Agreeableness, and Emotional Stability. Each of these five parent traits is referred to as a “Domain”. Within each Domain is 6 child Elements referred to as facets. In all, Primary Traits measure your broad patterns of behavior and emotion.",
    order: 3,
    hasArchetype: true,
    estimatedTime: 6,
    isPrivate: false,
    numElements: 38,
    archetypeGroupSlugs: ["spirit", "love"],
    isNeeded: false,
    summaryView: null,
    permission: "isClose",
    summaryDescription: [
      `The Primary Traits Dimension is based on the Big Five personality model, the most widely researched and scientifically validated measure of personality. This Dimension is foundational in the app, as it captures the ways you generally think, feel, and interact with the world around you.`,
      `The Big Five model identifies the five key traits that vary most across people: Openness, Conscientiousness, Extraversion, Agreeableness, and Emotional Stability.`,
      `However, two people who score similarly on one of these broad traits can still show that trait differently. For instance, two highly extraverted people might each have their own style—one might be more outgoing and sociable, while the other is energized by thrill and adventure.`,
      `To add nuance, each of these five traits breaks down into six facets, which offer a closer look at specific behaviors and tendencies.`,
    ],
  },
  {
    title: "Interaction Styles",
    url: "/app/assessment/communication-style",
    icon: <WorkStyleIcon />,
    iconInactive: <WorkStyleIcon inactive />,
    event: "Start",
    slug: "communication-style",
    numOfElements: 5,
    headline: "YOUR WORK INSTINCTS",
    description: `How you interact with others and engage with tasks.`,
    longDescription:
      "The Interaction Styles Dimension explores how we interact and communicate with others. Using the DISC model of Personality, this Dimension unlocks 4 traits that exist along 2 continuums: Dominance, Influence, Steadiness, and Cautiousness. Dominance and Steadiness exist along one continuum, while Cautiousness and Influence exist on the second continuum.",
    order: 4,
    estimatedTime: 6,
    isPrivate: false,
    numElements: 5,
    archetypeGroupSlugs: ["spirit", "work"],
    isNeeded: false,
    summaryView: null,
    permission: "connected",
    summaryDescription: [
      `The Interaction Styles Dimension is based on the DISC model of personality, which captures the core ways people engage with others and approach tasks. This model highlights two sets of opposite traits Dominant vs. Supportive, and  Influential vs. Cautious.`,
      `People with a Dominant style are often charismatic and assertive, driven by results and direct in their communication. They thrive in leadership roles, focusing on getting things done efficiently. On the other hand, people with a Supportive style are patient, reliable, and cooperative.`,
      `People an Influential style bring energy and sociability to their interactions, excelling at collaboration and keeping the environment positive and flexible. In contrast, people with a Cautious style are more reserved and independent. They are are disciplined and detail-oriented, preferring to analyze situations carefully before making decisions.`,
      `People's combinations of scores on these two opposite trait pairs can predict a lot about how you engage with the world and the style of your relationships.`,
    ],
  },
  {
    title: "Love Styles",
    url: "/app/assessment/love-style",
    icon: <LoveStylesIcon />,
    iconInactive: <LoveStylesIcon inactive />,
    event: "Start",
    slug: "love-style",
    numOfElements: 10,
    headline: "GIVING AND RECEIVING LOVE",
    description: `How you naturally give and desire love.`,
    longDescription:
      "The Love Styles Dimension explores how we naturally show love and what we need to feel loved. There are five love styles that each have two traits: one corresponding to needing that love style to feel loved, and naturally giving that love style. The love styles are Words of Endearment, Touch, Service, Time Together, and Gifts.",
    order: 5,
    estimatedTime: 4,
    isPrivate: false,
    numElements: 10,
    archetypeGroupSlugs: ["love"],
    isNeeded: false,
    summaryView: null,
    permission: "isClose",
    summaryDescription: [
      `The Love Styles Dimension explores how you experience and express love, highlighting both the ways you desire affection and how you naturally show it to others. Each of us connects through different forms of love, and understanding these styles can make relationships more fulfilling.`,
      `There are five main Love Styles: Affirmation, Touch, Helpful Service, Time, and Gifts. People who resonate with Affirmation value words of appreciation and encouragement, while those who prefer Touch feel most connected through physical affection. For some, love is best shown through Helpful Service—actions that make life easier for their loved ones. Others feel love most deeply through dedicated Time, appreciating undivided attention and shared moments. Lastly, the Gifts style sees love in thoughtful tokens and gestures, valuing the care that goes into selecting something meaningful.`,
      `This Dimension is a key to understanding not just how you seek love but also how you tend to give it, helping you and your loved ones connect in ways that feel authentic and satisfying.`,
    ],
  },
  {
    title: "Love Attitudes",
    url: "/app/assessment/love-attitudes",
    icon: <LoveAttitudesIcon />,
    iconInactive: <LoveAttitudesIcon inactive />,
    event: "Start",
    slug: "love-attitudes",
    numOfElements: 8,
    headline: "HOW YOU VIEW LOVE",
    description: `How you conceptualize and psychologically experience romance.`,
    longDescription:
      "The Love Attitudes Dimension explores how we conceptualize love and romance and what we want out of our love lives. Some of us have a pragmatic perspective on love, prioritizing fit and values. Others are idealistic. Others are playful. And more. Here we examine traits that breakdown these different perspectives and desires in the romantic realm.",
    order: 6,
    useFuzzyClustering: true,
    estimatedTime: 6,
    isPrivate: false,
    numElements: 8,
    archetypeGroupSlugs: ["love"],
    isNeeded: false,
    summaryView: null,
    permission: "isClose",
    summaryDescription: [
      `The Love Attitudes Dimension examines 6 broad ways we conceptualize and experience romantic relationships. This Dimension captures your unique perspective on love, exploring both the intensity and style of your romantic connections.`,
      `If Passionate Love (Eros) resonates with you, you're likely to dive into relationships with intense attraction and desire, embracing romance wholeheartedly.`,
      `Obsessive Love (Mania) is marked by a consuming, sometimes overwhelming focus on a partner, often leading to high emotional highs and lows.`,
      `For those with Practical Love (Pragma), relationships are based on compatibility and shared goals, valuing a grounded and logical approach to love.`,
      `Affectionate Love (Storge) centers around deep friendship and familiarity, prioritizing trust and companionship over drama or excitement.`,
      `Compassionate Love (Agape) is a selfless, giving love, focused on caring for a partner's well-being without expecting anything in return.`,
      `And finally, Playful Love (Ludus) brings a sense of lightness and fun, enjoying love as a game and savoring the thrill of new connections.`,
      `This Dimension helps you understand your romantic inclinations, giving you a clearer view of what you naturally seek in love and how you relate to partners. Knowing your Love Attitudes can foster stronger connections and more fulfilling relationships by aligning your expectations with your authentic style of loving.`,
    ],
  },
  {
    title: "Attachment Styles",
    url: "/app/assessment/attachment-style",
    icon: <AttachmentStyleIcon />,
    iconInactive: <AttachmentStyleIcon inactive />,
    event: "Start",
    slug: "attachment-style",
    numOfElements: 4,
    headline: "HOW YOU CONNECT",
    description: `How you psychologically react to close romantic relationships.`,
    longDescription:
      "This Dimension looks at the different ways we form and maintain close emotional relationships with others, based on our early experiences with caregivers. In this Dimension we explore two traits: Bond Avoidant and Bond Anxious. High and low combinations of scores on these traits result in four Attachment Styles: secure, anxious, avoidant, and fearful-avoidant. Attachment styles can have significant impacts on one's personal and professional relationships throughout their lives.",
    order: 7,
    isPrivate: false,
    estimatedTime: 5,
    numElements: 4,
    archetypeGroupSlugs: ["love"],
    isNeeded: false,
    summaryView: null,
    permission: "isDeep",
    summaryDescription: [
      `The Attachment Styles Dimension explores the emotional blueprint that shapes how you bond with others, particularly in close relationships. Based on attachment theory, this Dimension reveals the patterns you tend to follow in love and connection, influenced by your early experiences and how safe and supported you felt in important relationships.`,
      `There are four primary attachment styles: Secure, Anxious, Avoidant, and Fearful-Avoidant.`,
      `If you have a Secure attachment style, you likely find it natural to trust others, communicate openly, and build stable, lasting relationships.`,
      `An Anxious attachment style, on the other hand, may make you crave closeness intensely and worry about your partner's commitment, often feeling more sensitive to changes in the relationship.`,
      `Avoidant attachment is characterized by a preference for independence and self-reliance, sometimes leading to discomfort with too much emotional closeness.`,
      `Fearful-Avoidant, also known as disorganized attachment, combines both anxious and avoidant tendencies, creating a push-pull dynamic in relationships due to a mix of longing for closeness and fear of getting hurt.`,
      `Understanding your Attachment Style can shed light on the way you approach intimacy and handle emotional challenges, helping you navigate relationships with greater awareness and self-compassion.`,
    ],
  },
  {
    title: "Interests",
    url: "/app/assessment/interests",
    icon: <InterestsIcon />,
    iconInactive: <InterestsIcon inactive />,
    event: "Start",
    slug: "interests",
    numOfElements: 6,
    headline: "FIND MEANING IN WORK",
    description: `What kinds of activities make you feel most fulfilled and in flow.`,
    longDescription:
      "This Dimension explores 6 traits that together describe our basic interests as it relates to career choice. Based on the Holland Code model, the six traits are Realistic, Artistic, Investigative, Social, Enterprising and Conventional. Our mix of basic interests is highly predictive of what careers we are likely to enjoy and excel at. For instance, someone who is high on enterprising and conventional will be interested in traditional business-related careers and value things like power, status, and financial success. On the other hand, someone who is high on realistic and artistic will be more interested in creative and hands-on careers, such as engineering or the arts, and value things like innovation, independence, and self-expression.",
    order: 8,
    hasArchetype: true,
    estimatedTime: 7,
    isPrivate: false,
    numElements: 6,
    archetypeGroupSlugs: ["spirit", "career"],
    isNeeded: false,
    summaryView: null,
    permission: "connected",
    summaryDescription: [
      `The Interests Dimension is based on the Holland Codes, a model that helps you understand the kinds of activities and work environments that resonate with you. This Dimension explores the fields and pursuits you're naturally drawn to, and for that reason it is the most relevant Dimension for exploring Career choices in your life.`,
      `There are six primary interest areas: Artistic, Investigative, Enterprising, Realistic, Conventional, and Social.`,
      `If you're Artistic, you may be drawn to creative expression and value originality.`,
      `Those with Investigative interests are often curious and analytical, enjoying problem-solving and intellectual challenges.`,
      `Enterprising individuals thrive in leadership and persuasion roles, seeking opportunities to take initiative and make an impact.`,
      `A Realistic interest points to a preference for hands-on, practical work, often with tools, machinery, or the outdoors.`,
      `Conventional interests indicate a comfort with structure and organization, excelling in roles that require attention to detail and routine.`,
      `Lastly, Social interests reflect a desire to help and connect with others, finding satisfaction in collaborative and service-oriented environments.`,
      `Understanding your Vocational Interests can guide your career choices and help you align your lifestyle and hobbies with paths that feel meaningful and fulfilling.`,
    ],
  },
  {
    title: "Strengths",
    url: "/app/assessment/strengths",
    icon: <StrengthsIcon />,
    iconInactive: <StrengthsIcon inactive />,
    event: "Start",
    slug: "strengths",
    numOfElements: 25,
    headline: "HOW YOU SHINE",
    description: `The virtues that define your character.`,
    longDescription:
      "The Strengths Dimension focuses on the area of positive psychology. While most assessments often focus on what is wrong with us, the Strengths Dimension is focused entirely on what is right with us. Here we explore 24 character strengths (or virtues) that span cultures and history. Our strengths can be applied to a variety of contexts from work, love, personal growth, and overall wellbeing.",
    order: 10,
    useFuzzyClustering: true,
    estimatedTime: 25,
    numElements: 25,
    isPrivate: false,
    isNeeded: false,
    summaryView: null,
    permission: "connected",
    summaryDescription: [
      `The Strengths Dimension dives into the core virtues that define your character. This Dimension categorizes 24 strengths—traits that have been shown to contribute to a meaningful and fulfilling life. These strengths highlight the positive qualities that help you thrive, connect with others, and contribute to the world around you.`,
      `Strengths are grouped into key virtues that reflect universal human values, like Wisdom, Courage, Humanity, Justice, Temperance, and Transcendence. Whether your strengths lie in creativity, kindness, leadership, or gratitude, each one represents a unique capacity for positive impact, both in your own life and in the lives of others.`,
      `This Dimension helps you recognize and harness your best qualities. Understanding these strengths can boost your self-awareness, improve your relationships, and guide you toward activities and goals that feel deeply rewarding.`,
    ],
  },
  {
    title: "Conflict Styles",
    url: "/app/assessment/conflict-style",
    icon: <ConflictStylesIcon />,
    iconInactive: <ConflictStylesIcon inactive />,
    event: "Start",
    slug: "conflict-style",
    numOfElements: 2,
    headline: "FINDING MEANING IN WORK",
    description: `Your instincts when dealing with conflict.`,
    longDescription:
      "Conflict Styles measure how you handle disputes and friction in your relationships. There are 5 conflict styles in total: Collaborating, Competitive, Compromising, Avoiding, and Accommodating. Each of us makes use of all of these conflict styles and different times, but we tend to consciously or subconsciously gravitate towards one of them more than the others. Which conflict style we choose should depend on the circumstances of the dispute. Sometimes it's best to avoid conflict. Sometimes it's best to compromise. Sometimes it's best to collaborate.",
    order: 11,
    estimatedTime: 3,
    numElements: 2,
    isPrivate: false,
    isNeeded: false,
    summaryView: null,
    permission: "private",
    summaryDescription: [
      `The Conflict Styles Dimension explores how you handle disagreements, offering insight into your natural approach to resolving tension and finding solutions.`,
      `This Dimension identifies four main styles of dealing with conflict: Competing, Collaborating, Avoiding, and Accommodating.`,
      `Each style represents a different way of balancing your own needs with those of others, whether you tend to assert yourself, seek harmony, or look for middle ground.`,
      `For instance, a Competing style is direct and assertive, focusing on winning or achieving personal goals, while a Collaborating style aims to find solutions that satisfy everyone involved. An Avoiding style involves stepping back from conflict, and an Accommodating style is more about prioritizing others' needs over your own.`,
      `Understanding your Conflict Style can help you navigate challenging situations with greater awareness, recognizing when your approach is effective and when it may be helpful to adapt.`,
      `Each conflict style has its strengths and is useful in different situations. Sometimes a Competing approach is necessary to stand up for important principles, while Collaborating can lead to the best long-term solutions. Avoiding might be wise when emotions are high, and Accommodating helps to maintain harmony in sensitive situations.`,
      `While we all naturally gravitate toward one style, learning to adapt and use different approaches as needed is a hallmark of maturity and emotional intelligence. `,
    ],
  },
  {
    title: "Sex Attitudes",
    url: "/app/assessment/sex-attitudes",
    icon: <SexAttitudesIcon />,
    iconInactive: <SexAttitudesIcon inactive />,
    event: "Start",
    slug: "sex-attitudes",
    numOfElements: 13,
    headline: "HOW YOU SHINE",
    description: `How you psychologically experience desire, connection, and intimacy.`,
    longDescription:
      "TA look into your sexual dynamic with others. All Elements in this Dimension are private.",
    order: 12,
    useFuzzyClustering: true,
    estimatedTime: 6,
    numElements: 13,
    isPrivate: false,
    isNeeded: false,
    summaryView: null,
    permission: "isDeep",
    summaryDescription: [
      `The Sex Attitudes Dimension dives into the psychological underpinnings of your sexuality, exploring the unique ways you experience desire, connection, and intimacy. This Dimension goes beyond surface-level preferences, uncovering the deeper attitudes and values that shape how you relate to yourself and others in intimate moments.`,
      `Our attitudes toward sex are shaped by a mix of personal values, emotional needs, and psychological patterns. Whether you're drawn to emotional closeness or physical exploration, power dynamics or tenderness, each of these elements reflects something meaningful about who you are. This Dimension helps you understand what brings you fulfillment and satisfaction in intimacy, shining a light on the desires that might be hard to articulate.`,
      `Ultimately, the Sex Attitudes Dimension is about self-discovery—seeing your sexuality as a multi-layered part of who you are. This awareness allows you to approach relationships with authenticity and confidence, creating experiences that align with what genuinely feels right for you.`,
    ],
  },
  {
    title: "Political Ideology",
    url: "/app/assessment/political-ideology",
    icon: <PoliticalIdeologyIcon />,
    iconInactive: <PoliticalIdeologyIcon inactive />,
    event: "Start",
    slug: "political-ideology",
    numOfElements: 17,
    headline: "HOW YOU SHINE",
    description: `The core beliefs and values that shape your views on society, governance, and the role of institutions.`,
    longDescription: "A mapping of your ideological perspectives and leanings.",
    order: 13,
    useFuzzyClustering: true,
    estimatedTime: 6,
    numElements: 17,
    isPrivate: false,
    isNeeded: false,
    summaryView: null,
    permission: "isClose",
    summaryDescription: [
      `The Political Ideology Dimension explores the core beliefs and values that shape your views on society, governance, and the role of institutions. This Dimension provides insight into how you see the balance between individual freedom and collective responsibility, the importance of tradition versus progress, and the role of government in ensuring fairness and stability. It's a look at the principles you feel should guide society, touching on economic, social, and cultural issues.`,
      `This Dimension is about understanding the values that drive your vision for society, offering a deeper look at the beliefs and principles that feel most essential to you. By recognizing your political ideology, you can better understand your stance on complex issues, fostering a more grounded and self-aware perspective on the world around you.`,
    ],
  },
  {
    title: "Secondary Traits",
    url: "/app/assessment/secondary-traits",
    icon: <SecondaryTraitsIcon />,
    iconInactive: <SecondaryTraitsIcon inactive />,
    event: "Start",
    slug: "secondary-traits",
    numOfElements: 29,
    headline: "HOW YOU SHINE",
    description: `The aspects of your personality that relate to wellness, personal development, and growth.`,
    longDescription:
      "An even deeper dive into your personality. The traits in this Dimension mostly focus on personal development and happiness.",
    order: 14,
    useFuzzyClustering: true,
    estimatedTime: 20,
    numElements: 29,
    isPrivate: false,
    isNeeded: false,
    summaryView: null,
    permission: "isClose",
    summaryDescription: [
      `The Secondary Traits Dimension captures the subtler aspects of your personality—the qualities that might not be as visible at first glance but play a huge role in shaping your inner world and how you navigate life. More than any other Dimension, this one touches on themes of personal development and wellness, exploring how you relate to yourself, handle challenges, and pursue growth.`,
      `Unlike other Dimensions, which are often based on a single psychological model, the Secondary Traits Dimension draws from a variety of researched theories and models. It combines insights from areas like Maximizing, Mindful Awareness, Growth Mindset, and Self-Compassion, offering a comprehensive look at the psychological factors that influence your well-being. These traits reveal the nuances of your self-perception, thought patterns, and approach to relationships, painting a picture of the "inner workings" that guide your actions and responses.`,
      `From Self-Reflection to Playfulness, from Guilt to Behavioral Autonomy, this Dimension dives into the complex, sometimes contradictory qualities that make you who you are. It's about understanding the forces that shape your decisions, influence your moods, and define how you relate to yourself and others.`,
      `This Dimension gives you the tools to see yourself with depth, acknowledge both your strengths and growth areas, and ultimately create a more balanced, self-aware life.`,
    ],
  },
  {
    title: "Lifestyle",
    url: "/app/assessment/lifestyle",
    icon: <LifeStyleIcon />,
    iconInactive: <LifeStyleIcon inactive />,
    event: "Start",
    slug: "lifestyle",
    numOfElements: 24,
    headline: "HOW YOU SHINE",
    description: `The day-to-day preferences, habits, and values that shape how you live.`,
    longDescription: "What you're into and how you spend your time.",
    order: 9,
    useFuzzyClustering: true,
    estimatedTime: 10,
    numElements: 24,
    isPrivate: false,
    isNeeded: false,
    summaryView: null,
    permission: "connected",
    summaryDescription: [
      `The Lifestyle Dimension captures the day-to-day preferences, habits, and values that shape how you live. This Dimension includes six pairs of opposite traits, highlighting things like what time of day you are most energized, and your psychological relationship with money.`,
      `It also explores unique traits that stand alone, such as Interest in Child Rearing or Conscious Eating. Together, these lifestyle factors offer a well-rounded look at the rhythm of your life and the choices you naturally gravitate toward.`,
      `Each trait represents a preference or value that influences your daily life. For example, being an Outdoorsy person might mean you recharge in nature, while an Urbanite thrives in bustling city environments. Minimalists are often drawn to simplicity and order, while Shoppers may find joy in curating their personal style and staying on top of trends.`,
      `This Dimension doesn't define who you are but instead shows the unique ways you shape your environment to reflect your personal priorities. This Dimension is particularly useful for understanding your lifestyle compatibility with others.`,
    ],
  },
  {
    title: "Coping Styles",
    url: "/app/assessment/coping-style",
    icon: <CopingStyleIcon />,
    iconInactive: <CopingStyleIcon inactive />,
    event: "Start",
    slug: "coping-style",
    numOfElements: 8,
    headline: "HOW YOU SHINE",
    description: `How you respond to stress and difficult situations.`,
    longDescription:
      "How you deal with stressful situations in your life. All Elements in this Dimension are private.",
    order: 14,
    useFuzzyClustering: true,
    estimatedTime: 4,
    numElements: 8,
    isPrivate: true,
    isNeeded: false,
    summaryView: null,
    permission: "private",
    isPremium: true,
    summaryDescription: [
      `The Coping Styles Dimension explores the different ways you respond to stress and navigate challenging situations. Unlike other Dimensions, these styles aren't fixed personality traits but adaptable responses that vary depending on the circumstances. This means your approach to coping might look different in the context of work versus personal relationships, for instance. You can retake this Dimension with specific stressors in mind to see how your responses shift.`,
      `Each coping style reflects a unique strategy for handling pressure, from Accountability Coping, where you take ownership and look for lessons in hardship, to Assertive Coping, where you stand up for your needs and set boundaries. Detachment Coping involves emotionally stepping back to gain perspective, while Escapist Coping offers relief through distractions or pleasurable activities.`,
      `If Plan-Based Coping is your approach, you likely focus on practical steps to resolve issues, while Self-Regulating Coping helps you stay calm and centered by managing your emotions. Silver-Lining Coping finds the positives in tough times, and Social Coping means you lean on friends, family, or colleagues for support.`,
      `This Dimension gives you a flexible toolkit for understanding how you adapt to different forms of stress. By exploring these coping styles, you can become more aware of your default reactions, empowering you to choose the strategies that best serve you in various situations and cultivate resilience that's suited to each unique challenge.`,
    ],
  },
];

type DimensionIconData = {
  icon: React.ReactNode;
  iconInactive: React.ReactNode;
  iconDark: React.ReactNode;
};

export const dimensionIcons: Record<string, DimensionIconData> = {
  personality: {
    icon: <PrimaryTraitsIcon />,
    iconInactive: <PrimaryTraitsIcon inactive />,
    iconDark: <PrimaryTraitsIcon dark />,
  },
  "personality-II": {
    icon: <CognitionIcon />,
    iconInactive: <CognitionIcon inactive />,
    iconDark: <CognitionIcon dark />,
  },
  values: {
    icon: <ValuesIcon />,
    iconInactive: <ValuesIcon inactive />,
    iconDark: <ValuesIcon dark />,
  },
  "love-style": {
    icon: <LoveStylesIcon />,
    iconInactive: <LoveStylesIcon inactive />,
    iconDark: <LoveStylesIcon dark />,
  },
  "love-attitudes": {
    icon: <LoveAttitudesIcon />,
    iconInactive: <LoveAttitudesIcon inactive />,
    iconDark: <LoveAttitudesIcon dark />,
  },
  "attachment-style": {
    icon: <AttachmentStyleIcon />,
    iconInactive: <AttachmentStyleIcon inactive />,
    iconDark: <AttachmentStyleIcon dark />,
  },
  interests: {
    icon: <InterestsIcon />,
    iconInactive: <InterestsIcon inactive />,
    iconDark: <InterestsIcon dark />,
  },
  "communication-style": {
    icon: <WorkStyleIcon />,
    iconInactive: <WorkStyleIcon inactive />,
    iconDark: <WorkStyleIcon dark />,
  },
  strengths: {
    icon: <StrengthsIcon />,
    iconInactive: <StrengthsIcon inactive />,
    iconDark: <StrengthsIcon dark />,
  },
  "conflict-style": {
    icon: <ConflictStylesIcon />,
    iconInactive: <ConflictStylesIcon inactive />,
    iconDark: <ConflictStylesIcon dark />,
  },
  "sex-attitudes": {
    icon: <SexAttitudesIcon />,
    iconInactive: <SexAttitudesIcon inactive />,
    iconDark: <SexAttitudesIcon dark />,
  },
  "political-ideology": {
    icon: <PoliticalIdeologyIcon />,
    iconInactive: <PoliticalIdeologyIcon inactive />,
    iconDark: <PoliticalIdeologyIcon dark />,
  },
  "secondary-traits": {
    icon: <SecondaryTraitsIcon />,
    iconInactive: <SecondaryTraitsIcon inactive />,
    iconDark: <SecondaryTraitsIcon dark />,
  },
  lifestyle: {
    icon: <LifeStyleIcon />,
    iconInactive: <LifeStyleIcon inactive />,
    iconDark: <LifeStyleIcon dark />,
  },
  "coping-style": {
    icon: <CopingStyleIcon />,
    iconInactive: <CopingStyleIcon inactive />,
    iconDark: <CopingStyleIcon dark />,
  },
};

export const archetypeMapper: Record<string, string> = {
  identity: "Cognition",
  "personality-II": "Cognition",
  values: "Values",
  "love-style": "Love",
  love: "Love",
  work: "Interaction",
  career: "Career",
};

export const archetypeGroupNames: Record<string, string> = {
  identity: "Cognition",
  values: "Values",
  love: "Love",
  work: "Interaction",
  career: "Career",
};

export const archetypeGroupNamesReversed: Record<string, string> = {
  Cognition: "identity",
  Values: "values",
  Love: "love",
  Interaction: "work",
  Career: "career",
};

export const archetypeDiscoveryMap = {
  values: ["values"],
  cognition: ["personality-II"],
  identity: ["personality-II"],
  love: ["personality", "love-style", "love-attitudes", "attachment-style"],
  interaction: ["communication-style"],
  career: ["interests"],
};
