import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function PrimaryTraitsIcon(
  props: DimensionIconAssessmentStatus
) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "100%"}
      height={props.maxWidth ? "100%" : "100%"}
      viewBox="0 0 80 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5323 5.61243C33.185 5.61243 32.9034 5.89205 32.9034 6.23698V24.3547L20.0648 11.6049C19.8192 11.361 19.421 11.361 19.1754 11.6049L11.1677 19.5572C10.9221 19.8011 10.9221 20.1966 11.1677 20.4405L24.8963 34.0741H5.57177C5.22444 34.0741 4.94287 34.3537 4.94287 34.6987V45.9449C4.94287 46.2899 5.22444 46.5695 5.57177 46.5695H23.8159L10.9768 59.3198C10.7312 59.5637 10.7312 59.9592 10.9768 60.2031L18.9844 68.1554C19.23 68.3993 19.6282 68.3993 19.8738 68.1554L32.9034 55.2159V73.7123C32.9034 74.0572 33.185 74.3369 33.5323 74.3369H44.8569C45.2042 74.3369 45.4858 74.0572 45.4858 73.7123V54.5212L59.2122 68.1527C59.4578 68.3966 59.856 68.3966 60.1016 68.1527L68.1093 60.2004C68.3549 59.9565 68.3549 59.5611 68.1093 59.3172L55.2728 46.5695H73.5169C73.8643 46.5695 74.1458 46.2899 74.1458 45.9449V34.6987C74.1458 34.3537 73.8643 34.0741 73.5169 34.0741H54.1924L67.9183 20.4431C68.1639 20.1992 68.1639 19.8037 67.9183 19.5598L59.9107 11.6076C59.6651 11.3636 59.2669 11.3636 59.0213 11.6076L45.4858 25.0495V6.23698C45.4858 5.89205 45.2042 5.61243 44.8569 5.61243H33.5323Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
