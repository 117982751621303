import { useSelector } from "react-redux";

import styles from "./StoryCard.module.css";

import ReadMoreStoryIcon from "./ReadMoreStoryIcon";
import { replaceTitle } from "./storiesTextFunctions";
import InvitePill from "@/components/appHome/InvitePill/InvitePill";
import { RootState } from "@/redux/store";

type Props = {
  imageSlug: string;
  title: string;
  color: string;
  showReadMore?: boolean;
  notOwn?: boolean;
  blackedOut?: boolean;
  numSuccessfulInvitesRequired?: number | null | undefined;
  showInvitePill?: boolean;
};

export default function StoryCard(props: Props) {
  const title = replaceTitle(props.title, props.notOwn);

  const inviteLevel = useSelector((state: RootState) => state.user.inviteLevel);

  if (props.showInvitePill) {
    return (
      <div style={{ position: "relative" }}>
        {props.numSuccessfulInvitesRequired && (
          <div className={styles.invitePill}>
            <InvitePill invitesRequired={props.numSuccessfulInvitesRequired} />
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(/stories/${props.imageSlug})`,
            width: "123px",
            height: "123px",
          }}
          className={
            props.blackedOut
              ? `${styles.main} ${styles.blackedOut}`
              : styles.main
          }
        >
          <h1
            style={
              props.blackedOut ? { color: "#fffff2" } : { color: props.color }
            }
          >
            {title}
          </h1>
          {props.showReadMore && (
            <div className={styles.readmoreicon}>
              <ReadMoreStoryIcon />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      {props.numSuccessfulInvitesRequired &&
        inviteLevel < props.numSuccessfulInvitesRequired && (
          <div className={styles.invitePill}>
            <InvitePill invitesRequired={props.numSuccessfulInvitesRequired} />
          </div>
        )}
      <div
        style={{
          backgroundImage: `url(/stories/${props.imageSlug})`,
          width: "123px",
          height: "123px",
        }}
        className={
          props.blackedOut ? `${styles.main} ${styles.blackedOut}` : styles.main
        }
      >
        <h1
          style={
            props.blackedOut ? { color: "#fffff2" } : { color: props.color }
          }
        >
          {title}
        </h1>
        {props.showReadMore && (
          <div className={styles.readmoreicon}>
            <ReadMoreStoryIcon />
          </div>
        )}
      </div>
    </div>
  );
}

type SmallProps = {
  imageSlug: string;
  title: string;
  color: string;
  showReadMore?: boolean;
  notOwn?: boolean;
  blackedOut?: boolean;
  numSuccessfulInvitesRequired?: number | null | undefined;
  showInvitePill?: boolean;
  onClick: () => void;
};

export function StoryCardSmall(props: SmallProps) {
  const title = replaceTitle(props.title, props.notOwn);
  const inviteLevel = useSelector((state: RootState) => state.user.inviteLevel);

  if (props.showInvitePill) {
    return (
      <div style={{ position: "relative" }}>
        {props.numSuccessfulInvitesRequired && (
          <div className={styles.invitePill}>
            <InvitePill invitesRequired={props.numSuccessfulInvitesRequired} />
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(/stories/${props.imageSlug})`,
            width: "123px",
            height: "123px",
          }}
          className={
            props.blackedOut
              ? `${styles.main} ${styles.blackedOut}`
              : styles.main
          }
          onClick={() => {
            props.onClick();
          }}
        >
          <h1
            style={
              props.blackedOut
                ? { color: "#fffff2", marginTop: "20px" }
                : { color: props.color, marginTop: "20px" }
            }
          >
            {title}
          </h1>
          {props.showReadMore && (
            <div className={styles.readmoreicon}>
              <ReadMoreStoryIcon />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      {props.numSuccessfulInvitesRequired &&
        inviteLevel < props.numSuccessfulInvitesRequired && (
          <div className={styles.invitePill}>
            <InvitePill invitesRequired={props.numSuccessfulInvitesRequired} />
          </div>
        )}
      <div
        style={{
          backgroundImage: `url(/stories/${props.imageSlug})`,
          width: "123px",
          height: "123px",
        }}
        className={
          props.blackedOut ? `${styles.main} ${styles.blackedOut}` : styles.main
        }
        onClick={() => {
          props.onClick();
        }}
      >
        <h1
          style={
            props.blackedOut
              ? { color: "#fffff2", marginTop: "20px" }
              : { color: props.color, marginTop: "20px" }
          }
        >
          {title}
        </h1>
        {props.showReadMore && (
          <div className={styles.readmoreicon}>
            <ReadMoreStoryIcon />
          </div>
        )}
      </div>
    </div>
  );
}
