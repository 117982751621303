import { DimensionIconAssessmentStatus } from "@/models/sharedModels";

export default function LifeStyleIcon(props: DimensionIconAssessmentStatus) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ padding: "2px" }}
    >
      <path
        d="M90 0C78.0642 0 66.618 4.74255 58.1805 13.1805C49.743 21.6184 45 33.0651 45 45C45 33.0642 40.2575 21.618 31.8195 13.1805C23.3816 4.743 11.9349 0 0 0V45C0 61.0767 8.57808 75.9339 22.5 83.9709C36.4221 92.0111 53.5779 92.0111 67.5 83.9709C81.4221 75.9342 90 61.0767 90 45V0Z"
        fill={props.dark ? "#2D2927" : "#FFFFF2"}
        style={props?.inactive ? { opacity: 0.5 } : {}}
      />
    </svg>
  );
}
