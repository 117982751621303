import {
  ContentType,
  DiscoveredCompatibilitySnippet,
} from "@dimensional-engineering/dimensional-models";

import styles from "../CompatibilitySnippets.module.css";

import { FriendsPublicProfile } from "@/models/sharedModels";

import Spectrums from "@/components/shared/Spectrum/Spectrums";

type Props = {
  snippet: DiscoveredCompatibilitySnippet | null;
  connection: FriendsPublicProfile | null;
};

export default function CompatibilitySpectrum(props: Props) {
  return (
    <div className={styles.spectrumsParent}>
      {props.snippet?.sectionSubheadings && (
        <div className={styles.spectrumsSubHeadingsDiv}>
          <h1 className={styles.spectrumsSubHeading}>
            {props.snippet?.sectionSubheadings[0].subheading}
          </h1>
          <h2 className={styles.spectrumsDescription}>
            {props.snippet?.sectionSubheadings[0].subheadingDescription}
          </h2>
        </div>
      )}
      <CompatibilitySpectrumChart
        connection={props.connection}
        spectrumSlug={props.snippet?.snippetSlug ?? ""}
        contentType={ContentType.compatibilitySnippet}
      />
    </div>
  );
}

export function CompatibilitySpectrumChart(props: {
  connection: FriendsPublicProfile | null;
  spectrumSlug: string;
  contentType: ContentType;
}) {
  return (
    <>
      <Spectrums
        contentIdentifier={{
          contentType: props.contentType,
          contentSlug: props.spectrumSlug,
        }}
        otherPublicProfile={props.connection}
        imageUrl={props.connection?.imageUrl}
      />
    </>
  );
}
