import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PublicProfile } from "@dimensional-engineering/dimensional-models";

import styles from "./PatternTraitsPageLeftBar.module.css";

import { ExtendedPattern } from "@/models/sharedModels";
import { RootState } from "@/redux/store";
import TraitScoreBar from "../../shared/TraitScoreBar/TraitScoreBar";
import { getUserPatternScore, traitPermissionStatus } from "../../utils";
import useConnectionStatus from "@/helpers/useConnectionStatus";
import TraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/TraitAccessDeniedBar";
import TraitDescription from "../../shared/TraitScoreTable/TraitDescription";
import useGetIsPremium from "@/helpers/useGetIsPremium";
import PremiumTraitAccessDeniedBar from "@/components/premium/PremiumTraitAccessDeniedBar/PremiumTraitAccessDeniedBar";
import PrivateTraitAccessDeniedBar from "../../shared/TraitAccessDeniedBar/PrivateTraitAccessDeniedBar";
import LargePatternModal from "../Pattern/largePattern/LargePatternModal";
import { AvailableToComponentAvailibility } from "@/components/shared/AvailableToComponent/AvailableToComponent";

type LeftBarProps = {
  pattern: ExtendedPattern;
  nonOwnProfile?: boolean;
  friendScore?: number | null | undefined;
  publicProfile?: PublicProfile | null;
};

export default function PatternTraitsPageLeftBar(props: LeftBarProps) {
  const [score, setScore] = useState<number | null>();
  const userState = useSelector((state: RootState) => state.user);
  const friendStatus = useConnectionStatus(props.publicProfile?.ownerUUID);
  const isUserPremium = useGetIsPremium(
    props.nonOwnProfile
      ? props.publicProfile?.ownerUUID
      : userState.publicProfileData?.ownerUUID
  );

  useEffect(() => {
    if (props.nonOwnProfile) {
      setScore(getUserPatternScore(props.friendScore, props.pattern.slug));
    } else {
      userState.patternsDiscovered?.forEach((patt) => {
        if (patt.patternSlug === props.pattern.slug) {
          setScore(getUserPatternScore(patt.percentScore, patt.patternSlug));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nonOwnProfile, props.friendScore]);

  if (!props.nonOwnProfile) {
    // own profile
    return (
      <div className={styles.leftBarModal}>
        <LargePatternModal pattern={props.pattern} />
        <div className={styles.defDivModal}>
          <p>{props.pattern.shortDescription}</p>
        </div>
        {props.pattern.isPremium && !isUserPremium ? (
          <div className={styles.premiumDeniedDiv}>
            <PremiumTraitAccessDeniedBar />
          </div>
        ) : (
          <div className={styles.scoreDiv}>
            <TraitScoreBar score={score ? score * 100 : null} />
          </div>
        )}
        <TraitDescription
          type="Pattern"
          childElementCount={props.pattern.traitContent.traitDetails?.length}
        />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.pattern.connectionAvailabilities}
          />
        </div>
      </div>
    );
  } else {
    ///connected profile
    const status = traitPermissionStatus(
      friendStatus,
      props.pattern.connectionAvailabilities
    );
    return (
      <div className={styles.leftBarModal}>
        <LargePatternModal pattern={props.pattern} />
        <div className={styles.defDivModal}>
          <p>{props.pattern.shortDescription}</p>
        </div>
        <div className={styles.scoreDiv}>
          {status === "private" ? (
            <PrivateTraitAccessDeniedBar />
          ) : props.pattern.isPremium && !isUserPremium ? (
            <div className={styles.premiumDeniedDiv}>
              <PremiumTraitAccessDeniedBar
                notOwn
                traitName={props.pattern.name}
              />
            </div>
          ) : status === null ? (
            <TraitScoreBar
              friendPublicProfile={props.publicProfile}
              nonOwner
              score={score ? score * 100 : null}
            />
          ) : (
            <TraitAccessDeniedBar
              publicProfile={props.publicProfile}
              connectionTypeNeeded={status}
            />
          )}
        </div>
        <TraitDescription
          type="Pattern"
          childElementCount={props.pattern.traitContent.traitDetails?.length}
        />
        <div className={styles.availability}>
          <AvailableToComponentAvailibility
            traits
            connectionAvailability={props.pattern.connectionAvailabilities}
          />
        </div>
      </div>
    );
  }
}
