import {
  ArchetypeDiscovery,
  ContentType,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Dichotomies.module.css";

import ArchetypeBreakdownHeading from "../../Heading/ArchetypeBreakdownHeading";
import Spectrums from "@/components/shared/Spectrum/Spectrums";

export default function DichotomiesModal(props: {
  archetypeDiscovery: ArchetypeDiscovery | undefined;
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  return (
    <div className={styles.parentModal}>
      <ArchetypeBreakdownHeading publicProfile={props.publicProfile} />
      <Spectrums
        contentIdentifier={{
          contentType: ContentType.archetype,
          contentSlug: props.archetypeDiscovery?.archetypeSlug ?? "",
        }}
        otherPublicProfile={props.publicProfile}
        imageUrl={props.imageUrl}
        noMargin
      />
    </div>
  );
}
