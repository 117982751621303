import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import { Roboto_Mono } from "next/font/google";

import styles from "./Snippets.module.css";

import { peerRatedSnippets, snippetMappedSubtitles } from "./snippetsMap";
import { ExtendedScale } from "@/models/sharedModels";
import { getElementFromTraitIdentifier } from "@/components/authenticatedProfile/tabs/AuthCompareTab/utils";
import { natureData } from "@/assets/traitInfo/natureData";
import { useModalContext } from "@/context/ModalContext";
import ElementModal from "@/components/traits/TraitsModals/Element/ElementModal";

const font = Roboto_Mono({ subsets: ["latin"] });

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetStrengthsPeerRated(props: Props) {
  const { setModalComponent } = useModalContext();

  const myTop =
    props.snippet.snippetDetail.topPeerRatedTraitsDetail?.myTopTraitIdentifiers.map(
      (traitIdentifier) => {
        const element = getElementFromTraitIdentifier(
          traitIdentifier
        ) as ExtendedScale;

        const backgroundColor = natureData?.find(
          (n) => n.slug == element.nature
        )?.color;

        return (
          <div
            style={
              backgroundColor
                ? { border: `2px solid ${backgroundColor}` }
                : { border: `2px solid grey` }
            }
            key={element.slug}
            className={styles.traitTop}
            onClick={() =>
              setModalComponent(<ElementModal slug={element.slug} />)
            }
          >
            <p className={styles.traitName}>{element.alias ?? element.name}</p>
          </div>
        );
      }
    );

  const peerTop =
    props.snippet.snippetDetail.topPeerRatedTraitsDetail?.topPeerRatedTraitIdentifiers.map(
      (traitIdentifier) => {
        const element = getElementFromTraitIdentifier(
          traitIdentifier
        ) as ExtendedScale;

        const backgroundColor = natureData?.find(
          (n) => n.slug == element.nature
        )?.color;

        return (
          <div
            style={
              backgroundColor
                ? { border: `2px solid ${backgroundColor}` }
                : { border: `2px solid grey` }
            }
            key={element.slug}
            className={styles.traitTop}
            onClick={() =>
              setModalComponent(<ElementModal slug={element.slug} />)
            }
          >
            <p className={styles.traitName}>{element.alias ?? element.name}</p>
          </div>
        );
      }
    );

  const mySecondary =
    props.snippet.snippetDetail.topPeerRatedTraitsDetail?.myOtherTraitIdentifiers.map(
      (traitIdentifier) => {
        const element = getElementFromTraitIdentifier(
          traitIdentifier
        ) as ExtendedScale;

        const backgroundColor = natureData?.find(
          (n) => n.slug == element.nature
        )?.color;

        return (
          <div
            style={
              backgroundColor
                ? { border: `2px solid ${backgroundColor}` }
                : { border: `2px solid grey` }
            }
            key={element.slug}
            className={styles.traitTop}
            onClick={() =>
              setModalComponent(<ElementModal slug={element.slug} />)
            }
          >
            <p className={styles.traitNameSecondary}>
              {element.alias ?? element.name}
            </p>
          </div>
        );
      }
    );

  const peerSecondary =
    props.snippet.snippetDetail.topPeerRatedTraitsDetail?.otherPeerRatedTraitIdentifiers.map(
      (traitIdentifier) => {
        const element = getElementFromTraitIdentifier(
          traitIdentifier
        ) as ExtendedScale;

        const backgroundColor = natureData?.find(
          (n) => n.slug == element.nature
        )?.color;

        return (
          <div
            style={
              backgroundColor
                ? { border: `2px solid ${backgroundColor}` }
                : { border: `2px solid grey` }
            }
            key={element.slug}
            className={styles.traitTop}
            onClick={() =>
              setModalComponent(<ElementModal slug={element.slug} />)
            }
          >
            <p className={styles.traitNameSecondary}>
              {element.alias ?? element.name}
            </p>
          </div>
        );
      }
    );

  return (
    <>
      {props.snippet.snippetDetail.topPeerRatedTraitsDetail && (
        <>
          <h2
            className={
              peerRatedSnippets.includes(props.snippet?.snippetSlug)
                ? styles.llmListNamePeer
                : styles.llmListName
            }
          >
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={styles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <h4 className={styles.topStrenthsText}>Top Strengths</h4>
          <div className={styles.accordingTextDiv}>
            <h5 className={`${font.className} ${styles.accordingText}`}>
              ACCORDING TO PEERS
            </h5>
            <h5 className={`${font.className} ${styles.accordingText}`}>
              ACCORDING TO YOU
            </h5>
          </div>
          <div className={styles.mappedSectionsDiv}>
            <div className={styles.topStrengthsMappedDiv}>{peerTop}</div>
            <div
              style={{ alignItems: "flex-end" }}
              className={styles.topStrengthsMappedDiv}
            >
              {myTop}
            </div>
          </div>
          <h4 className={styles.topStrenthsText}>Secondary Strengths</h4>
          <div className={styles.accordingTextDiv}>
            <h5 className={`${font.className} ${styles.accordingText}`}>
              ACCORDING TO PEERS
            </h5>
            <h5 className={`${font.className} ${styles.accordingText}`}>
              ACCORDING TO YOU
            </h5>
          </div>
          <div className={styles.mappedSectionsDiv}>
            <div className={styles.topStrengthsMappedDiv}>{peerSecondary}</div>
            <div
              style={{ alignItems: "flex-end" }}
              className={styles.topStrengthsMappedDiv}
            >
              {mySecondary}
            </div>
          </div>
        </>
      )}
    </>
  );
}
