import { useEffect, useState } from "react";

import patternJsonData from "../../../../assets/traitInfo/patterns.json";
import { ExtendedPattern } from "@/models/sharedModels";

export default function useGetPatternData(slug: string) {
  const [pattern, setPattern] = useState<ExtendedPattern | null>(null);

  useEffect(() => {
    patternJsonData.forEach((p) => {
      if (p.slug === slug) {
        setPattern(p as unknown as ExtendedPattern);
      }
    });
  }, [slug]);

  return pattern;
}
