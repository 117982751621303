import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

export default function useGetDiscoveredStoriesSlugsHook() {
  const [discoveredSlugs, setDiscoveredSlugs] = useState<string[]>([]);
  const discoveredStories = useSelector(
    (state: RootState) => state.user.discoveredStories
  );

  useEffect(() => {
    setDiscoveredSlugs([]);
    discoveredStories?.forEach((story) => {
      if (story.storyDetail) {
        setDiscoveredSlugs((current) => [...current, story.storySlug]);
      }
    });
  }, [discoveredStories]);

  return discoveredSlugs;
}
