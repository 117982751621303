import { DimensionDataType } from "@/assets/traitInfo/dimensionData";
import { RankOrderItemResponseWithStep } from "@/components/assessments-new/views/RankListAndRegularItemAssessmentView/RankListAndRegularItemAssessmentView";
import {
  Item,
  ItemResponse,
  RankOrderItemResponse,
  RankOrderItemSet,
  RankOrderItemSetResponse,
} from "@dimensional-engineering/dimensional-models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AssessmentSlice {
  slug: string | null;
  dimension: DimensionDataType | null;
  inTutorial: boolean;
  tutorialType: "element" | "pattern";
  items: Item[] | [];
  itemResponses: ItemResponse[] | [];
  itemStartIndex: number;
  rankOrderItemSet: RankOrderItemSet | undefined;
  rankOrderItemSetResponse: RankOrderItemSetResponse | undefined;
  rankOrderResponses: RankOrderItemResponseWithStep[] | [];
}

const initialState: AssessmentSlice = {
  slug: null,
  dimension: null,
  inTutorial: false,
  tutorialType: "element",
  items: [],
  itemResponses: [],
  itemStartIndex: 0,
  rankOrderItemSet: undefined,
  rankOrderItemSetResponse: undefined,
  rankOrderResponses: [],
};

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {
    setAssessmentSlug: (state, action: PayloadAction<string>) => {
      return { ...state, slug: action.payload };
    },
    setDimension: (state, action: PayloadAction<DimensionDataType>) => {
      return { ...state, dimension: action.payload };
    },
    setInTutorial: (state, action: PayloadAction<boolean>) => {
      return { ...state, inTutorial: action.payload };
    },
    setTutorialType: (state, action: PayloadAction<"element" | "pattern">) => {
      return { ...state, tutorialType: action.payload };
    },
    setItems: (state, action: PayloadAction<Item[]>) => {
      return { ...state, items: action.payload };
    },
    addItem: (state, action: PayloadAction<Item>) => {
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    },
    setNonAnsweredItems: (state, action: PayloadAction<Item[]>) => {
      return { ...state, nonAnsweredItems: action.payload };
    },
    setItemResponses: (state, action: PayloadAction<ItemResponse[]>) => {
      return { ...state, itemResponses: action.payload };
    },
    addItemResponse: (state, action: PayloadAction<ItemResponse>) => {
      return {
        ...state,
        itemResponses: [...state.itemResponses, action.payload],
        itemStartIndex: state.itemStartIndex + 1,
      };
    },
    removeLastItemResponse: (state) => {
      if (state.itemResponses.length === 0) {
        return state;
      }
      const updatedItemResponses = state.itemResponses.slice(0, -1);
      return {
        ...state,
        itemResponses: updatedItemResponses,
        itemStartIndex: state.itemStartIndex - 1,
      };
    },
    setItemStartIndex: (state, action: PayloadAction<number>) => {
      return { ...state, itemStartIndex: action.payload };
    },
    setRankOrderItemSet: (
      state,
      action: PayloadAction<RankOrderItemSet | undefined>
    ) => {
      return { ...state, rankOrderItemSet: action.payload };
    },
    setRankOrderItemSetResponse: (
      state,
      action: PayloadAction<RankOrderItemSetResponse | undefined>
    ) => {
      return { ...state, rankOrderItemSetResponse: action.payload };
    },
    setRankOrderResponses: (
      state,
      action: PayloadAction<RankOrderItemResponseWithStep[]>
    ) => {
      return { ...state, rankOrderResponses: action.payload };
    },
    addRankOrderResponse: (
      state,
      action: PayloadAction<RankOrderItemResponseWithStep>
    ) => {
      return {
        ...state,
        rankOrderResponses: [...state.rankOrderResponses, action.payload],
      };
    },
    removeRankOrderResponseByIndex: (state, action: PayloadAction<number>) => {
      const indexToRemove = action.payload;

      if (
        indexToRemove >= 0 &&
        indexToRemove < state.rankOrderResponses.length
      ) {
        const updatedRankOrderResponses = state.rankOrderResponses.filter(
          (_, index) => index !== indexToRemove
        );

        return {
          ...state,
          rankOrderResponses: updatedRankOrderResponses,
        };
      }
      return state;
    },
    resetAssessmentState: (state) => {
      return initialState;
    },
  },
});

export const {
  setAssessmentSlug,
  setDimension,
  setInTutorial,
  setTutorialType,
  setItems,
  addItem,
  setItemResponses,
  addItemResponse,
  removeLastItemResponse,
  setRankOrderItemSet,
  setRankOrderItemSetResponse,
  setRankOrderResponses,
  resetAssessmentState,
  setItemStartIndex,
  addRankOrderResponse,
  removeRankOrderResponseByIndex,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
