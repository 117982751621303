import { TabIconModel } from "@/models/sharedModels";

export default function TabHomeIcon(props: TabIconModel) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99549 0.201487C9.3414 -0.0671624 9.82657 -0.0671624 10.1725 0.201487L18.7978 6.91037C19.0314 7.09186 19.168 7.37128 19.168 7.66689V20.126C19.168 20.6552 18.7385 21.0838 18.2093 21.0838H12.4591C11.9299 21.0838 11.5004 20.6552 11.5004 20.126V15.3335H7.66756V20.126C7.66756 20.6552 7.23808 21.0838 6.70888 21.0838H0.958675C0.429471 21.0838 0 20.6552 0 20.126V7.66689C0 7.37128 0.136567 7.09187 0.370175 6.91037L8.99549 0.201487ZM1.91744 8.13585V19.1673H5.7503V14.3757C5.7503 13.8465 6.17977 13.4171 6.70897 13.4171H12.4592C12.9884 13.4171 13.4179 13.8465 13.4179 14.3757V19.1673H17.2507V8.13585L9.58408 2.17266L1.91744 8.13585Z"
        fill={props.active ? "#FFFFF2" : "#928E8C"}
      />
    </svg>
  );
}
