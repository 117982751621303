export default function LightningStrikeIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5215 36.0939L19.9502 24.7861H10.0928C9.24961 24.7861 8.6999 23.8438 9.17661 23.0899L20.4629 3.97862C21.0856 2.92293 22.6245 3.48831 22.4785 4.69418L21.0498 16.002H30.9072C31.7504 16.002 32.3001 16.9443 31.8234 17.6981L20.5371 36.8479C19.913 37.8653 18.3741 37.2997 18.5215 36.0939Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
