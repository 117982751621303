import { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./RequestFeedbackModal.module.css";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { RootState } from "@/redux/store";
import SmallProfileImage from "@/components/shared/SmallProfileImage/SmallProfileImage";
import MagnifyingGlassIcon from "@/components/shared/icons/MagnifyingGlassIcon";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { Mixpanel } from "@/helpers/mixpanel";

export default function RequestFeedbackModal() {
  const connectionState = useSelector((state: RootState) => state.connections);
  const userState = useSelector((state: RootState) => state.user);

  const [search, setSearch] = useState<string>("");
  const [requestedUidList, setRequestedUidList] = useState<string[]>([]);

  const mappedConnections = connectionState?.publicProfiles
    ?.filter(
      (p) =>
        p.userName?.toLowerCase().includes(search.toLowerCase()) ||
        p.name?.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) => {
      const isCompleteA = userState.memberProfile?.peerAssesseeUids?.includes(
        a.ownerUUID
      );
      const isCompleteB = userState.memberProfile?.peerAssesseeUids?.includes(
        b.ownerUUID
      );

      if (isCompleteA && !isCompleteB) {
        return 1;
      }
      if (isCompleteB && !isCompleteA) {
        return -1;
      }
      return 0;
    })
    .map((profile) => {
      const isComplete = userState.memberProfile?.peerAssesseeUids?.includes(
        profile.ownerUUID
      );
      const isSelected = requestedUidList.includes(profile.ownerUUID);
      return (
        <div className={styles.profileDiv} key={profile.ownerUUID}>
          <SmallProfileImage
            medium
            publicProfile={profile}
            imageUrl={profile.imageUrl}
          />
          <div className={styles.nameDiv}>
            <p className={styles.name}>{profile.name}</p>
            <p className={styles.username}>@{profile.userName}</p>
          </div>
          {isComplete ? (
            <button className={styles.button} disabled>
              Completed
            </button>
          ) : (
            <button
              className={styles.button}
              onClick={() => {
                Mixpanel?.track("Peer Assessment Request Sent", {
                  type: "internal invite",
                });
                const call = callFirebaseFunctions("requestPeerAssessor");
                setRequestedUidList((current) => [
                  ...current,
                  profile.ownerUUID,
                ]);
                call({ connectionUid: profile.ownerUUID })
                  .then(() => {})
                  .catch((err) => {
                    setRequestedUidList((current) => [
                      ...current.filter((uid) => uid !== profile.ownerUUID),
                    ]);
                  });
              }}
              disabled={isSelected}
            >
              {isSelected ? "Sent" : "Request"}
            </button>
          )}
        </div>
      );
    });

  return (
    <ModalWrapper backgroundColor="#2D2927" title="Request feedback">
      <main className={styles.main}>
        <div className={styles.inputDiv}>
          <input
            className={styles.input}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          />
          <div className={styles.searchIconDiv}>
            <MagnifyingGlassIcon dark />
          </div>
        </div>

        <div className={styles.profileWrapper}>{mappedConnections}</div>
      </main>
    </ModalWrapper>
  );
}
