/* eslint-disable @next/next/no-img-element */
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import styles from "./MobileSearchPage.module.css";
import { useAuth } from "@/context/AuthContext";
import { summonProfileLink } from "@/components/shared/utils";

export default function SearchShareSummoner() {
  const colorMap = ProfileColorMap;
  const userState = useSelector((state: RootState) => state.user);
  const { user } = useAuth();
  return (
    <div
      className={styles.shareDivParent}
      onClick={async () => {
        await summonProfileLink(
          user?.uid,
          "global search",
          "Invite to Dimensional"
        );
      }}
    >
      <div className={styles.userImage}>
        <img
          style={
            userState?.publicProfileData?.primaryNature?.nature
              ? {
                  border: `3px solid ${
                    colorMap[
                      userState?.publicProfileData?.primaryNature?.nature
                    ]
                  }`,
                  marginLeft: "10px",
                }
              : {
                  border: `3px solid #55504F`,
                  marginLeft: "10px",
                }
          }
          width={50}
          height={50}
          alt=""
          src={userState?.imageURL as string}
          className={styles.smallImage}
        />
      </div>
      <div className={styles.shareDescDiv}>
        <h5 className={styles.invite}>Invite friends to Dimensional</h5>
        <h6
          className={styles.linkText}
        >{`dimensional.me/${userState.publicProfileData?.userName}`}</h6>
      </div>
      <svg
        className={styles.shareSvg}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M11.917 3.59212V6.87496H17.4214C18.4311 6.87496 19.2501 7.69482 19.2501 8.70286V19.7141C19.2501 20.723 18.432 21.542 17.4214 21.542H4.57887C3.56912 21.542 2.75012 20.7221 2.75012 19.7141V8.70286C2.75012 7.6931 3.56826 6.87496 4.57887 6.87496H10.0833V3.58948L9.04859 4.62417C8.69367 4.97909 8.11788 4.97909 7.75781 4.61815C7.39945 4.26065 7.40202 3.678 7.75179 3.32823L10.3566 0.723426C10.5328 0.547254 10.7639 0.458744 10.996 0.457886L11.0028 0.461323C11.2357 0.461323 11.4669 0.54898 11.6413 0.723431L14.2453 3.32823C14.6002 3.68316 14.6002 4.25808 14.2401 4.61816C13.8817 4.97651 13.2991 4.97394 12.9493 4.62417L11.917 3.59212ZM10.0831 8.708H4.58307V19.708H17.417V8.708H11.917V12.3767C11.917 12.882 11.5096 13.292 11 13.292C10.4939 13.292 10.0831 12.8812 10.0831 12.3767V8.708ZM7.33307 6.87496V8.708H14.667V6.87496H7.33307ZM10.0831 6.87496H11.917V8.708H10.0831V6.87496Z"
          fill="#FFFFF2"
        />
      </svg>
    </div>
  );
}
