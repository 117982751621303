import {
  DiscoverableSnippet,
  DiscoveredSnippet,
} from "@dimensional-engineering/dimensional-models";
import Image from "next/image";
import { useSelector } from "react-redux";

import styles from "../personalityAnalysis/AnalysisTwoByTwo/AnalysisTwoByTwo.module.css";
import snippetStyles from "./Snippets.module.css";

import { RootState } from "@/redux/store";
import { ProfileColorMap } from "@/components/shared/ColorMap";
import { PeerRatedScorePill } from "@/components/shared/Spectrum/Spectrums";
import { peerRatedSnippets, snippetMappedSubtitles } from "./snippetsMap";

type Props = {
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
};

export default function SnippetAggregatePeerTwoByTwoView(props: Props) {
  const userState = useSelector((state: RootState) => state.user);
  return (
    <>
      {props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail && (
        <>
          <h2
            className={
              peerRatedSnippets.includes(props.snippet?.snippetSlug)
                ? snippetStyles.llmListNamePeer
                : snippetStyles.llmListName
            }
          >
            {props.discoverableSnippet.name}
          </h2>
          <h3 className={snippetStyles.llmListSubtitle}>
            {
              snippetMappedSubtitles[
                props.snippet
                  ?.snippetSlug as keyof typeof snippetMappedSubtitles
              ]
            }
          </h3>
          <div style={{ marginTop: "50px" }} className={styles.main}>
            <p className={styles.quadrantOnePeer}>
              {
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                  .quadrantData[0]
              }
            </p>
            <p className={styles.quadrantTwoPeer}>
              {
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                  .quadrantData[1]
              }
            </p>
            <p className={styles.quadrantThreePeer}>
              {
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                  .quadrantData[2]
              }
            </p>
            <p className={styles.quadrantFourPeer}>
              {
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                  .quadrantData[3]
              }
            </p>
            <p style={{ alignSelf: "center" }} className={styles.label}>
              {
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail.yAxis
                  .highDescriptor
              }
            </p>
            <div className={styles.rowTop}>
              <div className={styles.leftTop}>
                <p className={styles.label}>
                  {
                    props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                      .xAxis.lowDescriptor
                  }
                </p>
              </div>
              <div className={styles.RightTop}>
                <p className={styles.label}>
                  {
                    props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                      .xAxis.highDescriptor
                  }
                </p>
              </div>
            </div>
            <div className={styles.rowBottom}>
              <div className={styles.leftBottom}></div>
              <div className={styles.rightBottom}></div>
            </div>
            <p style={{ alignSelf: "center" }} className={styles.label}>
              {
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail.yAxis
                  .lowDescriptor
              }
            </p>
            <div style={{ top: "35%" }} className={styles.cicle}></div>
            <div
              style={
                userState.publicProfileData?.primaryNature?.nature
                  ? {
                      border: `3px solid ${
                        ProfileColorMap[
                          userState?.publicProfileData.primaryNature?.nature
                        ]
                      }`,
                      left: `calc(${
                        props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                          .myXAxisScore * 100
                      }% - 26px)`,
                      bottom: `calc(${
                        props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                          .myXAxisScore * 100
                      }% - 26px)`,
                    }
                  : {
                      border: `3px solid #55504F`,
                      left: `calc(${
                        props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                          .myXAxisScore * 100
                      }% - 26px)`,
                      bottom: `calc(${
                        props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                          .myXAxisScore * 100
                      }% - 26px)`,
                    }
              }
              className={styles.imageDiv}
            >
              <Image
                priority
                src={
                  userState.imageURL ? userState.imageURL : "/default-image.png"
                }
                alt="Profile Image"
                fill
                style={{ borderRadius: "50%", border: "1px solid black" }}
              />
            </div>
            <div
              style={
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                  .myAggregatePeerXAxisScore &&
                props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                  .myAggregatePeerYAxisScore
                  ? {
                      left: `calc(${
                        props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                          .myAggregatePeerXAxisScore * 100
                      }% - 15px)`,
                      bottom: `calc(${
                        props.snippet.snippetDetail.aggregatePeerTwoByTwoDetail
                          .myAggregatePeerYAxisScore * 100
                      }% - 15px)`,
                    }
                  : { display: "none" }
              }
              className={styles.peerPillImage}
            >
              <PeerRatedScorePill />
            </div>
          </div>

          <div className={snippetStyles.spectrumLegend}>
            <div className={snippetStyles.peerRatedImage}>
              <PeerRatedScorePill />
            </div>
            <p className={snippetStyles.legendText}>Peer-rated scores</p>
          </div>
        </>
      )}
    </>
  );
}
