import { TabIconModel } from "@/models/sharedModels";

export default function WarningIcon(props: TabIconModel) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3389 18.9525L14.9681 1.14135H14.9669C14.1402 -0.361896 11.9603 -0.361896 11.0588 1.14135L0.68797 18.9525C-0.213686 20.4559 0.913845 22.335 2.64201 22.335H23.3849C25.189 22.335 26.2405 20.4558 25.3389 18.9525ZM12.7128 2.04268C12.8637 1.8168 13.2395 1.8168 13.3895 2.04268H13.3907L23.7615 19.9288C23.9115 20.1547 23.6866 20.5305 23.3857 20.5305H2.64286C2.34201 20.5305 2.19208 20.1547 2.34201 19.9288L12.7128 2.04268Z"
        fill={props.active ? "#FFFFF2" : "#2D2927"}
      />
      <path
        d="M12.5629 6.62646H13.4644C13.7653 6.62646 13.9902 6.70143 14.2161 6.92731C14.4419 7.15319 14.5169 7.3781 14.5169 7.67895L14.0661 14.5176C14.0661 15.0433 13.6153 15.4941 13.0896 15.4941H12.9397C12.4139 15.4941 12.0381 15.0433 11.9631 14.5176L11.5873 7.67895C11.5124 7.3781 11.6623 7.15321 11.8132 6.92731C12.0391 6.70143 12.264 6.62646 12.5648 6.62646L12.5629 6.62646Z"
        fill={props.active ? "#FFFFF2" : "#2D2927"}
      />
      <path
        d="M14.4415 17.6741C14.4415 18.4632 13.8013 19.1024 13.0132 19.1024C12.2251 19.1024 11.585 18.4632 11.585 17.6741C11.585 16.886 12.2251 16.2468 13.0132 16.2468C13.8013 16.2468 14.4415 16.886 14.4415 17.6741Z"
        fill={props.active ? "#FFFFF2" : "#2D2927"}
      />
    </svg>
  );
}
