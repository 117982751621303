import {
  DynamicProfileResultData,
  RomanticCompatibilityReport,
  WorkCompatibilityReport,
} from "@/models/sharedModels";
import {
  ConnectionLlmRequest,
  DiscoverableCompatibilitySnippet,
  DiscoveredCompatibilitySnippet,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CompatiblitySnippetSlice {
  discoverableCompatibilitySnippets: DiscoverableCompatibilitySnippet[];
  discoveredCompatibilitySnippets: DiscoveredCompatibilitySnippet[];
  discoveredSlugs: string[];
  dimensionComplete: string[];
  llmRequests: ConnectionLlmRequest[];
  romanticCompatibilityReport?: RomanticCompatibilityReport | null;
  workCompatibilityReport?: WorkCompatibilityReport | null;
  friendStatus:
    | "connected"
    | "close"
    | "deep"
    | "sent"
    | "unconnected"
    | "received"
    | null;
  publicProfile: PublicProfile | null;
  profileData: DynamicProfileResultData | null;
}

const initialState: CompatiblitySnippetSlice = {
  discoverableCompatibilitySnippets: [],
  discoveredCompatibilitySnippets: [],
  discoveredSlugs: [],
  dimensionComplete: [],
  llmRequests: [],
  romanticCompatibilityReport: null,
  workCompatibilityReport: null,
  friendStatus: null,
  publicProfile: null,
  profileData: null,
};

export const compatibilitySnippetsSlice = createSlice({
  name: "compatibilitySnippets",
  initialState: initialState,
  reducers: {
    setcompatibilitySnippetsData: (
      state,
      action: PayloadAction<CompatiblitySnippetSlice>
    ) => {
      return { ...action.payload };
    },
    setDiscoverableCompatibilitySnippets: (
      state,
      action: PayloadAction<DiscoverableCompatibilitySnippet[]>
    ) => {
      return { ...state, discoverableCompatibilitySnippets: action.payload };
    },
    resetCompatibilitySnippetsState: (state) => {
      return {
        ...initialState,
        discoverableCompatibilitySnippets:
          state.discoverableCompatibilitySnippets,
      };
    },
  },
});

export const {
  setcompatibilitySnippetsData,
  resetCompatibilitySnippetsState,
  setDiscoverableCompatibilitySnippets,
} = compatibilitySnippetsSlice.actions;

export default compatibilitySnippetsSlice.reducer;
