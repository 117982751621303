import { useEffect, useState } from "react";

import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetElementModalData from "./useGetElementModalData";
import Loader from "@/components/shared/Loader";
import { useAuth } from "@/context/AuthContext";
import useGetFriendStatus from "@/helpers/useGetFriendStatus";
import OwnElementModal from "./OwnElementModal";
import FriendElementModal from "./FriendElementModal";
import { Mixpanel } from "@/helpers/mixpanel";

export default function ElementModal(props: { slug: string; uid?: string }) {
  const { element } = useGetElementModalData(props.slug);
  const { user } = useAuth();
  const [ownProfile, setOwnProfile] = useState<boolean>(true);
  const isFriend = useGetFriendStatus(props.uid);

  useEffect(() => {
    Mixpanel?.track("Trait clicked", { type: "element", slug: props.slug });
    Mixpanel?.track("Trait Viewed", { type: "element", slug: props.slug });
  }, []);

  useEffect(() => {
    if (props.uid) {
      if (props.uid === user?.uid) {
        setOwnProfile(true);
      } else {
        setOwnProfile(false);
      }
    }
  }, [user?.uid, isFriend, props.uid]);

  if (element === null || !element.isDiscoverable) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  if (ownProfile === true) {
    return <OwnElementModal slug={props.slug} />;
  } else {
    return <FriendElementModal slug={props.slug} uid={props.uid} />;
  }
}
