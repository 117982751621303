import { useEffect, useState } from "react";
import {
  ContentIdentifier,
  PublicProfile,
  TwoByTwoGridNew,
} from "@dimensional-engineering/dimensional-models";

import styles from "./TwoByTwoNew.module.css";

import SmallProfileImage from "../SmallProfileImage/SmallProfileImage";
import { FriendsPublicProfile } from "@/models/sharedModels";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { useAuth } from "@/context/AuthContext";
import Loader from "../Loader";

export default function TwoByTwoNew(props: {
  data: TwoByTwoGridNew;
  imageUrl: string;
  publicProfile: PublicProfile | null;
  connectionPublicProfile?: FriendsPublicProfile | null;
}) {
  const scoreX = props.data?.xAxis.myScore;
  const scoreY = props.data?.yAxis.myScore;

  const connectionScoreX = props.data?.xAxis.connectionScore;
  const connectionsScoreY = props.data?.yAxis.connectionScore;

  return (
    <div className={styles.main}>
      <p className={styles.label}>{props.data.yAxis.highDescriptor}</p>
      <div className={styles.parent}>
        {scoreX && scoreY && (
          <div
            style={{
              left: `calc(${scoreX * 100}% - 26px)`,
              bottom: `calc(${scoreY * 100}% - 26px)`,
            }}
            className={styles.imageDiv}
          >
            <SmallProfileImage
              noLink
              medium
              imageUrl={props.imageUrl}
              publicProfile={props.publicProfile}
            />
          </div>
        )}
        {props.connectionPublicProfile &&
          connectionScoreX &&
          connectionsScoreY && (
            <div
              style={{
                left: `calc(${connectionScoreX * 100}% - 26px)`,
                bottom: `calc(${connectionsScoreY * 100}% - 26px)`,
              }}
              className={styles.imageDiv}
            >
              <SmallProfileImage
                noLink
                medium
                imageUrl={props.connectionPublicProfile.imageUrl}
                publicProfile={props.connectionPublicProfile}
              />
            </div>
          )}
        <div className={styles.verticalLine}></div>
        <div className={styles.horizontalLine}></div>
        <div className={styles.midCircle}></div>
        <p className={styles.labelLeft}>{props.data.xAxis.lowDescriptor}</p>
        <p className={styles.labelRight}>{props.data.xAxis.highDescriptor}</p>
        <p className={styles.quadrantLabelOne}>
          {props.data.quadrantDetail?.q1Data.label}
        </p>
        <p className={styles.quadrantLabelTwo}>
          {props.data.quadrantDetail?.q2Data.label}
        </p>
        <p className={styles.quadrantLabelThree}>
          {props.data.quadrantDetail?.q4Data.label}
        </p>
        <p className={styles.quadrantLabelFour}>
          {props.data.quadrantDetail?.q3Data.label}
        </p>
      </div>
      <p className={styles.label}>{props.data.yAxis.lowDescriptor}</p>
    </div>
  );
}

export function TwoByTwoNewNoData(props: {
  contentIdentifier: ContentIdentifier;
  imageUrl: string;
  publicProfile: PublicProfile | null;
  connectionPublicProfile?: FriendsPublicProfile | null;
  onLoad?: () => void;
}) {
  const { loading, data } = useGetTwoByTwoData(
    props.contentIdentifier,
    props.connectionPublicProfile?.ownerUUID
  );

  useEffect(() => {
    if (loading === false && props.onLoad) {
      props.onLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const scoreX = data?.xAxis.myScore;
  const scoreY = data?.yAxis.myScore;

  const connectionScoreX = data?.xAxis.connectionScore;
  const connectionsScoreY = data?.yAxis.connectionScore;

  if (loading) {
    return <Loader height="367px" mobileHeight="367px" />;
  }

  return (
    <div className={styles.main}>
      <p className={styles.label}>{data?.yAxis.highDescriptor}</p>
      <div className={styles.parent}>
        {scoreX && scoreY && (
          <div
            style={{
              left: `calc(${scoreX * 100}% - 26px)`,
              bottom: `calc(${scoreY * 100}% - 26px)`,
            }}
            className={styles.imageDiv}
          >
            <SmallProfileImage
              noLink
              medium
              imageUrl={props.imageUrl}
              publicProfile={props.publicProfile}
            />
          </div>
        )}
        {props.connectionPublicProfile &&
          connectionScoreX &&
          connectionsScoreY && (
            <div
              style={{
                left: `calc(${connectionScoreX * 100}% - 26px)`,
                bottom: `calc(${connectionsScoreY * 100}% - 26px)`,
              }}
              className={styles.imageDiv}
            >
              <SmallProfileImage
                noLink
                medium
                imageUrl={props.connectionPublicProfile.imageUrl}
                publicProfile={props.connectionPublicProfile}
              />
            </div>
          )}
        <div className={styles.verticalLine}></div>
        <div className={styles.horizontalLine}></div>
        <div className={styles.midCircle}></div>
        <p className={styles.labelLeft}>{data?.xAxis.lowDescriptor}</p>
        <p className={styles.labelRight}>{data?.xAxis.highDescriptor}</p>
        <p className={styles.quadrantLabelOne}>
          {data?.quadrantDetail?.q1Data.label}
        </p>
        <p className={styles.quadrantLabelTwo}>
          {data?.quadrantDetail?.q2Data.label}
        </p>
        <p className={styles.quadrantLabelThree}>
          {data?.quadrantDetail?.q4Data.label}
        </p>
        <p className={styles.quadrantLabelFour}>
          {data?.quadrantDetail?.q3Data.label}
        </p>
      </div>
      <p className={styles.label}>{data?.yAxis.lowDescriptor}</p>
    </div>
  );
}

function useGetTwoByTwoData(
  contentIdentifier: ContentIdentifier,
  peerUid: string | null | undefined
) {
  const [data, setData] = useState<TwoByTwoGridNew | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useAuth();

  useEffect(() => {
    const twoByTwoCall = callFirebaseFunctions("fetchTwoByTwoGrid");
    if (contentIdentifier) {
      twoByTwoCall({
        memberId: user?.uid,
        peerUid: peerUid,
        contentIdentifier: contentIdentifier,
      })
        .then((res) => {
          setLoading(false);
          setData(res.data as TwoByTwoGridNew);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [contentIdentifier, peerUid, user?.uid]);

  return { loading, data };
}
