import { useEffect, useState } from "react";

import styles from "./AnalysisFeedback.module.css";

import { CloseButtonDailyStoryFeedback } from "@/components/shared/CloseButtonSummary";

type Props = {
  title: string;
  subtitle: string;
  onAnswer: (value: number) => void;
  currentAnswer: number | null;
  onClose?: () => void;
};

type PropsStory = {
  title: string;
  subtitle: string;
  onAnswer: (value: number) => void;
  currentAnswer: number | null;
  onClose: () => void;
};

export default function AnalysisFeedback(props: Props) {
  const [feedback, setFeedback] = useState<number | null>(props.currentAnswer);

  useEffect(() => {
    setFeedback(props.currentAnswer);
  }, [props.currentAnswer]);

  return (
    <div className={styles.main}>
      <h4 className={styles.title}>{props.title}</h4>
      <h5 className={styles.subtitle}>{props.subtitle}</h5>
      <div className={styles.emojiParentDiv}>
        <div
          onClick={() => {
            setFeedback(0);
            props.onAnswer(0);
          }}
          className={feedback === 0 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😒</p>
          <p className={styles.desc}>Meh</p>
          <FeedbackScoreBar score={0} />
        </div>
        <div
          onClick={() => {
            setFeedback(1);
            props.onAnswer(1);
          }}
          className={feedback === 1 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>🙂</p>
          <p className={styles.desc}>{"It's okay"}</p>
          <FeedbackScoreBar score={1} />
        </div>
        <div
          onClick={() => {
            setFeedback(2);
            props.onAnswer(2);
          }}
          className={feedback === 2 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😊</p>
          <p className={styles.desc}>Love it</p>
          <FeedbackScoreBar score={2} />
        </div>
        <div
          onClick={() => {
            setFeedback(3);
            props.onAnswer(3);
          }}
          className={feedback === 3 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😍</p>
          <p className={styles.desc}>OMG</p>
          <FeedbackScoreBar score={3} />
        </div>
      </div>
    </div>
  );
}

export function AnalysisFeedbackSnippets(props: Props) {
  const [feedback, setFeedback] = useState<number | null>(props.currentAnswer);

  useEffect(() => {
    setFeedback(props.currentAnswer);
  }, [props.currentAnswer]);

  return (
    <div
      style={{
        backgroundColor: "#2D2927",
        border: 0,
        width: "100%",
      }}
      className={styles.main}
    >
      <div
        onClick={() => props.onClose && props.onClose()}
        className={styles.closeButtonSnippets}
      >
        <CloseButtonDailyStoryFeedback />
      </div>
      <h4 className={styles.title}>{props.title}</h4>
      <h5 className={styles.subtitle}>{props.subtitle}</h5>
      <div className={styles.emojiParentDiv}>
        <div
          onClick={() => {
            setFeedback(0);
            props.onAnswer(0);
          }}
          className={feedback === 0 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😒</p>
          <p className={styles.desc}>Meh</p>
          <FeedbackScoreBar score={0} />
        </div>
        <div
          onClick={() => {
            setFeedback(1);
            props.onAnswer(1);
          }}
          className={feedback === 1 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>🙂</p>
          <p className={styles.desc}>{"It's okay"}</p>
          <FeedbackScoreBar score={1} />
        </div>
        <div
          onClick={() => {
            setFeedback(2);
            props.onAnswer(2);
          }}
          className={feedback === 2 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😊</p>
          <p className={styles.desc}>Love it</p>
          <FeedbackScoreBar score={2} />
        </div>
        <div
          onClick={() => {
            setFeedback(3);
            props.onAnswer(3);
          }}
          className={feedback === 3 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😍</p>
          <p className={styles.desc}>OMG</p>
          <FeedbackScoreBar score={3} />
        </div>
      </div>
    </div>
  );
}

export function FeedbackScoreBar(props: { score: number }) {
  return (
    <div className={styles.scoreBarParent}>
      <div className={styles.scoreBarActive}></div>
      <div
        className={
          props.score >= 1 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
      <div
        className={
          props.score >= 2 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
      <div
        style={{ marginRight: "0" }}
        className={
          props.score === 3 ? styles.scoreBarActive : styles.scoreBarInactive
        }
      ></div>
    </div>
  );
}

export function DailyStoryFeedbackScoreBar(props: PropsStory) {
  const [feedback, setFeedback] = useState<number | null>(props.currentAnswer);

  useEffect(() => {
    setFeedback(props.currentAnswer);
  }, [props.currentAnswer]);

  return (
    <div className={styles.mainStories}>
      <div onClick={() => props.onClose()} className={styles.closeButton}>
        <CloseButtonDailyStoryFeedback />
      </div>
      <h4 className={styles.title}>{props.title}</h4>
      <h5 className={styles.subtitle}>{props.subtitle}</h5>
      <div className={styles.emojiParentDiv}>
        <div
          onClick={() => {
            setFeedback(0);
            props.onAnswer(0);
          }}
          className={feedback === 0 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😒</p>
          <p className={styles.desc}>Meh</p>
          <FeedbackScoreBar score={0} />
        </div>
        <div
          onClick={() => {
            setFeedback(1);
            props.onAnswer(1);
          }}
          className={feedback === 1 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>🙂</p>
          <p className={styles.desc}>{"It's okay"}</p>
          <FeedbackScoreBar score={1} />
        </div>
        <div
          onClick={() => {
            setFeedback(2);
            props.onAnswer(2);
          }}
          className={feedback === 2 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😊</p>
          <p className={styles.desc}>Love it</p>
          <FeedbackScoreBar score={2} />
        </div>
        <div
          onClick={() => {
            setFeedback(3);
            props.onAnswer(3);
          }}
          className={feedback === 3 ? styles.emojiBoxSelected : styles.emojiBox}
        >
          <p className={styles.emoji}>😍</p>
          <p className={styles.desc}>OMG</p>
          <FeedbackScoreBar score={3} />
        </div>
      </div>
    </div>
  );
}
