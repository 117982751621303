import { useModalContext } from "@/context/ModalContext";

export default function ModalHandler() {
  const { modalComponent } = useModalContext();

  const mapped = modalComponent.map((comp, index) => {
    return <div key={index}>{comp}</div>;
  });

  // return modalComponent[modalComponent.length - 1];
  return <>{mapped}</>;
}
