import {
  ArchetypeDiscovery,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";

import styles from "./ValuesArchetypeBreakdown.module.css";

import { ExtendedArchetype } from "@/models/sharedModels";
import ArchetypeBreakdownHeading from "../../Heading/ArchetypeBreakdownHeading";

type Props = {
  archetypeDiscovery: ArchetypeDiscovery | undefined;
  publicProfile: PublicProfile | null;
};

export default function ValuesArchetypeBreakdown(props: Props) {
  return (
    <div className={styles.main}>
      <ArchetypeBreakdownHeading publicProfile={props.publicProfile} />
      <div className={styles.chartMain}>
        <p className={styles.label}>Rejects hierarchies</p>
        <div className={styles.parent}>
          <div className={styles.rowTop}>
            <div
              style={
                props.archetypeDiscovery?.archetypeSlug ===
                "values-individualist"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.leftTop}
            >
              <p
                style={
                  props.archetypeDiscovery?.archetypeSlug ===
                  "values-individualist"
                    ? { color: "black" }
                    : {}
                }
                className={styles.label}
              >
                Focuses on self
              </p>
            </div>

            <div
              style={
                props.archetypeDiscovery?.archetypeSlug === "values-giver"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.rightTop}
            >
              <p
                style={
                  props.archetypeDiscovery?.archetypeSlug === "values-giver"
                    ? { color: "black" }
                    : {}
                }
                className={styles.label}
              >
                Focuses on community
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div
              style={
                props.archetypeDiscovery?.archetypeSlug === "values-ascender"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.leftBottom}
            ></div>
            <div
              style={
                props.archetypeDiscovery?.archetypeSlug ===
                "values-traditionalist"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.rightBottom}
            ></div>
          </div>
        </div>
        <div className={styles.lineTopLeftModal}></div>
        <div className={styles.lineTopRightModal}></div>
        <p className={styles.label}>Works within hierarchies</p>
        <p className={styles.info}>
          Values Archetypes are determined by the mix of your scores in Values
          Dimension.
        </p>
      </div>
      <p
        style={
          props.archetypeDiscovery?.archetypeSlug === "values-individualist"
            ? { color: "black" }
            : {}
        }
        className={styles.labelOne}
      >
        Individualist <br /> (Pleasure)
      </p>
      <p
        style={
          props.archetypeDiscovery?.archetypeSlug === "values-giver"
            ? { color: "black" }
            : {}
        }
        className={styles.labelTwo}
      >
        Giver <br /> (Helping)
      </p>
      <p
        style={
          props.archetypeDiscovery?.archetypeSlug === "values-ascender"
            ? { color: "black" }
            : {}
        }
        className={styles.labelThree}
      >
        Ascender <br /> (Success)
      </p>
      <p
        style={
          props.archetypeDiscovery?.archetypeSlug === "values-traditionalist"
            ? { color: "black" }
            : {}
        }
        className={styles.labelFour}
      >
        Traditionalist <br /> (Harmony)
      </p>
    </div>
  );
}

type DetailsProps = {
  archetype: ExtendedArchetype;
};

export function ValuesArchetypeBreakdownDetailsTab(props: DetailsProps) {
  return (
    <div className={styles.main}>
      <ArchetypeBreakdownHeading />
      <div className={styles.chartMain}>
        <p className={styles.label}>Rejects hierarchies</p>
        <div className={styles.parent}>
          <div className={styles.rowTop}>
            <div
              style={
                props.archetype?.slug === "values-individualist"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.leftTop}
            >
              <p
                style={
                  props.archetype?.slug === "values-individualist"
                    ? { color: "#2D2927" }
                    : {}
                }
                className={styles.label}
              >
                Focuses on self
              </p>
            </div>

            <div
              style={
                props.archetype?.slug === "values-giver"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.rightTop}
            >
              <p
                style={
                  props.archetype?.slug === "values-giver"
                    ? { color: "#2D2927" }
                    : {}
                }
                className={styles.label}
              >
                Focuses on community
              </p>
            </div>
          </div>
          <div className={styles.row}>
            <div
              style={
                props.archetype?.slug === "values-ascender"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.leftBottom}
            ></div>
            <div
              style={
                props.archetype?.slug === "values-traditionalist"
                  ? { backgroundColor: "#fffff2" }
                  : {}
              }
              className={styles.rightBottom}
            ></div>
          </div>
        </div>
        <div className={styles.lineTopLeftModal}></div>
        <div className={styles.lineTopRightModal}></div>
        <p className={styles.label}>Works within hierarchies</p>
        <p className={styles.info}>
          Values Archetypes are determined by the mix of your scores in Values
          Dimension.
        </p>
      </div>
      <p
        style={
          props.archetype?.slug === "values-individualist"
            ? { color: "#2D2927" }
            : {}
        }
        className={styles.labelOne}
      >
        Individualist <br /> (Pleasure)
      </p>
      <p
        style={
          props.archetype?.slug === "values-giver" ? { color: "#2D2927" } : {}
        }
        className={styles.labelTwo}
      >
        Giver <br /> (Helping)
      </p>
      <p
        style={
          props.archetype?.slug === "values-ascender"
            ? { color: "#2D2927" }
            : {}
        }
        className={styles.labelThree}
      >
        Ascender <br /> (Success)
      </p>
      <p
        style={
          props.archetype?.slug === "values-traditionalist"
            ? { color: "#2D2927" }
            : {}
        }
        className={styles.labelFour}
      >
        Traditionalist <br /> (Harmony)
      </p>
    </div>
  );
}
