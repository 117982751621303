/* eslint-disable @next/next/no-img-element */
import { useSelector } from "react-redux";

import styles from "./DiscoveryGate.module.css";

import { useGetImagesAndDataInviteGate } from "./UserSuccessInviteGate";
import { RootState } from "@/redux/store";
import { ProfileColorMap } from "../ColorMap";
import DiscoveryCheckMark from "./DiscoveryCheckMark";
import { PeerFeedbackPillSmall } from "@/components/appHome/PeerFeedBackModule/PeerFeedbackPill/PeerFeedbackPillElement";
import { useModalContext } from "@/context/ModalContext";
import PeerFeedbackModal from "@/components/appHome/PeerFeedBackModule/PeerFeedbackModal/PeerFeedbackModal";

type Props = {
  numPeerAssessorsRequired: number;
};

export default function UserPeerAssessmentGate(props: Props) {
  const { setModalComponent } = useModalContext();

  const userState = useSelector((state: RootState) => state.user);
  const { imagURls, emptyImages, profiles, invitesFull } =
    useGetImagesAndDataInviteGate({
      numberOfInvitesRequired: props.numPeerAssessorsRequired,
      invites: userState.memberProfile?.peerAssesseeUids,
    });

  const mapped = [imagURls, new Array(emptyImages).fill(" ")]
    .flat()
    .map((value, i) => {
      if (value === " ") {
        return (
          <div
            style={i === 0 ? { marginLeft: "0" } : {}}
            className={styles.emptyImageDiv}
            key={i}
          >
            <p>{i + 1}</p>
          </div>
        );
      } else {
        return (
          <div
            style={i === 0 ? { marginLeft: "0" } : {}}
            className={styles.imageDiv}
            key={value}
          >
            <img
              style={
                profiles[i]?.primaryNature
                  ? {
                      border: `solid 3px ${
                        ProfileColorMap[
                          profiles[i].primaryNature
                            ?.nature as keyof typeof ProfileColorMap
                        ]
                      }`,
                    }
                  : { border: `solid 3px grey` }
              }
              className={styles.image}
              src={value}
              alt=""
            />
          </div>
        );
      }
    });

  if (invitesFull) {
    return (
      <div className={styles.tableHeader}>
        Peer Assessments{" "}
        <div className={styles.checkmarkCompleteDiv}>
          <DiscoveryCheckMark backgroundColor="#e6e1df" checkColor="#2D2927" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.tableHeader}>Peer Assessments</div>
      <div className={styles.friendSignupWrapper}>
        <div className={styles.peerAssessmentTopDiv}>
          <div className={styles.peerAssessmentIcon}>
            <PeerFeedbackPillSmall
              numOfPeerAssessmentsReceived={props.numPeerAssessorsRequired}
            />
          </div>
          <p className={styles.peerTopText}>
            Requires assessments from {props.numPeerAssessorsRequired} peers.
          </p>
        </div>

        <div className={styles.imageParent}>{mapped}</div>
        <div className={styles.inviteButtonDiv}>
          <button
            onClick={() => setModalComponent(<PeerFeedbackModal />)}
            className={styles.peerButton}
          >
            Request Peer Assessments
          </button>
        </div>
      </div>
    </>
  );
}
