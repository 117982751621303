import styles from "./AuthenticatedParent.module.css";

import { useModalContext } from "@/context/ModalContext";

export default function AuthenticatedParent({
  children,
  blur,
}: {
  children: React.ReactNode;
  blur?: boolean;
}) {
  const { modalComponent } = useModalContext();
  return (
    <div
      style={blur === true ? { filter: "blur(6px)" } : {}}
      className={modalComponent.length > 0 ? styles.mainNoScroll : styles.main}
    >
      {children}
    </div>
  );
}
