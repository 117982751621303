export default function ArchetypesStatusIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6875 19.6042C21.7019 19.6042 22.3317 18.5011 21.8161 17.6275L12.1286 1.21577C11.6215 0.356625 10.3785 0.356625 9.87141 1.21577L0.183944 17.6275C-0.331742 18.5011 0.298058 19.6042 1.31254 19.6042H20.6875ZM11.0179 13.9654C11.9 13.9654 12.6151 13.2503 12.6151 12.3682C12.6151 11.486 11.9 10.7709 11.0179 10.7709C10.1358 10.7709 9.4207 11.486 9.4207 12.3682C9.4207 13.2503 10.1358 13.9654 11.0179 13.9654Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
