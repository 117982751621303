import { useEffect, useState } from "react";

import elementsJsonData from "../../../assets/traitInfo/elements.json";
import patternsJsonData from "../../../assets/traitInfo/patterns.json";

export default function useIsTraitPremium(
  type: "element" | "pattern" | "archetype",
  slug: string
) {
  const [isPremium, setIsPremium] = useState<boolean>(false);

  useEffect(() => {
    if (type === "element") {
      elementsJsonData.forEach((el) => {
        if (el.slug === slug) {
          setIsPremium(el.isPremium);
        }
      });
    } else {
      patternsJsonData.forEach((patt) => {
        if (patt.slug === slug) {
          setIsPremium(patt.isPremium);
        }
      });
    }
  }, [type, slug]);

  return isPremium;
}
