import {
  ArchetypeDiscovery,
  ContentIdentifier,
  ContentType,
  DiscoveredSnippet,
  ElementDiscovery,
  LlmRequest,
  PatternDiscovery,
  SimilarConnection,
  TraitIdentifier,
} from "@dimensional-engineering/dimensional-models";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { db } from "@/_firebase/firebaseConfig";
import { Mixpanel } from "@/helpers/mixpanel";
import {
  getUserPatternScore,
  getUserScaleScore,
} from "@/components/traits/utils";
import { FriendsPublicProfile } from "@/models/sharedModels";
import { callFirebaseFunctions } from "@/_firebase/callFirebaseFunctions";
import { MutableRefObject } from "react";

export function getUserScoreFromTraitIdentifier(
  traitIdentifier: TraitIdentifier,
  elementDiscovery: ElementDiscovery[] | null,
  patternDiscovery: PatternDiscovery[] | null,
  arcehtypeDiscovery: ArchetypeDiscovery[] | null
): number | null | undefined {
  let score: number | null | undefined = null;
  if (traitIdentifier.type === "element") {
    elementDiscovery?.forEach((el) => {
      if (el.scaleSlug === traitIdentifier.slug) {
        score = getUserScaleScore(el.scaleScore?.percentScore, el.scaleSlug);
      }
    });
  } else if (traitIdentifier.type === "pattern") {
    patternDiscovery?.forEach((patt) => {
      if (patt.patternSlug === traitIdentifier.slug) {
        score = getUserPatternScore(patt.percentScore, patt.patternSlug);
      }
    });
  } else {
    arcehtypeDiscovery?.forEach((arch) => {
      if (arch.archetypeSlug === traitIdentifier.slug) {
        score = arch.archetypeScore;
      }
    });
  }
  return score;
}

export function getPublicProfileFromSimilarConnection(
  similarConnection: SimilarConnection,
  friendsPublicProfiles: FriendsPublicProfile[]
): FriendsPublicProfile | null {
  let returnProfile: FriendsPublicProfile | null = null;

  friendsPublicProfiles.forEach((prof) => {
    if (prof.ownerUUID === similarConnection.uid) {
      returnProfile = prof;
    }
  });

  return returnProfile;
}

export async function updateSnippetsFeedback(
  slug: string,
  uid: string,
  value: number
) {
  const ref = doc(db, "members", `${uid}`, "discoveredSnippets", slug);
  Mixpanel?.track("Feedback given", {
    slug: slug,
    content_type: "snippet",
    score: value,
  });
  return await updateDoc(ref, {
    feedbackScore: value,
  });
}

export async function getSnippetFeedbackScore(
  slug: string,
  uid: string
): Promise<number | null> {
  const docRef = doc(db, "members", `${uid}`, "discoveredSnippets", slug);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    if (
      docSnap.data().feedbackScore !== null &&
      docSnap.data().feedbackScore !== undefined
    ) {
      return docSnap.data().feedbackScore as number;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export function isSnippetLLMGenerated(snippet: DiscoveredSnippet): boolean {
  if (
    snippet.snippetType === "connectionComparison" ||
    snippet.snippetType === "allUserComparison" ||
    snippet.snippetType === "similarityComparison" ||
    snippet.snippetType === "aggregatePeerTwoByTwo" ||
    snippet.snippetType === "peerRatedNature" ||
    snippet.snippetType === "topPeerRatedTraits" ||
    snippet.snippetType === "peerRatedTraitsDelta" ||
    snippet.snippetType === "aggregatePeerSpectrum"
  ) {
    return false;
  }

  return true;
}

export async function regenerateSnippet(slug: string) {
  const call = callFirebaseFunctions("updateDimensionGatedSnippets");

  return call({ snippetSlug: slug });
}

export async function getSnippetLLMRequest(
  uid: string | undefined,
  snippet: DiscoveredSnippet
) {
  const snippetsManualLlmRef = query(
    collection(db, `llmRequests/${uid}/snippets`),
    where("contentIdentifier", "==", {
      contentType: ContentType.resultSnippet,
      contentSlug: snippet?.snippetSlug,
    }),
    orderBy("createdOn", "desc"),
    limit(1)
  );

  let allRequests: LlmRequest[] = [];

  await getDocs(snippetsManualLlmRef).then((snapshot) => {
    snapshot.forEach((doc) => {
      allRequests.push(doc.data() as LlmRequest);
    });
  });

  return allRequests[0];
}

export async function fetchHighestScoringTraits(uid: string) {
  const content: ContentIdentifier = {
    contentSlug: "snippet-highest-scoring-traits",
    contentType: ContentType.resultSnippet,
  };
  const call = callFirebaseFunctions("fetchHighestScoringTraits");
  return await call({ memberId: uid, contentIdentifier: content });
}

export function checkScroll(divRef: MutableRefObject<HTMLDivElement | null>) {
  const div = divRef.current;

  if (div) {
    const isScrollable = div.scrollHeight > div.clientHeight;
    const isScrolledToBottom =
      div.scrollTop + div.clientHeight >= div.scrollHeight;

    if (!isScrollable || isScrolledToBottom) {
      console.log("Hello");
    } else {
      console.log("NO HELLO");
    }
  }
}
