/* eslint-disable @next/next/no-img-element */
import styles from "./ConversionModal.module.css";

export const ConversionModalSlideOne = () => {
  return (
    <div key={1} className={styles.midWrapper}>
      <div
        style={{ backgroundImage: `url(/conversionModal/slide-one.png)` }}
        className={styles.slide}
      ></div>
      <div className={styles.line}></div>
      <ConversionModalSlideTitleAndSubtitle
        title="Go deeper with Dimensional Plus"
        subtitle="More assessments, more traits, more content, more to learn about you."
      />
    </div>
  );
};

export const ConversionModalSlideTwo = () => {
  return (
    <div key={1} className={styles.midWrapper}>
      <div
        style={{ backgroundImage: `url(/conversionModal/slideTwo.png)` }}
        className={styles.slide}
      ></div>
      <div className={styles.line}></div>
      <ConversionModalSlideTitleAndSubtitle
        title="More Traits"
        subtitle="Unlock scores to 50 Premium Elements and Patterns."
      />
    </div>
  );
};

export const ConversionModalSlideThree = () => {
  return (
    <div key={1} className={styles.midWrapper}>
      <div
        style={{ backgroundImage: `url(/conversionModal/slideThree.png)` }}
        className={styles.slide}
      ></div>
      <div className={styles.line}></div>
      <ConversionModalSlideTitleAndSubtitle
        title="More results"
        subtitle="Understand your quirks, find your purpose, make important decisions in life."
      />
    </div>
  );
};

export const ConversionModalSlideFour = () => {
  return (
    <div key={1} className={styles.midWrapper}>
      <div
        style={{ backgroundImage: `url(/conversionModal/slideFive.png)` }}
        className={styles.slide}
      ></div>
      <div className={styles.line}></div>
      <ConversionModalSlideTitleAndSubtitle
        title="More compatibility snippets "
        subtitle="Get hyper-detailed insights into your relationships."
      />
    </div>
  );
};

export const ConversionModalSlideSix = () => {
  return (
    <div key={1} className={styles.midWrapper}>
      <div
        style={{
          backgroundImage: `url(/conversionModal/slideSix.png)`,
          backgroundSize: "contain",
        }}
        className={styles.slide}
      ></div>
      <div className={styles.line}></div>
      <ConversionModalSlideTitleAndSubtitle
        title="Premium Reports"
        subtitle="Detailed guides to better understand yourself in areas like work, love, and wellness."
      />
    </div>
  );
};

export const ConversionModalSlideSeven = () => {
  return (
    <div key={1} className={styles.midWrapper}>
      <div
        style={{ backgroundImage: `url(/conversionModal/slideTen.png)` }}
        className={styles.slide}
      ></div>
      <div className={styles.line}></div>
      <ConversionModalSlideTitleAndSubtitle
        title="Support us"
        subtitle="We're a small team with a big mission. We don't advertise or sell data. Purchases help us make Dimensional better."
      />
    </div>
  );
};

function ConversionModalSlideTitleAndSubtitle(props: {
  title: string;
  subtitle: string;
}) {
  return (
    <div className={styles.slideTitleDiv}>
      <h2 className={styles.slideTitle}>{props.title}</h2>
      <h3 className={styles.slideSubTitle}>{props.subtitle}</h3>
    </div>
  );
}
