import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import html2canvas from "html2canvas";

import styles from "./DailyStories.module.css";

import { RootState } from "@/redux/store";
import { splitStringIntoMultipleLines } from "./utils";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { useModalContext } from "@/context/ModalContext";
import LogoWithText from "@/components/shared/LogoWithText";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyStoriesShareView(props: {
  type: "instagram" | "snapchat";
}) {
  const { setModalOpen } = useModalContext();
  const dailyStory = useSelector((state: RootState) => state.dailyStory);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDownloadImage = async () => {
      if (imageRef.current) {
        const canvas = await html2canvas(imageRef.current);
        // var a = document.createElement("a");
        // a.href = canvas
        //   .toDataURL("image/jpeg")
        //   .replace("image/jpeg", "image/octet-stream");
        // a.download = "somefilename.jpg";
        // a.click();

        const data = canvas.toDataURL("image/jpg");
        if (window.Android) {
          window.Android.onHaptic();

          if (props.type === "instagram") {
            window.Android.shareToInstagramQuiz(data);
          } else {
            window.Android.shareToSnapChat(data);
          }
        }
      }
    };

    setTimeout(() => {
      handleDownloadImage();
      setModalOpen(false);
    }, 110);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dailyStory.dailyStory) {
    return <></>;
  }

  const bodySplit = splitStringIntoMultipleLines(
    dailyStory.dailyStory.assignmentRuleset?.body,
    28
  );

  const mappedBody = bodySplit.map((line, i) => {
    return (
      <p style={{ fontSize: "26px" }} className={styles.storyBody} key={i}>
        {line}
      </p>
    );
  });

  return (
    <ModalWrapper title="" noHeader>
      <main
        ref={imageRef}
        style={{
          backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/dailyStories/${dailyStory.dailyStory.computeableStoryModelSlug}.jpg)`,
          overflow: "hidden",
        }}
        className={styles.modalMain}
      >
        <section style={{ marginTop: "90px" }} className={styles.wrapper}>
          <div className={styles.logoDiv}>
            <LogoWithText />
          </div>
          <h5
            style={{ marginTop: "35px" }}
            className={`${styles.storyTitleModal} ${font.className}`}
          >
            {dailyStory.dailyStory.name.toUpperCase()}:
          </h5>
          <div className={styles.bodyWrapper}>{mappedBody}</div>
        </section>
      </main>
    </ModalWrapper>
  );
}
