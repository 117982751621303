import { ScoreConfidence } from "@dimensional-engineering/dimensional-models";

import styles from "./SelfRatedScoreTable.module.css";

import { capitalizeFirstLetter } from "@/helpers/helpers";
import { useModalContext } from "@/context/ModalContext";
import PeerFeedbackModal from "@/components/appHome/PeerFeedBackModule/PeerFeedbackModal/PeerFeedbackModal";
import { Mixpanel } from "@/helpers/mixpanel";

export default function AccuracyComponent(props: {
  confidence: ScoreConfidence | null;
}) {
  const { setModalComponent } = useModalContext();

  return (
    <div className={styles.accuracyMain}>
      <div className={styles.accuracybuttonDiv}>
        <div
          className={
            props.confidence === null
              ? styles.circleInactive
              : styles.circleActive
          }
        ></div>
        <div
          className={
            props.confidence === "low" || props.confidence === null
              ? styles.circleInactive
              : styles.circleActive
          }
        ></div>
        <div
          className={
            props.confidence === "high"
              ? styles.circleActive
              : styles.circleInactive
          }
        ></div>
      </div>
      <h3 style={{ marginLeft: "8px" }} className={styles.rowText}>
        {props.confidence ? capitalizeFirstLetter(props.confidence) : "None"}
      </h3>
      <button
        onClick={() => {
          Mixpanel?.track("Peer Feedback Levels Modal Viewed", {
            source: "trait page",
          });
          setModalComponent(<PeerFeedbackModal />);
        }}
        className={styles.improveButton}
      >
        Improve
      </button>
    </div>
  );
}
