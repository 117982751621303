export default function PeerIcon(props: { light?: boolean }) {
  return (
    <svg
      width="13"
      height="24"
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25989 18.3827V19.5583C10.6755 19.8939 11.4921 20.4705 11.4921 20.9027C11.4921 21.5749 9.5477 22.5583 6.49984 22.5583C3.45198 22.5583 1.5076 21.5739 1.5076 20.9027C1.5076 20.4705 2.32322 19.8949 3.73979 19.5583V18.3827C1.84418 18.7905 0.332031 19.6549 0.332031 20.9272C0.332031 22.7516 3.49979 23.7594 6.47651 23.7594C9.45203 23.7594 12.621 22.775 12.621 20.9272C12.6444 19.655 11.1555 18.7906 9.25989 18.3827Z"
        fill={props.light ? "#fffff2" : "#2D2927"}
      />
      <path
        d="M4.09979 13.4394L4.29198 13.4873C4.53198 13.5594 4.69979 13.7751 4.69979 14.0394V20.255C4.69979 20.7828 5.13198 21.215 5.65979 21.215H7.2676C7.79541 21.215 8.2276 20.7828 8.2276 20.255V14.0394C8.2276 13.7751 8.39541 13.5594 8.63541 13.4873L8.82759 13.4394C9.3554 13.2951 9.7154 12.8394 9.7154 12.3116V8.73516C9.7154 7.65516 8.85102 6.79077 7.77102 6.79077H5.17974C4.09974 6.79077 3.23535 7.65516 3.23535 8.73516V12.3107C3.23535 12.8394 3.59541 13.3194 4.09979 13.4394Z"
        fill={props.light ? "#fffff2" : "#2D2927"}
      />
      <path
        d="M9.06765 3.5027C9.06765 4.92114 7.91827 6.07046 6.49989 6.07046C5.08151 6.07046 3.93213 4.92107 3.93213 3.5027C3.93213 2.08425 5.08151 0.934937 6.49989 0.934937C7.91827 0.934937 9.06765 2.08432 9.06765 3.5027Z"
        fill={props.light ? "#fffff2" : "#2D2927"}
      />
    </svg>
  );
}
