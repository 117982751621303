import { useEffect, useState } from "react";
import { TraitIdentifier as TraitIdentifierModel } from "@dimensional-engineering/dimensional-models";
import Link from "next/link";

import Element from "../../element/Element/Element";
import Pattern from "../../pattern/Pattern/Pattern";
import Archetype from "../../arcehtype/Archetypes/Archetype";
import archetypeJsonData from "../../../../assets/traitInfo/archetypes.json";
import { ExtendedArchetype } from "@/models/sharedModels";
import { useModalContext } from "@/context/ModalContext";
import ArchetypeModal from "../../TraitsModals/Archetype/ArchetypeModal";
import PatternModal from "../../TraitsModals/Pattern/PatternModal";
import ElementModal from "../../TraitsModals/Element/ElementModal";
import { IOSDynamicTraitLinkGenerator } from "@/_firebase/generateDymaicLink";

export default function TraitIdentifier(props: {
  identifier: TraitIdentifierModel;
  deeplink?: boolean;
  shareHash?: string | undefined;
  uid?: string;
}) {
  const [archetype, setArchetype] = useState<ExtendedArchetype | null>(null);
  const { setModalComponent } = useModalContext();
  const [dynamicLink, setDynamicLink] = useState<string>("");

  useEffect(() => {
    if (props.deeplink && props.shareHash) {
      IOSDynamicTraitLinkGenerator(
        props.identifier.slug,
        props.identifier.type,
        props.shareHash
      )
        .then((res) => {
          if (res) {
            setDynamicLink(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deeplink, props.shareHash]);

  useEffect(() => {
    if (props.identifier.type === "archetype") {
      archetypeJsonData.forEach((arch) => {
        if (arch.slug === props.identifier.slug) {
          setArchetype(arch as unknown as ExtendedArchetype);
        }
      });
    }
  }, [props.identifier.slug, props.identifier.type]);

  if (props.identifier.type === "archetype" && archetype !== null) {
    if (props.deeplink) {
      return (
        <Link href={dynamicLink} target="_blank">
          <Archetype archetype={archetype} />
        </Link>
      );
    }
    return (
      <div
        onClick={() =>
          setModalComponent(
            <ArchetypeModal
              uid={props.uid ?? undefined}
              slug={props.identifier.slug}
            />
          )
        }
        style={{ cursor: "pointer" }}
      >
        <Archetype archetype={archetype} />
      </div>
    );
  } else if (props.identifier.type === "pattern") {
    if (props.deeplink) {
      return (
        <Link href={dynamicLink} target="_blank">
          <Pattern patternSlug={props.identifier.slug} />
        </Link>
      );
    }
    return (
      <div
        onClick={() =>
          setModalComponent(
            <PatternModal
              uid={props.uid ?? undefined}
              slug={props.identifier.slug}
            />
          )
        }
        style={{ cursor: "pointer" }}
      >
        <Pattern patternSlug={props.identifier.slug} />
      </div>
    );
  } else {
    if (props.deeplink) {
      return (
        <Link href={dynamicLink} target="_blank">
          <Element slug={props.identifier.slug} />
        </Link>
      );
    }
    return (
      <div
        onClick={() =>
          setModalComponent(
            <ElementModal
              uid={props.uid ?? undefined}
              slug={props.identifier.slug}
            />
          )
        }
        style={{ cursor: "pointer" }}
      >
        <Element slug={props.identifier.slug} />
      </div>
    );
  }
}
