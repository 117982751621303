import { dimensionData } from "@/assets/traitInfo/dimensionData";
import { setCurrentRecommended } from "@/redux/slices/userSlice";
import { RootState } from "@/redux/store";
import { DimensionEvent } from "@dimensional-engineering/dimensional-models";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useRecommendedDimensionSlug() {
  const [slug, setSlug] = useState<string | null>("");
  const dimensionEvents: DimensionEvent[] | null = useSelector(
    (state: RootState) => state.user.dimensionEvents
  );
  const primaryFinished: boolean = useSelector(
    (state: RootState) => state.user.primaryFinished
  );
  const cognitiveFinished: boolean = useSelector(
    (state: RootState) => state.user.cognitiveFinished
  );
  const valuesFinished: boolean = useSelector(
    (state: RootState) => state.user.valueFinished
  );
  const dispath = useDispatch();

  useEffect(() => {
    ///logic for what to show for the recommended dimension
    //if core dimensions aren;t finished, show next core dimension not finished
    //if core finished, show any dimension they've started by latest for cta block
    //if they have'n't started any, just show the latest in order
    let core = ["personality", "personality-II", "values"];

    // if (!primaryFinished) {
    //   setSlug("personality");
    //   dispath(setCurrentRecommended("personality"));
    // }

    // if (primaryFinished && !cognitiveFinished) {
    //   dispath(setCurrentRecommended("personality-II"));
    //   setSlug("personality-II");
    // }

    // if (primaryFinished && cognitiveFinished && !valuesFinished) {
    //   setSlug("values");
    // }

    if (!cognitiveFinished) {
      setSlug("personality-II");
      dispath(setCurrentRecommended("personality-II"));
    }

    if (cognitiveFinished && !valuesFinished) {
      dispath(setCurrentRecommended("values"));
      setSlug("values");
    }

    if (valuesFinished && cognitiveFinished && !primaryFinished) {
      setSlug("personality");
      dispath(setCurrentRecommended("personality"));
    }

    if (primaryFinished && cognitiveFinished && valuesFinished) {
      //other dimensions
      let otherDimInProgress: { slug: string; date: number | undefined }[] = [];
      let otherFinished: string[] = [];
      dimensionEvents?.forEach((dim) => {
        if (!core.includes(dim.dimensionSlug) && dim.event === "complete") {
          otherFinished.push(dim.dimensionSlug);
        } else if (
          !core.includes(dim.dimensionSlug) &&
          dim.event === "started"
        ) {
          otherDimInProgress.push({
            slug: dim.dimensionSlug,
            date: dim.updatedOn,
          });
        }
      });

      if (otherDimInProgress.length === 0) {
        //none of the other dimensions are are in progress. We check for completion and use next one in order as the recommended
        let tempArray = [];
        for (let i = 0; i < dimensionData.length; i++) {
          if (
            !core.includes(dimensionData[i].slug) &&
            !otherFinished.includes(dimensionData[i].slug)
          ) {
            ///not core and not finished
            tempArray.push(dimensionData[i]);
          }
        }
        tempArray.sort((a, b) => {
          return a.order - b.order;
        });
        if (tempArray.length > 0) {
          setSlug(tempArray[0].slug);
          dispath(setCurrentRecommended(tempArray[0].slug));
        }
      } else {
        otherDimInProgress.sort((a, b) => {
          if (a.date && b.date) {
            return a.date - b.date;
          } else {
            return 0;
          }
        });
        setSlug(otherDimInProgress[0].slug);
        dispath(setCurrentRecommended(otherDimInProgress[0].slug));
      }
    }

    console.log(primaryFinished, cognitiveFinished, valuesFinished);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitiveFinished, primaryFinished, valuesFinished]);

  return slug;
}
