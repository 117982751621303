import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { DM_Sans } from "next/font/google";
import { doc, updateDoc } from "firebase/firestore";

import styles from "./ConversionModal.module.css";
import "swiper/css";

import CloseButtonSummary from "@/components/shared/CloseButtonSummary";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import {
  ConversionModalSlideFour,
  ConversionModalSlideOne,
  ConversionModalSlideSix,
  ConversionModalSlideSeven,
  ConversionModalSlideThree,
  ConversionModalSlideTwo,
} from "./Slides";
import { useModalContext } from "@/context/ModalContext";
import Alert from "@/components/shared/Alerts/Alert";
import { db } from "@/_firebase/firebaseConfig";
import { useAuth } from "@/context/AuthContext";
import { Mixpanel } from "@/helpers/mixpanel";
import { useAlertContext } from "@/context/AlertContext";

const font = DM_Sans({ weight: "500", subsets: ["latin"] });

export default function ConversionModal(props: { initialSlide?: number }) {
  const { setModalOpen, emptyModal } = useModalContext();
  const { setAlertComponent, emptyAlerts } = useAlertContext();

  const [mySwiperRef, setMySwiperRef] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const fillArray = [0, 1, 2, 3, 4, 5];
  const { user } = useAuth();

  const slideIndexMap = fillArray.map((n, i) => {
    return (
      <div
        onClick={() => setIndex(i)}
        key={i}
        className={index === i ? styles.circleActive : styles.circle}
      ></div>
    );
  });

  const mappedSlides = [
    <ConversionModalSlideOne key={1} />,
    <ConversionModalSlideTwo key={2} />,
    <ConversionModalSlideThree key={3} />,
    <ConversionModalSlideFour key={4} />,
    <ConversionModalSlideSix key={6} />,
    <ConversionModalSlideSeven key={7} />,
  ].map((c, i) => {
    return <SwiperSlide key={i}>{c}</SwiperSlide>;
  });

  function onContinueClick() {
    let source =
      props.initialSlide === 3
        ? "Compatibility Snippets"
        : props.initialSlide === 1
        ? "Trait"
        : props.initialSlide === 3
        ? "Personality Analysis"
        : props.initialSlide === 2
        ? "Snippets"
        : "settings";

    if (
      props.initialSlide !== undefined &&
      props.initialSlide >= 5 &&
      props.initialSlide <= 8
    ) {
      source = "Report";
    }
    Mixpanel?.track("Purchase CTA Clicked", { source: source });

    if (user?.uid) {
      const ref = doc(db, "members", user?.uid);
      updateDoc(ref, { isPremiumWaitlisted: true })
        .then(() => {
          setTimeout(() => {
            setAlertComponent(
              <Alert
                elementName="Alert"
                type="warning"
                message="Purchasing premium is not yet available on Android. We'll email you when it's ready!"
              />,
              7000
            );
          }, 200);
          setTimeout(() => {
            emptyModal();
            emptyAlerts();
          }, 6000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    if (props.initialSlide) {
      setIndex(props.initialSlide);
    }
  }, [props.initialSlide]);

  useEffect(() => {
    const handleArrowKeys = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        if (index !== 0) {
          setIndex((current) => current - 1);
        }
      }

      if (event.key === "ArrowRight") {
        if (index !== 9) setIndex((current) => current + 1);
      }
    };

    document.addEventListener("keydown", handleArrowKeys);

    return () => {
      document.removeEventListener("keydown", handleArrowKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    if (mySwiperRef) mySwiperRef?.slideTo(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  return (
    <ModalWrapper noHeader noLine title="">
      <section className={styles.topSection}>
        <h1 className={`${styles.title} ${font.className}`}>
          Dimensional <span className={styles.plus}>Plus</span>
        </h1>
        <div style={{ cursor: "pointer" }} onClick={() => setModalOpen(false)}>
          <CloseButtonSummary />
        </div>
      </section>

      <section className={styles.middle}>
        <Swiper
          min-height={200}
          autoHeight={false}
          slidesPerView={1}
          touchReleaseOnEdges={true}
          onSlideChange={(e) => {
            setIndex(e.activeIndex);
          }}
          scrollbar={{ draggable: true }}
          threshold={1}
          onSwiper={setMySwiperRef}
        >
          {mappedSlides}
        </Swiper>
        <div className={styles.circleDiv}>{slideIndexMap}</div>
      </section>
      <section className={styles.bottomDiv}>
        <button
          onClick={() => onContinueClick()}
          className={styles.continueButton}
        >
          Continue - $59/year
        </button>
        <p className={styles.bottomText}>Recurring billing, cancel anytime.</p>
      </section>
    </ModalWrapper>
  );
}
