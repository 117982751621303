export default function ComingSoonIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.37952 31.3784C6.74543 26.6237 7.18077 21.3276 9.68708 16.8965C12.1934 12.5737 16.3325 9.54775 21.2362 8.57448L20.9096 6.08838L26.4665 9.11429L22.1085 13.761L21.6731 11.2749C17.6414 12.1401 14.1558 14.6247 12.0861 18.1921C10.0166 21.9754 9.57975 26.4061 10.9962 30.5119L8.381 31.377L8.37952 31.3784Z"
        fill="#FFFFF2"
      />
      <path
        d="M38.3407 36.7824C34.9636 40.6737 30.0599 42.9423 24.9399 42.9423C19.9289 42.9423 15.2425 40.8895 11.8658 37.2143L10.1228 38.9432L9.25061 32.6754L15.5694 33.4326L13.8265 35.2694C16.6593 38.4033 20.6911 40.2401 24.9386 40.2401C29.2966 40.2401 33.3283 38.2953 36.1611 35.052L38.3395 36.7809L38.3407 36.7824Z"
        fill="#FFFFF2"
      />
      <path
        d="M28.0993 8.46802C33.2192 9.3332 37.5775 12.3593 40.0838 16.898C42.5901 21.2208 43.1343 26.4078 41.6091 31.056L43.6786 32.461L37.5774 34.5137L37.0332 28.2459L39.1027 29.5429C40.3015 25.7596 39.7558 21.6543 37.6863 18.1949C35.508 14.5212 31.9118 11.9271 27.5538 11.1701L28.098 8.46813L28.0993 8.46802Z"
        fill="#FFFFF2"
      />
      <rect
        x="17.3657"
        y="23.779"
        width="4.08672"
        height="7.00196"
        rx="0.930461"
        fill="#FFFFF2"
      />
      <rect
        x="22.9386"
        y="18.2512"
        width="4.08672"
        height="12.5298"
        rx="0.930461"
        fill="#FFFFF2"
      />
      <rect
        x="28.5114"
        y="21.5679"
        width="4.08672"
        height="9.2131"
        rx="0.930461"
        fill="#FFFFF2"
      />
    </svg>
  );
}
