/* eslint-disable @next/next/no-img-element */
import {
  Nature,
  PublicProfile,
} from "@dimensional-engineering/dimensional-models";
import Link from "next/link";

import styles from "./SmallProfileImage.module.css";

import { ProfileColorMap } from "../ColorMap";
import DeepConnectionIcon from "../icons/DeepConnectionIcon";
import CloseConnectionIcon from "../icons/CloseConnectionIcon";
import { useModalContext } from "@/context/ModalContext";
import { useRouter } from "next/router";

export default function SmallProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
  medium?: boolean;
  isClose?: boolean;
  isDeep?: boolean;
  noLink?: boolean;
}) {
  const { emptyModal } = useModalContext();
  if (props.noLink) {
    return (
      <div
        className={
          props.medium
            ? styles.mediumImageContainer
            : styles.smallImageContainer
        }
        style={
          props.publicProfile?.primaryNature?.nature
            ? {
                border: `3px solid ${
                  ProfileColorMap[props.publicProfile.primaryNature?.nature]
                }`,
              }
            : {
                border: `3px solid #55504F`,
              }
        }
      >
        <img
          alt=""
          src={props.imageUrl}
          className={props.medium ? styles.mediumImage : styles.smallImage}
        />
        {props.isClose && (
          <div className={styles.iconDiv}>
            <CloseConnectionIcon active />
          </div>
        )}
        {props.isDeep && (
          <div className={styles.iconDiv}>
            <DeepConnectionIcon active />
          </div>
        )}
      </div>
    );
  }

  return (
    <Link
      href={`/app/profile/${props.publicProfile?.ownerUUID}`}
      onClick={() => emptyModal()}
      className={
        props.medium ? styles.mediumImageContainer : styles.smallImageContainer
      }
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `3px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      <img
        alt=""
        src={props.imageUrl}
        className={props.medium ? styles.mediumImage : styles.smallImage}
      />
      {props.isClose && (
        <div className={styles.iconDiv}>
          <CloseConnectionIcon active />
        </div>
      )}
      {props.isDeep && (
        <div className={styles.iconDiv}>
          <DeepConnectionIcon active />
        </div>
      )}
    </Link>
  );
}

export function TabBarProfileImage(props: {
  publicProfile: PublicProfile | null;
  imageUrl: string;
}) {
  return (
    <div
      className={styles.smallImageContainerTabBar}
      style={
        props.publicProfile?.primaryNature?.nature
          ? {
              border: `3px solid ${
                ProfileColorMap[props.publicProfile.primaryNature?.nature]
              }`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      <img alt="" src={props.imageUrl} className={styles.smallImage} />
    </div>
  );
}

export function NotificationProfileImage(props: {
  nature?: Nature | null | undefined;
  peerId?: string | null;
  imageUrl: string;
}) {
  const router = useRouter();
  return (
    <Link
      onClick={() => {
        router.push(`/app/profile/${props.peerId}`);
        console.log("Router hit");
      }}
      href={`/app/profile/${props.peerId}`}
      className={styles.mediumImageContainer}
      style={
        props.nature
          ? {
              border: `3px solid ${ProfileColorMap[props.nature]}`,
            }
          : {
              border: `3px solid #55504F`,
            }
      }
    >
      <img alt="" src={props.imageUrl} className={styles.mediumImage} />
    </Link>
  );
}
