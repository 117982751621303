import styles from "./AssessmentBackButton.module.css";

export default function AssessmentBackButton(props: {
  onClick: () => void;
  rotated?: boolean;
}) {
  if (props.rotated) {
    return (
      <button onClick={() => props.onClick()} className={styles.backButton}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          style={{ marginRight: "7px", transform: "rotate(-90deg)" }}
        >
          <path
            d="M12.6949 10.4346C12.4136 10.4346 12.1409 10.3432 11.9168 10.1752L7.50017 6.86304L3.08354 10.1752C2.51001 10.605 1.69658 10.4894 1.26552 9.91583C0.835667 9.3423 0.951351 8.52765 1.52489 8.0978L6.72085 4.20114C7.18237 3.85287 7.81799 3.85287 8.27951 4.20114L13.4755 8.0978C13.9224 8.43267 14.1038 9.01716 13.9272 9.54686C13.7494 10.0778 13.2538 10.4358 12.6949 10.4346Z"
            fill="#FFFFF2"
          />
        </svg>{" "}
        Back
      </button>
    );
  }

  return (
    <button onClick={() => props.onClick()} className={styles.backButton}>
      Back{" "}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        style={{ marginLeft: "7px" }}
      >
        <path
          d="M12.6949 10.4346C12.4136 10.4346 12.1409 10.3432 11.9168 10.1752L7.50017 6.86304L3.08354 10.1752C2.51001 10.605 1.69658 10.4894 1.26552 9.91583C0.835667 9.3423 0.951351 8.52765 1.52489 8.0978L6.72085 4.20114C7.18237 3.85287 7.81799 3.85287 8.27951 4.20114L13.4755 8.0978C13.9224 8.43267 14.1038 9.01716 13.9272 9.54686C13.7494 10.0778 13.2538 10.4358 12.6949 10.4346Z"
          fill="#FFFFF2"
        />
      </svg>
    </button>
  );
}
