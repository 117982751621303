import styles from "./CompatibilitySnippets.module.css";

export default function CompatibilityConversionCTA(props: {
  username: string | undefined;
}) {
  return (
    <div className={styles.conversionWrapper}>
      <div className={styles.conversionMain}>
        <p className={styles.conversionText}>
          This compatibility snippet is available because @{props.username} is a
          Plus subscriber.
        </p>
      </div>
    </div>
  );
}
