export default function LowerThanAllUsersIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="18" y="33.4805" width="4" height="9.52" rx="2" fill="#FFFFF2" />
      <rect x="25" y="21.2402" width="4" height="21.76" rx="2" fill="#FFFFF2" />
      <rect x="46" y="29.4004" width="4" height="13.6" rx="2" fill="#FFFFF2" />
      <rect x="39" y="17.1602" width="4" height="25.84" rx="2" fill="#FFFFF2" />
      <rect x="32" y="9" width="4" height="34" rx="2" fill="#FFFFF2" />
      <path
        d="M13.4287 36.1646C12.6658 36.9263 11.4304 36.9263 10.6687 36.1646L1.22901 26.7249H1.22779C0.46607 25.962 0.46607 24.7266 1.22779 23.9637L10.6675 14.524H10.6687C11.4377 13.8074 12.6365 13.8282 13.3811 14.5716C14.1245 15.3162 14.1453 16.515 13.4287 17.284L5.37215 25.3443L13.4287 33.4046C14.1904 34.1663 14.1904 35.4016 13.4287 36.1646Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
