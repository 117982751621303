/* eslint-disable react/no-children-prop */
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector } from "react-redux";

import styles from "../../../../styles/app/AuthTraits/ElementModal.module.css";

import Loader from "@/components/shared/Loader";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import useGetPatternData from "./useGetPatternData";
import PatternTraitsPageLeftBarModal from "../../pattern/patternsLeftBar/PatternTraitsPageLeftBarModal";
import SwipeTabs from "@/components/shared/SwipeTabs/SwipeTabs";
import SelfRatedScoreTable from "../../detailsTab/SelfRatedScoreTable/SelfRatedScoreTable";
import AboutThisTrait from "../../detailsTab/aboutThisTrait/AboutThisTrait";
import Histogram from "../../detailsTab/histogram/Histogram";
import OppositePattern from "../../detailsTab/patterns/oppositePatterns/OppositePattern";
import PatternComponentOf from "../../detailsTab/patterns/patternComponentOf/PatternComponentOf";
import ConnectionTab from "../../connectionTab/ConnectionTab";
import { useAuth } from "@/context/AuthContext";
import InteractionBar from "../../shared/InteractionBar/InteractionBar";
import Pattern from "../../pattern/Pattern/Pattern";
import { RootState } from "@/redux/store";
import { getUserPatternScore } from "../../utils";

export default function OwnPatternModal(props: { slug: string }) {
  const pattern = useGetPatternData(props.slug);
  const [tabs, setTabs] = useState<string[]>([
    "summary",
    "details",
    "identity",
  ]);
  const [slicedMarkdown, setSlicedMarkdown] = useState<string[]>([]);
  const [markdownContent, setMarkdownContent] = useState<string[]>([]);
  const [score, setScore] = useState<number | null | undefined>(null);
  const userState = useSelector((state: RootState) => state.user);
  const { user } = useAuth();

  useEffect(() => {
    setTabs(["summary", "details", "identity"]);
    setMarkdownContent([]);
    setSlicedMarkdown([]);
    pattern?.traitContent.markdownSections?.forEach((section, index) => {
      if (!tabs.includes(section.traitSection as string)) {
        setTabs((current) => [...current, section.traitSection as string]);
      }
      setMarkdownContent((current) => [...current, section.markdownString]);
      if (index !== 0) {
        setSlicedMarkdown((current) => [...current, section.markdownString]);
      }
    });
    setTabs((current) => [...current, "Connections"]);

    userState.patternsDiscovered?.forEach((patt) => {
      if (patt.patternSlug === props.slug) {
        const patPercScore = getUserPatternScore(
          patt.percentScore,
          patt.patternSlug
        );
        setScore(
          patPercScore !== undefined && patPercScore !== null
            ? patPercScore * 100
            : null
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pattern?.slug]);

  if (pattern === null) {
    return (
      <ModalWrapper title="">
        <Loader height="500px" mobileHeight="500px" />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper noLine title={pattern.name}>
      <div className={styles.main}>
        <div className={styles.parent}>
          <div className={styles.rightBarMain}>
            <SwipeTabs
              sticky
              tabs={tabs}
              slicedContent={slicedMarkdown}
              content={[
                <PatternTraitsPageLeftBarModal key={1} pattern={pattern} />,
                <div key={2}>
                  <div className={styles.archetypeDiv}>
                    <Pattern patternSlug={pattern.slug} score={score} />
                  </div>
                  <SelfRatedScoreTable slug={pattern.slug} type="pattern" />
                  <Histogram
                    // @ts-ignore
                    data={pattern.traitContent.histogramData}
                    auth
                    type="pattern"
                    averageUsrScore={pattern.traitContent.averagePercentScore}
                    modal
                  />
                  <AboutThisTrait
                    dimensionSlug={pattern.dimensionSlugs[0]}
                    traitContent={pattern.traitContent}
                  />
                  {pattern.traitContent.oppositeTrait?.traitSlug && (
                    <OppositePattern authenticated element={pattern} />
                  )}
                  {pattern.traitContent.traitDetails && (
                    <PatternComponentOf modal pattern={pattern} authenticated />
                  )}
                </div>,
                <div
                  key={3}
                  style={{ pointerEvents: "none" }}
                  className={styles.markdownDiv}
                >
                  <ReactMarkdown
                    transformLinkUri={(href: string) => {
                      return "";
                    }}
                    children={markdownContent[0]}
                  />
                </div>,
              ]}
              grow={tabs.length < 4 ? true : false}
              connectionTab={[
                <ConnectionTab
                  // @ts-ignore
                  histogramData={pattern.traitContent.histogramData}
                  slug={pattern.slug}
                  type="pattern"
                  averageUserScore={pattern.traitContent.averagePercentScore}
                  key={3}
                  connectionAvailabilities={pattern.connectionAvailabilities}
                  traitSlug={pattern.slug}
                />,
              ]}
            />
          </div>
        </div>
      </div>
      {user?.uid && (
        <InteractionBar
          uid={user?.uid}
          slug={pattern.slug}
          type="pattern"
          ownProfile
        />
      )}
    </ModalWrapper>
  );
}
