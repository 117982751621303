export default function LoveStylesAndAttitudesIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 19.5005C2.35435 18.9438 2.9905 18.3945 4.02989 17.8986C4.47484 17.6864 4.60384 17.0882 4.28326 16.7137C1.11685 13.0145 4.69083 11.4303 5.74706 11.2329C7.82918 10.8551 10.651 11.909 11.8376 13.2717L17.4272 19.4655C17.7277 19.7985 17.6203 20.3273 17.2136 20.5165L9.64967 24.037C8.02553 24.8268 5.01724 24.6958 3.24677 23.5351L3.24676 23.5351L3.24674 23.535C2.92239 23.3195 2.36839 22.7898 2 22.1039V19.5005ZM20 17.8916L19.845 18.1596C19.6204 18.5479 19.0842 18.6081 18.7791 18.2792L13.1054 12.1624C11.8548 10.8594 11.0554 7.95633 11.6161 5.9149L11.6162 5.91488L11.6162 5.91486C11.9069 4.88131 13.9344 1.45283 17.2206 4.92875C17.5593 5.28699 18.1682 5.22601 18.4261 4.80588C18.9547 3.945 19.4857 3.38816 20 3.05469V17.8916ZM17.5356 37.0066L17.5357 37.0066L17.5357 37.0066C19.1559 35.6439 20.145 32.7999 19.8547 31.0174L18.654 22.7611C18.5895 22.3173 18.1138 22.0625 17.7085 22.2548L10.1707 25.8309C8.52477 26.5764 6.70512 28.9768 6.46921 31.0797C6.35511 32.1482 6.84651 36.0265 11.299 34.0554C11.7498 33.8558 12.2858 34.1511 12.3614 34.6383C13.0948 39.3652 16.7098 37.6927 17.5356 37.0066Z"
        fill="#FFFFF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 36.7704C21.3691 36.8029 21.7408 36.8194 22.114 36.8197C22.6281 36.8197 23.1446 36.7892 23.6564 36.7306C27.8751 36.2122 31.7441 34.1271 34.493 30.8878C37.2398 27.6487 38.6648 23.4926 38.484 19.2492L38.4838 19.2496C38.2912 14.582 36.1033 10.2194 32.4738 7.2711C29.2202 4.60921 25.1536 3.19658 21 3.23311V8.0216C24.0767 7.96466 27.0861 9.0137 29.4692 11.0002C31.9389 13.0385 33.4602 16.0033 33.6762 19.1953C33.8734 22.3407 32.8311 25.4371 30.7745 27.8245C28.7179 30.2123 25.8069 31.7016 22.6658 31.9738C22.1064 32.0221 21.5482 32.0097 21 31.9395V36.7704ZM21 22.4538C21.1437 22.4242 21.2925 22.4087 21.445 22.4087H21.6563C22.3653 22.404 23.0344 22.0873 23.4875 21.5432C23.9406 20.999 24.1308 20.2836 24.0063 19.587C23.7481 18.433 22.7245 17.6097 21.5413 17.605C21.3599 17.6076 21.1794 17.617 21 17.6332V12.8192C21.2179 12.8073 21.4367 12.8013 21.656 12.8013C23.7807 12.7989 25.7949 13.7348 27.1684 15.3532C28.5394 16.974 29.1263 19.1155 28.7718 21.2077C28.1077 24.719 25.0205 27.2475 21.445 27.2123C21.2925 27.2123 21.1437 27.1967 21 27.1672V22.4538Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
