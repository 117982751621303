import { ConnectionAvailability } from "@dimensional-engineering/dimensional-models";

import styles from "./AvailableToComponent.module.css";

import BasicConnectionIcon from "../icons/BasicConnectionIcon";
import CloseConnectionIcon from "../icons/CloseConnectionIcon";
import DeepConnectionIcon from "../icons/DeepConnectionIcon";
import PrivateIcon from "../icons/PrivateIcon";

export default function AvailableToComponent(props: {
  connectionRequired: "basic" | "close" | "deep" | "private" | "unconnected";
}) {
  return (
    <div className={styles.requiredDivModal}>
      {props.connectionRequired === "basic" ? (
        <div className={styles.connectionIconModal}>
          <BasicConnectionIcon active />
        </div>
      ) : props.connectionRequired === "close" ? (
        <div className={styles.connectionIconModal}>
          <CloseConnectionIcon active />
        </div>
      ) : (
        <div className={styles.connectionIconModal}>
          <DeepConnectionIcon active />
        </div>
      )}
      <p className={styles.requiredDivModalText}>
        Available to {props.connectionRequired} connections
      </p>
    </div>
  );
}

export function AvailableToComponentAvailibility(props: {
  connectionAvailability: ConnectionAvailability[] | null | undefined;
  traits?: boolean;
}) {
  const status = props.connectionAvailability?.includes(
    "basic" as ConnectionAvailability
  )
    ? "basic"
    : props.connectionAvailability?.includes("close" as ConnectionAvailability)
    ? "close"
    : props.connectionAvailability?.includes("deep" as ConnectionAvailability)
    ? "deep"
    : "private";

  const text =
    status === "basic"
      ? `Score available to all connections`
      : status === "close"
      ? `Score available to close connections`
      : status === "deep"
      ? `Score available to deep connections`
      : `Private trait: score not available to connections`;

  return (
    <div className={styles.requiredDivModal}>
      {status === "basic" ? (
        <div className={styles.connectionIconModal}>
          <BasicConnectionIcon active />
        </div>
      ) : status === "close" ? (
        <div className={styles.connectionIconModal}>
          <CloseConnectionIcon active />
        </div>
      ) : status === "deep" ? (
        <div className={styles.connectionIconModal}>
          <DeepConnectionIcon active />
        </div>
      ) : (
        <div
          style={{ backgroundColor: "transparent" }}
          className={styles.connectionIconModal}
        >
          <PrivateIcon />
        </div>
      )}
      <p className={styles.requiredDivModalText}>
        {props.traits ? text : `Available to ${status} connections`}
      </p>
    </div>
  );
}
