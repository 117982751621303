import { DimensionIconProps } from "@/models/sharedModels";

export default function CompatibilityValuesIcon(props: DimensionIconProps) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "41"}
      height={props.maxWidth ? "100%" : "41"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.12904 4.35434C3.09393 3.84811 3.50661 3.43311 4.01406 3.43311H36.0854C36.5928 3.43311 37.0055 3.84811 36.9704 4.35434C36.4009 12.566 29.3914 19.1008 20.7092 19.4171C20.7795 19.469 20.8437 19.5322 20.8994 19.6068L32.5432 35.2083C32.9922 35.81 32.5628 36.6662 31.8121 36.6662H8.52454C7.7738 36.6662 7.34443 35.81 7.79346 35.2083L19.4372 19.6068C19.4905 19.5355 19.5516 19.4745 19.6182 19.424C10.831 19.2149 3.70353 12.6377 3.12904 4.35434Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
