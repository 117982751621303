import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { peerRatedSnippets } from "./snippetsMap";

import { RootState } from "@/redux/store";

export function useGetDiscoveredSnippetSlugs() {
  const snippetState = useSelector((state: RootState) => state.snippet);
  const [discoveredSlugs, setDiscoveredSlugs] = useState<string[]>([]);
  const userState = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let tempDiscoveredSlugs: string[] = [];
    snippetState.snippets.forEach((s) => {
      if (peerRatedSnippets.includes(s?.snippetSlug)) {
        const numberOfPeerAssessees =
          userState.memberProfile?.peerAssesseeUids?.length;
        const requiredNumberOfPeerAssessments =
          s.discoveryGate.numPeerAssessorsRequired;
        if (numberOfPeerAssessees && requiredNumberOfPeerAssessments) {
          if (numberOfPeerAssessees >= requiredNumberOfPeerAssessments) {
            tempDiscoveredSlugs.push(s?.snippetSlug);
          }
        }
      } else {
        tempDiscoveredSlugs.push(s?.snippetSlug);
      }
    });
    setDiscoveredSlugs(tempDiscoveredSlugs);
  }, [snippetState.snippets]);

  return discoveredSlugs;
}
