import React from "react";

import styles from "./CircularScoreBar.module.css";

import { manipulateScorebarValue } from "../utils";

export default function CircularScoreBarSmall(props: {
  score: number | null | undefined;
  doubleQuestionMark?: boolean;
}) {
  const radius = 29; // Adjust as needed
  const circumference = 2 * Math.PI * radius;
  const openPercentage = props.score
    ? 100 - manipulateScorebarValue(props.score)
    : 100;

  const openLength = (circumference * openPercentage) / 100;
  const closedLength = circumference - openLength;

  const strokeDashoffset = closedLength;

  if (props.doubleQuestionMark) {
    return (
      <div className={styles.main}>
        <div className={styles.mainCircleSmall}></div>
        <svg
          className={styles.innerSmalll}
          width={radius * 2}
          height={radius * 2}
        >
          <circle
            cx={radius}
            cy={radius}
            r={radius - 5}
            fill="transparent"
            strokeWidth={4}
            stroke="#FFFFDE"
            strokeDasharray={`${closedLength} ${openLength}`}
            strokeDashoffset={strokeDashoffset}
            style={props.score !== null ? { strokeLinecap: "round" } : {}}
          />
        </svg>
        <p
          className={
            props.score !== null && props.score !== undefined
              ? props.score < 10
                ? styles.scoreLowSmall
                : styles.scoreSmall
              : styles.scoreunknownDouble
          }
        >
          {props.score !== null && props.score !== undefined
            ? `${props.score.toFixed(0)}%`
            : "??"}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.mainCircleSmall}></div>
      <svg
        className={styles.innerSmalll}
        width={radius * 2}
        height={radius * 2}
      >
        <circle
          cx={radius}
          cy={radius}
          r={radius - 5}
          fill="transparent"
          strokeWidth={4}
          stroke="#FFFFDE"
          strokeDasharray={`${closedLength} ${openLength}`}
          strokeDashoffset={strokeDashoffset}
          style={props.score !== null ? { strokeLinecap: "round" } : {}}
        />
      </svg>
      <p
        className={
          props.score !== null && props.score !== undefined
            ? props.score < 10
              ? styles.scoreLowSmall
              : styles.scoreSmall
            : styles.scoreunknown
        }
      >
        {props.score !== null && props.score !== undefined
          ? `${props.score.toFixed(0)}%`
          : "?"}
      </p>
    </div>
  );
}
