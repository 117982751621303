import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Roboto_Mono } from "next/font/google";
import { doc, onSnapshot } from "firebase/firestore";
import { DeliveredDailyStoryNew } from "@dimensional-engineering/dimensional-models";

import styles from "./DailyStories.module.css";

import { RootState } from "@/redux/store";
import {
  getCountOfTraitRulesDailyStoriesForMixpanel,
  getListOfTraitRulesDailyStoriesForMixpanel,
  splitStringIntoMultipleLines,
  updateDailyStoryFeedbackScore,
} from "./utils";
import ModalWrapper from "@/components/shared/ModalWrapper/ModalWrapper";
import { CloseButtonWhite } from "@/components/shared/CloseButtonSummary";
import { useModalContext } from "@/context/ModalContext";
import { DailyStoryFeedbackScoreBar } from "@/components/results/personalityAnalysis/AnalysisFeedback/AnalysisFeedback";
import { useAuth } from "@/context/AuthContext";
import { db } from "@/_firebase/firebaseConfig";
import DailyStoriesShareView from "./DailyStoriesShareView";
import { Mixpanel } from "@/helpers/mixpanel";
import ExpiredErrorModal from "@/components/shared/ExpiredErrorModal/ExpiredErrorModal";
import { DailyStoriesInterractionBar } from "@/components/results/Snippets/SnippetInterractionBar/SnippetInterractionBar";
import InformationIcon from "@/components/premium/reports/blindspot/ReportAccuracy/InformationIcon";
import GeneralModal from "@/components/shared/GeneralModal/GeneralModal";

const font = Roboto_Mono({ subsets: ["latin"] });

export default function DailyStoriesModal(props: {
  elementName: "DailyStory";
  slug?: string;
}) {
  const { user } = useAuth();
  const { setModalOpen, setModalComponent } = useModalContext();
  const dailyStory = useSelector((state: RootState) => state.dailyStory);
  const feedbackData = useGetDailyStoryFeedback();
  const [showRating, setShowRating] = useState<boolean>(false);

  useEffect(() => {
    if (dailyStory.dailyStory?.computeableStoryModelSlug) {
      Mixpanel?.track("Daily Story Viewed", {
        slug: dailyStory.dailyStory?.computeableStoryModelSlug,
        body: dailyStory.dailyStory.assignmentRuleset?.body.slice(0, 250),
        trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
          dailyStory.dailyStory
        ),
        is_novel_ruleset:
          dailyStory.dailyStory.assignmentRuleset.isNovelGenerated,
        trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
          dailyStory.dailyStory
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyStory.dailyStory?.computeableStoryModelSlug]);

  useEffect(() => {
    if (feedbackData !== null && feedbackData !== undefined) {
      setShowRating(false);
    } else {
      setShowRating(true);
    }
  }, [feedbackData]);

  if (!dailyStory.dailyStory) {
    return (
      <ExpiredErrorModal body="You waited too long to see that story! Stories disappear after 24 hours." />
    );
  }

  if (
    props.slug &&
    props.slug !== dailyStory.dailyStory.computeableStoryModelSlug
  ) {
    return (
      <ExpiredErrorModal body="You waited too long to see that story! Stories disappear after 24 hours." />
    );
  }

  const bodySplit = splitStringIntoMultipleLines(
    dailyStory.dailyStory.assignmentRuleset?.body,
    37
  );

  const mappedBody = bodySplit.map((line, i) => {
    return (
      <p className={styles.storyBody} key={i}>
        {line}
      </p>
    );
  });

  return (
    <ModalWrapper title="" noHeader>
      <main
        style={{
          backgroundImage: `linear-gradient(rgba(37, 0, 0, 0) 54.69%, rgba(6, 0, 0, 0.5) 100%), url(/dailyStories/${dailyStory.dailyStory.computeableStoryModelSlug}.jpg)`,
        }}
        className={styles.modalMain}
      >
        <div
          onClick={() => {
            setModalOpen(false);
          }}
          className={styles.xmark}
        >
          <CloseButtonWhite />
        </div>
        <section className={styles.wrapper}>
          <h5 className={`${styles.storyTitleModal} ${font.className}`}>
            {dailyStory.dailyStory.name.toUpperCase() + ":"}
          </h5>
          <div
            onClick={() => {
              if (dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated) {
                setModalComponent(
                  <GeneralModal
                    title="Experimental Story"
                    body="Sometimes we sprinkle in a bit of chaos to our stories. The results aren't always good, so feedback on these experimental stories is particularly helpful."
                  />
                );
              }
            }}
            style={
              dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated
                ? {}
                : { opacity: "0" }
            }
            className={styles.experimentalDiv}
          >
            <p className={`${styles.experimentalText} ${font.className}`}>
              EXPERIMENTAL STORY
            </p>
            <div className={styles.infoIcon}>
              <InformationIcon />
            </div>
          </div>
          <div className={styles.bodyWrapper}>{mappedBody}</div>
        </section>
        <div className={styles.interractionBar}>
          {showRating && (
            <div className={styles.feedbackDiv}>
              <DailyStoryFeedbackScoreBar
                onAnswer={async (value) => {
                  Mixpanel?.track("Feedback given", {
                    slug: dailyStory.dailyStory?.computeableStoryModelSlug,
                    body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(
                      0,
                      250
                    ),
                    score: value,
                    content_type: "daily_story",
                    trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
                      dailyStory.dailyStory
                    ),
                    is_novel_ruleset:
                      dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated,
                    trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
                      dailyStory.dailyStory
                    ),
                  });
                  return await updateDailyStoryFeedbackScore(
                    user?.uid,
                    value,
                    dailyStory.dailyStory?.deliveryId
                  ).then(() => {
                    setShowRating(false);
                  });
                }}
                title="How good was this content?"
                subtitle="Feedback improves your results"
                currentAnswer={feedbackData ?? null}
                onClose={() => setShowRating(false)}
              />
            </div>
          )}
          <div
            style={showRating ? { height: "0" } : {}}
            className={styles.bottomGradientDiv}
          ></div>
          <DailyStoriesInterractionBar
            onCopyClick={() => {
              if (
                window.Android &&
                window.Android.copyToClipboard &&
                dailyStory.dailyStory?.assignmentRuleset?.body
              ) {
                window.Android.copyToClipboard(
                  dailyStory.dailyStory.assignmentRuleset?.body
                );
                Mixpanel?.track("Content copied", {
                  slug: dailyStory.dailyStory?.computeableStoryModelSlug,
                  body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(
                    0,
                    250
                  ),
                  trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
                    dailyStory.dailyStory
                  ),
                  is_novel_ruleset:
                    dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated ??
                    false,
                  trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
                    dailyStory.dailyStory
                  ),
                });
              }
            }}
            onCommentClick={() => {}}
            onShare={(type) => {
              setModalComponent(<DailyStoriesShareView type={type} />);
              Mixpanel?.track("Shared to Social", {
                type: "dailyStory",
                slug: dailyStory.dailyStory?.computeableStoryModelSlug,
                platform: type,
                body: dailyStory.dailyStory?.assignmentRuleset?.body.slice(
                  0,
                  250
                ),
                user_message:
                  dailyStory.dailyStory?.assignmentRuleset.promptDetails
                    .userMessage,
                assistant_message:
                  dailyStory.dailyStory?.assignmentRuleset.promptDetails
                    .assistantMessage,
                system_message:
                  dailyStory.dailyStory?.assignmentRuleset.promptDetails
                    .systemMessage,
                trait_rules: getListOfTraitRulesDailyStoriesForMixpanel(
                  dailyStory.dailyStory
                ),
                is_novel_ruleset:
                  dailyStory.dailyStory?.assignmentRuleset.isNovelGenerated ??
                  false,
                trait_count: getCountOfTraitRulesDailyStoriesForMixpanel(
                  dailyStory.dailyStory
                ),
              });
            }}
            onRateClick={() => {
              setShowRating(!showRating);
            }}
          />
        </div>
      </main>
    </ModalWrapper>
  );
}

function useGetDailyStoryFeedback() {
  const [feedbackData, setFeedbackData] = useState<number | null | undefined>(
    null
  );
  const { user } = useAuth();

  const dailyStory = useSelector((state: RootState) => state.dailyStory);

  useEffect(() => {
    const ref = doc(
      db,
      `/members/${user?.uid}/deliveredDailyStoriesNew/${dailyStory.dailyStory?.deliveryId}`
    );
    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data() as DeliveredDailyStoryNew;
          setFeedbackData(data.feedbackScore);
        } else {
          setFeedbackData(null);
        }
      },
      (err) => {
        setFeedbackData(null);
      }
    );

    return () => {
      unsubscribe;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyStory.dailyStory?.deliveryId]);

  return feedbackData;
}
