export default function ThickForwardArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clipPath="url(#clip0_44912_15227)">
        <path
          d="M16.1226 7.55632L10.8551 2.2888C10.6157 2.03693 10.2844 1.89255 9.93693 1.88755C9.58943 1.88318 9.25506 2.01943 9.0088 2.26506C8.76317 2.51069 8.62693 2.84506 8.63067 3.19256C8.63505 3.54069 8.77942 3.87131 9.0313 4.11131L12.1451 7.22011H1.78938C1.0775 7.22011 0.5 7.79762 0.5 8.50949C0.5 9.22198 1.0775 9.79949 1.78938 9.79949H12.1163L9.0313 12.8889C8.54943 13.3964 8.55943 14.1952 9.05442 14.6896C9.54942 15.184 10.3488 15.1939 10.8551 14.7114L16.1226 9.44392C16.3651 9.20268 16.5013 8.87455 16.5001 8.53205C16.5007 8.52143 16.5007 8.5108 16.5001 8.50018C16.5007 8.48955 16.5007 8.47893 16.5001 8.4683C16.5001 8.12643 16.3645 7.79819 16.1226 7.55632Z"
          fill="#FFFFF2"
        />
      </g>
      <defs>
        <clipPath id="clip0_44912_15227">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
