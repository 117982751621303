import { DimensionIconProps } from "@/models/sharedModels";

export default function YouTwoSummarizedIcon(props: DimensionIconProps) {
  return (
    <svg
      width={props.maxWidth ? "100%" : "100%"}
      height={props.maxWidth ? "100%" : "100%"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9997 5.5H8.22975L15.6443 20.6308L8 34.5H32V29.3819H16.5063L21.2516 20.7734L16.2762 10.6178H32L31.9997 5.5Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
