export const storyColorMap = {
  "your-hell": "#fffff2",
  "seeks-attention-by": "#fffff2",
  "you-in-a-nutshell": "rgb(0, 0, 0)",
  "want-a-partner-who-will": "#2A1654",
  "your-most-annoying-habits": "#FEFFC5",
  "calling-in-life": "#fffff2",
  "worst-habits-in-love": "#403126",
  "greatest-strengths": "rgb(0, 0, 0)",
  "hate-talking-about": "#41344A",
  "obstacles-to-happiness": "#ffffff",
  "crush-on": "#fffff2",
  "most-toxic-traits": "#fffff2",
  "story-how-you-flirt": "#0f001c",
  "story-your-heaven": "#fffff2",
  "story-secretly-wishing-for": "#000000",
};
