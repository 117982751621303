import {
  DiscoverableSnippet,
  DiscoveredSnippet,
  Nature,
} from "@dimensional-engineering/dimensional-models";

import styles from "./Snippets.module.css";

import TraitIdentifier from "@/components/traits/shared/TraitIdentifier/TraitIdentifier";
import SnippetsHistogram from "@/components/traits/detailsTab/histogram/SnippetsHistogram";
import {
  ExtendedArchetype,
  ExtendedPattern,
  ExtendedScale,
} from "@/models/sharedModels";
import { UnderlinedTraitText } from "./SnippetsModal";

type Props = {
  trait: ExtendedArchetype | ExtendedPattern | ExtendedScale | null;
  snippet: DiscoveredSnippet;
  discoverableSnippet: DiscoverableSnippet;
  score: number | null | undefined;
  imageUrl?: string;
  nature?: Nature | null | undefined;
  shareHash?: string;
  uid?: string;
};

export default function SnippetTraitComparisonDetailView(props: Props) {
  return (
    <>
      {props.snippet.snippetDetail.traitComparisonDetail &&
        props.snippet.snippetDetail.traitComparisonDetail?.body !== undefined &&
        props.trait && (
          <>
            <UnderlinedTraitText
              text={props.snippet.snippetDetail.traitComparisonDetail?.body}
              //@ts-ignore
              traitName={props.trait.alias ?? props.trait.name}
              trait={props.trait}
            />
            <div className={styles.traitDiv}>
              <TraitIdentifier
                identifier={
                  props.snippet.snippetDetail.traitComparisonDetail
                    .traitIdentifier
                }
                deeplink={props.shareHash ? true : false}
                shareHash={props.shareHash}
                uid={props.uid}
              />
            </div>
            <div>
              <SnippetsHistogram
                //@ts-ignore
                data={props.trait.traitContent?.histogramData}
                score={props.score}
                averageUserScore={
                  props.snippet.snippetDetail.traitComparisonDetail
                    .averageUserScore
                }
                connectionAverageScore={
                  props.snippet.snippetDetail.traitComparisonDetail
                    .connectionAverageScore
                }
                imageUrl={props.imageUrl}
                nature={props.nature}
              />
            </div>
          </>
        )}
      {props.snippet.snippetDetail.traitComparisonDetail?.averageUserScore && (
        <div className={styles.labelDiv}>
          <div className={styles.averageCircle}></div>
          <p className={styles.label}>Average score of all users</p>
        </div>
      )}
      {props.snippet.snippetDetail.traitComparisonDetail
        ?.connectionAverageScore && (
        <div className={styles.labelDiv}>
          <div className={styles.averageConnectionCircle}></div>
          <p className={styles.label}>Average score of your connections</p>
        </div>
      )}
    </>
  );
}
