export default function CloseConnectionIcon(props: { active?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      style={{ padding: "2px" }}
    >
      <path
        d="M15.618 7.04914C16.2328 6.50235 15.9247 5.47813 15.1059 5.41013L11.3156 5.10339C10.9742 5.06872 10.6675 4.86468 10.5301 4.52328L9.06178 1.00647C8.75505 0.255655 7.66147 0.255655 7.35475 1.00647L5.88644 4.52328C5.75041 4.83001 5.44234 5.07007 5.10094 5.10339L1.34685 5.4448C0.528028 5.51281 0.186624 6.53702 0.834746 7.08382L3.702 9.54165C3.97539 9.78037 4.07809 10.1218 4.00873 10.4632L3.12053 14.1507C2.91516 14.9362 3.80334 15.5843 4.48615 15.1402L7.72949 13.1598C8.03623 12.9891 8.4123 12.9891 8.6857 13.1598L11.929 15.1402C12.6465 15.5843 13.5 14.9348 13.2947 14.1507L12.4065 10.4632C12.3384 10.1218 12.4411 9.78036 12.7132 9.54165L15.618 7.04914Z"
        fill={props.active ? "#2D2927" : "#55504F"}
      />
    </svg>
  );
}

export function CloseConnectionIconSummaries() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9999 12.9999C25.9999 20.1796 20.1796 25.9999 12.9999 25.9999C5.82027 25.9999 0 20.1796 0 12.9999C0 5.82027 5.82027 0 12.9999 0C20.1796 0 25.9999 5.82027 25.9999 12.9999ZM19.1583 10.7552C19.8679 10.8141 20.1349 11.7018 19.6021 12.1757L17.0846 14.3358C16.8488 14.5427 16.7598 14.8386 16.8188 15.1345L17.5885 18.3303C17.7666 19.0099 17.0268 19.5728 16.405 19.1879L13.5941 17.4715C13.3572 17.3236 13.0313 17.3236 12.7654 17.4715L9.95455 19.1879C9.36278 19.5728 8.59303 19.0111 8.77102 18.3303L9.54078 15.1345C9.6009 14.8386 9.51189 14.5427 9.27495 14.3358L6.79001 12.2057C6.22831 11.7318 6.52419 10.8442 7.23384 10.7852L10.4874 10.4894C10.7832 10.4605 11.0502 10.2524 11.1681 9.9866L12.4407 6.93871C12.7065 6.28801 13.6542 6.28801 13.9201 6.93871L15.1926 9.9866C15.3117 10.2825 15.5775 10.4593 15.8734 10.4894L19.1583 10.7552Z"
        fill="#FFFFF2"
      />
    </svg>
  );
}
