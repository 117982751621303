import styles from "./Iconstyles.module.css";

export default function SimilarIcon(props: {
  primaryColor: string;
  secondaryColor: string;
}) {
  return (
    <div className={styles.circleParent}>
      <div
        style={{ borderColor: `${props.primaryColor}` }}
        className={styles.leftCircle}
      ></div>
      <div
        style={{ borderColor: `${props.secondaryColor}` }}
        className={styles.rightCircle}
      ></div>
    </div>
  );
}

export function NonSimilarIcon(props: {
  primaryColor: string;
  secondaryColor: string;
}) {
  return (
    <div className={styles.circleParent}>
      <div
        style={{ borderColor: `${props.primaryColor}` }}
        className={styles.leftCircleNonSimilar}
      ></div>
      <div
        style={{ borderColor: `${props.secondaryColor}` }}
        className={styles.rightCircleNonSimilar}
      ></div>
    </div>
  );
}
