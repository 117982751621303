import styles from "./PeerFeedbackPill.module.css";

import { useModalContext } from "@/context/ModalContext";
import PeerFeedbackModal from "../PeerFeedbackModal/PeerFeedbackModal";
import PeerFeedbackIcon, {
  PeerFeedbackIconSmall,
  PeerFeedbackIconVerySmall,
} from "@/components/shared/icons/PeerFeedbackIcon";
import { Mixpanel } from "@/helpers/mixpanel";

export default function PeerFeedbackPill(props: {
  numOfPeerAssessmentsReceived: number | undefined;
  onClick: () => void;
  inActive?: boolean;
}) {
  return (
    <div
      onClick={() => {
        Mixpanel?.track("Peer Feedback Levels Modal Viewed", {
          source: "dashboard feedback level module",
        });
        if (window.Android) {
          window.Android.onHaptic();
        }
        props.onClick();
      }}
      style={props.inActive === true ? { background: "#2D2927" } : {}}
      className={styles.main}
    >
      <div className={styles.iconDiv}>
        <PeerFeedbackIcon />
      </div>
      <p className={styles.text}>{props.numOfPeerAssessmentsReceived ?? 0}</p>
    </div>
  );
}

export function PeerFeedbackPillSmall(props: {
  numOfPeerAssessmentsReceived: number | undefined;
}) {
  const { setModalComponent } = useModalContext();
  return (
    <div
      onClick={() => {
        Mixpanel?.track("Peer Feedback Levels Modal Viewed", {
          source: "dashboard feedback level module",
        });
        setModalComponent(<PeerFeedbackModal />);
      }}
      className={styles.mainSmall}
    >
      <div className={styles.iconDiv}>
        <PeerFeedbackIconSmall />
      </div>
      <p className={styles.text}>{props.numOfPeerAssessmentsReceived ?? 0}</p>
    </div>
  );
}

export function PeerFeedbackPillVerySmall(props: {
  numOfPeerAssessmentsReceived: number | undefined;
}) {
  const { setModalComponent } = useModalContext();
  return (
    <div
      onClick={() => {
        Mixpanel?.track("Peer Feedback Levels Modal Viewed", {
          source: "dashboard feedback level module",
        });
        setModalComponent(<PeerFeedbackModal />);
      }}
      className={styles.mainVerySmall}
    >
      <div className={styles.iconDiv}>
        <PeerFeedbackIconVerySmall />
      </div>
      <p style={{ fontSize: "12px" }} className={styles.text}>
        {props.numOfPeerAssessmentsReceived ?? 0}
      </p>
    </div>
  );
}
