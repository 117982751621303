import { useAlertContext } from "@/context/AlertContext";

export default function AlertHandler() {
  const { alertComponent, isAlertOpen } = useAlertContext();

  const mapped = alertComponent
    .sort((a, b) => {
      const mapper = {
        fail: 0,
        warning: 1,
        success: 2,
      };
      return (
        mapper[a.component.props.type as keyof typeof mapper] -
        mapper[b.component.props.type as keyof typeof mapper]
      );
    })
    .map((comp) => {
      return <div key={comp.component.type.name}>{comp.component}</div>;
    });

  return <>{mapped[0]}</>;
}
